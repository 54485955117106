import { HttpErrorResponse } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

import { debounceTime, Subject } from "rxjs";

import { CardContentTypeEnum } from "@design-makeover/components/cards/card-content/card-content.model";
import { InputSelectOption } from "@design-makeover/components/inputs/input-select/input-select.model";
import { SlideOverlayContentComponent } from "@design-makeover/components/overlay/slide-overlay-content/slide-overlay-content.component";
import { SlideOverlayPageClass } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.class";
import { NotificationService } from "@design-makeover/services/notification/notification.service";

import {
  LocationOverlayGpsComponent,
  LocationOverlayLinksComponent,
  LocationOverlayMainInformationComponent,
} from "@components/locations";
import { LocationOverlayService } from "@components/locations/pages/location-overlay/location-overlay.service";
import {
  AnalysesTableComponent,
  OverlayCertificateAttachmentsComponent,
  OverlayDocumentAttachmentsComponent,
} from "@components/shared";
import { AnalysesTableService } from "@components/shared/tables/analyses-table/analyses-table.service";
import { CommonConstants } from "@shared/constants";
import {
  AttachmentTargetEnum,
  CustomFieldsResourceTypeEnum,
  EntityTypeEnum,
  FeatureFlagEnum,
  GeoJsonTypeEnum,
  OverlayTabEnum,
  RecordStateEnum,
  ResourceTypeEnum,
  RoutingEnum,
} from "@shared/enums";
import {
  IAttachment,
  IAttachmentPayload,
  ILocationDetails,
  ILocationPayload,
  ILocationType,
  IOrganisation,
  IRecordResponse,
  IRecordState,
  ISelectOption,
} from "@shared/interfaces";
import {
  AttachmentsService,
  CommonService,
  ConnectionsService,
  FeatureFlagService,
  LocationsService,
  OrganisationsService,
  RulesetsService,
} from "@shared/services";
import { CommonUtils, CustomFieldsUtils, FormUtils, GeoJSONUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

@Component({
  selector: "app-location-overlay",
  templateUrl: "./location-overlay.component.html",
  styleUrls: ["./location-overlay.component.scss", "../location-overlay-common.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class LocationOverlayComponent extends SlideOverlayPageClass implements OnInit, OnDestroy {
  @ViewChild("informationView") informationView: LocationOverlayMainInformationComponent;

  @ViewChild("linkedLocationView") linkedLocationView: LocationOverlayLinksComponent;

  @ViewChild("slideOverlayContent") override slideOverlayContent: SlideOverlayContentComponent;

  @ViewChild("certificateView") certificateView: OverlayCertificateAttachmentsComponent;

  @ViewChild("documentView") documentView: OverlayDocumentAttachmentsComponent;

  @ViewChild("analysesTableView") analysesTableView: AnalysesTableComponent;

  @ViewChild("locationGpsView") locationGpsView: LocationOverlayGpsComponent;

  formGroup: UntypedFormGroup;

  override menuItems = signal(
    new Map([
      [OverlayTabEnum.DETAILS, { isEnabled: true }],
      [OverlayTabEnum.GPS_DATA, { isEnabled: false }],
      [OverlayTabEnum.LINKED_LOCATIONS, { isEnabled: false, isHidden: !this.isRegularUser }],
      [OverlayTabEnum.CERTIFICATES, { isEnabled: false }],
      [OverlayTabEnum.DOCUMENTS, { isEnabled: false }],
      [OverlayTabEnum.ANALYSES, { isEnabled: false }],
      [OverlayTabEnum.SHARES, { isEnabled: false, isHidden: !this.isRegularUser }],
      [OverlayTabEnum.COMMENTS, { isEnabled: false, isHidden: !this.isRegularUser }],
    ]),
  );

  override element: ILocationDetails = null;

  override entityType = EntityTypeEnum.LOCATIONS;

  override attachmentTargetType = AttachmentTargetEnum.LOCATION;

  public isLoadingShares = signal(false);

  organisationOptions: ISelectOption[] = [];

  allOrganisations: IOrganisation[] = [];

  allLocationTypes: ILocationType[] = [];

  allCountries: ISelectOption[] = [];

  readonly attachmentTargetEnum = AttachmentTargetEnum;

  rulesetsRecords: IRecordResponse[];

  readonly resourceTypeEnum = ResourceTypeEnum;

  protected readonly cardContentTypeEnum = CardContentTypeEnum;

  private organisationAttachment: IAttachment = null;

  private setGeoJsonWithCoordinatesPointSubject = new Subject();

  public readonly isCrossOrgSharingEnabled = this.featureFlagService.isEnabled(
    FeatureFlagEnum.CROSS_ORGANISATION_SHARING,
  );

  public readonly isAccountOwnerOrAdminOrContributor =
    this.authenticationService.isAccountOwnerOrAdminOrContributor();

  constructor(
    private locationsService: LocationsService,
    private attachmentsService: AttachmentsService,
    private connectionsService: ConnectionsService,
    private commonService: CommonService,
    private organisationsService: OrganisationsService,
    private rulesetsService: RulesetsService,
    public locationOverlay: LocationOverlayService,
    public analysesTableService: AnalysesTableService,
    private featureFlagService: FeatureFlagService,
    private notificationService: NotificationService,
  ) {
    super();

    this.subscriptions.add(
      this.commonService.countriesOptionsObservable$.subscribe(
        (countriesOptions: ISelectOption[]) => {
          this.allCountries = countriesOptions;
        },
      ),
    );

    this.subscriptions.add(
      this.setGeoJsonWithCoordinatesPointSubject
        .pipe(debounceTime(CommonConstants.DEBOUNCE_TIME_MS))
        .subscribe(() => {
          this.setGeoJsonWithCoordinatesPoint();
        }),
    );
  }

  override get isSubmitButtonDisabled(): boolean {
    const isGeoLocationEmpty = !this.formGroup?.controls["geoLocation"]?.value;
    const isLocationGpsViewActive = !!this.locationGpsView;

    if (!this.isEditing() || (isLocationGpsViewActive && isGeoLocationEmpty)) {
      return false;
    }

    return this.formGroup?.invalid || this.formGroup?.pending || !this.hasFormValuesChanged;
  }

  async ngOnInit(): Promise<void> {
    this.overlay.showLoading();
    if (!this.isOnCorrectOverlay(RoutingEnum.OVERLAY_LOCATION)) {
      return;
    }

    await Promise.all([
      this.reloadOrganisations(),
      this.setAllCustomFields(CustomFieldsResourceTypeEnum.LOCATION),
      this.loadLocationTypes(),
    ]);

    this.setupForm();

    if (this.recordId) {
      this.setCountersToLoadingState();
      await this.reloadElement(this.recordId);
      await this.setMenuItemFromURLParam();
      this.loadCounters();
    }

    this.overlay.dismissLoading();
  }

  override async save(): Promise<boolean> {
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);
      this.notificationService.showError(CommonConstants.FILL_REQUIRED_FIELDS_MSG);

      return false;
    }

    const payload = await this.getSavePayload();

    try {
      this.element = await this.locationsService.createOrUpdate(payload, this.element?.id);
      this.analysesTableService.setLocationId(this.element.id);
      this.analysesTableService.setColumnDefs();
      this.hasFormValuesChanged = false;
      await this.updateOrganisationAttachment();
      this.notification.showSuccess(`Location ${this.isEditing() ? "modified" : "created"}`);

      return true;
    } catch (error) {
      this.notification.showError(error);

      return false;
    }
  }

  override async afterSave(isSaveOnly?: boolean): Promise<void> {
    if (isSaveOnly && !this.isEditing()) {
      await this.routerService.navigate(this.routerService.getLocationLink(this.element.id), {
        replaceUrl: true,
      });
    }
    if (this.isEditing() && this.shouldUpdateTable) {
      this.loadDocuments();
    }
  }

  reloadOrganisations = async (autoSelectOrganisationId?: string): Promise<void> => {
    this.organisationOptions = [];

    await this.connectionsService
      .getAll()
      .then(async (response: IOrganisation[]) => {
        const selectedOrganisationId = this.organisationAttachment?.targetUri
          ? CommonUtils.getUriId(this.organisationAttachment.targetUri)
          : null;

        this.allOrganisations = response.filter(
          (o) =>
            o.recordState === RecordStateEnum.ACTIVE ||
            (selectedOrganisationId && o.id === selectedOrganisationId),
        );

        await this.setOrganisationOptions(
          this.allOrganisations.map(
            (c: IOrganisation) => <ISelectOption>{ label: c.name, value: c.id },
          ),
        );
        if (autoSelectOrganisationId) {
          this.selectOrganisation(autoSelectOrganisationId);
        }
      })
      .catch((error) => {
        this.notification.showError(error);
      });
  };

  async setRulesetRecords(): Promise<void> {
    if (!this.isRegularUser) {
      this.rulesetsRecords = [];

      return;
    }
    try {
      this.rulesetsRecords = await this.rulesetsService.getInstantRulesetRecords(
        `/organisations/${this.activeOrganisationId}/locations/${this.element.id}`,
      );
    } catch (error) {
      this.notification.showError(error);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.setCountersEmptyState();
  }

  loadCounters(): void {
    if (this.recordId) {
      this.loadLinkedLocations();
      this.loadDocuments();
      this.loadCertificates();
      this.loadAnalyses();
      this.loadShares(true);
      this.loadComments();
    }
  }

  setCountersToLoadingState() {
    this.locationOverlay.setCountersToLoadingState();
  }

  setCountersEmptyState() {
    this.locationOverlay.setCountersEmptyState();
  }

  override setupForm = (): void => {
    let countryValue = null;
    let organisationValue = null;
    const types: InputSelectOption[] = [];

    if (this.isEditing()) {
      if (this.element.address?.country) {
        countryValue = this.allCountries.find((c) => c.value === this.element?.address?.country);
      }
      if (this.organisationAttachment) {
        const organisationId = CommonUtils.getUriId(this.organisationAttachment.targetUri);

        organisationValue = this.organisationOptions.find((c) => c.value === organisationId);
      }

      for (const typeUri of this.element.types) {
        const typeId = CommonUtils.getUriId(typeUri);
        const type = this.allLocationTypes.find((t) => t.id === typeId);

        types.push({
          label: type.id,
          value: type.id,
          icon: type.pointOfOrigin ? "target" : undefined,
          iconTooltip: type.pointOfOrigin ? CommonConstants.POINT_OF_ORIGIN_CHIP_TEXT : undefined,
        });
      }
    }

    this.formGroup = this.formBuilder.group({
      organisation: [organisationValue ?? null, [CustomValidators.required]],
      name: [
        this.element?.name ?? null,
        [CustomValidators.required],
        [
          CustomValidators.entityAlreadyExists(
            this.locationsService,
            this.element?.id ?? this.route.snapshot.params["id"] ?? null,
          ),
        ],
      ],
      street: [this.element?.address?.street ?? null],
      sameAsOrganisationAddress: [false],
      region: [this.element?.address?.region ?? null],
      zipCode: [this.element?.address?.zipCode ?? null],
      country: [countryValue, [CustomValidators.required]],
      geoLocation: [this.element?.geoLocation ?? null],
      gpsX: [null, [CustomValidators.latitude]],
      gpsY: [null, [CustomValidators.longitude]],
      types: [types, [CustomValidators.required]],
    });
    this.visibleCustomFields = CustomFieldsUtils.getVisible(
      this.allCustomFields,
      this.element?.customFields,
    );
    CustomFieldsUtils.addToFormGroup(
      this.formGroup,
      this.visibleCustomFields,
      this.element?.customFields,
    );

    if (this.isEditing() && GeoJSONUtils.isPoint(this.element?.geoLocation)) {
      this.formGroup.controls["gpsX"].setValue(
        this.element.geoLocation.features[0].geometry.coordinates[1],
        {
          emitEvent: false,
        },
      );
      this.formGroup.controls["gpsY"].setValue(
        this.element.geoLocation.features[0].geometry.coordinates[0],
        {
          emitEvent: false,
        },
      );
      FormUtils.setControlsRequiredValidator(this.formGroup, ["gpsX", "gpsY"]);
    }

    //todo do not add subscriptions on setupForm (as this is called after saving..)

    this.subscriptions.add(
      this.formGroup.controls["gpsX"].valueChanges.subscribe(() => {
        this.setGeoJsonWithCoordinatesPointSubject.next(true);
      }),
    );
    this.subscriptions.add(
      this.formGroup.controls["gpsY"].valueChanges.subscribe(() => {
        this.setGeoJsonWithCoordinatesPointSubject.next(true);
      }),
    );

    this.initialFormValue = this.formGroup.value;
    this.hasFormValuesChanged = false;
    this.subscriptions.add(
      this.formGroup.valueChanges.subscribe(() => {
        this.hasFormValuesChanged = this.hasInitialFormValueChanged(this.formGroup.value);
      }),
    );
  };

  confirmShareDialog() {
    this.locationOverlay
      .shareLocation(this.activeOrganisationId, this.element.id)
      .subscribe(async (result) => {
        if (result?.hasSaved) {
          await this.loadShares();
          this.changeDetectorRef.detectChanges();
          await this.changeMenuItem(OverlayTabEnum.SHARES);
        }
      });
  }

  confirmSendDialog() {
    this.locationOverlay.sendLocation(this.activeOrganisationId, this.element.id);
  }

  async loadShares(isFirstLoad = false): Promise<void> {
    if (!this.recordId || !this.isRegularUser) {
      return;
    }

    this.isLoadingShares.set(true);
    await this.locationOverlay.loadShares(this.element.id);
    this.isLoadingShares.set(false);

    if (
      isFirstLoad &&
      this.tabQueryParam &&
      CommonUtils.enumToText(this.tabQueryParam) === OverlayTabEnum.SHARES &&
      this.locationOverlay.shareCounter()
    ) {
      this.changeDetectorRef.detectChanges();
      await this.setMenuItemFromURLParam();
    }
  }

  loadAnalyses(): void {
    this.analysesTableService.getAll();
  }

  loadLinkedLocations(): void {
    this.locationOverlay.loadLinkedLocationCounter(this.element.id);
  }

  loadDocuments(): void {
    this.locationOverlay.loadDocumentCounter(this.element.id, this.attachmentTargetType);
  }

  loadCertificates(): void {
    this.locationOverlay.loadCertificateCounter(this.element.id, this.attachmentTargetType);
  }

  loadComments(): void {
    this.locationOverlay.loadCommentCounter(this.entityUri);
  }

  public async onShareDeleted(): Promise<void> {
    await this.loadShares();

    if (!this.locationOverlay.shares().length) {
      await this.slideOverlayContent.selectDefaultMenuItem();
    }
  }

  async loadLocationTypes(): Promise<void> {
    this.allLocationTypes = await this.locationOverlay.loadLocationTypes();
  }

  protected override async deleteRecord(id: string): Promise<void> {
    await this.locationsService.delete(id);
  }

  protected override async archiveRecord(id: string, payload: IRecordState): Promise<void> {
    await this.locationsService.setRecordState(payload, id);
  }

  protected override async reloadElement(id: string): Promise<void> {
    this.overlay.showLoading();

    await this.locationsService
      .get(id)
      .then(async (element: ILocationDetails) => {
        // if (element.recordState === RecordStateEnum.ARCHIVED) {
        //     this.notification.showError("Editing an archived record is not allowed");
        //     // await this.router.navigate([`/${RoutingEnum.LOCATIONS_DETAILS}/${element.id}`]);
        //     return;
        // }
        this.element = element;
        this.analysesTableService.setLocationId(this.element.id);
        this.analysesTableService.setColumnDefs();
        this.setEditMode();
        this.organisationAttachment = await this.locationOverlay.getOrganisationAttachment(
          this.activeOrganisationId,
          this.element.id,
        );
        await this.reloadOrganisations();
        this.setupForm();
        this.overlay.dismissLoading();
      })
      .catch((error) => {
        this.notification.showError(error);
      });
    if (this.isEditing() && this.isRegularUser) {
      await this.setRulesetRecords();
    }
  }

  private async getSavePayload(): Promise<ILocationPayload> {
    const unsavedTypes = this.formGroup.get("types").value.filter((item) => !item.value);

    if (unsavedTypes.length) {
      for (let i = 0; i < unsavedTypes.length; i++) {
        const item = unsavedTypes[i];

        await this.informationView.locationTypesInputChips.createTag(item, true);
      }
    }

    let geoLocation = this.formGroup.controls["geoLocation"].value;

    if (
      geoLocation?.type !== "FeatureCollection" &&
      geoLocation?.geometry?.type === GeoJsonTypeEnum.MULTI_POLYGON
    ) {
      // Wrap the MultiPolygon in a FeatureCollection type, or the backend will return a bad request.
      geoLocation = {
        type: "FeatureCollection",
        features: [geoLocation],
      };
    }

    const payload = {
      id: this.element?.id ?? undefined,
      name: this.formGroup.controls["name"].value.trim(),
      types: this.formGroup
        .get("types")
        .value.map(
          (locationType: InputSelectOption) =>
            `/organisations/${this.activeOrganisationId}/location-types/${locationType.value}`,
        ),
      address: {
        street: this.formGroup.controls["street"].value,
        region: this.formGroup.controls["region"].value,
        zipCode: this.formGroup.controls["zipCode"].value,
        country: this.formGroup.controls["country"].value.value,
      },
      geoLocation,
    };

    CustomFieldsUtils.addToPayload(
      payload,
      this.activeOrganisationId,
      this.formGroup,
      this.visibleCustomFields,
    );

    return payload;
  }

  private async updateOrganisationAttachment(): Promise<void> {
    if (!this.organisationAttachment) {
      await this.createOrganisationAttachment();

      return;
    }

    const currentOrganisationId = CommonUtils.getUriId(this.organisationAttachment.targetUri);
    const newOrganisationId = this.formGroup.controls["organisation"].value.value;

    if (currentOrganisationId !== newOrganisationId) {
      await this.attachmentsService.delete(this.organisationAttachment.id);
      await this.createOrganisationAttachment();
    }
  }

  private isLoggedInUserOrganisation(): boolean {
    return this.formGroup.controls["organisation"].value.value === this.activeOrganisationId;
  }

  private createOrganisationAttachment = async (): Promise<void> => {
    const payload: IAttachmentPayload = {
      targetUri: this.isLoggedInUserOrganisation()
        ? `/organisations/${this.activeOrganisationId}`
        : `/organisations/${this.activeOrganisationId}/connections/${this.formGroup.controls["organisation"].value.value}`,
      attachmentUri: `/organisations/${this.activeOrganisationId}/locations/${this.element.id}`,
    };

    await this.attachmentsService
      .create(payload)
      .then(async (response: IAttachment) => {
        this.organisationAttachment = response;
      })
      .catch((error: HttpErrorResponse) => {
        this.notification.showError(error);
      });
  };

  private selectOrganisation = (organisationId: string): void => {
    const organisation = this.organisationOptions.find((o) => o.value === organisationId);

    if (organisation) {
      this.formGroup.controls["organisation"].setValue(organisation);
    }
  };

  private async setOrganisationOptions(
    organisationOptionsToAdd: ISelectOption[] = null,
  ): Promise<void> {
    this.organisationOptions = [];

    if (this.isRegularUser) {
      const loggedInUserOrganisation = await this.organisationsService.get(
        this.activeOrganisationId,
      );

      this.organisationOptions.push({
        label: loggedInUserOrganisation.name,
        value: loggedInUserOrganisation.id,
      });
    }
    if (organisationOptionsToAdd?.length) {
      this.organisationOptions.push(...organisationOptionsToAdd);
    }

    this.organisationOptions = [...this.organisationOptions]; // to trigger onchanges
  }

  private setGeoJsonWithCoordinatesPoint(): void {
    const gpsX = this.formGroup.controls["gpsX"].value
      ? this.formGroup.controls["gpsX"].value.trim()
      : null;
    const gpsY = this.formGroup.controls["gpsY"].value
      ? this.formGroup.controls["gpsY"].value.trim()
      : null;

    const areBothCoordinatesFilled = gpsX !== null && gpsY !== null;
    const isSomeCoordinateFilled = gpsX !== null || gpsY !== null;

    FormUtils.setControlsRequiredValidator(
      this.formGroup,
      ["gpsX", "gpsY"],
      isSomeCoordinateFilled,
      false,
    );

    if (!areBothCoordinatesFilled) {
      return;
    }

    if (GeoJSONUtils.isValidCoordinates(gpsX, gpsY)) {
      this.formGroup.controls["geoLocation"].setValue(
        GeoJSONUtils.getObject(GeoJsonTypeEnum.POINT, [+gpsY, +gpsX]),
        { emitEvent: false },
      );
    } else {
      this.formGroup.controls["geoLocation"].setValue(null, { emitEvent: false });
    }
  }
}
