import { ChangeDetectionStrategy, Component, Input, input } from "@angular/core";

import { BrowserUtils } from "@shared/utils";

@Component({
  selector: "iov-form-field-group",
  templateUrl: "./form-field-group.component.html",
  styleUrls: ["./form-field-group.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldGroupComponent {
  name = input.required<string>();

  public readonly isFirefox: boolean = BrowserUtils.isFirefox();

  @Input() class: string;

  @Input() groupNameRowClass: string;
}
