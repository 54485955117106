<div class="container-space-between">
  <app-page-title title="Admin"></app-page-title>
</div>

@if (isSystemAdmin) {
  <h2>System definitions</h2>

  <ng-container
    [ngTemplateOutlet]="settingsTemplate"
    [ngTemplateOutletContext]="{ settings: operationsGeneralSettings }"
  ></ng-container>
} @else {
  <h2>Operations</h2>

  <h3>General</h3>
  <ng-container
    [ngTemplateOutlet]="settingsTemplate"
    [ngTemplateOutletContext]="{ settings: operationsGeneralSettings }"
  ></ng-container>

  @if (operationsRiskAssessmentSettings.length) {
    <h3>Risk assessment</h3>
    <ng-container
      [ngTemplateOutlet]="settingsTemplate"
      [ngTemplateOutletContext]="{ settings: operationsRiskAssessmentSettings }"
    />
  }

  <ng-container *ngIf="operationsUserSettings?.length">
    <h3>Memberships</h3>
    <ng-container
      [ngTemplateOutlet]="settingsTemplate"
      [ngTemplateOutletContext]="{ settings: operationsUserSettings }"
    ></ng-container>
  </ng-container>

  <ng-container *ngIf="operationsExtensionsSettings?.length">
    <h3>Extensions</h3>
    <ng-container
      [ngTemplateOutlet]="settingsTemplate"
      [ngTemplateOutletContext]="{ settings: operationsExtensionsSettings }"
    ></ng-container>
  </ng-container>

  <ng-container *ngIf="operationsDataSharingSettings?.length">
    <h3>Data sharing</h3>
    <ng-container
      [ngTemplateOutlet]="settingsTemplate"
      [ngTemplateOutletContext]="{ settings: operationsDataSharingSettings }"
    ></ng-container>
  </ng-container>
}
<ng-template #settingsTemplate let-settings="settings">
  <div class="settings-container flex-wrap">
    @for (setting of settings; track $index) {
      <iov-settings-card (pressed)="onGoToRoute(setting.route)" [setting]="setting" />
    }
  </div>
</ng-template>

@if (isShowEnvironment) {
  <div class="environment">{{ featureFlagService.getEnvName() }}</div>
}
