import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  input,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  OnChanges,
} from "@angular/core";

import { AgGridAngular } from "ag-grid-angular";
import {
  ColDef,
  DomLayoutType,
  GridState,
  IRowNode,
  RowClassRules,
  RowClickedEvent,
  SelectionChangedEvent,
  StateUpdatedEvent,
} from "ag-grid-community";
import { debounceTime, Subject, Subscription } from "rxjs";
import { CommonConstants } from "src/app/shared/constants";
import { RecordStateEnum, TableEnum } from "src/app/shared/enums";
import { LocalizationService, LocalStorageService } from "src/app/shared/services";

import { CommonUtils } from "@shared/utils";

import { TableTooltip } from "./table-tooltip";

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  public table: TableEnum;

  @Input()
  public class: string;

  public isLoading = input<boolean>(true);

  public rowData = input<any[]>([]);

  @Input()
  public columnDefs: any = [];

  @Input()
  public areButtonsEnabled: boolean = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isRecordStateFilterEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public masterDetail: boolean = false;

  @Input()
  public detailCellRendererParams: any;

  @Input()
  public detailCellRenderer: any;

  @Input()
  public getRowClass: (params: any) => string | string[];

  @Input()
  public autoExpandDetailGrids: boolean = false;

  @Input()
  public isFixedBottomPaginator = false;

  @Input()
  public getGridHeightFn: any;

  @Input()
  public isSaveState = false;

  @Input()
  public isSaveSearch = false;

  @Input()
  public recordState: RecordStateEnum = RecordStateEnum.ALL;

  @Input()
  public getRowHeight;

  @Input()
  public emptyResultsTemplate: TemplateRef<unknown>;

  @Output()
  public rowClick: EventEmitter<any> = new EventEmitter();

  @Output()
  public selectionChanged: EventEmitter<any[]> = new EventEmitter();

  @Output()
  public rowsVisibleUpdated: EventEmitter<IRowNode<any>[]> = new EventEmitter<IRowNode<any>[]>();

  @ViewChild("grid") public grid: AgGridAngular;

  public readonly tableAvailablePageSizes = CommonConstants.TABLES_AVAILABLE_PAGE_SIZES;

  public readonly initialPageSize: number = CommonConstants.TABLES_AVAILABLE_PAGE_SIZES[0];

  public readonly overlayNoRowsTemplate =
    "<div class='content no-results-found'><img src='/assets/images/no-results-found.svg' height='100' alt='No records found' /><div class='title'>No records found</div></div>";

  public readonly popupParent: HTMLElement | null = document.querySelector("body");

  public localeText: any = null;

  public rowClassRules: RowClassRules = null;

  public readonly defaultColDef: ColDef = {
    minWidth: 100,
    sortable: true,
    resizable: true,
    enableRowGroup: true,
    enableValue: true,
    enablePivot: true,
    filter: true,
    filterParams: { newRowsAction: "keep" },
    tooltipComponent: TableTooltip,
    mainMenuItems: (params: any) => {
      const resetColumnsIndex = params.defaultItems.findIndex((i) => i === "resetColumns");

      if (resetColumnsIndex !== -1) {
        params.defaultItems[resetColumnsIndex] = {
          name: "Reset Columns",
          action: () => {
            this.grid.api.resetColumnState();
            this.autoSizeGridSubject.next(true);
          },
        };
      }

      return params.defaultItems;
    },
  };

  public initialState: GridState;

  public searchText: string = null;

  public domLayout: DomLayoutType = "autoHeight";

  public heightStyle = "";

  @ViewChild("grid", { read: ElementRef }) private gridHtml: ElementRef<HTMLElement>;

  private readonly eventsToIgnoreForSaveState = [
    "gridInitializing",
    "columnSizing",
    "focusedCell",
    "scroll",
  ];

  private readonly eventsToIgnoreForRowsVisible = [
    ...this.eventsToIgnoreForSaveState,
    "rowGroupExpansion",
    "columnPinning",
    "columnOrder",
  ];

  private readonly eventsToTriggerSelectionChanged = ["uiSelectAllFiltered", "checkboxSelected"]; // only user using UI triggered events

  private readonly searchLocalStorageSuffix = "-search";

  private readonly keyLocalStoragePreffix = "table-";

  private currentState: GridState = undefined;

  private saveStateSubject = new Subject();

  private autoSizeGridSubject = new Subject();

  private setGridHeightSubject = new Subject();

  private showHideNoRecordsSubject = new Subject();

  private renderedRowsSubject = new Subject();

  private subscriptions = new Subscription();

  constructor(
    private localStorageService: LocalStorageService,
    private localizationService: LocalizationService,
  ) {
    this.subscriptions.add(
      this.localizationService.agGridLocaleObservable$.subscribe((agGridLocale: any) => {
        this.localeText = agGridLocale;
      }),
    );

    this.subscriptions.add(
      this.setGridHeightSubject
        .pipe(debounceTime(CommonConstants.DEBOUNCE_TABLE_SET_HEIGHT_TIME_MS))
        .subscribe(() => {
          this.setGridHeight();
        }),
    );

    this.subscriptions.add(
      this.autoSizeGridSubject
        .pipe(debounceTime(CommonConstants.DEBOUNCE_TABLE_RESIZE_TIME_MS))
        .subscribe(() => {
          this.autoSizeGrid();
        }),
    );

    this.subscriptions.add(
      this.saveStateSubject
        .pipe(debounceTime(CommonConstants.DEBOUNCE_TABLE_SAVE_STATE_TIME_MS))
        .subscribe((state: GridState) => {
          if (this.isSaveState && this.table) {
            this.localStorageService.set(
              `${this.keyLocalStoragePreffix}${this.table}`,
              JSON.stringify(state),
            );
          }
        }),
    );

    this.subscriptions.add(
      this.showHideNoRecordsSubject
        .pipe(debounceTime(CommonConstants.DEBOUNCE_TABLE_SHOW_HIDE_NO_RECORDS_TIME_MS))
        .subscribe(() => {
          this.showHideNoRecords();
        }),
    );

    this.subscriptions.add(
      this.renderedRowsSubject
        .pipe(debounceTime(CommonConstants.DEBOUNCE_TABLE_SHOW_HIDE_NO_RECORDS_TIME_MS))
        .subscribe((rows: IRowNode<any>[]) => {
          this.rowsVisibleUpdated.emit(rows);
        }),
    );
  }

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.setGridHeightSubject.next(true);
    this.autoSizeGridSubject.next(true);
  }

  public ngOnInit(): void {
    this.setInitialState();

    if (this.areButtonsEnabled) {
      this.rowClassRules = {
        clickable: () => this.areButtonsEnabled && this.rowClick.observed,
      };
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes["rowData"] && !changes["rowData"].firstChange) {
      this.initialState = this.currentState;
      this.setGridHeightSubject.next(true);
      this.autoSizeGridSubject.next(true);
    }
  }

  public onGridReady = async (): Promise<void> => {
    this.grid.api["autoSizeGridSubject"] = this.autoSizeGridSubject;
    this.setGridHeightSubject.next(true);
    this.autoSizeGridSubject.next(true);
  };

  public onFirstDataRendered = async (): Promise<void> => {
    this.setNodesSelected();

    if (this.autoExpandDetailGrids) {
      this.grid.api.forEachNode((node) => node.setExpanded(true));
    }

    this.autoSizeGridSubject.next(true);
  };

  public onGridSizeChanged = (): void => {
    this.autoSizeGridSubject.next(true);
  };

  public onSelectionChanged = (event: SelectionChangedEvent): void => {
    if (this.eventsToTriggerSelectionChanged.includes(event.source)) {
      const selectedRowsIds = this.grid.api.getSelectedRows().map((r) => r.id);
      const rowData = this.rowData().map((r) => ({
        ...r,
        isSelected: selectedRowsIds.some((id) => id === r.id),
      }));

      this.selectionChanged.emit(rowData);
    }
  };

  public onPaginationChanged = (): void => {
    this.renderedRowsSubject.next(this.grid.api.getRenderedNodes());
  };

  public onRowClicked = (event: RowClickedEvent): void => {
    if (!this.areButtonsEnabled || !event.data || event.node?.detail) {
      return;
    }

    this.rowClick.emit(event.data);
  };

  public onSearchChanged = async (searchText: string): Promise<void> => {
    this.searchText = searchText;
    if (!this.grid) {
      return;
    }
    this.grid.api.setGridOption("quickFilterText", searchText);
    if (this.isSaveSearch && this.table) {
      if (searchText) {
        this.localStorageService.set(
          `${this.keyLocalStoragePreffix}${this.table}${this.searchLocalStorageSuffix}`,
          searchText,
        );
      } else {
        this.localStorageService.remove(
          `${this.keyLocalStoragePreffix}${this.table}${this.searchLocalStorageSuffix}`,
        );
      }
    }
    this.renderedRowsSubject.next(this.grid.api.getRenderedNodes());
    this.showHideNoRecordsSubject.next(true);
  };

  public onRecordStateChanged = async (recordState: RecordStateEnum): Promise<void> => {
    if (!this.grid) {
      return;
    }

    this.recordState = recordState ?? RecordStateEnum.ALL;

    let filterModel = null;

    if (recordState !== RecordStateEnum.ALL) {
      filterModel = {
        filterType: "text",
        type: "contains",
        filter: recordState,
      };
    }
    const { api } = this.grid;

    await api.setColumnFilterModel("recordState", filterModel);
    api.onFilterChanged();
    this.showHideNoRecordsSubject.next(true);
  };

  public onStateUpdated = (event: StateUpdatedEvent): void => {
    this.currentState = event.state;
    this.showHideNoRecordsSubject.next(true);

    const eventSource = event.sources[0];

    if (!this.eventsToIgnoreForRowsVisible.includes(eventSource)) {
      this.renderedRowsSubject.next(this.grid.api.getRenderedNodes());
    }

    if (this.isSaveState && !this.eventsToIgnoreForSaveState.includes(eventSource)) {
      this.saveStateSubject.next(event.state);
    }
  };

  public onColumnRowGroupChanged = (): void => {
    this.autoSizeGridSubject.next(true);
  };

  public onBottomPaginationPageSizeChanged = (pageSize: number): void => {
    this.grid.api.setGridOption("paginationPageSize", +pageSize);
  };

  public getFirstElementShownNumber = (): number =>
    this.grid.api.paginationGetPageSize() * this.grid.api.paginationGetCurrentPage() +
    (this.grid.api.paginationGetRowCount() ? 1 : 0);

  public geLastElementShownNumber = (): number =>
    Math.min(
      this.grid.api.paginationGetRowCount(),
      this.grid.api.paginationGetPageSize() * (this.grid.api.paginationGetCurrentPage() + 1),
    );

  public canGoToPrevPage = (): boolean => this.grid.api.paginationGetCurrentPage() > 0;

  public canGoToNextPage = (): boolean =>
    this.grid.api.paginationGetCurrentPage() + 1 < this.grid.api.paginationGetTotalPages();

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setNodesSelected = (): void => {
    if (!this.grid) {
      return;
    }
    const nodesToSelect: IRowNode[] = [];

    this.grid.api.forEachNode((node: IRowNode) => {
      if (node.data?.isSelected) {
        nodesToSelect.push(node);
      }
    });
    this.grid.api.setNodesSelected({ nodes: nodesToSelect, newValue: true });
  };

  private setInitialState = (): void => {
    this.initialState = {};

    if (this.isRecordStateFilterEnabled && this.recordState !== RecordStateEnum.ALL) {
      this.initialState = {
        ...this.initialState,
        filter: {
          filterModel: {
            recordState: {
              filterType: "text",
              type: "contains",
              filter: this.recordState,
            },
          },
        },
      };
    }

    if (this.isSaveState && this.table) {
      const storedStateString = this.localStorageService.get(
        `${this.keyLocalStoragePreffix}${this.table}`,
      );

      if (storedStateString) {
        const storedStateJson = JSON.parse(storedStateString);

        this.initialState = {
          ...storedStateJson,
          pagination: { ...this.initialState.pagination, page: 0 },
          scroll: null,
        };
        if (this.isRecordStateFilterEnabled) {
          const recordStateFilter = this.initialState?.filter?.filterModel["recordState"];

          this.recordState = recordStateFilter?.filter ?? RecordStateEnum.ALL;
        }
      }
      if (this.isSearchEnabled && this.isSaveSearch) {
        const storedSearch = this.localStorageService.get(
          `${this.keyLocalStoragePreffix}${this.table}${this.searchLocalStorageSuffix}`,
        );

        if (storedSearch) {
          this.searchText = storedSearch;
        }
      }
    }

    this.currentState = { ...this.initialState };
  };

  private showHideNoRecords = (): void => {
    const hasVisibleRows = !!this.grid.api.getDisplayedRowCount();

    if (hasVisibleRows) {
      this.grid.api.hideOverlay();
    } else {
      this.grid.api.showNoRowsOverlay();
    }
  };

  private autoSizeGrid = (): void => {
    if (!this.grid) {
      return;
    }

    this.grid.api.autoSizeAllColumns();
    const firstRow = this.gridHtml.nativeElement.querySelector(".ag-row") as HTMLElement;
    const gridWidth = this.gridHtml.nativeElement.offsetWidth;

    if (!firstRow || firstRow.offsetWidth < gridWidth) {
      this.grid.api.sizeColumnsToFit();
    }
  };

  private setGridHeight = (): void => {
    if (this.isFixedBottomPaginator || this.getGridHeightFn) {
      this.domLayout = "normal";
      this.heightStyle = `${this.getGridHeightFn ? this.getGridHeightFn() : CommonUtils.getOverviewPageHeight()}px`;
      this.autoSizeGridSubject.next(true);
    }
  };
}
