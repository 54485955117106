@if (isLoading()) {
  <app-loader></app-loader>
} @else {
  <form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
    <mat-dialog-content>
      <div class="row-container">
        <div class="row row-1">
          <div class="item">
            @switch (fieldType) {
              @case (fieldTypeEnum.INPUT) {
                <iov-input
                  [formControlName]="formControlName"
                  [label]="label"
                  [type]="params.inputType"
                  [suffix]="params.suffix"
                  [hint]="params.hint"
                ></iov-input>
              }
              @case (fieldTypeEnum.SELECT) {
                <iov-input-select
                  class="no-padding"
                  [options]="params.options"
                  [label]="label"
                  [tooltip]="params.tooltip"
                  [formControlName]="formControlName"
                ></iov-input-select>
              }

              @case (fieldTypeEnum.CHIPS) {
                <iov-input-chips
                  inputOnlyMode
                  [label]="label"
                  [allowCreateTag]="false"
                  [allowDeleteTag]="false"
                  [options]="params.options"
                  [formControlName]="formControlName"
                ></iov-input-chips>
              }
              @case (fieldTypeEnum.TAGS) {
                <iov-form-label [label]="label"></iov-form-label>
                <app-entity-tags
                  [initialTags]="params.initialTags"
                  [entityType]="entityTypeEnum.ITEMS"
                  (tagsChanged)="onTagsChanged($event)"
                ></app-entity-tags>
              }
              @case (fieldTypeEnum.DATE_PICKER) {
                <div>
                  @if (
                    formGroup.controls[params.dateTypeFormControlName].value === dateTypeEnum.EXACT
                  ) {
                    <iov-datepicker
                      [label]="label"
                      class="no-padding"
                      [formControlName]="params.fromFormControlName"
                    ></iov-datepicker>
                  } @else if (
                    formGroup.controls[params.dateTypeFormControlName].value === dateTypeEnum.RANGE
                  ) {
                    <iov-daterangepicker
                      [label]="label"
                      class="no-padding"
                      [formControlName]="params.rangeFormControlName"
                    ></iov-daterangepicker>
                  }

                  <iov-checkbox
                    [formControlName]="params.isRangeDateTypeFormControlName"
                    label="Range (from/to)"
                  ></iov-checkbox>
                </div>
              }
            }
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <iov-button-group>
        <iov-button (pressed)="onClose()" type="ghost">Cancel</iov-button>
        <iov-button [disabled]="isSubmitButtonDisabled" accessibilityRole="submit">
          <mat-icon icon-left>check</mat-icon>
          Save
        </iov-button>
      </iov-button-group>
    </mat-dialog-actions>
  </form>
}
