<form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
  <h2 mat-dialog-title>Add material</h2>

  <app-loader *ngIf="isLoading()" containerClass="on-dialog"></app-loader>

  <ng-container *ngIf="!isLoading()">
    <mat-dialog-content>
      @if (allowModeChange) {
        <div class="mode-radio-buttons">
          <iov-radio-button-group>
            <iov-radio-button
              (changeEvent)="onChangeType(modeEnum.ADD_NEW)"
              [checked]="mode === modeEnum.ADD_NEW"
              label="Add new"
              [value]="modeEnum.ADD_NEW"
            ></iov-radio-button>
            &nbsp;
            <iov-radio-button
              (changeEvent)="onChangeType(modeEnum.ADD_EXISTING)"
              [checked]="mode === modeEnum.ADD_EXISTING"
              label="Add existing"
              tpPlacement="right"
              [value]="modeEnum.ADD_EXISTING"
            ></iov-radio-button>
          </iov-radio-button-group>
        </div>
      }

      @if (mode === modeEnum.ADD_NEW) {
        <iov-input [autofocus]="!isLoading()" formControlName="name" label="Name"></iov-input>

        <iov-input-select
          [options]="categoriesOptions"
          [addOnWrite]="true"
          formControlName="category"
          hint="E.g. tree species, alloys, meat, etc."
          label="Category"
        ></iov-input-select>

        @if (visibleCustomFields?.length) {
          <app-custom-fields-edit
            [columns]="1"
            [elementCustomFields]="[]"
            [formGroup]="formGroup"
            [enableViewMode]="true"
            [visibleCustomFields]="visibleCustomFields"
          ></app-custom-fields-edit>
        }
      } @else if (mode === modeEnum.ADD_EXISTING) {
        <iov-input-select
          [options]="materialOptions"
          formControlName="existingMaterial"
          label="Material"
        ></iov-input-select>
      }
    </mat-dialog-content>

    <mat-dialog-actions>
      <iov-button-group>
        <iov-button (pressed)="onClose()" type="ghost">Cancel</iov-button>
        <iov-button [disabled]="isSubmitButtonDisabled" accessibilityRole="submit">
          <mat-icon icon-left>check</mat-icon>
          {{ saveButtonText }}
        </iov-button>
      </iov-button-group>
    </mat-dialog-actions>
  </ng-container>
</form>
