import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  AfterViewInit,
  ChangeDetectionStrategy,
  signal,
} from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

import { ColDef, ValueGetterParams } from "ag-grid-community";
import { clone } from "lodash";

import { SlideOverlayContentComponent } from "@design-makeover/components/overlay/slide-overlay-content/slide-overlay-content.component";
import { SlideOverlayPageClass } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.class";
import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { AddMaterialDialogComponent } from "@components/settings/settings-materials";
import { ProductOverlayService } from "@components/settings/settings-products/pages/product-overlay/product-overlay.service";
import { RelatedProductsComponent } from "@components/settings/settings-products/related-products/related-products.component";
import { EditCustomUnitDialogComponent } from "@components/settings/settings-units-of-measurement";
import {
  ConfirmDialogComponent,
  OverlayCertificateAttachmentsComponent,
  OverlayDocumentAttachmentsComponent,
} from "@components/shared";
import { QuickActionsMenuComponent } from "@shared/cell-renderers";
import { CommonConstants } from "@shared/constants";
import {
  AttachmentTargetEnum,
  AttachmentTypeEnum,
  ConfirmDialogResponseEnum,
  CustomFieldsResourceTypeEnum,
  EntityTypeEnum,
  FeatureFlagEnum,
  OverlayTabEnum,
  RecordStateEnum,
  RoutingEnum,
  UnitOfMeasurementCategoryTypeEnum,
} from "@shared/enums";
import {
  IAttachment,
  IBaseUnit,
  ICustomEvent,
  ICustomUnitOfMeasurement,
  IMaterial,
  IPageableContent,
  IProductDetails,
  IProductPayload,
  IRecordState,
  ISelectOption,
} from "@shared/interfaces";
import {
  FeatureFlagService,
  MaterialsService,
  ProductsService,
  UnitsOfMeasurementService,
} from "@shared/services";
import { CellRendererUtils, CommonUtils, CustomFieldsUtils, FormUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

@Component({
  selector: "app-product-overlay",
  templateUrl: "./product-overlay.component.html",
  styleUrl: "./product-overlay.component.scss",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ProductOverlayComponent
  extends SlideOverlayPageClass
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild("certificateSection") certificateSection: OverlayCertificateAttachmentsComponent;

  @ViewChild("documentSection") documentSection: OverlayDocumentAttachmentsComponent;

  @ViewChild("relatedProductsSection") relatedProductsSection: RelatedProductsComponent;

  @ViewChild("slideOverlayContent") override slideOverlayContent: SlideOverlayContentComponent;

  public formGroup: UntypedFormGroup;

  public isLoadingSelectedMaterials = signal<boolean>(false);

  public isLoadingAvailableMaterials = signal<boolean>(true);

  public isLoadingUnitsOfMeasurement = signal<boolean>(false);

  override element: IProductDetails;

  public supplyChainHeight: number;

  public unitOfMeasurementTypesOptions = signal<ISelectOption[]>([]);

  public availableMaterials = signal<IMaterial[]>([]);

  public selectedMaterials = signal<IMaterial[]>([]);

  private attachedMaterials = signal<IMaterial[]>([]);

  private attachedMaterialsIds = signal<string[]>([]);

  private allMaterials = signal<IMaterial[]>([]);

  private selectedMaterialsIds = signal<string[]>([]);

  private initialMaterialsIds = signal<string[]>([]);

  private productInitiallyAllowedMaterials = signal<string[]>([]);

  public searchAvailableText: string;

  public relatedProductsLength: number;

  override menuItems = signal(
    new Map([
      [OverlayTabEnum.DETAILS, { isEnabled: true }],
      [OverlayTabEnum.DOCUMENTS, { isEnabled: false }],
      [OverlayTabEnum.RELATED_PRODUCTS, { isEnabled: false }],
      [OverlayTabEnum.SUPPLY_CHAIN, { isEnabled: false, isHidden: !this.isRegularUser }],
      [OverlayTabEnum.COMMENTS, { isEnabled: false, isHidden: !this.isRegularUser }],
    ]),
  );

  public readonly attachmentTypeEnum = AttachmentTypeEnum;

  public readonly attachmentTargetEnum = AttachmentTargetEnum;

  public readonly entityTypeEnum = EntityTypeEnum;

  public override entityType: EntityTypeEnum = EntityTypeEnum.PRODUCTS;

  override readonly attachmentTargetType = AttachmentTargetEnum.PRODUCT;

  public unitsOfMeasurementColDefs: ColDef[] = [];

  public systemUnitsOfMeasurement: IBaseUnit[] = [];

  private selectedSystemUnitOfMeasurement: IBaseUnit;

  public availableUnitsOfMeasurement: ICustomUnitOfMeasurement[] & IBaseUnit[] = [];

  public selectedUnitsOfMeasurement: ICustomUnitOfMeasurement[] & IBaseUnit[] = [];

  public defaultUnitOfMeasurementId: string;

  private initialDefaultUnitOfMeasurementId: string;

  private initialUnitsOfMeasurementIds: string[] = [];

  private selectedUnitsOfMeasurementIds: string[] = [];

  private materialAttachments: IAttachment[] = [];

  private lastTypeSelected: string;

  private materialsToAttach: IMaterial[] = [];

  private materialsToDettach: IMaterial[] = [];

  editMode$ = toObservable(this.overlay.editMode);

  public readonly isOldMaterialsEnabled = !this.featureFlagService.isEnabled(
    FeatureFlagEnum.NEW_MATERIALS_BEHAVIOUR,
  );

  constructor(
    public productOverlay: ProductOverlayService,
    private notificationService: NotificationService,
    private materialsService: MaterialsService,
    private productsService: ProductsService,
    private unitsOfMeasurementService: UnitsOfMeasurementService,
    private cdr: ChangeDetectorRef,
    private featureFlagService: FeatureFlagService,
  ) {
    super();

    this.subscriptions.add(
      this.editMode$.subscribe((editMode: boolean) => {
        if (!editMode) {
          this.materialsToAttach = [];
          this.materialsToDettach = [];
        }
      }),
    );
  }

  override get isSubmitButtonDisabled(): boolean {
    if (!this.isEditing()) {
      return false;
    }

    return (
      !this.formGroup ||
      this.formGroup.invalid ||
      this.formGroup.pending ||
      !this.areThereAnyChanges()
    );
  }

  private areThereAnyChanges(): boolean {
    return (
      this.hasFormValuesChanged ||
      this.areInitialMaterialsChanged() ||
      this.haveUnitsOfMeasurementChanged()
    );
  }

  loadCounters(): void {
    if (this.element.id) {
      this.loadDocuments();
      this.loadComments();
    }
  }

  setCountersToLoadingState() {
    this.productOverlay.setCountersToLoadingState();
  }

  setCountersEmptyState() {
    this.productOverlay.setCountersEmptyState();
  }

  loadDocuments(): void {
    this.productOverlay.loadDocumentCounter(this.element.id, this.attachmentTargetType);
  }

  async reloadAttachedMaterials(): Promise<void> {
    this.materialAttachments = (await this.productOverlay.loadSelectedAttachments(
      AttachmentTypeEnum.MATERIAL,
      this.attachmentTargetType,
      this.element.id,
      {},
      true,
    )) as IAttachment[];

    this.attachedMaterialsIds.set(
      this.materialAttachments.map((a) => CommonUtils.getUriId(a.attachmentUri)),
    );

    this.attachedMaterials.set(
      this.allMaterials().filter((m) => this.attachedMaterialsIds().includes(m.id)),
    );
    this.availableMaterials.set(this.getAvailableMaterials());
    this.getSelectedMaterials([]).then((materials) => {
      this.selectedMaterials.set(materials);
    });
  }

  loadComments(): void {
    this.productOverlay.loadCommentCounter(this.entityUri);
  }

  public async ngOnInit(): Promise<void> {
    this.overlay.showLoading();
    if (!this.isOnCorrectOverlay(RoutingEnum.OVERLAY_PRODUCT)) {
      return;
    }

    await Promise.all([
      this.setUnitsOfMeasurementData(),
      this.setAllCustomFields(CustomFieldsResourceTypeEnum.PRODUCT),
      this.onReloadMaterials(),
    ]);

    const recordId = this.recordId;

    if (recordId) {
      this.setCountersToLoadingState();
      await this.reloadElement(recordId);
      await this.setMenuItemFromURLParam();
      this.loadCounters();
    } else {
      this.setupForm();
    }

    this.setColumnDefsForAUnitsOfMeasurements();

    this.overlay.dismissLoading();
  }

  public ngAfterViewInit(): void {
    this.supplyChainHeight = CommonUtils.getOverlaySupplyChainHeight();
  }

  public ngOnDestroy(): void {
    this.setCountersEmptyState();
    this.subscriptions.unsubscribe();
  }

  private setColumnDefsForAUnitsOfMeasurements = (): void => {
    this.unitsOfMeasurementColDefs = [
      {
        headerName: "Name",
        field: "name",
        lockVisible: true,
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: {
          actions: [
            {
              icon: "check",
              tooltip: "Set as default",
              show: () => this.overlay.editMode(),
              disabled: (unitOfMeasurement: ICustomUnitOfMeasurement & IBaseUnit) => {
                return unitOfMeasurement.id === this.defaultUnitOfMeasurementId;
              },
              click: (unitOfMeasurement: ICustomUnitOfMeasurement & IBaseUnit) =>
                this.onSetDefaultUnitOfMeasurement(unitOfMeasurement.id),
            },
            {
              icon: "close",
              tooltip: (unitOfMeasurement: ICustomUnitOfMeasurement & IBaseUnit) => {
                if (unitOfMeasurement.id === this.selectedSystemUnitOfMeasurement.id) {
                  return "This unit of measurement cannot be removed, as it is a system unit of measurement";
                } else {
                  return "Remove";
                }
              },
              show: () => this.overlay.editMode(),
              disabled: (unitOfMeasurement: ICustomUnitOfMeasurement & IBaseUnit) => {
                return (
                  unitOfMeasurement.id === this.selectedSystemUnitOfMeasurement.id ||
                  unitOfMeasurement.id === this.defaultUnitOfMeasurementId
                );
              },
              click: (unitOfMeasurement: ICustomUnitOfMeasurement & IBaseUnit) =>
                this.onRemoveUnitOfMeasurement(unitOfMeasurement.id),
            },
          ],
        },
      },
      {
        headerName: "Default",
        field: "id",
        cellRenderer: CellRendererUtils.showIconIfValue,
        valueGetter: (params: ValueGetterParams<ICustomUnitOfMeasurement & IBaseUnit>) =>
          params.data?.id === this.defaultUnitOfMeasurementId,
      },
    ];
  };

  public async onRemoveAllowedMaterial(material: IMaterial): Promise<void> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: "Remove allowed material",
        contentTranslatedText: "Are you sure you want to remove this material from the record?",
        confirmButtonColor: "danger",
        confirmButtonTranslatedText: "Remove",
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        this.isLoadingAvailableMaterials.set(true);
        this.isLoadingSelectedMaterials.set(true);
        const itemIndex = this.selectedMaterials().findIndex((i) => i.id === material.id);

        const selectedMaterials = [...this.selectedMaterials()];

        selectedMaterials.splice(itemIndex, 1);
        this.selectedMaterials.set([...selectedMaterials]);

        const allowedMaterialsFormValue = this.formGroup.controls["allowedMaterials"].value;

        const updatedAllowedMaterialsFormValue = allowedMaterialsFormValue.filter((uri: string) => {
          const materialId = CommonUtils.getUriId(uri);

          return materialId !== material.id;
        });

        this.formGroup.controls["allowedMaterials"].setValue(updatedAllowedMaterialsFormValue);

        this.notificationService.showSuccess("Material removed");
        this.setSelectedMaterialsIds();
        await this.onReloadMaterials();
        if (!this.isOldMaterialsEnabled) {
          const index = this.materialsToAttach.findIndex((m) => m.id === material.id);

          if (index !== -1) {
            this.materialsToAttach.splice(index, 1);
          }
          this.materialsToDettach.push(material);
        }

        this.isLoadingAvailableMaterials.set(false);
        this.isLoadingSelectedMaterials.set(false);
      }
    });
  }

  private haveUnitsOfMeasurementChanged = (): boolean => {
    return this.hasDefaultUnitOfMeasurementChanged() || this.areInitialUnitsOfMeasurementChanged();
  };

  private async removeMaterialAttachment(material: IMaterial): Promise<void> {
    try {
      const attachment = this.materialAttachments.find(
        (attachment) => CommonUtils.getUriId(attachment.attachmentUri) === material.id,
      );

      if (attachment) {
        await this.productOverlay.removeAttachment(AttachmentTypeEnum.MATERIAL, attachment.id);
        await this.reloadAttachedMaterials();
        await this.onReloadMaterials();
      }
    } catch (error) {
      this.notificationService.showError(error);
    }
  }

  public onReloadMaterials = async (): Promise<void> => {
    this.isLoadingAvailableMaterials.set(true);
    await this.materialsService
      .getPage(
        this.searchAvailableText || undefined,
        CommonConstants.MAX_API_GET_ITEMS_SIZE,
        0,
        undefined,
        RecordStateEnum.ACTIVE,
      )
      .then((response: IPageableContent<IMaterial>) => {
        this.allMaterials.set(response.content);
        this.availableMaterials.set(this.getAvailableMaterials());
        this.isLoadingAvailableMaterials.set(false);
      })
      .catch((error) => {
        this.notificationService.showError(error);
      });
  };

  public onSearchAvailable = async (value: string): Promise<void> => {
    this.searchAvailableText = value;
    await this.onReloadMaterials();
  };

  public onAddAllMaterials = (): void => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: "Add all confirmation",
        contentTranslatedText: `Are you sure you want to add all ${this.availableMaterials().length} materials?`,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        this.isLoadingAvailableMaterials.set(true);
        this.isLoadingSelectedMaterials.set(true);
        this.cdr.detectChanges();

        while (this.availableMaterials().length > 0) {
          await this.onAddMaterial(this.availableMaterials()[0].id, false);
        }

        this.setSelectedMaterialsIds();

        this.notificationService.showSuccess("Materials added");

        await this.onReloadMaterials();
        this.isLoadingAvailableMaterials.set(false);
        this.isLoadingSelectedMaterials.set(false);

        this.cdr.detectChanges();
      }
    });
  };

  public onAddMaterial = async (id: string, isSingleAdd = true): Promise<void> => {
    if (isSingleAdd) {
      this.isLoadingAvailableMaterials.set(true);
      this.isLoadingSelectedMaterials.set(true);
    }
    const itemIndex = this.availableMaterials().findIndex((i) => i.id === id);
    const item = { ...this.availableMaterials()[itemIndex] };

    const availableMaterials = [...this.availableMaterials()];

    availableMaterials.splice(itemIndex, 1);
    this.availableMaterials.set([...availableMaterials]);

    const allowedMaterialsFormValue = this.formGroup.controls["allowedMaterials"].value;

    allowedMaterialsFormValue.push(
      `/organisations/${this.activeOrganisationId}/materials/${item.id}`,
    );
    this.formGroup.controls["allowedMaterials"].setValue(allowedMaterialsFormValue);

    if (!this.isOldMaterialsEnabled) {
      this.materialsToAttach.push(item);
    }

    this.selectedMaterials.set([...this.selectedMaterials(), item]);
    if (isSingleAdd) {
      this.notificationService.showSuccess("Material added");
      this.setSelectedMaterialsIds();
      await this.onReloadMaterials();
      this.isLoadingAvailableMaterials.set(false);
      this.isLoadingSelectedMaterials.set(false);
    }
  };

  public attachMaterial = async (id: string, isSingleAdd = true): Promise<void> => {
    if (isSingleAdd) {
      this.isLoadingSelectedMaterials.set(true);
    }

    try {
      await this.productOverlay.createAttachment(
        AttachmentTargetEnum.PRODUCT,
        this.element.id,
        AttachmentTypeEnum.MATERIAL,
        id,
      );

      if (isSingleAdd) {
        this.notification.showSuccess("Material added");
        await this.reloadAttachedMaterials();
      }
    } catch {
      this.notification.showError("Error attaching materials");
    } finally {
      this.isLoadingSelectedMaterials.set(false);
    }
  };

  public onAddNewMaterial = async (): Promise<void> => {
    const dialogRef = this.dialog.open(AddMaterialDialogComponent);

    dialogRef.afterClosed().subscribe(async (result: { hasSaved: boolean }) => {
      if (result?.hasSaved) {
        await this.onReloadMaterials();
      }
    });
  };

  override async save(): Promise<boolean> {
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);
      this.notificationService.showError(CommonConstants.FILL_REQUIRED_FIELDS_MSG);

      return false;
    }

    const payload = this.getSavePayload();

    if (!this.isOldMaterialsEnabled) {
      payload.allowedMaterials = this.productInitiallyAllowedMaterials();
    }
    try {
      this.element = await this.productsService.createOrUpdate(payload, this.element?.id);
      if (!this.isOldMaterialsEnabled) {
        if (this.materialsToDettach.length) {
          await Promise.all(
            this.materialsToDettach.map((material) =>
              this.removeMaterialAttachment(material).catch((error) => {
                this.notificationService.showError(error);
              }),
            ),
          );
        }
        if (this.materialsToAttach.length) {
          this.isLoadingSelectedMaterials.set(true);
          await Promise.all(
            this.materialsToAttach.map((material) => this.attachMaterial(material.id, false)),
          );
          this.notification.showSuccess("Materials added");
          this.isLoadingSelectedMaterials.set(false);
        }
      }

      this.hasFormValuesChanged = false;
      this.initialMaterialsIds.set([...this.selectedMaterialsIds()]);
      this.notificationService.showSuccess(`Product ${this.isEditing() ? "modified" : "created"}`);

      return true;
    } catch (error) {
      this.notificationService.showError(error);

      return false;
    }
  }

  override async afterSave(): Promise<void> {
    if (this.isEditing()) {
      await this.reloadElement(this.element.id);
    } else {
      await this.routerService.navigate(this.routerService.getProductLink(this.element.id), {
        replaceUrl: true,
      });
    }
  }

  override reloadElement = async (id: string): Promise<void> => {
    this.overlay.showLoading();

    try {
      this.element = await this.productsService.get(id);
      this.productInitiallyAllowedMaterials.set(clone(this.element.allowedMaterials));
      this.setEditMode();

      if (!this.isOldMaterialsEnabled) {
        await this.reloadAttachedMaterials();
      }
      this.selectedSystemUnitOfMeasurement = await this.unitsOfMeasurementService.getBaseUnit(
        CommonUtils.getUriId(this.element.baseUnit),
      );
      this.defaultUnitOfMeasurementId = CommonUtils.getUriId(this.element.defaultUnit);
      this.initialDefaultUnitOfMeasurementId = this.defaultUnitOfMeasurementId;

      await Promise.all([
        this.setSelectedUnitsOfMeasurement(this.element.customUnits),
        this.getSelectedMaterials(this.element.allowedMaterials).then((materials) => {
          this.selectedMaterials.set(materials);
        }),
        this.reloadSupplyChain(id),
      ]);

      this.setSelectUnitsOfMeasurementIds();
      this.setSelectedMaterialsIds();
      this.initialMaterialsIds.set([...this.selectedMaterialsIds()]);
      this.initialUnitsOfMeasurementIds = [...this.selectedUnitsOfMeasurementIds];
      this.availableMaterials.set(this.getAvailableMaterials());

      this.setupForm();
      this.overlay.dismissLoading();
    } catch (error) {
      this.notificationService.showError(error, true);
    }
  };

  public async reloadSupplyChain(productId: string) {
    try {
      const productSupplyChainItems = (
        await this.productsService.getProductSupplyChain(this.activeOrganisationId, productId)
      ).loadProductSupplyChain?.products;
      const relatedProducts = [
        productSupplyChainItems.map((item) => item.usedIn).flat(),
        productSupplyChainItems.map((item) => item.createdFrom).flat(),
      ]
        .flat()
        .filter((value, index, self) => self.findIndex((v) => v.id === value.id) === index)
        .filter((p) => p.id !== productId);

      this.relatedProductsLength = relatedProducts.length;
    } catch (error) {
      this.notificationService.showError(error);
    }
  }

  private getSavePayload = (): IProductPayload => {
    const payload: IProductPayload = {
      id: this.element?.id ?? undefined,
      name: this.formGroup.controls["name"].value.trim(),
      baseUnit: this.isEditing()
        ? undefined
        : `/common/base-units/${this.formGroup.controls["baseUnit"].value}`,
      allowedMaterials: this.formGroup.controls["allowedMaterials"].value,
    };

    payload.customUnits = this.selectedUnitsOfMeasurement
      .slice(1)
      .map((unit) => `/organisations/${this.activeOrganisationId}/units-of-measurement/${unit.id}`);
    payload.defaultUnit =
      this.defaultUnitOfMeasurementId === this.selectedSystemUnitOfMeasurement.id
        ? `/common/base-units/${this.selectedSystemUnitOfMeasurement.id}`
        : `/organisations/${this.activeOrganisationId}/units-of-measurement/${this.defaultUnitOfMeasurementId}`;
    payload.baseUnit = this.isEditing()
      ? undefined
      : `/common/base-units/${this.selectedSystemUnitOfMeasurement.id}`;

    CustomFieldsUtils.addToPayload(
      payload,
      this.activeOrganisationId,
      this.formGroup,
      this.visibleCustomFields,
    );

    return payload;
  };

  private getSelectedMaterials = async (materialUris: string[]): Promise<IMaterial[]> => {
    if (this.isOldMaterialsEnabled) {
      const materialIds = materialUris.map((uri) => CommonUtils.getUriId(uri)) || [];

      return this.allMaterials().filter((material) => materialIds.includes(material.id));
    } else {
      return this.allMaterials().filter((material) =>
        this.attachedMaterialsIds().includes(material.id),
      );
    }
  };

  override async handleDiscardChanges(): Promise<void> {
    this.overlay.closeSideMenu();

    if (this.areInitialMaterialsChanged() || this.haveUnitsOfMeasurementChanged()) {
      await this.reloadElement(this.element.id);
    }
  }

  public setSelectedUnitsOfMeasurement = async (unitsUris: string[]) => {
    this.pushSelectedUnitOfMeasurement(this.selectedSystemUnitOfMeasurement);
    for (const uri of unitsUris) {
      const id = CommonUtils.getUriId(uri);

      try {
        const customUnit = await this.unitsOfMeasurementService.get(id);

        if (customUnit) {
          this.pushSelectedUnitOfMeasurement({
            ...customUnit,
            systemUnit: this.selectedSystemUnitOfMeasurement,
          });
        }
      } catch {
        if (this.isRegularUser) {
          throw "Error fetching custom unit";
        }
      }
    }
    this.setSelectUnitsOfMeasurementIds();
    await this.setAvailableUnitsOfMeasurement();
  };

  private getAvailableMaterials = (): IMaterial[] => {
    const selectedMaterialIds = this.selectedMaterials().map((material) =>
      CommonUtils.getUriId(material.id),
    );

    return this.allMaterials().filter((material) => !selectedMaterialIds.includes(material.id));
  };

  override setupForm = (): void => {
    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl(
        this.element?.name ?? null,
        [CustomValidators.required],
        [
          CustomValidators.entityAlreadyExists(
            this.productsService,
            this.element?.id ?? this.route.snapshot.params["id"] ?? null,
          ),
        ],
      ),
      baseUnit: new UntypedFormControl(
        {
          value: this.element?.baseUnit ? CommonUtils.getUriId(this.element.baseUnit) : null,
          disabled: this.isEditing(),
        },
        [CustomValidators.required],
      ),
      allowedMaterials: new UntypedFormControl(this.element?.allowedMaterials ?? []),
    });

    if (this.isEditing()) {
      const systemUnit = this.systemUnitsOfMeasurement?.find(
        (unit) => unit.id === CommonUtils.getUriId(this.element.baseUnit),
      );

      this.formGroup.controls["baseUnit"].setValue(
        { label: systemUnit.type, value: systemUnit.type },
        { emitEvent: false },
      );
    }

    this.visibleCustomFields = CustomFieldsUtils.getVisible(
      this.allCustomFields,
      this.element?.customFields,
    );
    CustomFieldsUtils.addToFormGroup(
      this.formGroup,
      this.visibleCustomFields,
      this.element?.customFields,
    );
    this.initialFormValue = this.formGroup.value;
    this.hasFormValuesChanged = false;

    this.subscriptions.add(
      this.formGroup.valueChanges.subscribe(() => {
        this.hasFormValuesChanged =
          !this.formGroup.pristine && this.hasInitialFormValueChanged(this.formGroup.value);
      }),
    );

    this.subscriptions.add(
      this.formGroup.controls["baseUnit"].valueChanges.subscribe(
        async (type: UnitOfMeasurementCategoryTypeEnum) => {
          if (type) {
            await this.onUnitOfMeasurementTypeChanged(type);
          }
        },
      ),
    );
  };

  public onAddUnitOfMeasurement = (id: string, isSingleAdd: boolean = true): void => {
    const itemIndex = this.availableUnitsOfMeasurement.findIndex((i) => i.id === id);
    const item = this.availableUnitsOfMeasurement[itemIndex];

    this.availableUnitsOfMeasurement.splice(itemIndex, 1);

    if (isSingleAdd) {
      this.selectedUnitsOfMeasurement = [...this.selectedUnitsOfMeasurement, item];
      this.notificationService.showSuccess("Unit of measurement added");
      this.setSelectUnitsOfMeasurementIds();
    } else {
      this.pushSelectedUnitOfMeasurement(item);
    }
  };

  public onRemoveUnitOfMeasurement = async (id: string): Promise<void> => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: "Removing unit of measurement",
        contentTranslatedText:
          "Are you sure you want to remove this unit of measurement from the product?",
        confirmButtonColor: "danger",
        confirmButtonTranslatedText: "Remove",
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        await this.removeUnitOfMeasurement(id);
      }
    });
  };

  private onSetDefaultUnitOfMeasurement = async (id: string): Promise<void> => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: "Set default unit of measurement",
        contentTranslatedText:
          "Are you sure you want to set this unit of measurement as the default one for this product?",
        confirmButtonTranslatedText: "Set as default",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        this.isLoadingUnitsOfMeasurement.set(true);
        this.defaultUnitOfMeasurementId = id;
        this.selectedUnitsOfMeasurement = this.selectedUnitsOfMeasurement.filter(() => true);
        this.setColumnDefsForAUnitsOfMeasurements();
        this.isLoadingUnitsOfMeasurement.set(false);
      }
    });
  };

  private removeUnitOfMeasurement = async (id: string): Promise<void> => {
    this.selectedUnitsOfMeasurement = this.selectedUnitsOfMeasurement.filter(
      (unitOfMeasurement) => unitOfMeasurement.id !== id,
    );

    this.notificationService.showSuccess("Unit of measurement removed");
    await this.setAvailableUnitsOfMeasurement();
    this.setSelectUnitsOfMeasurementIds();
  };

  public onAddAllUnitsOfMeasurement = async (): Promise<void> => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: "Add all confirmation",
        contentTranslatedText: `Are you sure you want to add all ${this.availableUnitsOfMeasurement.length} units of measurement?`,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        while (this.availableUnitsOfMeasurement.length > 0) {
          this.onAddUnitOfMeasurement(this.availableUnitsOfMeasurement[0].id, false);
        }

        this.selectedUnitsOfMeasurement = this.selectedUnitsOfMeasurement.filter(() => true);
        this.setSelectUnitsOfMeasurementIds();

        this.cdr.detectChanges();

        this.notificationService.showSuccess("Units of measurement added");
      }
    });
  };

  public reloadUnitsOfMeasurement = async (): Promise<void> => {
    await this.setAvailableUnitsOfMeasurement();
  };

  public onAddNewUnitOfMeasurement = (): void => {
    const dialogRef = this.dialog.open(EditCustomUnitDialogComponent, {
      width: "665px",
      data: { type: this.selectedSystemUnitOfMeasurement?.type },
    });

    dialogRef.afterClosed().subscribe(async (result: { hasSaved: boolean }) => {
      if (result?.hasSaved) {
        await this.setAvailableUnitsOfMeasurement();
      }
    });
  };

  private setUnitsOfMeasurementData = async (): Promise<void> => {
    this.systemUnitsOfMeasurement = await this.unitsOfMeasurementService.getSystemUnits();
    const unitTypes = await this.unitsOfMeasurementService.getUnitTypes();

    this.unitOfMeasurementTypesOptions.set(
      unitTypes.map((unit) => ({
        label: CommonUtils.capitaliseFirstLetter(unit),
        value: unit,
      })),
    );
  };

  private onUnitOfMeasurementTypeChanged = async (
    type: UnitOfMeasurementCategoryTypeEnum,
  ): Promise<void> => {
    if (this.selectedUnitsOfMeasurement.length > 1 && this.lastTypeSelected !== type) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          titleTranslatedText: "Resetting units of measurement",
          contentTranslatedText:
            "Changing the main measurement type will remove all of the organisation units of measurement from the product. Are you sure you want to change it?",
        },
      });

      dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
        if (result === ConfirmDialogResponseEnum.CONFIRM) {
          await this.updateSelectedUnitsOfMeasurement(type, true);
        } else {
          const previousOption = this.unitOfMeasurementTypesOptions().find(
            (u) => u.value === this.lastTypeSelected,
          );

          this.formGroup.controls["baseUnit"].setValue(previousOption.value);
        }
      });
    } else {
      await this.updateSelectedUnitsOfMeasurement(type);
    }
  };

  private updateSelectedUnitsOfMeasurement = async (
    type: string,
    resetSelectedUnitsOfMeasurement = false,
  ): Promise<void> => {
    this.lastTypeSelected = type;
    if (this.selectedUnitsOfMeasurement.length === 1 || resetSelectedUnitsOfMeasurement) {
      this.selectedUnitsOfMeasurement = [];
    }
    if (!type) {
      this.availableUnitsOfMeasurement = [];

      return;
    }

    this.selectedSystemUnitOfMeasurement = this.systemUnitsOfMeasurement.find(
      (unit) => unit.type === type,
    );

    if (!this.isEditing()) {
      this.defaultUnitOfMeasurementId = this.selectedSystemUnitOfMeasurement.id;
    }
    this.pushSelectedUnitOfMeasurement(this.selectedSystemUnitOfMeasurement);
    await this.setAvailableUnitsOfMeasurement();
  };

  private setAvailableUnitsOfMeasurement = async (): Promise<void> => {
    try {
      this.isLoadingUnitsOfMeasurement.set(true);
      if (this.selectedSystemUnitOfMeasurement) {
        this.availableUnitsOfMeasurement = (
          await this.unitsOfMeasurementService.getAll(this.selectedSystemUnitOfMeasurement.type)
        )
          .filter((unit) => !this.selectedUnitsOfMeasurement.some((u) => u.id === unit.id))
          .map((unit) => ({ ...unit, systemUnit: this.selectedSystemUnitOfMeasurement }));
      }
    } catch (error) {
      this.notificationService.showError(error);
    } finally {
      this.setColumnDefsForAUnitsOfMeasurements();
      this.isLoadingUnitsOfMeasurement.set(false);
    }
  };

  private pushSelectedUnitOfMeasurement = (
    currentUnit: ICustomUnitOfMeasurement | IBaseUnit,
  ): void => {
    if (
      currentUnit &&
      !this.selectedUnitsOfMeasurement.some((unit) => unit?.id === currentUnit?.id)
    ) {
      this.selectedUnitsOfMeasurement.push(currentUnit);
    }
  };

  public onDefaultUnitChanged = (event: ICustomEvent) => {
    if (this.defaultUnitOfMeasurementId === event.value) {
      this.defaultUnitOfMeasurementId = null;
    } else {
      this.defaultUnitOfMeasurementId = event.value;
    }

    if (!event.isEnabled || !this.defaultUnitOfMeasurementId) {
      this.defaultUnitOfMeasurementId = this.selectedSystemUnitOfMeasurement.id;
    }
  };

  private hasDefaultUnitOfMeasurementChanged(): boolean {
    return (
      !!this.defaultUnitOfMeasurementId &&
      this.defaultUnitOfMeasurementId !== this.initialDefaultUnitOfMeasurementId
    );
  }

  override get requiresConfirmation(): boolean {
    return (
      this.hasFormValuesChanged ||
      this.areInitialMaterialsChanged() ||
      this.haveUnitsOfMeasurementChanged()
    );
  }

  private setSelectedMaterialsIds(): void {
    this.selectedMaterialsIds.set(
      this.selectedMaterials()
        .map((m) => CommonUtils.getUriId(m.id))
        .sort(),
    );
  }

  private setSelectUnitsOfMeasurementIds(): void {
    this.selectedUnitsOfMeasurementIds = this.selectedUnitsOfMeasurement.map((u) => u.id).sort();
  }

  private areInitialMaterialsChanged = (): boolean => {
    return (
      JSON.stringify(this.initialMaterialsIds()) !== JSON.stringify(this.selectedMaterialsIds())
    );
  };

  private areInitialUnitsOfMeasurementChanged = (): boolean => {
    return (
      JSON.stringify(this.initialUnitsOfMeasurementIds) !==
      JSON.stringify(this.selectedUnitsOfMeasurementIds)
    );
  };

  protected override async archiveRecord(id: string, payload: IRecordState): Promise<void> {
    await this.productsService.setRecordState(payload, id);
  }
}
