import { ChangeDetectionStrategy, Component, inject, input, OnInit, signal } from "@angular/core";

import { DeliveryReportOrganisationCertificatesDocumentsModel as Model } from "@components/reports/delivery-report/organisation-certificates-documents/delivery-report-organisation-certificates-documents.model";
import { ILocationExtended } from "@shared/interfaces";
import { ConnectionsService } from "@shared/services";

@Component({
  selector: "app-delivery-report-organisation-certificates-documents",
  templateUrl: "./delivery-report-organisation-certificates-documents.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryReportOrganisationCertificatesDocumentsComponent implements OnInit {
  public locations = input.required<ILocationExtended[]>();

  public isLoading = signal<boolean>(true);

  public organisationsByCountry: Model.IOrganisationByCountry;

  private connectionsService: ConnectionsService = inject(ConnectionsService);

  public async ngOnInit(): Promise<void> {
    const organisationIds = this.locations().map((location) => location.organisationId);

    const organisations = await this.connectionsService.getByIdsGraphQL(
      organisationIds,
      undefined,
      ["CERTIFICATES", "DOCUMENTS", "FULL_ADDRESS"],
    );

    this.organisationsByCountry = organisations.reduce((acc, organisation) => {
      const country = organisation.address.country;

      if (!acc[country]) {
        acc[country] = [];
      }
      acc[country].push(organisation);

      return acc;
    }, {});

    this.isLoading.set(false);
  }
}
