import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  Signal,
  untracked,
} from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

import { BaseActionsCellRenderer } from "@shared/cell-renderers/base-actions.cell-renderer";
import { UnitOfMeasurementCellRendererService } from "@shared/cell-renderers/unit-of-measurement.cell-renderer.service";
import { CommonConstants } from "@shared/constants";
import { CellRendererUtils } from "@shared/utils";

@Component({
  selector: "app-quick-actions-menu",
  templateUrl: "./quick-actions-menu.component.html",
  styleUrl: "./quick-actions-menu.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickActionsMenuComponent
  extends BaseActionsCellRenderer
  implements ICellRendererAngularComp
{
  public readonly defaultNumberRounding = CommonConstants.DEFAULT_NUMBER_PIPE_ROUNDING;

  private unitOfMeasurementService: UnitOfMeasurementCellRendererService = inject(
    UnitOfMeasurementCellRendererService,
  );

  agInit(params: ICellRendererParams): void {
    this.params.set(params);
  }

  hasLink = computed(() => {
    const params = this.params();

    return !!(params["linkRoute"] || params["linkRouteIdParam"] || params["linkRouteFn"]);
  });

  warningTooltipText = computed<string>(() => {
    return this.params()["warningTooltipText"];
  });

  link = computed(() => CellRendererUtils.getLink(this.params(), this.data()));

  value: Signal<string> = computed(() => {
    const params = this.params();

    return untracked(() => {
      const hasUnitOfMeasurement = params["hasUnitOfMeasurement"] ?? false;
      const data = this.data();

      if (hasUnitOfMeasurement) {
        return this.unitOfMeasurementService.getValue(data, params);
      } else {
        return params.value || "-";
      }
    });
  });
}
