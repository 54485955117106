import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";

import { IAvailableOrganisation, IUserData } from "../../../../shared/interfaces";
import { OrganisationService } from "../../../../shared/services/feature/organisation/organisation.service";

@Component({
  selector: "iov-sidebar-dropdown",
  templateUrl: "./sidebar-dropdown.component.html",
  styleUrl: "./sidebar-dropdown.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SidebarDropdownComponent {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  @Input() currentUrl: string;

  @Input() isSidebarCollapsed: boolean;

  @Input() currentOrganisation: IAvailableOrganisation;

  @Input() userData: IUserData;

  hasNoTopBorder: boolean = false;

  constructor(private organisationService: OrganisationService) {}

  updateDropdownState(): void {
    this.hasNoTopBorder = this.trigger.menuOpen && !this.isSidebarCollapsed;
  }

  changeOrganisation = async (index: number): Promise<void> => {
    await this.organisationService.changeOrganisation(index, this.userData, this.currentUrl);
  };

  createOrganisation(): void {
    this.organisationService.createOrganisation();
  }
}
