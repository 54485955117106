import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";

import { CriteriaTypeEnum } from "src/app/shared/enums";
import { ISelectOption } from "src/app/shared/interfaces";

import { InputSelectComponent } from "@design-makeover/components/inputs/input-select/input-select.component";

import { BaseRulesetInfoClass } from "./base-ruleset-info.class";
import { LocationCriteriaInfoComponent } from "../location-criteria-info/location-criteria-info.component";

@Component({
  selector: "app-ruleset-delivery-info",
  templateUrl: "./ruleset-delivery-info.component.html",
  styleUrl: "./ruleset-shared-info.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RulesetDeliveryInfoComponent extends BaseRulesetInfoClass {
  @Input() deliveryFromCriteria: CriteriaTypeEnum = CriteriaTypeEnum.EXACT;

  @Input() deliveryToCriteria: CriteriaTypeEnum = CriteriaTypeEnum.EXACT;

  @Output() deliveryFromCriteriaChanged: EventEmitter<CriteriaTypeEnum> =
    new EventEmitter<CriteriaTypeEnum>();

  @Output() deliveryToCriteriaChanged: EventEmitter<CriteriaTypeEnum> =
    new EventEmitter<CriteriaTypeEnum>();

  @ViewChild("deliveryFrom") deliveryFrom: LocationCriteriaInfoComponent;

  @ViewChild("deliveryTo") deliveryTo: LocationCriteriaInfoComponent;

  @ViewChild("fromLocationSelect") exactFromLocationSelect: InputSelectComponent;

  @ViewChild("toLocationSelect") exactToLocationSelect: InputSelectComponent;

  constructor() {
    super();
  }

  get allLocationsOptions(): ISelectOption[] {
    return this.allLocations.map((l) => ({ value: l.id, label: l.name }));
  }

  allLocationOptionsExceptSelectedBy(controlName: string): ISelectOption[] {
    const toValues = this.formGroup.controls[controlName].value.map(
      (option: ISelectOption) => option.value,
    );

    return this.allLocationsOptions.filter((option) => !toValues.includes(option.value));
  }
}
