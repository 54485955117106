<div [formGroup]="formGroup" class="row-container row row-with-index">
  <div class="production-place-index">
    {{ index + 1 }}
  </div>
  <iov-input formControlName="description" label="Production Place Description"></iov-input>
  <iov-input formControlName="area" label="Area (ha)"></iov-input>
  <iov-input
    formControlName="type"
    label="Type"
    tooltipIcon="alert-triangle"
    iconClass="warn-icon"
    [tooltip]="
      formGroup.controls['type'].value === 'None' ? 'This location has no GPS data.' : null
    "
  ></iov-input>
</div>
