<iov-slide-overlay-header [title]="title()" icon="category"></iov-slide-overlay-header>
<iov-slide-overlay-content #slideOverlayContent>
  <ng-template
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="menu-info"
    overlay-menu-title="Material details"
  >
    <iov-form-field-group [name]="mainInformationText">
      <div [formGroup]="formGroup" class="row-container">
        <div class="row">
          <div class="item">
            <iov-input enableViewMode formControlName="name" label="Name"></iov-input>
          </div>
        </div>

        <div class="row">
          <div class="item">
            <iov-input-select
              enableViewMode
              [options]="categoriesOptions"
              [addOnWrite]="true"
              formControlName="category"
              hint="E.g. tree species, alloys, meat, etc."
              label="Category"
            ></iov-input-select>
          </div>
        </div>
      </div>
    </iov-form-field-group>
    @if (visibleCustomFields?.length) {
      <iov-form-field-group [name]="additionalInformationText">
        <app-custom-fields-edit
          [columns]="1"
          [elementCustomFields]="element?.customFields"
          [formGroup]="formGroup"
          [enableViewMode]="true"
          [visibleCustomFields]="visibleCustomFields"
        ></app-custom-fields-edit>
      </iov-form-field-group>
    }
  </ng-template>
</iov-slide-overlay-content>
