import { Component, OnDestroy, OnInit, signal, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

import { cloneDeep } from "lodash";

import { InputSelectOption } from "@design-makeover/components/inputs/input-select/input-select.model";
import { SlideOverlayContentComponent } from "@design-makeover/components/overlay/slide-overlay-content/slide-overlay-content.component";
import { SlideOverlaySupplyChainClass } from "@design-makeover/components/overlay/slide-overlay-supply-chain/slide-overlay-supply-chain.component";
import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { DeliveryOverlayService } from "@components/deliveries/pages/delivery-overlay/delivery-overlay.service";
import { IItemQuantityDialogPayload, SlideOverlayDeliveryItemsComponent } from "@components/index";
import {
  OverlayCertificateAttachmentsComponent,
  OverlayDocumentAttachmentsComponent,
} from "@components/shared";
import { CommonConstants } from "@shared/constants";
import {
  AttachmentTargetEnum,
  AttachmentTypeEnum,
  CustomFieldsResourceTypeEnum,
  DeliveryStatusEnum,
  EntityTypeEnum,
  FeatureFlagEnum,
  OverlayTabEnum,
  RecordStateEnum,
  ResourceTypeEnum,
  RoutingEnum,
} from "@shared/enums";
import {
  IBaseUnit,
  IDelivery,
  IDeliveryDetails,
  IDeliveryPayload,
  IItem,
  IItemDetails,
  ILocationDetails,
  ILocationExtended,
  IMaterial,
  IOrganisation,
  IProcessType,
  IProductExtended,
  IRecordResponse,
  IRecordState,
  ISelectOption,
  IShare,
} from "@shared/interfaces";
import {
  CommonService,
  ConnectionsService,
  DeliveriesService,
  FeatureFlagService,
  ItemsService,
  LocationsService,
  ProcessTypesService,
  RulesetsService,
  SharesService,
} from "@shared/services";
import { NavigationParams } from "@shared/services/router.service";
import { CommonUtils, CustomFieldsUtils, FormUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

@Component({
  selector: "app-delivery-overlay",
  templateUrl: "./delivery-overlay.component.html",
  styleUrl: "./delivery-overlay.component.scss",
})
export class DeliveryOverlayComponent
  extends SlideOverlaySupplyChainClass
  implements OnDestroy, OnInit
{
  @ViewChild("itemSection") itemSection: SlideOverlayDeliveryItemsComponent;

  @ViewChild("certificateSection") certificateSection: OverlayCertificateAttachmentsComponent;

  @ViewChild("slideOverlayContent") override slideOverlayContent: SlideOverlayContentComponent;

  @ViewChild("documentSection") documentSection: OverlayDocumentAttachmentsComponent;

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    deliveryId: new UntypedFormControl(null, [CustomValidators.required]),
    from: new UntypedFormControl(null, [CustomValidators.required]),
    to: new UntypedFormControl(null, [CustomValidators.required]),
    status: new UntypedFormControl(null, [CustomValidators.required]),
    sent: new UntypedFormControl(null),
    delivered: new UntypedFormControl(null),
    agents: new UntypedFormControl(null),
  });

  public isLoadingItems = signal(false);

  public isLoadingShares = signal(false);

  public isLoadingMap = signal(true);

  public override element: IDelivery;

  public deliveryDetails: IDeliveryDetails;

  public override entityType = EntityTypeEnum.DELIVERIES;

  public allUnitOfMeasurements: IBaseUnit[] = [];

  public fromLocationOptions: ISelectOption[] = [];

  public toLocationOptions: ISelectOption[] = [];

  public availableItems: IItemDetails[] = [];

  public allItems: IItem[] = [];

  public selectedItems: IItem[] = [];

  public rulesetsRecords: IRecordResponse[];

  public supplyChainHeight: number;

  public organisationsOptions: ISelectOption[];

  public allProcessTypes: IProcessType[] = [];

  public allOrganisations: IOrganisation[];

  public shares: IShare[] = [];

  public readonly attachmentTypeEnum = AttachmentTypeEnum;

  public readonly attachmentTargetEnum = AttachmentTargetEnum;

  public readonly resourceTypeEnum = ResourceTypeEnum;

  public readonly recordStateEnum = RecordStateEnum;

  public override readonly attachmentTargetType = AttachmentTargetEnum.DELIVERY;

  public readonly isSharedUser = this.authenticationService.isSharedUser();

  public readonly isCrossOrgSharingEnabled = this.featureFlagService.isEnabled(
    FeatureFlagEnum.CROSS_ORGANISATION_SHARING,
  );

  public readonly isAccountOwnerOrAdminOrContributor =
    this.authenticationService.isAccountOwnerOrAdminOrContributor();

  override menuItems = signal(
    new Map([
      [OverlayTabEnum.DETAILS, { isEnabled: true }],
      [OverlayTabEnum.ITEMS, { isEnabled: false }],
      [OverlayTabEnum.CERTIFICATES, { isEnabled: false }],
      [OverlayTabEnum.DOCUMENTS, { isEnabled: false }],
      [OverlayTabEnum.SUPPLY_CHAIN, { isEnabled: false }],
      [OverlayTabEnum.SHARES, { isEnabled: false, isHidden: !this.isRegularUser }],
      [OverlayTabEnum.COMMENTS, { isEnabled: false, isHidden: !this.isRegularUser }],
    ]),
  );

  public readonly statusOptions: ISelectOption[] = [
    { label: "Delivered", value: DeliveryStatusEnum.DELIVERED },
    { label: "Pending", value: DeliveryStatusEnum.PENDING },
    { label: "In progress", value: DeliveryStatusEnum.IN_PROGRESS },
  ];

  private allLocations: ILocationExtended[] = [];

  private lastFromLocationIdSelected: string;

  public readonly isEudrReportEnabled = this.featureFlagService.isEnabled(
    FeatureFlagEnum.EUDR_REPORT,
  );

  public readonly isDeliveryReportEnabled = this.featureFlagService.isEnabled(
    FeatureFlagEnum.DELIVERY_REPORT,
  );

  constructor(
    public deliveryOverlayService: DeliveryOverlayService,
    public deliveriesService: DeliveriesService,
    private locationsService: LocationsService,
    private notificationService: NotificationService,
    private itemsService: ItemsService,
    private connectionsService: ConnectionsService,
    private commonService: CommonService,
    private rulesetsService: RulesetsService,
    private processTypesService: ProcessTypesService,
    private sharesService: SharesService,
    private featureFlagService: FeatureFlagService,
  ) {
    super();

    this.subscriptions.add(
      this.commonService.unitOfMeasurementsObservable$.subscribe(
        (unitOfMeasurements: IBaseUnit[]) => {
          this.allUnitOfMeasurements = unitOfMeasurements;
        },
      ),
    );
  }

  public override get isSubmitButtonDisabled(): boolean {
    if (!this.isEditing()) {
      return false;
    }

    return this.formGroup?.invalid || this.formGroup?.pending || !this.hasFormValuesChanged;
  }

  loadCounters(): void {
    if (this.recordId) {
      this.loadDocuments();
      this.loadCertificates();
      this.loadComments();
    }
  }

  get allProducts(): IProductExtended[] {
    return this.deliveryOverlayService.allProducts;
  }

  get allMaterials(): IMaterial[] {
    return this.deliveryOverlayService.allMaterials;
  }

  setCountersToLoadingState() {
    this.deliveryOverlayService.setCountersToLoadingState();
    this.selectedItems = null;
  }

  setCountersEmptyState() {
    this.deliveryOverlayService.setCountersEmptyState();
  }

  loadDocuments(): void {
    this.deliveryOverlayService.loadDocumentCounter(this.element.id, this.attachmentTargetType);
  }

  loadCertificates(): void {
    this.deliveryOverlayService.loadCertificateCounter(this.element.id, this.attachmentTargetType);
  }

  loadComments(): void {
    this.deliveryOverlayService.loadCommentCounter(this.entityUri);
  }

  public async ngOnInit(): Promise<void> {
    this.overlay.showLoading();

    if (!this.isOnCorrectOverlay(RoutingEnum.OVERLAY_DELIVERY)) {
      return;
    }

    const productIncludes = [];

    if (!this.deliveryOverlayService.isOldMaterialsEnabled) {
      productIncludes.push("MATERIALS");
    }
    await Promise.all([
      this.onReloadLocations(),
      this.setAllCustomFields(CustomFieldsResourceTypeEnum.DELIVERY),
      this.deliveryOverlayService.onReloadProducts(productIncludes),
      this.deliveryOverlayService.onReloadMaterials(),
      this.onReloadItems(),
      this.onReloadOrganisations(),
      this.onReloadProcessTypes(),
    ]);

    const recordId = this.recordId;

    if (recordId) {
      this.setCountersToLoadingState();
      await this.reloadElement(recordId);
      await this.setMenuItemFromURLParam();
      this.loadCounters();
    } else {
      this.setupForm();
      this.overlay.dismissLoading();
    }

    this.supplyChainHeight = CommonUtils.getOverlaySupplyChainHeight();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.setCountersEmptyState();
  }

  public getLocationLink = (id: string): NavigationParams => {
    return this.routerService.getLocationLink(id, false) as NavigationParams;
  };

  public onFromLocationSelected = async (newFromLocationOption: ISelectOption): Promise<void> => {
    if (!newFromLocationOption || typeof newFromLocationOption === "string") {
      this.fromLocationOptions = this.getLocationOptions(
        "FROM",
        this.formGroup.controls["to"].value,
      );
      this.toLocationOptions = this.getLocationOptions("TO");

      return;
    }

    await this.onChangeFromLocation(newFromLocationOption);
  };

  public onUpdateDeliveredQuantity = (data: IItemQuantityDialogPayload): void => {
    const selectedItem = this.selectedItems.find((i) => i.id === data.id);

    selectedItem.deliveredQuantity = data.deliveredQuantity;
    this.selectedItems = [...this.selectedItems];
    this.save();
  };

  public onAddNewItem = (item: IItem): void => {
    item = this.standardizeItems([item])[0];

    this.onSetSelectedItems([item, ...this.selectedItems]);
  };

  public onReloadItems = async (searchAvailableText?: string): Promise<void> => {
    this.isLoadingItems.set(true);
    this.availableItems = [];

    const extendedItems = await this.itemsService.getAllGraphQL(
      { itemId: searchAvailableText, recordState: RecordStateEnum.ACTIVE },
      undefined,
      ["TAGS"],
    );

    const items: IItem[] = [];

    for (const extendedItem of extendedItems) {
      const item = CommonUtils.convertExtendedItemToItem(
        extendedItem,
        this.activeOrganisationId,
        this.isSharedUser,
      );

      items.push(item);
    }

    this.allItems = items;

    const availableItems = this.allItems.filter(
      (l) => !this.selectedItems.some((s) => s.id === l.id),
    );

    this.availableItems = this.standardizeItems(availableItems);

    this.availableItems = await Promise.all(
      this.availableItems.map(async (item) => {
        const product = this.allProducts.find((p) => p.id === item.productId);
        const defaultCustomUnit = product?.defaultCustomUnit;

        if (defaultCustomUnit) {
          const customUnit = defaultCustomUnit;

          item.remainingQuantityFormatted = CommonUtils.formatQuantityWithDefaultUnit(
            item.remainingQuantity,
            customUnit,
            item.unitOfMeasurement,
          );
          item.initialQuantityFormatted = CommonUtils.formatQuantityWithDefaultUnit(
            item.initialQuantity,
            customUnit,
            item.unitOfMeasurement,
          );
        }

        return item;
      }),
    );

    this.isLoadingItems.set(false);
  };

  private async setDeliveryDetailsForMap(element: IDelivery): Promise<void> {
    this.isLoadingMap.set(true);

    let from: ILocationDetails;
    let to: ILocationDetails;
    const fromId = CommonUtils.getUriId(element.from);
    const toId = CommonUtils.getUriId(element.to);

    try {
      from = await this.locationsService.get(fromId);
    } catch {
      if (this.isRegularUser) {
        throw "Error fetching 'from' location";
      }
    }
    try {
      to = await this.locationsService.get(toId);
    } catch {
      if (this.isRegularUser) {
        throw "Error fetching 'to' location";
      }
    }

    this.deliveryDetails = { ...element, from, to };
    this.isLoadingMap.set(false);
  }

  public override reloadElement = async (id: string): Promise<void> => {
    this.overlay.showLoading();

    await this.deliveriesService
      .get(id)
      .then(async (element: IDelivery) => {
        this.element = element;
        this.setEditMode();
        this.setDeliveryDetailsForMap(element);

        await this.onReloadOrganisations();
        this.setSelectedItems();
        await this.onReloadItems();
        this.setupForm();
      })
      .catch((error) => {
        this.notificationService.showError(error);
      });
    if (this.isEditing() && this.isRegularUser) {
      this.setRulesetRecords();
      this.reloadShares(true);
    }

    this.overlay.dismissLoading();
  };

  public onSetSelectedItems = async (selectedItems: IItem[]): Promise<void> => {
    const allItems = cloneDeep([...this.selectedItems, ...this.availableItems]);

    this.selectedItems = [...selectedItems];
    this.availableItems = allItems.filter((i) => !selectedItems.some((s) => s.id === i.id));

    this.save();
  };

  public override get itemIds(): string[] {
    return (this.selectedItems || []).map((item) => item.id);
  }

  public override async save(): Promise<boolean> {
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);
      this.notificationService.showError(CommonConstants.FILL_REQUIRED_FIELDS_MSG);

      return false;
    }

    const payload = this.getSavePayload();

    try {
      this.element = await this.deliveriesService.createOrUpdate(payload, this.element?.id);

      this.hasFormValuesChanged = false;
      this.notificationService.showSuccess(`Delivery ${this.isEditing() ? "modified" : "created"}`);
      this.initialFormValue = this.formGroup.getRawValue();

      return true;
    } catch (error) {
      this.notificationService.showError(error);

      return false;
    }
  }

  public override async afterSave(isSaveOnly: boolean): Promise<void> {
    if (isSaveOnly && !this.isEditing()) {
      await this.routerService.navigate(this.routerService.getDeliveryLink(this.element.id), {
        replaceUrl: true,
      });
    }
    if (this.isEditing() && this.shouldUpdateTable) {
      this.loadDocuments();
    }
  }

  public confirmShareDialog(): void {
    this.deliveryOverlayService
      .share(this.activeOrganisationId, this.element.id)
      .subscribe(async (result) => {
        if (result?.hasSaved) {
          await this.reloadShares();
          this.changeDetectorRef.detectChanges();
          await this.changeMenuItem(OverlayTabEnum.SHARES);
        }
      });
  }

  confirmSendDialog() {
    this.deliveryOverlayService.send(this.activeOrganisationId, this.element.id);
  }

  public async setRulesetRecords(): Promise<void> {
    if (!this.isRegularUser) {
      this.rulesetsRecords = [];

      return;
    }
    try {
      this.rulesetsRecords = await this.rulesetsService.getInstantRulesetRecords(
        `/organisations/${this.activeOrganisationId}/deliveries/${this.element.id}`,
      );
    } catch (error) {
      this.notificationService.showError(error);
    }
  }

  public override setupForm = (): void => {
    let fromValue: ISelectOption;
    let toValue: ISelectOption;
    let selectedAgents: string[] = [];
    let statusValue: ISelectOption = { label: "DELIVERED", value: "DELIVERED" };

    if (this.isEditing()) {
      const fromId = CommonUtils.getUriId(this.element.from);
      const fromLocation = this.allLocations.find((l) => l.id === fromId);
      const selectedAgentsIds = this.element.agents.map((agent) => CommonUtils.getUriId(agent));

      statusValue = { label: this.element.status, value: this.element.status };
      selectedAgents = (this.allOrganisations || [])
        .filter((org) => selectedAgentsIds.includes(org.id))
        .map((org) => org.id);
      if (fromLocation) {
        fromValue = { label: fromLocation.name, value: fromLocation.id };
        this.lastFromLocationIdSelected = fromLocation.id;
      }
      const toId = CommonUtils.getUriId(this.element.to);
      const toLocation = this.allLocations.find((l) => l.id === toId);

      if (toLocation) {
        toValue = { label: toLocation.name, value: toLocation.id };
      }
    }

    const entityExistsValidatorArgs = {
      searchPropertyName: "deliveryId",
      searchPropertyErrorDisplayName: "ID",
    };

    this.formGroup = new UntypedFormGroup(
      {
        deliveryId: new UntypedFormControl(
          this.element?.deliveryId ?? null,
          [CustomValidators.required],
          [
            CustomValidators.entityAlreadyExists(
              this.deliveriesService,
              this.element?.id ?? this.route.snapshot.params["id"] ?? null,
              entityExistsValidatorArgs,
            ),
          ],
        ),
        from: new UntypedFormControl(fromValue, [CustomValidators.required]),
        to: new UntypedFormControl(toValue, [CustomValidators.required]),
        status: new UntypedFormControl(statusValue, [CustomValidators.required]),
        sent: new UntypedFormControl(this.element?.sent ?? null),
        delivered: new UntypedFormControl(this.element?.delivered ?? null),
        agents: new UntypedFormControl({
          value: selectedAgents,
          disabled: selectedAgents.length === 5,
        }),
      },
      CustomValidators.dateRules([
        {
          name: "sent",
          rules: [
            { type: ">", target: "delivered", errorMessage: "Cannot be later than delivered date" },
          ],
        },
        {
          name: "delivered",
          rules: [{ type: "<", target: "sent", errorMessage: "Cannot be before than sent date" }],
        },
      ]),
    );

    this.visibleCustomFields = CustomFieldsUtils.getVisible(
      this.allCustomFields,
      this.element?.customFields,
    );
    CustomFieldsUtils.addToFormGroup(
      this.formGroup,
      this.visibleCustomFields,
      this.element?.customFields,
    );

    this.subscriptions.add(
      this.formGroup.controls["from"].valueChanges.subscribe(async (from: any) => {
        if (typeof from === "string") {
          return;
        }
        await this.onFromLocationSelected(from);
      }),
    );
    this.subscriptions.add(
      this.formGroup.controls["to"].valueChanges.subscribe((to: any) => {
        if (typeof to === "string") {
          return;
        }
        this.fromLocationOptions = this.getLocationOptions("FROM", to);
      }),
    );

    this.fromLocationOptions = this.getLocationOptions("FROM");
    this.toLocationOptions = this.getLocationOptions("TO");
    this.initialFormValue = this.formGroup.getRawValue();
    this.hasFormValuesChanged = false;
    this.subscriptions.add(
      this.formGroup.valueChanges.subscribe(() => {
        this.hasFormValuesChanged = this.hasInitialFormValueChanged(this.formGroup.getRawValue());
      }),
    );

    this.subscriptions.add(
      this.formGroup.controls["agents"].valueChanges.subscribe((value: string[]) => {
        if (value.length === 5) {
          this.formGroup.controls["agents"].disable({ emitEvent: false });
        } else {
          this.formGroup.controls["agents"].enable({ emitEvent: false });
        }
      }),
    );
  };

  public onClickGenerateEudrReport(): void {
    const url = `${RoutingEnum.EUDR_REPORT}/${this.element.id}`;

    CommonUtils.openInNewTab(url);
  }

  public onClickGenerateReport(): void {
    const url = `${RoutingEnum.DELIVERY_REPORT}/${this.element.id}`;

    CommonUtils.openInNewTab(url);
  }

  public async onShareDeleted(): Promise<void> {
    await this.reloadShares();

    if (!this.shares.length) {
      await this.slideOverlayContent.selectDefaultMenuItem();
    }
  }

  protected override recordName(): string {
    return this.element?.deliveryId;
  }

  protected override async archiveRecord(id: string, payload: IRecordState): Promise<void> {
    await this.deliveriesService.setRecordState(payload, id);
  }

  protected override async deleteRecord(id: string): Promise<void> {
    await this.deliveriesService.delete(id);
  }

  private onReloadProcessTypes = async (): Promise<void> => {
    await this.processTypesService
      .getAll()
      .then((response: IProcessType[]) => {
        this.allProcessTypes = response;
      })
      .catch((error) => {
        this.notificationService.showError(error);
      });
  };

  private onReloadOrganisations = async (): Promise<void> => {
    try {
      this.allOrganisations = await this.connectionsService.getAll();
      this.organisationsOptions = this.allOrganisations
        .filter((org) => org.recordState === RecordStateEnum.ACTIVE)
        .map((org) => ({ label: org.name, value: org.id }));
    } catch (error) {
      this.notificationService.showError(error);
    }
  };

  private onChangeFromLocation = async (newFromLocationOption: ISelectOption): Promise<void> => {
    this.toLocationOptions = this.getLocationOptions("TO", newFromLocationOption);
    const location = this.allLocations.find((c) => c.id === newFromLocationOption.value);

    if (location && location.id !== this.lastFromLocationIdSelected) {
      this.lastFromLocationIdSelected = newFromLocationOption.value.toString();
    }
  };

  private onReloadLocations = async (): Promise<void> => {
    await this.locationsService
      .getAllGraphQL()
      .then((response: ILocationExtended[]) => {
        this.allLocations = response;
      })
      .catch((error) => {
        this.notificationService.showError(error);
      });
  };

  private setSelectedItems = (): void => {
    const selectedItems: IItem[] = [];

    for (const elementItem of this.element.items) {
      const itemId = CommonUtils.getUriId(elementItem.item);
      const item = this.allItems.find((i) => i.id === itemId);

      if (item) {
        selectedItems.push({ ...item, deliveredQuantity: elementItem.quantity });
      }
    }
    this.selectedItems = [...selectedItems];
  };

  private standardizeItems = (items: IItem[]): IItem[] => {
    return CommonUtils.getItemsWithProductAndAssignedUnit(items, this.allProducts);
  };

  private getSavePayload = (): IDeliveryPayload => {
    const fromValueId = this.formGroup.controls["from"].value.value;
    const toValueId = this.formGroup.controls["to"].value?.value;

    const agents: string[] = this.formGroup.controls["agents"]
      .getRawValue()
      ?.map(
        (agentId: string) => `/organisations/${this.activeOrganisationId}/connections/${agentId}`,
      );

    const payload: IDeliveryPayload = {
      id: this.element?.id ?? undefined,
      deliveryId: this.formGroup.controls["deliveryId"].value,
      from: `/organisations/${this.activeOrganisationId}/locations/${fromValueId}`,
      to: `/organisations/${this.activeOrganisationId}/locations/${toValueId}`,
      status: this.formGroup.controls["status"].value?.value,
      sent: FormUtils.getDateValueForPayload(this.formGroup.controls["sent"].value),
      delivered: FormUtils.getDateValueForPayload(this.formGroup.controls["delivered"].value),
      items: this.selectedItems.map((item) => ({
        item: `/organisations/${this.activeOrganisationId}/items/${item.id}`,
        quantity: item.deliveredQuantity,
      })),
      agents,
    };

    CustomFieldsUtils.addToPayload(
      payload,
      this.activeOrganisationId,
      this.formGroup,
      this.visibleCustomFields,
    );

    return payload;
  };

  private getLocationOptions = (type: string, avoidLocation?: ISelectOption): ISelectOption[] => {
    let filteredLocations: ILocationExtended[];

    if (type === "FROM") {
      const selectedFromId = this.element?.from ? CommonUtils.getUriId(this.element.from) : null;

      filteredLocations = this.allLocations.filter(
        (l) =>
          l.recordState === RecordStateEnum.ACTIVE || (selectedFromId && l.id === selectedFromId),
      );
    } else {
      const selectedToId = this.element?.to ? CommonUtils.getUriId(this.element.to) : null;

      filteredLocations = this.allLocations.filter(
        (l) => l.recordState === RecordStateEnum.ACTIVE || (selectedToId && l.id === selectedToId),
      );
    }

    if (!avoidLocation?.value) {
      return [...filteredLocations.map((l: ILocationExtended) => ({ label: l.name, value: l.id }))];
    }

    return [
      ...filteredLocations
        .filter((l: ILocationExtended) => l.id !== avoidLocation.value)
        .map((l: ILocationExtended) => ({ label: l.name, value: l.id })),
    ];
  };

  private async reloadShares(isFirstLoad = false): Promise<void> {
    if (!this.recordId || !this.isRegularUser) {
      this.shares = [];
      this.deliveryOverlayService.linkedSharesCount.set(0);

      return;
    }

    this.isLoadingShares.set(true);
    try {
      this.shares = await this.sharesService.getAll(
        `/organisations/${this.activeOrganisationId}/deliveries/${this.element.id}`,
      );

      this.deliveryOverlayService.linkedSharesCount.set(this.shares.length);

      this.isLoadingShares.set(false);

      if (
        isFirstLoad &&
        this.tabQueryParam &&
        CommonUtils.enumToText(this.tabQueryParam) === OverlayTabEnum.SHARES &&
        this.deliveryOverlayService.linkedSharesCount()
      ) {
        this.changeDetectorRef.detectChanges();
        await this.setMenuItemFromURLParam();
      }
    } catch (error) {
      this.notificationService.showError(error);
    }
  }

  public traderTagPressed(tag: InputSelectOption): void {
    this.routerService.navigate(this.routerService.getOrganisationLink(tag.value as string));
  }

  public async onOpenBulkAddItemsSlideOver(): Promise<void> {
    await this.routerService.navigate(
      this.routerService.getBulkAddItemsLink(true, { deliveryId: this.element.id }),
    );
  }
}
