<div class="form-page-container">
  @if (isLoading()) {
    <app-loader></app-loader>
  } @else {
    <app-page-title [title]="title"></app-page-title>

    <div class="element-content element-content-padded no-bottom-margin">
      <div [formGroup]="formGroup" class="row-container">
        @if (locationWithoutOrgAttachment) {
          <div class="row">
            <div class="item red">
              ERROR: Location {{ locationWithoutOrgAttachment.name }} does not have an attached
              organisation.
            </div>
            <div
              class="item blue-link"
              (click)="onClickLocation(locationWithoutOrgAttachment)"
              (keyup)="onClickLocation(locationWithoutOrgAttachment)"
              tabindex="0"
            >
              See location
            </div>
          </div>
        }
        <div class="row row-2">
          <div class="item">
            <iov-input
              formControlName="referenceNumber"
              label="1. Reference number"
              placeholder="Reference number"
            ></iov-input>
          </div>

          <div class="item">
            <iov-form-label label="2. Activity"></iov-form-label>
            <iov-radio-button-group>
              <iov-radio-button
                (changeEvent)="onActivityChange(constants.ActivityEnum.DOMESTIC)"
                [checked]="
                  this.formGroup.controls['activity'].value === constants.ActivityEnum.DOMESTIC
                "
                [disabled]="true"
                label="Domestic"
              ></iov-radio-button>
              &nbsp;
              <iov-radio-button
                (changeEvent)="onActivityChange(constants.ActivityEnum.IMPORT)"
                [checked]="
                  this.formGroup.controls['activity'].value === constants.ActivityEnum.IMPORT
                "
                label="Import"
              ></iov-radio-button>
              &nbsp;
              <iov-radio-button
                (changeEvent)="onActivityChange(constants.ActivityEnum.EXPORT)"
                [checked]="
                  this.formGroup.controls['activity'].value === constants.ActivityEnum.EXPORT
                "
                label="Export"
              ></iov-radio-button>
            </iov-radio-button-group>
          </div>
        </div>

        <div class="row row-2">
          <div><iov-form-label label="3. Operator / Trader name and address"></iov-form-label></div>
          <div><iov-form-label label="4. Place of activity"></iov-form-label></div>
        </div>
        <div class="row row-operator-activity">
          <div class="item">
            <iov-input formControlName="operatorName" label="Name"></iov-input>
          </div>
          <div class="item">
            <iov-input-select
              [options]="countryOptions"
              formControlName="activityCountry"
              label="Country of activity"
            ></iov-input-select>
          </div>
          <div class="item">
            <iov-input formControlName="activityCountryIsoCode" label="ISO Code"></iov-input>
          </div>
        </div>
        <div class="row row-1-half-1-half">
          <div class="item">
            <iov-input-select
              [options]="countryOptions"
              formControlName="operatorCountry"
              label="Country"
            ></iov-input-select>
          </div>
          <div class="item">
            <iov-input formControlName="operatorCountryIsoCode" label="ISO Code"></iov-input>
          </div>
          <div class="item">
            <iov-input-select
              [options]="countryOptions"
              formControlName="entryCountry"
              label="Country of entry"
            ></iov-input-select>
          </div>
          <div class="item">
            <iov-input formControlName="entryCountryIsoCode" label="ISO Code"></iov-input>
          </div>
        </div>
        <div class="row row-2">
          <div class="item">
            <iov-input formControlName="operatorActivity" label="Activity"></iov-input>
          </div>
          <div class="item">
            <iov-datepicker formControlName="entryDate" label="Date of entry"></iov-datepicker>
          </div>
        </div>
        <div class="row row-2">
          <div class="item">
            <iov-form-label label="Address"></iov-form-label>
            <iov-editable-div
              class="bordered"
              placeholder="Address"
              formControlName="operatorStreet"
              [isTextarea]="true"
            ></iov-editable-div>
          </div>
        </div>
        <div>
          <iov-form-label label="5. Communication for Competent Authority"></iov-form-label>
        </div>
        <div class="row row-1">
          <div class="item">
            <iov-editable-div
              class="bordered"
              placeholder="Message"
              formControlName="competentAuthorityMessage"
              [isTextarea]="true"
            ></iov-editable-div>
          </div>
        </div>

        @if (!productsWithErrors.length) {
          <div><iov-form-label label="Totals"></iov-form-label></div>
          <div class="row row-4">
            <div class="item">
              <iov-input
                type="number"
                formControlName="totalNetMass"
                label="Net mass (kg)"
                placeholder=""
              ></iov-input>
            </div>
            <div class="item">
              <iov-input
                type="number"
                formControlName="totalVolume"
                label="Volume (m3)"
                placeholder=""
              ></iov-input>
            </div>
            <div class="item">
              <iov-input
                type="number"
                formControlName="suplementaryUnits"
                label="Suplementary units"
                placeholder=""
              ></iov-input>
            </div>
            <div class="item">
              <iov-input
                formControlName="totalArea"
                tooltipIcon="alert-triangle"
                iconClass="warn-icon"
                label="Total area (ha)"
                [tooltip]="
                  this.totalAreaError
                    ? 'Some of the Area (ha) fields may contain non-number values. Please correct them to see the total sum.'
                    : null
                "
                placeholder=""
              ></iov-input>
            </div>
          </div>
        }

        <div><iov-form-label label="6. Commodity(-ies) or Product(-s)"></iov-form-label></div>
        @if (productsWithErrors.length) {
          <div class="missing-required-custom-fields">
            <div class="item">
              Cannot display this section, as some of the products in this delivery's supply chain
              do not have a filled in "HS Code" field or have different values in the "Description"
              field:
              @for (product of productsWithErrors; track product.id) {
                <ul>
                  <li
                    class="blue-link"
                    (keyup)="onClickProduct(product)"
                    (click)="onClickProduct(product)"
                    tabindex="0"
                  >
                    {{ product.name }}
                  </li>
                </ul>
              }
              Fill in the values for those fields and re-generate the report.
            </div>
          </div>
        } @else {
          <div class="commodities" formArrayName="commodities">
            <div *ngFor="let control of commodityFormControls; let i = index" [formGroupName]="i">
              <app-reports-eudr-commodity-info
                [formGroup]="control"
                [index]="i"
              ></app-reports-eudr-commodity-info>
            </div>
          </div>
        }
      </div>
    </div>
  }
</div>
