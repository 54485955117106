import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Router } from "@angular/router";

import { RecordStateEnum, RoutingEnum } from "@shared/enums";
import { AuthenticationService } from "@shared/services";

@Component({
  templateUrl: "./documents.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsComponent {
  public readonly recordStateEnum = RecordStateEnum;

  public readonly canAddModifyEntities = this.authenticationService.canAddModifyEntities();

  constructor(
    private router: Router,
    public authenticationService: AuthenticationService,
  ) {}

  public onAdd = async (): Promise<void> => {
    await this.router.navigate([`/${RoutingEnum.DOCUMENTS_UPLOAD}`]);
  };
}
