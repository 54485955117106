import { DatePipe } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

import { DateUtils } from "@shared/utils";

import { CommonConstants } from "../constants";

@Component({
  template: `
    {{ getDate() }}
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateCellRendererComponent implements ICellRendererAngularComp {
  public params!: ICellRendererParams;

  constructor(private datePipe: DatePipe) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  getDate = (): string =>
    this.params.value
      ? this.datePipe.transform(
          DateUtils.getFormattedDateForPipe(this.params.value),
          this.params["dateFormat"] ?? CommonConstants.DATE_FORMAT,
        )
      : "-";
}
