import { inject, signal } from "@angular/core";

import { SlideOverlayPageClass } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.class";
import { SlideOverlaySupplyChainModel as Model } from "@design-makeover/components/overlay/slide-overlay-supply-chain/slide-overlay-supply-chain.model";

import { ItemSupplyChainMapperService } from "@components/shared/items-supply-chain/item-supply-chain-mapper.service";

export abstract class SlideOverlaySupplyChainClass extends SlideOverlayPageClass {
  public supplyChainTabIndex: Model.SupplyChainTabEnumValues = 0;

  public isLoadingSupplyChainLocations = signal<boolean>(false);

  public supplyChainLocationIds: string[] = [];

  public readonly supplyChainTabEnum = Model.SupplyChainTabEnum;

  private itemSupplyChainMapperService: ItemSupplyChainMapperService = inject(
    ItemSupplyChainMapperService,
  );

  abstract get itemIds(): string[];

  public async onSelectSupplyChainTab(index: Model.SupplyChainTabEnumValues): Promise<void> {
    this.isLoadingSupplyChainLocations.set(true);

    this.supplyChainTabIndex = index;

    const supplyChainItems = await this.itemSupplyChainMapperService.mapItemSupplyChain(
      this.itemIds,
    );

    this.supplyChainLocationIds = supplyChainItems
      .map((i) => i.locations?.map((d) => d.id))
      .flat()
      .filter((id) => !!id);

    this.isLoadingSupplyChainLocations.set(false);
  }
}
