import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
  booleanAttribute,
} from "@angular/core";

import { InputSelectOption } from "@design-makeover/components/inputs/input-select/input-select.model";

import { CommonConstants } from "@shared/constants";

@Component({
  selector: "iov-tag-group",
  templateUrl: "./tag-group.component.html",
  styleUrl: "./tag-group.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TagGroupComponent {
  @Input() class: string;

  @Input({ transform: booleanAttribute }) canRemove: boolean = false;

  @Input() tags: InputSelectOption[] | string[] = [];

  @Input() maxTextLengthToShow: number = CommonConstants.MAX_CHIPS_TEXT_LENGTH_TO_SHOW;

  @Input({ transform: booleanAttribute }) isTagClickable: boolean = false;

  @Output() remove: EventEmitter<InputSelectOption> = new EventEmitter<InputSelectOption>();

  @Output() tagPressed: EventEmitter<InputSelectOption> = new EventEmitter<InputSelectOption>();

  onRemove(tag: InputSelectOption): void {
    this.remove.emit(tag);
  }

  onTagPressed(tag: InputSelectOption): void {
    this.tagPressed.emit(tag);
  }

  get safeTags(): InputSelectOption[] {
    if (this.tags?.length > 0) {
      return this.tags.map((tag) => {
        return this.buildTag(tag);
      });
    }

    return [];
  }

  private buildTag(tag: InputSelectOption | string): InputSelectOption {
    if (typeof tag === "string") {
      return { label: tag, value: tag };
    }

    return tag;
  }
}
