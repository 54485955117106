export const getSupplyChainQuery = (orgId: string, supplyChainId: string) => `
{
    loadSupplyChain(
        orgId: "${orgId}"
        supplyChainId: "${supplyChainId}"
    ) {
        id
        name
        description
        recordState
        links {
            from {
                id
                name
            }
            to {
                id
                name
            }
        }
        locations {
            location {
                id
                name
                geoLocation
                types {
                    type
                    pointOfOrigin
                }
                address {
                    country
                }
                connections {
                    id
                    name
                }
                documents {
                    id
                    name
                    issuance
                    validityStart
                    validityEnd
                    contentType
                    type {
                        id
                        name
                    }
                    tags {
                        title
                        color
                    }
                    recordState
                }
                certificates {
                    id
                    number
                    issuanceDate
                    validFromDate
                    validToDate
                    standardType {
                        id
                        fullName
                    }
                    standard {
                        id
                        name
                    }
                    tags {
                        title
                        color
                    }
                    recordState
                }      
            }
            documents {
                id
                name
                issuance
                validityStart
                validityEnd
                contentType
                type {
                    id
                    name
                }
                tags {
                    title
                    color
                }
                recordState

            }
            certificates {
                id
                number
                issuanceDate
                validFromDate
                validToDate
                standardType {
                    id
                    fullName
                }
                standard {
                    id
                    name
                }
                tags {
                    title
                    color
                }
                recordState
            }
        }
    }
}
`;
