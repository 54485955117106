import { Inject, Injectable, inject } from "@angular/core";

import { Observable } from "rxjs";

import { getCertificatesByIdsGraphQLQuery, getCertificatesGraphQLQuery } from "@shared/queries";
import { APP_CONFIG } from "@shared/tokens";

import { ApiService } from "./api.service";
import { GraphService } from "./graph.service";
import { CommonConstants } from "../../constants";
import { RecordStateEnum } from "../../enums";
import {
  ICertificate,
  ICertificateExtended,
  ICertificateGraphQLFilter,
  ICertificateGraphQLResponse,
  ICertificatePayload,
  IConfig,
  IPageableContent,
  IRecordState,
} from "../../interfaces";
import { FormUtils } from "../../utils";
import { AuthenticationService } from "../authentication.service";

@Injectable({
  providedIn: "root",
})
export class CertificatesService {
  private graphService = inject(GraphService);

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {}

  public getPage = async (
    number: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page: number = 0,
    sort: string = undefined,
    recordState: RecordStateEnum = undefined,
  ): Promise<IPageableContent<ICertificate>> => {
    const url = `${this.getBaseUrl()}?${FormUtils.addUrlParams({ number, size, page, sort, recordState })}`;

    return await this.apiService.get<IPageableContent<ICertificate>>(url).toPromise();
  };

  public getPageSubscription = (
    number: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
    recordState: RecordStateEnum = undefined,
  ): Observable<IPageableContent<ICertificate>> => {
    const url = `${this.getBaseUrl()}?${FormUtils.addUrlParams({ number, size, page, sort, recordState })}`;

    return this.apiService.get<IPageableContent<ICertificate>>(url);
  };

  public async getAll(recordState: RecordStateEnum = undefined): Promise<ICertificate[]> {
    const result: ICertificate[] = [];
    let page = 0;
    let hasNextPage: boolean = true;

    while (hasNextPage) {
      const {
        content,
        page: { totalElements },
      } = await this.getPage(
        undefined,
        CommonConstants.MAX_API_GET_ITEMS_SIZE,
        page,
        undefined,
        recordState,
      );

      result.push(...content);

      page++;
      hasNextPage = result.length < totalElements;
    }

    return result;
  }

  public async getAllGraphQL(
    filter: ICertificateGraphQLFilter = null,
    first: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    include: string[] = [],
  ): Promise<ICertificateExtended[]> {
    const result: ICertificateExtended[] = [];
    let cursor: string | undefined = undefined;
    let hasNextPage: boolean = true;
    const activeOrganisationId = this.authenticationService.getActiveOrganisationId();

    while (hasNextPage) {
      const query = getCertificatesGraphQLQuery(
        activeOrganisationId,
        filter,
        first,
        null,
        cursor,
        null,
        include,
      );
      const { content, pageInfo, totalCount } = await this.graphService.fetchSinglePage<
        ICertificateGraphQLResponse,
        ICertificateExtended
      >("loadCertificates", query, this.graphQLPropertiesToAddFunction);

      result.push(...content);

      cursor = pageInfo.endCursor;
      hasNextPage = result.length < totalCount;
    }

    return result;
  }

  public async getByIdsGraphQL(
    ids: string[],
    first: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    include: string[] = [],
  ): Promise<ICertificateExtended[]> {
    if (!ids.length) {
      return [];
    }
    const result: ICertificateExtended[] = [];
    let cursor: string | undefined = undefined;
    let hasNextPage: boolean = true;
    const activeOrganisationId = this.authenticationService.getActiveOrganisationId();

    while (hasNextPage) {
      const query = getCertificatesByIdsGraphQLQuery(
        activeOrganisationId,
        ids,
        first,
        null,
        cursor,
        null,
        include,
      );
      const { content, pageInfo, totalCount } = await this.graphService.fetchSinglePage<
        ICertificateGraphQLResponse,
        ICertificateExtended
      >("loadCertificatesByIds", query, this.graphQLPropertiesToAddFunction);

      result.push(...content);

      cursor = pageInfo.endCursor;
      hasNextPage = result.length < totalCount;
    }

    return result;
  }

  private graphQLPropertiesToAddFunction = (edge: any): any => ({
    documents: edge.node.documents,
  });

  public get = async (id: string): Promise<ICertificate> =>
    await this.apiService.get<ICertificate>(`${this.getBaseUrl()}/${id}`).toPromise();

  public delete = async (id: string): Promise<void> =>
    await this.apiService.delete<void>(`${this.getBaseUrl()}/${id}`).toPromise();

  public createOrUpdate = async (
    payload: ICertificatePayload,
    id?: string,
  ): Promise<ICertificate> => {
    if (id) {
      return await this.apiService
        .put<ICertificate>(`${this.getBaseUrl()}/${id}`, payload)
        .toPromise();
    } else {
      return await this.apiService.post<ICertificate>(`${this.getBaseUrl()}`, payload).toPromise();
    }
  };

  public setRecordState = async (payload: IRecordState, id: string): Promise<ICertificate> =>
    await this.apiService.put<ICertificate>(`${this.getBaseUrl()}/${id}`, payload).toPromise();

  private getBaseUrl = (): string =>
    `${this.environment.baseUrl}organisations/${this.authenticationService.getActiveOrganisationId()}/certificates`;
}
