import { IBaseUnit, IItem, IProductExtended } from "@shared/interfaces";

import { ReportsEudrModel as Model } from "./reports-eudr.model";

export class EudrItem {
  public item: IItem;

  public product: IProductExtended;

  public baseUnit: IBaseUnit;

  public quantity: number;

  public customFieldValues: Model.RequiredCustomFieldValues = {} as Model.RequiredCustomFieldValues;

  constructor(item: IItem, product: IProductExtended, baseUnit: IBaseUnit, quantity: number) {
    this.item = item;
    this.product = product;
    this.baseUnit = baseUnit;
    this.quantity = quantity;
    this.setCustomFieldValues();
  }

  private setCustomFieldValues(): void {
    for (const customField of this.product.customFields || []) {
      const label = customField.definition.label;

      if (label) {
        this.customFieldValues[label] = customField.value;
      }
    }
  }
}
