<div class="container-flex-space-between">
  <div class="left-content">
    <div
      [ngClass]="{ ellipsis: type === cardContentTypeEnum.ADD }"
      class="name"
      [tp]="material.name"
      [tpDelay]="500"
      tpPlacement="top-start"
    >
      {{ material.name }}
    </div>
    <div class="type gray">
      {{ material.category }}
    </div>
  </div>
  <iov-button-group>
    @if (type === cardContentTypeEnum.ADD) {
      <iov-button size="medium" type="icon">
        <mat-icon icon-medium-18>add</mat-icon>
      </iov-button>
    } @else {
      <iov-button (pressed)="onClickRemove()" tp="Remove" size="medium" type="icon">
        <mat-icon icon-medium-18>close</mat-icon>
      </iov-button>
    }
  </iov-button-group>
</div>
