<div [formGroup]="formGroup" class="row-container">
  <div class="row">
    <div class="item">
      <iov-input enableViewMode formControlName="street" label="Address"></iov-input>
    </div>
  </div>

  <div class="row">
    <div class="item">
      <iov-input enableViewMode formControlName="region" label="State / Region"></iov-input>
    </div>
  </div>

  <div class="row">
    <div class="item">
      <iov-input enableViewMode formControlName="zipCode" label="ZIP / Postcode"></iov-input>
    </div>
  </div>

  <div class="row">
    <div class="item">
      <iov-input-select
        [options]="countryOptions"
        [viewModeTemplate]="countryViewMode"
        enableViewMode
        formControlName="country"
        label="Country"
      ></iov-input-select>
      <ng-template #countryViewMode let-label="label" let-option>
        <iov-form-input-readonly [label]="label" [value]="option.label">
          <app-flag [country]="option.value" input-readonly-prefix></app-flag>
        </iov-form-input-readonly>
      </ng-template>
    </div>
  </div>
</div>
