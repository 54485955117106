<div class="container-flex-space-between">
  <div class="left-content">
    <div class="location-types flex-wrap">
      @for (locationType of locationTypes; track $index) {
        <div class="location-type">
          <iov-tag
            class="in-card"
            [tag]="buildLocationTypeTag(locationType)"
            [maxTextLengthToShow]="8"
          ></iov-tag>
        </div>
      }
      @if (
        !!maxNumDisplayableLocationTypes &&
        this.location.types.length > maxNumDisplayableLocationTypes
      ) {
        <div class="location-type" [tp]="moreLocationTypes">
          <iov-tag class="in-card" [tag]="buildTag('...')"></iov-tag>
        </div>
      }
    </div>

    <div class="organisation-name break-word">{{ location.organisationName }}</div>

    <div [tp]="location.name" class="name">
      {{ location.name }}
    </div>
    <div>
      <iov-flag [country]="location.address.country"></iov-flag>
      <span class="country-name">
        {{ location.address.countryName }}
      </span>
    </div>
  </div>
  <iov-button-group>
    @if (type === cardContentTypeEnum.ADD) {
      <iov-button size="medium" type="icon" class="right">
        <mat-icon icon-medium-18>add</mat-icon>
      </iov-button>
    } @else {
      <iov-button (pressed)="onClickRemove()" tp="Remove" size="medium" type="icon">
        <mat-icon icon-medium-18>close</mat-icon>
      </iov-button>
    }
  </iov-button-group>
</div>
