@if (formGroupOrganisationId && this.overlay.editMode()) {
  <div [formGroup]="formGroup">
    <iov-checkbox
      class="checkbox-same-address"
      [label]="sameAsOrganisationAddressText"
      formControlName="sameAsOrganisationAddress"
    ></iov-checkbox>
  </div>
  <br />
}
