import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

import { DateUtils } from "@shared/utils";

@Pipe({
  name: "localTime",
})
export class LocalTimePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(utcTime: string, dateFormat: string = "medium"): string {
    return this.datePipe.transform(
      DateUtils.getFormattedDateForPipe(utcTime),
      dateFormat,
      this.getLocalTimeZone(),
    );
  }

  private getLocalTimeZone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
}
