import { DecimalPipe } from "@angular/common";
import { Injectable } from "@angular/core";

import { ICellRendererParams } from "ag-grid-community";

import { CommonUtils } from "../utils";

@Injectable({
  providedIn: "root",
})
export class UnitOfMeasurementCellRendererService {
  constructor(private decimalPipe: DecimalPipe) {}

  getValue = (data: any, params: ICellRendererParams): string => {
    const formattedValue = params["formattedQuantityParam"]
      ? CommonUtils.getObjectNestedProperty(params["formattedQuantityParam"], data)
      : null;

    if (formattedValue) {
      return formattedValue;
    }
    if (!params.value) {
      return "";
    }
    const precision = params["precisionParam"]
      ? CommonUtils.getObjectNestedProperty(params["precisionParam"], data)
      : (data.unitOfMeasurement?.precision ?? 0);

    const symbol = params["symbolParam"]
      ? CommonUtils.getObjectNestedProperty(params["symbolParam"], data)
      : (data.unitOfMeasurement?.symbol ?? "");

    return this.formatValue(params.value, precision, symbol);
  };

  formatValue = (value: number, precision: number, symbol: string): string => {
    return `${this.decimalPipe.transform(value, `1.0-${precision ?? "0"}`)} ${symbol ?? ""}`;
  };
}
