<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <h2 mat-dialog-title>{{ isEditing() ? "Edit" : "Add" }} unit of measurement</h2>
  @if (isLoading()) {
    <app-loader containerClass="on-dialog"></app-loader>
  } @else {
    <mat-dialog-content>
      <div class="row-container">
        <div class="row row-2">
          <div class="item">
            <iov-input
              [autofocus]="!isEditing()"
              label="Name"
              formControlName="name"
              type="text"
            ></iov-input>
          </div>
          <div class="item">
            <iov-input-select
              label="Category"
              formControlName="category"
              [options]="unitTypesOptions"
            ></iov-input-select>
          </div>
        </div>
      </div>
      <div class="container-flex symbol-container">
        <iov-input
          [prefix]="categoryControl.valid || categoryControl.disabled ? '1' : ' '"
          [class]="isSafari ? 'width-165' : ''"
          label="Symbol"
          formControlName="symbol"
          customErrorClass="absolute"
          type="text"
        ></iov-input>
        =
        <iov-input
          label="Conversion factor"
          formControlName="conversionFactor"
          [placeholder]="categoryControl.invalid ? 'Select category first' : 'Conversion factor'"
          type="number"
          [disabled]="
            categoryControl.invalid || categoryControl.value?.value === unitTypesEnum.UNITS
          "
          customErrorClass="absolute"
          [suffixTooltip]="categoryControl.value?.value | unitSymbol: baseUnits : 'name'"
          [suffix]="categoryControl.value?.value | unitSymbol: baseUnits"
        ></iov-input>
        <iov-input
          [class]="isSafari ? 'width-165' : ''"
          label="Display scale"
          type="number"
          customErrorClass="absolute"
          [disabled]="categoryControl.value?.value === unitTypesEnum.UNITS"
          tooltip="Determines how many digits after the decimal point will be displayed on quantities
                    that are measured in this unit of measurement. E.g. if you state 2, the value will be
                    rounded to the first 2 decimals: e.g. 5.716 will be rounded to 5.72."
          formControlName="displayScale"
        ></iov-input>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <iov-button-group>
        <iov-button (pressed)="onClose()" type="ghost">Cancel</iov-button>
        <iov-button [disabled]="isSubmitButtonDisabled()" accessibilityRole="submit">
          <mat-icon icon-left>check</mat-icon>
          Save
        </iov-button>
      </iov-button-group>
    </mat-dialog-actions>
  }
</form>
