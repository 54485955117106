import { FormArray, FormControl, FormGroup } from "@angular/forms";

import { BulkAddSetValuesModel } from "@components/items/bulk-add-items/set-values/bulk-add-set-values.model";
import { DateTypeEnum } from "@shared/enums";
import { ISelectOption, ITagExtended } from "@shared/interfaces";

export namespace BulkAddItemsModel {
  export interface IItemFieldSharedFormGroup {
    [FieldEnum.TAGS]: FormControl<ITagExtended[]>;
    [FieldEnum.PRODUCT]: FormControl<ISelectOption>;
    [FieldEnum.MATERIALS]: FormControl<ISelectOption[]>;
    [FieldEnum.INITIAL_QUANTITY]: FormControl<number>;
    [FieldEnum.UNIT_OF_MEASUREMENT]: FormControl<ISelectOption>;
    [FieldEnum.CREATED_AT_LOCATION]: FormControl<ISelectOption>;
    [FieldEnum.CREATED_FROM]: FormControl<string>;
    [FieldEnum.CREATED_RANGE]: FormControl<[string, string]>;
    [FieldEnum.IS_RANGE_DATE_TYPE]: FormControl<boolean>;
    [FieldEnum.DATE_TYPE]: FormControl<DateTypeEnum>;
    [FieldEnum.CURRENT_LOCATION]: FormControl<ISelectOption>;
    [FieldEnum.UNIT_OF_MEASUREMENT_OPTIONS]: FormControl<ISelectOption[]>;
    [FieldEnum.BASE_INITIAL_QUANTITY]: FormControl<number>;
  }

  export interface SetValuesFormGroup extends IItemFieldSharedFormGroup {
    mode: FormControl<BulkAddSetValuesModel.ModeEnum>;
    [FieldEnum.IS_FIXED_TAGS]: FormControl<boolean>;
    [FieldEnum.IS_FIXED_PRODUCT]: FormControl<boolean>;
    [FieldEnum.IS_FIXED_MATERIALS]: FormControl<boolean>;
    [FieldEnum.IS_FIXED_INITIAL_QUANTITY]: FormControl<boolean>;
    [FieldEnum.IS_FIXED_UNIT_OF_MEASUREMENT]: FormControl<boolean>;
    [FieldEnum.IS_FIXED_CREATED_AT_LOCATION]: FormControl<boolean>;
    [FieldEnum.IS_FIXED_CREATION_DATE]: FormControl<boolean>;
    [FieldEnum.IS_FIXED_CURRENT_LOCATION]: FormControl<boolean>;
    items: FormArray<FormGroup<ItemFormGroup>>;
  }

  export interface ItemFormGroup extends IItemFieldSharedFormGroup {
    [FieldEnum.ITEM_ID]: FormControl<string>;
    [FieldEnum.PREVIOUS_PRODUCT]: FormControl<ISelectOption>;
    [FieldEnum.IS_ITEM_ID_DUPLICATED]: FormControl<boolean>;
    [FieldEnum.DELIVERY_QUANTITY]?: FormControl<number>;
    [FieldEnum.BASE_DELIVERY_QUANTITY]?: FormControl<number>;
  }

  export type FormGroupRawValue<T> = {
    [K in keyof T]: T[K] extends FormControl<infer U> ? U : never;
  };

  export type ItemFormRawValue =
    BulkAddItemsModel.FormGroupRawValue<BulkAddItemsModel.ItemFormGroup>;

  export enum FieldEnum {
    ITEM_ID = "itemId",
    TAGS = "tags",
    PRODUCT = "product",
    MATERIALS = "materials",
    INITIAL_QUANTITY = "initialQuantity",
    UNIT_OF_MEASUREMENT = "unitOfMeasurement",
    CREATED_AT_LOCATION = "createdAtLocation",
    CREATED_FROM = "createdFrom",
    CREATED_RANGE = "createdRange",
    DATE_TYPE = "dateType",
    CURRENT_LOCATION = "currentLocation",
    PREVIOUS_PRODUCT = "previousProduct",
    IS_RANGE_DATE_TYPE = "isRangeDateType",
    UNIT_OF_MEASUREMENT_OPTIONS = "unitOfMeasurementOptions",
    BASE_INITIAL_QUANTITY = "baseInitialQuantity",
    DELIVERY_QUANTITY = "deliveryQuantity",
    BASE_DELIVERY_QUANTITY = "baseDeliveryQuantity",
    IS_FIXED_TAGS = "isFixedTags",
    IS_FIXED_PRODUCT = "isFixedProduct",
    IS_FIXED_MATERIALS = "isFixedMaterials",
    IS_FIXED_INITIAL_QUANTITY = "isFixedInitialQuantity",
    IS_FIXED_UNIT_OF_MEASUREMENT = "isFixedUnitOfMeasurement",
    IS_FIXED_CREATED_AT_LOCATION = "isFixedCreatedAtLocation",
    IS_FIXED_CREATION_DATE = "isFixedCreationDate",
    IS_FIXED_CURRENT_LOCATION = "isFixedCurrentLocation",
    IS_ITEM_ID_DUPLICATED = "isItemIdDuplicated",
    ITEMS = "items",
  }

  export const fieldEnumToLabelMap: Partial<Record<FieldEnum, string>> = {
    [FieldEnum.ITEM_ID]: "ID",
    [FieldEnum.DELIVERY_QUANTITY]: "Delivery q-ty",
    [FieldEnum.TAGS]: "Tags",
    [FieldEnum.PRODUCT]: "Product",
    [FieldEnum.MATERIALS]: "Materials",
    [FieldEnum.INITIAL_QUANTITY]: "Initial quantity",
    [FieldEnum.UNIT_OF_MEASUREMENT]: "Unit of measurement",
    [FieldEnum.CREATED_AT_LOCATION]: "Created at location",
    [FieldEnum.CREATED_FROM]: "Created from",
    [FieldEnum.CREATED_RANGE]: "Created range",
    [FieldEnum.CURRENT_LOCATION]: "Current location",
  };

  export const entityExistsValidatorArgs = {
    searchPropertyName: FieldEnum.ITEM_ID,
    searchPropertyErrorDisplayName: "ID",
  };

  export enum StepsEnum {
    SET_VALUES = 0,
    ENTER_ITEMS = 1,
    CREATE_ITEMS = 2,
  }
}
