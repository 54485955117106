import { Injectable } from "@angular/core";

import { StorageKeyEnum } from "../enums";

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  private readonly localStoragePrefix = "interu_";

  private readonly usersLastActiveOrganisationIdFullStorageKey = `${this.localStoragePrefix}${StorageKeyEnum.LAST_ACTIVE_ORGANISATION_PER_USER}`;

  public get = (key: string): string | null =>
    localStorage.getItem(`${this.localStoragePrefix}${key}`);

  public set = (key: string, value: string): void =>
    localStorage.setItem(`${this.localStoragePrefix}${key}`, value);

  public remove = (key: string): void =>
    localStorage.removeItem(`${this.localStoragePrefix}${key}`);

  public removeMany = (keys: string[]): void => {
    for (const key of keys) {
      this.remove(key);
    }
  };

  public clear = (): void => localStorage.clear();

  public getUserLastActiveOrganisationId = (userId: string): string => {
    const map = this.getUserLastActiveOrganisationIdMap();

    if (!map) {
      return null;
    }

    return map.get(userId);
  };

  public setUserLastActiveOrganisationId = (userId: string, organisationId: string): void => {
    let map = this.getUserLastActiveOrganisationIdMap();

    if (!map) {
      map = new Map();
    }
    map.set(userId, organisationId);
    const serialisedMap = JSON.stringify(Array.from(map.entries()));

    localStorage.setItem(this.usersLastActiveOrganisationIdFullStorageKey, serialisedMap);
  };

  public removeUserLastActiveOrganisationId = (userId: string): void => {
    const map = this.getUserLastActiveOrganisationIdMap();

    if (!map) {
      return;
    }
    map.delete(userId);
    const serialisedMap = JSON.stringify(Array.from(map.entries()));

    localStorage.setItem(this.usersLastActiveOrganisationIdFullStorageKey, serialisedMap);
  };

  public removeNoLongerUsedItems = (): void => {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i).replace(this.localStoragePrefix, "");

      if (
        !Object.values(StorageKeyEnum).includes(key as StorageKeyEnum) &&
        !key.startsWith(`table-`)
      ) {
        localStorage.removeItem(`${this.localStoragePrefix}${key}`);
      }
    }
  };

  public removeSavedTableItems = (): void => {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i).replace(this.localStoragePrefix, "");

      if (key.startsWith(`table-`)) {
        localStorage.removeItem(`${this.localStoragePrefix}${key}`);
      }
    }
  };

  public secureSet(key: string, data: unknown): void {
    this.set(key, JSON.stringify({ data }));
  }

  public secureGet(key: string): unknown {
    const stringified = this.get(key);
    let data: any = {};

    if (stringified) {
      data = JSON.parse(stringified);
    }

    return data.data;
  }

  private getUserLastActiveOrganisationIdMap = (): Map<string, string> | null => {
    const storageMap = localStorage.getItem(this.usersLastActiveOrganisationIdFullStorageKey);

    if (!storageMap) {
      return null;
    }

    return new Map(JSON.parse(storageMap));
  };
}
