<h1 *ngIf="data.titleTranslatedText" mat-dialog-title class="text-center">
  {{ data.titleTranslatedText }}
</h1>
<mat-dialog-content class="text-center">
  <mat-icon *ngIf="data.icon" [class]="data.iconColor">{{ data.icon }}</mat-icon>

  <p *ngIf="data.contentTranslatedText">
    {{ data.contentTranslatedText }}
  </p>

  <div *ngIf="data.contentSafeHTML" [innerHTML]="data.contentSafeHTML"></div>
</mat-dialog-content>
<mat-dialog-actions>
  <iov-button (pressed)="onClose()" cdkFocusInitial>Close</iov-button>
</mat-dialog-actions>
