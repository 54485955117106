import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "[iovNoScrollInput]",
  standalone: true,
})
export class NoScrollInputDirective {
  @HostListener("wheel", ["$event"])
  @HostListener("scroll", ["$event"])
  onWheel(event: WheelEvent | Event): void {
    event.preventDefault();
    if (event["deltaY"]) {
      // Manually handle the scrolling of the page
      window.scrollBy(0, event["deltaY"]);
    }
  }
}
