import { ChangeDetectionStrategy, Component, signal } from "@angular/core";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { AdminOrganisationsService } from "@shared/services";

@Component({
  templateUrl: "./admin-users.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminUsersComponent {
  public allOrganisations: any[] = [];

  public isLoadingOrganisations = signal(true);

  constructor(
    private adminOrganisationsService: AdminOrganisationsService,
    private notificationService: NotificationService,
  ) {
    this.onReloadOrganisations();
  }

  private onReloadOrganisations = async (): Promise<void> => {
    this.isLoadingOrganisations.set(true);
    try {
      this.allOrganisations = await this.adminOrganisationsService.getAll();
      this.isLoadingOrganisations.set(false);
    } catch (error) {
      this.notificationService.showError(error);
    }
  };
}
