<mat-accordion>
  <mat-expansion-panel class="mat-elevation-z0 section-box" [expanded]="false">
    <mat-expansion-panel-header class="section-header">
      <mat-panel-title class="section-title">
        <div class="location-country-group">
          <app-flag [country]="locationsCountryGroup().key"></app-flag>
          <span class="country-name">{{ countryName }} ({{ locationsCount }})</span>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div>
      @for (
        locationsGroupByType of locationsCountryGroup().value | keyvalue;
        track locationsGroupByType.key
      ) {
        <div class="locations-grouped-by-type">
          <app-delivery-report-location-type-group
            [locationsGroupByType]="locationsGroupByType"
          ></app-delivery-report-location-type-group>
        </div>
      }
    </div>
  </mat-expansion-panel>
</mat-accordion>
