import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  Output,
  inject,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

import { CardContentComponent } from "@design-makeover/components/cards/card-content/card-content.component";
import { ItemCardContentDisplayTypeEnum } from "@design-makeover/components/cards/item-card-content/item-card-content.model";

import { FeatureFlagEnum } from "@shared/enums";
import { IItemDetails } from "@shared/interfaces";
import { FeatureFlagService } from "@shared/services";

@Component({
  selector: "iov-item-card-content",
  templateUrl: "./item-card-content.component.html",
  styleUrl: "./item-card-content.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemCardContentComponent extends CardContentComponent implements AfterViewInit {
  @Input() item: IItemDetails;

  @Input() displayType = ItemCardContentDisplayTypeEnum.QUANTITY;

  @Input() class: string;

  @Input() isShowTags = true;

  @Output() itemQuantityChanged: EventEmitter<void> = new EventEmitter<void>();

  private destroyRef = inject(DestroyRef);

  readonly itemCardContentDisplayTypeEnum = ItemCardContentDisplayTypeEnum;

  private featureFlagService = inject(FeatureFlagService);

  public readonly isOldMaterialsEnabled = !this.featureFlagService.isEnabled(
    FeatureFlagEnum.NEW_MATERIALS_BEHAVIOUR,
  );

  ngAfterViewInit(): void {
    if (this.item.formGroup) {
      this.item.formGroup.valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => this.itemQuantityChanged.emit());
    }
  }
}
