import { ColDef, ValueFormatterFunc, ValueFormatterParams } from "ag-grid-community";

import { CommonUtils } from "@shared/utils/common.utils";

import { CellRendererUtils } from "./cell-renderer.utils";
import {
  ButtonsCellRendererComponent,
  ChipsCellRendererComponent,
  LinkCellRendererComponent,
} from "../cell-renderers";
import { RecordStateEnum } from "../enums";

const iconColumnCommonValues = {
  resizable: false,
  width: 40,
  maxWidth: 40,
  suppressSizeToFit: true,
  suppressAutoSize: true,
  suppressHeaderMenuButton: true,
  suppressFiltersToolPanel: true,
  suppressMovable: true,
  suppressHeaderFilterButton: true,
  suppressColumnsToolPanel: true,
  sortable: false,
  cellClass: "container-flex-center",
};

const recordState = (): ColDef => ({
  ...iconColumnCommonValues,
  headerName: "Record state",
  field: "recordState",
  cellRenderer: CellRendererUtils.recordState,
  filter: "agTextColumnFilter",
  tooltipValueGetter: (params: any) =>
    params.value === RecordStateEnum.ARCHIVED ? "Archived" : "",
});

const buttons = (buttons: any[]): ColDef => ({
  ...iconColumnCommonValues,
  width: Math.max(90, buttons.length * 65),
  maxWidth: Math.max(90, buttons.length * 65),
  cellRenderer: ButtonsCellRendererComponent,
  cellRendererParams: {
    buttons,
  },
});

export const arrayValueFormatter = (row: any, cellRendererParams?: any): any => {
  if (typeof cellRendererParams === "function") {
    cellRendererParams = cellRendererParams(row);
  }

  if (!row.value) {
    return "-";
  }
  if (Array.isArray(row.value)) {
    return row.value
      .map((t) =>
        typeof t === "string"
          ? t
          : CommonUtils.getObjectNestedProperty(cellRendererParams["textParam"] as string, t),
      )
      .join(", ");
  }

  return typeof row.value === "string"
    ? row.value
    : CommonUtils.getObjectNestedProperty(cellRendererParams["textParam"] as string, row.value);
};

const arrayQuickFilterText = (row: any, cellRendererParams?: any): any => {
  if (typeof cellRendererParams === "function") {
    cellRendererParams = cellRendererParams(row);
  }

  if (!row.value) {
    return "";
  }
  if (Array.isArray(row.value)) {
    return row.value
      .map((t) =>
        typeof t === "string"
          ? t
          : CommonUtils.getObjectNestedProperty(cellRendererParams["textParam"] as string, t),
      )
      .join(", ");
  }

  return typeof row.value === "string"
    ? row.value
    : CommonUtils.getObjectNestedProperty(cellRendererParams["textParam"] as string, row.value);
};

const multipleLinks = (headerName: string, field: string, cellRendererParams: any): ColDef => ({
  headerName,
  field,
  cellRenderer: LinkCellRendererComponent,
  cellRendererParams,
  suppressAutoSize: true,
  suppressSizeToFit: true,
  valueFormatter: (row: any) => arrayValueFormatter(row, cellRendererParams),
  getQuickFilterText: (row) => arrayQuickFilterText(row, cellRendererParams),
});

const chips = (
  headerName: string,
  field: string,
  cellRendererParams?: any,
  options?: Partial<ColDef>,
): ColDef => {
  const defaultColDef: ColDef = {
    headerName,
    field,
    cellRenderer: ChipsCellRendererComponent,
    cellRendererParams,
    cellClass: "container-flex",
    suppressSizeToFit: true,
    suppressAutoSize: true,
    valueFormatter: (row: any) => arrayValueFormatter(row, cellRendererParams),
    getQuickFilterText: (row) => arrayQuickFilterText(row, cellRendererParams),
  };

  if (options) {
    return { ...defaultColDef, ...options };
  }

  return defaultColDef;
};

const tags = (headerName: string, field: string = "tags"): ColDef =>
  chips(headerName, field, {
    textParam: "title",
    classParam: "color",
  });

const selectCheckbox = (): ColDef => ({
  ...iconColumnCommonValues,
  cellClass: undefined,
  width: 60,
  maxWidth: 60,
  checkboxSelection: true,
  headerCheckboxSelection: true,
  showDisabledCheckboxes: true,
  headerCheckboxSelectionFilteredOnly: true,
  lockVisible: true,
  headerTooltip: "Select/Deselect all",
});

const formatterFn =
  <TData, TValue extends string | number | boolean>() =>
  (params: ValueFormatterParams<TData, TValue>): string => {
    return params?.value?.toString() ?? "-";
  };

const defaultValueFormatter = <
  TData,
  TValue extends string | number | boolean,
>(): ValueFormatterFunc<TData, TValue> => {
  return formatterFn<TData, TValue>();
};

const longTextValueFormatter =
  <TData, TValue extends string>() =>
  (params: ValueFormatterParams<TData, TValue>, maxLength = 120): string =>
    params?.value
      ? params.value.length > maxLength
        ? params.value.slice(0, maxLength) + "..."
        : params.value
      : "-";

export const ColumnUtils = {
  iconColumnCommonValues,
  recordState,
  buttons,
  multipleLinks,
  chips,
  tags,
  selectCheckbox,
  defaultValueFormatter,
  longTextValueFormatter,
};
