@if (isLoading()) {
  <app-loader></app-loader>
} @else {
  <div class="container-space-between">
    <app-page-title
      [goBackRoute]="routingEnum.ADMIN_RULESETS"
      [goBackRouteState]="{ selectedTabResourceType: resourceType }"
      [tag]="element()?.recordState === recordStateEnum.ARCHIVED ? 'Archived' : ''"
      [title]="element()?.name"
      backText="Back to Rulesets"
    ></app-page-title>

    @if (canAddOrModifyRulesets) {
      <iov-button-group>
        <app-more-actions-buttons
          (clickLabel)="onEdit()"
          (reloadData)="loadData($event)"
          [isDeleteEnabled]="true"
          [disabledMainButton]="element()?.recordState !== recordStateEnum.ACTIVE"
          [element]="element()"
          [service]="rulesetsService"
          label="Edit"
          labelIcon="edit"
        ></app-more-actions-buttons>
      </iov-button-group>
    }
  </div>

  <mat-accordion>
    <mat-expansion-panel class="mat-elevation-z0 section-box" [expanded]="true">
      <mat-expansion-panel-header class="section-header">
        <mat-panel-title class="section-title">
          <h3>{{ mainInformationText }}</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="row-container">
        <div class="row">
          <div class="item">
            <p class="details-label">Ruleset for</p>
            <app-value-and-copy-button
              valueClass="details-value"
              [value]="element().resourceType | enumToText: true"
            ></app-value-and-copy-button>
          </div>
          <div class="item">
            <p class="details-label">Description</p>
            <app-value-and-copy-button
              valueClass="details-value"
              [value]="element().description"
            ></app-value-and-copy-button>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <br />
  <mat-accordion>
    <mat-expansion-panel class="mat-elevation-z0 section-box" [expanded]="true">
      <mat-expansion-panel-header class="section-header">
        <mat-panel-title class="section-title"><h3>Criteria</h3></mat-panel-title>
      </mat-expansion-panel-header>
      @switch (resourceType) {
        @case (resourceTypeEnum.LOCATION) {
          <div class="row-container">
            <div class="row row-1">
              <div class="item">
                <p class="details-label">Location types for</p>
                <iov-tag-group
                  [tags]="getLocationTypesTags(selectedLocationTypes())"
                ></iov-tag-group>
              </div>
            </div>

            <div class="row row-1">
              <div class="item">
                <p class="details-label">Location countries</p>
                <div class="tags-container flex-wrap">
                  @for (country of selectedCountries(); track country.value) {
                    <iov-tag [tag]="{ label: country.label }">
                      <app-flag [country]="country.value.toString()"></app-flag>
                    </iov-tag>
                  } @empty {
                    <iov-tag [tag]="{ label: 'All' }"></iov-tag>
                  }
                </div>
              </div>
            </div>
          </div>
        }
        @case (resourceTypeEnum.DELIVERY) {
          <h4>Deliveries From locations</h4>
          <div class="row-container">
            @if (deliveryFromCriteria() === criteriaTypeEnum.PARAMETERS) {
              <ng-container
                [ngTemplateOutlet]="parametersCriteriaTemplate"
                [ngTemplateOutletContext]="{
                  countries: selectedLocationFromCountries(),
                  locationTypeTags: getLocationTypesTags(selectedLocationFromTypes()),
                }"
              ></ng-container>
            } @else {
              <ng-container
                [ngTemplateOutlet]="exactCriteriaTemplate"
                [ngTemplateOutletContext]="{
                  locationNames: getLocationNames(selectedLocationsFrom()),
                }"
              ></ng-container>
            }
          </div>
          <h4>Deliveries To locations</h4>
          <div class="row-container">
            @if (deliveryToCriteria() === criteriaTypeEnum.PARAMETERS) {
              <ng-container
                [ngTemplateOutlet]="parametersCriteriaTemplate"
                [ngTemplateOutletContext]="{
                  countries: selectedLocationToCountries(),
                  locationTypeTags: getLocationTypesTags(selectedLocationToTypes()),
                }"
              ></ng-container>
            } @else {
              <ng-container
                [ngTemplateOutlet]="exactCriteriaTemplate"
                [ngTemplateOutletContext]="{
                  locationNames: getLocationNames(selectedLocationsTo()),
                }"
              ></ng-container>
            }
          </div>
        }
      }
    </mat-expansion-panel>
  </mat-accordion>
  <br />
  <mat-accordion>
    <mat-expansion-panel class="mat-elevation-z0 section-box" [expanded]="true">
      <mat-expansion-panel-header class="section-header">
        <mat-panel-title class="section-title">
          <h3>Expectations</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="row-container">
        <p class="details-label">Required documents</p>
        <app-document-types-table
          [documentTypes]="selectedDocumentTypes()"
          [areButtonsEnabled]="false"
          [isSearchEnabled]="false"
          [columns]="['type']"
        ></app-document-types-table>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
}

<ng-template #exactCriteriaTemplate let-locationNames="locationNames">
  <div class="row row-1">
    <div class="item">
      <iov-tag-group [tags]="locationNames"></iov-tag-group>
    </div>
  </div>
</ng-template>

<ng-template
  #parametersCriteriaTemplate
  let-countries="countries"
  let-locationTypeTags="locationTypeTags"
>
  <div class="row row-1">
    <div class="item">
      <p class="details-label">Type(s)</p>
      <iov-tag-group [tags]="locationTypeTags"></iov-tag-group>
    </div>
  </div>

  <div class="row row-1">
    <div class="item">
      <p class="details-label">Country(ies)</p>
      <div class="tags-container flex-wrap">
        @for (country of countries; track country.value) {
          <iov-tag [tag]="{ label: country.label }">
            <app-flag [country]="country.value.toString()"></app-flag>
          </iov-tag>
        } @empty {
          <iov-tag [tag]="{ label: 'All' }"></iov-tag>
        }
      </div>
    </div>
  </div>
</ng-template>
