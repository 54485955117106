import { inject, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute, Params } from "@angular/router";

import { tap } from "rxjs";
import { CanDeactivateClass } from "src/app/shared/classes/can-deactivate.class";
import { LinkDirectionEnum, RecordStateEnum } from "src/app/shared/enums";
import {
  IDocumentType,
  ILocation,
  ILocationLink,
  ILocationLinkDetail,
  ISelectOption,
  IStandard,
  ISupplyChainDetail,
  ISupplyChainGraphDetail,
} from "src/app/shared/interfaces";
import { getSupplyChainQuery } from "src/app/shared/queries/supply-chain.query";
import {
  CommonService,
  DocumentTypesService,
  LocationsLinksService,
  LocationTypesService,
  StandardsService,
  SupplyChainsService,
} from "src/app/shared/services";

import { SupplyChainMapperService } from "./services/supply-chain-mapper.service";

export abstract class SupplyChainAbstractClass extends CanDeactivateClass {
  public locations: ILocation[] = [];

  public locationLinks: ILocationLinkDetail[] = [];

  public isLoading = signal(true);

  public allStandards: IStandard[] = [];

  public allDocumentTypes: IDocumentType[] = [];

  public supplyChain: ISupplyChainDetail;

  protected readonly activeOrganisation = this.authenticationService.getActiveOrganisation();

  public countryOptions: ISelectOption[];

  public readonly recordStateEnum = RecordStateEnum;

  protected locationTypesService = inject(LocationTypesService);

  protected standardsService = inject(StandardsService);

  protected documentTypesService = inject(DocumentTypesService);

  protected supplyChainsService = inject(SupplyChainsService);

  protected commonService = inject(CommonService);

  protected route = inject(ActivatedRoute);

  protected supplyChainMapperService = inject(SupplyChainMapperService);

  protected locationLinksService = inject(LocationsLinksService);

  constructor() {
    super();
    this.commonService.countriesOptionsObservable$
      .pipe(
        takeUntilDestroyed(),
        tap(async (countriesOptions: ISelectOption[]) => {
          this.countryOptions = countriesOptions;
        }),
      )
      .subscribe();
    this.route.params
      .pipe(
        takeUntilDestroyed(),
        tap(async (params: Params) => {
          await this.loadData(params["id"]);
        }),
      )
      .subscribe();
  }

  abstract loadData(id: string): Promise<void>;

  async getSupplyChainDetail(id: string): Promise<ISupplyChainDetail> {
    const supplyChainGraph = await this.getSupplyChainGraph(id);

    if (supplyChainGraph?.loadSupplyChain === null) {
      return null;
    }

    return await this.supplyChainMapperService.mapGraphQlData(
      supplyChainGraph,
      this.activeOrganisation,
      this.countryOptions,
    );
  }

  async getSupplyChainGraph(id: string): Promise<ISupplyChainGraphDetail> {
    return await this.supplyChainsService.querySupplyChain(
      getSupplyChainQuery(this.activeOrganisation.id, id),
    );
  }

  async getDocumentTypes(): Promise<IDocumentType[]> {
    return await this.documentTypesService.getAll();
  }

  async getStandards(): Promise<IStandard[]> {
    return await this.standardsService.getAll();
  }

  async getAllLinks(): Promise<ILocationLink[]> {
    return await this.locationLinksService.getAll(LinkDirectionEnum.BOTH, null);
  }
}
