import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  booleanAttribute,
} from "@angular/core";

import { IBaseUnit, ICustomEvent, ICustomUnitOfMeasurement } from "@shared/interfaces";

import { CardContentComponent } from "../card-content/card-content.component";

@Component({
  selector: "iov-unit-of-measurement-card",
  templateUrl: "./unit-of-measurement-card.component.html",
  styleUrl: "./unit-of-measurement-card.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnitOfMeasurementCardComponent extends CardContentComponent {
  @Input()
  unitOfMeasurement: IBaseUnit & ICustomUnitOfMeasurement;

  @Input({ transform: booleanAttribute })
  isSystemUnit: boolean;

  @Input()
  defaultUnitOfMeasurementId: string;

  @Input({ transform: booleanAttribute })
  isToggleDisabled: boolean;

  @Output()
  defaultUnitChanged: EventEmitter<ICustomEvent> = new EventEmitter<ICustomEvent>();

  onDefaultUnitChaged(isEnabled: boolean): void {
    this.defaultUnitChanged.emit({ isEnabled, value: this.unitOfMeasurement.id });
  }
}
