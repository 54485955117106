import { inject, Injectable, OnDestroy, signal } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";

import { lastValueFrom, Observable, Subject, Subscription } from "rxjs";

import { UnitConversionPipe } from "@design-makeover/pipes/unit-conversion.pipe";
import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { BulkAddItemsModel } from "@components/items/bulk-add-items/bulk-add-items.component.model";
import { BulkAddItemsCreateItemsModel } from "@components/items/bulk-add-items/create-items/bulk-add-items-create-items.model";
import { BulkAddSetValuesModel } from "@components/items/bulk-add-items/set-values/bulk-add-set-values.model";
import { ConfirmDialogComponent } from "@components/shared";
import {
  ConfirmDialogResponseEnum,
  DateTypeEnum,
  EntityTypeEnum,
  RecordStateEnum,
} from "@shared/enums";
import {
  IBaseUnit,
  ICustomUnitOfMeasurement,
  IDelivery,
  IDeliveryPayload,
  IItem,
  IMaterial,
  IProduct,
  ISelectOption,
  ITagExtended,
  ITagPayload,
} from "@shared/interfaces";
import {
  AuthenticationService,
  CommonService,
  DeliveriesService,
  ItemsService,
  MaterialsService,
  ProductsService,
  TagsService,
  UnitsOfMeasurementService,
} from "@shared/services";
import { CommonUtils, FormUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

@Injectable({
  providedIn: "root",
})
export class BulkAddItemsService implements OnDestroy {
  private productsService: ProductsService = inject(ProductsService);

  private unitsOfMeasurementsService: UnitsOfMeasurementService = inject(UnitsOfMeasurementService);

  private tagsService: TagsService = inject(TagsService);

  private materialsService: MaterialsService = inject(MaterialsService);

  private unitConversionPipe: UnitConversionPipe = inject(UnitConversionPipe);

  private commonService: CommonService = inject(CommonService);

  private dialog: MatDialog = inject(MatDialog);

  private authenticationService: AuthenticationService = inject(AuthenticationService);

  private itemsService: ItemsService = inject(ItemsService);

  private notificationService: NotificationService = inject(NotificationService);

  private deliveriesService: DeliveriesService = inject(DeliveriesService);

  private route: ActivatedRoute = inject(ActivatedRoute);

  public refreshTableSubject = new Subject();

  public abortController: AbortController;

  public allProducts: IProduct[] = [];

  public allMaterials: IMaterial[] = [];

  public materialOptionsForSetValuesStep: ISelectOption[] = [];

  public customUnitOfMeasurements: ICustomUnitOfMeasurement[] = [];

  public baseUnitOfMeasurements: IBaseUnit[] = [];

  public initialFormValue: object;

  public formGroup: FormGroup<BulkAddItemsModel.SetValuesFormGroup>;

  public isNextButtonDisabled = signal<boolean>(false);

  public delivery = signal<IDelivery>(null);

  private activeOrganisationId: string;

  private entityType = EntityTypeEnum.ITEMS;

  private readonly fieldEnum = BulkAddItemsModel.FieldEnum;

  private subscriptions: Subscription = new Subscription();

  constructor() {
    this.subscriptions.add(
      this.commonService.unitOfMeasurementsObservable$.subscribe(
        (baseUnitOfMeasurements: IBaseUnit[]) => {
          this.baseUnitOfMeasurements = baseUnitOfMeasurements;
        },
      ),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public initializeProperties(): void {
    this.delivery.set(null);
    this.abortController = new AbortController();
    this.activeOrganisationId = this.authenticationService.getActiveOrganisationId();
    this.materialOptionsForSetValuesStep = [];
  }

  public setupForm(): void {
    const { fieldEnum } = this;

    this.formGroup = new FormGroup<BulkAddItemsModel.SetValuesFormGroup>({
      mode: new FormControl(BulkAddSetValuesModel.defaultMode),
      [fieldEnum.TAGS]: new FormControl([]),
      [fieldEnum.PRODUCT]: new FormControl(),
      [fieldEnum.MATERIALS]: new FormControl({ value: [], disabled: true }),
      [fieldEnum.INITIAL_QUANTITY]: new FormControl({ value: null, disabled: true }, [
        CustomValidators.greaterThan(0, true),
      ]),
      [fieldEnum.UNIT_OF_MEASUREMENT]: new FormControl({ value: null, disabled: true }),
      [fieldEnum.CREATED_AT_LOCATION]: new FormControl(),
      [fieldEnum.CREATED_FROM]: new FormControl(null, [CustomValidators.date]),
      [fieldEnum.CREATED_RANGE]: new FormControl([null, null], [CustomValidators.dateRange]),
      [fieldEnum.IS_RANGE_DATE_TYPE]: new FormControl(false),
      [fieldEnum.DATE_TYPE]: new FormControl(DateTypeEnum.EXACT),
      [fieldEnum.CURRENT_LOCATION]: new FormControl(),
      [fieldEnum.BASE_INITIAL_QUANTITY]: new FormControl(),
      [fieldEnum.UNIT_OF_MEASUREMENT_OPTIONS]: new FormControl([]),
      [fieldEnum.IS_FIXED_TAGS]: new FormControl(false),
      [fieldEnum.IS_FIXED_PRODUCT]: new FormControl({ value: false, disabled: true }),
      [fieldEnum.IS_FIXED_MATERIALS]: new FormControl({ value: false, disabled: true }),
      [fieldEnum.IS_FIXED_INITIAL_QUANTITY]: new FormControl({ value: false, disabled: true }),
      [fieldEnum.IS_FIXED_UNIT_OF_MEASUREMENT]: new FormControl({ value: false, disabled: true }),
      [fieldEnum.IS_FIXED_CREATED_AT_LOCATION]: new FormControl({ value: false, disabled: true }),
      [fieldEnum.IS_FIXED_CREATION_DATE]: new FormControl({ value: false, disabled: true }),
      [fieldEnum.IS_FIXED_CURRENT_LOCATION]: new FormControl({ value: false, disabled: true }),
      [fieldEnum.ITEMS]: new FormArray([]),
    });

    this.initialFormValue = this.formGroup.getRawValue();

    this.subscriptions.add(
      this.formGroup.statusChanges.subscribe((status) => {
        this.isNextButtonDisabled.set(status === "PENDING");
      }),
    );
  }

  public get refreshTable$(): Observable<unknown> {
    return this.refreshTableSubject.asObservable();
  }

  public async setDelivery(): Promise<void> {
    const deliveryId = this.route.snapshot.queryParams["deliveryId"];

    if (deliveryId) {
      this.delivery.set(await this.deliveriesService.get(deliveryId));
    }
  }

  public async getCustomUnitOfMeasurements(): Promise<ICustomUnitOfMeasurement[]> {
    return (this.customUnitOfMeasurements = await this.unitsOfMeasurementsService.getAll());
  }

  public async getAllProducts(): Promise<IProduct[]> {
    try {
      return (this.allProducts = await this.productsService.getAll(RecordStateEnum.ACTIVE));
    } catch (error) {
      this.notificationService.showError(error);

      return [];
    }
  }

  public async getAllMaterials(): Promise<IMaterial[]> {
    try {
      return (this.allMaterials = await this.materialsService.getAll(RecordStateEnum.ACTIVE));
    } catch (error) {
      this.notificationService.showError(error);

      return [];
    }
  }

  public abortCreation(): void {
    this.abortController.abort();
  }

  public hasInitialFormValueChanged(currentFormValue: object): boolean {
    return FormUtils.hasInitialFormValueChanged(this.initialFormValue, currentFormValue);
  }

  public async canExitSlideOver(): Promise<boolean> {
    const hasChanged = this.hasInitialFormValueChanged(this.formGroup.getRawValue());

    if (!hasChanged) {
      return true;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: "Exit confirmation",
        contentTranslatedText: `You're about to leave this page. Are you sure you want to discard your changes?`,
        confirmButtonTranslatedText: "Discard changes & leave",
        confirmButtonColor: "danger",
        confirmButtonIcon: "close",
      },
    });

    const response = await lastValueFrom(dialogRef.afterClosed());

    return response === ConfirmDialogResponseEnum.CONFIRM;
  }

  public async createItem(
    payloadsWithStatus: BulkAddItemsCreateItemsModel.IPayloadWithStatus,
  ): Promise<IItem> {
    let item: IItem;

    const itemPayload = payloadsWithStatus.itemPayload;

    try {
      item = await this.itemsService.createOrUpdate(itemPayload);
    } catch (error) {
      payloadsWithStatus.status = BulkAddItemsCreateItemsModel.StatusEnum.ERROR;

      return null;
    }

    const entityUri = `/organisations/${this.activeOrganisationId}/${this.entityType}/${item.id}`;

    await this.saveTagsForNewRecord(itemPayload.tags, entityUri);

    payloadsWithStatus.status = BulkAddItemsCreateItemsModel.StatusEnum.SUCCESS;

    return item;
  }

  public async createItems(
    payloadsWithStatus: BulkAddItemsCreateItemsModel.IPayloadWithStatus[],
  ): Promise<IItem[]> {
    const items: IItem[] = [];

    for (let i = 0; i < payloadsWithStatus.length; i += 1) {
      if (this.abortController.signal.aborted) {
        payloadsWithStatus[i].status = BulkAddItemsCreateItemsModel.StatusEnum.CANCELED;
        continue;
      }

      const item = await this.createItem(payloadsWithStatus[i]);

      item.deliveredQuantity = payloadsWithStatus[i].itemPayload.deliveredQuantity;

      if (item) {
        items.push(item);
      }
    }

    return items;
  }

  public async addItemsToDelivery(items: IItem[]): Promise<void> {
    const delivery = this.delivery();

    const newDeliveryItems = items.map((item) => {
      return {
        item: `/organisations/${this.activeOrganisationId}/items/${item.id}`,
        quantity: item.deliveredQuantity,
      };
    });

    const payload: IDeliveryPayload = {
      id: delivery.id,
      deliveryId: delivery.deliveryId,
      from: delivery.from,
      to: delivery.to,
      status: delivery.status,
      sent: delivery.sent,
      delivered: delivery.delivered,
      agents: delivery.agents,
      customFields: delivery.customFields,
      items: [...delivery.items, ...newDeliveryItems],
    };

    await this.deliveriesService.createOrUpdate(payload, delivery.id);
  }

  async saveTagsForNewRecord(tags: ITagExtended[], entityUri: string): Promise<void> {
    const promises = tags.map((tag) => {
      const payload: ITagPayload = { entity: entityUri, definition: tag.definition };

      return this.tagsService.addToRecord(payload);
    });

    await Promise.all(promises);
  }

  public buildInitialQuantitySubscription(
    formGroup: FormGroup<BulkAddItemsModel.IItemFieldSharedFormGroup>,
  ): Subscription {
    const { fieldEnum } = this;
    const { controls } = formGroup;

    return controls[fieldEnum.INITIAL_QUANTITY].valueChanges.subscribe((initialQuantity) => {
      const productId = controls[fieldEnum.PRODUCT].value?.value;

      if (!productId) {
        return;
      }

      if (initialQuantity === null || String(initialQuantity) === "") {
        controls[fieldEnum.IS_FIXED_INITIAL_QUANTITY]?.setValue(false);
        controls[fieldEnum.IS_FIXED_INITIAL_QUANTITY]?.disable();
      } else {
        controls[fieldEnum.IS_FIXED_INITIAL_QUANTITY]?.enable();
        controls[fieldEnum.IS_FIXED_INITIAL_QUANTITY]?.setValue(true);
      }

      const product = this.allProducts.find((product) => product.id === productId);

      const baseUnitId = CommonUtils.getUriId(product.baseUnit);
      const baseUnit = this.baseUnitOfMeasurements.find((unit) => unit.id === baseUnitId);

      const unitOfMeasurementOption = controls[fieldEnum.UNIT_OF_MEASUREMENT].value;

      if (!unitOfMeasurementOption) {
        return;
      }

      if (baseUnit.id !== unitOfMeasurementOption.value) {
        const selectedUnitOfMeasurement = [
          ...this.baseUnitOfMeasurements,
          ...this.customUnitOfMeasurements,
        ].find((unit) => {
          return unit.id === unitOfMeasurementOption.value;
        });

        const value = +this.unitConversionPipe.transform(
          `${initialQuantity}`,
          selectedUnitOfMeasurement,
          baseUnit,
          true,
        );

        controls[fieldEnum.BASE_INITIAL_QUANTITY].setValue(value, { emitEvent: false });
      } else {
        controls[fieldEnum.BASE_INITIAL_QUANTITY].setValue(
          initialQuantity ? +initialQuantity : null,
          {
            emitEvent: false,
          },
        );
      }
    });
  }

  public buildDeliveryQuantitySubscription(
    formGroup: FormGroup<BulkAddItemsModel.IItemFieldSharedFormGroup>,
  ): Subscription {
    const { fieldEnum } = this;
    const { controls } = formGroup;

    return controls[fieldEnum.DELIVERY_QUANTITY].valueChanges.subscribe((deliveryQuantity) => {
      const productId = controls[fieldEnum.PRODUCT].value?.value;

      if (!productId) {
        return;
      }

      const product = this.allProducts.find((product) => product.id === productId);

      const baseUnitId = CommonUtils.getUriId(product.baseUnit);
      const baseUnit = this.baseUnitOfMeasurements.find((unit) => unit.id === baseUnitId);

      const unitOfMeasurementOption = controls[fieldEnum.UNIT_OF_MEASUREMENT].value;

      if (!unitOfMeasurementOption) {
        return;
      }

      if (baseUnit.id !== unitOfMeasurementOption.value) {
        const selectedUnitOfMeasurement = [
          ...this.baseUnitOfMeasurements,
          ...this.customUnitOfMeasurements,
        ].find((unit) => {
          return unit.id === unitOfMeasurementOption.value;
        });

        const value = +this.unitConversionPipe.transform(
          `${deliveryQuantity}`,
          selectedUnitOfMeasurement,
          baseUnit,
          true,
        );

        controls[fieldEnum.BASE_DELIVERY_QUANTITY].setValue(value, { emitEvent: false });
      } else {
        controls[fieldEnum.BASE_DELIVERY_QUANTITY].setValue(
          deliveryQuantity ? +deliveryQuantity : null,
          {
            emitEvent: false,
          },
        );
      }
    });
  }

  private transformUnitValue(
    value: number,
    selectedUnitOfMeasurement: IBaseUnit,
    baseUnit: IBaseUnit,
  ) {
    return +this.unitConversionPipe.transform(
      `${value}`,
      selectedUnitOfMeasurement,
      baseUnit,
      true,
      false,
      true,
    );
  }

  public buildUnitOfMeasurementOptionsSubscription(
    formGroup: FormGroup<BulkAddItemsModel.IItemFieldSharedFormGroup>,
    onAfter?: Function,
  ): Subscription {
    const { fieldEnum } = this;
    const { controls } = formGroup;

    return controls[fieldEnum.UNIT_OF_MEASUREMENT].valueChanges.subscribe(
      (unitOfMeasurementOption) => {
        if (!unitOfMeasurementOption) {
          controls[fieldEnum.IS_FIXED_UNIT_OF_MEASUREMENT]?.setValue(false);
          controls[fieldEnum.IS_FIXED_UNIT_OF_MEASUREMENT]?.disable();

          return;
        }

        const baseInitialQuantity = controls[fieldEnum.BASE_INITIAL_QUANTITY].value;

        let baseDeliveryQuantity: number;

        if (controls[fieldEnum.BASE_DELIVERY_QUANTITY]) {
          baseDeliveryQuantity = controls[fieldEnum.BASE_DELIVERY_QUANTITY].value;
        }

        const productId = controls[fieldEnum.PRODUCT].value?.value;

        if (!productId) {
          return;
        }

        controls[fieldEnum.IS_FIXED_UNIT_OF_MEASUREMENT]?.enable();
        controls[fieldEnum.IS_FIXED_UNIT_OF_MEASUREMENT]?.setValue(true);

        const product = this.allProducts.find((product) => product.id === productId);

        const baseUnitId = CommonUtils.getUriId(product.baseUnit);
        const baseUnit = this.baseUnitOfMeasurements.find((unit) => unit.id === baseUnitId);

        const setValueOptions = { emitEvent: false };

        if (baseUnit.id === unitOfMeasurementOption.value) {
          controls[fieldEnum.INITIAL_QUANTITY].setValue(
            controls[fieldEnum.BASE_INITIAL_QUANTITY].value,
            setValueOptions,
          );

          controls[fieldEnum.DELIVERY_QUANTITY]?.setValue(
            controls[fieldEnum.BASE_DELIVERY_QUANTITY].value,
            setValueOptions,
          );
        } else {
          const selectedUnitOfMeasurement = [
            ...this.baseUnitOfMeasurements,
            ...this.customUnitOfMeasurements,
          ].find((unit) => unit.id === unitOfMeasurementOption.value);

          const initialQuantity = this.transformUnitValue(
            baseInitialQuantity,
            selectedUnitOfMeasurement,
            baseUnit,
          );

          if (initialQuantity) {
            controls[fieldEnum.INITIAL_QUANTITY].setValue(initialQuantity, setValueOptions);
          }

          if (controls[fieldEnum.BASE_DELIVERY_QUANTITY]) {
            const deliveryQuantity = this.transformUnitValue(
              baseDeliveryQuantity,
              selectedUnitOfMeasurement,
              baseUnit,
            );

            controls[fieldEnum.DELIVERY_QUANTITY].setValue(deliveryQuantity, setValueOptions);
          }
        }

        if (onAfter) {
          onAfter();
        }
      },
    );
  }
}
