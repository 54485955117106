import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  signal,
  ViewChild,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";

import { cloneDeep, isEqual } from "lodash";
import { CommonConstants } from "src/app/shared/constants";
import {
  RecordStateEnum,
  ResourceTypeEnum,
  RoutingEnum,
  TagSelectorTypeEnum,
  TargetPathEnum,
} from "src/app/shared/enums";
import { ICondition, IConditionAny, IConditionEq, IRuleset } from "src/app/shared/interfaces";
import { CustomValidators } from "src/app/shared/validators";

import { InputSelectOption } from "@design-makeover/components/inputs/input-select/input-select.model";

import { TagsSelectorDialogComponent } from "@components/shared";
import { FormUtils } from "@shared/utils";

import { RulesetLocationInfoComponent } from "..";
import { RulesetBaseClass } from "../ruleset-base.class";

@Component({
  templateUrl: "./edit-location-ruleset.component.html",
  styleUrls: ["./edit-location-ruleset.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditLocationRulesetComponent extends RulesetBaseClass {
  @ViewChild("informationSection") informationSection: RulesetLocationInfoComponent;

  public formGroup: UntypedFormGroup;

  public isEditing = signal<boolean>(false);

  public selectedLocationTypesIds = signal<string[]>([]);

  private selectedDocumentTypesIds = signal<string[]>([]);

  private originalSelectedDocumentTypesIds = signal<string[]>([]);

  private hasSelectedListsChanged = signal<boolean>(false);

  private destroyRef = inject(DestroyRef);

  constructor(private dialog: MatDialog) {
    super();
  }

  public openDocumentTypeDialog() {
    const dialogRef = this.dialog.open(TagsSelectorDialogComponent, {
      autoFocus: false,
      data: {
        tagUrl: this.documentTypesService.baseUrl,
        type: TagSelectorTypeEnum.DOCUMENT_TYPES,
        placeholder: "Document type",
        canDelete: false,
        titleTranslatedText: "Add document types",
        selectedIds: [...this.selectedDocumentTypesIds()],
        isNewValueAllowed: false,
      },
    });

    dialogRef
      .afterClosed()
      .subscribe(
        async (response: {
          hasSaved: boolean;
          selectedIds: string[];
          isNewTagCreated: boolean;
        }) => {
          if (response.hasSaved && response.isNewTagCreated) {
            this.allDocumentTypes.set(await this.getAllDocumentTypes());
          }
          if (response.hasSaved) {
            this.selectedDocumentTypesIds.set([...response.selectedIds]);
            this.selectedDocumentTypes.set(
              this.allDocumentTypes().filter((d) => this.selectedDocumentTypesIds().includes(d.id)),
            );
            this.updateSelectedListsChangedState();
          }
        },
      );
  }

  public removeDocumentType(documentTypeId: string) {
    this.selectedDocumentTypes.set(
      this.selectedDocumentTypes().filter((type) => type.id !== documentTypeId),
    );
    this.selectedDocumentTypesIds.set(
      this.selectedDocumentTypesIds().filter((typeId) => typeId !== documentTypeId),
    );
    this.updateSelectedListsChangedState();
  }

  public async loadData(rulesetId?: string): Promise<void> {
    this.isLoading.set(true);
    try {
      if (rulesetId) {
        this.element.set(await this.rulesetsService.get(rulesetId));
        if (this.element().recordState === RecordStateEnum.ARCHIVED) {
          this.notificationService.showError(CommonConstants.EDIT_ARCHIVED_RECORD_ERROR_TEXT);
          await this.router.navigate([
            `/${RoutingEnum.ADMIN_RULESETS_DETAILS}/${this.element().id}`,
          ]);

          return;
        }
        this.setSelectedCountries();
        this.setSelectedLocationTypes();
        this.selectedLocationTypesIds.set(this.selectedLocationTypes().map((type) => type.id));
        this.setSelectedDocumentTypes();
        this.selectedDocumentTypesIds.set(this.selectedDocumentTypes().map((type) => type.id));

        this.isEditing.set(true);
      }

      this.setUpForm();
      this.isLoading.set(false);
    } catch (error) {
      this.notificationService.showError(error, !!rulesetId);
    }
  }

  public async reloadLocationTypes() {
    this.allLocationTypes.set(await this.getAllLocationTypes());
  }

  public async onSave(isSaveOnly: boolean = true) {
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);
      this.notificationService.showError(CommonConstants.FILL_REQUIRED_FIELDS_MSG);

      return;
    }
    this.isLoading.set(true);
    try {
      const payload = await this.getPayload();
      const ruleset = await this.rulesetsService.createOrUpdate(payload, this.element()?.id);

      this.hasFormValuesChanged = false;
      this.hasSelectedListsChanged.set(false);
      this.notificationService.showSuccess(`Ruleset ${this.isEditing() ? "modified" : "created"}`);
      if (isSaveOnly) {
        if (this.isEditing()) {
          if (this.newTypesCreated()) {
            await this.reloadLocationTypes();
            this.newTypesCreated.set(false);
          }
          await this.loadData(ruleset.id);
        } else {
          await this.router.navigate(
            [`/${RoutingEnum.ADMIN_RULESETS_EDIT_LOCATION}/${ruleset.id}`],
            {
              replaceUrl: true,
            },
          );
        }
      }
    } catch (error) {
      this.notificationService.showError(error);
    } finally {
      this.isLoading.set(false);
    }
  }

  public updateSelectedListsChangedState = (): void => {
    this.hasSelectedListsChanged.set(
      !isEqual(this.originalSelectedDocumentTypesIds(), this.selectedDocumentTypesIds().sort()),
    );
  };

  public override isSubmitButtonDisabled = (): boolean => {
    if (!this.isEditing()) {
      return false;
    }

    return (
      this.formGroup.invalid ||
      this.formGroup.pending ||
      !(this.hasFormValuesChanged || this.hasSelectedListsChanged()) ||
      !this.selectedDocumentTypesIds()?.length
    );
  };

  private getConditionForLocationType(locationTypesUris: string[]): ICondition {
    const countryCodes = this.formGroup
      .get("countryCodes")
      .value.map((country: InputSelectOption) => country.value);
    const conditions: (IConditionEq | IConditionAny)[] = [];

    if (locationTypesUris?.length) {
      conditions.push({
        any: {
          path: TargetPathEnum.TYPES,
          value: locationTypesUris,
        },
      });
    }

    if (countryCodes?.length) {
      conditions.push({
        any: {
          path: TargetPathEnum.COUNTRY,
          value: countryCodes,
        },
      });
    }

    const conditionAnd = conditions.length ? { and: conditions } : null;

    return conditionAnd;
  }

  private setUpForm() {
    const originalLocationTypesIds = cloneDeep(this.selectedLocationTypesIds()?.sort()).map(
      (id) => ({
        value: id,
      }),
    );

    const originalSelectedCountriesCodes = cloneDeep(this.selectedCountriesCodes()?.sort()).map(
      (code) => ({
        value: code,
      }),
    );

    const entityExistsValidatorArgs: any = {
      resourceType: ResourceTypeEnum.LOCATION,
    };

    this.originalSelectedDocumentTypesIds.set(cloneDeep(this.selectedDocumentTypesIds()?.sort()));
    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl(this.element()?.name ?? "", CustomValidators.required, [
        CustomValidators.entityAlreadyExists(
          this.rulesetsService,
          this.element()?.id ?? this.route.snapshot.params["id"] ?? null,
          entityExistsValidatorArgs,
        ),
      ]),
      description: new UntypedFormControl(this.element()?.description ?? ""),
      locationTypes: new UntypedFormControl(originalLocationTypesIds || []),
      countryCodes: new UntypedFormControl(originalSelectedCountriesCodes || []),
    });

    this.initialFormValue = this.formGroup.value;
    this.hasFormValuesChanged = false;
    this.formGroup.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.hasFormValuesChanged = this.hasInitialFormValueChanged(this.formGroup.value);
    });
  }

  private async getPayload(): Promise<IRuleset> {
    const locationTypesValue = this.formGroup.get("locationTypes").value;
    const { name, description } = this.formGroup.value;
    const locationTypes: InputSelectOption[] = locationTypesValue?.filter(
      (type: InputSelectOption) => type.value,
    );
    const typesToCreate: InputSelectOption[] = locationTypesValue?.filter(
      (type: InputSelectOption) => !type.value,
    );

    if (typesToCreate?.length) {
      this.newTypesCreated.set(true);
      for (const type of typesToCreate) {
        const newType = await this.locationTypesService.createOrUpdate({
          type: type.label,
          pointOfOrigin: false,
        });

        locationTypes.push({ label: newType.type, value: newType.id });
      }
    }

    const locationTypesUris = locationTypes.map(
      (type: InputSelectOption) =>
        `/organisations/${this.activeOrganisationId}/location-types/${type.value}`,
    );
    const documentTypesUris = this.selectedDocumentTypesIds().map(
      (typeId: string) => `/organisations/${this.activeOrganisationId}/document-types/${typeId}`,
    );

    const condition: ICondition = this.getConditionForLocationType(locationTypesUris);

    const expectations = documentTypesUris.map((uri) => ({
      path: TargetPathEnum.DOCUMENT,
      condition: {
        and: [
          {
            eq: {
              path: TargetPathEnum.TYPE,
              value: uri,
            },
          },
        ],
      },
    }));

    return {
      name,
      description: description || undefined,
      active: true,
      resourceType: this.resourceType ?? ResourceTypeEnum.LOCATION,
      condition,
      expectations,
    };
  }
}
