@if (enableViewMode && overlay.viewMode()) {
  @if (viewModeTemplate) {
    <ng-container
      [ngTemplateOutlet]="viewModeTemplate"
      [ngTemplateOutletContext]="{
        $implicit: selectedOption || {},
        label,
        searchText,
      }"
    ></ng-container>
  } @else {
    <iov-form-input-readonly [value]="searchText" [label]="label"></iov-form-input-readonly>
  }
} @else {
  <ng-container [ngTemplateOutlet]="editView"></ng-container>
}

<ng-template #editView>
  <div
    [class.invalid]="hasValidationError"
    [class.no-padding]="inputOnlyMode"
    [class]="class ? 'form-field-item ' + class : 'form-field-item'"
  >
    @if (!inputOnlyMode && label) {
      <iov-form-label [label]="label" [tooltip]="tooltip">
        <ng-container action>
          <ng-content select="[action]"></ng-content>
        </ng-container>
      </iov-form-label>
    }
    <div #origin="matAutocompleteOrigin" class="input-container" matAutocompleteOrigin>
      @if (prefixTemplate && hasSelectedOption) {
        <ng-container
          [ngTemplateOutlet]="prefixTemplate"
          [ngTemplateOutletContext]="{
            $implicit: selectedOption || {},
            label,
            searchText: searchText,
          }"
        ></ng-container>
      }
      <input
        #autoInput
        (blur)="onInputBlur()"
        (focus)="onInputFocus()"
        (keyup)="onInputKeyUp($event)"
        [disabled]="isDisabled() || disabled"
        [matAutocompleteConnectedTo]="origin"
        [matAutocomplete]="auto"
        [placeholder]="placeholder || label"
        [value]="searchText"
        iovAutocompletePositionUpdate
        matInput
        type="text"
      />

      @if (hasValidationError) {
        <mat-icon class="suffix-icon error-icon" svgIcon="action_pending" matSuffix></mat-icon>
      } @else {
        <mat-icon
          (click)="onInputClear()"
          [hidden]="shouldHideClearButton"
          class="suffix-icon clear-icon"
          matSuffix
          tp="Clear value"
        >
          cancel
        </mat-icon>
      }

      <mat-icon
        (click)="onToggleDropdown()"
        [ngClass]="{ disabled: isDisabled() || disabled }"
        class="suffix-icon"
        matSuffix
      >
        {{ dropdownIndicatorIcon }}
      </mat-icon>
    </div>

    <mat-autocomplete
      #auto="matAutocomplete"
      (closed)="onAutocompleteClose()"
      (optionSelected)="onAutocompleteOptionSelected($event.option.value)"
      [autoActiveFirstOption]="true"
      [displayWith]="getDisplayLabel"
      [hideSingleSelectionIndicator]="true"
      class="dropdown-input-menu"
    >
      @for (option of filteredOptions; track option.label) {
        <mat-option [value]="option" [ngClass]="{ active: option.value === selectedOption?.value }">
          @if (prefixTemplate) {
            <ng-container
              [ngTemplateOutlet]="prefixTemplate"
              [ngTemplateOutletContext]="{ $implicit: option, label, searchText: searchText }"
            ></ng-container>
          }

          {{ option.label }}
        </mat-option>
      } @empty {
        <mat-option [disabled]="true" class="not-allowed">No results</mat-option>
      }
    </mat-autocomplete>

    @if (!inputOnlyMode) {
      <iov-form-hint
        [errors]="ngControl.errors"
        [customClass]="hintClass"
        [hint]="hint"
        [isOptional]="isOptional() && showOptionalHint"
        [isDisabled]="disabled || isDisabled()"
        [showError]="hasValidationError"
      ></iov-form-hint>
    }
  </div>
</ng-template>
