import { QueriesUtils } from "./queries.utils";

export const idAndNameFields = `
    id
    name
`;

export const documentFields = `
    ${idAndNameFields}
    issuance
    validityStart
    validityEnd
    contentType
    type {
        ${idAndNameFields}
    }
    tags {
        title
        color
    }
    recordState
`;

export const unitOfMeasurementFields = `
    ${idAndNameFields}
    type
    symbol
    precision
`;

export const customUnitOfMeasurementFields = `
    ${idAndNameFields}
    symbol
    displayScale
    conversionFactor
`;
export const locationFields = `
    id
    name
    types {
        type
        pointOfOrigin
    }
    address {
        country
    }
    connections {
        id
        name
    }
    ${QueriesUtils.getQueryIncludes(["DOCUMENTS", "CERTIFICATES"])}
`;
export const materialsFields = `
  id
  name
  category
`;

export const getLinkFields = (includeId: boolean = false): string => `
    links {
      ${includeId ? "id" : ""}
      from {
        id
      }
      to {
        id
      }
    }
  `;
/**
 * Generates a GraphQL fragment for Products.
 * @returns A string representing the GraphQL query for Product.
 */
export const getProductFields = (include: string[] = []): string => `
    {
        ${idAndNameFields}
        recordState
        unitOfMeasurement {
            ${unitOfMeasurementFields}
        }
        ${QueriesUtils.getQueryIncludes(include)}
    }
`;
