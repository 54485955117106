<div class="input-container">
  <ng-content select="[prefix-icon],[prefix-text]"></ng-content>
  <input
    #input
    (blur)="onBlur()"
    (focus)="onFocus()"
    (input)="emitOnInput ? onInput($event) : null"
    (keyup)="onKeyUp($event)"
    (search)="onSearch($event)"
    [autocomplete]="autocomplete"
    [class]="class"
    [disabled]="disabled"
    [id]="placeholder"
    [placeholder]="placeholder"
    [type]="inputType"
    [value]="searchQuery"
    matInput
    iovNoScrollInput
  />
  @if (hasValidationError) {
    <mat-icon
      class="suffix-icon error-icon"
      [tp]="errorTooltip"
      svgIcon="action_pending"
      matSuffix
    ></mat-icon>
  } @else if (showClear) {
    <mat-icon (click)="onClear()" class="suffix-icon clear-icon" matSuffix tp="Clear value">
      cancel
    </mat-icon>
  }
  <ng-content select="[suffix-icon],[suffix-text]"></ng-content>
</div>
