@if (chunkVisibleCustomFields?.length) {
  <div class="dashed-divider"></div>
  <div class="row-container">
    <div *ngFor="let chunk of chunkVisibleCustomFields" class="row row-{{ columns }}">
      <div *ngFor="let customField of chunk" class="item">
        <p class="details-label">{{ customField.label }}</p>
        <app-value-and-copy-button
          valueClass="details-value"
          [value]="getValue(customField.id)"
        ></app-value-and-copy-button>
      </div>
    </div>
  </div>
}
