<div class="container-flex-space-between" [ngClass]="class ?? ''">
  <div class="left-content">
    <div class="type gray">
      {{ item.productName }}
    </div>
    <div class="name">
      {{ item.itemId }}
    </div>
    <div class="quantity gray">
      @if (displayType === itemCardContentDisplayTypeEnum.QUANTITY) {
        Initial quantity:
        @if (item.initialQuantityFormatted) {
          {{ item.initialQuantityFormatted }}
        } @else {
          {{ item.initialQuantity | number: "1.0-" + item.unitOfMeasurement?.precision ?? "0" }}
          {{ item.unitOfMeasurement?.symbol }}
        }
        <br />

        Remaining quantity:
        @if (item.remainingQuantityFormatted) {
          {{ item.remainingQuantityFormatted }}
        } @else {
          {{ item.remainingQuantity | number: "1.0-" + item.unitOfMeasurement?.precision ?? "0" }}
          {{ item.unitOfMeasurement?.symbol }}
        }
      } @else if (isOldMaterialsEnabled && item.materialNames?.length) {
        <iov-tag-group [tags]="item.materialNames" [maxTextLengthToShow]="10"></iov-tag-group>
      } @else {
        -
      }
    </div>
    @if (isShowTags && item.tags?.length) {
      <div class="tags">
        @for (tag of item.tags; track tag.title) {
          <div
            class="tag-chip {{ tag.color }}"
            [tp]="tag.title.length > MAX_TAG_CHARS ? tag.title : ''"
            tpPlacement="top-start"
            [tpIsLazy]="true"
          >
            {{ truncateTag(tag.title) }}
          </div>
        }
      </div>
    }
  </div>
  <ng-content select="[form-fields]"></ng-content>
  <iov-button-group>
    @if (type === cardContentTypeEnum.ADD) {
      <iov-button size="medium" type="icon">
        <mat-icon icon-medium-18>add</mat-icon>
      </iov-button>
    } @else {
      <iov-button (pressed)="onClickRemove()" tp="Remove" size="medium" type="icon">
        <mat-icon icon-medium-18>close</mat-icon>
      </iov-button>
    }
  </iov-button-group>
</div>
