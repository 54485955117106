export enum CustomFieldsResourceTypeEnum {
  ORGANISATION = "ORGANISATION",
  CONNECTION = "CONNECTION",
  LOCATION = "LOCATION",
  ITEM = "ITEM",
  DELIVERY = "DELIVERY",
  PROCESS = "PROCESS",
  PRODUCT = "PRODUCT",
  MATERIAL = "MATERIAL",
  CERTIFICATE = "CERTIFICATE",
}
