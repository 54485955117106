<form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
  <h2 mat-dialog-title>Select active organisation</h2>

  <app-loader *ngIf="isLoading()" containerClass="on-dialog"></app-loader>

  <ng-container *ngIf="!isLoading()">
    <mat-dialog-content>
      <iov-input-select
        [options]="organisationOptions"
        formControlName="organisation"
        label="Organisation"
      ></iov-input-select>
    </mat-dialog-content>
    <mat-dialog-actions>
      <iov-button [disabled]="isSubmitButtonDisabled()" accessibilityRole="submit">
        <mat-icon icon-left>check</mat-icon>
        Select organisation
      </iov-button>
    </mat-dialog-actions>
  </ng-container>
</form>
