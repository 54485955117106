import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  booleanAttribute,
  input,
} from "@angular/core";
import { MatMenu } from "@angular/material/menu";

import { SlideOverlayPageService } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.service";

@Component({
  selector: "iov-right-side-panel",
  templateUrl: "./right-side-panel.component.html",
  styleUrl: "./right-side-panel.component.scss",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class RightSidePanelComponent<T> implements OnDestroy {
  @Input() itemType: string;

  @Input() itemTypePlural: string;

  @Input() title: string;

  @Input() noItemsFoundText: string;

  @Input() addItemsText: string;

  @Input() addMenu: MatMenu;

  @Input({ transform: booleanAttribute }) displayTopButtons: boolean = true;

  @Input({ transform: booleanAttribute }) displayContent: boolean = true;

  public isLoadingItems = input<boolean>(false);

  @Input({ transform: booleanAttribute }) isAddOpenInNewTab: boolean = false;

  @Input({ transform: booleanAttribute }) displayCloseButton: boolean = true;

  @Input() availableItems: T[] = [];

  @Input() overlayTopButtons: TemplateRef<unknown>;

  @Output() closeMenu: EventEmitter<void> = new EventEmitter();

  @Output() searchEvent: EventEmitter<string> = new EventEmitter();

  @Output() pressRefresh: EventEmitter<string> = new EventEmitter();

  @Output() pressAdd: EventEmitter<void> = new EventEmitter();

  @Output() pressAddAll: EventEmitter<void> = new EventEmitter();

  constructor(public overlay: SlideOverlayPageService) {}

  get searchPlaceholder(): string {
    return `Search ${this.itemTypePlural.toLocaleLowerCase()}`;
  }

  get safeTitle(): string {
    return this.title || `Add ${this.itemTypePlural.toLocaleLowerCase()}`;
  }

  onAddNewItem(): void {
    this.pressAdd.emit();
  }

  onAddAll(): void {
    this.pressAddAll.emit();
  }

  onPressRefresh(): void {
    this.pressRefresh.emit();
  }

  onSearchEvent(searchAvailableText: string): void {
    this.searchEvent.emit(searchAvailableText);
  }

  onCloseSideMenu(): void {
    this.overlay.closeSideMenu();
    this.closeMenu.emit();
  }

  ngOnDestroy(): void {
    if (this.overlay.componentRef()) {
      this.searchEvent?.emit("");
    }
  }
}
