import { Inject, Injectable } from "@angular/core";

import { APP_CONFIG } from "@shared/tokens";

import { ApiService } from "./api.service";
import { CommonConstants } from "../../constants";
import { LinkDirectionEnum } from "../../enums";
import { IConfig, ILocationLink, ILocationLinkPayload, IPageableContent } from "../../interfaces";
import { FormUtils } from "../../utils";
import { AuthenticationService } from "../authentication.service";

@Injectable({
  providedIn: "root",
})
export class LocationsLinksService {
  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {}

  private getBaseUrl = (): string =>
    `${this.environment.baseUrl}organisations/${this.authenticationService.getActiveOrganisationId()}/location-links`;

  public getPage = async (
    linkDirection: LinkDirectionEnum,
    locationUri: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
  ): Promise<IPageableContent<ILocationLink>> => {
    const url = `${this.getBaseUrl()}?${FormUtils.addUrlParams({
      linkDirection,
      locationUri,
      size,
      page,
      sort,
    })}`;

    return await this.apiService.get<IPageableContent<ILocationLink>>(url).toPromise();
  };

  public async getAll(
    linkDirection: LinkDirectionEnum,
    locationUri: string = undefined,
  ): Promise<ILocationLink[]> {
    const result: ILocationLink[] = [];
    let page = 0;
    let hasNextPage: boolean = true;

    while (hasNextPage) {
      const {
        content,
        page: { totalElements },
      } = await this.getPage(
        linkDirection,
        locationUri,
        CommonConstants.MAX_API_GET_ITEMS_SIZE,
        page,
        undefined,
      );

      result.push(...content);

      page++;
      hasNextPage = result.length < totalElements;
    }

    return result;
  }

  public get = async (id: string): Promise<ILocationLink> =>
    await this.apiService.get<ILocationLink>(`${this.getBaseUrl()}/${id}`).toPromise();

  public delete = async (id: string): Promise<void> =>
    await this.apiService.delete<void>(`${this.getBaseUrl()}/${id}`).toPromise();

  public create = async (payload: ILocationLinkPayload): Promise<void> =>
    await this.apiService.post<void>(`${this.getBaseUrl()}`, payload).toPromise();
}
