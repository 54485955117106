import { getCertificateProperties } from "./certificates.query";
import { QueriesUtils } from "./queries.utils";

export const getInboundCertificatesByIdsGraphQLQuery = (
  orgId: string,
  senderId: string,
  ids: string[],
  first: number,
  last: number,
  after: string,
  before: string,
  include: string[],
): string => {
  const afterCursorClause = first > 0 ? QueriesUtils.generateClause("after", after) : "";
  const firstClause = first > 0 ? QueriesUtils.generateClause("first", first) : "";
  const beforeCursorClause = last > 0 ? QueriesUtils.generateClause("before", before) : "";
  const lastClause = last > 0 ? QueriesUtils.generateClause("last", last) : "";

  return `
  {
      inboundLoadCertificatesByIds(   
      orgId: "${orgId}",
      senderId: "${senderId}",
      ids: ${QueriesUtils.getParsedIds(ids)},
      ${afterCursorClause ? `${afterCursorClause},` : ""}
      ${beforeCursorClause ? `${beforeCursorClause},` : ""}
      ${firstClause ? `${firstClause},` : ""}
      ${lastClause ? `${lastClause},` : ""}
  ) {       
    ${getCertificateProperties(include)}
      }
  }
  `;
};
