<form [formGroup]="form">
  @if (data.titleTranslatedText) {
    <h2 mat-dialog-title>{{ data.titleTranslatedText }}</h2>
  }
  <mat-dialog-content>
    <iov-input-chips
      #inputChips
      (tagCreatedEvent)="isNewTagCreated = true"
      [allowDeleteTag]="data.canDelete"
      [allowCreateTag]="true"
      [label]="data.placeholder"
      [tagUrl]="data.tagUrl"
      formControlName="tags"
      hint="Select from the list or type it in and hit enter to add a new type"
      tooltip="Determines the type(s) of the location. E.g. Forest, Sawmill, Exporter, Importer, Agent, etc."
    ></iov-input-chips>
  </mat-dialog-content>
  <mat-dialog-actions>
    <iov-button-group>
      <iov-button (pressed)="close()" type="ghost">Cancel</iov-button>
      <iov-button (pressed)="save()" [disabled]="form.invalid">
        <mat-icon icon-left>check</mat-icon>
        Save
      </iov-button>
    </iov-button-group>
  </mat-dialog-actions>
</form>
