<div class="requirements-container">
  <div *ngFor="let requirement of requirements" class="requirement">
    <mat-icon *ngIf="!(fieldErrors.required || fieldErrors[requirement.type])" class="green">
      check
    </mat-icon>
    <mat-icon *ngIf="fieldErrors.required || fieldErrors[requirement.type]" class="red">
      close
    </mat-icon>
    {{ requirement.text }}
  </div>
</div>
