import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  signal,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

import { IBaseUnit, IItemDetails, IProduct } from "src/app/shared/interfaces";
import { CommonUtils } from "src/app/shared/utils";
import { CustomValidators } from "src/app/shared/validators";

import { CardContentTypeEnum } from "@design-makeover/components/cards/card-content/card-content.model";
import { UnitConversionPipe } from "@design-makeover/pipes/unit-conversion.pipe";

import { UnitsOfMeasurementService } from "@shared/services";

@Component({
  selector: "app-items-list-items",
  templateUrl: "./items-list-items.component.html",
  styleUrls: ["./items-list-items.component.scss"],
  providers: [UnitConversionPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemsListItemsComponent implements OnChanges {
  @Input()
  public items: IItemDetails[];

  @Input()
  public allProducts: IProduct[] = [];

  @Input()
  public allUnitOfMeasurements: IBaseUnit[] = [];

  @Input()
  public canEdit = true;

  @Input()
  public canRemove = true;

  @Output()
  public remove: EventEmitter<string> = new EventEmitter();

  @Output()
  public updateDeliveredQuantity: EventEmitter<any> = new EventEmitter();

  public isLoading = signal(true);

  readonly contentCardTypeEnum = CardContentTypeEnum;

  constructor(
    private unitsOfMeasurementService: UnitsOfMeasurementService,
    private unitConversionPipe: UnitConversionPipe,
  ) {}

  public async ngOnChanges(): Promise<void> {
    this.isLoading.set(true);

    if (this.items?.length && this.allProducts?.length) {
      this.items = CommonUtils.getItemsWithProductAndUnitOfMeasurement(
        this.items,
        this.allProducts,
        this.allUnitOfMeasurements,
      );
      for (const item of this.items) {
        let itemDeliveredQuantity = item.deliveredQuantity;
        let itemInitialQuanitity = item.initialQuantity;
        const product = this.allProducts.find((p) => p.id === item.productId);
        const defaultUnitId = CommonUtils.getUriId(product.defaultUnit);

        if (defaultUnitId !== item.unitOfMeasurement.id) {
          item.productDefaultUnit = await this.unitsOfMeasurementService.get(defaultUnitId);
          itemDeliveredQuantity = +this.unitConversionPipe.transform(
            `${itemDeliveredQuantity}`,
            item.productDefaultUnit,
            item.unitOfMeasurement,
            false,
            false,
            true,
          );
          itemInitialQuanitity = +this.unitConversionPipe.transform(
            `${itemInitialQuanitity}`,
            item.productDefaultUnit,
            item.unitOfMeasurement,
            false,
            false,
            true,
          );
          item.remainingQuantityFormatted = CommonUtils.formatQuantityWithDefaultUnit(
            item.remainingQuantity,
            item.productDefaultUnit,
            item.unitOfMeasurement,
          );
        }
        item.formGroup = new UntypedFormGroup({
          deliveredQty: new UntypedFormControl(
            { value: itemDeliveredQuantity ?? itemInitialQuanitity, disabled: !this.canEdit },
            [CustomValidators.required, CustomValidators.greaterThan(0)],
          ),
        });
      }
    }
    this.isLoading.set(false);
  }

  async onItemQuantityChange(item: IItemDetails) {
    const currentQuantity = item.formGroup.controls["deliveredQty"].value;
    let convertedValue = currentQuantity;
    const product = this.allProducts.find((p) => p.id === item.productId);
    const defaultUnitId = CommonUtils.getUriId(product.defaultUnit);

    if (defaultUnitId !== item.unitOfMeasurement.id) {
      item.productDefaultUnit = await this.unitsOfMeasurementService.get(defaultUnitId);
      convertedValue = this.unitConversionPipe.transform(
        currentQuantity,
        item.productDefaultUnit,
        item.unitOfMeasurement,
        true,
      );
    }
    this.updateDeliveredQuantity.emit({
      id: item.id,
      deliveredQuantity: +convertedValue,
    });
  }

  public onRemove = (id: string): void => {
    this.remove.emit(id);
  };
}
