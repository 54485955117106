import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  signal,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

import { SlideOverlayContentComponent } from "@design-makeover/components/overlay/slide-overlay-content/slide-overlay-content.component";
import { SlideOverlayPageClass } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.class";

import { CommonConstants } from "@shared/constants";
import { AttachmentTypeEnum, OverlayTabEnum, RoutingEnum } from "@shared/enums";
import { IAttachment, IDocumentExtended, ISelectOption } from "@shared/interfaces";
import { DownloadDocumentsService, RecordSharingService } from "@shared/services";
import { CommonUtils } from "@shared/utils";
import { FileUtils } from "@shared/utils/file.utils";
import { InboundSharedRecordUtils } from "@shared/utils/inboud-shared-record.utils";

@Component({
  selector: "app-shared-document-overlay",
  templateUrl: "./shared-document-overlay.component.html",
  styleUrls: ["../document-overlay-common.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SharedDocumentOverlayComponent extends SlideOverlayPageClass implements OnInit {
  private recordSharingService = inject(RecordSharingService);

  public formGroup: UntypedFormGroup;

  public fileExtension: string;

  private fileName: string;

  override element = signal<IDocumentExtended>(null);

  @ViewChild("slideOverlayContent") override slideOverlayContent: SlideOverlayContentComponent;

  public documentTypesOptions = signal<ISelectOption[]>([]);

  public selectedAttachments = signal<IAttachment[]>([]);

  override menuItems = signal(
    new Map([
      [OverlayTabEnum.DETAILS, { isEnabled: true }],
      [OverlayTabEnum.ATTACHED_TO, { isEnabled: true }],
    ]),
  );

  private downloadDocumentsService = inject(DownloadDocumentsService);

  get canViewContent(): boolean {
    return this.element()?.canView;
  }

  get hasContent(): boolean {
    return !!this.element()?.contentType;
  }

  async ngOnInit(): Promise<void> {
    this.overlay.showLoading();
    if (!this.isOnCorrectOverlay(RoutingEnum.OVERLAY_SHARED_DOCUMENT)) {
      return;
    }
    if (!this.recordId || !this.organisationIdQueryParam) {
      this.notification.showError("Missing parameters");

      return;
    }

    await this.reloadElement(this.recordId);
    await this.setMenuItemFromURLParam();
  }

  contentTooltip(actionType: "View" | "Download"): string {
    return this.hasContent ? `${actionType} document` : CommonConstants.DOCUMENT_NO_CONTENT_TEXT;
  }

  override setupForm(): void {
    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl(this.fileName ?? null),
      type: new UntypedFormControl(this.documentTypesOptions()[0].value ?? null),
      issuance: new UntypedFormControl(this.element()?.issuance ?? null),
      validityStart: new UntypedFormControl(this.element()?.validityStart ?? null),
      validityEnd: new UntypedFormControl(this.element()?.validityEnd ?? null),
    });
  }

  protected override async reloadElement(id: string): Promise<void> {
    try {
      const inboundDocument = await this.recordSharingService.getInboundDocumentsByIdsGraphQL(
        [id],
        this.organisationIdQueryParam,
        CommonConstants.MAX_API_GET_ITEMS_SIZE,
        ["ATTACHED_TO"],
      );

      this.element.set(inboundDocument[0]);

      CommonUtils.setDocumentsCanView([this.element()]);
      this.fileExtension = FileUtils.getFileExtension(this.element().name);
      this.fileName = FileUtils.getFileNameWithoutExtension(this.element().name);

      this.documentTypesOptions.set([
        { value: this.element()?.type?.id, label: this.element()?.type?.name },
      ]);
      await this.setupSelectedAttachments();
      this.setupForm();
      this.overlay.dismissLoading();
    } catch (error) {
      this.notification.showError(error);
    }
  }

  async setupSelectedAttachments(): Promise<void> {
    const attachments = await InboundSharedRecordUtils.setupSelectedAttachments(
      this.element(),
      AttachmentTypeEnum.DOCUMENT,
      this.organisationIdQueryParam,
      this.activeOrganisationId,
      this.recordSharingService,
      this.routerService,
    );

    this.selectedAttachments.set(attachments);
  }

  async onNameClick(): Promise<void> {
    if (!this.element().contentType) {
      return;
    }

    this.element()?.canView ? await this.viewContent() : await this.downloadContent();
  }

  async downloadContent(): Promise<void> {
    if (!this.element().contentType) {
      return;
    }

    const urlPath = `organisations/${this.activeOrganisationId}/record-sharing/inbound/records/${this.organisationIdQueryParam}/documents/${this.element().id}/content`;

    this.downloadDocumentsService.add(
      {
        documentId: this.element().id,
        documentName: this.element().name,
      },
      urlPath,
    );
  }

  async viewContent(): Promise<void> {
    CommonUtils.openInNewTab(
      `${RoutingEnum.DOCUMENTS_VIEW}/${this.element().id}?senderOrgId=${this.organisationIdQueryParam}`,
    );
  }

  protected override recordName(): string {
    return this.element()?.name;
  }
}
