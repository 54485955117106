import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  input,
  Input,
  Output,
} from "@angular/core";

import { IProduct } from "src/app/shared/interfaces";

@Component({
  selector: "app-product-list-items",
  templateUrl: "./product-list-items.component.html",
  styleUrl: "./product-list-items.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductListItemsComponent {
  @Input()
  public items: IProduct[];

  public isLoading = input<boolean>(false);

  @Output()
  remove: EventEmitter<IProduct> = new EventEmitter<IProduct>();
}
