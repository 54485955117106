import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, Inject, OnInit, signal } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { DocumentTypesService } from "src/app/shared/services";
import { CustomValidators } from "src/app/shared/validators";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { CommonConstants } from "@shared/constants";
import { IDocumentType } from "@shared/interfaces";
import { FormUtils } from "@shared/utils";

@Component({
  templateUrl: "./edit-document-type-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditDocumentTypeDialogComponent implements OnInit {
  public formGroup: UntypedFormGroup = null;

  public isLoading = signal(false);

  public isEditing = signal(false);

  constructor(
    public dialogRef: MatDialogRef<EditDocumentTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { documentType?: any; isForceAdd?: boolean },
    private documentTypesService: DocumentTypesService,
    private notificationService: NotificationService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.isEditing.set(!!this.data?.documentType?.id && !this.data.isForceAdd);

    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl(
        this.data?.documentType?.name,
        [CustomValidators.required],
        [
          CustomValidators.entityAlreadyExists(
            this.documentTypesService,
            this.data?.documentType?.id ?? null,
          ),
        ],
      ),
    });
  }

  public isSubmitButtonDisabled = (): boolean => {
    if (!this.isEditing()) {
      return false;
    }

    return this.formGroup.invalid || this.formGroup.pending;
  };

  public onSubmit = async (): Promise<void> => {
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);
      this.notificationService.showError(CommonConstants.FILL_REQUIRED_FIELDS_MSG);

      return;
    }
    this.isLoading.set(true);

    const payload: any = {
      name: this.formGroup.controls["name"].value.trim(),
    };

    await this.documentTypesService
      .createOrUpdate(payload, this.isEditing() ? this.data?.documentType?.id : undefined)
      .then((newDocumentType: IDocumentType) => {
        this.notificationService.showSuccess(
          `Document type ${this.isEditing() ? "modified" : "created"}`,
        );
        this.onClose(true, newDocumentType);
      })
      .catch((error: HttpErrorResponse) => {
        this.notificationService.showError(error);
      })
      .finally(() => {
        this.isLoading.set(false);
      });
  };

  public onClose = (hasSaved = false, newDocumentType?: IDocumentType): void => {
    this.dialogRef.close({ hasSaved, newDocumentType });
  };
}
