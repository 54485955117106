import { Injectable } from "@angular/core";

import { BehaviorSubject, Observable } from "rxjs";

import { StorageKeyEnum } from "@shared/enums";
import { LocalStorageService } from "@shared/services";

interface MenuToggle {
  persist?: boolean;
  isCollapsed?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class SidebarMenuService {
  private menuCollapsedState: BehaviorSubject<boolean>;

  constructor(private localStorageService: LocalStorageService) {
    this.init();
  }

  get menuCollapsedState$(): Observable<boolean> {
    return this.menuCollapsedState.asObservable();
  }

  toggleMenu({ persist = false, isCollapsed }: MenuToggle): void {
    const newCollapsedValue = isCollapsed ?? !this.menuCollapsedState.value;

    this.menuCollapsedState.next(newCollapsedValue);

    if (persist) {
      this.localStorageService.set(StorageKeyEnum.IS_MENU_COLLAPSED, newCollapsedValue.toString());
    }
  }

  private init(): void {
    const isCollapsedRawValue = this.localStorageService.get(StorageKeyEnum.IS_MENU_COLLAPSED);
    const isCollapsed = isCollapsedRawValue === "true";

    this.menuCollapsedState = new BehaviorSubject<boolean>(isCollapsed);
  }
}
