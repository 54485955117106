<div class="flow-chart-node" [ngClass]="class">
  @if (template) {
    @if (groupedProduct) {
      <ng-container
        [ngTemplateOutlet]="template"
        [ngTemplateOutletContext]="{
          $implicit: groupedProduct,
        }"
      ></ng-container>
    } @else if (delivery) {
      <ng-container
        [ngTemplateOutlet]="template"
        [ngTemplateOutletContext]="{
          $implicit: delivery,
          deliveriesRulesetsRecords: deliveriesRulesetsRecords,
        }"
      ></ng-container>
    }
  } @else {
    <div>
      @if (canRemove) {
        <iov-button
          size="medium"
          type="icon"
          class="clickable"
          tp="Remove"
          [ngClass]="isSafari ? 'remove-button-safari' : 'remove-button'"
        >
          <mat-icon icon-small>close</mat-icon>
        </iov-button>
      }
    </div>

    <div class="location-types flex-wrap">
      @for (locationType of locationTypes; track $index) {
        <div class="location-type">
          @if (locationType.pointOfOrigin) {
            <mat-icon class="point-of-origin">target</mat-icon>
          }
          <div class="location-type-text">{{ locationType.type }}</div>
        </div>
      }
    </div>

    <div
      class="organisation-name organisation clickable"
      [class.hover-visibility-container]="shouldOpenInNewTab"
    >
      {{ location.organisationName }}
      @if (shouldOpenInNewTab) {
        <app-external-link-icon></app-external-link-icon>
      }
    </div>

    <div
      [ngClass]="{ 'name-safari': isSafari }"
      class="name break-word clickable"
      [class.hover-visibility-container]="shouldOpenInNewTab"
    >
      {{ location.name }}
      @if (shouldOpenInNewTab) {
        <app-external-link-icon></app-external-link-icon>
      }
    </div>

    <div class="footer">
      <p class="country">
        <img
          [src]="'assets/images/flags/' + location.address.country.toLowerCase() + '.svg'"
          width="15"
          height="10"
          alt="flag"
        />
        <span class="country-name one-line-wrap-ellipsis">
          {{ location.address.countryName }}
        </span>
      </p>
      <div
        *ngIf="
          location.filteredAndSelectedDocuments?.length ||
          location.filteredAndSelectedCertificates?.length ||
          location?.processes?.length ||
          isEditing
        "
      >
        <div class="attachments">
          @if (
            location.filteredAndSelectedDocuments?.length ||
            (isEditing && hasAvailableDocuments) ||
            !(location.id | conditionsMetCheckerPipe: locationsRulesetsRecords)
          ) {
            <div class="border documents">
              @if (!(location.id | conditionsMetCheckerPipe: locationsRulesetsRecords)) {
                <mat-icon svgIcon="alert-triangle" class="warn-icon"></mat-icon>
                <mat-icon svgIcon="document"></mat-icon>
                <span class="number selected-documents">
                  {{ location?.filteredAndSelectedDocuments?.length || "" }}
                </span>
              } @else {
                <mat-icon svgIcon="document"></mat-icon>
                <span class="number selected-documents">
                  {{ location?.filteredAndSelectedDocuments?.length }}
                </span>
              }
            </div>
          }
          @if (
            location.filteredAndSelectedCertificates?.length ||
            (isEditing && hasAvailableCertificates)
          ) {
            <div class="border certificates">
              <mat-icon svgIcon="certificates"></mat-icon>
              <span class="number selected-certificates">
                {{ location?.filteredAndSelectedCertificates?.length }}
              </span>
            </div>
          }
          @if (location?.processes?.length) {
            <div class="border processes clickable">
              <mat-icon svgIcon="processes"></mat-icon>
              <span class="number selected-processes">{{ location?.processes?.length }}</span>
            </div>
          }
        </div>
      </div>
    </div>
  }
</div>
