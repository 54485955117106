import { inject } from "@angular/core";

import { FormUtils } from "src/app/shared/utils";

import { ConfirmDialogResponseEnum } from "../enums";
import { IHasUnsavedData } from "../interfaces/has-unsaved-data";
import { AuthenticationService } from "../services";
import { CanDeactiveDialogService } from "../services/can-deactive-dialog.service";

export abstract class CanDeactivateClass implements IHasUnsavedData {
  protected canDeactivateDialogService = inject(CanDeactiveDialogService);

  protected authenticationService = inject(AuthenticationService);

  hasFormValuesChanged = false;

  initialFormValue = null;

  async canExit(): Promise<boolean> {
    if (!this.authenticationService.haveTokensExpired() && this.requiresConfirmation()) {
      const response = await this.canDeactivateDialogService.showDialog(
        this.isSubmitButtonDisabled(),
      );

      if (response === ConfirmDialogResponseEnum.CONFIRM) {
        // save and leave
        await this.saveBeforeClosing();

        return true;
      } else if (response === ConfirmDialogResponseEnum.THIRD_ACTION) {
        // do not save and leave
        this.hasFormValuesChanged = false;

        return true;
      } else {
        // stay on page.
        return false;
      }
    } else {
      return true;
    }
  }

  protected hasInitialFormValueChanged(currentFormValue: object): boolean {
    return FormUtils.hasInitialFormValueChanged(this.initialFormValue, currentFormValue);
  }

  protected requiresConfirmation(): boolean {
    return this.hasFormValuesChanged;
  }

  /** To be overriden in components that need it */
  protected async saveBeforeClosing(): Promise<void> {}

  protected isSubmitButtonDisabled(): boolean {
    return true;
  }
}
