import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  input,
  OnInit,
  Output,
  signal,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef } from "ag-grid-community";
import { lastValueFrom } from "rxjs";
import { ColumnUtils, CommonUtils } from "src/app/shared/utils";

import { CardContentTypeEnum } from "@design-makeover/components/cards/card-content/card-content.model";
import { OverlayCommonService } from "@design-makeover/components/overlay/overlay-common.service";
import { SlideOverlayPageService } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.service";
import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { ConfirmDialogComponent } from "@components/shared";
import { slideOverAnimation } from "@shared/animations";
import {
  AttachmentTargetEnum,
  AttachmentTypeEnum,
  ConfirmDialogResponseEnum,
  RecordStateEnum,
} from "@shared/enums";
import { IAttachment, IDocument, IDocumentType } from "@shared/interfaces";
import { AuthenticationService } from "@shared/services";

@Component({
  selector: "app-overlay-document-attachments",
  templateUrl: "./overlay-document-attachments.component.html",
  styleUrl: "./overlay-document-attachments.component.scss",
  animations: [slideOverAnimation],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class OverlayDocumentAttachmentsComponent implements OnInit {
  @ViewChild("attachmentsRightMenu") attachmentsRightMenu: TemplateRef<unknown>;

  @Input() overlayService: OverlayCommonService;

  public isReadOnly = input<boolean>(false);

  attachmentTargetId = input.required<string>();

  attachmentTargetType = input.required<AttachmentTargetEnum>();

  @Output() public selectedAttachmentsUpdated: EventEmitter<void> = new EventEmitter<void>();

  @Output() changes: EventEmitter<void> = new EventEmitter();

  isAvailableAttachmentsLoading = signal<boolean>(true);

  isSelectedAttachmentsLoading = signal<boolean>(true);

  displayConfimDialogUponAttachDocumentsExit = signal<boolean>(false);

  allMissingDocumentTypes = signal<IDocumentType[]>([]);

  allDocumentTypes = signal<IDocumentType[]>([]);

  selectedDocumentsIds = signal<string[]>([]);

  availableDocuments = signal<IDocument[]>([]);

  private selectedAttachments = signal<IAttachment[]>([]);

  public readonly recordStateEnum = RecordStateEnum;

  public readonly canAddModifyEntities = this.authenticationService.canAddModifyEntities();

  private readonly shouldGetRulesets = this.authenticationService.isRegularUser();

  attachTargetUri: string;

  closeOverlay = signal<boolean>(true);

  protected allMissingDocumentTypeColumnDefs = signal<ColDef[]>([]);

  protected documentColumns = signal<string[]>([]);

  protected readonly cardContentTypeEnum = CardContentTypeEnum;

  protected readonly attachmentTypeEnum = AttachmentTypeEnum;

  private searchAvailableAttachmentsText: string = undefined;

  constructor(
    private dialog: MatDialog,
    private notification: NotificationService,
    public overlay: SlideOverlayPageService,
    private authenticationService: AuthenticationService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.searchAvailableAttachmentsText = undefined;
    this.setMissingDocumentTypeColumnDefs();
    this.setDocumentColumns();
    await this.loadHelperData();
    await this.loadSelectedAttachments();
    await this.loadAvailableAttachments();
  }

  public async loadHelperData(): Promise<void> {
    let allDocumentTypes = await this.overlayService.loadDocumentTypes();

    if (this.shouldGetRulesets) {
      allDocumentTypes = await this.setAppliedRulesets(allDocumentTypes);
    }

    this.allDocumentTypes.set(allDocumentTypes);
  }

  public async onAddAll(): Promise<void> {
    const availableDocuments = this.availableDocuments();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: "Add all confirmation",
        contentTranslatedText: `Are you sure you want to add all ${availableDocuments.length} documents?`,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        this.isSelectedAttachmentsLoading.set(true);
        this.isAvailableAttachmentsLoading.set(true);

        const promises = availableDocuments.map(async (availableDocument) => {
          await this.onAdd(availableDocument, false);
        });

        await Promise.all(promises);

        this.notification.showSuccess("Documents added");

        await this.loadSelectedAttachments();
        await this.loadAvailableAttachments();
        this.changes.emit();
      }
    });
  }

  public async onCloseAttachSlideOver(): Promise<boolean> {
    if (!this.displayConfimDialogUponAttachDocumentsExit()) {
      this.displayConfimDialogUponAttachDocumentsExit.set(false);
      this.closeOverlay.set(true);

      return true;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: "Exit confirmation",
        contentTranslatedText: `You're about to leave this page. Are you sure you want to discard your changes?`,
        confirmButtonTranslatedText: "Discard changes & leave",
        confirmButtonColor: "danger",
        confirmButtonIcon: "close",
      },
    });

    const response = await lastValueFrom(dialogRef.afterClosed());

    if (response === ConfirmDialogResponseEnum.CONFIRM) {
      this.displayConfimDialogUponAttachDocumentsExit.set(false);
      this.closeOverlay.set(true);

      return true;
    }

    return false;
  }

  public onSearchAvailableAttachments = async (searchText?: string): Promise<void> => {
    this.searchAvailableAttachmentsText = searchText || undefined;
    await this.loadAvailableAttachments();
  };

  private async loadAvailableAttachments(): Promise<void> {
    this.isAvailableAttachmentsLoading.set(true);
    const documents = await this.overlayService.loadDocuments(
      this.searchAvailableAttachmentsText || undefined,
    );

    this.availableDocuments.set(
      documents.filter(
        (document) =>
          !this.selectedDocumentsIds().some(
            (selectedDocumentId) => selectedDocumentId === document.id,
          ),
      ),
    );
    this.isAvailableAttachmentsLoading.set(false);
  }

  public async onAdd(attachment: IDocument, isSingleAdd = true): Promise<void> {
    if (isSingleAdd) {
      this.isSelectedAttachmentsLoading.set(true);
      this.isAvailableAttachmentsLoading.set(true);
    }

    try {
      await this.overlayService.createAttachment(
        this.attachmentTargetType(),
        this.attachmentTargetId(),
        this.attachmentTypeEnum.DOCUMENT,
        attachment.id,
      );

      if (isSingleAdd) {
        this.notification.showSuccess("Document added");
        await this.loadSelectedAttachments();
        await this.loadAvailableAttachments();
        this.changes.emit();
      }
    } catch {
      if (isSingleAdd) {
        this.notification.showError("Error when adding document");
      }
    }
  }

  public async loadSelectedAttachments(): Promise<void> {
    this.isSelectedAttachmentsLoading.set(true);
    const attachmentTargetId = this.attachmentTargetId();

    const selectedAttachments = (await this.overlayService.loadSelectedAttachments(
      this.attachmentTypeEnum.DOCUMENT,
      this.attachmentTargetType(),
      attachmentTargetId,
      {},
      true,
    )) as IAttachment[];

    this.selectedAttachments.set(selectedAttachments);

    this.selectedDocumentsIds.set(
      selectedAttachments.map((a) => CommonUtils.getUriId(a.attachmentUri)),
    );

    await this.loadMissingDocumentTypes();
    this.overlayService.documentCounter.set(this.selectedDocumentsIds().length);
    this.selectedAttachmentsUpdated.emit();
    this.isSelectedAttachmentsLoading.set(false);
  }

  public async onRemove(document: IDocument): Promise<void> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: "Remove document",
        contentTranslatedText: "Are you sure you want to remove this document from the record?",
        confirmButtonColor: "danger",
        confirmButtonTranslatedText: "Remove",
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        try {
          this.isSelectedAttachmentsLoading.set(true);
          this.isAvailableAttachmentsLoading.set(true);
          const selectedAttachment = this.selectedAttachments().find(
            (a) => CommonUtils.getUriId(a.attachmentUri) === document.id,
          );

          await this.overlayService.removeAttachment("document", selectedAttachment.id);
          await this.loadSelectedAttachments();
          await this.loadAvailableAttachments();

          this.changes.emit();
        } catch (_) {
          this.isSelectedAttachmentsLoading.set(false);
          this.isAvailableAttachmentsLoading.set(false);
        }
      }
    });
  }

  public async addNewDocument(): Promise<void> {
    const activeOrganisationId =
      this.overlayService.authenticationService.getActiveOrganisationId();

    this.attachTargetUri = CommonUtils.getTargetUri(
      activeOrganisationId,
      this.attachmentTargetType(),
      this.attachmentTargetId(),
    );
    this.closeOverlay.set(false);
  }

  public onAttachDocumentsCompleted = async (result: {
    attachedIds: string[];
    uploadedIds: string[];
  }): Promise<void> => {
    this.closeOverlay.set(true);

    if (result?.attachedIds?.length || result?.uploadedIds?.length) {
      await this.loadHelperData();
      if (result?.attachedIds?.length) {
        this.notification.showSuccess(`Document${result.attachedIds.length > 1 ? "s" : ""} added`);
        await this.loadSelectedAttachments();
      }
      if (result?.uploadedIds?.length) {
        await this.loadAvailableAttachments();
      }
    }
  };

  public onFilesAdded(): void {
    this.displayConfimDialogUponAttachDocumentsExit.set(true);
  }

  private async loadMissingDocumentTypes(): Promise<void> {
    this.allMissingDocumentTypes.set([]);
    this.overlayService.hasMissingDocumentTypes.set(false);

    if (this.shouldGetRulesets) {
      const allMissingDocumentTypes = await this.overlayService.allMissingDocumentTypesByRuleSet(
        this.attachmentTargetType(),
        this.attachmentTargetId(),
        this.allDocumentTypes(),
      );

      this.allMissingDocumentTypes.set(allMissingDocumentTypes);
      this.overlayService.hasMissingDocumentTypes.set(!!allMissingDocumentTypes.length);
    }
  }

  private async setAppliedRulesets(documentTypes: IDocumentType[]): Promise<IDocumentType[]> {
    const missingDocumentTypes = await this.overlayService.allMissingDocumentTypesByRuleSet(
      this.attachmentTargetType(),
      this.attachmentTargetId(),
      documentTypes,
      true,
    );

    const missingTypesMap = new Map<string, IDocumentType>();

    missingDocumentTypes.forEach((missingType) => {
      if (missingType && missingType.id) {
        missingTypesMap.set(missingType.id, missingType);
      }
    });

    const updatedDocumentTypes = documentTypes.map((docType) => {
      if (missingTypesMap.has(docType.id)) {
        return {
          ...docType,
          rulesetsNames: missingTypesMap.get(docType.id).rulesetsNames,
        };
      }

      return docType;
    });

    return updatedDocumentTypes;
  }

  private setDocumentColumns = (): void => {
    const documentColumns = ["recordState", "name", "type.name"];

    if (this.shouldGetRulesets) {
      documentColumns.push("rulesetsNames");
    }

    documentColumns.push(...["issuance", "validityStart", "validityEnd", "tags"]);

    this.documentColumns.set(documentColumns);
  };

  private setMissingDocumentTypeColumnDefs(): void {
    this.allMissingDocumentTypeColumnDefs.set([
      ColumnUtils.recordState(),
      {
        headerName: "Type",
        field: "name",
        lockVisible: true,
      },
      {
        headerName: "Rulesets",
        valueGetter: ({ data: documentType }) => documentType.rulesetsNames.join(", "),
      },
    ]);
  }
}
