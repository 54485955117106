import { AfterViewInit, ChangeDetectionStrategy, Component, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";

import { ColDef } from "ag-grid-community";
import {
  AcceptInboundShareErrorEnum,
  ConfirmDialogResponseEnum,
  CrossOrgShareDataTypeEnum,
  InboundShareStatusEnum,
  RoutingEnum,
  TableEnum,
  TagsColorEnum,
} from "src/app/shared/enums";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { ConfirmDialogComponent, InfoDialogComponent } from "@components/shared";
import { DateCellRendererComponent, LinkCellRendererComponent } from "@shared/cell-renderers";
import { CommonConstants } from "@shared/constants";
import { ICheckExistenceRecord, IInboundShare, IOrganisation } from "@shared/interfaces";
import { ConnectionsService, RecordSharingService } from "@shared/services";
import { RouterService } from "@shared/services/router.service";
import { CellRendererUtils, ColumnUtils, CommonUtils } from "@shared/utils";
import { InboundSharedRecordUtils } from "@shared/utils/inboud-shared-record.utils";

@Component({
  selector: "app-inbox-table",
  templateUrl: "./inbox-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InboxTableComponent implements AfterViewInit {
  public table = TableEnum.INBOX;

  public isLoading = signal(true);

  public rowData: any[] = [];

  public columnDefs = signal<ColDef[]>([]);

  private allInboundShares: IInboundShare[] = [];

  constructor(
    private recordSharingService: RecordSharingService,
    private notificationService: NotificationService,
    private connectionsService: ConnectionsService,
    private routerService: RouterService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
  ) {}

  public async ngAfterViewInit() {
    this.setColumnDefs();
    await this.getAll();
  }

  private setColumnDefs = (): void => {
    const columnDefs: ColDef[] = [
      {
        headerName: "New",
        field: "status",
        cellRenderer: (cell) => {
          return CellRendererUtils.showIconIfValue(
            cell,
            "fiber_manual_record",
            "red filled-icon",
            "",
            (cell) => cell.value === InboundShareStatusEnum.NEW,
          );
        },
        ...ColumnUtils.iconColumnCommonValues,
        tooltipValueGetter: (params: any) =>
          params.value === InboundShareStatusEnum.NEW ? "New" : "",
      },
      {
        headerName: "Sender",
        field: "sender",

        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteIdParam: "senderId",
          linkRouteFn: this.routerService.getOrganisationLink,
        },
      },
      { headerName: "Record type", field: "recordType" },
      {
        headerName: "ID / Name",
        field: "recordName",
        lockVisible: true,
        cellRenderer: (cell) => `<a>${cell.value}</a>`,
      },
      {
        headerName: "Received on",
        field: "receivedOn",
        cellRenderer: DateCellRendererComponent,
        cellRendererParams: {
          dateFormat: CommonConstants.DATE_TIME_FORMAT,
        },
        sort: "desc",
      },
      ColumnUtils.tags("New / Existing records", "newExistingTags"),
      {
        headerName: "Status",
        field: "status",
        cellRenderer: CellRendererUtils.capitaliseFirstLetter,
      },
    ];

    const buttons = [
      {
        onClick: this.onDelete,
        tooltip: () => "Delete",
        icon: "delete",
        isVisible: (row) => row.canDelete,
      },
      {
        onClick: this.onViewRawData,
        tooltip: () => "View raw data",
        icon: "package_2",
      },
      {
        tooltip: () => "Map",
        icon: "sync_alt",
        onClick: this.onMap,
        isVisible: (row) => row.canMap,
      },
      {
        onClick: this.onAccept,
        tooltip: () => "Accept",
        icon: "arrow_forward",
        isVisible: (row) => row.canAccept,
      },
    ];

    columnDefs.push(ColumnUtils.buttons(buttons));

    this.columnDefs.set(columnDefs);
  };

  public onViewOnOverlay = async (row: any): Promise<void> => {
    if (row.status === InboundShareStatusEnum.NEW) {
      //update the NEW badge count on the "Inbox" left side menu
      await this.recordSharingService.setInboundShareStatus(
        { status: InboundShareStatusEnum.VIEWED },
        row.id,
      );
      //we don't await for these requests, they run in the background while the overlay is opening
      this.recordSharingService.getAllInboundShares();
      this.getAll();
    }

    switch (row.crossOrgShareDataType) {
      case CrossOrgShareDataTypeEnum.LOCATIONS:
        await this.routerService.navigate(
          this.routerService.getSharedLocationLink(row.recordId, false, {
            organisationId: row.senderId,
          }),
        );
        break;
      case CrossOrgShareDataTypeEnum.SUPPLY_CHAINS:
        await this.routerService.navigate(
          `${RoutingEnum.INBOX_SHARED_SUPPLY_CHAIN}/${row.recordId}?&senderOrgId=${row.senderId}`,
        );
        break;
      case CrossOrgShareDataTypeEnum.DELIVERIES:
        await this.routerService.navigate(
          this.routerService.getSharedDeliveryLink(row.recordId, false, {
            organisationId: row.senderId,
          }),
        );
        break;
    }
  };

  public onViewRawData = async (row: any): Promise<void> => {
    const currentRow = this.allInboundShares.find((i) => i.id === row.id);

    this.dialog.open(InfoDialogComponent, {
      data: {
        titleTranslatedText: "Shared raw data",
        contentTranslatedText: JSON.stringify(currentRow),
      },
    });
  };

  private onDelete = (row: any): void => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: `Delete shared data`,
        contentTranslatedText: "Are you sure that you want to delete this shared data?",
        confirmButtonColor: "danger",
        confirmButtonTranslatedText: "Delete",
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        await this.delete(row.id);
      }
    });
  };

  private onMap = async (row: any): Promise<void> => {
    await this.routerService.navigate(`${RoutingEnum.INBOX_TRANSFER_OR_MAP}/${row.id}`);
  };

  private onAccept = (row: any): void => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: `Accept data`,
        contentTranslatedText: "Are you sure that you want to accept this shared data?",
        confirmButtonTranslatedText: "Accept",
        confirmButtonIcon: "check",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        await this.accept(row.id);
      }
    });
  };

  private async delete(id: string): Promise<void> {
    this.isLoading.set(true);
    try {
      await this.recordSharingService.deleteInboundShare(id);
      this.notificationService.showSuccess("Shared data deleted");
      await this.getAll();
    } catch (error) {
      this.notificationService.showError(error);
    } finally {
      this.isLoading.set(false);
    }
  }

  private async accept(id: string): Promise<void> {
    this.isLoading.set(true);
    try {
      await this.recordSharingService.acceptInboundShare(id);
      this.notificationService.showSuccess("Shared data accepted");
      await this.getAll();
    } catch (error) {
      await this.handleAcceptError(error);
    } finally {
      this.isLoading.set(false);
    }
  }

  private handleAcceptError = async (error: any): Promise<void> => {
    if (error.status === 422 && error.error?.errors?.length) {
      let contentSafeHTML: string = "";

      const alreadyExistsErrors = error.error.errors.filter(
        (e) => e.code === AcceptInboundShareErrorEnum.SHARED_ENTITY_ALREADY_EXISTS,
      );

      if (alreadyExistsErrors.length) {
        let errorsListHtml = "";

        for (const errorObj of alreadyExistsErrors) {
          const recordType = errorObj.arguments.uri.split("/")[3];
          const formattedRecordType = CommonUtils.capitaliseFirstLetter(
            CommonUtils.singlifyEntity(recordType),
          ).replace("-", " ");

          errorsListHtml += `<li><b>${formattedRecordType}:</b> ${errorObj.arguments.id}</li>`;
        }

        contentSafeHTML += `<p>The following records already exist locally and have not yet been mapped:</p><ul class="text-left">${errorsListHtml}</ul>`;
      }

      const addArchivedErrors = error.error.errors.filter(
        (e) => e.code === AcceptInboundShareErrorEnum.ADD_ARCHIVED_ENTITY_FORBIDDEN,
      );

      if (addArchivedErrors.length) {
        let errorsListHtml = "";

        for (const errorObj of addArchivedErrors) {
          // The archived element here is the active organisation element, not the inbound record.
          // To get the name we'd have to switch case the entity_name and use the related service to get the record name
          // We are not implementing that just yet as there is a high chance we will not allow records to be mapped to
          // archived elements so this error should not happen. For now we just display the archived element ID
          const recordName = errorObj.arguments.id;

          errorsListHtml += `<li><b>${errorObj.arguments.entity_name}:</b> ${recordName}</li>`;
        }

        contentSafeHTML += `<p>The following records cannot be added because they are archived:</p><ul class="text-left">${errorsListHtml}</ul>`;
      }

      const otherErrors = error.error.errors.filter(
        (e) =>
          ![
            AcceptInboundShareErrorEnum.SHARED_ENTITY_ALREADY_EXISTS,
            AcceptInboundShareErrorEnum.ADD_ARCHIVED_ENTITY_FORBIDDEN,
          ].includes(e.code),
      );

      for (const otherError of otherErrors) {
        contentSafeHTML += `<p>${CommonUtils.enumToText(otherError)}</p>`;
      }

      this.dialog.open(InfoDialogComponent, {
        data: {
          icon: "error",
          iconColor: "red",
          titleTranslatedText: "Can’t accept share",
          contentSafeHTML: this.sanitizer.bypassSecurityTrustHtml(contentSafeHTML),
        },
      });
    } else {
      this.notificationService.showError(error);
    }
  };

  private getParsedRowData = async (inboundShare: IInboundShare): Promise<any> => {
    const crossOrgShareDataType = InboundSharedRecordUtils.getSharedDataType(
      inboundShare.rootRecordUri,
    );

    const senderId = CommonUtils.getUriId(inboundShare.senderUri);
    const recordId = CommonUtils.getUriId(inboundShare.rootRecordUri);
    let sender: IOrganisation = undefined;
    let record: any = undefined;
    let checkExistenceRecords: ICheckExistenceRecord = undefined;

    await Promise.all([
      (sender = await this.connectionsService.get(senderId)),
      (record = await InboundSharedRecordUtils.getSharedRecord(
        senderId,
        crossOrgShareDataType,
        recordId,
        this.recordSharingService,
      )),
      (checkExistenceRecords = await this.recordSharingService.getCheckExistingRecord(
        inboundShare.rootRecordUri,
      )),
    ]);

    const newExistingTags = [];

    if (checkExistenceRecords?.newRecords?.length > 0) {
      newExistingTags.push({
        title: `New (${checkExistenceRecords.newRecords.length})`,
        color: TagsColorEnum.BLUE,
      });
    }
    if (checkExistenceRecords?.existingRecordsInfo?.length > 0) {
      newExistingTags.push({
        title: `Existing (${checkExistenceRecords.existingRecordsInfo.length})`,
        color: TagsColorEnum.ORANGE,
      });
    }

    const recordType = CommonUtils.singlifyEntity(
      CommonUtils.capitaliseFirstLetter(inboundShare.rootRecordUri.split("/")[7]),
    );
    const status = inboundShare.status;

    let recordName: string;

    switch (crossOrgShareDataType) {
      case CrossOrgShareDataTypeEnum.DELIVERIES:
        recordName = record.deliveryId;
        break;
      default:
        recordName = record.name;
        break;
    }

    return {
      id: inboundShare.id,
      senderId,
      sender: sender.name,
      recordType,
      crossOrgShareDataType,
      recordId,
      recordName,
      receivedOn: inboundShare.receivedOn,
      status,
      newExistingTags,
      canDelete: true,
      canMap: true,
      canAccept: status !== InboundShareStatusEnum.ACCEPTED,
    };
  };

  public getAll = async (): Promise<void> => {
    this.isLoading.set(true);
    try {
      this.allInboundShares = await this.recordSharingService.getAllInboundShares();
      const rowData = [];
      const promises = this.allInboundShares.map(async (inboundShare) => {
        rowData.push(await this.getParsedRowData(inboundShare));
      });

      await Promise.all(promises);
      this.rowData = rowData;
      this.isLoading.set(false);
    } catch (error) {
      this.notificationService.showError(error);
    }
  };
}
