import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  input,
  Output,
  TemplateRef,
} from "@angular/core";
import { FormGroup } from "@angular/forms";

import { InputSelectOption } from "@design-makeover/components/inputs/input-select/input-select.model";

import { ISelectOption } from "@shared/interfaces";

@Component({
  selector: "app-edit-delivery-main-details",
  templateUrl: "./edit-delivery-main-details.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditDeliveryMainDetailsComponent {
  @Input() formGroup: FormGroup;

  public fromLocationOptions = input<ISelectOption[]>([]);

  public toLocationOptions = input<ISelectOption[]>([]);

  public organisationsOptions = input<ISelectOption[]>([]);

  public statusOptions = input<ISelectOption[]>([]);

  public isEditing = input<boolean>(false);

  public locationViewTemplate = input<TemplateRef<unknown>>(null);

  @Output() traderTagPressed: EventEmitter<InputSelectOption> =
    new EventEmitter<InputSelectOption>();
}
