<div class="container-flex-space-between">
  <div class="container-flex">
    <mat-icon>account_circle</mat-icon>
    {{ comment.emailAddress }}
  </div>
  <div class="container-flex-right">
    <span>{{ comment.created | localTime: dateTimeFormat }}</span>
    @if (isAccountOwnerOrAdmin || currentUserEmail === comment.emailAddress) {
      <button class="more-button" mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu class="dropdown-menu comment-card-dropdown-menu">
        <iov-dropdown-menu>
          @if (currentUserEmail === comment.emailAddress) {
            <iov-dropdown-menu-item (pressed)="onEdit()">
              <mat-icon>edit</mat-icon>
              Edit
            </iov-dropdown-menu-item>
          }

          <iov-dropdown-menu-item (pressed)="onDelete()">
            <mat-icon>delete</mat-icon>
            Delete
          </iov-dropdown-menu-item>
        </iov-dropdown-menu>
      </mat-menu>
    }
  </div>
</div>

@if (isEditing()) {
  <form [formGroup]="formGroup">
    <div class="comment-input-container">
      <iov-editable-div
        hintErrorClass="top-83"
        #editableDiv
        formControlName="text"
      ></iov-editable-div>
      <div class="container-flex-right">
        <iov-button-group>
          <iov-button (pressed)="onSave()" [disabled]="isSubmitButtonDisabled()">
            <mat-icon icon-left>check</mat-icon>
            Save
          </iov-button>
          <iov-button (pressed)="onCancel()" type="ghost">Cancel</iov-button>
        </iov-button-group>
      </div>
    </div>
  </form>
} @else {
  <p
    #textElement
    [innerHtml]="commentText"
    [class.expanded]="!isCollapsed"
    class="comment-content break-word"
  ></p>

  @if (isShowReadMoreLess) {
    <a tabindex="0" class="blue-link" (click)="toggleCollapse()" (keyup)="toggleCollapse()">
      {{ isCollapsed ? "Read more..." : "Show less" }}
    </a>
  }
}
