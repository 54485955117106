import { Injectable } from "@angular/core";
import { AbstractControl, Validators } from "@angular/forms";

import { CustomValidators } from "src/app/shared/validators";

@Injectable({
  providedIn: "root",
})
export class FormControlService {
  calculateOptionalFlag(control: AbstractControl): boolean {
    return [
      control.hasValidator(CustomValidators.required),
      control.hasValidator(Validators.required),
    ].every((value) => value === false);
  }
}
