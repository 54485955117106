import { inject } from "@angular/core";

import { BulkAddSlideOverService } from "@components/shared/bulk-add-slide-over/bulk-add-slide-over.service";

export abstract class BulkAddSlideOverClass {
  protected bulkAddService = inject(BulkAddSlideOverService);

  abstract canClose(): Promise<boolean>;

  public afterClose(): void {}

  public slideOverWrapperClass(): string {
    return "";
  }
}
