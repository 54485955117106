import { getDocumentProperties } from "./documents.query";
import { QueriesUtils } from "./queries.utils";

/**
 * Retrieves inbound documents by their IDs using a GraphQL query.
 *
 * @param orgId - The ID of the organization.
 * @param senderId - The ID of the sender.
 * @param ids - An array of document IDs.
 * @param first - The maximum number of documents to retrieve from the beginning.
 * @param last - The maximum number of documents to retrieve from the end.
 * @param after - The cursor to start retrieving documents after.
 * @param before - The cursor to start retrieving documents before.
 * @param include - An array of properties to include in the retrieved documents.
 * @returns The GraphQL query string.
 */
export const getInboundDocumentsByIdsGraphQLQuery = (
  orgId: string,
  senderId: string,
  ids: string[],
  first: number,
  last: number,
  after: string,
  before: string,
  include: string[],
): string => {
  const afterCursorClause = first > 0 ? QueriesUtils.generateClause("after", after) : "";
  const firstClause = first > 0 ? QueriesUtils.generateClause("first", first) : "";
  const beforeCursorClause = last > 0 ? QueriesUtils.generateClause("before", before) : "";
  const lastClause = last > 0 ? QueriesUtils.generateClause("last", last) : "";

  return `
  {
      inboundLoadDocumentsByIds(   
      orgId: "${orgId}",
      senderId: "${senderId}",
      ids: ${QueriesUtils.getParsedIds(ids)},
      ${afterCursorClause ? `${afterCursorClause},` : ""}
      ${beforeCursorClause ? `${beforeCursorClause},` : ""}
      ${firstClause ? `${firstClause},` : ""}
      ${lastClause ? `${lastClause},` : ""}
  ) {       
    ${getDocumentProperties(include)}
      }
  }
  `;
};
