import { inject, Injectable, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { AttachmentTargetEnum, AttachmentTypeEnum, RecordStateEnum } from "@shared/enums";
import {
  IAttachment,
  ICertificate,
  ICertificateExtended,
  IDocument,
  IDocumentExtended,
  IDocumentType,
  IStandard,
} from "@shared/interfaces";
import {
  AttachmentsService,
  AuthenticationService,
  CertificatesService,
  DocumentsService,
  DocumentTypesService,
  RulesetsService,
  StandardsService,
  StandardTypesService,
} from "@shared/services";
import { CommentsService } from "@shared/services/api/comments.service";
import { CommonUtils } from "@shared/utils";
import { RulesetUtils } from "@shared/utils/ruleset.utils";

@Injectable()
export abstract class OverlayCommonService {
  public documentCounter = signal<number>(0);

  public hasMissingDocumentTypes = signal<boolean>(false);

  public certificateCounter = signal<number>(0);

  public commentCounter = signal<number>(0);

  authenticationService = inject(AuthenticationService);

  attachmentsService = inject(AttachmentsService);

  certificatesService = inject(CertificatesService);

  standardTypesService = inject(StandardTypesService);

  documentsService = inject(DocumentsService);

  standardsService = inject(StandardsService);

  documentTypesService = inject(DocumentTypesService);

  notificationService = inject(NotificationService);

  rulesetsService = inject(RulesetsService);

  commentsService = inject(CommentsService);

  dialog = inject(MatDialog);

  public async removeAttachment(attachmentType: string, attachmentId: string): Promise<void> {
    try {
      await this.attachmentsService.delete(attachmentId);
      this.notificationService.showSuccess(
        `${CommonUtils.capitaliseFirstLetter(attachmentType)} removed`,
      );
    } catch (error) {
      this.notificationService.showError(error);

      return Promise.reject(error);
    }
  }

  public async loadSelectedAttachments(
    attachmentType: AttachmentTypeEnum,
    attachmentTarget: AttachmentTargetEnum,
    targetId: string,
    collections?: {
      allCertificates?: ICertificate[];
      allStandards?: IStandard[];
      allDocuments?: IDocument[];
      allDocumentTypes?: IDocumentType[];
    },
    raw?: boolean,
  ): Promise<ICertificate[] | IDocument[] | IAttachment[]> {
    try {
      const activeOrganisationId = this.authenticationService.getActiveOrganisationId();
      const response = [];

      const attachments = await this.attachmentsService.getAll(
        attachmentType,
        CommonUtils.getTargetUri(activeOrganisationId, attachmentTarget, targetId),
        null,
      );

      if (raw) {
        return attachments;
      }

      if (attachmentType === AttachmentTypeEnum.CERTIFICATE) {
        for (const item of attachments) {
          const certificateId = CommonUtils.getUriId(item["attachmentUri"]);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          let certificate: any = collections?.allCertificates?.find((m) => m.id === certificateId);

          if (!certificate) {
            const certificatesResponse = await this.certificatesService.getByIdsGraphQL([
              certificateId,
            ]);

            certificate = certificatesResponse[0] ?? undefined;
          }
          certificate.linkId = item.id;
          response.push(certificate);
        }
      } else if (attachmentType === AttachmentTypeEnum.DOCUMENT) {
        for (const item of attachments) {
          const documentId = CommonUtils.getUriId(item["attachmentUri"]);
          let document: IDocument = collections?.allDocuments?.find((m) => m.id === documentId);

          if (!document) {
            document = await this.documentsService.get(documentId);
          }
          let documentType: IDocumentType;

          if (document.type.includes("/")) {
            documentType = collections?.allDocumentTypes?.find(
              (t) => t.id === CommonUtils.getUriId(document.type),
            );
          }

          response.push({
            id: document.id,
            type: documentType?.name || document.type,
            name: document.name,
            contentType: document.contentType,
            validityStart: document.validityStart,
            validityEnd: document.validityEnd,
            linkId: item.id,
            recordState: document.recordState,
          });
        }
      }

      return response;
    } catch (error) {
      this.notificationService.showError(error);

      return Promise.reject(error);
    }
  }

  public async allMissingDocumentTypesByRuleSet(
    attachmentTargetType: AttachmentTargetEnum,
    attachmentTargetId: string,
    allDocumentTypes: IDocumentType[],
    conditionsMet: boolean = false,
  ): Promise<IDocumentType[]> {
    if (!this.authenticationService.isRegularUser()) {
      return [];
    }
    try {
      const organisationId = this.authenticationService.getActiveOrganisationId();
      const uri = CommonUtils.getTargetUri(
        organisationId,
        attachmentTargetType,
        attachmentTargetId,
      );
      const records = await this.rulesetsService.getInstantRulesetRecords(uri);
      const record = records[0];

      return RulesetUtils.getDocumentTypesWithRulesets(record, conditionsMet, allDocumentTypes);
    } catch (error) {
      this.notificationService.showError(error);

      return Promise.reject();
    }
  }

  public async loadStandards(): Promise<IStandard[]> {
    try {
      return await this.standardsService.getAll();
    } catch (error) {
      this.notificationService.showError(error);

      return Promise.reject();
    }
  }

  public async loadDocumentTypes(): Promise<IDocumentType[]> {
    try {
      return await this.documentTypesService.getAll();
    } catch (error) {
      this.notificationService.showError(error);

      return Promise.reject();
    }
  }

  public async loadCertificates(searchText?: string): Promise<ICertificateExtended[]> {
    try {
      const certificates = await this.certificatesService.getAllGraphQL({
        number: searchText || undefined,
        recordState: RecordStateEnum.ACTIVE,
      });

      return certificates;
    } catch (error) {
      this.notificationService.showError(error);

      return Promise.reject();
    }
  }

  private convertExtendedDocumentToDocument(extendedDocument: IDocumentExtended): IDocument {
    return {
      ...extendedDocument,
      typeName: extendedDocument.type?.name,
      type: extendedDocument.type?.name,
      createdBy: null,
      createdTime: null,
    };
  }

  public async loadDocuments(searchText?: string): Promise<IDocument[]> {
    try {
      const extendedDocuments = await this.documentsService.getAllGraphQL(
        { name: searchText || undefined, recordState: RecordStateEnum.ACTIVE, hasContent: true },
        undefined,
        ["TAGS"],
      );

      const documents: IDocument[] = [];

      for (const extendedDocument of extendedDocuments) {
        const document = this.convertExtendedDocumentToDocument(extendedDocument);

        documents.push(document);
      }

      return documents;
    } catch (error) {
      this.notificationService.showError(error);

      return Promise.reject();
    }
  }

  public async createAttachment(
    attachmentTargetType: AttachmentTargetEnum,
    attachmentTargetId: string,
    attachmentType: AttachmentTypeEnum,
    attachmentId: string,
  ): Promise<void> {
    try {
      const activeOrganisationId = this.authenticationService.getActiveOrganisationId();
      const payload = CommonUtils.getSaveAttachmentPayload(
        activeOrganisationId,
        attachmentTargetType,
        attachmentTargetId,
        attachmentType,
        attachmentId,
      );

      await this.attachmentsService.create(payload);
    } catch (error) {
      this.notificationService.showError(error);

      return Promise.reject();
    }
  }

  setCountersToLoadingState(): void {
    this.hasMissingDocumentTypes.set(false);
    this.documentCounter.set(null);
    this.certificateCounter.set(null);
    this.commentCounter.set(null);
  }

  setCountersEmptyState(): void {
    this.hasMissingDocumentTypes.set(false);
    this.documentCounter.set(0);
    this.certificateCounter.set(0);
    this.commentCounter.set(0);
  }

  async loadDocumentCounter(
    attachmentTargetId: string,
    attachmentTargetType: AttachmentTargetEnum,
  ): Promise<void> {
    const allDocumentTypes = await this.loadDocumentTypes();

    const allMissingDocumentTypes = await this.allMissingDocumentTypesByRuleSet(
      attachmentTargetType,
      attachmentTargetId,
      allDocumentTypes,
    );

    this.hasMissingDocumentTypes.set(!!allMissingDocumentTypes.length);

    const documentAttachments = await this.loadSelectedAttachments(
      AttachmentTypeEnum.DOCUMENT,
      attachmentTargetType,
      attachmentTargetId,
      {},
      true,
    );

    this.documentCounter.set(documentAttachments.length);
  }

  async loadCertificateCounter(
    attachmentTargetId: string,
    attachmentTargetType: AttachmentTargetEnum,
  ): Promise<void> {
    const certificates = await this.loadSelectedAttachments(
      AttachmentTypeEnum.CERTIFICATE,
      attachmentTargetType,
      attachmentTargetId,
    );

    this.certificateCounter.set(certificates.length);
  }

  async loadCommentCounter(entityUri: string): Promise<void> {
    if (!this.authenticationService.isRegularUser()) {
      return;
    }
    const comments = (await this.commentsService.getAll(entityUri)).content;

    this.commentCounter.set(comments.length);
  }
}
