<div class="container-space-between">
  <app-page-title
    title="Location types"
    backText="Back to Admin"
    [goBackRoute]="routingEnum.ADMIN"
  ></app-page-title>

  <iov-button-group>
    <iov-button (pressed)="onAdd()">
      <mat-icon icon-left>add</mat-icon>
      Add new
    </iov-button>
  </iov-button-group>
</div>

<app-location-types-table
  [recordState]="recordStateEnum.ACTIVE"
  [isSaveTableState]="true"
  [isFixedBottomPaginator]="true"
  #locationTypesTable
></app-location-types-table>
