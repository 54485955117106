import { Inject, Injectable } from "@angular/core";

import { APP_CONFIG } from "@shared/tokens";

import { ApiService } from "./api.service";
import { CommonConstants } from "../../constants";
import { IConfig, IPageableContent, IUser } from "../../interfaces";
import { FormUtils } from "../../utils";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  constructor(
    private apiService: ApiService,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {}

  private getBaseUrl = (): string => `${this.environment.baseUrl}users`;

  public getPage = async (
    firstName: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
  ): Promise<IPageableContent<IUser>> => {
    const url = `${this.getBaseUrl()}?${FormUtils.addUrlParams({ firstName, size, page, sort })}`;

    return await this.apiService.get<IPageableContent<IUser>>(url).toPromise();
  };

  public async getAll(): Promise<IUser[]> {
    const result: IUser[] = [];
    let page = 0;
    let hasNextPage: boolean = true;

    while (hasNextPage) {
      const {
        content,
        page: { totalElements },
      } = await this.getPage(undefined, CommonConstants.MAX_API_GET_ITEMS_SIZE, page, undefined);

      result.push(...content);

      page++;
      hasNextPage = result.length < totalElements;
    }

    return result;
  }

  public get = async (id: string): Promise<IUser> =>
    await this.apiService.get<IUser>(`${this.getBaseUrl()}/${id}`).toPromise();

  public getUserOrganisationMembershipsPage = async (
    userId: string,
    name: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
  ): Promise<IPageableContent<any>> => {
    const url = `${this.getBaseUrl()}/${userId}/organisations?${FormUtils.addUrlParams({
      name,
      size,
      page,
      sort,
    })}`;

    return await this.apiService.get<IPageableContent<any>>(url).toPromise();
  };

  public async getAllUserOrganisationMemberships(userId: string): Promise<any[]> {
    const result: any[] = [];
    let page = 0;
    let hasNextPage: boolean = true;

    while (hasNextPage) {
      const {
        content,
        page: { totalElements },
      } = await this.getUserOrganisationMembershipsPage(
        userId,
        undefined,
        CommonConstants.MAX_API_GET_ITEMS_SIZE,
        page,
        undefined,
      );

      result.push(...content);

      page++;
      hasNextPage = result.length < totalElements;
    }

    return result;
  }

  public leaveOrganisation = async (userId: string, organisationId: string): Promise<void> =>
    await this.apiService
      .post<void>(`${this.getBaseUrl()}/${userId}/organisations/${organisationId}/leave`)
      .toPromise();
}
