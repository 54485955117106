import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatIcon } from "@angular/material/icon";

@Component({
  selector: "app-external-link-icon",
  templateUrl: "./external-link-icon.component.html",
  styleUrls: ["./external-link-icon.component.scss"],
  imports: [MatIcon],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ExternalLinkIconComponent {}
