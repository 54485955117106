import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

import { SlideOverlayContentComponent } from "@design-makeover/components/overlay/slide-overlay-content/slide-overlay-content.component";
import { SlideOverlayPageClass } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.class";

import { OrganisationOverlayService } from "@components/organisations/pages/organisation-overlay/organisation-overlay.service";
import { CommonConstants } from "@shared/constants";
import { AttachmentTargetEnum, EntityTypeEnum, OverlayTabEnum, RoutingEnum } from "@shared/enums";
import { IConnectionExtended, ISelectOption } from "@shared/interfaces";
import { CommonService, RecordSharingService } from "@shared/services";

@Component({
  selector: "app-shared-organisation-overlay",
  templateUrl: "./shared-organisation-overlay.component.html",
  styleUrl: "../organisation-overlay-common.scss",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SharedOrganisationOverlayComponent
  extends SlideOverlayPageClass
  implements OnInit, OnDestroy
{
  formGroup: UntypedFormGroup = null;

  countryOptions: ISelectOption[] = [];

  override menuItems = signal(
    new Map([
      [OverlayTabEnum.DETAILS, { isEnabled: true }],
      [OverlayTabEnum.DOCUMENTS, { isEnabled: true }],
      [OverlayTabEnum.CERTIFICATES, { isEnabled: true }],
    ]),
  );

  override element = signal<IConnectionExtended>(null);

  public override entityType: EntityTypeEnum = EntityTypeEnum.CONNECTIONS;

  override readonly attachmentTargetType = AttachmentTargetEnum.ORGANISATION;

  @ViewChild("slideOverlayContent") override slideOverlayContent: SlideOverlayContentComponent;

  constructor(
    public organisationOverlay: OrganisationOverlayService,
    private commonService: CommonService,
    private recordSharingService: RecordSharingService,
  ) {
    super();

    this.subscriptions.add(
      this.commonService.countriesOptionsObservable$.subscribe(
        (countriesOptions: ISelectOption[]) => {
          this.countryOptions = countriesOptions;
        },
      ),
    );
  }

  // setCountersToLoadingState() {
  //     this.organisationOverlay.setCountersToLoadingState();
  //     this.selectedLocationsIds = null;
  // }

  // setCountersEmptyState() {
  //     this.organisationOverlay.setCountersEmptyState();
  // }

  async ngOnInit(): Promise<void> {
    this.overlay.showLoading();
    if (!this.isOnCorrectOverlay(RoutingEnum.OVERLAY_SHARED_ORGANISATION)) {
      return;
    }
    if (!this.recordId) {
      this.notification.showError("Missing parameters");

      return;
    }
    //this.setCountersToLoadingState();
    await this.reloadElement(this.recordId);
    await this.setMenuItemFromURLParam();
    //this.loadCounters();
  }

  ngOnDestroy(): void {
    //this.setCountersEmptyState();
    this.subscriptions.unsubscribe();
  }

  override setupForm(): void {
    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl(this.element()?.name ?? null),
      website: new UntypedFormControl(this.element()?.website ?? null),
      street: new UntypedFormControl(this.element()?.address?.street ?? null),
      region: new UntypedFormControl(this.element()?.address?.region ?? null),
      zipCode: new UntypedFormControl(this.element()?.address?.zipCode ?? null),
      country: new UntypedFormControl(
        this.countryOptions.find((c) => c.value === this.element().address?.country),
      ),
    });
  }

  protected override async reloadElement(id: string): Promise<void> {
    try {
      this.overlay.showLoading();
      const connection = await this.recordSharingService.getInboundConnectionsByIdsGraphQL(
        [id],
        this.organisationIdQueryParam,
        CommonConstants.MAX_API_GET_ITEMS_SIZE,
        ["FULL_ADDRESS", "CERTIFICATES", "DOCUMENTS"],
      );

      this.element.set(connection[0]);
      this.setupForm();
      this.overlay.dismissLoading();
    } catch (error) {
      this.notification.showError(error);
    }
  }

  protected override recordName(): string {
    return this.element()?.name;
  }
}
