import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { ExtensionProviderEnum, FeatureFlagEnum, RoutingEnum } from "src/app/shared/enums";
import {
  AuthenticationService,
  ExtensionsService,
  FeatureFlagService,
} from "src/app/shared/services";

@Component({
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsComponent implements OnInit {
  public readonly isSystemAdmin = this.authenticationService.isSystemAdminOrganisation();

  public readonly isShowEnvironment = this.featureFlagService.isEnabled(
    FeatureFlagEnum.ADMIN_SHOW_ENVIRONMENT,
  );

  private readonly isRiskAssessmentEnabled = this.featureFlagService.isEnabled(
    FeatureFlagEnum.RISK_ASSESSMENT,
  );

  private readonly isCrossOrgSharingEnabled = this.featureFlagService.isEnabled(
    FeatureFlagEnum.CROSS_ORGANISATION_SHARING,
  );

  public operationsGeneralSettings = [];

  public operationsUserSettings = [];

  public operationsExtensionsSettings = [];

  public operationsDataSharingSettings = [];

  operationsRiskAssessmentSettings = [];

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    public featureFlagService: FeatureFlagService,
    private extensionsService: ExtensionsService,
    private cd: ChangeDetectorRef,
  ) {}

  public async ngOnInit(): Promise<void> {
    await this.setupSettings();
  }

  private setupSettings = async (): Promise<void> => {
    const isAccountOwnerOrAdmin = this.authenticationService.isAccountOwnerOrAdmin();
    const isAccountOwnerOrAdminOrContributor =
      this.authenticationService.isAccountOwnerOrAdminOrContributor();

    if (this.isSystemAdmin) {
      this.operationsGeneralSettings.push({
        route: RoutingEnum.ADMIN_STANDARDS,
        icon: "security",
        title: "Standards",
        description: "Manage system standards",
      });
    } else {
      this.operationsGeneralSettings.push(
        ...[
          {
            route: RoutingEnum.ADMIN_PRODUCTS,
            icon: "deployed_code",
            title: "Products",
            description: "Define products of your org.",
          },
          {
            route: RoutingEnum.ADMIN_MATERIALS,
            icon: "category",
            title: "Materials",
            description: "Define materials of your org.",
          },
          {
            route: RoutingEnum.ADMIN_DOCUMENT_TYPES,
            icon: "file_copy",
            title: "Document types",
            description: "Define document types of your org.",
          },
          {
            route: RoutingEnum.ADMIN_LOCATION_TYPES,
            icon: "distance",
            title: "Location types",
            description: "Define location types of your org.",
          },
          {
            route: RoutingEnum.ADMIN_PROCESS_TYPES,
            icon: "shuffle",
            title: "Process types",
            description: "Define process types of your org.",
          },
          {
            route: RoutingEnum.ADMIN_RULESETS,
            icon: "flag",
            title: "Rulesets",
            description: "Define rulesets for your operations.",
          },
        ],
      );

      if (isAccountOwnerOrAdmin) {
        this.operationsUserSettings.push({
          route: RoutingEnum.ADMIN_MEMBERSHIPS,
          icon: "group",
          title: "Users",
          description: "Manage users for your org.",
        });
        this.operationsGeneralSettings.push({
          route: RoutingEnum.ADMIN_CUSTOM_FIELDS,
          icon: "format_align_left",
          title: "Custom fields",
          description: "Define custom fields of your org.",
        });
      }

      if (isAccountOwnerOrAdminOrContributor) {
        this.operationsGeneralSettings.push({
          route: RoutingEnum.ADMIN_TAGS,
          icon: "sell",
          title: "Tags",
          description: "Define tags of your org.",
        });

        if (this.isCrossOrgSharingEnabled) {
          this.operationsDataSharingSettings.push({
            route: RoutingEnum.ADMIN_SENT_INFORMATION,
            icon: "send",
            title: "Sent information",
            description: "See which infromation was sent from your org.",
          });
        }

        if (this.isRiskAssessmentEnabled) {
          this.operationsRiskAssessmentSettings = [
            ...this.operationsRiskAssessmentSettings,
            {
              route: RoutingEnum.ADMIN_RISK_ASSESSMENT_TEMPLATES,
              icon: "book_2",
              title: "Risk assessment templates",
              description: "Define templates for risk assessment.",
            },
            {
              route: RoutingEnum.ADMIN_RISK_LEVEL_SETS,
              icon: "list",
              title: "Risk level sets",
              description: "Configure risk levels for risk assessments.",
            },
          ];
        }
      }

      if (isAccountOwnerOrAdmin) {
        this.operationsGeneralSettings.push({
          route: RoutingEnum.ADMIN_UNITS_OF_MEASUREMENT,
          svgIcon: "tape",
          title: "Units of measurement",
          description: "Define units of measurement of your org.",
        });
      }

      // we don't await this call not to block the page loading.
      this.extensionsService
        .getAllEnabled(this.authenticationService.getActiveOrganisationId())
        .then((enabledExtensions: string[]) => {
          if (enabledExtensions.includes(ExtensionProviderEnum.ORBIFY)) {
            this.operationsExtensionsSettings.push({
              route: RoutingEnum.ADMIN_EXTENSIONS_ORBIFY,
              svgIcon: "orbify",
              title: "Orbify",
              description: "See all analyses generated via Orbify",
            });
            this.cd.markForCheck();
          }
        });
    }
  };

  public onGoToRoute = async (route: RoutingEnum): Promise<void> => {
    await this.router.navigate([`/${route}`]);
  };
}
