<div class="supply-chain-buttons">
  @if (addLocationOptionEnabled) {
    <div>
      <iov-button (pressed)="addLocation()" tp="Add Location" type="filled">
        <mat-icon svgIcon="plus"></mat-icon>
      </iov-button>
    </div>
  }

  @if (fullScreenOptionEnabled) {
    <div>
      <iov-button (pressed)="fullScreen()" tp="Full screen" type="outlined">
        <mat-icon svgIcon="maximize-2"></mat-icon>
      </iov-button>
    </div>
  }
</div>

<div
  class="flow-chart-container"
  [style.height]="height + 'px'"
  [style.width]="'100%'"
  [style.cursor]="isZoomEnabled ? 'move' : 'unset'"
  [ngClass]="containerClass"
>
  <svg class="svg-content" [id]="svgId">
    <g class="flow-chart-content"></g>
  </svg>
</div>

<iov-slide-overlay-wrapper [close]="!isSlideOverVisible()" (clickClose)="closeSlideOver()">
  <div class="full-width">
    <div class="slide-over-content full-width full-height">
      @if (isSlideOverVisible()) {
        <div class="container-space-between">
          <app-page-title [title]="slideOverTitle" (back)="closeSlideOver()"></app-page-title>
        </div>
        <div class="overlay-wrapper-content-max-width">
          @if (selectedCertificates?.length) {
            <app-certificates-table
              [recordState]="
                onlyDisplayActiveAttachments ? recordStateEnum.ACTIVE : recordStateEnum.ALL
              "
              [certificates]="selectedCertificates"
              [shouldOpenInNewTab]="isFullScreenMode"
              [inboundSharedSenderOrgId]="inboundSharedSenderOrgId"
            ></app-certificates-table>
          } @else if (selectedDocuments?.length || missingDocumentTypes?.length) {
            @if (missingDocumentTypes?.length && !isLoadingMissingData()) {
              <p class="dark-gray bold">Missing documents ({{ missingDocumentTypes.length }})</p>
              <app-document-types-table
                [documentTypes]="missingDocumentTypes"
                [isSearchEnabled]="false"
                [areButtonsEnabled]="false"
                [columns]="['type', 'rulesetsNames']"
              ></app-document-types-table>
            }
            <p *ngIf="missingDocumentTypes?.length" class="dark-gray bold">Attached documents</p>
            <app-documents-table
              [recordState]="
                onlyDisplayActiveAttachments ? recordStateEnum.ACTIVE : recordStateEnum.ALL
              "
              [documents]="selectedDocuments"
              [shouldOpenInNewTab]="isFullScreenMode"
              [isInboundShared]="isInboundShared"
              [inboundSharedSenderOrgId]="inboundSharedSenderOrgId"
            ></app-documents-table>
          }
        </div>
      } @else {
        <app-loader></app-loader>
      }
    </div>
  </div>
</iov-slide-overlay-wrapper>
