import { ChangeDetectionStrategy, Component, ViewChild } from "@angular/core";

import { RecordStateEnum, RoutingEnum } from "src/app/shared/enums";

import { RouterService } from "@shared/services/router.service";

import { MaterialsTableComponent } from "../../shared";

@Component({
  templateUrl: "./settings-materials.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsMaterialsComponent {
  @ViewChild("materialsTable") materialsTable: MaterialsTableComponent;

  public readonly routingEnum = RoutingEnum;

  public readonly recordStateEnum = RecordStateEnum;

  constructor(private router: RouterService) {}

  public onAdd = async (): Promise<void> => {
    await this.router.navigate(this.router.getMaterialLink());
  };
}
