import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

import { SlideOverlayContentComponent } from "@design-makeover/components/overlay/slide-overlay-content/slide-overlay-content.component";
import { SlideOverlayPageClass } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.class";
import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { AddLocationDialogComponent } from "@components/organisations";
import { OrganisationOverlayService } from "@components/organisations/pages/organisation-overlay/organisation-overlay.service";
import {
  ConfirmDialogComponent,
  EditOrganisationConnectionDialogComponent,
  LocationsTableComponent,
  OverlayCertificateAttachmentsComponent,
  OverlayDocumentAttachmentsComponent,
} from "@components/shared";
import { CommonConstants } from "@shared/constants";
import {
  AttachmentTargetEnum,
  AttachmentTypeEnum,
  ConfirmDialogResponseEnum,
  CustomFieldsResourceTypeEnum,
  EntityTypeEnum,
  OverlayTabEnum,
  RecordStateEnum,
  RoutingEnum,
} from "@shared/enums";
import {
  IAttachment,
  ILocationDetails,
  IOrganisation,
  IRecordState,
  ISelectOption,
} from "@shared/interfaces";
import {
  AttachmentsService,
  CommonService,
  ConnectionsService,
  OrganisationsService,
} from "@shared/services";
import { CommonUtils, CustomFieldsUtils, FormUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

@Component({
  selector: "app-organisation-overlay",
  templateUrl: "./organisation-overlay.component.html",
  styleUrl: "../organisation-overlay-common.scss",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class OrganisationOverlayComponent
  extends SlideOverlayPageClass
  implements OnInit, OnDestroy
{
  @ViewChild("locationsTable") locationsTable: LocationsTableComponent;

  @ViewChild("certificateSection") certificateSection: OverlayCertificateAttachmentsComponent;

  @ViewChild("documentSection") documentSection: OverlayDocumentAttachmentsComponent;

  @ViewChild("slideOverlayContent") override slideOverlayContent: SlideOverlayContentComponent;

  isActiveOrganisation: boolean = false;

  formGroup: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl(null, [CustomValidators.required]),
    website: new UntypedFormControl(null, [CustomValidators.url()]),
    street: new UntypedFormControl(null),
    region: new UntypedFormControl(null),
    zipCode: new UntypedFormControl(null),
    country: new UntypedFormControl(null, [CustomValidators.required]),
  });

  selectedLocationsIds: string[] = [];

  countryOptions: ISelectOption[] = [];

  public isLoadingLocations = signal(true);

  override menuItems = signal(
    new Map([
      [OverlayTabEnum.DETAILS, { isEnabled: true }],
      [OverlayTabEnum.LOCATIONS, { isEnabled: false }],
      [OverlayTabEnum.CERTIFICATES, { isEnabled: false }],
      [OverlayTabEnum.DOCUMENTS, { isEnabled: false }],
      [OverlayTabEnum.COMMENTS, { isEnabled: false, isHidden: !this.isRegularUser }],
    ]),
  );

  override element: IOrganisation;

  readonly attachmentTargetEnum = AttachmentTargetEnum;

  public readonly entityTypeEnum = EntityTypeEnum;

  public override entityType: EntityTypeEnum = EntityTypeEnum.CONNECTIONS;

  override readonly attachmentTargetType = AttachmentTargetEnum.ORGANISATION;

  private activeOrganisationName: string;

  private locationAttachments: IAttachment[] = [];

  constructor(
    public organisationOverlay: OrganisationOverlayService,
    private connectionsService: ConnectionsService,
    private organisationsService: OrganisationsService,
    private attachmentsService: AttachmentsService,
    private commonService: CommonService,
    private notificationService: NotificationService,
    private cdr: ChangeDetectorRef,
  ) {
    super();

    this.activeOrganisationName = this.authenticationService.getActiveOrganisation().name;

    this.subscriptions.add(
      this.commonService.countriesOptionsObservable$.subscribe(
        (countriesOptions: ISelectOption[]) => {
          this.countryOptions = countriesOptions;
        },
      ),
    );
  }

  get locationCount(): number {
    return this.selectedLocationsIds?.length ?? null;
  }

  override get isSubmitButtonDisabled(): boolean {
    if (!this.isEditing()) {
      return false;
    }

    return this.formGroup?.invalid || this.formGroup?.pending || !this.hasFormValuesChanged;
  }

  loadCounters(): void {
    if (this.recordId) {
      this.loadDocuments();
      this.loadCertificates();
      this.loadComments();
    }
  }

  setCountersToLoadingState() {
    this.organisationOverlay.setCountersToLoadingState();
    this.selectedLocationsIds = null;
  }

  setCountersEmptyState() {
    this.organisationOverlay.setCountersEmptyState();
  }

  loadDocuments(): void {
    this.organisationOverlay.loadDocumentCounter(this.element.id, this.attachmentTargetType);
  }

  loadCertificates(): void {
    this.organisationOverlay.loadCertificateCounter(this.element.id, this.attachmentTargetType);
  }

  loadComments(): void {
    this.organisationOverlay.loadCommentCounter(this.entityUri);
  }

  getAttachments = async (attachmentType: AttachmentTypeEnum): Promise<IAttachment[]> => {
    return await this.attachmentsService.getAll(
      attachmentType,
      this.isActiveOrganisation
        ? `/organisations/${this.element.id}`
        : `/organisations/${this.activeOrganisationId}/connections/${this.element.id}`,
      null,
    );
  };

  async ngOnInit(): Promise<void> {
    this.overlay.showLoading();
    if (!this.isOnCorrectOverlay(RoutingEnum.OVERLAY_ORGANISATION)) {
      return;
    }

    const recordId = this.recordId;

    if (recordId) {
      this.setCountersToLoadingState();
      await this.reloadElement(recordId);
      this.loadCounters();
      await this.onAfterReloadElement();
      await this.setMenuItemFromURLParam();
    } else {
      await this.setAllCustomFields(CustomFieldsResourceTypeEnum.CONNECTION);
      this.setupForm();
      this.overlay.dismissLoading();
    }
  }

  ngOnDestroy(): void {
    this.setCountersEmptyState();
    this.subscriptions.unsubscribe();
  }

  override setupForm(): void {
    let countryValue: ISelectOption;

    if (this.isEditing()) {
      countryValue = this.countryOptions.find((c) => c.value === this.element.address?.country);
    }

    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl(
        this.element?.name ?? null,
        [CustomValidators.required],
        [CustomValidators.entityAlreadyExists(this.connectionsService, this.element?.id)],
      ),
      website: new UntypedFormControl(this.element?.website ?? null, [CustomValidators.url()]),
      street: new UntypedFormControl(this.element?.address?.street ?? null),
      region: new UntypedFormControl(this.element?.address?.region ?? null),
      zipCode: new UntypedFormControl(this.element?.address?.zipCode ?? null),
      country: new UntypedFormControl(countryValue, [CustomValidators.required]),
    });

    if (!this.isActiveOrganisation) {
      this.formGroup.controls["name"].addValidators([
        CustomValidators.forbiddenValueValidator(
          this.activeOrganisationName,
          CommonConstants.SAME_ORG_NAME_ERROR_MSG,
        ),
      ]);
    }

    this.visibleCustomFields = CustomFieldsUtils.getVisible(
      this.allCustomFields,
      this.element?.customFields,
    );
    CustomFieldsUtils.addToFormGroup(
      this.formGroup,
      this.visibleCustomFields,
      this.element?.customFields,
    );

    this.initialFormValue = this.formGroup.value;
    this.hasFormValuesChanged = false;

    this.subscriptions.add(
      this.formGroup.valueChanges.subscribe(() => {
        this.hasFormValuesChanged =
          !this.formGroup.pristine && this.hasInitialFormValueChanged(this.formGroup.value);
      }),
    );
  }

  override async save(): Promise<boolean> {
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);
      this.notificationService.showError(CommonConstants.FILL_REQUIRED_FIELDS_MSG);

      return false;
    }

    const payload = this.getSavePayload();
    const service = this.isActiveOrganisation ? this.organisationsService : this.connectionsService;
    const id = this.element?.id;

    try {
      this.element = (await service.createOrUpdate(payload, id)) as IOrganisation;
      this.element.recordState = RecordStateEnum.ACTIVE;
      this.hasFormValuesChanged = false;
      this.notificationService.showSuccess(
        `Organisation ${this.isEditing() ? "modified" : "created"}`,
      );
      if (this.element.id === this.activeOrganisationId) {
        this.authenticationService.setActiveOrganisationName(payload.name);
      }

      return true;
    } catch (error) {
      this.notificationService.showError(error);

      return false;
    }
  }

  override async afterSave(isSaveOnly: boolean): Promise<void> {
    if (isSaveOnly) {
      if (!this.isEditing()) {
        await this.routerService.navigate(this.routerService.getOrganisationLink(this.element.id), {
          replaceUrl: true,
        });
      }
    }
  }

  async onAddLocationClicked(): Promise<void> {
    const result = await this.overlay.openDialog<
      {
        hasSaved: boolean;
        location?: ILocationDetails;
      },
      { countryOptions: ISelectOption[]; organisation: IOrganisation }
    >(AddLocationDialogComponent, {
      countryOptions: this.countryOptions,
      organisation: this.element,
    });

    if (result?.hasSaved) {
      this.isLoadingLocations.set(true);
      this.cdr.detectChanges();
      const newLocationId = result.location.id;

      this.selectedLocationsIds = [newLocationId, ...this.selectedLocationsIds];
      this.isLoadingLocations.set(false);
    }
  }

  protected override editConnection = (): void => {
    const dialogRef = this.dialog.open(EditOrganisationConnectionDialogComponent, {
      data: {
        connection: this.element,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: { hasSaved: boolean }) => {
      if (result?.hasSaved) {
        try {
          await this.reloadElement(this.element.id);
          this.updateTable();
        } catch (error) {
          this.notification.showError(error);
        }
      }
    });
  };

  protected override removeConnection = (): void => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: "Remove connection",
        contentTranslatedText: `Are you sure that you want to remove the connection to this organisation?`,
        confirmButtonColor: "danger",
        confirmButtonTranslatedText: "Remove",
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        try {
          this.overlay.showLoading();
          const payload: IOrganisation = {
            ...this.element,
            publicUri: null,
            recordState: undefined,
          };

          await this.connectionsService.createOrUpdate(payload, this.element.id);
          this.notificationService.showSuccess(`Connection removed`);
          await this.reloadElement(this.element.id);
          this.updateTable();
        } catch (error) {
          this.notification.showError(error);
          this.overlay.dismissLoading();
        }
      }
    });
  };

  protected override async archiveRecord(id: string, payload: IRecordState): Promise<void> {
    await this.connectionsService.setRecordState(payload, id);
  }

  protected override async deleteRecord(id: string): Promise<void> {
    await this.connectionsService.delete(id);
  }

  protected override async reloadElement(id: string): Promise<void> {
    try {
      this.overlay.showLoading();

      if (id === this.activeOrganisationId) {
        this.isActiveOrganisation = true;
        this.element = await this.organisationsService.get(this.activeOrganisationId);
        this.element.recordState = RecordStateEnum.ACTIVE;

        await this.setAllCustomFields(CustomFieldsResourceTypeEnum.ORGANISATION);
      } else {
        this.element = await this.connectionsService.get(id);

        await this.setAllCustomFields(CustomFieldsResourceTypeEnum.CONNECTION);
      }

      this.setEditMode();

      this.setupForm();
      this.overlay.dismissLoading();
    } catch (error) {
      this.notification.showError(error);
    }
  }

  private async onAfterReloadElement(): Promise<void> {
    this.locationAttachments = await this.getAttachments(AttachmentTypeEnum.LOCATION);
    this.selectedLocationsIds = await this.getSelectedLocationsIds();
    this.isLoadingLocations.set(false);
  }

  private getSelectedLocationsIds = async (): Promise<string[]> => {
    if (!this.locationAttachments?.length) {
      return [];
    }

    return this.locationAttachments.map((a) => CommonUtils.getUriId(a.attachmentUri));
  };

  private getSavePayload(): IOrganisation {
    const payload = {
      id: this.element?.id ?? undefined,
      name: this.formGroup.controls["name"].value.trim(),
      website: FormUtils.addUrlProtocol(this.formGroup.controls["website"].value),
      address: {
        street: this.formGroup.controls["street"].value,
        region: this.formGroup.controls["region"].value,
        zipCode: this.formGroup.controls["zipCode"].value,
        country: this.formGroup.controls["country"].value.value,
      },
    };

    CustomFieldsUtils.addToPayload(
      payload,
      this.activeOrganisationId,
      this.formGroup,
      this.visibleCustomFields,
    );

    return payload;
  }
}
