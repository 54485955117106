@if (!elements?.length) {
  <iov-empty-search-results></iov-empty-search-results>
} @else {
  @for (element of elements; track element.id) {
    <mat-accordion
      class="hover-visibility-container"
      [togglePosition]="canRemove ? 'before' : 'after'"
    >
      <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            @if (element.date.type === dateTypeEnum.EXACT) {
              {{ element.date.on | date: dateFormat }}
            } @else {
              {{ element.date.start | date: dateFormat }} -
              {{ element.date.end | date: dateFormat }}
            }
          </mat-panel-title>
          <div class="buttons">
            @if (canEdit) {
              <iov-button
                stopPropagation
                (pressed)="onEdit(element)"
                type="icon"
                tp="Edit"
                tpPlacement="bottom"
              >
                <mat-icon icon-medium-18>edit</mat-icon>
              </iov-button>
            }
            @if (canRemove) {
              <iov-button
                stopPropagation
                (pressed)="onRemove(element.id)"
                type="icon"
                tp="Remove"
                tpPlacement="bottom"
              >
                <mat-icon icon-medium-18>close</mat-icon>
              </iov-button>
            }
          </div>
        </mat-expansion-panel-header>

        @if (element.note) {
          <p class="note">
            <b>Note:</b>
            {{ element.note }}
          </p>
        }

        <app-process-selected-items-table
          [type]="type"
          [items]="element.items"
          [allMaterials]="allMaterials"
          [allProducts]="allProducts"
          [allUnitOfMeasurements]="allUnitOfMeasurements"
        ></app-process-selected-items-table>
      </mat-expansion-panel>
    </mat-accordion>
  }
}
