import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnInit,
  signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute, Params } from "@angular/router";

import { combineLatest, filter, tap } from "rxjs";

import { InputSelectOption } from "@design-makeover/components/inputs/input-select/input-select.model";
import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { CrossOrgShareDataTypeEnum, RoutingEnum } from "@shared/enums";
import {
  ICertificateExtended,
  IDeliveryExtended,
  IDocument,
  IDocumentType,
  IInboundMapping,
  IInboundShare,
  ILocationExtended,
  ILocationType,
  IOrganisation,
  ISelectOption,
} from "@shared/interfaces";
import {
  RecordSharingService,
  LocationTypesService,
  DocumentTypesService,
  ConnectionsService,
  DocumentsService,
  LocationsService,
  CommonService,
  DeliveriesService,
  CertificatesService,
} from "@shared/services";
import { CommonUtils } from "@shared/utils";

import { UnmappedDependenciesService } from "../../unmapped-dependencies.service";

@Component({
  templateUrl: "./transfer-or-map-data.component.html",
  styleUrl: "./transfer-or-map-data.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransferOrMapDataComponent implements OnInit {
  private recordSharingService = inject(RecordSharingService);

  private locationTypesService = inject(LocationTypesService);

  private documentTypesService = inject(DocumentTypesService);

  private documentsService = inject(DocumentsService);

  private connectionsService = inject(ConnectionsService);

  private locationsService = inject(LocationsService);

  private deliveriesService = inject(DeliveriesService);

  private certificatesService = inject(CertificatesService);

  private notification = inject(NotificationService);

  private route = inject(ActivatedRoute);

  private destroyRef = inject(DestroyRef);

  private commonService = inject(CommonService);

  private unmappedDependenciesService = inject(UnmappedDependenciesService);

  public allLocationTypes = signal<ILocationType[]>([]);

  public allDocumentTypes = signal<IDocumentType[]>([]);

  public allDocuments = signal<IDocument[]>(null);

  public allOrganisations = signal<IOrganisation[]>(null);

  public allLocations = signal<ILocationExtended[]>(null);

  public allDeliveries = signal<IDeliveryExtended[]>(null);

  public allCertificates = signal<ICertificateExtended[]>(null);

  public locationTypesOptions = signal<InputSelectOption[]>([]);

  public documentTypesOptions = signal<InputSelectOption[]>([]);

  public documentsOptions = signal<InputSelectOption[]>([]);

  public connectionsOptions = signal<InputSelectOption[]>([]);

  public locationsOptions = signal<InputSelectOption[]>([]);

  public deliveriesOptions = signal<InputSelectOption[]>([]);

  public certificatesOptions = signal<InputSelectOption[]>([]);

  public sharedLocationTypes = signal<ILocationType[]>([]);

  public sharedDocumentTypes = signal<IDocumentType[]>([]);

  public sharedDocuments = signal<IDocument[]>([]);

  public sharedLocations = signal<ILocationExtended[]>([]);

  public sharedConnections = signal<IOrganisation[]>([]);

  public sharedDeliveries = signal<IDeliveryExtended[]>([]);

  public sharedCertificates = signal<IDeliveryExtended[]>([]);

  public countryOptions = signal<ISelectOption[]>([]);

  public isLoading = signal(false);

  public readonly inboundShareDataTypeEnum = CrossOrgShareDataTypeEnum;

  public readonly routingEnum = RoutingEnum;

  private senderId: string;

  public inboundShare: IInboundShare;

  public hasUnmappedLocationDependencies = signal<boolean>(false);

  public hasUnmappedDeliveryDependencies = signal<boolean>(false);

  public hasUnmappedDocumentDependencies = signal<boolean>(false);

  public existingMappings: IInboundMapping[];

  private inboundLocationTypesIds: string[] = [];

  private inboundDocumentTypesIds: string[] = [];

  private inboundConnectionsIds: string[] = [];

  private inboundLocationsIds: string[] = [];

  private inboundCertificatesIds: string[] = [];

  async ngOnInit(): Promise<void> {
    combineLatest([
      this.route.params,
      this.commonService.countriesOptionsObservable$.pipe(filter((countries) => !!countries)),
    ])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(async ([params, countriesOptions]: [Params, ISelectOption[]]) => {
          if (params["id"]) {
            await this.loadData(params["id"]);
          }
          this.countryOptions.set(countriesOptions);
        }),
      )
      .subscribe();
  }

  async loadData(id: string) {
    this.isLoading.set(true);
    try {
      this.existingMappings = await this.recordSharingService.getAllMappings();
      await this.reloadOrganisations();

      this.inboundShare = await this.recordSharingService.getInboundShare(id);
      this.senderId = CommonUtils.getUriId(this.inboundShare.senderUri);
      const allUris = [...this.inboundShare.recordUris, this.inboundShare.rootRecordUri];

      this.inboundLocationTypesIds = allUris
        .filter((uri) => uri.includes(CrossOrgShareDataTypeEnum.LOCATION_TYPES))
        .map((uri) => CommonUtils.getUriId(uri));

      this.inboundDocumentTypesIds = allUris
        .filter((uri) => uri.includes(CrossOrgShareDataTypeEnum.DOCUMENT_TYPES))
        .map((uri) => CommonUtils.getUriId(uri));

      const inboundDocuments = allUris
        .filter((uri) => uri.includes(CrossOrgShareDataTypeEnum.DOCUMENTS))
        .map((uri) => CommonUtils.getUriId(uri));

      this.inboundConnectionsIds = allUris
        .filter((uri) => uri.includes(CrossOrgShareDataTypeEnum.CONNECTIONS))
        .map((uri) => CommonUtils.getUriId(uri));

      const inboundDeliveries = allUris
        .filter((uri) => uri.includes(CrossOrgShareDataTypeEnum.DELIVERIES))
        .map((uri) => CommonUtils.getUriId(uri));

      this.inboundCertificatesIds = allUris
        .filter((uri) => uri.includes(CrossOrgShareDataTypeEnum.CERTIFICATES))
        .map((uri) => CommonUtils.getUriId(uri));

      this.inboundLocationsIds = allUris
        .filter((uri) => uri.includes(CrossOrgShareDataTypeEnum.LOCATIONS))
        .map((uri) => CommonUtils.getUriId(uri));

      this.checkUnmappedDependencies();

      if (this.inboundLocationTypesIds?.length) {
        await this.reloadLocationTypes();
        await this.fetchSharedLocationTypes(this.inboundLocationTypesIds);
      }
      if (this.inboundDocumentTypesIds?.length) {
        await this.reloadDocumentTypes();
        await this.fetchSharedDocumentTypes(this.inboundDocumentTypesIds);
      }
      if (this.inboundConnectionsIds?.length) {
        await this.fetchSharedConnections(this.inboundConnectionsIds);
      }
      if (inboundDocuments?.length) {
        await this.reloadDocuments();
        await this.fetchSharedDocuments(inboundDocuments);
      }
      if (this.inboundLocationsIds?.length) {
        await this.reloadLocations();
        await this.fetchSharedLocations(this.inboundLocationsIds);
      }
      if (inboundDeliveries?.length) {
        await this.reloadDeliveries();
        await this.fetchSharedDeliveries(inboundDeliveries);
      }
      if (this.inboundCertificatesIds?.length) {
        await this.reloadCertificates();
        await this.fetchSharedCertificates(this.inboundCertificatesIds);
      }
    } catch (error) {
      this.notification.showError(error);
    } finally {
      this.isLoading.set(false);
    }
  }

  private async fetchSharedLocationTypes(typesIds: string[]): Promise<void> {
    await this.fetchSharedData(
      typesIds,
      CrossOrgShareDataTypeEnum.LOCATION_TYPES,
      this.sharedLocationTypes(),
    );
  }

  private async fetchSharedDocumentTypes(typesIds: string[]): Promise<void> {
    await this.fetchSharedData(
      typesIds,
      CrossOrgShareDataTypeEnum.DOCUMENT_TYPES,
      this.sharedDocumentTypes(),
    );
  }

  private async fetchSharedDocuments(documentsIds: string[]): Promise<void> {
    await this.fetchSharedData(
      documentsIds,
      CrossOrgShareDataTypeEnum.DOCUMENTS,
      this.sharedDocuments(),
    );
  }

  private async fetchSharedConnections(connectionsIds: string[]): Promise<void> {
    await this.fetchSharedData(
      connectionsIds,
      CrossOrgShareDataTypeEnum.CONNECTIONS,
      this.sharedConnections(),
    );
  }

  private async fetchSharedLocations(locationsIds: string[]): Promise<void> {
    await this.fetchSharedData(
      locationsIds,
      CrossOrgShareDataTypeEnum.LOCATIONS,
      this.sharedLocations(),
    );
  }

  private async fetchSharedDeliveries(deliveriesIds: string[]): Promise<void> {
    await this.fetchSharedData(
      deliveriesIds,
      CrossOrgShareDataTypeEnum.DELIVERIES,
      this.sharedDeliveries(),
    );
  }

  private async fetchSharedCertificates(certificatesIds: string[]): Promise<void> {
    await this.fetchSharedData(
      certificatesIds,
      CrossOrgShareDataTypeEnum.CERTIFICATES,
      this.sharedCertificates(),
    );
  }

  private async fetchSharedData(
    sharedRecordsIds: string[],
    dataType: CrossOrgShareDataTypeEnum,
    targetArray: any[],
  ): Promise<void> {
    try {
      const promises = sharedRecordsIds.map(async (recordId) => {
        const id = CommonUtils.getUriId(recordId);
        const record = await this.recordSharingService.getSharedRecord(this.senderId, dataType, id);

        targetArray.push(record);
      });

      await Promise.all(promises);
    } catch (error) {
      this.notification.showError(error);
    }
  }

  public async reloadDocumentTypes(): Promise<void> {
    try {
      const documentTypes = await this.documentTypesService.getAll();

      this.allDocumentTypes.set(documentTypes);

      this.documentTypesOptions.set(
        this.allDocumentTypes().map((l) => ({
          label: l.name,
          value: l.id,
        })),
      );
    } catch (error) {
      this.notification.showError(error);
    }
  }

  public async reloadLocationTypes(): Promise<void> {
    try {
      const locationTypes = await this.locationTypesService.getAll();

      this.allLocationTypes.set(locationTypes);
      this.locationTypesOptions.set(
        this.allLocationTypes().map((l) => ({
          label: l.type,
          value: l.id,
        })),
      );
    } catch (error) {
      this.notification.showError(error);
    }
  }

  public async reloadDocuments(): Promise<void> {
    try {
      const allDocuments = await this.documentsService.getAll();

      this.allDocuments.set(allDocuments);
      this.documentsOptions.set(
        this.allDocuments().map((d) => ({
          label: d.name,
          value: d.id,
        })),
      );
    } catch (error) {
      this.notification.showError(error);
    }
  }

  public async reloadOrganisations(): Promise<void> {
    try {
      const allOrganisations = await this.connectionsService.getAll();

      this.allOrganisations.set(allOrganisations);
      this.connectionsOptions.set(
        this.allOrganisations().map((c) => ({
          label: c.name,
          value: c.id,
        })),
      );
    } catch (error) {
      this.notification.showError(error);
    }
  }

  public async reloadLocations(): Promise<void> {
    try {
      const allLocations = await this.locationsService.getAllGraphQL();

      this.allLocations.set(allLocations);
      this.locationsOptions.set(
        this.allLocations().map((c) => ({
          label: c.name,
          value: c.id,
        })),
      );
    } catch (error) {
      this.notification.showError(error);
    }
  }

  public async reloadDeliveries(): Promise<void> {
    try {
      const allDeliveries = await this.deliveriesService.getAllGraphQL();

      this.allDeliveries.set(allDeliveries);
      this.deliveriesOptions.set(
        this.allDeliveries().map((d) => ({
          label: d.deliveryId,
          value: d.id,
        })),
      );
    } catch (error) {
      this.notification.showError(error);
    }
  }

  public async reloadCertificates(): Promise<void> {
    try {
      const allCertificates = await this.certificatesService.getAllGraphQL();

      this.allCertificates.set(allCertificates);
      this.certificatesOptions.set(
        this.allCertificates().map((c) => ({
          label: c.number,
          value: c.id,
        })),
      );
    } catch (error) {
      this.notification.showError(error);
    }
  }

  public async checkUnmappedDependencies(): Promise<void> {
    try {
      this.existingMappings = await this.recordSharingService.getAllMappings();

      const idsToCheckForLocations = [
        ...this.inboundLocationTypesIds,
        ...this.inboundConnectionsIds,
      ];
      const idsToCheckForDeliveries = [...this.inboundConnectionsIds, ...this.inboundLocationsIds];

      const hasUnmappedLocationDependencies =
        this.unmappedDependenciesService.hasUnmappedDependencies(
          idsToCheckForLocations,
          this.existingMappings,
          "inboundUri",
        );

      const hasUnmappedDeliveryDependencies =
        this.unmappedDependenciesService.hasUnmappedDependencies(
          idsToCheckForDeliveries,
          this.existingMappings,
          "inboundUri",
        );

      const hasUnmappedDocumentDependencies =
        this.unmappedDependenciesService.hasUnmappedDependencies(
          this.inboundDocumentTypesIds,
          this.existingMappings,
          "inboundUri",
        );

      this.hasUnmappedLocationDependencies.set(hasUnmappedLocationDependencies);
      this.hasUnmappedDeliveryDependencies.set(hasUnmappedDeliveryDependencies);
      this.hasUnmappedDocumentDependencies.set(hasUnmappedDocumentDependencies);
    } catch (error) {
      this.notification.showError(error);
    }
  }
}
