import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  Optional,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from "@angular/material/core";
import {
  MatCalendar,
  MatCalendarView,
  MatDatepickerIntl,
  yearsPerPage,
} from "@angular/material/datepicker";
import { MatIconModule } from "@angular/material/icon";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { DatepickerService } from "@design-makeover/components/datepicker/datepicker.service";

import { CalendarViewEnum, Mode, ModeEnum } from "./datepicker-header.model";

@Component({
  selector: "iov-datepicker-header",
  styleUrls: ["datepicker-header.component.scss"],
  templateUrl: "./datepicker-header.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatButtonModule, MatIconModule],
})
export class DatepickerHeaderComponent<D> implements OnDestroy {
  private destroyed = new Subject<void>();

  readonly modeEnum: typeof ModeEnum = ModeEnum;

  readonly calendarViewEnum: typeof CalendarViewEnum = CalendarViewEnum;

  constructor(
    public calendar: MatCalendar<D>,
    private dateAdapter: DateAdapter<D>,
    private intl: MatDatepickerIntl,
    private datepickerService: DatepickerService<D>,
    cdr: ChangeDetectorRef,
    @Optional() @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats,
  ) {
    calendar.stateChanges.pipe(takeUntil(this.destroyed)).subscribe(() => cdr.markForCheck());
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  onClickYear(): void {
    this.calendar.currentView = CalendarViewEnum.MULTIYEAR;
  }

  onClickMonth(): void {
    this.calendar.currentView = CalendarViewEnum.YEAR;
  }

  get monthLabel(): string {
    return this.dateAdapter.format(this.calendar.activeDate, "MMMM");
  }

  get yearLabel(): string {
    return this.dateAdapter.format(this.calendar.activeDate, "YYYY");
  }

  previousArrowDefaultClicked(mode: Mode): void {
    this.calendar.activeDate =
      mode === ModeEnum.MONTH
        ? this.dateAdapter.addCalendarMonths(this.calendar.activeDate, -1)
        : this.dateAdapter.addCalendarYears(this.calendar.activeDate, -1);
  }

  nextArrowDefaultClicked(mode: Mode): void {
    this.calendar.activeDate =
      mode === ModeEnum.MONTH
        ? this.dateAdapter.addCalendarMonths(this.calendar.activeDate, 1)
        : this.dateAdapter.addCalendarYears(this.calendar.activeDate, 1);
  }

  previousArrowClicked(calendarView: MatCalendarView): void {
    if (calendarView === CalendarViewEnum.YEAR) {
      this.calendar.activeDate = this.dateAdapter.addCalendarYears(this.calendar.activeDate, -1);
    } else if (calendarView === CalendarViewEnum.MULTIYEAR) {
      this.calendar.activeDate = this.dateAdapter.addCalendarYears(
        this.calendar.activeDate,
        -yearsPerPage,
      );
    }
  }

  nextArrowClicked(calendarView: MatCalendarView): void {
    if (calendarView === CalendarViewEnum.YEAR) {
      this.calendar.activeDate = this.dateAdapter.addCalendarYears(this.calendar.activeDate, 1);
    } else if (calendarView === CalendarViewEnum.MULTIYEAR) {
      this.calendar.activeDate = this.dateAdapter.addCalendarYears(
        this.calendar.activeDate,
        yearsPerPage,
      );
    }
  }

  get periodButtonText(): string {
    if (this.calendar.currentView === CalendarViewEnum.MONTH) {
      return this.dateAdapter
        .format(this.calendar.activeDate, this.dateFormats.display.monthYearLabel)
        .toLocaleUpperCase();
    }
    if (this.calendar.currentView === CalendarViewEnum.YEAR) {
      return this.dateAdapter.getYearName(this.calendar.activeDate);
    }

    return this.intl.formatYearRange(
      ...this.datepickerService.formatMinAndMaxYearLabels(this.calendar),
    );
  }
}
