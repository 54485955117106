<mat-accordion>
  <mat-expansion-panel class="mat-elevation-z0 section-box" [expanded]="false">
    <mat-expansion-panel-header class="section-header">
      <mat-panel-title class="section-title">
        <div class="location-country-group">
          <app-flag [country]="deliveriesByCountry().value[0].from.address.country"></app-flag>
          <span class="country-name">
            {{ countryName }} ({{ deliveriesByCountry().value.length }})
          </span>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    @for (delivery of deliveriesByCountry().value; track delivery.id) {
      <app-delivery-report-other-deliveries-delivery-details
        [delivery]="delivery"
      ></app-delivery-report-other-deliveries-delivery-details>
    }
  </mat-expansion-panel>
</mat-accordion>
