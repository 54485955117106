import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  templateUrl: "./accept-invitation-dialog.component.html",
  styleUrls: ["./accept-invitation-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcceptInvitationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<AcceptInvitationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      organisationName: string;
    },
  ) {}

  public onClose = (hasAccepted: boolean = null): void => {
    this.dialogRef.close({ hasAccepted });
  };
}
