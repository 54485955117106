import { Inject, inject, Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { getMaterialsByIdsGraphQLQuery, getMaterialsGraphQLQuery } from "@shared/queries";
import { APP_CONFIG } from "@shared/tokens";

import { ApiService } from "./api.service";
import { GraphService } from "./graph.service";
import { CommonConstants } from "../../constants";
import { RecordStateEnum } from "../../enums";
import {
  IMaterial,
  IMaterialPayload,
  IPageableContent,
  IRecordState,
  IConfig,
  IMaterialGraphQLFilter,
  IMaterialGraphQLResponse,
  IMaterialExtended,
  IMaterialByIdsGraphQLResponse,
} from "../../interfaces";
import { FormUtils } from "../../utils";
import { AuthenticationService } from "../authentication.service";

@Injectable({
  providedIn: "root",
})
export class MaterialsService {
  private graphService = inject(GraphService);

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {}

  private getBaseUrl = (): string =>
    `${this.environment.baseUrl}organisations/${this.authenticationService.getActiveOrganisationId()}/materials`;

  public getPage = async (
    name: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
    recordState: RecordStateEnum = undefined,
  ): Promise<IPageableContent<IMaterial>> => {
    const url = `${this.getBaseUrl()}?${FormUtils.addUrlParams({ name, size, page, sort, recordState })}`;

    return await this.apiService.get<IPageableContent<IMaterial>>(url).toPromise();
  };

  public getPageSubscription = (
    name: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
    recordState: RecordStateEnum = undefined,
  ): Observable<IPageableContent<IMaterial>> => {
    const url = `${this.getBaseUrl()}?${FormUtils.addUrlParams({ name, size, page, sort, recordState })}`;

    return this.apiService.get<IPageableContent<IMaterial>>(url);
  };

  public async getAll(recordState: RecordStateEnum = undefined): Promise<IMaterial[]> {
    const result: IMaterial[] = [];
    let page = 0;
    let hasNextPage: boolean = true;

    while (hasNextPage) {
      const {
        content,
        page: { totalElements },
      } = await this.getPage(
        undefined,
        CommonConstants.MAX_API_GET_ITEMS_SIZE,
        page,
        undefined,
        recordState,
      );

      result.push(...content);

      page++;
      hasNextPage = result.length < totalElements;
    }

    return result;
  }

  public async getAllGraphQL(
    filter: IMaterialGraphQLFilter = null,
    first: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    include: string[] = [],
  ): Promise<IMaterialExtended[]> {
    const result: IMaterialExtended[] = [];
    let cursor: string | undefined = undefined;
    let hasNextPage: boolean = true;
    const activeOrganisationId = this.authenticationService.getActiveOrganisationId();

    while (hasNextPage) {
      const query = getMaterialsGraphQLQuery(
        activeOrganisationId,
        filter,
        first,
        null,
        cursor,
        null,
        include,
      );
      const { content, pageInfo, totalCount } = await this.graphService.fetchSinglePage<
        IMaterialGraphQLResponse,
        IMaterialExtended
      >("loadMaterials", query);

      result.push(...content);

      cursor = pageInfo.endCursor;
      hasNextPage = result.length < totalCount;
    }

    return result;
  }

  public async getByIdsGraphQL(
    ids: string[],
    first: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    include: string[] = [],
  ): Promise<IMaterialExtended[]> {
    if (!ids.length) {
      return [];
    }
    const result: IMaterialExtended[] = [];
    let cursor: string | undefined = undefined;
    let hasNextPage: boolean = true;
    const activeOrganisationId = this.authenticationService.getActiveOrganisationId();

    while (hasNextPage) {
      const query = getMaterialsByIdsGraphQLQuery(
        activeOrganisationId,
        ids,
        first,
        null,
        cursor,
        null,
        include,
      );
      const { content, pageInfo, totalCount } = await this.graphService.fetchSinglePage<
        IMaterialByIdsGraphQLResponse,
        IMaterialExtended
      >("loadMaterialsByIds", query);

      result.push(...content);

      cursor = pageInfo.endCursor;
      hasNextPage = result.length < totalCount;
    }

    return result;
  }

  public get = async (id: string): Promise<IMaterial> =>
    await this.apiService.get<IMaterial>(`${this.getBaseUrl()}/${id}`).toPromise();

  public getAllCategories = async (): Promise<string[]> => {
    return await this.apiService
      .get<
        string[]
      >(`${this.environment.baseUrl}organisations/${this.authenticationService.getActiveOrganisationId()}/material-categories`)
      .toPromise();
  };

  public delete = async (id: string): Promise<void> =>
    await this.apiService.delete<void>(`${this.getBaseUrl()}/${id}`).toPromise();

  public createOrUpdate = async (payload: IMaterialPayload, id?: string): Promise<IMaterial> => {
    if (id) {
      return await this.apiService
        .put<IMaterial>(`${this.getBaseUrl()}/${id}`, payload)
        .toPromise();
    } else {
      return await this.apiService.post<IMaterial>(`${this.getBaseUrl()}`, payload).toPromise();
    }
  };

  public setRecordState = async (payload: IRecordState, id: string): Promise<IMaterial> =>
    await this.apiService.put<IMaterial>(`${this.getBaseUrl()}/${id}`, payload).toPromise();
}
