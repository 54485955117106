import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  OnDestroy,
  signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

import { ColDef } from "ag-grid-community";
import {
  LinkCellRendererComponent,
  RulesetIconNameCellRendererComponent,
} from "src/app/shared/cell-renderers";
import { RecordStateEnum, ResourceTypeEnum, RoutingEnum, TableEnum } from "src/app/shared/enums";
import { ILocation, ILocationExtended, ISelectOption } from "src/app/shared/interfaces";
import { CommonService, LocationsService } from "src/app/shared/services";
import { CellRendererUtils, ColumnUtils, CommonUtils } from "src/app/shared/utils";

import { SlideOverlayPageService } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.service";

import { TableWithRulesetsClass } from "@shared/classes/table-with-rulesets.class";
import { CommonConstants } from "@shared/constants";
import { RouterService } from "@shared/services/router.service";

@Component({
  selector: "app-locations-table",
  templateUrl: "./locations-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationsTableComponent
  extends TableWithRulesetsClass<ILocationExtended>
  implements AfterViewInit, OnDestroy
{
  locations = input<ILocation[] | ILocationExtended[]>(null);

  locationsIds = input<string[]>(null);

  areButtonsEnabled = input<boolean>(true);

  isSearchEnabled = input<boolean>(true);

  isRecordStateFilterEnabled = input<boolean>(true);

  isPaginatorEnabled = input<boolean>(true);

  isFixedBottomPaginator = input<boolean>(false);

  recordState = input<RecordStateEnum>(RecordStateEnum.ALL);

  table = input<TableEnum>(TableEnum.LOCATIONS);

  subscribeToOverlayRefreshTable = input<boolean>(true);

  isSaveTableState = input<boolean>(false);

  columns = input<string[]>([
    "recordState",
    "name",
    "organisationName",
    "types",
    "address.countryName",
    "tags",
  ]);

  isLoading = signal<boolean>(true);

  columnDefs = signal<ColDef[]>([]);

  override shouldGetRulesets = computed(
    () => this.columns().includes("nameWithRulesets") && this.isRegularUser,
  );

  override resourceType = ResourceTypeEnum.LOCATION;

  private countryOptions = signal<ISelectOption[]>([]);

  private readonly isSystemAdminOrganisation =
    this.authenticationService.isSystemAdminOrganisation();

  private readonly isRegularUser = this.authenticationService.isRegularUser();

  constructor(
    private routerService: RouterService,
    private locationsService: LocationsService,
    private commonService: CommonService,
    private overlay: SlideOverlayPageService,
  ) {
    super();
    this.subscriptions.add(
      this.commonService.countriesOptionsObservable$
        .pipe(takeUntilDestroyed())
        .subscribe((countriesOptions: ISelectOption[]) =>
          this.countryOptions.set(countriesOptions),
        ),
    );
  }

  public async ngAfterViewInit(): Promise<void> {
    if (this.subscribeToOverlayRefreshTable()) {
      this.subscriptions.add(
        this.overlay.refreshTable$.subscribe((newLocation: ILocation) => {
          if ("geoLocation" in newLocation) {
            this.newRecord = newLocation;
          }
          this.getAll(true);
        }),
      );
    }

    this.setColumnDefs();
    await this.getAll();
  }

  public onViewDetails = async (row: ILocationExtended): Promise<void> => {
    if (!this.areButtonsEnabled()) {
      return;
    }
    if (this.isSystemAdminOrganisation) {
      this.routerService.navigate([`${RoutingEnum.ADMIN_LOCATIONS_DETAILS}/${row.id}`]);
    } else {
      this.routerService.navigate(this.routerService.getLocationLink(row.id));
    }
  };

  public getAll = async (force?: boolean): Promise<void> => {
    this.isLoading.set(true);

    let locations = this.locations();
    const locationsIds = this.locationsIds();
    const countryOptions = this.countryOptions();
    const columnDefs = this.columnDefs();

    const tagsFieldPresent = columnDefs.some((c) => c.field === "tags");
    const geolocationFieldPresent = columnDefs.some((c) => c.field === "geoLocation");
    const fieldsToInclude = [];

    if (tagsFieldPresent) {
      fieldsToInclude.push("TAGS");
    }
    if (geolocationFieldPresent) {
      fieldsToInclude.push("GEOLOCATION");
    }

    if (locations && !force) {
      this.rowData.set(CommonUtils.getElementsWithCountryName(countryOptions, locations));
      this.isLoading.set(false);
    } else if (locationsIds) {
      try {
        locations = await this.locationsService.getByIdsGraphQL(
          locationsIds,
          undefined,
          fieldsToInclude,
        );

        this.rowData.set(CommonUtils.getElementsWithCountryName(countryOptions, locations));
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    } else {
      try {
        const rowData = await this.locationsService.getAllGraphQL(
          undefined,
          undefined,
          fieldsToInclude,
        );

        this.rowData.set(CommonUtils.getElementsWithCountryName(countryOptions, rowData));
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setColumnDefs(): void {
    const columnDefs: ColDef[] = [
      ColumnUtils.recordState(),
      {
        colId: "name",
        headerName: "Name",
        field: "name",
        lockVisible: true,
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRoute: this.isSystemAdminOrganisation
            ? RoutingEnum.ADMIN_LOCATIONS_DETAILS
            : undefined,
          linkRouteIdParam: "id",
          linkRouteFn: this.isSystemAdminOrganisation
            ? undefined
            : this.routerService.getLocationLink,
        },
        suppressSizeToFit: true,
      },
      {
        colId: "nameWithRulesets",
        headerName: "Name",
        field: "name",
        lockVisible: true,
        cellClass: "container-flex-left",
        cellRenderer: RulesetIconNameCellRendererComponent,
        cellRendererParams: {
          rulesetRecordsSubject: this.rulesetRecordsSubject,
          iconTooltip: this.getRulesetIconTooltip(),
          linkRouteIdParam: "id",
          linkRouteFn: this.routerService.getLocationLink,
        },
        suppressSizeToFit: true,
      },
      {
        headerName: "Organisation",
        field: "organisationName",
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteIdParam: "organisationId",
          linkRouteFn: this.routerService.getOrganisationLink,
        },
      },
      ColumnUtils.chips("Type(s)", "types", {
        textParam: "type",
        showIcon: (locationType) => locationType.pointOfOrigin,
        icon: "target",
        iconTooltip: CommonConstants.POINT_OF_ORIGIN_CHIP_TEXT,
      }),
      {
        headerName: "Certificates",
        field: "certificates",
        valueGetter: (cell: { data: ILocationExtended }) => {
          return cell.data.certificates.map((certificate) => {
            const values: string[] = [
              certificate.standard.name,
              certificate.standardType?.fullName,
              certificate.number,
            ];

            const name = values.filter((value) => !!value).join(" - ");

            return { name, id: certificate.id };
          });
        },
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteIdParam: "id",
          linkRouteFn: this.routerService.getCertificateLink,
          textParam: "name",
        },
      },
      {
        headerName: "Country",
        field: "address.countryName",
        cellRenderer: CellRendererUtils.country,
      },
      {
        headerName: "ZIP / Post code",
        field: "address.zipCode",
      },
      {
        headerName: "Region / State",
        field: "address.region",
      },
      { headerName: "Link type", field: "linkType" },
      {
        headerName: "Geolocation",
        field: "geoLocation",
        cellRenderer: CellRendererUtils.showIconIfValue,
        valueGetter: (cell: any) => !!cell.data?.geoLocation,
      },
    ];

    if (this.isRegularUser) {
      columnDefs.push(ColumnUtils.tags("Tags"));
    }
    this.columnDefs.set(CommonUtils.getVisibleColumnDefs(columnDefs, this.columns()));
  }
}
