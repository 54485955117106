/**
 * Retrieves inbound locations by their IDs using a GraphQL query.
 *
 * @param orgId - The organization ID.
 * @param senderId - The sender ID.
 * @param ids - An array of location IDs.
 * @param first - The maximum number of locations to retrieve from the start.
 * @param last - The maximum number of locations to retrieve from the end.
 * @param after - The cursor to start retrieving locations after.
 * @param before - The cursor to start retrieving locations before.
 * @param include - An array of additional fields to include in the query response.
 * @returns The GraphQL query string.
 */
import { QueriesUtils } from "./queries.utils";

export const getInboundLocationsByIdsGraphQLQuery = (
  orgId: string,
  senderId: string,
  ids: string[],
  first: number,
  last: number,
  after: string,
  before: string,
  include: string[],
): string => {
  const afterCursorClause = first > 0 ? QueriesUtils.generateClause("after", after) : "";
  const firstClause = first > 0 ? QueriesUtils.generateClause("first", first) : "";
  const beforeCursorClause = last > 0 ? QueriesUtils.generateClause("before", before) : "";
  const lastClause = last > 0 ? QueriesUtils.generateClause("last", last) : "";

  return `
      {
        inboundLoadLocationsByIds(   
          orgId: "${orgId}",
          senderId: "${senderId}",
          ids: ${QueriesUtils.getParsedIds(ids)},
          ${afterCursorClause ? `${afterCursorClause},` : ""}
          ${beforeCursorClause ? `${beforeCursorClause},` : ""}
          ${firstClause ? `${firstClause},` : ""}
          ${lastClause ? `${lastClause},` : ""}
        ) {
          pageInfo {
            hasNextPage 
            hasPreviousPage
            startCursor
            endCursor
          }
          totalCount
          edges {
            cursor
            node {
              id
              name
              recordState
              types {
                type
                pointOfOrigin
              }
              address {
                country
              }
              connections {
                id
                name
              }
              ${QueriesUtils.getQueryIncludes(include)}
            }            
          }       
        }
      }
    `;
};
