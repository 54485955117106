<div class="main-container">
  <div class="upload-box hover-container">
    <div class="content">
      <iov-button class="upload-button" type="ghost" (pressed)="fileInput.click()">
        <mat-icon icon-left svgIcon="upload"></mat-icon>
        Upload
      </iov-button>
      {{ "or drag and drop file(s) here." }}
    </div>

    <input
      #fileInput
      [multiple]="multiple"
      class="file-upload-input"
      type="file"
      title=""
      id="files"
      (change)="onFilesSelected($event)"
    />
  </div>

  <div class="footer">
    <div class="footer-left">{{ footerLeftText }}</div>
    <div class="footer-right">{{ footerRightText }}</div>
  </div>
</div>
