<form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
  <h2 mat-dialog-title>Add new product</h2>
  @if (isLoading()) {
    <app-loader containerClass="on-dialog"></app-loader>
  } @else {
    <mat-dialog-content>
      <iov-input [autofocus]="true" formControlName="name" label="Name"></iov-input>

      <iov-input-select
        label="Main measurement type"
        formControlName="baseUnit"
        [options]="unitOfMeasurementTypesOptions"
      ></iov-input-select>
    </mat-dialog-content>

    <mat-dialog-actions>
      <iov-button-group>
        <iov-button (pressed)="onClose()" type="ghost">Cancel</iov-button>
        <iov-button accessibilityRole="submit">
          <mat-icon icon-left>check</mat-icon>
          Add
        </iov-button>
      </iov-button-group>
    </mat-dialog-actions>
  }
</form>
