<div class="container-space-between">
  <app-page-title [goBackRoute]="routing.ADMIN" title="Risk level sets" backText="Back to Admin" />
  @if (canAddModifyEntities) {
    <iov-button-group>
      <iov-button (pressed)="onEdit()">
        <mat-icon icon-left>add</mat-icon>
        Add new
      </iov-button>
    </iov-button-group>
  }
</div>

<app-risk-level-sets-table
  #riskLevelSetsTable
  [isSaveTableState]="true"
  [isFixedBottomPaginator]="true"
  [recordState]="recordState.ACTIVE"
  [areButtonsEnabled]="canAddModifyEntities"
  (edit)="onEdit($event)"
/>
