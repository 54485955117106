import { ChangeDetectionStrategy, Component, Input, booleanAttribute } from "@angular/core";

import { CommonConstants } from "src/app/shared/constants";
import { IDeliveryExtended } from "src/app/shared/interfaces";

@Component({
  selector: "iov-delivery-card-content",
  templateUrl: "./delivery-card-content.component.html",
  styleUrl: "./delivery-card-content.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryCardContentComponent {
  @Input() delivery: IDeliveryExtended;

  @Input({ transform: booleanAttribute }) shouldOpenInNewTab: boolean = false;

  @Input({ transform: booleanAttribute }) showWarning: boolean;

  readonly dateFormat = CommonConstants.DATE_FORMAT;
}
