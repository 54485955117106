import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, signal } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";

import { Subscription } from "rxjs";
import { IOrganisation, IOrganisationPayload, ISelectOption } from "src/app/shared/interfaces";
import {
  AuthenticationService,
  CommonService,
  OrganisationsService,
} from "src/app/shared/services";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { CommonConstants } from "@shared/constants";
import { FormUtils } from "@shared/utils";

import { CustomValidators } from "../../../shared/validators";

@Component({
  templateUrl: "./add-organisation-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddOrganisationDialogComponent implements OnInit, OnDestroy {
  public formGroup: UntypedFormGroup = null;

  public isLoading = signal(true);

  public countryOptions: ISelectOption[] = [];

  private subscriptions = new Subscription();

  public activeOrganisationId: string;

  public readonly mainInformationText = CommonConstants.MAIN_INFORMATION_TEXT;

  constructor(
    public dialogRef: MatDialogRef<AddOrganisationDialogComponent>,
    private organisationsService: OrganisationsService,
    private notificationService: NotificationService,
    private commonService: CommonService,
    private authenticationService: AuthenticationService,
  ) {
    this.subscriptions.add(
      this.commonService.countriesOptionsObservable$.subscribe(
        (countriesOptions: ISelectOption[]) => {
          this.countryOptions = countriesOptions;
        },
      ),
    );
    this.activeOrganisationId = this.authenticationService.getActiveOrganisationId();
  }

  public async ngOnInit(): Promise<void> {
    this.setupForm();
    this.isLoading.set(false);
  }

  public get isSpecifyAddress(): boolean {
    return this.formGroup?.get("specifyAddress")?.value;
  }

  public onSubmit = async (): Promise<void> => {
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);
      this.notificationService.showError(CommonConstants.FILL_REQUIRED_FIELDS_MSG);

      return;
    }
    this.isLoading.set(true);

    const payload: IOrganisationPayload = {
      name: this.formGroup.controls["name"].value.trim(),
      address: {
        street: this.isSpecifyAddress ? this.formGroup.controls["street"].value : undefined,
        region: this.isSpecifyAddress ? this.formGroup.controls["region"].value : undefined,
        zipCode: this.isSpecifyAddress ? this.formGroup.controls["zipCode"].value : undefined,
        country: this.formGroup.controls["country"].value.value,
      },
      roles: ["PARTICIPANT"],
    };

    await this.organisationsService
      .createOrUpdate(payload)
      .then((response: IOrganisation) => {
        this.notificationService.showSuccess(`Organisation created`);
        this.onClose(response);
      })
      .catch((error: HttpErrorResponse) => {
        this.notificationService.showError(error);
      })
      .finally(() => {
        this.isLoading.set(false);
      });
  };

  public onClose = (savedOrganisation: IOrganisation = null): void => {
    this.dialogRef.close({ savedOrganisation });
  };

  private setupForm = (): void => {
    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl(null, [CustomValidators.required]),
      street: new UntypedFormControl(null),
      region: new UntypedFormControl(null),
      zipCode: new UntypedFormControl(null),
      country: new UntypedFormControl(null, [CustomValidators.required]),
      specifyAddress: new UntypedFormControl(false),
    });
  };

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
