import { ChangeDetectionStrategy, Component } from "@angular/core";

import { AuthenticationService } from "src/app/shared/services";

@Component({
  selector: "app-not-shared-data",
  templateUrl: "./not-shared-data.component.html",
  styleUrls: ["./not-shared-data.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotSharedDataComponent {
  public readonly isVisible = this.authenticationService.isSharedUser();

  constructor(private authenticationService: AuthenticationService) {}
}
