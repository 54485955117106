import { Injectable } from "@angular/core";

import { ToastrService } from "ngx-toastr";

import {
  NotificationFailureComponent,
  NotificationSuccessComponent,
} from "@design-makeover/components/notification";
import { NotificationError } from "@design-makeover/services/notification/notification.model";

import { CommonConstants } from "@shared/constants";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(private notification: ToastrService) {}

  public showSuccess(message: string): void {
    this.notification.success(message, undefined, {
      toastComponent: NotificationSuccessComponent,
      timeOut: CommonConstants.NOTIFICATION_DURATION_MS,
    });
  }

  public showError(error: NotificationError | string, isFetchingEntity: boolean = false): void {
    const message = this.getFormattedErrorMessage(error, isFetchingEntity);

    this.notification.error(message, undefined, {
      toastComponent: NotificationFailureComponent,
      timeOut: CommonConstants.NOTIFICATION_ERROR_DURATION_MS,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private isRecordNotFoundError = (error: any): boolean =>
    error.status === 404 ||
    (error.status === 400 &&
      error?.error &&
      error.error.detail?.startsWith("Failed to convert") &&
      error.error.instance &&
      error.error.type === "about:blank");

  private getFormattedErrorMessage(
    error: NotificationError | string,
    isFetchingEntity: boolean = false,
  ): string {
    let message: string = `An unexpected error happened. Please try again later`;

    if (typeof error === "string") {
      message = error;
    } else {
      if (isFetchingEntity && this.isRecordNotFoundError(error)) {
        message = "Record not found";
      } else {
        switch (error?.status) {
          case 0:
          case 503:
            message = "Service is unavailable. Please try again later";
            break;
          case 403:
            message = "Your user profile has no permissions for this";
            break;
          default: {
            const errorMessages = error?.error?.errors;

            message =
              errorMessages?.length && errorMessages[0].message
                ? errorMessages[0].message
                : message;
            break;
          }
        }
      }
    }

    if (message === "Invalid JWT") {
      message = CommonConstants.SESSION_HAS_EXPIRED_TEXT;
    }

    return message;
  }
}
