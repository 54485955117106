import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";

import { AuthUserTypeEnum, ConfirmDialogResponseEnum, RoutingEnum } from "src/app/shared/enums";
import { IUserData } from "src/app/shared/interfaces";
import { AuthenticationService, DownloadDocumentsService } from "src/app/shared/services";

import { ConfirmDialogComponent } from "@components/shared";
import { UserSettingsLanguageDialogComponent } from "@components/user-settings";

@Component({
  selector: "iov-user-account-button",
  templateUrl: "./user-account-button.component.html",
  styleUrl: "./user-account-button.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAccountButtonComponent {
  @Input() userType: AuthUserTypeEnum;

  @Input() pendingInvitationsCount: number = 0;

  @Input() userName: string;

  @Input() userData: IUserData;

  isMenuOpen: boolean = false;

  public readonly authUserTypeEnum = AuthUserTypeEnum;

  public readonly isLanguageSettingEnabled = false;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private downloadDocumentsService: DownloadDocumentsService,
    private dialog: MatDialog,
  ) {}

  get initial(): string {
    return (this.userName ?? this.userData.email)[0].toUpperCase();
  }

  get safeInvitationsCount(): string {
    return this.pendingInvitationsCount > 9 ? "9+" : `${this.pendingInvitationsCount}`;
  }

  get cssClass(): string {
    return this.isMenuOpen ? "open" : "";
  }

  menuOpened(): void {
    this.isMenuOpen = true;
  }

  menuClosed(): void {
    this.isMenuOpen = false;
  }

  async onInvitationsClicked(): Promise<void> {
    await this.router.navigate([`/${RoutingEnum.INVITATIONS}`]);
  }

  async onSettingClicked(): Promise<void> {
    await this.router.navigate([`/${RoutingEnum.USER_SETTINGS}`]);
  }

  public onLanguageClicked = (): void => {
    this.dialog.open(UserSettingsLanguageDialogComponent);
  };

  async onLogout(): Promise<void> {
    if (this.downloadDocumentsService.isDownloadingDocuments()) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          titleTranslatedText: "Download in progress",
          contentTranslatedText: `Are you sure you want to logout?`,
          confirmButtonColor: "danger",
          confirmButtonTranslatedText: "Cancel downloads and logout",
          confirmButtonIcon: "logout",
        },
      });

      dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
        if (result === ConfirmDialogResponseEnum.CONFIRM) {
          this.downloadDocumentsService.cancelAllDownloadsAndCloseToast();
          await this.authenticationService.logout();
        }
      });
    } else {
      await this.authenticationService.logout();
    }
  }
}
