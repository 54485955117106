<div class="container-space-between no-margin">
  <app-page-title
    [isGoBackUrlHistory]="true"
    [tag]="element?.recordState === recordStateEnum.ARCHIVED ? 'Archived' : ''"
    [title]="element?.name"
    backText="Back to Organisation"
  ></app-page-title>
</div>
@if (isLoading()) {
  <app-loader></app-loader>
} @else {
  <mat-accordion>
    <mat-expansion-panel class="mat-elevation-z0 section-box" [expanded]="true">
      <mat-expansion-panel-header class="section-header">
        <mat-panel-title class="section-title"><h3>Admin information</h3></mat-panel-title>
      </mat-expansion-panel-header>
      <div class="row-container">
        <div class="row">
          <div class="item">
            <p class="details-label">Recorded time</p>
            <app-value-and-copy-button
              [value]="element.recordedTime | date: dateTimeFormat"
            ></app-value-and-copy-button>
          </div>
          <div class="item">
            <p class="details-label">Recorded by</p>
            <app-value-and-copy-button [value]="element.recordedByName"></app-value-and-copy-button>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
    <br />
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel class="mat-elevation-z0 section-box" [expanded]="true">
      <mat-expansion-panel-header class="section-header">
        <mat-panel-title class="section-title">
          <h3>{{ mainInformationText }}</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="row-container">
        <div class="row row-4">
          <div class="item">
            <p class="details-label">Organisation</p>
            <a
              class="blue-link"
              [routerLink]="[
                '/' + routingEnum.ADMIN_ORGANISATION_DETAILS,
                element?.organisation?.id,
              ]"
            >
              {{ element.organisation?.name || "-" }}
            </a>
          </div>
          <div class="item">
            <p class="details-label">Address</p>

            <app-value-and-copy-button
              [value]="element.address.street"
              valueClass="details-value"
            ></app-value-and-copy-button>
          </div>
          <div class="item">
            <p class="details-label">ZIP / Post code</p>

            <app-value-and-copy-button
              [value]="element.address.zipCode"
              valueClass="details-value"
            ></app-value-and-copy-button>
          </div>
        </div>
        <div class="row row-4">
          <div class="item">
            <p class="details-label">State / Region</p>

            <app-value-and-copy-button
              [value]="element.address.region"
              valueClass="details-value"
            ></app-value-and-copy-button>
          </div>
          <div class="item">
            <p class="details-label">Country</p>
            <app-flag [country]="element.address.country"></app-flag>

            <app-value-and-copy-button
              [value]="element.address.countryName"
              valueClass="details-value"
            ></app-value-and-copy-button>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
}
