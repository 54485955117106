import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, signal } from "@angular/core";

import { Subscription } from "rxjs";
import { RecordStateEnum } from "src/app/shared/enums";
import { IOrganisation } from "src/app/shared/interfaces";
import { AuthenticationService, OrganisationsService } from "src/app/shared/services";

import { RouterService } from "@shared/services/router.service";

@Component({
  templateUrl: "./organisations.component.html",
  styleUrls: ["./organisations.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganisationsComponent implements OnInit, OnDestroy {
  public readonly recordStateEnum = RecordStateEnum;

  public isLoading = signal(true);

  public activeOrganisation: IOrganisation;

  private subscriptions = new Subscription();

  public translations = {
    organisations: $localize`:@@shared.organisations:Organisations`,
  };

  constructor(
    public authenticationService: AuthenticationService,
    private organisationsService: OrganisationsService,
    private routerService: RouterService,
  ) {}

  public async ngOnInit(): Promise<void> {
    await this.setActiveOrganisation();

    this.subscriptions.add(
      this.authenticationService.userDataObservable$.subscribe(async () => {
        await this.setActiveOrganisation();
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onAdd = async (): Promise<void> => {
    this.routerService.navigate(this.routerService.getOrganisationLink());
  };

  public onViewDetails = async (id: string): Promise<void> => {
    this.routerService.navigate(this.routerService.getOrganisationLink(id));
  };

  private async setActiveOrganisation(): Promise<void> {
    this.isLoading.set(true);
    this.activeOrganisation = undefined;
    const activeOrganisationId = this.authenticationService.getActiveOrganisationId();

    if (activeOrganisationId) {
      this.activeOrganisation = await this.organisationsService.get(activeOrganisationId);
      this.isLoading.set(false);
    }
  }
}
