<div [formGroup]="formGroup" class="row-container">
  <div class="row">
    <div class="item">
      <iov-input
        [autofocus]="!isEditing()"
        enableViewMode
        formControlName="name"
        label="Name"
      ></iov-input>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <iov-input-chips
        #locationTypesInputChips
        [allowCreateTag]="true"
        [tagUrl]="locationTypesService.getBaseUrl"
        [isGetTagsFromForm]="isInboundShare"
        enableViewMode
        formControlName="types"
        label="Type(s)"
        placeholder="Type new value and hit enter"
        tooltip="Determines the type(s) of the location. E.g. Forest, Sawmill, Exporter, Importer, Agent, etc."
      ></iov-input-chips>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <iov-input-select
        [options]="organisationOptions"
        [viewModeTemplate]="organisationViewMode"
        enableViewMode
        formControlName="organisation"
        label="Organisation"
      >
        <ng-container action>
          <iov-button
            (pressed)="onAddOrganisationClicked()"
            class="icon-add-circle"
            tp="Add new organisation"
            type="icon"
          >
            <mat-icon icon-small>add</mat-icon>
          </iov-button>
        </ng-container>
      </iov-input-select>

      <ng-template #organisationViewMode let-label="label" let-option>
        <iov-form-label [label]="label"></iov-form-label>
        <div class="form-field-input-readonly">
          @if (option.label && option.value) {
            <iov-form-link [link]="getOrganisationLink(option.value)" [styledLink]="true">
              {{ option.label }}
            </iov-form-link>
          } @else {
            @if (isSharedUser) {
              <app-not-shared-data></app-not-shared-data>
            } @else {
              <app-loader containerClass="size-small"></app-loader>
            }
          }
        </div>
      </ng-template>
    </div>
  </div>
</div>
