<div [formGroup]="formGroup" class="row-container">
  <div class="row row-2">
    <div class="item">
      <iov-input-chips
        #locationSelect
        (tagCreatedEvent)="newLocationTypeCreated.emit()"
        [allowCreateTag]="true"
        [formControlName]="locationFormControlName"
        [tagUrl]="locationTypesService.getBaseUrl"
        label="Type(s)"
        tooltip="Determines the type(s) of the location.
                         E.g. Forest, Sawmill, Exporter, Importer, Agent, etc."
      ></iov-input-chips>
    </div>
    <div class="item">
      <iov-input-chips
        #countrySelect
        [formControlName]="countryFormControlName"
        [options]="allCountriesOption"
        label="Country(ies)"
        tooltip="Select the country(ies) from the list"
      ></iov-input-chips>
    </div>
  </div>
</div>
