import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { loadTranslations } from "@angular/localize";

import { BehaviorSubject, Observable } from "rxjs";

import { LocalStorageService } from "./local-storage.service";
import { CommonConstants } from "../constants";
import { LanguageEnum, StorageKeyEnum } from "../enums";

@Injectable({
  providedIn: "root",
})
export class LocalizationService {
  private agGridLocaleSubject: BehaviorSubject<any>;

  public agGridLocaleObservable$: Observable<any>;

  private readonly localizationFolderPath = "../../../../assets/data/localization/";

  constructor(
    private localStorageService: LocalStorageService,
    private httpClient: HttpClient,
  ) {
    this.agGridLocaleSubject = new BehaviorSubject<any>(null);
    this.agGridLocaleObservable$ = this.agGridLocaleSubject.asObservable();
  }

  public getLanguage = (): LanguageEnum => {
    const storedLanguage = this.localStorageService.get(StorageKeyEnum.LANGUAGE);
    let language = storedLanguage ?? navigator.language.substring(0, 2).toLowerCase();

    if (!CommonConstants.AVAILABLE_LANGUAGE_CODES.includes(language as LanguageEnum)) {
      language = CommonConstants.DEFAULT_LANGUAGE_CODE;
    }

    return language as LanguageEnum;
  };

  public setLanguage = (language: LanguageEnum): void =>
    this.localStorageService.set(StorageKeyEnum.LANGUAGE, language);

  public loadTranslations = (): void => {
    this.loadAgGridLocale();
    this.loadTextLocale();
  };

  private loadTextLocale = (): void => {
    const language = this.getLanguage();

    if (language !== CommonConstants.DEFAULT_LANGUAGE_CODE) {
      const localeJsonPath = `${this.localizationFolderPath}${language}/text.json`;

      this.httpClient.get(localeJsonPath).subscribe((translations: any) => {
        switch (language) {
          case "es":
            loadTranslations(translations);
            break;
        }
      });
    }
  };

  private loadAgGridLocale = (): void => {
    if (this.agGridLocaleSubject.value) {
      return;
    }

    const localeJsonPath = `${this.localizationFolderPath}${this.getLanguage()}/ag-grid.json`;

    this.httpClient.get(localeJsonPath).subscribe((localeJson: any) => {
      this.agGridLocaleSubject.next(localeJson);
    });
  };
}
