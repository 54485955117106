import { HttpClient } from "@angular/common/http";
import { Injectable, Inject } from "@angular/core";

import { Observable, catchError, firstValueFrom, map, throwError } from "rxjs";

import { APP_CONFIG } from "@shared/tokens";

import { IConfig, IGraphQLPaginationResponse } from "../../interfaces";

interface IGraphQLError {
  message: string;
  path?: string[];
  extensions?: {
    classification: string;
  };
}
@Injectable({
  providedIn: "root",
})
export class GraphService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {}

  public query<T>(options: { query: string }): Observable<T> {
    return this.http
      .post<{ data: T; errors: IGraphQLError[] }>(`${this.environment.baseUrl}graphql`, {
        query: options.query,
      })
      .pipe(
        map((response) => {
          if (response.errors?.length > 0) {
            throw response.errors;
          }

          return response.data;
        }),
        catchError((error: IGraphQLError[]) => {
          console.error(error);

          return throwError(() => error[0].message);
        }),
      );
  }

  private async executeQuery<T>(query: string): Promise<T> {
    return firstValueFrom(this.query<T>({ query }));
  }

  public async fetchSinglePage<T, R>(
    functionName: string,
    query: string,
    propertiesToAddFunction: (edge: any) => any = () => {},
  ): Promise<IGraphQLPaginationResponse<R>> {
    const queriedElements = await this.executeQuery<T>(query);

    const elements = queriedElements[functionName].edges.map((edge) => ({
      ...edge.node,
      ...propertiesToAddFunction(edge),
    }));

    return {
      totalCount: queriedElements[functionName].totalCount,
      pageInfo: queriedElements[functionName].pageInfo,
      content: elements,
    };
  }
}
