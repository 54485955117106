import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { MatIcon } from "@angular/material/icon";

import { TippyDirective } from "@ngneat/helipopper";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { CopyModeEnum } from "@components/shared/value-and-copy-button/value-and-copy-button.model";
import { CommonUtils } from "@shared/utils";

@Component({
  selector: "app-value-and-copy-button",
  templateUrl: "./value-and-copy-button.component.html",
  styleUrls: ["./value-and-copy-button.component.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIcon, TippyDirective, CommonModule],
})
export class ValueAndCopyButtonComponent implements OnInit {
  @Input()
  public value: string;

  @Input()
  public showCopyIcon = true;

  @Input() valueClass: string;

  @Input() copyMode: CopyModeEnum = CopyModeEnum.CONTAINER;

  @Input() isHTML = false;

  @HostBinding("class")
  @Input()
  appearance: "input" | "textarea" = "input";

  @Output() clickOnValue: EventEmitter<void> = new EventEmitter();

  public hasValue = false;

  readonly copyModeEnum = CopyModeEnum;

  readonly translations = {
    copy: `Copy`,
    copied: `Copied`,
  };

  constructor(private notificationService: NotificationService) {}

  public ngOnInit(): void {
    this.hasValue = !!this.value;
  }

  public onValueClick(): void {
    this.clickOnValue.emit();
  }

  public onClick = (): void => {
    CommonUtils.textToClipboard(this.value);
    this.notificationService.showSuccess(this.translations.copied);
  };
}
