import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  input,
  Input,
  OnDestroy,
  Output,
  signal,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef } from "ag-grid-community";
import { Subscription } from "rxjs";
import { ConfirmDialogComponent } from "src/app/components";
import { ConfirmDialogResponseEnum, RecordStateEnum, TableEnum } from "src/app/shared/enums";
import { IMaterial, IMaterialExtended } from "src/app/shared/interfaces";
import { AuthenticationService, MaterialsService } from "src/app/shared/services";
import { ColumnUtils, CommonUtils } from "src/app/shared/utils";

import { SlideOverlayPageService } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.service";
import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { LinkCellRendererComponent, QuickActionsMenuComponent } from "@shared/cell-renderers";
import { RouterService } from "@shared/services/router.service";

@Component({
  selector: "app-materials-table",
  templateUrl: "./materials-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialsTableComponent implements AfterViewInit, OnDestroy {
  @Input()
  public materials: IMaterial[] = null;

  materialsIds = input<string[]>(null);

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isRecordStateFilterEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  @Input()
  public recordState: RecordStateEnum = RecordStateEnum.ALL;

  @Input()
  public columns: string[] = ["recordState", "name", "category", "tags"];

  @Input()
  public table = TableEnum.MATERIALS;

  @Input()
  public isSaveTableState = false;

  @Output()
  public removeClicked = new EventEmitter<IMaterial>();

  public isLoading = signal(true);

  public rowData: IMaterial[] | IMaterialExtended[] = [];

  public columnDefs = signal<ColDef[]>([]);

  private subscriptions = new Subscription();

  private readonly isRegularUser = this.authenticationService.isRegularUser();

  constructor(
    private materialsService: MaterialsService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private overlay: SlideOverlayPageService,
    private routerService: RouterService,
    private authenticationService: AuthenticationService,
  ) {
    this.subscriptions.add(
      this.overlay.refreshTable$.subscribe(() => {
        this.getAll();
      }),
    );
  }

  public async ngAfterViewInit() {
    this.setColumnDefs();
    await this.getAll();
  }

  private setColumnDefs = (): void => {
    const columnDefs: ColDef[] = [
      ColumnUtils.recordState(),
      {
        colId: "name",
        headerName: "Name",
        field: "name",
        lockVisible: true,
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteIdParam: "id",
          linkRouteFn: this.routerService.getMaterialLink,
        },
      },
      {
        colId: "nameWithRemove",
        headerName: "Name",
        field: "name",
        lockVisible: true,
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: {
          actions: [
            {
              icon: "close",
              tooltip: "Remove",
              show: () => this.overlay.editMode(),
              click: (material: IMaterial) => this.removeClicked.emit(material),
            },
          ],
        },
      },
      { headerName: "Category", field: "category" },
    ];

    if (this.isRegularUser) {
      columnDefs.push(ColumnUtils.tags("Tags"));
    }

    this.columnDefs.set(CommonUtils.getVisibleColumnDefs(columnDefs, this.columns));
  };

  public onRowClick = async (element: IMaterial): Promise<void> => {
    if (!this.areButtonsEnabled) {
      return;
    }

    await this.routerService.navigate(this.routerService.getMaterialLink(element.id));
  };

  public onToggleArchiveStatus = async (element: IMaterial): Promise<void> => {
    const isArchived = element.recordState === RecordStateEnum.ARCHIVED;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: `${isArchived ? "Unarchive" : "Archive"} confirmation`,
        contentTranslatedText: `Are you sure that you want to ${isArchived ? "unarchive" : "archive"} this record?`,
        confirmButtonTranslatedText: isArchived ? "Unarchive" : "Archive",
        confirmButtonIcon: isArchived ? "inventory" : "archive",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        try {
          this.isLoading.set(true);
          const wasArchived = element.recordState === RecordStateEnum.ARCHIVED;

          await this.materialsService.setRecordState(
            {
              recordState: wasArchived ? RecordStateEnum.ACTIVE : RecordStateEnum.ARCHIVED,
            },
            element.id,
          );
          this.notificationService.showSuccess(wasArchived ? "Unarchived" : "Archived");
          await this.getAll();
        } catch (error) {
          this.notificationService.showError(error);
          this.isLoading.set(false);
        }
      }
    });
  };

  public getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    const columnDefs = this.columnDefs();
    const tagsFieldPresent = columnDefs.some((c) => c.field === "tags");
    const fieldsToInclude = [];

    if (tagsFieldPresent) {
      fieldsToInclude.push("TAGS");
    }

    if (this.materials) {
      this.rowData = this.materials;
      this.isLoading.set(false);
    } else if (this.materialsIds()) {
      try {
        this.rowData = await this.materialsService.getByIdsGraphQL(
          this.materialsIds(),
          undefined,
          fieldsToInclude,
        );
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    } else {
      try {
        this.rowData = await this.materialsService.getAllGraphQL(
          undefined,
          undefined,
          fieldsToInclude,
        );

        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
