@if (fixedFields.length) {
  <div class="fixed-fields">
    <mat-icon svgIcon="lock"></mat-icon>
    @for (field of fixedFields; track field.label) {
      <div class="fixed-field">
        <div class="label">
          {{ field.label }}
        </div>
        <div>
          {{ field.value }}
        </div>
      </div>
    }
  </div>
}

@if (columnDefs().length) {
  <app-table
    #table
    class="bulk-add-items-table"
    [isPaginatorEnabled]="false"
    [getGridHeightFn]="getGridHeightFn"
    [isSearchEnabled]="false"
    [isLoading]="false"
    [columnDefs]="columnDefs()"
    [rowData]="rowData"
  ></app-table>
}

@if (formGroup.controls[fieldEnum.ITEMS].controls.length < maxItemsCount) {
  <div class="add-items" [formGroup]="itemsQuantityFormGroup">
    <span
      class="label blue-link add-item-button mt-12"
      [class.disabled]="
        !itemsQuantityFormGroup.controls['quantity'].value ||
        itemsQuantityFormGroup.invalid ||
        itemsQuantityFormGroup.pending
      "
      (click)="buildItems()"
      (keyup)="onKeyUpAddItems($event)"
      tabindex="0"
    >
      + Add
    </span>
    <iov-input
      hideOptionalHint
      type="number"
      formControlName="quantity"
      formInputClass="reduced-width"
      customErrorClass="reduced-width"
    ></iov-input>
    <span class="mt-12">item(s)</span>
  </div>
}
