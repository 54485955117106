<div class="form-page-container">
  <div class="header-container">
    <app-page-title [title]="(isEditing() ? 'Edit' : 'Add') + ' supply chain'"></app-page-title>
  </div>
  <div class="element-content hidden-overflow-y">
    @if (isLoading()) {
      <app-loader></app-loader>
    } @else {
      <div cdkDropListGroup class="right-side-panel-container">
        <div [formGroup]="formGroup" class="content">
          <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z0 section-box no-padding" [expanded]="true">
              <mat-expansion-panel-header class="section-header">
                <mat-panel-title class="section-title">{{ mainInformationText }}</mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row-container">
                <div class="row row-1-2">
                  <div class="item">
                    <iov-input
                      [autofocus]="!isEditing()"
                      formControlName="name"
                      label="Name"
                      tooltip="Please provide a name that corresponds the contents of your supply chain.
                                       E.g. product name, certification name, etc."
                    ></iov-input>
                  </div>
                  <div class="item">
                    <iov-input formControlName="description" label="Description"></iov-input>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion>
            <mat-expansion-panel
              [class.scroll-body]="!isZoomEnabled"
              [expanded]="true"
              class="mat-elevation-z0 section-box no-padding"
            >
              <mat-expansion-panel-header class="section-header">
                <mat-panel-title class="section-title">
                  Locations
                  <mat-icon
                    class="info-icon"
                    tp="Click and drag to navigate the supply chain and use the mouse wheel / touch pad to zoom in/out."
                  >
                    help
                  </mat-icon>
                </mat-panel-title>
              </mat-expansion-panel-header>
              @if (isLoadingSelectedLocations()) {
                <app-loader containerClass="on-small-supply-chain"></app-loader>
              } @else {
                <div
                  (cdkDropListDropped)="onItemDropped($event)"
                  [cdkDropListSortingDisabled]="true"
                  cdkDropList
                  class="flow-chart-container"
                >
                  <div
                    [class.opaque]="!selectedLocations()?.length"
                    class="flow-chart-drop-layover"
                  >
                    Drop locations here
                  </div>
                  @if (selectedLocations().length) {
                    @if (!isReloadingSupplyChainFlowChart()) {
                      <app-supply-chain-flow-chart
                        (remove)="onRemove($event)"
                        [canRemove]="true"
                        [containerClass]="'within-right-side-container'"
                        [height]="300"
                        [isZoomEnabled]="isZoomEnabled"
                        [links]="locationLinks"
                        [locations]="selectedLocations()"
                      ></app-supply-chain-flow-chart>
                    }
                  } @else {
                    <iov-drag-drop-container
                      (itemDropped)="onItemDropped($event)"
                      class="big"
                      itemType="locations"
                      placeHolder="Drag and drop locations here or click '+' button next to their name to add them to the supply chain."
                    ></iov-drag-drop-container>
                  }
                </div>
              }
            </mat-expansion-panel>
          </mat-accordion>

          @if (!isLoadingSelectedLocations()) {
            <br />
            <app-supply-chain-locations
              [locations]="selectedLocations()"
              [allStandards]="allStandards"
              [allDocumentTypes]="allDocumentTypes"
              [isShowCertificates]="true"
              [isShowDocuments]="true"
              [isShowLinks]="true"
              [expandArrowPosition]="'before'"
              [isShowTableSelectCheckbox]="true"
              [canRemove]="true"
              (remove)="onRemove($event)"
              (selectedCertificatesChanged)="onSelectedLocationsChanged($event)"
              (selectedDocumentsChanged)="onSelectedLocationsChanged($event)"
              [allDocumentTypes]="allDocumentTypes"
              [allStandards]="allStandards"
              [canRemove]="true"
              [expandArrowPosition]="'before'"
              [isShowCertificates]="true"
              [isShowDocuments]="true"
              [isShowLinks]="true"
            ></app-supply-chain-locations>
          }
        </div>

        <iov-right-side-panel
          itemType="Location"
          itemTypePlural="Locations"
          [isLoadingItems]="isLoadingAvailableLocations()"
          [availableItems]="availableLocations"
          (pressAdd)="onAddNewLocation()"
          (pressAddAll)="onAddAll()"
          (pressRefresh)="onReloadLocations()"
          (searchEvent)="onSearchAvailable($event)"
        >
          <div
            class="items-container"
            cdkDropList
            [cdkDropListSortingDisabled]="true"
            [cdkDropListData]="availableLocations"
          >
            @for (item of availableLocations; track $index) {
              <iov-draggable-card (pressed)="onAdd(item.id)" [item]="item">
                <iov-location-card-content [location]="item" [type]="cardContentTypeEnum.ADD" />
              </iov-draggable-card>
            }
          </div>
        </iov-right-side-panel>
      </div>
    }
  </div>
  <app-entity-save-footer
    (save)="onSave()"
    [overrideCancel]="true"
    (cancel)="onCancel()"
    [isDisabled]="isSubmitButtonDisabled()"
    [showSubmitButton]="!isLoading()"
  ></app-entity-save-footer>
</div>
