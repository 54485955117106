<div class="container-space-between">
  <app-page-title
    [title]="element?.name"
    backText="Back to Organisations"
    [goBackRoute]="routingEnum.ADMIN_ORGANISATIONS"
  ></app-page-title>
</div>

@if (isLoading()) {
  <app-loader></app-loader>
} @else {
  <mat-accordion>
    <mat-expansion-panel class="mat-elevation-z0 section-box" [expanded]="true">
      <mat-expansion-panel-header class="section-header">
        <mat-panel-title class="section-title">
          <h3>{{ mainInformationText }}</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="row-container">
        <div class="row row-4">
          <div class="item">
            <p class="details-label">Name</p>
            <app-value-and-copy-button
              valueClass="details-value"
              [value]="element.name"
            ></app-value-and-copy-button>
          </div>
          <div class="item">
            <p class="details-label">Country</p>
            <app-flag [country]="element.address.country"></app-flag>
            <app-value-and-copy-button
              valueClass="details-value"
              [value]="element.address.countryName"
            ></app-value-and-copy-button>
          </div>
          <div class="item">
            <p class="details-label">State / Region</p>
            <app-value-and-copy-button
              valueClass="details-value"
              [value]="element.address.region"
            ></app-value-and-copy-button>
          </div>
          <div class="item">
            <p class="details-label">ZIP / Postcode</p>
            <app-value-and-copy-button
              valueClass="details-value"
              [value]="element.address.zipCode"
            ></app-value-and-copy-button>
          </div>
        </div>
        <div class="row row-4">
          <div class="item">
            <p class="details-label">Address</p>
            <app-value-and-copy-button
              valueClass="details-value"
              [value]="element.address.street"
            ></app-value-and-copy-button>
          </div>
          <div class="item one-line-wrap-ellipsis hover-visibility-container">
            <p class="details-label">Website</p>
            @if (element.website) {
              <a
                [href]="element.website"
                class="details-value"
                target="_blank"
                [tp]="element.website"
              >
                {{ element.website }}
                <app-external-link-icon></app-external-link-icon>
              </a>
            } @else {
              -
            }
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <br />
  <mat-tab-group
    animationDuration="0ms"
    mat-stretch-tabs="false"
    mat-align-tabs="start"
    [selectedIndex]="selectedTabIndex"
    (selectedIndexChange)="selectedTabIndex = $event"
  >
    <mat-tab>
      <ng-template mat-tab-label>Locations ({{ locations.length }})</ng-template>
      @if (!isLoadingLocations()) {
        <app-locations-table
          [locations]="locations"
          [columns]="['recordState', 'name', 'address.countryName']"
        ></app-locations-table>
      } @else {
        <app-loader></app-loader>
      }
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>Extensions</ng-template>
      @if (selectedTabIndex === 1) {
        <app-admin-organisation-extensions-table
          [organisationId]="element.id"
        ></app-admin-organisation-extensions-table>
      }
    </mat-tab>
  </mat-tab-group>
}
