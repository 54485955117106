import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, Inject, OnInit, signal } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { ProcessTypesService } from "src/app/shared/services";
import { CustomValidators } from "src/app/shared/validators";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { CommonConstants } from "@shared/constants";
import { FormUtils } from "@shared/utils";

@Component({
  templateUrl: "./edit-process-type-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditProcessTypeDialogComponent implements OnInit {
  public formGroup: UntypedFormGroup = null;

  public isLoading = signal(false);

  public isEditing = signal(false);

  constructor(
    public dialogRef: MatDialogRef<EditProcessTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { processType?: any },
    private processTypesService: ProcessTypesService,
    private notificationService: NotificationService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.isEditing.set(!!this.data?.processType?.id);

    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl(
        null,
        [CustomValidators.required],
        [
          CustomValidators.entityAlreadyExists(
            this.processTypesService,
            this.data?.processType?.id ?? null,
          ),
        ],
      ),
    });

    if (this.isEditing()) {
      this.formGroup.controls["name"].setValue(this.data.processType.name);
    }
  }

  public isSubmitButtonDisabled = (): boolean => {
    if (!this.isEditing()) {
      return false;
    }

    return this.formGroup.invalid || this.formGroup.pending;
  };

  public onSubmit = async (): Promise<void> => {
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);
      this.notificationService.showError(CommonConstants.FILL_REQUIRED_FIELDS_MSG);

      return;
    }
    this.isLoading.set(true);

    const payload: any = {
      id: this.isEditing() ? this.data.processType.id : undefined,
      name: this.formGroup.controls["name"].value.trim(),
    };

    await this.processTypesService
      .createOrUpdate(payload, this.data?.processType?.id)
      .then(() => {
        this.notificationService.showSuccess(
          `Process type ${this.isEditing() ? "modified" : "created"}`,
        );
        this.onClose(true);
      })
      .catch((error: HttpErrorResponse) => {
        this.notificationService.showError(error);
      })
      .finally(() => {
        this.isLoading.set(false);
      });
  };

  public onClose = (hasSaved = false): void => {
    this.dialogRef.close({ hasSaved });
  };
}
