import { Injectable } from "@angular/core";

import { IInboundMapping } from "@shared/interfaces";

@Injectable({
  providedIn: "root",
})
export class UnmappedDependenciesService {
  constructor() {}

  public hasUnmappedDependencies(
    idsToCheck: string[],
    mappings: IInboundMapping[],
    key: string,
  ): boolean {
    return idsToCheck.some((id) => !this.hasLocalMapping(id, mappings, key));
  }

  private hasLocalMapping(idToCheck: string, mappings: IInboundMapping[], key: string): boolean {
    return mappings.some((item) => item[key].includes(idToCheck));
  }
}
