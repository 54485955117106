<iov-button (pressed)="onButtonClick()" [type]="buttonType">
  {{ buttonText }}
</iov-button>
<ng-container *ngIf="isShowLinkText">
  <br />
  <br />
  <a class="clickable underline" (click)="onToggleShowText()">
    {{ isShowingText ? "Hide" : "Show" }} link
  </a>
  <br />
  <br />
  <p *ngIf="isShowingText" class="break-word">{{ text }}</p>
</ng-container>
