@if (!isApiAvailable) {
  <app-api-unavailable></app-api-unavailable>
} @else {
  <div class="auth-container">
    <mat-icon class="logo-icon" svgIcon="logo-night"></mat-icon>
    <div class="outer-container">
      <div class="container">
        <div class="title">
          @if (hasInvitation) {
            <h2>Please log in to accept invitation from {{ invitation.organisationName }}</h2>
          } @else {
            <h1>Login</h1>
          }
        </div>

        @if (isLoading()) {
          <app-loader></app-loader>
        } @else {
          <div class="action-button-container">
            <form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
              <iov-input
                type="email"
                [emitOnInput]="isFirefox"
                formControlName="email"
                label="Email"
                autofocus
                autocomplete="on"
              ></iov-input>
              <iov-input-password
                [emitOnInput]="isFirefox"
                label="Password"
                formControlName="password"
                autocomplete="current-password"
              ></iov-input-password>

              <div class="details">
                <a
                  class="underline"
                  [routerLink]="['/' + routingEnum.FORGOT_PASSWORD]"
                  [state]="{ email: formGroup.controls['email'].value }"
                >
                  Forgot your password?
                </a>
              </div>

              <div class="login-button">
                <iov-button
                  class="fresh-green"
                  [disabled]="formGroup.invalid"
                  accessibilityRole="submit"
                  size="large"
                >
                  Log in
                </iov-button>
              </div>
            </form>
          </div>

          <div class="switch-mode-container text-center">
            <span>Don't have an account yet?</span>
            <a [routerLink]="'/' + routingEnum.SIGN_UP">Sign up</a>
          </div>
        }
      </div>
    </div>
  </div>
}
