import { DecimalPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject, Input } from "@angular/core";

import { CardContentComponent } from "@design-makeover/components/cards/card-content/card-content.component";

import { ItemsSupplyChainModel } from "@components/shared/items-supply-chain/items-supply-chain.component.model";
import { CommonUtils } from "@shared/utils";

@Component({
  selector: "iov-grouped-product-card-content",
  templateUrl: "./grouped-product-card-content.component.html",
  styleUrls: ["./grouped-product-card-content.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupedProductCardContentComponent extends CardContentComponent {
  @Input() groupedProduct: ItemsSupplyChainModel.IGroupedProduct;

  private decimalPipe: DecimalPipe = inject(DecimalPipe);

  public get totalQuantityFormatted(): string {
    const { quantitySum, defaultUnitOfMeasurement, baseUnitOfMeasurement } = this.groupedProduct;

    if (defaultUnitOfMeasurement) {
      return CommonUtils.formatQuantityWithDefaultUnit(
        quantitySum,
        defaultUnitOfMeasurement,
        baseUnitOfMeasurement,
      );
    } else {
      const precision = baseUnitOfMeasurement.precision ?? "0";

      return `${this.decimalPipe.transform(quantitySum, `1.0-${precision}`)} ${baseUnitOfMeasurement.symbol}`;
    }
  }
}
