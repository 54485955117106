import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  EventEmitter,
  HostListener,
  Injector,
  input,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Router } from "@angular/router";

import { TippyProps } from "@ngneat/helipopper/lib/tippy.types";
import * as d3 from "d3";
import { graphlib, render } from "dagre-d3";
import { debounceTime, Subject, Subscription } from "rxjs";
import { GraphScaler } from "src/app/shared/classes/graph-scaler.class";

import { FullScreenSupplyChainDialogComponent } from "@components/shared/fullscreen-supply-chain-dialog/fullscreen-supply-chain-dialog.component";
import { CommonConstants } from "@shared/constants";
import { RecordStateEnum } from "@shared/enums";
import {
  ICertificate,
  IDocument,
  IDocumentType,
  ILocationLinkDetail,
  IRecordResponse,
} from "@shared/interfaces";
import { NavigationParams, RouterService } from "@shared/services/router.service";

import { FlowChartNodeComponent } from "./flow-chart-node/flow-chart-node.component";

@Component({
  selector: "app-supply-chain-flow-chart",
  templateUrl: "./supply-chain-flow-chart.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplyChainFlowChartComponent
  extends GraphScaler
  implements OnChanges, AfterViewInit, AfterContentChecked, OnDestroy
{
  public locations = input<any[]>([]);

  @Input()
  public links: ILocationLinkDetail[];

  @Input()
  public override height: number;

  @Input()
  public override svgId: string;

  @Input()
  public containerClass: string = "supply-chain-flow-chart";

  @Input()
  public canRemove = false;

  @Input()
  public override isZoomEnabled = true;

  @Input()
  public fullScreenOptionEnabled: boolean = true;

  @Input()
  public addLocationOptionEnabled: boolean = false;

  @Input()
  public onlyDisplayActiveAttachments: boolean = false;

  @Input()
  public locationsRulesetsRecords: IRecordResponse[];

  @Input()
  public override allDocumentTypes: IDocumentType[];

  @Input()
  public isInboundShared = false;

  @Input()
  public inboundSharedSenderOrgId: string;

  @Output()
  public remove: EventEmitter<string> = new EventEmitter();

  public isFullScreenMode = !!document.querySelector(
    `.${CommonConstants.SUPPLY_CHAIN_FULL_SCREEN_PANEL_CLASS}`,
  );

  getMainClass() {
    return this.containerClass;
  }

  public readonly recordStateEnum = RecordStateEnum;

  private componentFactory: ComponentFactory<FlowChartNodeComponent>;

  private readonly tooltipOptionsAttachments: Partial<TippyProps> = {
    allowHTML: true,
    placement: "bottom",
  };

  private readonly tooltipOptionsRemoveButton: Partial<TippyProps> = {
    allowHTML: true,
    placement: "top",
  };

  private graph: graphlib.Graph;

  private svg: any;

  private svgGroup: any;

  private zoom: any;

  private autoScaleGraphSubject = new Subject();

  private subscriptions = new Subscription();

  override getSvg() {
    return this.svg;
  }

  override getSvgGroup() {
    return this.svgGroup;
  }

  override getZoom() {
    return this.zoom;
  }

  override getGraph() {
    return this.graph;
  }

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.autoScaleGraphSubject.next(true);
  }

  constructor(
    private _resolver: ComponentFactoryResolver,
    private _injector: Injector,
    private router: Router,
    private routerService: RouterService,
    private dialog: MatDialog,
  ) {
    super();

    this.subscriptions.add(
      this.autoScaleGraphSubject
        .pipe(debounceTime(CommonConstants.DEBOUNCE_SUPPLY_CHAIN_RESIZE_TIME_MS))
        .subscribe(() => this.scaleAndCenterGraph(`#${this.svgId}`)),
    );
  }

  public ngAfterViewInit(): void {
    this.componentFactory = this._resolver.resolveComponentFactory(FlowChartNodeComponent);
  }

  public ngAfterContentChecked(): void {
    // This will keep trying until document is fully ready and so graph is drawn only once
    const drawnNodes = this.mainContainer()?.getElementsByClassName("flow-chart-node");

    if (drawnNodes && !drawnNodes.length) {
      this.drawGraph();
    }
  }

  public ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges["locations"] && !simpleChanges["locations"].isFirstChange()) {
      this.updateNodes();
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public mainContainer() {
    return document.querySelector(`.${this.getMainClass()}`);
  }

  public fullScreen(): void {
    const dialogRef = this.dialog.open(FullScreenSupplyChainDialogComponent, {
      width: window.innerWidth + "px",
      height: window.innerHeight - 25 + "px",
      panelClass: CommonConstants.SUPPLY_CHAIN_FULL_SCREEN_PANEL_CLASS,
      data: {
        containerClass: "flow-chart-container-class-dialog",
        locations: this.locations(),
        allDocumentTypes: this.allDocumentTypes,
        locationsRulesetsRecords: this.locationsRulesetsRecords,
        links: this.links,
      },
    } as MatDialogConfig);

    dialogRef.afterOpened().subscribe(() => {
      dialogRef.componentInstance.setIsOpened(true);
    });
  }

  public addLocation(): void {
    this.routerService.navigate(this.routerService.getLocationLink());
  }

  private updateNodes(): void {
    for (const location of this.locations()) {
      location.filteredAndSelectedDocuments = location.documents.filter(
        this.attachmentsFilter.bind(this),
      );
      location.filteredAndSelectedCertificates = location.certificates.filter(
        this.attachmentsFilter.bind(this),
      );
      this.updateNode(location);
    }

    this.scaleAndCenterGraph(`#${this.svgId}`);
  }

  private updateNode(location: any): void {
    const matchingNodeElement = this.svgGroup
      .selectAll("g.node")
      .filter((d) => d === location.id)
      .node();

    if (matchingNodeElement) {
      const selectedDocumentsLn = location.filteredAndSelectedDocuments.length;
      const selectedCertificatesLn = location.filteredAndSelectedCertificates.length;
      const selectedDocumentsEl = d3.select(matchingNodeElement).select(".selected-documents");
      const selectedCertificatesEl = d3
        .select(matchingNodeElement)
        .select(".selected-certificates");

      selectedDocumentsEl.text(`${selectedDocumentsLn}`);
      selectedCertificatesEl.text(`${selectedCertificatesLn}`);

      this.setLocationDocumentsTooltip(location, matchingNodeElement);
      this.setLocationCertificatesTooltip(location, matchingNodeElement);
      this.setLocationTypesTooltips(matchingNodeElement);
    }
  }

  private attachmentsFilter(attachedRecord: ICertificate | IDocument): boolean {
    let guard: boolean = true;

    if (this.onlyDisplayActiveAttachments) {
      guard = attachedRecord.recordState === RecordStateEnum.ACTIVE;
    }

    return attachedRecord.isSelected && guard;
  }

  private drawGraph = (): void => {
    if (!this.locations()?.length || !this.componentFactory) {
      return;
    }

    // Sort nodes and links so supply chain is always drawn the same way on re-render.
    // this.locations = this.locations.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
    // this.links = this.links.sort((a: any, b: any) => (a.from > b.from ? 1 : -1));

    this.graph = new graphlib.Graph().setGraph({}).setDefaultEdgeLabel(() => {
      return {};
    });
    (this.graph.graph() as any).rankdir = "LR";
    (this.graph.graph() as any).nodesep = 0;
    for (const location of this.locations()) {
      location.filteredAndSelectedDocuments = location.documents.filter(
        this.attachmentsFilter.bind(this),
      );
      location.filteredAndSelectedCertificates = location.certificates.filter(
        this.attachmentsFilter.bind(this),
      );
      location.allSelectedCertificates = location.certificates.filter(
        (certificate) => certificate.isSelected,
      );
      location.allSelectedDocuments = location.documents.filter((document) => document.isSelected);

      const nodeComponent = this.componentFactory.create(this._injector);

      nodeComponent.instance.location = location;
      nodeComponent.instance.locationsRulesetsRecords = this.locationsRulesetsRecords;

      nodeComponent.instance.canRemove = this.canRemove;
      nodeComponent.instance.isEditing = !!this.router.url.includes("edit");
      nodeComponent.instance.hasAvailableCertificates =
        !!location.filteredAndSelectedCertificates.length;
      nodeComponent.instance.hasAvailableDocuments = !!location.filteredAndSelectedDocuments.length;
      nodeComponent.instance.isSafari = this.isSafari;
      nodeComponent.instance.shouldOpenInNewTab = this.isFullScreenMode;
      nodeComponent.changeDetectorRef.detectChanges();

      this.graph.setNode(location.id, {
        label: nodeComponent.location.nativeElement.innerHTML,
        labelType: "html",
      });
    }

    // Links
    for (const link of this.links) {
      const fromId = link.from.id;
      const toId = link.to.id;
      const fromIdExists = this.locations().some((n) => n.id === fromId);

      if (fromIdExists) {
        const toIdExists = this.locations().some((n) => n.id === toId);

        if (toIdExists) {
          this.graph.setEdge(fromId, toId, { label: "" });
        }
      }
    }

    this.svg = d3.select(`.${this.getMainClass()} svg#${this.svgId}`);
    this.svgGroup = this.svg.select("g");

    // Zoom
    if (this.isZoomEnabled) {
      this.zoom = d3
        .zoom()
        .scaleExtent([0.03, 1])
        .on("zoom", (event: any) => {
          this.svgGroup.attr("transform", event.transform);
        });
      this.svg.call(this.zoom);
    }

    new render()(this.svgGroup, this.graph as any);

    for (const nodeElement of this.svgGroup.selectAll("g.node")) {
      const locationId = nodeElement.__data__;
      const location = this.locations().find((n) => n.id === locationId);
      const certificatesIcon = d3.select(nodeElement).select(".certificates");
      const documentsIcon = d3.select(nodeElement).select(".documents");

      certificatesIcon.classed("clickable", true);
      documentsIcon.classed("clickable", true);
      certificatesIcon.on("click", () => {
        this.setSelectedCertificates(location.allSelectedCertificates, location.name);
      });

      documentsIcon.on("click", () => {
        this.setSelectedDocuments(location.allSelectedDocuments, location.name);
        const record = this.locationsRulesetsRecords?.find((r) => r.uri.includes(location.id));

        this.setMissingDocuments(record);
      });

      //Documents tooltips
      this.setLocationDocumentsTooltip(location, nodeElement);

      //Certificates tooltips
      this.setLocationCertificatesTooltip(location, nodeElement);

      this.setLocationTypesTooltips(nodeElement);

      //Remove location
      if (this.canRemove) {
        const removeButton = d3.select(nodeElement).select(".remove-button");

        removeButton.on("click", () => {
          this.remove.emit(locationId);
        });

        this.tippyService.create(
          nodeElement.querySelector(".remove-button"),
          '<div class="text-center">Remove location</div>',
          this.tooltipOptionsRemoveButton,
        );
      }
      //Location name click
      const name = d3.select(nodeElement).select(".name");

      name.on("click", async () => {
        const link = this.isInboundShared
          ? this.routerService.getSharedLocationLink(locationId, false, {
              organisationId: this.inboundSharedSenderOrgId,
            })
          : this.routerService.getLocationLink(locationId, false);

        await this.navigateOrOpenInNewTab(link);
      });
      //Organisation name click
      const organisation = d3.select(nodeElement).select(".organisation");

      organisation.on("click", async () => {
        await this.navigateOrOpenInNewTab(
          this.routerService.getOrganisationLink(location.organisationId, false),
        );
      });
    }

    // Center the graph
    this.scaleAndCenterGraph(`#${this.svgId}`);
  };

  private navigateOrOpenInNewTab = async (
    navigationParams: NavigationParams | string,
  ): Promise<void> => {
    if (this.isFullScreenMode) {
      this.routerService.openNewTab(navigationParams);
    } else {
      await this.routerService.navigate(navigationParams);
    }
  };

  private setLocationDocumentsTooltip = (location: any, nodeElement: any): void => {
    const documentsNode = nodeElement.querySelector(".documents");

    if (!documentsNode) {
      return;
    }
    if (documentsNode._tippy) {
      documentsNode._tippy.destroy();
    }
    if (location.filteredAndSelectedDocuments?.length) {
      this.tippyService.create(
        documentsNode,
        this.getCustomTooltip(location, "Documents"),
        this.tooltipOptionsAttachments,
      );
    }
  };

  private setLocationCertificatesTooltip = (location: any, nodeElement: any): void => {
    const certificatesNode = nodeElement.querySelector(".certificates");

    if (!certificatesNode) {
      return;
    }
    if (certificatesNode._tippy) {
      certificatesNode._tippy.destroy();
    }
    if (location.filteredAndSelectedCertificates?.length) {
      this.tippyService.create(
        certificatesNode,
        this.getCustomTooltip(location, "Certificates"),
        this.tooltipOptionsAttachments,
      );
    }
  };

  private getCustomTooltip = (location: any, type: string): string => {
    let result = `<div class="node-tooltip"><div class="text-center">${type}</div><ul>`;

    switch (type) {
      case "Documents":
        for (const doc of location.filteredAndSelectedDocuments) {
          result += `<li><b>${doc.type?.name ?? "N/A"}:</b> ${doc.name}</li>`;
        }
        break;
      case "Certificates":
        for (const cert of location.filteredAndSelectedCertificates) {
          result += `<li><b>${cert.standard?.name ?? "N/A"}${
            cert.standardType ? ` ${cert.standardType.fullName}` : ""
          }:</b> ${cert.number}</li>`;
        }
        break;
    }

    return `${result}</ul></div>`;
  };
}
