import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  input,
  OnDestroy,
  signal,
  untracked,
} from "@angular/core";

import { ColDef } from "ag-grid-community";
import {
  DateCellRendererComponent,
  EnumsCellRendererComponent,
  LinkCellRendererComponent,
  RulesetIconNameCellRendererComponent,
} from "src/app/shared/cell-renderers";
import { RecordStateEnum, ResourceTypeEnum, TableEnum } from "src/app/shared/enums";
import { IDelivery, IDeliveryExtended } from "src/app/shared/interfaces";
import { DeliveriesService, LocationsService } from "src/app/shared/services";
import { ColumnUtils, CommonUtils } from "src/app/shared/utils";

import { SlideOverlayPageService } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.service";

import { TableWithRulesetsClass } from "@shared/classes/table-with-rulesets.class";
import { RouterService } from "@shared/services/router.service";

@Component({
  selector: "app-deliveries-table",
  templateUrl: "./deliveries-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveriesTableComponent
  extends TableWithRulesetsClass<IDeliveryExtended>
  implements OnDestroy
{
  deliveries = input<IDelivery[]>(null);

  deliveriesIds = input<string[]>(null);

  areButtonsEnabled = input<boolean>(true);

  isSearchEnabled = input<boolean>(true);

  isRecordStateFilterEnabled = input<boolean>(true);

  isPaginatorEnabled = input<boolean>(true);

  isFixedBottomPaginator = input<boolean>(false);

  recordState = input<RecordStateEnum>(RecordStateEnum.ALL);

  table = input<TableEnum>(TableEnum.DELIVERIES);

  isSaveTableState = input<boolean>(false);

  columns = input<string[]>([
    "recordState",
    "deliveryId",
    "from.name",
    "to.name",
    "status",
    "sent",
    "delivered",
    "tags",
  ]);

  isLoading = signal<boolean>(true);

  columnDefs = computed<ColDef[]>(() => this.setColumnDefs());

  override shouldGetRulesets = computed(
    () =>
      this.columns().includes("deliveryIdWithRulesets") &&
      this.authenticationService.isRegularUser(),
  );

  override resourceType = ResourceTypeEnum.DELIVERY;

  constructor(
    private routerService: RouterService,
    private deliveriesService: DeliveriesService,
    private locationsService: LocationsService,
    private overlay: SlideOverlayPageService,
  ) {
    super();

    this.subscriptions.add(
      this.overlay.refreshTable$.subscribe((newDelivery: IDelivery) => {
        if ("deliveryId" in newDelivery) {
          this.newRecord = newDelivery;
        }
        this.getAll();
      }),
    );
    effect(async () => {
      this.deliveries();
      untracked(async () => this.getAll());
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onViewDetails = async (row: IDeliveryExtended): Promise<void> => {
    if (!this.areButtonsEnabled()) {
      return;
    }

    await this.routerService.navigate(this.routerService.getDeliveryLink(row.id));
  };

  private setColumnDefs(): ColDef[] {
    const columnDefs: ColDef[] = [
      ColumnUtils.recordState(),
      {
        colId: "deliveryId",
        headerName: "ID",
        field: "deliveryId",
        lockVisible: true,
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteIdParam: "id",
          linkRouteFn: this.routerService.getDeliveryLink,
        },
        suppressSizeToFit: true,
      },
      {
        colId: "deliveryIdWithRulesets",
        headerName: "ID",
        field: "deliveryId",
        lockVisible: true,
        cellClass: "container-flex-left",
        cellRenderer: RulesetIconNameCellRendererComponent,
        cellRendererParams: {
          rulesetRecordsSubject: this.rulesetRecordsSubject,
          iconTooltip: this.getRulesetIconTooltip(),
          linkRouteIdParam: "id",
          linkRouteFn: this.routerService.getDeliveryLink,
        },
        suppressSizeToFit: true,
      },
      {
        headerName: "From",
        field: "from.name",
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteIdParam: "from.id",
          linkRouteFn: this.routerService.getLocationLink,
        },
      },
      {
        headerName: "To",
        field: "to.name",
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteIdParam: "to.id",
          linkRouteFn: this.routerService.getLocationLink,
        },
      },
      { headerName: "Status", field: "status", cellRenderer: EnumsCellRendererComponent },
      {
        headerName: "Sent",
        field: "sent",
        cellRenderer: DateCellRendererComponent,
      },
      {
        headerName: "Delivered",
        field: "delivered",
        cellRenderer: DateCellRendererComponent,
      },
    ];

    if (this.authenticationService.isRegularUser()) {
      columnDefs.push(ColumnUtils.tags("Tags"));
    }

    return CommonUtils.getVisibleColumnDefs(columnDefs, this.columns());
  }

  private getParsedRowData = async (deliveries: any[]): Promise<IDeliveryExtended[]> => {
    return await Promise.all(
      deliveries.map(async (delivery) => {
        if (typeof delivery.from !== "string" && typeof delivery.to !== "string") {
          return delivery;
        }

        const fromId = CommonUtils.getUriId(delivery.from);
        const locationFrom = await this.locationsService.get(fromId);

        const toId = CommonUtils.getUriId(delivery.to);
        const locationTo = await this.locationsService.get(toId);

        return {
          ...delivery,
          from: locationFrom,
          to: locationTo,
        };
      }),
    );
  };

  private async getAll(): Promise<void> {
    this.isLoading.set(true);

    const deliveries = this.deliveries();
    const deliveriesIds = this.deliveriesIds();
    const columnDefs = this.columnDefs();

    const tagsFieldPresent = columnDefs.some((c) => c.field === "tags");
    const fieldsToInclude = [];

    if (tagsFieldPresent) {
      fieldsToInclude.push("TAGS");
    }

    if (deliveries) {
      this.rowData.set(await this.getParsedRowData(deliveries));
    } else if (deliveriesIds) {
      try {
        const rowData = await this.deliveriesService.getByIdsGraphQL(
          deliveriesIds,
          undefined,
          fieldsToInclude,
        );

        this.rowData.set(rowData);
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    } else {
      try {
        const rowData = await this.deliveriesService.getAllGraphQL(
          undefined,
          undefined,
          fieldsToInclude,
        );

        this.rowData.set(rowData);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
    this.isLoading.set(false);
  }
}
