@if (enableViewMode) {
  <iov-form-input-readonly
    [label]="label"
    [value]="innerText"
    [isTextarea]="isTextarea"
    [isHTML]="isHTML"
    [copyMode]="viewModeCopyMode"
  />
} @else {
  @if (label) {
    <iov-form-label [label]="label" />
  }

  <div
    [attr.data-placeholder]="placeholder"
    #editableDiv
    [contentEditable]="!isDisabled()"
    class="editable-div break-word"
  >
    @if (innerText && isDisabled()) {
      {{ innerText }}
    }
  </div>

  <iov-form-hint
    [class]="hintErrorClass"
    [errors]="ngControl?.errors"
    [isDisabled]="isDisabled()"
    [isOptional]="isOptional"
    [showError]="hasValidationError"
  />
}
