import { ChangeDetectionStrategy, Component } from "@angular/core";

import { AuthenticationService } from "src/app/shared/services";

@Component({
  templateUrl: "./logout.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutComponent {
  constructor(private authenticationService: AuthenticationService) {
    this.authenticationService.logout();
  }
}
