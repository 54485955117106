<app-drag-drop-list-container
  placeholder="Please add indicators and categories"
  [enableViewMode]="overlay.viewMode()"
  (drop)="onDrop($event)"
>
  @for (item of controls; track item; let index = $index) {
    <app-drag-drop-list-item
      [isDeleteDisabled]="isDeleteButtonDisabled(index)"
      [deleteDisabledText]="validationMessage"
      (moveUp)="onMoveUp(index)"
      (moveDown)="onMoveDown(index)"
      (remove)="onRemove(index)"
    >
      <div [formGroup]="item">
        <iov-input
          formControlName="name"
          class="name-field"
          [label]="item.value._title"
          [enableViewMode]="overlay.viewMode()"
          [viewModeCopyMode]="copyModeEnum.NONE"
        />

        @if (item.value._type === "indicator") {
          <iov-editable-div
            class="bordered"
            placeholder="Type your guidance here…"
            formControlName="guidance"
            label="Guidance"
            [isTextarea]="true"
            [isResizable]="true"
            [isOptional]="true"
            [isHTML]="true"
            [enableViewMode]="overlay.viewMode()"
            [viewModeCopyMode]="copyModeEnum.NONE"
          />
        }
      </div>
    </app-drag-drop-list-item>
  }
</app-drag-drop-list-container>

<ng-template #controller>
  @if (canAddModifyEntities) {
    @if (overlay.editMode()) {
      <iov-button type="ghost" (pressed)="add('indicator')">
        <mat-icon class="action-icon" icon-left>add</mat-icon>
        Add indicator
      </iov-button>
      <iov-button type="ghost" (pressed)="add('category')">
        <mat-icon class="action-icon" icon-left>add</mat-icon>
        Add category
      </iov-button>
      <iov-button type="ghost" (pressed)="overlay.enableViewMode(false)">Cancel</iov-button>
      <iov-button
        [disabled]="isSubmitButtonDisabled"
        [tp]="validationMessage"
        [tpIsLazy]="true"
        [tpIsEnabled]="isSubmitButtonDisabled"
        (pressed)="overlay.save()"
      >
        Save
      </iov-button>
    } @else {
      <iov-button type="ghost" (pressed)="overlay.enableEditMode()" [disabled]="overlay.loading()">
        Edit
      </iov-button>
    }
  }
</ng-template>
