import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

import { SlideOverlayContentComponent } from "@design-makeover/components/overlay/slide-overlay-content/slide-overlay-content.component";
import { SlideOverlayPageClass } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.class";
import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { DocumentOverlayService } from "@components/documents/pages/document-overlay/document-overlay.service";
import { CommonConstants } from "@shared/constants";
import {
  AttachmentTypeEnum,
  EntityTypeEnum,
  OverlayTabEnum,
  RecordStateEnum,
  RoutingEnum,
  StartEndEnum,
} from "@shared/enums";
import {
  IAttachment,
  IDocument,
  IDocumentPayload,
  IDocumentType,
  IName,
  IRecordState,
  ISelectOption,
} from "@shared/interfaces";
import {
  AttachmentsService,
  DocumentsService,
  DocumentTypesService,
  DownloadDocumentsService,
} from "@shared/services";
import { CommonUtils, FileUtils, FormUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

@Component({
  selector: "app-document-overlay",
  templateUrl: "./document-overlay.component.html",
  styleUrls: ["./document-overlay.component.scss", "../document-overlay-common.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DocumentOverlayComponent extends SlideOverlayPageClass implements OnInit, OnDestroy {
  fileExtension: string;

  fileName: string;

  selectedAttachments: IAttachment[];

  formGroup: UntypedFormGroup;

  documentTypesOptions: ISelectOption[];

  override menuItems = signal(
    new Map([
      [OverlayTabEnum.DETAILS, { isEnabled: true }],
      [OverlayTabEnum.ATTACHED_TO, { isEnabled: false }],
      [OverlayTabEnum.COMMENTS, { isEnabled: false, isHidden: !this.isRegularUser }],
    ]),
  );

  override element: IDocument;

  override entityType = EntityTypeEnum.DOCUMENTS;

  @ViewChild("slideOverlayContent") override slideOverlayContent: SlideOverlayContentComponent;

  constructor(
    public documentOverlayService: DocumentOverlayService,
    private notificationService: NotificationService,
    private documentsService: DocumentsService,
    private downloadDocumentsService: DownloadDocumentsService,
    private documentTypesService: DocumentTypesService,
    private attachmentsService: AttachmentsService,
  ) {
    super();
  }

  get canViewContent(): boolean {
    return this.element.canView;
  }

  get hasContent(): boolean {
    return !!this.element.contentType;
  }

  override get isSubmitButtonDisabled(): boolean {
    return this.formGroup?.invalid || this.formGroup?.pending || !this.hasFormValuesChanged;
  }

  async ngOnInit(): Promise<void> {
    this.overlay.showLoading();

    if (!this.isOnCorrectOverlay(RoutingEnum.OVERLAY_DOCUMENT)) {
      return;
    }

    this.setCountersToLoadingState();

    await this.reloadElement(this.recordId);
    await this.setMenuItemFromURLParam();
    this.loadCounters();
  }

  loadCounters(): void {
    if (this.recordId) {
      this.loadComments();
    }
  }

  setCountersEmptyState() {
    this.documentOverlayService.setCountersEmptyState();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.setCountersEmptyState();
  }

  setCountersToLoadingState() {
    this.selectedAttachments = null;
    this.documentOverlayService.setCountersToLoadingState();
  }

  loadComments(): void {
    this.documentOverlayService.loadCommentCounter(this.entityUri);
  }

  async downloadContent(): Promise<void> {
    if (!this.element.contentType) {
      return;
    }

    this.downloadDocumentsService.add({
      documentId: this.element.id,
      documentName: this.element.name,
    });
  }

  async viewContent(): Promise<void> {
    if (!this.element.contentType) {
      return;
    }
    this.routerService.openNewTab(`${RoutingEnum.DOCUMENTS_VIEW}/${this.element.id}`);
  }

  async onNameClick(): Promise<void> {
    if (!this.element.contentType) {
      return;
    }

    this.canViewContent ? await this.viewContent() : await this.downloadContent();
  }

  contentTooltip(actionType: "View" | "Download"): string {
    return this.hasContent ? `${actionType} document` : CommonConstants.DOCUMENT_NO_CONTENT_TEXT;
  }

  override setupForm(): void {
    const typeId = CommonUtils.getUriId(this.element?.type);
    const documentType = this.documentTypesOptions?.find((s) => s.value === typeId);

    const entityExistsValidatorArgs = {
      fileExtension: this.fileExtension.toLowerCase(),
    };

    this.formGroup = new UntypedFormGroup(
      {
        name: new UntypedFormControl(
          this.fileName ?? null,
          [CustomValidators.required],
          [
            CustomValidators.entityAlreadyExists(
              this.documentsService,
              this.element.id,
              entityExistsValidatorArgs,
            ),
          ],
        ),
        type: new UntypedFormControl(documentType ?? null, [CustomValidators.required]),
        issuance: new UntypedFormControl(this.element?.issuance ?? null),
        validityStart: new UntypedFormControl(this.element?.validityStart ?? null),
        validityEnd: new UntypedFormControl(this.element?.validityEnd ?? null),
      },
      CustomValidators.dateRules([
        {
          name: "issuance",
          rules: [
            {
              type: ">",
              target: "validityStart",
              errorMessage: "Cannot be later than valid from date",
            },
            {
              type: ">",
              target: "validityEnd",
              errorMessage: "Cannot be later than valid to date",
            },
          ],
        },
        {
          name: "validityStart",
          rules: [
            { type: "<", target: "issuance", errorMessage: "Cannot be before than issuance date" },
            {
              type: ">",
              target: "validityEnd",
              errorMessage: "Cannot be later than valid to date",
            },
          ],
        },
        {
          name: "validityEnd",
          rules: [
            { type: "<", target: "issuance", errorMessage: "Cannot be before than issuance date" },
            {
              type: "<",
              target: "validityStart",
              errorMessage: "Cannot be before than valid from date",
            },
          ],
        },
      ]),
    );

    this.initialFormValue = this.formGroup.value;
    this.hasFormValuesChanged = false;

    this.subscriptions.add(
      this.formGroup.valueChanges.subscribe(() => {
        this.hasFormValuesChanged = this.hasInitialFormValueChanged(this.formGroup.value);
      }),
    );
  }

  override async save(): Promise<boolean> {
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);
      this.notificationService.showError(CommonConstants.FILL_REQUIRED_FIELDS_MSG);

      return false;
    }

    const isNewType = !this.formGroup.controls["type"].value.value;

    if (isNewType) {
      await this.createNewDocumentType();
    }

    const { name, issuance, validityStart, validityEnd, type } = this.formGroup.value;

    const payload: IDocumentPayload = {
      name: name + `.${this.fileExtension}`,
      issuance: FormUtils.getDateValueForPayload(issuance),
      validityStart: FormUtils.getDateValueForPayload(validityStart),
      validityEnd: FormUtils.getDateValueForPayload(validityEnd, StartEndEnum.END),
      type: `/organisations/${this.activeOrganisationId}/document-types/${type.value}`,
    };

    try {
      this.element = await this.documentsService.createOrUpdate(payload, this.element?.id);
      this.notificationService.showSuccess("Document modified");
      this.hasFormValuesChanged = false;
      if (isNewType) {
        this.documentTypesOptions = await this.loadDocumentTypesOptions();
      }

      return true;
    } catch (error) {
      this.notificationService.showError(error);

      return false;
    }
  }

  override async afterSave(isSaveOnly: boolean): Promise<void> {
    if (isSaveOnly) {
      if (!this.isEditing()) {
        await this.routerService.navigate(this.routerService.getDocumentLink(this.element.id), {
          replaceUrl: true,
        });
      }
    }
  }

  protected override async archiveRecord(id: string, payload: IRecordState): Promise<void> {
    await this.documentsService.setRecordState(payload, id);
  }

  protected override async deleteRecord(id: string): Promise<void> {
    await this.documentsService.delete(id);
  }

  protected override async reloadElement(id: string): Promise<void> {
    try {
      const element = await this.documentsService.get(id);

      this.element = element;
      this.setEditMode();

      await this.loadAttachments();

      this.documentTypesOptions = await this.loadDocumentTypesOptions();

      this.fileExtension = FileUtils.getFileExtension(element.name);
      this.fileName = FileUtils.getFileNameWithoutExtension(element.name);

      CommonUtils.setDocumentsCanView([this.element]);

      this.setupForm();
      this.overlay.dismissLoading();
    } catch (error) {
      this.notification.showError(error);
    }
  }

  private async loadAttachments(): Promise<void> {
    this.selectedAttachments = await this.attachmentsService.getAll(
      AttachmentTypeEnum.DOCUMENT,
      null,
      `/organisations/${this.activeOrganisationId}/documents/${this.element.id}`,
    );
  }

  private async createNewDocumentType(): Promise<void> {
    const typeLabel = this.formGroup.controls["type"].value.label.trim().toLowerCase();
    const existingType = this.documentTypesOptions.find(
      (t) => t.label.trim().toLowerCase() === typeLabel,
    );

    if (existingType?.value) {
      this.formGroup.controls["type"].setValue(existingType);

      return;
    }

    try {
      const payload: IName = { name: this.formGroup.controls["type"].value.label.trim() };
      const response: IDocumentType = await this.documentTypesService.createOrUpdate(payload);
      const newDocumentType: ISelectOption = { label: response.name, value: response.id };

      this.formGroup.controls["type"].setValue(newDocumentType);
    } catch (error) {
      this.notificationService.showError(error);
    }
  }

  private async loadDocumentTypes(): Promise<IDocumentType[]> {
    return await this.documentTypesService.getAll();
  }

  private async loadDocumentTypesOptions(
    selectedDocumentTypeId?: string,
  ): Promise<ISelectOption[]> {
    return (await this.loadDocumentTypes())
      .filter(
        (p) =>
          p.recordState === RecordStateEnum.ACTIVE ||
          (selectedDocumentTypeId && p.id === selectedDocumentTypeId),
      )
      .map((type) => ({ label: type.name, value: type.id }));
  }
}
