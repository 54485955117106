import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, Inject, OnInit, signal } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { CustomFieldsResourceTypeEnum, CustomFieldsTypeEnum } from "src/app/shared/enums";
import { ICustomFieldPayload, ISelectOption } from "src/app/shared/interfaces";
import { CustomFieldsService } from "src/app/shared/services";
import { CommonUtils, FormUtils } from "src/app/shared/utils";
import { CustomValidators } from "src/app/shared/validators";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { CommonConstants } from "@shared/constants";

@Component({
  templateUrl: "./edit-custom-field-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditCustomFieldDialogComponent implements OnInit {
  public formGroup: UntypedFormGroup = null;

  public isLoading = signal(true);

  public isEditing = signal(false);

  public readonly typeOptions: ISelectOption[] = Object.values(CustomFieldsTypeEnum).map(
    (value: any) => ({
      label: CommonUtils.capitaliseFirstLetter(CustomFieldsTypeEnum[value]),
      value,
    }),
  );

  public readonly resourceTypeOptions: ISelectOption[] = Object.values(
    CustomFieldsResourceTypeEnum,
  ).map((value: any) => ({
    label: CommonUtils.getParsedCustomFieldResourceType(CustomFieldsResourceTypeEnum[value]),
    value,
  }));

  constructor(
    public dialogRef: MatDialogRef<EditCustomFieldDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { customField?: any },
    private customFieldsService: CustomFieldsService,
    private notificationService: NotificationService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.isEditing.set(!!this.data?.customField?.id);

    let typeValue: ISelectOption = this.typeOptions[0];
    let resourceTypesValue: ISelectOption = null;

    if (this.isEditing()) {
      typeValue = this.typeOptions.find((t) => t.value === this.data.customField.type);
      resourceTypesValue = this.data.customField.resourceTypes.map(
        (parsedResourceTypeLabel: string) => ({
          label: parsedResourceTypeLabel,
          value: this.resourceTypeOptions.find((r) => r.label === parsedResourceTypeLabel).value,
        }),
      );
    }

    const entityExistsValidatorArgs: any = {
      searchPropertyName: "label",
      searchPropertyErrorDisplayName: "label",
    };

    this.formGroup = new UntypedFormGroup({
      type: new UntypedFormControl({ value: typeValue, disabled: this.isEditing() }, [
        CustomValidators.required,
      ]),
      label: new UntypedFormControl(
        this.isEditing() ? this.data.customField.label : null,
        [CustomValidators.required],
        [
          CustomValidators.entityAlreadyExists(
            this.customFieldsService,
            this.data?.customField?.id ?? null,
            entityExistsValidatorArgs,
          ),
        ],
      ),
      resourceTypes: new UntypedFormControl(resourceTypesValue, [CustomValidators.required]),
    });

    this.isLoading.set(false);
  }

  public isSubmitButtonDisabled = (): boolean => {
    if (!this.isEditing()) {
      return false;
    }

    return this.formGroup.pending;
  };

  public onSubmit = async (): Promise<void> => {
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);
      this.notificationService.showError(CommonConstants.FILL_REQUIRED_FIELDS_MSG);

      return;
    }
    this.isLoading.set(true);

    const payload: ICustomFieldPayload = {
      id: this.isEditing() ? this.data.customField.id : undefined,
      type: this.formGroup.controls["type"].value.value,
      label: this.formGroup.controls["label"].value.trim(),
      resourceTypes: this.formGroup.controls["resourceTypes"].value.map((r) => r.value),
    };

    await this.customFieldsService
      .createOrUpdate(payload, this.data?.customField?.id)
      .then(() => {
        this.notificationService.showSuccess(
          `Custom field ${this.isEditing() ? "modified" : "created"}`,
        );
        this.onClose(true);
      })
      .catch((error: HttpErrorResponse) => {
        this.notificationService.showError(error);
      })
      .finally(() => {
        this.isLoading.set(false);
      });
  };

  public onClose = (hasSaved = false): void => {
    this.dialogRef.close({ hasSaved });
  };
}
