import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Router } from "@angular/router";

import { RoutingEnum } from "src/app/shared/enums";

import { AuthenticationService } from "@shared/services";

@Component({
  templateUrl: "./user-settings.component.html",
  styleUrls: ["./user-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSettingsComponent {
  public readonly organisationsSettings = [
    {
      route: RoutingEnum.USER_SETTINGS_MEMBERSHIPS,
      icon: "group",
      title: "Memberships",
      description: "Manage your org memberships",
    },
  ];

  public readonly apiSettings = [
    {
      route: RoutingEnum.USER_SETTINGS_API_KEYS,
      icon: "key",
      title: "API keys",
      description: "Manage your API keys",
    },
  ];

  constructor(
    private router: Router,
    public auth: AuthenticationService,
  ) {}

  public onGoToRoute = async (route: RoutingEnum): Promise<void> => {
    await this.router.navigate([`/${route}`]);
  };
}
