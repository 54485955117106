import { ChangeDetectionStrategy, Component, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { RecordStateEnum, RoutingEnum } from "src/app/shared/enums";

import { EditCustomFieldDialogComponent } from "..";
import { CustomFieldsTableComponent } from "../../shared";

@Component({
  templateUrl: "./settings-custom-fields.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsCustomFieldsComponent {
  @ViewChild("customFieldsTable") customFieldsTable: CustomFieldsTableComponent;

  public readonly routingEnum = RoutingEnum;

  public readonly recordStateEnum = RecordStateEnum;

  constructor(private dialog: MatDialog) {}

  public onAdd = async (): Promise<void> => {
    const dialogRef = this.dialog.open(EditCustomFieldDialogComponent);

    dialogRef.afterClosed().subscribe(async (result: { hasSaved: boolean }) => {
      if (result?.hasSaved) {
        await this.customFieldsTable.getAll();
      }
    });
  };
}
