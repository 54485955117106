<div [class]="class" [ngClass]="{ invalid: hasValidationErrors }" class="form-field-item">
  <iov-form-label [label]="label"></iov-form-label>

  <iov-form-input
    (blurEvent)="isInputFocused = false"
    (focusEvent)="isInputFocused = true"
    (keyUpEvent)="handleInputChange($event)"
    [autofocus]="autofocus"
    [autocomplete]="autocomplete"
    [disabled]="isDisabled || disabled"
    [hasValidationError]="hasValidationErrors"
    [inputType]="inputType"
    [placeholder]="label"
    [searchQuery]="textValue"
    [tpHideOnClick]="false"
    [tpHideOnEscape]="false"
    [tpIsVisible]="isTooltipVisible"
    [tp]="tooltipContent"
    tpClassName="tooltip-password"
    tpPlacement="bottom"
    tpTrigger="manual"
    [emitOnInput]="emitOnInput"
    tpVariation="whiteTooltipNoArrow"
  >
    <mat-icon
      (click)="toggleInputVisibility()"
      [class.disabled]="isDisabled || disabled"
      [tp]="isInputObscure ? 'Show password' : 'Hide password'"
      class="clickable obscure-icon"
      suffix-icon
      tpPlacement="top-end"
    >
      {{ visibilityIcon }}
    </mat-icon>
  </iov-form-input>
  <iov-form-hint
    [errors]="filteredValidationErrors"
    [showError]="hasFilteredValidationErrors"
  ></iov-form-hint>
</div>

<ng-template #tooltipContent>
  @if (hasTooltipValidationErrors) {
    @for (requirement of passwordRequirements; track requirement.errorId) {
      <div class="requirement">
        @if (ngControl.errors[requirement.errorId]) {
          <mat-icon [@fadeInOut] class="requirement-icon" svgIcon="action_pending"></mat-icon>
        } @else {
          <mat-icon [@fadeInOut] class="requirement-icon" svgIcon="action_completed"></mat-icon>
        }
        {{ requirement.description }}
      </div>
    }
  }
</ng-template>
