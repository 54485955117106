<div class="container-space-between">
  <app-page-title
    [goBackRoute]="routingEnum.ADMIN"
    title="Rulesets"
    backText="Back to Admin"
  ></app-page-title>
  @if (userCanAddOrModifyRulesets) {
    <iov-button-group>
      <iov-button (pressed)="onAdd()">
        <mat-icon icon-left>add</mat-icon>
        Add new
      </iov-button>
    </iov-button-group>
  }
</div>
<mat-tab-group
  animationDuration="0ms"
  mat-align-tabs="start"
  [selectedIndex]="selectedTabIndex"
  (selectedIndexChange)="selectedTabIndex = $event"
  [mat-stretch-tabs]="false"
>
  <mat-tab>
    <ng-template mat-tab-label>Locations ({{ rulesetsLocationsTable.totalElements }})</ng-template>
    <app-rulesets-table
      [resourceType]="resourceTypeEnum.LOCATION"
      #rulesetsLocationsTable
      [isFixedBottomPaginator]="true"
    ></app-rulesets-table>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      Deliveries ({{ rulesetsDeliveriesTable.totalElements }})
    </ng-template>
    <app-rulesets-table
      [resourceType]="resourceTypeEnum.DELIVERY"
      #rulesetsDeliveriesTable
      [isFixedBottomPaginator]="true"
    ></app-rulesets-table>
  </mat-tab>
</mat-tab-group>
