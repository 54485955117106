import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from "@angular/forms";

@Component({
  selector: "iov-button-toggle",
  templateUrl: "./button-toggle.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ButtonToggleComponent),
      multi: true,
    },
  ],
})
export class ButtonToggleComponent implements ControlValueAccessor, OnInit {
  @Input({ transform: booleanAttribute }) disabled: boolean = false;

  @Input({ transform: booleanAttribute }) checkValue: boolean;

  @Output() changeEvent: EventEmitter<boolean> = new EventEmitter();

  ngControl: NgControl;

  isDisabled: boolean;

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl, null);
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  writeValue(value: boolean): void {
    this.checkValue = value;
  }

  registerOnChange(fn: (_: unknown | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  handleChange(): void {
    const newValue = !this.checkValue;

    this.changeEvent.emit(newValue);
    this.checkValue = newValue;
    this.onChange(newValue);
    this.onTouched();
  }

  private onChange: (_: unknown | null) => void = () => {};

  private onTouched: () => void = () => {};
}
