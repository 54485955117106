import {
  AfterViewInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { ValidationErrors } from "@angular/forms";

import { InputType } from "@design-makeover/components/form/form-input/form-input.model";

@Component({
  selector: "iov-form-input",
  templateUrl: "./form-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormInputComponent implements AfterViewInit {
  @ViewChild("input") input: ElementRef<HTMLInputElement>;

  @Input() class: string;

  @Input() inputType: InputType = "text";

  @Input() placeholder: string;

  @Input() searchQuery: string = "";

  @Input({ transform: booleanAttribute }) showClear: boolean;

  @Input() autocomplete: string | boolean = "off";

  @Input({ transform: booleanAttribute }) autofocus: boolean;

  @Input({ transform: booleanAttribute }) disabled: boolean;

  @Input({ transform: booleanAttribute }) hasValidationError: boolean;

  @Input({ transform: booleanAttribute }) emitOnInput: boolean;

  @Input() displayErrorsInTooltip: boolean = false;

  @Input() errors: ValidationErrors;

  @Output() keyUpEvent: EventEmitter<string> = new EventEmitter<string>();

  @Output() focusEvent: EventEmitter<string> = new EventEmitter<string>();

  @Output() blurEvent: EventEmitter<void> = new EventEmitter<void>();

  @Output() clearEvent: EventEmitter<void> = new EventEmitter();

  onKeyUp(event: KeyboardEvent): void {
    const inputElement: HTMLInputElement = event.target as HTMLInputElement;

    this.keyUpEvent.emit(inputElement.value);
  }

  onSearch(event: Event): void {
    const inputElement: HTMLInputElement = event.target as HTMLInputElement;

    this.keyUpEvent.emit(inputElement.value);
  }

  onClear(): void {
    this.clearEvent.emit();
  }

  onBlur(): void {
    this.blurEvent.emit();
  }

  onFocus(): void {
    this.focusEvent.emit();
  }

  ngAfterViewInit(): void {
    if (this.autofocus) {
      this.input.nativeElement.focus();
    }
  }

  onInput(event: Event): void {
    const inputElement: HTMLInputElement = event.target as HTMLInputElement;

    if (inputElement.classList.contains("cdk-text-field-autofill-monitored")) {
      this.keyUpEvent.emit(inputElement.value);
    }
  }

  get errorTooltip(): string {
    if (!this.displayErrorsInTooltip || !this.errors) {
      return null;
    }

    return Object.values(this.errors)
      .map((error) => (typeof error === "string" && !error.endsWith(".") ? `${error}.` : error))
      .join(" ");
  }
}
