import { ChangeDetectionStrategy, Component } from "@angular/core";

import { EntityTypeEnum } from "@shared/enums";
import { AuthenticationService } from "@shared/services";
import { RouterService } from "@shared/services/router.service";
import { CommonUtils } from "@shared/utils";

@Component({
  templateUrl: "./shared-link.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedLinkComponent {
  constructor(
    private authenticationService: AuthenticationService,
    private routerService: RouterService,
  ) {
    const currentUrl = document.location.href;
    const url = new URL(currentUrl);
    const viewParam = url.searchParams.get("view");
    const idParam = url.searchParams.get("id");

    if (viewParam && idParam) {
      this.routerService.navigateByType(
        CommonUtils.pluraliseEntity(viewParam) as EntityTypeEnum,
        idParam,
      );
    } else {
      this.authenticationService.goToMainShareLink();
    }
  }
}
