import { Inject, Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { APP_CONFIG } from "@shared/tokens";

import { ApiService } from "./api.service";
import { CommonConstants } from "../../constants";
import { CustomFieldsResourceTypeEnum, RecordStateEnum } from "../../enums";
import {
  ICustomField,
  ICustomFieldPayload,
  IPageableContent,
  IRecordState,
  IConfig,
} from "../../interfaces";
import { FormUtils } from "../../utils";
import { AuthenticationService } from "../authentication.service";

@Injectable({
  providedIn: "root",
})
export class CustomFieldsService {
  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {}

  private getBaseUrl = (): string =>
    `${this.environment.baseUrl}organisations/${this.authenticationService.getActiveOrganisationId()}/custom-field-definitions`;

  public getPage = async (
    label: string = undefined,
    resourceType: CustomFieldsResourceTypeEnum = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
    recordState: RecordStateEnum = undefined,
  ): Promise<IPageableContent<ICustomField>> => {
    const url = `${this.getBaseUrl()}?${FormUtils.addUrlParams({ label, resourceType, size, page, sort, recordState })}`;

    return await this.apiService.get<IPageableContent<ICustomField>>(url).toPromise();
  };

  public getPageSubscription = (
    label: string = undefined,
    resourceType: CustomFieldsResourceTypeEnum = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
    recordState: RecordStateEnum = undefined,
  ): Observable<IPageableContent<ICustomField>> => {
    const url = `${this.getBaseUrl()}?${FormUtils.addUrlParams({ label, resourceType, size, page, sort, recordState })}`;

    return this.apiService.get<IPageableContent<ICustomField>>(url);
  };

  public async getAll(
    resourceType: CustomFieldsResourceTypeEnum = undefined,
    recordState: RecordStateEnum = undefined,
  ): Promise<ICustomField[]> {
    const result: ICustomField[] = [];
    let page = 0;
    let hasNextPage: boolean = true;

    while (hasNextPage) {
      const {
        content,
        page: { totalElements },
      } = await this.getPage(
        undefined,
        resourceType,
        CommonConstants.MAX_API_GET_ITEMS_SIZE,
        page,
        undefined,
        recordState,
      );

      result.push(...content);

      page++;
      hasNextPage = result.length < totalElements;
    }

    return result;
  }

  public get = async (id: string): Promise<ICustomField> =>
    await this.apiService.get<ICustomField>(`${this.getBaseUrl()}/${id}`).toPromise();

  public delete = async (id: string): Promise<void> =>
    await this.apiService.delete<void>(`${this.getBaseUrl()}/${id}`).toPromise();

  public createOrUpdate = async (
    payload: ICustomFieldPayload,
    id?: string,
  ): Promise<ICustomField> => {
    if (id) {
      return await this.apiService
        .put<ICustomField>(`${this.getBaseUrl()}/${id}`, payload)
        .toPromise();
    } else {
      return await this.apiService.post<ICustomField>(`${this.getBaseUrl()}`, payload).toPromise();
    }
  };

  public setRecordState = async (payload: IRecordState, id: string): Promise<ICustomField> =>
    await this.apiService.put<ICustomField>(`${this.getBaseUrl()}/${id}`, payload).toPromise();
}
