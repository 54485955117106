import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  ViewChild,
  forwardRef,
  inject,
  signal,
} from "@angular/core";
import { NG_VALUE_ACCESSOR, NgControl } from "@angular/forms";

import { CopyModeEnum } from "@components/shared/value-and-copy-button/value-and-copy-button.model";

@Component({
  selector: "iov-editable-div",
  templateUrl: "./editable-div.component.html",
  styleUrl: "./editable-div.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditableDivComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableDivComponent implements OnInit, OnChanges {
  private readonly cd = inject(ChangeDetectorRef);

  @ViewChild("editableDiv", { static: false }) editableDiv!: ElementRef<HTMLDivElement>;

  @Input()
  hintErrorClass: string;

  @Input()
  placeholder: string = "Type your comment here…";

  @Input()
  isOptional: boolean;

  @Input()
  @HostBinding("class.resizable")
  isResizable = false;

  @Input()
  @HostBinding("class.textarea")
  isTextarea = false;

  @Input()
  enableViewMode = false;

  @Input()
  label: string;

  @Input()
  isHTML = false;

  @Input()
  viewModeCopyMode = CopyModeEnum.CONTAINER;

  private onChange: (value: string) => void = () => {};

  private onTouched: () => void = () => {};

  isCollapsed: boolean = true;

  showReadMore: boolean;

  public isDisabled = signal<boolean>(false);

  innerText: string = "";

  ngControl: NgControl;

  get hasValidationError(): boolean {
    return this.ngControl?.errors && (this.ngControl?.dirty || this.ngControl?.touched);
  }

  constructor(
    private renderer: Renderer2,
    private injector: Injector,
  ) {}

  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl, null);

    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnChanges(): void {
    if (!this.enableViewMode) {
      this.cd.detectChanges();
      this.setInnerText();
    }
  }

  writeValue(value: string): void {
    this.innerText = value;
    this.editableDiv && this.setInnerText();
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled.set(isDisabled);
    if (this.editableDiv) {
      this.renderer.setProperty(this.editableDiv.nativeElement, "contentEditable", !isDisabled);
    }
  }

  @HostListener("input", ["$event"])
  onInput(event: Event): void {
    const target = event.target as HTMLDivElement;
    const value = this.isHTML ? target.innerHTML : target.innerText;

    this.onChange(value);
    this.onTouched();
  }

  @HostListener("paste", ["$event"])
  onPaste(event: ClipboardEvent): void {
    event.preventDefault();
    const clipboardData = event.clipboardData || window["clipboardData"];
    const text = clipboardData.getData("text/plain");
    const selection = window.getSelection();

    if (!selection.rangeCount) {
      return;
    }
    selection.deleteFromDocument();
    selection.getRangeAt(0).insertNode(document.createTextNode(text));
    selection.collapseToEnd();
    this.onChange(this.editableDiv.nativeElement.innerText);
    this.onTouched();
  }

  setfocus(): void {
    setTimeout(() => {
      this.editableDiv.nativeElement.focus();
    });
  }

  private setInnerText(): void {
    this.renderer.setProperty(
      this.editableDiv.nativeElement,
      this.isHTML ? "innerHTML" : "innerText",
      this.innerText,
    );
  }
}
