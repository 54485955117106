<div class="container-space-between">
  <app-page-title
    title="Memberships"
    backText="Back to User settings"
    hint="See and manage your memberships in organisations."
    [isGoBackUrlHistory]="true"
  ></app-page-title>
</div>

<app-memberships-table
  [isSaveTableState]="true"
  [isFixedBottomPaginator]="true"
></app-memberships-table>
