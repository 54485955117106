import { IProductExtended } from "@shared/interfaces";

export namespace ReportsEudrModel {
  export enum ActivityEnum {
    DOMESTIC = "domestic",
    IMPORT = "import",
    EXPORT = "export",
  }

  export enum ReportCustomFieldEnum {
    HS_CODE = "HS Code",
    DESCRIPTION = "Description",
  }

  export const areaCustomFieldLabel = "Area (ha)";
  export const noGPSDataValue = "None";

  export const requiredCustomFieldsNeedingValuesLabels = [ReportCustomFieldEnum.HS_CODE];

  export const reportCustomFieldLabels = [
    ...requiredCustomFieldsNeedingValuesLabels,
    ReportCustomFieldEnum.DESCRIPTION,
  ];

  export type requiredCustomFieldValue = string;
  export type RequiredCustomFieldValues = {
    [label in ReportCustomFieldEnum]: requiredCustomFieldValue;
  };

  export interface IProductWithDescription {
    product: IProductExtended;
    descriptionCustomFieldValue: string;
    customFieldValuesToGroup: string[];
  }

  export interface IHsCode {
    hscode: string | number;
    description: string;
  }

  export enum MaterialCustomFieldEnum {
    SCIENTIFIC_NAME = "Scientific name",
    COMMON_NAME = "Common name",
  }

  export const hsCodesJsonPath = "/assets/data/hs_codes.json";
}
