import {
  Component,
  inject,
  OnInit,
  ViewChild,
  HostListener,
  signal,
  ChangeDetectionStrategy,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";

import { StepperService } from "@design-makeover/components/stepper/stepper.service";
import { NotificationService } from "@design-makeover/services/notification/notification.service";

import {
  BulkAddItemsModel,
  BulkAddItemsModel as Model,
} from "@components/items/bulk-add-items/bulk-add-items.component.model";
import { BulkAddItemsService } from "@components/items/bulk-add-items/bulk-add-items.service";
import { BulkAddItemsEnterItemsComponent } from "@components/items/bulk-add-items/enter-items/bulk-add-items-enter-items.component";
import { ConfirmDialogComponent } from "@components/shared";
import { BulkAddSlideOverClass } from "@components/shared/bulk-add-slide-over/bulk-add-slide-over.class";
import { ConfirmDialogResponseEnum } from "@shared/enums";
import { IDelivery, ILocationExtended, IProduct, ISelectOption } from "@shared/interfaces";
import { AuthenticationService, LocationsService } from "@shared/services";
import { RouterService } from "@shared/services/router.service";
import { FormUtils } from "@shared/utils";

@Component({
  selector: "app-bulk-add-items",
  templateUrl: "./bulk-add-items.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkAddItemsComponent extends BulkAddSlideOverClass implements OnInit {
  @ViewChild("bulkAddItemsEnterItems")
  public bulkAddItemsEnterItemsComponent: BulkAddItemsEnterItemsComponent;

  public bulkAddItemsService: BulkAddItemsService = inject(BulkAddItemsService);

  private locationsService: LocationsService = inject(LocationsService);

  private authenticationService: AuthenticationService = inject(AuthenticationService);

  private notificationService: NotificationService = inject(NotificationService);

  private dialog: MatDialog = inject(MatDialog);

  public stepper: StepperService = inject(StepperService);

  private router: RouterService = inject(RouterService);

  public activeOrganisationId: string = this.authenticationService.getActiveOrganisationId();

  public isLoading = signal<boolean>(true);

  public title = signal<string>("");

  public displayDoneButton = signal<boolean>(false);

  public allLocations: ILocationExtended[] = [];

  private readonly fieldEnum = Model.FieldEnum;

  public readonly stepsEnum = Model.StepsEnum;

  @HostListener("window:beforeunload")
  canDeactivate(): boolean {
    const hasChanged = this.bulkAddItemsService.hasInitialFormValueChanged(
      this.formGroup.getRawValue(),
    );

    return !hasChanged;
  }

  public async ngOnInit(): Promise<void> {
    this.bulkAddItemsService.initializeProperties();

    await this.bulkAddItemsService.setDelivery();

    this.title.set(
      this.delivery ? `Bulk-add items to delivery ${this.delivery.deliveryId}` : "Bulk-add items",
    );

    [this.allLocations] = await Promise.all([
      this.getAllLocations(),
      this.bulkAddItemsService.getAllProducts(),
    ]);

    this.bulkAddItemsService.setupForm();

    this.isLoading.set(false);
  }

  public get delivery(): IDelivery {
    return this.bulkAddItemsService.delivery();
  }

  public override afterClose(): void {
    this.bulkAddService.closeSlideOver();

    if (this.delivery) {
      this.router.navigate(this.router.getDeliveryLink(this.delivery.id, true, { tab: "ITEMS" }));
    }
  }

  public get formGroup(): FormGroup<BulkAddItemsModel.SetValuesFormGroup> {
    return this.bulkAddItemsService.formGroup;
  }

  public override slideOverWrapperClass(): string {
    return this.stepper.currentStepIndex() === 1 ? "wide" : "";
  }

  public override async canClose(): Promise<boolean> {
    return await this.bulkAddItemsService.canExitSlideOver();
  }

  public get allProducts(): IProduct[] {
    return this.bulkAddItemsService.allProducts;
  }

  private async getAllLocations(): Promise<ILocationExtended[]> {
    try {
      return await this.locationsService.getAllGraphQL();
    } catch (error) {
      this.notificationService.showError(error);

      return [];
    }
  }

  public get productOptions(): ISelectOption[] {
    return this.allProducts.map((p) => ({ label: p.name, value: p.id }));
  }

  public get locationOptions(): ISelectOption[] {
    return this.allLocations.map((l) => ({ label: l.name, value: l.id }));
  }

  public onClickBackToFirstStep(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        contentTranslatedText:
          "Are you sure you want to go back to the 1st step? Doing so will discard all of the already added items.",
        confirmButtonTranslatedText: "Discard changes & go back",
        confirmButtonColor: "danger",
        confirmButtonIcon: "arrow_back",
      },
    });

    dialogRef.afterClosed().subscribe((result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        this.formGroup.controls[this.fieldEnum.ITEMS].clear();
        this.stepper.goToPreviousStep();
      }
    });
  }

  public get isNextButtonDisabled(): boolean {
    return this.bulkAddItemsService.isNextButtonDisabled();
  }

  public onClickNext(): void {
    let canGoNext: boolean = true;

    if (this.stepper.currentStepIndex() === Model.StepsEnum.SET_VALUES && this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);

      canGoNext = false;
    }

    if (this.stepper.currentStepIndex() === Model.StepsEnum.ENTER_ITEMS) {
      canGoNext = this.bulkAddItemsEnterItemsComponent.onClickNext();
    }

    if (canGoNext) {
      this.stepper.goToNextStep();
    } else {
      this.notificationService.showError("Please resolve all errors before proceeding.");
    }
  }

  public onClickCancel(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        contentTranslatedText:
          "Are you sure you want to cancel the creation of the remaining items?",
      },
    });

    dialogRef.afterClosed().subscribe((result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        this.bulkAddItemsService.abortCreation();
      }
    });
  }

  public onClickDone(): void {
    this.bulkAddItemsService.refreshTableSubject.next(null);
    this.afterClose();
  }

  public onFinishedCreationProcess(): void {
    this.displayDoneButton.set(true);
  }
}
