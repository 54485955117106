import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

import { Subscription } from "rxjs";

import {
  DatepickerComponent,
  DateRangePickerComponent,
} from "@design-makeover/components/datepicker";

import { BulkAddItemsModel } from "@components/items/bulk-add-items/bulk-add-items.component.model";
import { BulkAddItemsService } from "@components/items/bulk-add-items/bulk-add-items.service";
import { BulkAddSetValuesModel as Model } from "@components/items/bulk-add-items/set-values/bulk-add-set-values.model";
import { ItemOverlayService } from "@components/items/pages/item-overlay/item-overlay.service";
import {
  AttachmentTargetEnum,
  AttachmentTypeEnum,
  DateTypeEnum,
  EntityTypeEnum,
  FeatureFlagEnum,
} from "@shared/enums";
import {
  IAttachment,
  IBaseUnit,
  ICustomUnitOfMeasurement,
  IMaterial,
  IProduct,
  ISelectOption,
  ITagExtended,
} from "@shared/interfaces";
import { FeatureFlagService } from "@shared/services";
import { CommonUtils, FormUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

@Component({
  selector: "app-bulk-add-items-set-values",
  templateUrl: "./bulk-add-items-set-values.component.html",
  styleUrls: ["./bulk-add-items-set-values.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class BulkAddItemsSetValuesComponent implements OnInit, OnDestroy {
  @ViewChild("datePicker") datePickerComponent: DatepickerComponent;

  @ViewChild("dateRangePicker") dateRangePickerComponent: DateRangePickerComponent;

  public bulkAddItemsService: BulkAddItemsService = inject(BulkAddItemsService);

  public enityTypeEnum = EntityTypeEnum;

  public productDefaultUnitOfMeasurement: ICustomUnitOfMeasurement | IBaseUnit;

  public systemUnitOfMeasurement: IBaseUnit;

  public unitOfMeasurementOptions: ISelectOption[] = [];

  public previousUnitOfMeasurement: ICustomUnitOfMeasurement | IBaseUnit;

  public createdAtLocationOptions: ISelectOption[] = [];

  private productAllowedMaterials: IMaterial[] = [];

  public mode = Model.defaultMode;

  public readonly dateTypeEnum: typeof DateTypeEnum = DateTypeEnum;

  public readonly modeEnum = Model.ModeEnum;

  public readonly fieldEnum = BulkAddItemsModel.FieldEnum;

  private subscriptions = new Subscription();

  private itemOverlayService = inject(ItemOverlayService);

  private featureFlagService = inject(FeatureFlagService);

  private cdr = inject(ChangeDetectorRef);

  @Input() formGroup: FormGroup<BulkAddItemsModel.SetValuesFormGroup>;

  @Input() productOptions: ISelectOption[];

  @Input() locationOptions: ISelectOption[];

  public readonly isOldMaterialsEnabled = !this.featureFlagService.isEnabled(
    FeatureFlagEnum.NEW_MATERIALS_BEHAVIOUR,
  );

  public onModeChange(mode: Model.ModeEnum): void {
    this.mode = mode;
  }

  private get customUnitOfMeasurements(): ICustomUnitOfMeasurement[] {
    return this.bulkAddItemsService.customUnitOfMeasurements;
  }

  private get baseUnitOfMeasurements(): IBaseUnit[] {
    return this.bulkAddItemsService.baseUnitOfMeasurements;
  }

  private handleIsFixedFieldProductDependency(checked: boolean): void {
    const { controls } = this.formGroup;
    const { fieldEnum } = this;

    const isAnyDependentIsFixedFieldEnabled =
      controls[fieldEnum.IS_FIXED_MATERIALS].value ||
      controls[fieldEnum.IS_FIXED_UNIT_OF_MEASUREMENT].value ||
      controls[fieldEnum.IS_FIXED_INITIAL_QUANTITY].value;

    if (checked) {
      controls[this.fieldEnum.IS_FIXED_PRODUCT].setValue(true);
      FormUtils.disableControls(
        this.formGroup,
        [fieldEnum.IS_FIXED_PRODUCT, fieldEnum.PRODUCT],
        false,
      );

      return;
    }

    if (!checked && !isAnyDependentIsFixedFieldEnabled) {
      FormUtils.enableControls(
        this.formGroup,
        [fieldEnum.IS_FIXED_PRODUCT, fieldEnum.PRODUCT],
        false,
      );
    }
  }

  public async ngOnInit(): Promise<void> {
    const { controls } = this.formGroup;
    const { fieldEnum } = this;

    await Promise.all([
      this.bulkAddItemsService.getAllMaterials(),
      this.bulkAddItemsService.getCustomUnitOfMeasurements(),
    ]);

    const isFixedMaterialsSubscription = controls[
      fieldEnum.IS_FIXED_MATERIALS
    ].valueChanges.subscribe(this.handleIsFixedFieldProductDependency.bind(this));

    const isFixedUnitOfMeasurementSubscription = controls[
      fieldEnum.IS_FIXED_UNIT_OF_MEASUREMENT
    ].valueChanges.subscribe(this.handleIsFixedFieldProductDependency.bind(this));

    const isFixedInitialQuantitySubscription = controls[
      fieldEnum.IS_FIXED_INITIAL_QUANTITY
    ].valueChanges.subscribe((checked) => {
      if (checked) {
        controls[fieldEnum.IS_FIXED_UNIT_OF_MEASUREMENT].disable();
        controls[fieldEnum.IS_FIXED_UNIT_OF_MEASUREMENT].setValue(true, { emitEvent: false });
      } else {
        controls[fieldEnum.IS_FIXED_UNIT_OF_MEASUREMENT].enable();
      }

      this.handleIsFixedFieldProductDependency(checked);
    });

    const tagsSubscription = controls[fieldEnum.TAGS].valueChanges.subscribe((tags) => {
      if (tags.length) {
        controls[fieldEnum.IS_FIXED_TAGS].setValue(true, { emitEvent: false });
      }
    });

    const productSubscription = controls[fieldEnum.PRODUCT].valueChanges.subscribe((product) => {
      this.onProductSelected(product);
      this.cdr.detectChanges();
    });

    const materialsSubscription = controls[fieldEnum.MATERIALS].valueChanges.subscribe(
      (materials) => {
        if (materials.length) {
          controls[fieldEnum.IS_FIXED_MATERIALS].setValue(true);
        }
      },
    );

    const initialQuantitySubscription = this.bulkAddItemsService.buildInitialQuantitySubscription(
      this.formGroup as unknown as FormGroup<BulkAddItemsModel.IItemFieldSharedFormGroup>,
    );

    const unitOfMeasurementSubscription =
      this.bulkAddItemsService.buildUnitOfMeasurementOptionsSubscription(
        this.formGroup as unknown as FormGroup<BulkAddItemsModel.IItemFieldSharedFormGroup>,
      );

    const isDateRangeTypeSubscription = controls[
      fieldEnum.IS_RANGE_DATE_TYPE
    ].valueChanges.subscribe((checked) => {
      const newDateType = checked ? DateTypeEnum.RANGE : DateTypeEnum.EXACT;

      this.formGroup.controls[this.fieldEnum.DATE_TYPE].setValue(newDateType);
    });

    const creationDateSubscription = this.formGroup.controls["dateType"].valueChanges.subscribe(
      (dateType) => {
        if (dateType === DateTypeEnum.RANGE) {
          this.formGroup.controls["createdFrom"].clearValidators();
          this.formGroup.controls["createdRange"].patchValue([
            this.formGroup.controls["createdFrom"].value,
            null,
          ]);
          this.formGroup.controls["createdRange"].addValidators([CustomValidators.dateRange]);
        }

        if (dateType === DateTypeEnum.EXACT) {
          this.formGroup.controls["createdRange"].clearValidators();
          this.formGroup.controls["createdFrom"].patchValue(
            this.formGroup.controls["createdRange"].value[0],
          );
          this.formGroup.controls["createdFrom"].addValidators([CustomValidators.date]);
        }

        this.formGroup.controls["createdFrom"].updateValueAndValidity();
        this.formGroup.controls["createdRange"].updateValueAndValidity();

        const createdFrom = this.formGroup.controls["createdFrom"].value;

        if (dateType === DateTypeEnum.RANGE) {
          setTimeout(() => this.dateRangePickerComponent.picker.open());
        } else if (dateType === DateTypeEnum.EXACT && !createdFrom) {
          setTimeout(() => this.datePickerComponent.picker.open());
        }
      },
    );

    const createdFromSubscription = controls[fieldEnum.CREATED_FROM].valueChanges.subscribe(
      (createdFrom) => {
        if (controls[fieldEnum.DATE_TYPE].value !== DateTypeEnum.EXACT) {
          return;
        }

        if (createdFrom) {
          controls[fieldEnum.IS_FIXED_CREATION_DATE].enable();
          controls[fieldEnum.IS_FIXED_CREATION_DATE].setValue(true, { emitEvent: false });
        } else {
          controls[fieldEnum.IS_FIXED_CREATION_DATE].setValue(false, { emitEvent: false });
          controls[fieldEnum.IS_FIXED_CREATION_DATE].disable();
        }
      },
    );

    const createdRangeSubscription = controls[fieldEnum.CREATED_RANGE].valueChanges.subscribe(
      (createdRange) => {
        if (controls[fieldEnum.DATE_TYPE].value !== DateTypeEnum.RANGE) {
          return;
        }

        if (createdRange[0] || createdRange[1]) {
          controls[fieldEnum.IS_FIXED_CREATION_DATE].enable();
          controls[fieldEnum.IS_FIXED_CREATION_DATE].setValue(true, { emitEvent: false });
        } else {
          controls[fieldEnum.IS_FIXED_CREATION_DATE].setValue(false, { emitEvent: false });
          controls[fieldEnum.IS_FIXED_CREATION_DATE].disable();
        }
      },
    );

    const createdAtLocationSubscription = controls[
      fieldEnum.CREATED_AT_LOCATION
    ].valueChanges.subscribe((createdAtLocation) => {
      if (createdAtLocation) {
        controls[fieldEnum.IS_FIXED_CREATED_AT_LOCATION].setValue(true, { emitEvent: false });
      }
    });

    const currentLocationSubsription = controls[fieldEnum.CURRENT_LOCATION].valueChanges.subscribe(
      (createdAtLocation) => {
        if (createdAtLocation) {
          controls[fieldEnum.IS_FIXED_CURRENT_LOCATION].setValue(true, { emitEvent: false });
        }
      },
    );

    this.subscriptions.add(isDateRangeTypeSubscription);
    this.subscriptions.add(createdFromSubscription);
    this.subscriptions.add(createdRangeSubscription);
    this.subscriptions.add(isFixedUnitOfMeasurementSubscription);
    this.subscriptions.add(isFixedInitialQuantitySubscription);
    this.subscriptions.add(isFixedMaterialsSubscription);
    this.subscriptions.add(productSubscription);
    this.subscriptions.add(creationDateSubscription);
    this.subscriptions.add(initialQuantitySubscription);
    this.subscriptions.add(unitOfMeasurementSubscription);
    this.subscriptions.add(createdAtLocationSubscription);
    this.subscriptions.add(currentLocationSubsription);
    this.subscriptions.add(materialsSubscription);
    this.subscriptions.add(tagsSubscription);

    this.subscriptions.add(
      this.getIsFixedFieldDependencySubscription(
        controls[fieldEnum.CREATED_AT_LOCATION],
        controls[fieldEnum.IS_FIXED_CREATED_AT_LOCATION],
      ),
    );
    this.subscriptions.add(
      this.getIsFixedFieldDependencySubscription(
        controls[fieldEnum.CURRENT_LOCATION],
        controls[fieldEnum.IS_FIXED_CURRENT_LOCATION],
      ),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private get allProducts(): IProduct[] {
    return this.bulkAddItemsService.allProducts;
  }

  public get dateType(): DateTypeEnum {
    return this.formGroup.controls["dateType"].value;
  }

  public onTagsChanged(tags: ITagExtended[]): void {
    this.formGroup.controls["tags"].setValue(tags);
  }

  private getIsFixedFieldDependencySubscription(
    control: FormControl,
    isFixedControl: FormControl,
  ): Subscription {
    return control.valueChanges.subscribe((value) => {
      if (value && control.valid) {
        isFixedControl.enable({ emitEvent: false });
      } else {
        isFixedControl.setValue(false, { emitEvent: false });
        isFixedControl.disable({ emitEvent: false });
      }
    });
  }

  private async onProductSelected(
    productOption: ISelectOption,
    initialMaterials: ISelectOption[] = [],
  ): Promise<void> {
    const { controls } = this.formGroup;
    const { fieldEnum } = this;

    controls[fieldEnum.MATERIALS].setValue(initialMaterials);
    controls[fieldEnum.UNIT_OF_MEASUREMENT].setValue(null);
    controls[fieldEnum.BASE_INITIAL_QUANTITY].setValue(null);
    controls[fieldEnum.INITIAL_QUANTITY].setValue(null);

    if (!productOption) {
      FormUtils.disableControls(this.formGroup, [
        fieldEnum.INITIAL_QUANTITY,
        fieldEnum.MATERIALS,
        fieldEnum.UNIT_OF_MEASUREMENT,
      ]);

      controls[fieldEnum.IS_FIXED_PRODUCT].setValue(false, { emitEvent: false });
      controls[fieldEnum.IS_FIXED_PRODUCT].disable({ emitEvent: false });
      controls[fieldEnum.IS_FIXED_MATERIALS].disable({ emitEvent: false });
      controls[fieldEnum.IS_FIXED_UNIT_OF_MEASUREMENT].disable({ emitEvent: false });
      controls[fieldEnum.IS_FIXED_INITIAL_QUANTITY].disable({ emitEvent: false });

      return;
    }

    FormUtils.enableControls(this.formGroup, [
      fieldEnum.INITIAL_QUANTITY,
      fieldEnum.MATERIALS,
      fieldEnum.UNIT_OF_MEASUREMENT,
    ]);

    controls[fieldEnum.IS_FIXED_PRODUCT].enable({ emitEvent: false });
    controls[fieldEnum.IS_FIXED_PRODUCT].setValue(true, { emitEvent: false });
    controls[fieldEnum.IS_FIXED_MATERIALS].enable({ emitEvent: false });
    controls[fieldEnum.IS_FIXED_UNIT_OF_MEASUREMENT].enable({ emitEvent: false });

    const product = this.allProducts.find((product) => product.id === productOption.value);

    this.productAllowedMaterials = await this.getProductAllowedMaterials(productOption);

    this.setMaterialOptions();

    if (!this.isOldMaterialsEnabled) {
      this.cdr.detectChanges();

      this.formGroup.controls[fieldEnum.MATERIALS].setValue(this.materialOptions);
    }

    this.updateFormUnits(productOption, product);
  }

  private async getProductAllowedMaterials(productOption: ISelectOption): Promise<IMaterial[]> {
    const product = this.allProducts.find((product) => product.id === productOption.value);
    let productAllowedMaterials: IMaterial[] = [];

    if (this.isOldMaterialsEnabled) {
      for (const allowedMaterial of product?.allowedMaterials || []) {
        const allowedMaterialId = CommonUtils.getUriId(allowedMaterial);
        const material = this.bulkAddItemsService.allMaterials.find(
          (material) => material.id === allowedMaterialId,
        );

        if (material) {
          productAllowedMaterials.push(material);
        }
      }
    } else {
      const materialAttachments = (await this.itemOverlayService.loadSelectedAttachments(
        AttachmentTypeEnum.MATERIAL,
        AttachmentTargetEnum.PRODUCT,
        productOption.value as string,
        {},
        true,
      )) as IAttachment[];

      productAllowedMaterials = materialAttachments
        .map((attachment) => {
          const materialId = CommonUtils.getUriId(attachment.attachmentUri);

          return this.bulkAddItemsService.allMaterials.find(
            (material) => material.id === materialId,
          );
        })
        .filter((material) => !!material) as IMaterial[];
    }

    return productAllowedMaterials;
  }

  private updateFormUnits(productOption: ISelectOption, product: IProduct): void {
    const { fieldEnum } = this;

    this.formGroup.controls[fieldEnum.UNIT_OF_MEASUREMENT_OPTIONS].setValue(
      this.getProductUnitOfMeasurements(`${productOption.value}`),
    );

    const defaultUnitId = CommonUtils.getUriId(product.defaultUnit);
    const defaultUnitOption = this.formGroup.controls[
      fieldEnum.UNIT_OF_MEASUREMENT_OPTIONS
    ].value.find((unit) => unit.value === defaultUnitId);

    this.cdr.detectChanges();

    setTimeout(() => {
      this.formGroup.controls[fieldEnum.UNIT_OF_MEASUREMENT].setValue(defaultUnitOption);
    });
  }

  private getProductUnitOfMeasurements(productId: string): ISelectOption[] {
    if (!productId) {
      return [];
    }

    const product = this.allProducts.find((product) => product.id === productId);

    if (!product) {
      return [];
    }

    const baseUnitId = CommonUtils.getUriId(product.baseUnit);
    const baseUnit = this.baseUnitOfMeasurements.find((unit) => unit.id === baseUnitId);

    const customUnits = (product.customUnits || []).map((customUnitUri) => {
      return this.customUnitOfMeasurements.find(
        (customUnit) => CommonUtils.getUriId(customUnitUri) === customUnit.id,
      );
    });

    return [baseUnit, ...customUnits].map((unit) => {
      return { label: unit.name, value: unit.id };
    });
  }

  public setMaterialOptions(): void {
    this.bulkAddItemsService.materialOptionsForSetValuesStep = this.productAllowedMaterials.map(
      (material) => ({
        label: `${material.category}: ${material.name}`,
        value: material.id,
      }),
    );
  }

  public get materialOptions(): ISelectOption[] {
    return this.bulkAddItemsService.materialOptionsForSetValuesStep;
  }

  private get unitOfMeasurementControl(): FormControl<ISelectOption> {
    return this.formGroup.controls[this.fieldEnum.UNIT_OF_MEASUREMENT];
  }

  public get selectedUnitOfMeasurement(): IBaseUnit | ICustomUnitOfMeasurement {
    const unitOfMeasurementId = this.unitOfMeasurementControl.value?.value;

    if (!unitOfMeasurementId) {
      return null;
    }

    return [...this.baseUnitOfMeasurements, ...this.customUnitOfMeasurements].find(
      (unit) => unit.id === unitOfMeasurementId,
    );
  }

  public get productDefaultUnitOption(): ISelectOption {
    return this.unitOfMeasurementOptions.find(
      (unit) => unit.value === this.productDefaultUnitOfMeasurement.id,
    );
  }
}
