import { HttpErrorResponse } from "@angular/common/http";
import { computed, Injectable, signal } from "@angular/core";

import { Observable } from "rxjs";

import { OverlayCommonService } from "@design-makeover/components/overlay/overlay-common.service";

import { SendDialogComponent, ShareDialogComponent } from "@components/shared";
import { AnalysesTableService } from "@components/shared/tables/analyses-table/analyses-table.service";
import {
  AttachmentTargetEnum,
  AttachmentTypeEnum,
  LinkDirectionEnum,
  RecordStateEnum,
  SendTargetTypeEnum,
  ShareTargetTypeEnum,
} from "@shared/enums";
import {
  IAttachment,
  ILocationExtended,
  ILocationLink,
  ILocationType,
  ISelectOption,
  IShare,
} from "@shared/interfaces";
import {
  LocationsLinksService,
  LocationsService,
  LocationTypesService,
  SharesService,
} from "@shared/services";
import { CommonUtils } from "@shared/utils";

@Injectable({
  providedIn: "root",
})
export class LocationOverlayService extends OverlayCommonService {
  linkedLocationSuppliedByCounter = signal<number>(0);

  linkedLocationSuppliedToCounter = signal<number>(0);

  linkedLocationCounter = computed(() => {
    if (
      this.linkedLocationSuppliedByCounter() === null ||
      this.linkedLocationSuppliedToCounter() === null
    ) {
      return null;
    }

    return this.linkedLocationSuppliedByCounter() + this.linkedLocationSuppliedToCounter();
  });

  shares = signal<IShare[]>([]);

  shareCounter = computed(() => this.shares().length);

  constructor(
    private sharesService: SharesService,
    private locationLinksService: LocationsLinksService,
    private locationsService: LocationsService,
    private locationTypesService: LocationTypesService,
    private analysesTableService: AnalysesTableService,
  ) {
    super();
  }

  async getOrganisationAttachment(
    organisationId: string,
    locationId: string,
  ): Promise<IAttachment> {
    const attachments = await this.attachmentsService.getAll(
      AttachmentTypeEnum.LOCATION,
      null,
      `/organisations/${organisationId}/locations/${locationId}`,
    );

    return CommonUtils.getTargetAttachment(
      attachments,
      AttachmentTargetEnum.ORGANISATION,
      AttachmentTypeEnum.LOCATION,
      locationId,
    );
  }

  public shareLocation(
    activeOrganisationId: string,
    locationId: string,
  ): Observable<{ hasSaved: boolean }> {
    return this.dialog
      .open(ShareDialogComponent, {
        data: {
          shareTargetType: ShareTargetTypeEnum.LOCATION,
          rootRecordUri: `/organisations/${activeOrganisationId}/locations/${locationId}`,
        },
      })
      .afterClosed();
  }

  public sendLocation(
    activeOrganisationId: string,
    locationId: string,
  ): Observable<{ hasSaved: boolean }> {
    return this.dialog
      .open(SendDialogComponent, {
        data: {
          sendTargetType: SendTargetTypeEnum.LOCATION,
          rootRecordUri: `/organisations/${activeOrganisationId}/locations/${locationId}`,
        },
      })
      .afterClosed();
  }

  public async loadShares(locationId: string): Promise<void> {
    if (!this.authenticationService.isRegularUser()) {
      this.shares.set([]);

      return;
    }

    try {
      const shares = await this.sharesService.getAll(
        `/organisations/${this.authenticationService.getActiveOrganisationId()}/locations/${locationId}`,
      );

      this.shares.set(shares);
    } catch (error) {
      this.shares.set([]);
      this.notificationService.showError(error);
    }
  }

  public async loadLocationsLinks(
    locationId: string,
    locationDirection: LinkDirectionEnum,
    locationTypes?: ILocationType[],
    countries?: ISelectOption[],
  ): Promise<ILocationExtended[] | ILocationLink[]> {
    if (!locationId) {
      return null;
    }
    const activeOrganisationId = this.authenticationService.getActiveOrganisationId();
    const locationUri = `/organisations/${activeOrganisationId}/locations/${locationId}`;
    const result = [];

    try {
      const locationLinks = await this.locationLinksService.getAll(locationDirection, locationUri);

      if (!locationTypes?.length) {
        return locationLinks;
      }
      const property = locationDirection === LinkDirectionEnum.TO ? "from" : "to";

      const locationsIds = locationLinks.map((locationLink) =>
        CommonUtils.getUriId(locationLink[property]),
      );

      const locations = await this.locationsService.getByIdsGraphQL(locationsIds);

      const parsedLocations = CommonUtils.getElementsWithCountryName(countries, locations);

      for (const location of parsedLocations) {
        const linkId = locationLinks.find(
          (l) => CommonUtils.getUriId(l[property]) === location.id,
        ).id;

        result.push({ ...location, linkId });
      }
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Request aborted");

        return null;
      }
      this.notificationService.showError(error);
    }

    return result;
  }

  public async loadLocationTypes(): Promise<ILocationType[]> {
    return await this.locationTypesService.getAll().catch((error) => {
      this.notificationService.showError(error);

      return [];
    });
  }

  public async getLocation(id: string, countries: ISelectOption[]): Promise<ILocationExtended> {
    try {
      const locationByIds = await this.locationsService.getByIdsGraphQL([id]);

      return CommonUtils.getElementsWithCountryName(countries, locationByIds[0]);
    } catch (error) {
      this.notificationService.showError(error);

      return null;
    }
  }

  public async getLocations(
    searchText: string,
    countries: ISelectOption[],
  ): Promise<ILocationExtended[]> {
    let availableLocations = [];

    const filter = {
      name: searchText || undefined,
      recordState: RecordStateEnum.ACTIVE,
    };

    try {
      const locations = await this.locationsService.getAllGraphQL(filter);

      availableLocations = CommonUtils.getElementsWithCountryName(countries, locations);
    } catch (error) {
      this.notificationService.showError(error);
    }

    return availableLocations;
  }

  public async createLocationLink(
    locationId: string,
    locationLinkId: string,
    locationDirection: LinkDirectionEnum,
  ) {
    const activeOrganisationId = this.authenticationService.getActiveOrganisationId();
    const payload = {
      from: `/organisations/${activeOrganisationId}/locations/${
        locationDirection === LinkDirectionEnum.FROM ? locationLinkId : locationId
      }`,
      to: `/organisations/${activeOrganisationId}/locations/${
        locationDirection === LinkDirectionEnum.FROM ? locationId : locationLinkId
      }`,
    };

    await this.locationLinksService.create(payload).catch((error: HttpErrorResponse) => {
      this.notificationService.showError(error);

      return Promise.reject(error);
    });
  }

  public async removeLocationLink(locationId: string) {
    return this.locationLinksService
      .delete(locationId)
      .then(() => this.notificationService.showSuccess("Link removed"))
      .catch((error: HttpErrorResponse) => {
        this.notificationService.showError(error);

        return Promise.reject();
      });
  }

  loadLinkedLocationCounter(recordId: string): void {
    Promise.all([
      this.loadLocationsLinks(recordId, LinkDirectionEnum.FROM),
      this.loadLocationsLinks(recordId, LinkDirectionEnum.TO),
    ]).then(([suppliedFromLocations, suppliedToLocations]) => {
      this.linkedLocationSuppliedByCounter.set(suppliedToLocations.length);
      this.linkedLocationSuppliedToCounter.set(suppliedFromLocations.length);
    });
  }

  override setCountersToLoadingState() {
    super.setCountersToLoadingState();
    this.linkedLocationSuppliedByCounter.set(null);
    this.linkedLocationSuppliedToCounter.set(null);
    this.analysesTableService.analyses.set([]);
    this.analysesTableService.isLoading.set(true);
    this.shares.set([]);
  }

  override setCountersEmptyState() {
    super.setCountersEmptyState();
    this.linkedLocationSuppliedByCounter.set(0);
    this.linkedLocationSuppliedToCounter.set(0);
    this.analysesTableService.analyses.set([]);
    this.analysesTableService.isLoading.set(false);
    this.shares.set([]);
  }
}
