<iov-slide-overlay-header
  [shouldDisplayTags]="!isActiveOrganisation"
  [title]="title()"
  icon="organisations"
></iov-slide-overlay-header>
<iov-slide-overlay-content #slideOverlayContent>
  <ng-template
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="menu-info"
    overlay-menu-title="Organisation details"
  >
    <iov-form-field-group [name]="mainInformationText">
      <div [formGroup]="formGroup" class="row-container">
        <div class="row">
          <div class="item">
            <iov-input enableViewMode formControlName="name" label="Name"></iov-input>
          </div>
        </div>
        <div class="row">
          <div class="item">
            <iov-input
              enableViewMode
              [viewModeTemplate]="urlTemplate"
              formControlName="website"
              label="Website"
            ></iov-input>
          </div>
        </div>
      </div>
    </iov-form-field-group>
    <iov-form-field-group name="Address">
      <div [formGroup]="formGroup" class="row-container">
        <div class="row">
          <div class="item">
            <iov-input enableViewMode formControlName="street" label="Address"></iov-input>
          </div>
        </div>
        <div class="row">
          <div class="item">
            <iov-input enableViewMode formControlName="region" label="State / Region"></iov-input>
          </div>
        </div>
        <div class="row">
          <div class="item">
            <iov-input enableViewMode formControlName="zipCode" label="ZIP / Postcode"></iov-input>
          </div>
        </div>
        <div class="row">
          <div class="item">
            <iov-input-select
              [options]="countryOptions"
              [viewModeTemplate]="countryViewMode"
              enableViewMode
              formControlName="country"
              label="Country"
            ></iov-input-select>
            <ng-template #countryViewMode let-label="label" let-option>
              <iov-form-input-readonly [label]="label" [value]="option.label">
                <app-flag [country]="option.value" input-readonly-prefix></app-flag>
              </iov-form-input-readonly>
            </ng-template>
          </div>
        </div>
      </div>
    </iov-form-field-group>
    @if (visibleCustomFields?.length) {
      <iov-form-field-group [name]="additionalInformationText">
        <app-custom-fields-edit
          [columns]="1"
          [elementCustomFields]="element?.customFields"
          [formGroup]="formGroup"
          [enableViewMode]="true"
          [visibleCustomFields]="visibleCustomFields"
        ></app-custom-fields-edit>
      </iov-form-field-group>
    }
  </ng-template>
  <ng-template
    [overlay-menu-buttons-template]="linkedController"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.LOCATIONS).isEnabled"
    [overlay-menu-indicator]="locationCount"
    [overlay-menu-show-save-button]="false"
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="locations"
    [overlay-menu-title]="overlayTabEnum.LOCATIONS"
  >
    @if (isLoadingLocations()) {
      <app-loader></app-loader>
    } @else {
      <article class="field-group-container block no-padding-bottom">
        <app-locations-table
          #locationsTable
          [areButtonsEnabled]="false"
          [locationsIds]="selectedLocationsIds"
          [columns]="['recordState', 'name', 'types', 'address.countryName', 'tags']"
        ></app-locations-table>
      </article>
    }
  </ng-template>
  <ng-template
    [overlay-menu-buttons-template]="certificateController"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.CERTIFICATES).isEnabled"
    [overlay-menu-indicator]="organisationOverlay.certificateCounter()"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="overlayTabEnum.CERTIFICATES"
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="certificates"
  >
    <app-overlay-certificate-attachments
      #certificateSection
      [attachmentTargetId]="element?.id"
      [attachmentTargetType]="attachmentTargetEnum.ORGANISATION"
      [isReadOnly]="isRecordArchived"
      [overlayService]="organisationOverlay"
    ></app-overlay-certificate-attachments>
  </ng-template>
  <ng-template
    [overlay-menu-buttons-template]="documentController"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.DOCUMENTS).isEnabled"
    [overlay-menu-indicator]="organisationOverlay.documentCounter()"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="overlayTabEnum.DOCUMENTS"
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="document"
  >
    <app-overlay-document-attachments
      #documentSection
      [attachmentTargetId]="element?.id"
      [attachmentTargetType]="attachmentTargetEnum.ORGANISATION"
      [isReadOnly]="isRecordArchived"
      [overlayService]="organisationOverlay"
    ></app-overlay-document-attachments>
  </ng-template>
  <ng-template
    *ngIf="!menuItems().get(overlayTabEnum.COMMENTS).isHidden"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.COMMENTS).isEnabled"
    [overlay-menu-indicator]="organisationOverlay.commentCounter()"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="overlayTabEnum.COMMENTS"
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="comment"
  >
    <article class="field-group-container">
      <app-comments
        (added)="onCommentAdded(organisationOverlay)"
        (deleted)="onCommentDeleted(organisationOverlay)"
        [entityId]="element?.id"
        [entityType]="entityTypeEnum.CONNECTIONS"
      ></app-comments>
    </article>
  </ng-template>
  <ng-template #certificateController>
    @if (!overlay.contentRightSidePanel() && isRecordActive && canAddModifyEntities) {
      <iov-button
        (pressed)="overlay.openSideMenu(certificateSection?.attachmentsRightMenu)"
        [disabled]="overlay.loading()"
        type="ghost"
      >
        <mat-icon class="action-icon" icon-left>add</mat-icon>
        Add
      </iov-button>
    }
  </ng-template>
  <ng-template #documentController>
    @if (!overlay.contentRightSidePanel() && isRecordActive && canAddModifyEntities) {
      <iov-button
        (pressed)="overlay.openSideMenu(documentSection?.attachmentsRightMenu)"
        [disabled]="overlay.loading()"
        type="ghost"
      >
        <mat-icon class="action-icon" icon-left>add</mat-icon>
        Add
      </iov-button>
    }
  </ng-template>
  <ng-template #linkedController>
    @if (!overlay.contentRightSidePanel() && isRecordActive && canAddModifyEntities) {
      <iov-button
        (pressed)="onAddLocationClicked()"
        class="icon-add-circle"
        tp="Add new location"
        type="ghost"
      >
        <mat-icon class="action-icon" icon-left>add</mat-icon>
        Add
      </iov-button>
    }
  </ng-template>
  <ng-template #urlTemplate let-data>
    <div class="form-field-item hover-visibility-container">
      <iov-form-label [label]="data.label"></iov-form-label>
      <div class="form-field-input-readonly">
        @if (data.value) {
          <div class="one-line-wrap-ellipsis">
            <a
              class="blue-link"
              [href]="data.value"
              target="_blank"
              tpPlacement="top-start"
              [tp]="data.value"
            >
              {{ data.value }}
              <app-external-link-icon></app-external-link-icon>
            </a>
          </div>
        } @else {
          -
        }
      </div>
    </div>
  </ng-template>
</iov-slide-overlay-content>
