<div class="location-detail">
  <mat-accordion>
    <mat-expansion-panel class="mat-elevation-z0 section-box" [expanded]="false">
      <mat-expansion-panel-header class="section-header">
        <mat-panel-title class="section-title">
          <div class="location-name-container">
            <iov-form-link [link]="getLocationLink()" [styledLink]="true" [maintainFontSize]="true">
              <span class="value location-name">
                {{ location.name }}
              </span>
            </iov-form-link>
            <iov-tag-group [tags]="locationTypesTags"></iov-tag-group>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="location-main-info">
        <div>
          Organisation:
          <span class="organisation-name">{{ location.organisationName }}</span>
        </div>
      </div>
      <div>
        <h4>Certificates</h4>
        <app-certificates-table
          [certificatesIds]="certificateIds"
          [isRecordStateFilterEnabled]="false"
          [isSearchEnabled]="false"
          [isPaginatorEnabled]="false"
          [columns]="[
            'number',
            'standard.name',
            'standardType.fullName',
            'issuanceDate',
            'validFromDate',
            'validToDate',
            'documents',
          ]"
        ></app-certificates-table>
      </div>
      <div>
        <h4>Geo data</h4>
        <div class="geodata">
          <div class="fields">
            <div class="field">
              <div>Address</div>
              <div class="value">
                {{ location.address.street || "-" }}
              </div>
            </div>
            <div class="field">
              <div>ZIP / Post code</div>
              <div class="value">
                {{ location.address.zipCode || "-" }}
              </div>
            </div>
            <div class="field">
              <div>State/Region</div>
              <div class="value">
                {{ location.address.region || "-" }}
              </div>
            </div>
            <div class="field">
              <div>Country</div>
              <div class="value">
                {{ location.address.countryName || "-" }}
              </div>
            </div>
            <div class="field">
              <div>Geo data type</div>
              <div class="value">
                {{ geoLocationType || "-" }}
              </div>
            </div>
            <div class="field">
              <div>Area (ha)</div>
              <div class="value">
                @if (area) {
                  {{ area | number }}
                } @else {
                  -
                }
              </div>
            </div>
          </div>
          <app-map [geoLocation]="location.geoLocation"></app-map>
        </div>
      </div>
      @if (location.customFieldsWithValues.length) {
        <div>
          <h4>{{ additionalInformationText }}</h4>
          <div class="information grid">
            @for (customField of location.customFieldsWithValues; track customField.label) {
              <div class="column">
                <div class="header">{{ customField.label }}</div>
                <div class="value">{{ customField.value }}</div>
              </div>
            }
          </div>
        </div>
      }
      <div>
        <h4>Documents</h4>
        @if (isLoadingDocumentTypesTable()) {
          <app-loader></app-loader>
        } @else {
          <app-document-types-table
            [documentTypes]="documentTypes"
            [areButtonsEnabled]="false"
            [isRecordStateFilterEnabled]="false"
            [isPaginatorEnabled]="false"
            [isSearchEnabled]="false"
            [rulesetWarningTooltipText]="documentTypesRulesetWarningTooltipText"
            [columns]="['documentType', 'documentNames']"
          ></app-document-types-table>
        }
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
