<article class="field-group-container">
  @if (isSelectedAttachmentsLoading()) {
    <app-loader></app-loader>
  } @else {
    <app-certificates-table
      [certificatesIds]="selectedCertificatesIds()"
      [canRemove]="!isReadOnly() && canAddModifyEntities"
      (remove)="onRemove($event)"
      [isSaveTableState]="true"
      [isPaginatorEnabled]="!overlay.contentRightSidePanel()"
    ></app-certificates-table>
  }
</article>

<ng-template #attachmentsRightMenu>
  <iov-right-side-panel
    (pressAddAll)="onAddAll()"
    (searchEvent)="onSearchAvailableAttachments($event)"
    [availableItems]="availableCertificates()"
    [displayTopButtons]="false"
    [isLoadingItems]="isAvailableAttachmentsLoading()"
    [itemType]="attachmentTypeEnum.CERTIFICATE"
    [overlayTopButtons]="overlayTopButtons"
    class="overlay-right-panel"
    itemTypePlural="certificates"
  >
    <div class="items-container">
      @if (isAvailableAttachmentsLoading()) {
        <app-loader></app-loader>
      } @else {
        @for (certificate of availableCertificates(); track $index) {
          <iov-draggable-card (pressed)="onAdd(certificate)" [draggable]="false">
            <iov-certificate-content-card
              [certificate]="certificate"
              [type]="cardContentTypeEnum.ADD"
            />
          </iov-draggable-card>
        }
      }
    </div>
  </iov-right-side-panel>
</ng-template>

<ng-template #overlayTopButtons>
  <iov-button
    (pressed)="addNewCertificate()"
    size="large"
    tp="Add new"
    tpPlacement="bottom"
    type="icon"
  >
    <mat-icon icon-medium-18>add</mat-icon>
  </iov-button>
</ng-template>
