<form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
  <h2 mat-dialog-title>{{ isEditing() ? "Edit" : "Add" }} custom field</h2>

  <app-loader *ngIf="isLoading()" containerClass="on-dialog"></app-loader>

  <ng-container *ngIf="!isLoading()">
    <mat-dialog-content>
      <iov-input
        [autofocus]="!isLoading() && !isEditing()"
        formControlName="label"
        label="Field label"
      ></iov-input>

      <iov-input-select
        [options]="typeOptions"
        formControlName="type"
        label="Field type"
      ></iov-input-select>

      <iov-input-chips
        [options]="resourceTypeOptions"
        formControlName="resourceTypes"
        label="Modules"
      ></iov-input-chips>
    </mat-dialog-content>

    <mat-dialog-actions>
      <iov-button-group>
        <iov-button (pressed)="onClose()" type="ghost">Cancel</iov-button>
        <iov-button [disabled]="isSubmitButtonDisabled()" accessibilityRole="submit">
          <mat-icon icon-left>check</mat-icon>
          Save
        </iov-button>
      </iov-button-group>
    </mat-dialog-actions>
  </ng-container>
</form>
