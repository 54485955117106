import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

import { RoutingEnum } from "src/app/shared/enums";

interface ISetting {
  route: RoutingEnum;
  icon: string;
  svgIcon?: string;
  title: string;
  description: string;
}

@Component({
  selector: "iov-settings-card",
  templateUrl: "./settings-card.component.html",
  styleUrl: "./settings-card.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsCardComponent {
  @Input() setting: ISetting;

  @Output() pressed: EventEmitter<void> = new EventEmitter();

  onClick(): void {
    this.pressed.emit();
  }
}
