import { HttpErrorResponse } from "@angular/common/http";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  signal,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef } from "ag-grid-community";
import { EditUserDialogComponent } from "src/app/components/settings";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { ConfirmDialogResponseEnum, TableEnum, UserRoleEnum } from "@shared/enums";
import { IOrganisationMember } from "@shared/interfaces";
import { AuthenticationService, OrganisationsService } from "@shared/services";
import { CellRendererUtils, ColumnUtils, CommonUtils } from "@shared/utils";

import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-users-table",
  templateUrl: "./users-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersTableComponent implements AfterViewInit {
  @Input()
  public users: IOrganisationMember[] = null;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  @Output()
  public totalElementsChanged: EventEmitter<number> = new EventEmitter();

  @Input()
  public table = TableEnum.USERS_EXISTING;

  @Input()
  public isSaveTableState = false;

  public isLoading = signal(true);

  public rowData: IOrganisationMember[] = [];

  public columnDefs = signal<ColDef[]>([]);

  private activeOrganisationId = this.authenticationService.getActiveOrganisationId();

  constructor(
    private notificationService: NotificationService,
    private organisationsService: OrganisationsService,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
  ) {}

  public async ngAfterViewInit() {
    this.setColumnDefs();
    await this.getAll();
  }

  private setColumnDefs = (): void => {
    const columnDefs: ColDef[] = [
      { headerName: "First name", field: "firstName", lockVisible: true },
      { headerName: "Last name", field: "lastName", lockVisible: true },
      { headerName: "Email", field: "emailAddress" },
      { headerName: "Roles", field: "roles", cellRenderer: CellRendererUtils.roleNames },
      {
        headerName: "Status",
        field: "status",
        cellRenderer: CellRendererUtils.capitaliseFirstLetter,
      },
    ];

    if (this.areButtonsEnabled) {
      const buttons = [
        {
          onClick: this.onToggleActivate,
          tooltip: (row) => (row.status === "ACTIVE" ? "Deactivate" : "Activate"),
          icon: (row) => (row.status === "ACTIVE" ? "block" : "check_circle"),
          isVisible: (row) => row.canLoggedInUserEdit,
        },
        {
          onClick: this.onArchive,
          tooltip: "Archive",
          icon: "archive",
          isVisible: (row) => row.canLoggedInUserEdit,
        },
        {
          onClick: this.onEdit,
          tooltip: "Edit",
          icon: "edit",
          isVisible: (row) => row.canLoggedInUserEdit,
        },
      ];

      columnDefs.push(ColumnUtils.buttons(buttons));
    }
    this.columnDefs.set(columnDefs);
  };

  public onToggleActivate = (user: IOrganisationMember): void => {
    if (user.status === "ACTIVE") {
      this.onDeactivate(user);
    } else {
      this.onActivate(user);
    }
  };

  private onActivate = (user: IOrganisationMember): void => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: `Activate confirmation`,
        contentTranslatedText: "Are you sure that you want to activate this user?",
        confirmButtonTranslatedText: "Activate",
        confirmButtonIcon: "check_circle",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        await this.organisationsService
          .setMemberStatus(this.activeOrganisationId, user.id, "ACTIVE")
          .then(async () => {
            this.notificationService.showSuccess(`User activated`);
            await this.getAll();
          })
          .catch((error: HttpErrorResponse) => {
            this.notificationService.showError(error);
          });
      }
    });
  };

  private onDeactivate = (user: IOrganisationMember): void => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: `Deactivate confirmation`,
        contentTranslatedText: "Are you sure that you want to deactivate this user?",
        confirmButtonColor: "danger",
        confirmButtonTranslatedText: "Deactivate",
        confirmButtonIcon: "block",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        await this.organisationsService
          .setMemberStatus(this.activeOrganisationId, user.id, "DEACTIVATED")
          .then(async () => {
            this.notificationService.showSuccess(`User deactivated`);
            await this.getAll();
          })
          .catch((error: HttpErrorResponse) => {
            this.notificationService.showError(error);
          });
      }
    });
  };

  public onArchive = (user: IOrganisationMember): void => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: `Archive confirmation`,
        contentTranslatedText: "Are you sure that you want to archive this user?",
        confirmButtonTranslatedText: "Archive",
        confirmButtonIcon: "archive",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        await this.organisationsService
          .setMemberStatus(this.activeOrganisationId, user.id, "ARCHIVED")
          .then(async () => {
            this.notificationService.showSuccess(`Archived`);
            await this.getAll();
          })
          .catch((error: HttpErrorResponse) => {
            this.notificationService.showError(error);
          });
      }
    });
  };

  public onEdit = (user: IOrganisationMember): void => {
    if (!this.areButtonsEnabled || !user.canLoggedInUserEdit) {
      return;
    }
    const dialogRef = this.dialog.open(EditUserDialogComponent, {
      autoFocus: false,
      data: {
        user,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: { hasSaved: boolean }) => {
      if (result?.hasSaved) {
        await this.getAll();
      }
    });
  };

  private isLoggedInUser = (user: IOrganisationMember): boolean =>
    CommonUtils.getUriId(user.uri) === this.authenticationService.getUserId();

  private setCanEdit = (users: IOrganisationMember[]): void => {
    const isLoggedInUserAccountOwner: boolean = this.authenticationService
      .getActiveOrganisation()
      .roles.some((role: UserRoleEnum) => [UserRoleEnum.ACCOUNT_OWNER].includes(role));
    const isLoggedInUserAdmin: boolean = this.authenticationService
      .getActiveOrganisation()
      .roles.some((role: UserRoleEnum) => [UserRoleEnum.ADMIN].includes(role));

    for (const user of users) {
      user.canLoggedInUserEdit =
        !this.isLoggedInUser(user) &&
        (user.canLoggedInUserEdit =
          isLoggedInUserAccountOwner ||
          !user.roles[0] ||
          (isLoggedInUserAdmin &&
            user.roles[0] !== UserRoleEnum.ACCOUNT_OWNER &&
            user.roles[0] !== UserRoleEnum.ADMIN));
    }
  };

  public getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    if (this.users) {
      const rowData = this.users;

      this.setCanEdit(rowData);
      this.rowData = rowData;
      this.totalElementsChanged.emit(this.rowData.length);
      this.isLoading.set(false);
    } else {
      try {
        const rowData = await this.organisationsService.getAllOrganisationMembers(
          this.activeOrganisationId,
          "ACTIVE,DEACTIVATED",
        );

        this.setCanEdit(rowData);
        this.rowData = rowData;
        this.totalElementsChanged.emit(this.rowData.length);
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };
}
