import {
  AfterViewInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Injector,
  Input,
  OnInit,
  signal,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from "@angular/forms";
import { MatDatepicker, MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MatInput } from "@angular/material/input";

import moment, { Moment } from "moment";

import { DatepickerHeaderComponent } from "@design-makeover/components/datepicker";
import { SlideOverlayPageService } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.service";
import { DATE_FORMAT } from "@design-makeover/constants/common";
import { FormControlService } from "@design-makeover/services/form-control.service";

import { CommonConstants } from "@shared/constants";

@Component({
  selector: "iov-datepicker",
  templateUrl: "./datepicker.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DatepickerComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @ViewChild("dateInput", { read: MatInput }) dateInputRef: MatInput;

  @ViewChild("picker") picker: MatDatepicker<Date>;

  @Input() placeholder: string;

  @Input() label: string;

  @Input() minDate: string;

  @Input() maxDate: string;

  @Input() class: string;

  @Input({ transform: booleanAttribute }) hideOptionalHint: boolean = false;

  @Input({ transform: booleanAttribute }) enableViewMode: boolean = false;

  public isOptional = signal<boolean>(false);

  public isDisabled = signal<boolean>(false);

  textValue: string;

  ngControl: NgControl;

  dateFormat: string = DATE_FORMAT;

  readonly dateFormatText = CommonConstants.DATE_FORMAT;

  datepickerHeader: typeof DatepickerHeaderComponent = DatepickerHeaderComponent;

  constructor(
    private injector: Injector,
    private formControlService: FormControlService,
    protected overlay: SlideOverlayPageService,
  ) {}

  get minDateValue(): Date {
    return this.minDate ? moment(this.minDate).toDate() : null;
  }

  get maxDateValue(): Date {
    return this.maxDate ? moment(this.maxDate).toDate() : null;
  }

  get hasValidationError(): boolean {
    return this.ngControl.errors && (this.ngControl.dirty || this.ngControl.touched);
  }

  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl, null);

    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngAfterViewInit(): void {
    if (this.ngControl) {
      this.isOptional.set(this.formControlService.calculateOptionalFlag(this.ngControl.control));
    }
  }

  onChange: (_: unknown | null) => void = () => {};

  onTouch: () => void = () => {};

  writeValue(inputValue: string): void {
    this.textValue = inputValue;
  }

  registerOnChange(fn: (_: unknown | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  handleCalendarClick(event: Event): void {
    event.preventDefault();
    this.picker.open();
  }

  handleChange(event: MatDatepickerInputEvent<Date> | Event): void {
    let value: string | Moment = (event.target as unknown as HTMLInputElement).value;

    if (moment.isMoment(value)) {
      value = value.format();
    } else if (typeof value === "string") {
      const momentValue = moment.utc(value, DATE_FORMAT, true);

      value = momentValue.isValid() ? momentValue.format() : value;
    }
    this.onChange(value);
    this.onTouch();
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled.set(isDisabled);
  }

  private clearInput(): void {
    this.dateInputRef.value = "";
  }

  get shouldHideClearButton(): boolean {
    const isEmpty = !this.ngControl.value;

    return this.isDisabled() || isEmpty;
  }

  onInputClear(): void {
    this.clearInput();
    this.onChange(null);
    this.onTouch();
  }
}
