import { ChangeDetectionStrategy, Component, input, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

import { SlideOverlayPageService } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.service";

import { ISelectOption } from "@shared/interfaces";

@Component({
  selector: "app-edit-organisation-main-details",
  templateUrl: "./edit-organisation-main-details.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class EditOrganisationMainDetailsComponent {
  @Input() formGroup: FormGroup;

  public isEditing = input<boolean>(false);

  @Input() countryOptions: ISelectOption[];

  constructor(protected overlay: SlideOverlayPageService) {}
}
