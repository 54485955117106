<div class="map-outer-container" [hidden]="isLoading()">
  <iov-button
    (pressed)="onDelete()"
    [visible]="canDelete"
    class="delete-button"
    tp="Remove"
    size="large"
    type="icon"
  >
    <mat-icon>delete</mat-icon>
  </iov-button>
  <div class="zoom-buttons">
    <button (click)="onSetZoom(1)" class="zoom-button in" tp="Zoom in" [tpDelay]="500">
      <mat-icon class="zoom-icon" svgIcon="plus"></mat-icon>
    </button>
    <button
      (click)="onSetZoom(-1)"
      class="zoom-button out"
      tp="Zoom out"
      tpPlacement="bottom"
      [tpDelay]="500"
    >
      <mat-icon class="zoom-icon" svgIcon="minus"></mat-icon>
    </button>
  </div>

  <div class="info">
    @switch (type) {
      @case (geoJsonTypeEnum.POINT) {
        <span class="coordinates-text">
          Lat: {{ getFormattedCoordinateProperty(mapOptions.center.lat) }}, Lon:
          {{ getFormattedCoordinateProperty(mapOptions.center.lng) }}
        </span>
        <div class="icons">
          <mat-icon
            svgIcon="copy"
            class="clickable"
            tp="Copy coordinates"
            (click)="onCopyCoordinates()"
          ></mat-icon>
          <mat-icon
            svgIcon="download"
            class="clickable"
            tp="Download"
            (click)="onDownload()"
          ></mat-icon>
        </div>
      }
      @default {
        @if (polygonArea) {
          <span
            tp="This calculation is based on the uploaded GEO data and may not match the official measurements"
          >
            Est. area (ha): {{ polygonArea | number }}
          </span>
        }
        <mat-icon
          svgIcon="download"
          class="clickable"
          tp="Download"
          (click)="onDownload()"
        ></mat-icon>
      }
    }
  </div>

  <google-map (idle)="onMapIdle()" [options]="mapOptions" [height]="height" [width]="'100%'">
    @if (type === geoJsonTypeEnum.POINT && markerOptions) {
      <map-marker [options]="markerOptions"></map-marker>
    }
  </google-map>
</div>
