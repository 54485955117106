import { Injectable } from "@angular/core";

import {
  IAvailableOrganisation,
  ICertificateDetails,
  IDocument,
  ISupplyChainDetailedLocation,
  IAddressBase,
  ISupplyChainGraphDetail,
  ISupplyChainDetail,
  ISelectOption,
  ILocationLinkDetail,
} from "src/app/shared/interfaces";

@Injectable({
  providedIn: "root",
})
export class SupplyChainMapperService {
  async mapGraphQlData(
    graphQlData: ISupplyChainGraphDetail,
    activeOrg: IAvailableOrganisation,
    countryOptions: ISelectOption[],
  ): Promise<ISupplyChainDetail> {
    const data = graphQlData;
    const locations: ISupplyChainDetailedLocation[] = [];
    const links: ILocationLinkDetail[] = data.loadSupplyChain.links;

    data.loadSupplyChain.locations.forEach((location) => {
      const selectedDocuments = location?.documents?.map((d) => ({
        ...d,
        isSelected: true,
        typeName: d.type?.name,
        type: d.type.id,
      })) as unknown as IDocument[];
      const documents =
        location.location?.documents?.map((document) => ({
          ...document,
          isSelected: selectedDocuments?.some((d) => d.id === document.id),
        })) || [];
      const selectedCertificates = location?.certificates?.map((c) => ({
        ...c,
        standardName: c.standard?.name,
        isSelected: true,
      })) as unknown as ICertificateDetails[];
      const certificates = location.location?.certificates?.map((certificate) => ({
        ...certificate,
        standardName: certificate.standard?.name,
        standardTypeName: certificate.standardType?.fullName,
        isSelected: selectedCertificates?.some((c) => c.id === certificate.id),
      })) as unknown as ICertificateDetails[];

      const address: IAddressBase = {
        ...location.location?.address,
        countryName: countryOptions?.find((c) => c.value === location?.location?.address?.country)
          ?.label,
      };
      const organisationName = location?.location?.connections[0]?.name ?? activeOrg.name;
      const organisationId = location?.location?.connections[0]?.id ?? activeOrg.id;

      locations.push({
        selectedCertificates,
        selectedDocuments,
        id: location.location.id,
        name: location.location.name,
        address,
        types: location.location.types,
        organisationName,
        organisation: organisationName,
        organisationId,
        documents, // all the documents.
        links,
        certificates,
        recordState: location.location.recordState,
        geoLocation: location.location.geoLocation?.featureCollection,
      });
    });

    return {
      id: data.loadSupplyChain.id,
      name: data.loadSupplyChain.name,
      description: data.loadSupplyChain.description,
      recordState: data.loadSupplyChain.recordState,
      locations,
      links,
    };
  }
}
