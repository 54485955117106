import { ChangeDetectionStrategy, Component, Input, TemplateRef } from "@angular/core";

import { ILocationType, IRecordResponse } from "src/app/shared/interfaces";

import { ItemsSupplyChainModel } from "@components/shared/items-supply-chain/items-supply-chain.component.model";

@Component({
  templateUrl: "./flow-chart-node.component.html",
  styleUrls: ["./flow-chart-node.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowChartNodeComponent {
  @Input() public isSafari = false;

  @Input() public location: any;

  @Input() public canRemove = false;

  @Input() public isEditing = false;

  @Input() public hasAvailableDocuments = false;

  @Input() public hasAvailableCertificates = false;

  @Input() public delivery: any;

  @Input() public groupedProduct: ItemsSupplyChainModel.IGroupedProduct;

  @Input() public deliveriesRulesetsRecords: IRecordResponse[];

  @Input() public locationsRulesetsRecords: IRecordResponse[];

  @Input() template: TemplateRef<any>;

  @Input() class: string;

  @Input() shouldOpenInNewTab: boolean = false;

  get locationTypes(): ILocationType[] {
    return this.location.types.slice(0, 8);
  }
}
