import { Injectable, inject } from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";

import { Subject } from "rxjs";

import {
  Indicator,
  IndicatorGroup,
  RiskAssessmentTemplatePayload,
} from "@shared/risk-assessment-templates/models";
import { CustomValidators } from "@shared/validators";

import { CategoryFormGroup, IndicatorFormGroup, IndicatorType } from "./models";
import { uniqueCategoryNameValidator } from "./unique-category-name.validator";

type IndicatorsForm = FormArray<FormGroup<IndicatorFormGroup | CategoryFormGroup>>;

@Injectable()
export class RiskAssessmentTemplateOverlayIndicatorsFormService {
  private readonly fb = inject(FormBuilder);

  private readonly indicatorTitleMap = {
    indicator: "Indicator",
    category: "Category",
  };

  private form: IndicatorsForm;

  readonly controlAdded$ = new Subject<FormGroup>();

  readonly controlRemoved$ = new Subject<FormGroup>();

  init(form: IndicatorsForm) {
    this.form = form;
  }

  set(indicatorGroups: IndicatorGroup[]) {
    this.form.clear();

    indicatorGroups.forEach((category) => {
      category.name !== null && this.addCategoryFormGroup(category);
      category.indicators.forEach((indicator) => this.addIndicatorFormGroup(indicator));
    });
  }

  addIndicatorFormGroup(indicator?: Indicator) {
    const group = this.getBaseFormGroup("indicator");

    group.addControl("guidance", this.fb.control<string>("", [CustomValidators.maxLength(5000)]));
    group.updateValueAndValidity();

    indicator && group.patchValue({ name: indicator.indicator, guidance: indicator.guidance });

    this.updateControl(group);
  }

  addCategoryFormGroup(category?: IndicatorGroup) {
    const group = this.getBaseFormGroup("category");

    group.get("name").addValidators([uniqueCategoryNameValidator(() => this.form.controls)]);
    group.updateValueAndValidity();

    category && group.patchValue({ name: category.name });

    this.updateControl(group);
  }

  remove(index: number) {
    const formGroup = this.form.at(index);

    this.controlRemoved$.next(formGroup);
    this.form.removeAt(index);
    this.form.updateValueAndValidity();
  }

  buildPayload(): RiskAssessmentTemplatePayload["indicatorGroups"] {
    let payload = [];
    let currentGroup: RiskAssessmentTemplatePayload["indicatorGroups"][0];

    const updatePayload = (group) => (payload = [...payload, group]);

    this.form.getRawValue().forEach((indicator) => {
      if (indicator._type === "indicator") {
        if (currentGroup === undefined) {
          currentGroup = { name: null, indicators: [] };
        }

        currentGroup.indicators = [
          ...currentGroup.indicators,
          { guidance: indicator.guidance, indicator: indicator.name },
        ];
      } else if (indicator._type === "category") {
        currentGroup && updatePayload(currentGroup);

        currentGroup = { name: indicator.name, indicators: [] };
      }
    });

    currentGroup?.indicators.length && updatePayload(currentGroup);

    return payload;
  }

  private getBaseFormGroup(type: IndicatorType): FormGroup {
    return this.fb.group({
      _type: [type],
      _title: [this.indicatorTitleMap[type]],
      name: ["", [CustomValidators.required]],
    });
  }

  private updateControl(group: FormGroup) {
    this.form.push(group);
    this.form.updateValueAndValidity();
    this.controlAdded$.next(group);
  }
}
