<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <h2 mat-dialog-title>Share {{ data.shareTargetType.toLowerCase() }}</h2>
  @if (isLoading()) {
    <app-loader containerClass="on-dialog"></app-loader>
  } @else {
    <mat-dialog-content [formGroup]="formGroup">
      <iov-input
        hideOptionalHint
        [autofocus]="true"
        label="Email address"
        formControlName="email"
        hintClass="info-hint hint-bg-gray"
        hint="Note: any changes to the shared record or the related records or addition of any extra information (documents, certificates, etc.) will not be shared automatically. In case you make the aforementioned changes and need to include them in a share(s) - delete the old share(s) and create new ones.
                Rulesets and tags will not be shared."
        type="email"
      ></iov-input>
    </mat-dialog-content>
    <mat-dialog-actions>
      <iov-button-group>
        <iov-button (pressed)="onClose()" type="ghost">Cancel</iov-button>
        <iov-button accessibilityRole="submit">
          <mat-icon icon-left>share</mat-icon>
          Share
        </iov-button>
      </iov-button-group>
    </mat-dialog-actions>
  }
</form>
