import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";

@Component({
  selector: "iov-sidebar-item",
  templateUrl: "./sidebar-item.component.html",
  styleUrls: ["./sidebar-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SidebarItemComponent {
  @ViewChild("contentRef") set content(value: ElementRef) {
    this.textElement = value;
    this.cdr.detectChanges();
  }

  textElement: ElementRef;

  @Input() active: boolean;

  @Input() icon: string;

  @Input() routePath: string[];

  @Input() showTooltip: boolean;

  @Input() badge: number;

  constructor(private cdr: ChangeDetectorRef) {}

  get tooltipContent(): string {
    return this.textElement?.nativeElement?.textContent;
  }
}
