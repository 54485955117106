<mat-slide-toggle
  (toggleChange)="handleChange()"
  [checked]="checkValue"
  [disableRipple]="true"
  [disabled]="isDisabled || disabled"
  class="toggle"
  labelPosition="before"
>
  <ng-content></ng-content>
</mat-slide-toggle>
