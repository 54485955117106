import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, Inject, OnInit, signal } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { ConnectionsService } from "src/app/shared/services";
import { CustomValidators } from "src/app/shared/validators";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { CommonConstants } from "@shared/constants";
import { IOrganisation } from "@shared/interfaces";
import { CommonUtils, FormUtils } from "@shared/utils";

@Component({
  templateUrl: "./edit-organisation-connection-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditOrganisationConnectionDialogComponent implements OnInit {
  public formGroup: UntypedFormGroup = null;

  public isLoading = signal(true);

  public isEditing = signal(false);

  constructor(
    public dialogRef: MatDialogRef<EditOrganisationConnectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { connection: IOrganisation },
    private connectionsService: ConnectionsService,
    private notificationService: NotificationService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.isEditing.set(!!this.data.connection.publicUri);
    this.setupForm();
    this.isLoading.set(false);
  }

  private setupForm = (): void => {
    this.formGroup = new UntypedFormGroup({
      organisationId: new UntypedFormControl(
        this.data.connection.publicUri
          ? CommonUtils.getUriId(this.data.connection.publicUri)
          : null,
        [CustomValidators.required],
      ),
    });
  };

  public isSubmitButtonDisabled = (): boolean => this.formGroup.invalid || this.formGroup.pending;

  public onSubmit = async (): Promise<void> => {
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);
      this.notificationService.showError(CommonConstants.FILL_REQUIRED_FIELDS_MSG);

      return;
    }
    this.isLoading.set(true);

    const payload: IOrganisation = {
      ...this.data.connection,
      publicUri: `/organisations/${this.formGroup.controls["organisationId"].value.trim()}`,
      recordState: undefined,
    };

    await this.connectionsService
      .createOrUpdate(payload, this.data.connection.id)
      .then(() => {
        this.notificationService.showSuccess(
          `Connection ${this.isEditing() ? "modified" : "created"}`,
        );
        this.onClose(true);
      })
      .catch((error: HttpErrorResponse) => {
        this.notificationService.showError(error);
      })
      .finally(() => {
        this.isLoading.set(false);
      });
  };

  public onClose = (hasSaved = false): void => {
    this.dialogRef.close({ hasSaved });
  };
}
