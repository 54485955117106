@if (initial) {
  <div
    class="clickable main-container"
    [matMenuTriggerFor]="userMenu"
    (menuOpened)="menuOpened()"
    (menuClosed)="menuClosed()"
  >
    <div class="button-arrow" [class]="cssClass">
      <button
        class="user-button"
        matBadge="!"
        matBadgeSize="small"
        matBadgePosition="after"
        matBadgeColor="warn"
        [matBadgeHidden]="!pendingInvitationsCount"
      >
        {{ initial }}
      </button>

      <mat-icon class="arrow-icon" svgIcon="keyboard_arrow_down"></mat-icon>
    </div>
    <mat-menu class="user-account-dropdown" #userMenu="matMenu">
      <iov-dropdown-menu class="user-account-dropdown-menu">
        <div class="user-details">
          @if (userName) {
            <div class="username">{{ userName }}</div>
          }
          <div class="email">{{ userData.email }}</div>
        </div>
        <div class="divider"></div>
        @if (userType === authUserTypeEnum.REGULAR) {
          <iov-dropdown-menu-item (pressed)="onInvitationsClicked()">
            <div class="invitations">
              <div class="option">
                <mat-icon svgIcon="inbox"></mat-icon>
                Invitations
              </div>
              @if (pendingInvitationsCount) {
                <div class="invitations-count">{{ safeInvitationsCount }}</div>
              }
            </div>
          </iov-dropdown-menu-item>
          @if (isLanguageSettingEnabled) {
            <iov-dropdown-menu-item (pressed)="onLanguageClicked()">
              <mat-icon>language</mat-icon>
              Language
            </iov-dropdown-menu-item>
          }
          @if (userData.availableOrganisations?.length) {
            <iov-dropdown-menu-item (pressed)="onSettingClicked()">
              <mat-icon svgIcon="settings"></mat-icon>
              User settings
            </iov-dropdown-menu-item>
          }
          <div class="divider"></div>
        }

        <iov-dropdown-menu-item class="height-52" (pressed)="onLogout()">
          <mat-icon>logout</mat-icon>
          Log out
        </iov-dropdown-menu-item>
      </iov-dropdown-menu>
    </mat-menu>
  </div>
}
