import { IItemPayloadWithTags } from "@shared/interfaces";

export namespace BulkAddItemsCreateItemsModel {
  export interface IPayloadWithStatus {
    status: StatusEnum;
    itemPayload: IItemPayloadWithTags;
  }

  export enum StatusEnum {
    CANCELED = "CANCELED",
    PENDING = "PENDING",
    SUCCESS = "SUCCESS",
    ERROR = "ERROR",
  }
}
