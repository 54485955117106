import { Inject, Injectable } from "@angular/core";

import { APP_CONFIG } from "@shared/tokens";

import { ApiService } from "./api.service";
import { CommonConstants } from "../../constants";
import { IConfig } from "../../interfaces";
import { FormUtils } from "../../utils";

@Injectable({
  providedIn: "root",
})
export class AdminLocationService {
  private readonly baseUrl: string = `${this.environment.baseUrl}admin/locations`;

  constructor(
    private apiService: ApiService,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {}

  public getPage = async (
    orgId: string,
    name: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
  ): Promise<any> => {
    const url = `${this.baseUrl}?${FormUtils.addUrlParams({ orgId, name, size, page, sort })}`;

    return await this.apiService.get<any>(url).toPromise();
  };

  public async getAll(orgId: string): Promise<any[]> {
    const result: any[] = [];
    let page = 0;
    let hasNextPage: boolean = true;

    while (hasNextPage) {
      const {
        content,
        page: { totalElements },
      } = await this.getPage(
        orgId,
        undefined,
        CommonConstants.MAX_API_GET_ITEMS_SIZE,
        page,
        undefined,
      );

      result.push(...content);

      page++;
      hasNextPage = result.length < totalElements;
    }

    return result;
  }

  public get = async (id: string): Promise<any> =>
    await this.apiService.get<any>(`${this.baseUrl}/${id}`).toPromise();
}
