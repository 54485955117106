import { ChangeDetectionStrategy, Component } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  template: `
    <div class="container-flex">
      <div class="value">
        {{ value }}
      </div>
      @if (shouldDisplayIcon()) {
        <mat-icon class="value-icon-tooltip" [tp]="iconTooltip" [tpIsLazy]="true">
          {{ icon }}
        </mat-icon>
      }
    </div>
  `,
  styles: [
    `
      .value {
        min-height: 46px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValueWithTooltipCellRendererComponent implements ICellRendererAngularComp {
  public iconTooltip: string;

  public icon: string;

  public shouldDisplayIcon: () => boolean;

  public params!: ICellRendererParams;

  public value!: string;

  agInit(params: ICellRendererParams): void {
    this.value = params["value"];
    this.iconTooltip = params["iconTooltip"];
    this.shouldDisplayIcon = params["shouldDisplayIcon"];
    this.icon = params["icon"];
  }

  public refresh(): boolean {
    return false;
  }
}
