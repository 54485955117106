import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "iov-shortcut-add-button",
  templateUrl: "./shortcut-add-button.component.html",
  styleUrls: ["./shortcut-add-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShortcutAddButtonComponent {
  @Input() icon: string;

  @Input() text: string;

  @Output() pressed = new EventEmitter<void>();

  public onClick(): void {
    this.pressed.emit();
  }
}
