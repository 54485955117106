import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormArray, FormGroup } from "@angular/forms";

@Component({
  selector: "app-reports-eudr-commodity-info",
  templateUrl: "./reports-eudr-commodity-info.component.html",
  styleUrls: ["./reports-eudr-commodity-info.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ReportsEudrCommodityInfoComponent {
  @Input() index: number;

  @Input() formGroup: FormGroup;

  public get producersInfoFormGroups(): FormGroup[] {
    return this.producersInfoFormArray.controls as FormGroup[];
  }

  public get materialsInfoFormGroups(): FormGroup[] {
    return this.materialsInfoFormArray.controls as FormGroup[];
  }

  public get displayFields(): boolean {
    return this.producersInfoFormArray.value.length;
  }

  private get producersInfoFormArray(): FormArray {
    return this.formGroup["controls"]["producersInfo"] as FormArray;
  }

  private get materialsInfoFormArray(): FormArray {
    return this.formGroup["controls"]["materialsInfo"] as FormArray;
  }
}
