import { inject } from "@angular/core";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { SupplyChainWithOverlaysClass } from "./supply-chain-with-overlays.class";
import { BrowserUtils } from "../utils";

export abstract class GraphScaler extends SupplyChainWithOverlaysClass {
  public height: number;

  public svgId = "node-svg";

  protected isZoomEnabled = true;

  protected notificationService = inject(NotificationService);

  protected readonly isSafari = BrowserUtils.isSafari();

  abstract getMainClass(): any;

  abstract getSvg(): any;

  abstract getSvgGroup(): any;

  abstract getZoom(): any;

  abstract getGraph(): any;

  protected scaleAndCenterGraph(svgElementId: string): void {
    try {
      const chartContainer: any = document.getElementsByClassName(this.getMainClass())[0];

      if (chartContainer) {
        const paddingWidth = this.isZoomEnabled ? 35 : 20;
        const paddingHeight = this.isZoomEnabled ? 5 : 20;

        if (this.isZoomEnabled) {
          let containerWidth = chartContainer.parentElement.offsetWidth - paddingWidth;
          const svgHtmlElement: any = document.getElementsByTagName(svgElementId)[0];

          if (svgHtmlElement) {
            svgHtmlElement.style = `width:${containerWidth}px`;
          }
          const containerHeight = this.height - paddingHeight;

          this.getSvg().attr("height", containerHeight);

          // Zoom to fit
          if (this.getSvgGroup().node()) {
            const { width, height } = this.getSvgGroup().node().getBBox();

            if (width && height) {
              // safari is returning a negative containerWidth
              containerWidth = this.isSafari ? window.innerWidth : containerWidth;
              const scale = Math.min(containerWidth / width, containerHeight / height) * 0.95;

              this.getZoom().scaleTo(this.getSvg(), scale);
              this.getZoom().translateTo(this.getSvg(), width / 2, height / 2);
            }
          }
        } else {
          const graphWidth = (this.getGraph().graph() as any).width;
          const graphHeight = (this.getGraph().graph() as any).height;

          const containerWidth = Math.max(chartContainer.offsetWidth - paddingWidth, graphWidth);
          const svgHtmlElement: any = document.getElementsByTagName(svgElementId)[0];

          if (svgHtmlElement) {
            svgHtmlElement.style = `width:${containerWidth}px`;
          }
          const containerHeight = Math.max(
            chartContainer.offsetHeight - paddingHeight,
            graphHeight + 70,
          );

          this.getSvg().attr("height", containerHeight);

          const xCenterOffset = (containerWidth - graphWidth) / 2;

          this.getSvgGroup().attr("transform", "translate(" + xCenterOffset + ", 20)");
        }
      }
    } catch (error) {
      this.notificationService.showError(error);
    }
  }
}
