import { Pipe, PipeTransform } from "@angular/core";

import { IStandardType } from "src/app/shared/interfaces";

@Pipe({
  name: "getTypeRecordState",
})
export class GetTypeRecordState implements PipeTransform {
  transform(id: string, standardTypes: IStandardType[]): string {
    const state = standardTypes.find((t) => t.id === id).recordState;

    return state;
  }
}
