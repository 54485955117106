import { ComponentType } from "@angular/cdk/overlay";
import { Injectable } from "@angular/core";

import { SlideOverlayPageClass } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.class";

import {
  CertificateOverlayComponent,
  SharedCertificateOverlayComponent,
} from "@components/certificates";
import { DeliveryOverlayComponent, SharedDeliveryOverlayComponent } from "@components/deliveries";
import { DocumentOverlayComponent, SharedDocumentOverlayComponent } from "@components/documents";
import { ItemOverlayComponent } from "@components/items";
import { LocationOverlayComponent, SharedLocationOverlayComponent } from "@components/locations";
import {
  OrganisationOverlayComponent,
  SharedOrganisationOverlayComponent,
} from "@components/organisations";
import { ProcessOverlayComponent } from "@components/processes";
import {
  MaterialOverlayComponent,
  ProductOverlayComponent,
  RiskAssessmentTemplateOverlayComponent,
} from "@components/settings";
import { RoutingEnum } from "@shared/enums";

@Injectable({
  providedIn: "root",
})
export class OverlayBridgeService {
  getComponent(view: string): ComponentType<SlideOverlayPageClass> | null {
    switch (view) {
      case RoutingEnum.OVERLAY_SHARED_LOCATION:
        return SharedLocationOverlayComponent;
      case RoutingEnum.OVERLAY_SHARED_ORGANISATION:
        return SharedOrganisationOverlayComponent;
      case RoutingEnum.OVERLAY_SHARED_DOCUMENT:
        return SharedDocumentOverlayComponent;
      case RoutingEnum.OVERLAY_SHARED_CERTIFICATE:
        return SharedCertificateOverlayComponent;
      case RoutingEnum.OVERLAY_SHARED_DELIVERY:
        return SharedDeliveryOverlayComponent;
      case RoutingEnum.OVERLAY_LOCATION:
        return LocationOverlayComponent;
      case RoutingEnum.OVERLAY_ORGANISATION:
        return OrganisationOverlayComponent;
      case RoutingEnum.OVERLAY_DOCUMENT:
        return DocumentOverlayComponent;
      case RoutingEnum.OVERLAY_CERTIFICATE:
        return CertificateOverlayComponent;
      case RoutingEnum.OVERLAY_DELIVERY:
        return DeliveryOverlayComponent;
      case RoutingEnum.OVERLAY_ITEM:
        return ItemOverlayComponent;
      case RoutingEnum.OVERLAY_PROCESS:
        return ProcessOverlayComponent;
      case RoutingEnum.OVERLAY_PRODUCT:
        return ProductOverlayComponent;
      case RoutingEnum.OVERLAY_MATERIAL:
        return MaterialOverlayComponent;
      case RoutingEnum.OVERLAY_RISK_ASSESSMENT_TEMPLATE:
        return RiskAssessmentTemplateOverlayComponent;
      default:
        return null;
    }
  }
}
