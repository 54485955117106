import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

@Component({
  selector: "app-flag",
  templateUrl: "./flag.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlagComponent implements OnChanges {
  @Input()
  public country: string;

  @Input()
  public size = "small-flag";

  public url: SafeUrl = null;

  constructor(private sanitizer: DomSanitizer) {}

  public ngOnChanges(changes: SimpleChanges): void {
    //todo remove lowercase if flags are uppercase
    if (changes["country"]) {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
        `assets/images/flags/${this.country.toLowerCase()}.svg`,
      );
    }
  }
}
