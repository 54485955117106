<iov-slide-overlay-header
  [canDelete]="false"
  [canShare]="false"
  [canArchive]="false"
  [title]="title()"
  icon="deliveries"
></iov-slide-overlay-header>
<iov-slide-overlay-content #slideOverlayContent>
  <ng-template
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="menu-info"
    overlay-menu-title="Delivery details"
  >
    <iov-form-field-group [name]="mainInformationText">
      <app-edit-delivery-main-details
        class="full-width"
        [formGroup]="formGroup"
        [isEditing]="false"
        [fromLocationOptions]="fromLocationOptions()"
        [toLocationOptions]="toLocationOptions()"
        [organisationsOptions]="organisationsOptions()"
        [locationViewTemplate]="locationViewTemplate"
        [statusOptions]="statusOptions()"
        (traderTagPressed)="traderTagPressed($event)"
      ></app-edit-delivery-main-details>
    </iov-form-field-group>
  </ng-template>

  <ng-template
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.CERTIFICATES).isEnabled"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-indicator]="element()?.certificates?.length"
    [overlay-menu-title]="overlayTabEnum.CERTIFICATES"
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="certificates"
  >
    <article class="field-group-container">
      <app-certificates-table
        [isInboundShared]="true"
        [inboundSharedSenderOrgId]="organisationIdQueryParam"
        [certificates]="element()?.certificates"
      ></app-certificates-table>
    </article>
  </ng-template>
  <ng-template
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.DOCUMENTS).isEnabled"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="overlayTabEnum.DOCUMENTS"
    [overlay-menu-indicator]="element()?.documents?.length"
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="document"
  >
    <article class="field-group-container">
      <app-documents-table
        [isInboundShared]="true"
        [documents]="element()?.documents"
        [inboundSharedSenderOrgId]="organisationIdQueryParam"
      ></app-documents-table>
    </article>
  </ng-template>
  <ng-template #locationViewTemplate let-label="label" let-option>
    <div class="form-field-item">
      <iov-form-label [label]="label"></iov-form-label>
      <div class="form-field-input-readonly">
        @if (option.label && option.value) {
          <iov-form-link [link]="getSharedLocationLink(option.value)" [styledLink]="true">
            {{ option.label }}
          </iov-form-link>
        } @else {
          <app-loader containerClass="size-small"></app-loader>
        }
      </div>
    </div>
  </ng-template>
</iov-slide-overlay-content>
