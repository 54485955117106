import { ChangeDetectionStrategy, Component, Inject, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { ConfirmDialogResponseEnum } from "src/app/shared/enums";
import { CustomValidators } from "src/app/shared/validators";

import { ButtonColor } from "@design-makeover/components/buttons/button/button.model";

@Component({
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent implements OnInit {
  public formGroup: UntypedFormGroup = new UntypedFormGroup({});

  public confirmDialogResponseEnum = ConfirmDialogResponseEnum;

  readonly translations: any = {
    type: "Type",
    toConfirmThisAction: "to confirm this action",
    confirmPhrasePlaceholderTranslatedText: null,
  };

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      titleTranslatedText: string;
      contentTranslatedText?: string;
      contentTranslatedHTML?: string;
      confirmButtonColor?: ButtonColor;
      confirmButtonTranslatedText?: string;
      cancelButtonTranslatedText?: string;
      cancelButtonIcon?: string;
      confirmPhraseTranslatedText?: string;
      confirmButtonIcon?: string;
      thirdActionButtonColor?: ButtonColor;
      thirdActionButtonText?: string;
      thirdActionButtonIcon?: string;
      isSubmitButtonDisabled?: boolean;
      onSubmit?: () => void;
    },
  ) {}

  public ngOnInit(): void {
    if (this.data.confirmPhraseTranslatedText) {
      this.translations.confirmPhrasePlaceholderTranslatedText = `${this.translations.type} "${this.data.confirmPhraseTranslatedText}" ${this.translations.toConfirmThisAction}`;
      this.formGroup = new UntypedFormGroup({
        confirmPhrase: new UntypedFormControl(null, [
          CustomValidators.required,
          CustomValidators.equalValue(this.data.confirmPhraseTranslatedText),
        ]),
      });
    }
  }

  public isSubmitButtonDisabled = (): boolean => this.formGroup?.invalid;

  public onSubmit = async (): Promise<void> => {
    this.data.onSubmit?.();
    this.dialogRef.close(ConfirmDialogResponseEnum.CONFIRM);
  };

  public onClose() {
    this.dialogRef.close(ConfirmDialogResponseEnum.CANCEL);
  }

  thirdActionPerfomed() {
    this.dialogRef.close(ConfirmDialogResponseEnum.THIRD_ACTION);
  }
}
