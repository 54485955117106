export enum UploadFileStatusEnum {
  FILE_UPLOAD_FAILED = "FILE_UPLOAD_FAILED",
  FILE_UPLOAD_START = "FILE_UPLOAD_START",
  FILE_ATTACH_FAILED = "FILE_ATTACH_FAILED",
  FILE_ATTACH_START = "FILE_ATTACH_START",
  FILE_ADD_TAGS_FAILED = "FILE_ADD_TAGS_FAILED",
  DOCUMENT_CREATION_FAILED = "DOCUMENT_CREATION_FAILED",
  DOCUMENT_CREATION_START = "DOCUMENT_CREATION_START",
  DOCUMENT_CREATED = "DOCUMENT_CREATED",
  TAGS_CREATED = "TAGS_CREATED",
  UPLOADED = "UPLOADED",
  ATTACHED = "ATTACHED",
}
