import { QueriesUtils } from "./queries.utils";
import { IProcessGraphQLFilter } from "../interfaces";

/**
 * Generates a GraphQL query to load processes based on specified parameters.
 *
 * @param {string} orgId - (Required) The organization ID for which processes are queried.
 * @param {IProcessGraphQLFilter} filter - An object containing filtering criteria for processes.
 * @param {number} first - The number of processes to retrieve in a single page (for forward pagination).
 * @param {number} last - The number of processes to retrieve in a single page (for backward pagination).
 * @param {string} after - The cursor indicating the end of the previous page for forward pagination.
 * @param {string} before - The cursor indicating the start of the next page for backward pagination.
 * @param {string[]} include - Additional fields to include in the query.
 * @returns {string} The generated GraphQL query for loading processes.
 */

export const getProcessesGraphQLQuery = (
  orgId: string,
  filter: IProcessGraphQLFilter,
  first: number,
  last: number,
  after: string,
  before: string,
  include: string[],
): string => {
  const filterClause = QueriesUtils.generateFilterClause(filter);
  const afterCursorClause = first > 0 ? QueriesUtils.generateClause("after", after) : "";
  const firstClause = first > 0 ? QueriesUtils.generateClause("first", first) : "";
  const beforeCursorClause = last > 0 ? QueriesUtils.generateClause("before", before) : "";
  const lastClause = last > 0 ? QueriesUtils.generateClause("last", last) : "";

  return `
{
    loadProcesses(   
    orgId: "${orgId}",
    ${filterClause ? `${filterClause},` : ""}
    ${afterCursorClause ? `${afterCursorClause},` : ""}
    ${beforeCursorClause ? `${beforeCursorClause},` : ""}
    ${firstClause ? `${firstClause},` : ""}
    ${lastClause ? `${lastClause},` : ""}
) {
        ${getProcessProperties(include)} 
    }
}
`;
};

/**
 * Generates a GraphQL query to load processes based on specified parameters.
 *
 * @param {string} orgId - (Required) The organization ID for which processes are queried.
 * @param {string[]} ids - (Required) A list of processes ids
 * @param {ICertificateGraphQLFilter} filter - An object containing filtering criteria for processes.
 * @param {number} first - The number of processes to retrieve in a single page (for forward pagination).
 * @param {number} last - The number of processes to retrieve in a single page (for backward pagination).
 * @param {string} after - The cursor indicating the end of the previous page for forward pagination.
 * @param {string} before - The cursor indicating the start of the next page for backward pagination.
 * @param {string[]} include - Additional fields to include in the query.
 * @returns {string} The generated GraphQL query for loading processes.
 */

export const getProcessesByIdsGraphQLQuery = (
  orgId: string,
  ids: string[],
  first: number,
  last: number,
  after: string,
  before: string,
  include: string[],
): string => {
  const afterCursorClause = first > 0 ? QueriesUtils.generateClause("after", after) : "";
  const firstClause = first > 0 ? QueriesUtils.generateClause("first", first) : "";
  const beforeCursorClause = last > 0 ? QueriesUtils.generateClause("before", before) : "";
  const lastClause = last > 0 ? QueriesUtils.generateClause("last", last) : "";

  return `
  {
      loadProcessesByIds(   
      orgId: "${orgId}",
      ids: ${QueriesUtils.getParsedIds(ids)},
      ${afterCursorClause ? `${afterCursorClause},` : ""}
      ${beforeCursorClause ? `${beforeCursorClause},` : ""}
      ${firstClause ? `${firstClause},` : ""}
      ${lastClause ? `${lastClause},` : ""}
  ) {       
    ${getProcessProperties(include)}
      }
  }
  `;
};

export const getProcessProperties = (include: string[]): string => `
          pageInfo {
            hasNextPage 
            hasPreviousPage
            startCursor
            endCursor
        }
        totalCount
        edges {
            cursor
            node {
                id
                processId
                firstInputDate
                lastOutputDate
                type {
                    id
                    name
                }
                location {
                    id
                    name
                }
                recordState
                ${QueriesUtils.getQueryIncludes(include)}
            }            
        }
  `;
