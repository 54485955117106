import { AfterViewInit, ChangeDetectionStrategy, Component, Input, signal } from "@angular/core";
import { Router } from "@angular/router";

import { ColDef } from "ag-grid-community";
import { LinkCellRendererComponent } from "src/app/shared/cell-renderers";
import { RecordStateEnum, RoutingEnum, TableEnum } from "src/app/shared/enums";
import { ISupplyChain } from "src/app/shared/interfaces";
import { SupplyChainsService } from "src/app/shared/services";
import { ColumnUtils } from "src/app/shared/utils";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

@Component({
  selector: "app-supply-chains-table",
  templateUrl: "./supply-chains-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplyChainsTableComponent implements AfterViewInit {
  @Input()
  public supplyChains: ISupplyChain[] = null;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isRecordStateFilterEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  @Input()
  public recordState: RecordStateEnum = RecordStateEnum.ALL;

  @Input()
  public table = TableEnum.SUPPLY_CHAINS;

  @Input()
  public isSaveTableState = false;

  public isLoading = signal(true);

  public rowData: ISupplyChain[] = [];

  public columnDefs = signal<ColDef[]>([
    ColumnUtils.recordState(),
    {
      headerName: "Name",
      field: "name",
      lockVisible: true,
      cellRenderer: LinkCellRendererComponent,
      cellRendererParams: {
        linkRoute: RoutingEnum.SUPPLY_CHAINS_DETAILS,
        linkRouteIdParam: "id",
      },
    },
    {
      headerName: "Description",
      field: "description",
      valueFormatter: ColumnUtils.defaultValueFormatter(),
    },
  ]);

  constructor(
    private router: Router,
    private supplyChainsService: SupplyChainsService,
    private notificationService: NotificationService,
  ) {}

  public async ngAfterViewInit() {
    await this.getAll();
  }

  public onViewDetails = async (row: ISupplyChain): Promise<void> => {
    if (!this.areButtonsEnabled) {
      return;
    }
    await this.router.navigate([`/${RoutingEnum.SUPPLY_CHAINS_DETAILS}/${row.id}`]);
  };

  private getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    if (this.supplyChains) {
      this.rowData = this.supplyChains;
      this.isLoading.set(false);
    } else {
      try {
        this.rowData = await this.supplyChainsService.getAll();
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };
}
