<div [formGroup]="formGroup" class="row-container">
  <div class="row">
    <div class="item">
      <iov-input enableViewMode formControlName="name" label="Name"></iov-input>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <iov-input enableViewMode formControlName="website" label="Website"></iov-input>
    </div>
  </div>
</div>
