import { Injectable } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { AuthService } from "./api";
import { AuthenticationService } from "./authentication.service";
import { LocalStorageService } from "./local-storage.service";
import { SetUserOrganisationsService } from "./set-user-organisations.service";
import { AuthUserTypeEnum } from "../enums";
import { ITokenResponse } from "../interfaces";
import { TokenUtils } from "../utils";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  constructor(
    private authenticationService: AuthenticationService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private setUserOrganisationsService: SetUserOrganisationsService,
    private localStorageService: LocalStorageService,
    private router: Router,
  ) {}

  public login = async (
    email: string,
    password: string,
    redirectToPath: string = undefined,
    redirectToExtras: NavigationExtras = {},
  ): Promise<void> => {
    return this.authService.login(email, password).then(async (response: ITokenResponse) => {
      if (!response?.access_token) {
        this.notificationService.showError(null);

        return;
      }
      const parsedToken = TokenUtils.parseJwt(response.access_token);

      if (!parsedToken?.uid) {
        this.notificationService.showError(null);

        return;
      }
      await this.authenticationService.setTokens(
        AuthUserTypeEnum.REGULAR,
        response.access_token,
        response.refresh_token,
      );
      await this.setUserOrganisationsService.setOrganisations();
      await this.setActiveOrganisation();

      if (!redirectToPath) {
        redirectToPath = `/${this.authenticationService.getDefaultRoute()}`;
      }
      await this.router.navigate([redirectToPath], redirectToExtras);
    });
  };

  private setActiveOrganisation = async (): Promise<void> => {
    const availableOrganisations = this.authenticationService.getAvailableOrganisations();
    const userId = this.authenticationService.getUserId();
    const lastUserActiveOrganisationId =
      this.localStorageService.getUserLastActiveOrganisationId(userId);

    if (lastUserActiveOrganisationId) {
      const organisationIndex = availableOrganisations.findIndex(
        (o) => o.id === lastUserActiveOrganisationId,
      );

      await this.authenticationService.setActiveOrganisationIndex(
        organisationIndex !== -1 ? organisationIndex : availableOrganisations.length ? 0 : null,
      );
    } else {
      await this.authenticationService.setActiveOrganisationIndex(
        availableOrganisations.length ? 0 : null,
      );
    }
  };
}
