import {
  AfterViewInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnInit,
  Output,
  signal,
  TemplateRef,
} from "@angular/core";
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl,
  ValidationErrors,
} from "@angular/forms";

import { InputType } from "@design-makeover/components/form/form-input/form-input.model";
import { SlideOverlayPageService } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.service";
import { FormControlService } from "@design-makeover/services/form-control.service";

import { CopyModeEnum } from "@components/shared/value-and-copy-button/value-and-copy-button.model";

@Component({
  selector: "iov-input",
  templateUrl: "./input.component.html",
  styleUrls: ["./input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class InputComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @Input() class: string;

  @Input() hintClass: string;

  @Input() customErrorClass: string;

  @Input() formInputClass: string;

  @Input() hint: string;

  @Input() label: string;

  @Input() placeholder: string;

  @Input() suffix: string;

  @Input() prefix: string;

  @Input() tooltip: string;

  @Input() tooltipIcon: string = "help";

  @Input() iconClass: string;

  @Input() suffixTooltip: string;

  @Input() type: InputType = "text";

  @Input() additionalErrors: ValidationErrors = {};

  @Input() autocomplete: string | boolean = "off";

  @Input({ transform: booleanAttribute }) autofocus: boolean = false;

  @Input({ transform: booleanAttribute }) disabled: boolean = false;

  @Input({ transform: booleanAttribute }) hideOptionalHint: boolean = false;

  @Input({ transform: booleanAttribute }) emitOnInput: boolean = false;

  @Input({ transform: booleanAttribute }) enableViewMode: boolean = false;

  @Input() displayErrorsInTooltip: boolean = false;

  @Input() viewModeTemplate: TemplateRef<unknown>;

  @Input()
  viewModeCopyMode = CopyModeEnum.CONTAINER;

  @Output() blurEvent: EventEmitter<string> = new EventEmitter<string>();

  public isOptional = signal<boolean>(false);

  public isDisabled = signal<boolean>(false);

  ngControl: NgControl;

  textValue: string = "";

  constructor(
    private injector: Injector,
    private formControlService: FormControlService,
    protected overlay: SlideOverlayPageService,
  ) {}

  get hasValidationError(): boolean {
    return Object.keys(this.errors).length > 0 && (this.ngControl.dirty || this.ngControl.touched);
  }

  get errors(): ValidationErrors {
    return { ...this.ngControl.errors, ...this.additionalErrors };
  }

  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl, null);
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngAfterViewInit(): void {
    if (this.ngControl && !this.hideOptionalHint) {
      this.isOptional.set(this.formControlService.calculateOptionalFlag(this.ngControl.control));
    }
  }

  writeValue(value: string): void {
    this.textValue = value;
  }

  registerOnChange(fn: (_: unknown | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled.set(isDisabled);
  }

  handleChange(value: string): void {
    if (value !== this.textValue) {
      this.textValue = value;
      this.onChange(value);
      this.onTouched();
    }
  }

  handleBlur(): void {
    this.blurEvent.emit(this.textValue);
  }

  onInputChange(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;

    if (inputValue !== this.textValue) {
      this.textValue = inputValue;
      this.onChange(inputValue);
      this.onTouched();
    }
  }

  private onChange: (_: unknown | null) => void = () => {};

  private onTouched: () => void = () => {};
}
