@if (isSupplyChainNode) {
  <div class="flow-chart-node" [ngClass]="customClass">
    <div
      class="product-node name clickable"
      [class.hover-visibility-container]="shouldOpenInNewTab"
    >
      {{ product.name }}
      @if (shouldOpenInNewTab) {
        <app-external-link-icon></app-external-link-icon>
      }
    </div>
    @if (product.documents?.length) {
      <div class="attachments">
        <div class="border documents">
          <mat-icon svgIcon="document"></mat-icon>
          <span class="number selected-documents">{{ product?.documents?.length }}</span>
        </div>
      </div>
    }
  </div>
} @else {
  <div class="container-flex-space-between">
    <div class="left-content">
      <div
        [ngClass]="{ ellipsis: type === cardContentTypeEnum.ADD }"
        class="name"
        [tp]="product.name"
        [tpDelay]="500"
        tpPlacement="top-start"
      >
        {{ product.name }}
      </div>
    </div>
    <iov-button-group>
      @if (type === cardContentTypeEnum.ADD) {
        <iov-button size="medium" type="icon">
          <mat-icon icon-medium-18>add</mat-icon>
        </iov-button>
      } @else {
        <iov-button (pressed)="remove.emit()" tp="Remove" size="medium" type="icon">
          <mat-icon icon-medium-18>close</mat-icon>
        </iov-button>
      }
    </iov-button-group>
  </div>
}
