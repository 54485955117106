import { ChangeDetectionStrategy, Component } from "@angular/core";

import { RecordStateEnum, RoutingEnum } from "@shared/enums";
import { AuthenticationService } from "@shared/services";
import { RouterService } from "@shared/services/router.service";

@Component({
  templateUrl: "./settings-products.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsProductsComponent {
  public readonly recordStateEnum = RecordStateEnum;

  public readonly routingEnum = RoutingEnum;

  public readonly canAddModifyEntities = this.authenticationService.canAddModifyEntities();

  constructor(
    private router: RouterService,
    public authenticationService: AuthenticationService,
  ) {}

  public onAdd = async (): Promise<void> => {
    await this.router.navigate(this.router.getProductLink());
  };
}
