import { AbstractControl, ValidationErrors, ValidatorFn, FormGroup } from "@angular/forms";

import { CategoryFormGroup, IndicatorFormGroup } from "./models";

export function uniqueCategoryNameValidator(
  formGroups: () => Array<FormGroup<IndicatorFormGroup | CategoryFormGroup>>,
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isExistingValue = formGroups().some(
      (group) =>
        group.value._type === "category" &&
        group.value.name === control.value &&
        group !== control.parent,
    );

    return isExistingValue ? { uniqueCategoryName: "This category name already exists." } : null;
  };
}
