<div class="container-space-between">
  <app-page-title
    title="Locations"
    hint="Access and manage all your and external locations. Edit, view details, and create new ones to streamline your operations."
  ></app-page-title>
  <iov-button-group [visible]="canAddModifyEntities">
    <iov-button (pressed)="onAdd()">
      <mat-icon icon-left>add</mat-icon>
      Add new
    </iov-button>
  </iov-button-group>
</div>

<app-locations-table
  [recordState]="recordStateEnum.ACTIVE"
  [isSaveTableState]="true"
  [isFixedBottomPaginator]="true"
  [columns]="[
    'recordState',
    'nameWithRulesets',
    'organisationName',
    'types',
    'address.countryName',
    'geoLocation',
    'tags',
  ]"
></app-locations-table>
