<section class="dropdown-button">
  <button
    [disabled]="disabledMainButton"
    (click)="onClickLabel()"
    class="dropdown dropdown-button-text"
    color="primary"
    mat-flat-button
    type="button"
  >
    {{ label }}
    @if (labelIcon) {
      <mat-icon icon-left>{{ labelIcon }}</mat-icon>
    }
  </button>
  <button
    [disabled]="disabledToggle"
    [ngClass]="{ active: open }"
    (menuClosed)="setState()"
    (menuOpened)="setState()"
    [matMenuTriggerFor]="menu"
    class="dropdown dropdown-button-icon dropdown-button"
    color="primary"
    mat-flat-button
    type="button"
  >
    <mat-icon>{{ icon }}</mat-icon>
  </button>
</section>
<mat-menu #menu yPosition="below" xPosition="before" class="dropdown-menu">
  <ng-content select="iov-dropdown-menu"></ng-content>
</mat-menu>
