import { ChangeDetectionStrategy, Component, Inject, signal } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { BulkAddItemsModel } from "@components/items/bulk-add-items/bulk-add-items.component.model";
import { BulkAddItemsEditFieldDialogModel as Model } from "@components/items/bulk-add-items/edit-field-dialog/bulk-add-items-edit-field-dialog.model";
import { DateTypeEnum, EntityTypeEnum } from "@shared/enums";
import { ITagExtended } from "@shared/interfaces";

@Component({
  templateUrl: "./bulk-add-items-edit-field-dialog.component.html",
  styleUrls: ["./bulk-add-items-edit-field-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkAddItemsEditFieldDialogComponent {
  public formGroup: FormGroup<BulkAddItemsModel.SetValuesFormGroup>;

  public fieldType: Model.FieldTypeEnum;

  public initialValue: any;

  public readonly fieldTypeEnum = Model.FieldTypeEnum;

  public readonly dateTypeEnum = DateTypeEnum;

  public params: any;

  public isLoading = signal<boolean>(true);

  public entityTypeEnum = EntityTypeEnum;

  constructor(
    public dialogRef: MatDialogRef<BulkAddItemsEditFieldDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.formGroup = data.formGroup;

    this.fieldType = data.fieldType || Model.defaultFieldType;

    this.params = data.params;

    this.initialValue = this.formGroup.controls[this.formControlName].value;

    this.isLoading.set(false);
  }

  public get isDatePickerFieldType(): boolean {
    return this.fieldType === Model.FieldTypeEnum.DATE_PICKER;
  }

  public get formControlName(): string {
    if (!this.isDatePickerFieldType) {
      return this.data.formControlName;
    }

    if (
      this.data.formGroup.controls[BulkAddItemsModel.FieldEnum.DATE_TYPE].value ===
      DateTypeEnum.EXACT
    ) {
      return this.params.fromFormControlName;
    } else {
      return this.params.rangeFormControlName;
    }
  }

  public get control(): FormControl {
    return this.formGroup.controls[this.formControlName];
  }

  public get label(): string {
    return this.params.label || BulkAddItemsModel.fieldEnumToLabelMap[this.formControlName];
  }

  public get isSubmitButtonDisabled(): boolean {
    return this.control.invalid || this.control.pending || this.control.pristine;
  }

  public onSubmit(): void {
    this.dialogRef.close({ hasSaved: true, newValue: this.control.value });
  }

  public onTagsChanged(tags: ITagExtended[]): void {
    this.control.setValue(tags);
    this.control.markAsTouched();
    this.control.markAsDirty();
  }

  public onClose(hasSaved = false): void {
    this.dialogRef.close({ hasSaved, initialValue: this.initialValue });
  }
}
