@if (enableViewMode && overlay.viewMode()) {
  <iov-form-input-readonly
    [value]="textValue | date: dateFormatText"
    [label]="label"
  ></iov-form-input-readonly>
} @else {
  <ng-container [ngTemplateOutlet]="editView"></ng-container>
}

<ng-template #editView>
  <div [class]="class" [ngClass]="{ invalid: hasValidationError, 'form-field-item': true }">
    @if (label) {
      <div class="label-container">
        <iov-form-label [label]="label"></iov-form-label>
      </div>
    }

    <div [class.invalid]="hasValidationError" class="input-container with-datepicker-toggle">
      <input
        #dateInput
        (dateInput)="handleChange($event)"
        (input)="handleChange($event)"
        [disabled]="isDisabled()"
        [matDatepicker]="picker"
        [max]="maxDateValue"
        [min]="minDateValue"
        [placeholder]="dateFormat"
        [value]="textValue"
        matInput
        type="text"
      />
      <mat-icon
        (click)="onInputClear()"
        class="suffix-icon clear-icon"
        [hidden]="shouldHideClearButton"
        matSuffix
        tp="Clear value"
      >
        cancel
      </mat-icon>

      @if (!isDisabled()) {
        <button
          type="button"
          class="datepicker-toggle clickable"
          (click)="handleCalendarClick($event)"
        >
          <mat-icon svgIcon="calendar"></mat-icon>
        </button>
      }
    </div>

    <mat-datepicker
      #picker
      [calendarHeaderComponent]="datepickerHeader"
      color="accent"
      panelClass="design-makeover"
    ></mat-datepicker>

    <iov-form-hint
      [errors]="ngControl.errors"
      [isDisabled]="isDisabled()"
      [isOptional]="isOptional()"
      [showError]="hasValidationError"
    ></iov-form-hint>
  </div>
</ng-template>
