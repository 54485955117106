@if (enableViewMode && overlay.viewMode()) {
  @if (viewModeTemplate) {
    <ng-container
      [ngTemplateOutlet]="viewModeTemplate"
      [ngTemplateOutletContext]="{
        $implicit: { label, value: textValue },
      }"
    ></ng-container>
  } @else {
    <iov-form-input-readonly
      [value]="textValue"
      [label]="label"
      [copyMode]="viewModeCopyMode"
    ></iov-form-input-readonly>
  }
} @else {
  <ng-container [ngTemplateOutlet]="editView"></ng-container>
}

<ng-template #editView>
  <div
    [class]="class ? 'form-field-item ' + class : 'form-field-item'"
    [ngClass]="{ invalid: hasValidationError }"
  >
    @if (label) {
      <div class="label-container">
        <iov-form-label
          [label]="label"
          [icon]="tooltipIcon"
          [iconClass]="iconClass"
          [tooltip]="tooltip"
        ></iov-form-label>
        <ng-content select="[icon-hint]"></ng-content>
      </div>
    }

    <iov-form-input
      (blurEvent)="handleBlur()"
      (input)="type === 'number' ? onInputChange($event) : null"
      (keyUpEvent)="handleChange($event)"
      [autocomplete]="autocomplete"
      [autofocus]="autofocus"
      [disabled]="isDisabled() || disabled"
      [emitOnInput]="emitOnInput"
      [hasValidationError]="hasValidationError"
      [displayErrorsInTooltip]="displayErrorsInTooltip"
      [errors]="errors"
      [inputType]="type"
      [placeholder]="placeholder ?? label"
      [searchQuery]="textValue"
      [class]="formInputClass"
    >
      @if (prefix) {
        <span prefix-text>{{ prefix }}</span>
      }
      @if (suffix) {
        <span suffix-text [tp]="suffixTooltip">{{ suffix }}</span>
      }
    </iov-form-input>

    <iov-form-hint
      [customClass]="hintClass"
      [customErrorClass]="customErrorClass"
      [errors]="errors"
      [hint]="hint"
      [isOptional]="isOptional()"
      [isDisabled]="isDisabled() || disabled"
      [showError]="hasValidationError"
    ></iov-form-hint>
  </div>
</ng-template>
