import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from "@angular/core";

import { debounceTime, Subject, Subscription } from "rxjs";

import { CommonConstants } from "@shared/constants";

export type searchSize = "default" | "small";

@Component({
  selector: "iov-input-search",
  templateUrl: "./input-search.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputSearchComponent implements OnDestroy {
  @Input() searchQuery: string = "";

  @Input() placeholder: string;

  @Input() size: searchSize = "default";

  @Input({ transform: booleanAttribute }) disabled: boolean;

  @Output() searchEvent: EventEmitter<string> = new EventEmitter();

  private searchSubject: Subject<string> = new Subject<string>();

  private subscriptions: Subscription = new Subscription();

  constructor() {
    this.subscriptions.add(
      this.searchSubject
        .pipe(debounceTime(CommonConstants.DEBOUNCE_TIME_MS))
        .subscribe((searchQuery) => this.searchEvent.emit(searchQuery)),
    );
  }

  get showClearButton(): boolean {
    return !this.disabled && this.searchQuery?.length > 0;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  handleSearch(value: string): void {
    this.updateSearchQuery(value);
  }

  handleClear(): void {
    this.updateSearchQuery("");
  }

  private updateSearchQuery(value: string): void {
    this.searchQuery = value;
    this.searchSubject.next(value.trim());
  }
}
