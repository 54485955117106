import { computed, signal } from "@angular/core";

import { ICellRendererParams } from "ag-grid-community";

export abstract class BaseActionsCellRenderer {
  params = signal<ICellRendererParams>(undefined);

  isDisabled = (button): boolean => {
    if (!button.disabled) {
      return false;
    }
    if (typeof button.disabled === "boolean") {
      return button.disabled;
    }

    return button.disabled(this.data());
  };

  tooltip = (button): string => {
    if (!button.tooltip) {
      return undefined;
    }
    if (typeof button.tooltip === "string") {
      return button.tooltip;
    }

    return button.tooltip(this.data());
  };

  iconClass = (button): string => {
    if (!button.iconClass) {
      return "";
    }
    if (typeof button.iconClass === "string") {
      return button.iconClass;
    }

    return button.iconClass(this.data());
  };

  refresh(params: any): boolean {
    this.params.set(params);

    return true;
  }

  buttons = computed(() => this.params()["actions"] || []);

  show(button) {
    return (!!button.show && button.show(this.data())) || !button.show;
  }

  data = computed(() => {
    const params = this.params();

    return params.data ?? params.node.allLeafChildren[0].data;
  });
}
