<div class="cell-container">
  @if (warningTooltipText()) {
    <div class="cell-warning">
      <mat-icon
        class="suffix-icon error warn-icon"
        svgIcon="alert-triangle"
        [tp]="warningTooltipText()"
      ></mat-icon>
    </div>
  }
  <div class="cell-text" [class.with-warning]="warningTooltipText()" role="row">
    @if (hasLink()) {
      <iov-form-link [link]="link()" [openInNewTab]="params()['openInNewTab']">
        {{ value() }}
      </iov-form-link>
    } @else {
      {{ value() }}
    }
  </div>
  <div class="cell-actions">
    @for (button of buttons(); track button) {
      @if (show(button)) {
        <iov-button
          stopPropagation
          [tp]="button.tooltipTemplate ? tooltipTemplate : tooltip(button)"
          [tpIsLazy]="true"
          [disabled]="isDisabled(button)"
          (pressed)="button.click && button.click(this.data(), $event)"
          type="icon"
        >
          @if (button.svgIcon) {
            <mat-icon [class]="iconClass(button)" icon-small [svgIcon]="button.svgIcon"></mat-icon>
          } @else {
            <mat-icon [class]="iconClass(button)" icon-small>{{ button.icon }}</mat-icon>
          }
        </iov-button>

        <ng-template #tooltipTemplate>
          @switch (button.tooltipTemplate) {
            @case ("orbifyDeforestation") {
              <div>
                <b>Project area (ha):</b>
                {{
                  data()?.providerResponse?.projectAreaHa
                    ? (data().providerResponse.projectAreaHa | number: defaultNumberRounding)
                    : "-"
                }}
              </div>
              <div>
                <b>Lost forest area (ha):</b>
                @if (data()?.providerResponse?.lostForestAreaHa) {
                  {{ data().providerResponse.lostForestAreaHa | number: defaultNumberRounding }}
                  {{
                    data()?.providerResponse?.lostForestAreaPercent
                      ? "(" +
                        (data().providerResponse.lostForestAreaPercent
                          | number: defaultNumberRounding) +
                        "%)"
                      : ""
                  }}
                } @else {
                  -
                }
              </div>
            }
          }
        </ng-template>
      }
    }
  </div>
</div>
