<div class="container-space-between"></div>
<app-page-title
  [goBackRoute]="routingEnum.INBOX"
  [title]="inboundSupplyChain()?.name ?? 'Supply chain details'"
  backText="Back to Inbox"
  hint="Click and drag to navigate the supply chain and use the mouse wheel / touch pad to zoom in/out."
></app-page-title>

@if (isLoading()) {
  <app-loader></app-loader>
} @else {
  <app-supply-chain-flow-chart
    [isZoomEnabled]="true"
    [fullScreenOptionEnabled]="false"
    [locations]="locations()"
    [links]="locationLinks()"
    [height]="supplyChainHeight()"
    [isInboundShared]="true"
    [inboundSharedSenderOrgId]="senderOrganisationId"
  ></app-supply-chain-flow-chart>
}
