import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

import { Subscription } from "rxjs";
import { CommonUtils } from "src/app/shared/utils";

import { SlideOverlayPageService } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.service";

import { GeographicalSupplyChainMap } from "@components/shared/map/geographical-supply-map";
import { IGeographicalMapLocation } from "@components/shared/map/supply-chain-map.model";
import { LinkDirectionEnum, RecordStateEnum, ResourceTypeEnum, RoutingEnum } from "@shared/enums";
import {
  IAddress,
  IDocumentType,
  ILocationExtended,
  ILocationLinkDetail,
  IRecordResponse,
  ISelectOption,
} from "@shared/interfaces";
import {
  AuthenticationService,
  CommonService,
  DocumentTypesService,
  LocationsService,
  RulesetsService,
} from "@shared/services";
import { RouterService } from "@shared/services/router.service";
import { RulesetUtils } from "@shared/utils/ruleset.utils";

import { NotificationService } from "./../../../design-makeover/services/notification/notification.service";
import { LocationsLinksService } from "./../../../shared/services/api/locations-links.service";

@Component({
  templateUrl: "./full-supply-chain.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullSupplyChainComponent implements AfterViewInit, OnDestroy {
  public allLocations: ILocationExtended[];

  public allLocationLinks: ILocationLinkDetail[];

  public allDocumentTypes: IDocumentType[];

  public locationsRulesetsRecords: IRecordResponse[];

  private documentTypesService = inject(DocumentTypesService);

  private locationsService = inject(LocationsService);

  private LocationsLinksService = inject(LocationsLinksService);

  private authenticationService = inject(AuthenticationService);

  private notificationService = inject(NotificationService);

  private rulesetsService = inject(RulesetsService);

  private commonService = inject(CommonService);

  private slideOverlayPageService = inject(SlideOverlayPageService);

  private countryOptions: ISelectOption[];

  public isLoading = signal(true);

  public supplyChainHeight: number;

  public selectedTabIndex: number = 0;

  public geographicalSupplyChainMap: GeographicalSupplyChainMap;

  public orgName: string;

  private subscriptions = new Subscription();

  public readonly routingEnum = RoutingEnum;

  public readonly canAddModifyEntities = this.authenticationService.canAddModifyEntities();

  constructor(
    private routerService: RouterService,
    private cd: ChangeDetectorRef,
  ) {
    this.commonService.countriesOptionsObservable$
      .pipe(takeUntilDestroyed())
      .subscribe((countriesOptions: ISelectOption[]) => {
        this.countryOptions = countriesOptions;
      });

    this.subscriptions.add(
      this.slideOverlayPageService.trigger$.subscribe(async (params) => {
        if (params?.hasSaved) {
          await this.refresh();
        }
      }),
    );
  }

  async ngAfterViewInit(): Promise<void> {
    await this.refresh();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public addLocation(): void {
    this.routerService.navigate(this.routerService.getLocationLink());
  }

  private async refresh(): Promise<void> {
    this.isLoading.set(true);
    this.supplyChainHeight = undefined;

    try {
      this.orgName = this.authenticationService.getActiveOrganisation().name;
      this.allLocations = (
        await this.locationsService.getAllGraphQL(
          { recordState: RecordStateEnum.ACTIVE },
          undefined,
          ["DOCUMENTS", "CERTIFICATES", "GEOLOCATION", "TAGS"],
        )
      ).map((l) => {
        const address: IAddress = {
          ...l?.address,
          countryName: this.countryOptions?.find((c) => c.value === l?.address?.country)?.label,
        };
        const certificates = l.certificates.map((c) => ({ ...c, isSelected: true }));
        const documents = l.documents.map((d) => ({ ...d, isSelected: true }));

        return { ...l, address, certificates, documents };
      });
      this.allLocationLinks = (await this.LocationsLinksService.getAll(LinkDirectionEnum.BOTH)).map(
        (link) => ({
          from: { id: CommonUtils.getUriId(link.from), name: "" },
          to: { id: CommonUtils.getUriId(link.to), name: "" },
        }),
      );
      this.allDocumentTypes = await this.documentTypesService.getAll();
      const geographicalMapLocation: IGeographicalMapLocation[] = this.allLocations.map(
        (location) => ({
          id: location.id,
          name: location.name,
          geoLocation: (location as any).geoLocation?.featureCollection,
          address: {
            country: location.address?.country,
          },
        }),
      );

      this.geographicalSupplyChainMap = new GeographicalSupplyChainMap(
        geographicalMapLocation,
        this.allLocationLinks.map((l) => ({
          to: l.to.id,
          from: l.from.id,
        })),
      );

      if (this.authenticationService.isRegularUser()) {
        const locationsIds = this.allLocations.map((l) => l.id);

        this.locationsRulesetsRecords = await this.getRulesetRecords(
          locationsIds,
          ResourceTypeEnum.LOCATION,
          this.authenticationService.getActiveOrganisationId(),
        );
      }
      this.isLoading.set(false);
      this.cd.detectChanges();
      this.supplyChainHeight = CommonUtils.getNonOverlaySupplyChainHeight();
    } catch (error) {
      this.notificationService.showError(error);
    }
  }

  private async getRulesetRecords(
    entitiesIds: string[],
    resourceType: ResourceTypeEnum,
    organisationId: string,
  ): Promise<IRecordResponse[]> {
    const route = RulesetUtils.getResourceRoute(resourceType);
    const uniqueIds = new Set(entitiesIds);
    const records = [...uniqueIds].map((id) => `/organisations/${organisationId}/${route}/${id}`);

    return await this.rulesetsService.getRulesetBasedOnRecord({
      resourceType,
      records,
    });
  }

  public onTabIndexChange(idx: number): void {
    this.selectedTabIndex = idx;
  }
}
