<div
  [class.has-filter]="isRecordStateFilterEnabled"
  [formGroup]="formGroup"
  class="search-filters-container"
>
  <iov-input-search
    (searchEvent)="onSearch($event)"
    [searchQuery]="searchText"
    placeholder="Search..."
    size="small"
  ></iov-input-search>
  @if (isRecordStateFilterEnabled) {
    <iov-input-select
      [options]="recordStateOptions"
      [showClear]="canClearSelection"
      emitRawValue
      formControlName="recordState"
      inputOnlyMode
      label="Status"
    ></iov-input-select>
  }
</div>
