<div class="row-container">
  <form [formGroup]="formGroup">
    <h3>General</h3>
    <div class="row row-2">
      <div class="item">
        <iov-input formControlName="name" label="Name"></iov-input>
      </div>
      <div class="item">
        <iov-input formControlName="description" label="Description"></iov-input>
      </div>
    </div>
    <h3>
      Criteria
      <mat-icon
        class="info-icon"
        tp="Specify the characteristics of the deliveries to which this ruleset should apply"
      >
        help
      </mat-icon>
    </h3>
    <p class="dark-gray">
      <small>Specifying no criteria will make the ruleset applicable to all deliveries.</small>
    </p>

    <h3>
      Deliveries "from" location
      <mat-icon
        class="info-icon"
        tp="Specify which characteristics a location mentioned in the delivery's
                'from' field should have for the ruleset to apply."
      >
        help
      </mat-icon>
    </h3>
    <div>
      <mat-radio-group>
        <mat-radio-button
          (change)="deliveryFromCriteriaChanged.emit(CriteriaTypeEnum.PARAMETERS)"
          [checked]="deliveryFromCriteria === CriteriaTypeEnum.PARAMETERS"
          [value]="CriteriaTypeEnum.PARAMETERS"
        >
          Parameters
        </mat-radio-button>
        <mat-radio-button
          (change)="deliveryFromCriteriaChanged.emit(CriteriaTypeEnum.EXACT)"
          [checked]="deliveryFromCriteria === CriteriaTypeEnum.EXACT"
          [value]="CriteriaTypeEnum.EXACT"
        >
          Exact
        </mat-radio-button>
      </mat-radio-group>
      <br />
      <br />
      @switch (deliveryFromCriteria) {
        @case (CriteriaTypeEnum.PARAMETERS) {
          <app-location-criteria-info
            #deliveryFrom
            formControlPrefix="from"
            [formGroup]="formGroup"
            (newLocationTypeCreated)="newLocationTypeCreated.emit()"
            [allCountriesOption]="allCountriesOption"
          ></app-location-criteria-info>
        }
        @case (CriteriaTypeEnum.EXACT) {
          <div class="row row-1">
            <div class="item">
              <iov-input-chips
                #fromLocationSelect
                formControlName="fromLocations"
                [options]="allLocationOptionsExceptSelectedBy('toLocations')"
                label="From location(s)"
                tooltip="Select the delivery's 'location(s) from' from the list"
              ></iov-input-chips>
            </div>
          </div>
        }
      }
    </div>

    <h3>
      Deliveries "to" location
      <mat-icon
        class="info-icon"
        tp="Specify which characteristics a location mentioned in the delivery's
                'to' field should have for the ruleset to apply."
      >
        help
      </mat-icon>
    </h3>
    <div>
      <mat-radio-group>
        <mat-radio-button
          (change)="deliveryToCriteriaChanged.emit(CriteriaTypeEnum.PARAMETERS)"
          [checked]="deliveryToCriteria === CriteriaTypeEnum.PARAMETERS"
          [value]="CriteriaTypeEnum.PARAMETERS"
        >
          Parameters
        </mat-radio-button>
        <mat-radio-button
          (change)="deliveryToCriteriaChanged.emit(CriteriaTypeEnum.EXACT)"
          [checked]="deliveryToCriteria === CriteriaTypeEnum.EXACT"
          [value]="CriteriaTypeEnum.EXACT"
        >
          Exact
        </mat-radio-button>
      </mat-radio-group>
      <br />
      <br />
      @switch (deliveryToCriteria) {
        @case (CriteriaTypeEnum.PARAMETERS) {
          <app-location-criteria-info
            #deliveryTo
            formControlPrefix="to"
            [formGroup]="formGroup"
            (newLocationTypeCreated)="newLocationTypeCreated.emit()"
            [allCountriesOption]="allCountriesOption"
          ></app-location-criteria-info>
        }
        @case (CriteriaTypeEnum.EXACT) {
          <div class="row row-1">
            <div class="item">
              <iov-input-chips
                #toLocationSelect
                formControlName="toLocations"
                [options]="allLocationOptionsExceptSelectedBy('fromLocations')"
                label="To location(s)"
                tooltip="Select the delivery's 'location(s) from' from the list"
              ></iov-input-chips>
            </div>
          </div>
        }
      }
    </div>

    <h3>
      Expectations
      <mat-icon
        class="info-icon"
        tp="Specify which expectations need to be met for the delivery not
                to be flagged as not meeting the ruleset requirements"
      >
        help
      </mat-icon>
    </h3>
    <h4 class="dark-gray">
      Document types
      <iov-button
        (pressed)="openDocumentTypeDialog.emit()"
        class="icon-add-circle"
        tp="Add document type"
        type="icon"
      >
        <mat-icon icon-medium-18>add</mat-icon>
      </iov-button>
    </h4>
    <p class="dark-gray">
      <small>
        Specify which documents should be attached to a delivery based on the ruleset criteria. At
        least one should be specified.
      </small>
    </p>
  </form>
</div>
