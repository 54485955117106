import { Injectable } from "@angular/core";

import { CommonService } from "./api";
import { AuthenticationService } from "./authentication.service";
import { FeatureFlagService } from "./feature-flag.service";
import { LocalizationService } from "./localization.service";
import { IconService } from "../../design-makeover/services/icon.service";

@Injectable({
  providedIn: "root",
})
export class AppInitService {
  constructor(
    private commonService: CommonService,
    private iconService: IconService,
    private localizationService: LocalizationService,
    private authenticationService: AuthenticationService,
    private featureFlagService: FeatureFlagService,
  ) {}

  public init = async (): Promise<void> => {
    return await this.loadData();
  };

  private loadData = async () => {
    this.iconService.registerIcons();
    this.localizationService.loadTranslations();
    await this.commonService.loadCountries();
    await this.featureFlagService.init(
      this.authenticationService.getActiveOrganisationId(),
      this.authenticationService.getUserId(),
    );
  };
}
