<button type="button" class="clickable" (click)="onClick()">
  <div class="content">
    <div class="icon-container">
      @if (setting.svgIcon) {
        <mat-icon class="setting-icon" [svgIcon]="setting.svgIcon" />
      } @else {
        <mat-icon class="setting-icon">{{ setting.icon }}</mat-icon>
      }
    </div>
    <div class="text">
      <div class="title">{{ setting.title }}</div>
      <div class="description">{{ setting.description }}</div>
    </div>
    <div class="ellipsis">
      <mat-icon class="arrow-icon" svgIcon="arrow_forward"></mat-icon>
    </div>
  </div>
</button>
