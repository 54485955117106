import { Inject, Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { APP_CONFIG } from "@shared/tokens";

import { ApiService } from "./api.service";
import { CommonConstants } from "../../constants";
import { ITagDefinition, ITagDefinitionPayload, IPageableContent, IConfig } from "../../interfaces";
import { FormUtils } from "../../utils";
import { AuthenticationService } from "../authentication.service";

@Injectable({
  providedIn: "root",
})
export class TagDefinitionsService {
  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {}

  private getBaseUrl = (): string =>
    `${this.environment.baseUrl}organisations/${this.authenticationService.getActiveOrganisationId()}/tag-definitions`;

  public getPage = async (
    title: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
  ): Promise<IPageableContent<ITagDefinition>> => {
    const url = `${this.getBaseUrl()}?${FormUtils.addUrlParams({ title, size, page, sort })}`;

    return await this.apiService.get<IPageableContent<ITagDefinition>>(url).toPromise();
  };

  public getPageSubscription = (
    title: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
  ): Observable<IPageableContent<ITagDefinition>> => {
    const url = `${this.getBaseUrl()}?${FormUtils.addUrlParams({ title, size, page, sort })}`;

    return this.apiService.get<IPageableContent<ITagDefinition>>(url);
  };

  public async getAll(): Promise<ITagDefinition[]> {
    const result: ITagDefinition[] = [];
    let page = 0;
    let hasNextPage: boolean = true;

    while (hasNextPage) {
      const {
        content,
        page: { totalElements },
      } = await this.getPage(undefined, CommonConstants.MAX_API_GET_ITEMS_SIZE, page, undefined);

      result.push(...content);

      page++;
      hasNextPage = result.length < totalElements;
    }

    return result;
  }

  public get = async (id: string): Promise<ITagDefinition> =>
    await this.apiService.get<ITagDefinition>(`${this.getBaseUrl()}/${id}`).toPromise();

  public delete = async (id: string): Promise<void> =>
    await this.apiService.delete<void>(`${this.getBaseUrl()}/${id}`).toPromise();

  public createOrUpdate = async (
    payload: ITagDefinitionPayload,
    id?: string,
  ): Promise<ITagDefinition> => {
    if (id) {
      return await this.apiService
        .put<ITagDefinition>(`${this.getBaseUrl()}/${id}`, payload)
        .toPromise();
    } else {
      return await this.apiService
        .post<ITagDefinition>(`${this.getBaseUrl()}`, payload)
        .toPromise();
    }
  };
}
