import { Inject, Injectable } from "@angular/core";

import { lastValueFrom } from "rxjs";

import { APP_CONFIG } from "@shared/tokens";

import { ApiService } from "./api.service";
import { CommonConstants } from "../../constants";
import { RecordStateEnum } from "../../enums";
import { IConfig, IRecordState, IPageableContent, IStandard } from "../../interfaces";
import { FormUtils } from "../../utils";

@Injectable({
  providedIn: "root",
})
export class StandardsService {
  private readonly baseUrl: string = `${this.environment.baseUrl}common/standards`;

  constructor(
    private apiService: ApiService,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {}

  public getPage = async (
    name: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
    recordState: RecordStateEnum = undefined,
  ): Promise<IPageableContent<IStandard>> => {
    const url = `${this.baseUrl}?${FormUtils.addUrlParams({ name, size, page, sort, recordState })}`;

    return await this.apiService.get<IPageableContent<IStandard>>(url).toPromise();
  };

  public async getAll(recordState: RecordStateEnum = undefined): Promise<IStandard[]> {
    const result: IStandard[] = [];
    let page = 0;
    let hasNextPage: boolean = true;

    while (hasNextPage) {
      const {
        content,
        page: { totalElements },
      } = await this.getPage(
        undefined,
        CommonConstants.MAX_API_GET_ITEMS_SIZE,
        page,
        undefined,
        recordState,
      );

      result.push(...content);

      page++;
      hasNextPage = result.length < totalElements;
    }

    return result;
  }

  public get = async (id: string): Promise<IStandard> =>
    await this.apiService.get<IStandard>(`${this.baseUrl}/${id}`).toPromise();

  public createOrUpdate = async (payload: IStandard, id?: string): Promise<IStandard> => {
    if (id) {
      return await lastValueFrom(this.apiService.put<IStandard>(`${this.baseUrl}/${id}`, payload));
    } else {
      return await lastValueFrom(this.apiService.post<IStandard>(`${this.baseUrl}`, payload));
    }
  };

  public setRecordState = async (payload: IRecordState, id: string): Promise<IStandard> =>
    await lastValueFrom(this.apiService.put<IStandard>(`${this.baseUrl}/${id}`, payload));
}
