import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

import { InputSelectOption } from "@design-makeover/components/inputs/input-select/input-select.model";
import { SlideOverlayContentComponent } from "@design-makeover/components/overlay/slide-overlay-content/slide-overlay-content.component";
import { SlideOverlayPageClass } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.class";

import { LocationOverlayMainInformationComponent } from "@components/locations";
import { CommonConstants } from "@shared/constants";
import {
  AttachmentTargetEnum,
  EntityTypeEnum,
  GeoJsonTypeEnum,
  OverlayTabEnum,
  RoutingEnum,
} from "@shared/enums";
import { ILocationExtended, IOrganisation, ISelectOption } from "@shared/interfaces";
import { CommonService, ConnectionsService, RecordSharingService } from "@shared/services";

import { SharedLocationOverlayService } from "./shared-location-overlay.service";

@Component({
  selector: "app-shared-location-overlay",
  templateUrl: "./shared-location-overlay.component.html",
  styleUrl: "../location-overlay-common.scss",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SharedLocationOverlayComponent
  extends SlideOverlayPageClass
  implements OnInit, OnDestroy
{
  @ViewChild("informationView") informationView: LocationOverlayMainInformationComponent;

  @ViewChild("slideOverlayContent") override slideOverlayContent: SlideOverlayContentComponent;

  formGroup: UntypedFormGroup;

  override menuItems = signal(
    new Map([
      [OverlayTabEnum.DETAILS, { isEnabled: true }],
      [OverlayTabEnum.GPS_DATA, { isEnabled: true }],
      [OverlayTabEnum.DOCUMENTS, { isEnabled: true }],
      [OverlayTabEnum.CERTIFICATES, { isEnabled: true }],
    ]),
  );

  override element = signal<ILocationExtended>(null);

  override entityType = EntityTypeEnum.LOCATIONS;

  override attachmentTargetType = AttachmentTargetEnum.LOCATION;

  allOrganisations: IOrganisation[] = [];

  organisationOptions: ISelectOption[] = [];

  allCountries: ISelectOption[] = [];

  public readonly attachmentTargetEnum = AttachmentTargetEnum;

  constructor(
    private recordSharingService: RecordSharingService,
    private commonService: CommonService,
    private connectionsService: ConnectionsService,
    public sharedLocationOverlayService: SharedLocationOverlayService,
  ) {
    super();
    this.subscriptions.add(
      this.commonService.countriesOptionsObservable$.subscribe(
        (countriesOptions: ISelectOption[]) => {
          this.allCountries = countriesOptions;
        },
      ),
    );
  }

  async ngOnInit(): Promise<void> {
    this.overlay.showLoading();
    if (!this.isOnCorrectOverlay(RoutingEnum.OVERLAY_SHARED_LOCATION)) {
      return;
    }
    if (!this.recordId || !this.organisationIdQueryParam) {
      this.notification.showError("Missing parameters");

      return;
    }

    //this.setCountersToLoadingState();
    await this.reloadElement(this.recordId);
    await this.setMenuItemFromURLParam();
    //this.loadCounters();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    //this.setCountersEmptyState();
  }

  // setCountersToLoadingState() {
  //     this.locationOverlay.setCountersToLoadingState();
  // }

  // setCountersEmptyState() {
  //     this.locationOverlay.setCountersEmptyState();
  // }

  override setupForm = async (): Promise<void> => {
    const types: InputSelectOption[] = this.element()?.types.map((type) => ({
      label: type.type,
      value: type.id,
      icon: type.pointOfOrigin ? "target" : undefined,
      iconTooltip: type.pointOfOrigin ? CommonConstants.POINT_OF_ORIGIN_CHIP_TEXT : undefined,
    }));

    this.formGroup = this.formBuilder.group({
      organisation: [this.organisationOptions[0]],
      name: [this.element()?.name ?? null],
      street: [this.element()?.address?.street ?? null],
      region: [this.element()?.address?.region ?? null],
      zipCode: [this.element()?.address?.zipCode ?? null],
      country: [this.allCountries.find((c) => c.value === this.element()?.address?.country)],
      geoLocation: [(this.element() as any).geoLocation?.featureCollection ?? null],
      gpsX: [null],
      gpsY: [null],
      types: [types],
    });
    const featureCollection = (this.element() as any).geoLocation?.featureCollection;

    if (
      featureCollection?.features[0]?.geometry?.type === GeoJsonTypeEnum.POINT &&
      featureCollection?.features[0]?.geometry?.coordinates.length
    ) {
      this.formGroup.controls["gpsX"].setValue(
        featureCollection.features[0].geometry.coordinates[1],
      );
      this.formGroup.controls["gpsY"].setValue(
        featureCollection.features[0].geometry.coordinates[0],
      );
    }
  };

  protected override async reloadElement(id: string): Promise<void> {
    this.overlay.showLoading();

    try {
      const inboundLocations = await this.recordSharingService.getInboundLocationsByIdsGraphQL(
        [id],
        this.organisationIdQueryParam,
        CommonConstants.MAX_API_GET_ITEMS_SIZE,
        ["DOCUMENTS", "GEOLOCATION", "FULL_ADDRESS", "CERTIFICATES"],
      );

      const organisation = await this.connectionsService.get(this.organisationIdQueryParam);

      this.organisationOptions = [{ label: organisation.name, value: organisation.id }];

      this.element.set(inboundLocations[0]);

      await this.setupForm();
      this.overlay.dismissLoading();
    } catch (error) {
      this.notification.showError(error);
    }
  }

  protected override recordName(): string {
    return this.element()?.name;
  }
}
