<div class="row-container">
  <form [formGroup]="formGroup">
    <mat-accordion>
      <mat-expansion-panel [expanded]="true" class="mat-elevation-z0 section-box">
        <mat-expansion-panel-header class="section-header">
          <mat-panel-title class="section-title">
            <h3>General</h3>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row row-2 bottom-row">
          <div class="item">
            <iov-input formControlName="name" label="Name"></iov-input>
          </div>
          <div class="item">
            <iov-input formControlName="description" label="Description"></iov-input>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion>
      <mat-expansion-panel [expanded]="true" class="mat-elevation-z0 section-box">
        <mat-expansion-panel-header class="section-header">
          <mat-panel-title class="section-title">
            <h3>
              Criteria
              <mat-icon
                class="info-icon"
                svgIcon="help"
                tp="Specify the characteristics of the locations to which this ruleset should apply"
              ></mat-icon>
            </h3>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <p class="dark-gray">
          <small>Specifying no criteria will make the ruleset applicable to all locations.</small>
        </p>
        <app-location-criteria-info
          #locationCriteria
          (newLocationTypeCreated)="newLocationTypeCreated.emit()"
          [allCountriesOption]="allCountriesOption"
          [formGroup]="formGroup"
        ></app-location-criteria-info>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion>
      <mat-expansion-panel [expanded]="true" class="mat-elevation-z0 section-box">
        <mat-expansion-panel-header class="section-header">
          <mat-panel-title class="section-title">
            <h3>
              Expectations
              <mat-icon
                class="info-icon"
                svgIcon="help"
                tp="Specify which expectations need to be met for the location not
                                to be flagged as not meeting the ruleset requirements"
              ></mat-icon>
            </h3>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <h4 class="dark-gray">
          Document types

          <iov-button
            (pressed)="openDocumentTypeDialog.emit()"
            class="icon-add-circle"
            type="icon"
            tp="Add document type"
          >
            <mat-icon icon-medium-18>add</mat-icon>
          </iov-button>
        </h4>
        <p class="dark-gray">
          <small>
            Specify which documents should be attached to a location based on the ruleset criteria.
          </small>
        </p>
        <ng-content></ng-content>
      </mat-expansion-panel>
    </mat-accordion>
  </form>
</div>
