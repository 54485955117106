import { Location } from "@angular/common";
import { Directive, HostListener, Input } from "@angular/core";

@Directive({
  selector: "[iovBackNavigation]",
  standalone: true,
})
export class BackNavigationDirective {
  private backNavigationInProgress = false;

  @Input() canExitFn: () => Promise<boolean>;

  @Input() afterCloseFn: () => void;

  constructor(private location: Location) {}

  @HostListener("window:popstate", ["$event"])
  async onPopState(event: PopStateEvent): Promise<void> {
    if (this.backNavigationInProgress) {
      this.backNavigationInProgress = false;

      return;
    }

    const canGoBack = await this.canExitFn();

    if (canGoBack) {
      this.afterCloseFn?.();
    } else {
      event.preventDefault();
      this.backNavigationInProgress = true;
      this.location.forward();
    }
  }
}
