import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";

import { AuthenticationService } from "src/app/shared/services";

import { EditableDivComponent } from "@design-makeover/components/editable-div/editable-div.component";
import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { CommonConstants } from "@shared/constants";
import { ConfirmDialogResponseEnum, EntityTypeEnum } from "@shared/enums";
import { IComment, ICommentPayload } from "@shared/interfaces/comment.interface";
import { CommentsService } from "@shared/services/api/comments.service";
import { CustomValidators } from "@shared/validators";

import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-comments",
  templateUrl: "./comments.component.html",
  styleUrl: "./comments.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentsComponent implements OnInit {
  @Input()
  public entityId: string;

  @Input()
  public entityType: EntityTypeEnum;

  @Input()
  public isVisible: boolean = true;

  @Output()
  public deleted: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public added: EventEmitter<void> = new EventEmitter<void>();

  public comments: IComment[] = [];

  public isLoading = signal(true);

  private entityUri: string;

  @ViewChild("editableDiv") editableDiv: EditableDivComponent;

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    text: new UntypedFormControl("", [
      CustomValidators.maxLength(CommonConstants.COMMENTS_MAX_LENGTH),
    ]),
  });

  constructor(
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
    private commentsService: CommentsService,
    private notificationService: NotificationService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.entityUri = `/organisations/${this.authenticationService.getActiveOrganisationId()}/${this.entityType}/${this.entityId}`;
    await this.loadComments();
  }

  private async loadComments(): Promise<void> {
    this.isLoading.set(true);
    try {
      this.comments = (await this.commentsService.getAll(this.entityUri)).content;
      this.isLoading.set(false);
    } catch (error) {
      this.notificationService.showError(error);
    }
  }

  public async onSave(): Promise<void> {
    const payload = this.getPayload(this.formGroup.controls["text"].value);

    await this.addToRecord(payload);
  }

  private getPayload(text: string): ICommentPayload {
    return {
      text: text.trim(),
      entity: this.entityUri,
    };
  }

  private async addToRecord(comment: ICommentPayload, id: string = null): Promise<void> {
    this.isLoading.set(true);
    try {
      await this.commentsService.createOrUpdate(comment, id);

      if (!id) {
        this.added.emit();
      }
      this.notificationService.showSuccess(`Comment ${id ? "modified" : "created"}`);
      await this.loadComments();
      this.formGroup.reset();
    } catch (error) {
      this.notificationService.showError(error);
    } finally {
      this.isLoading.set(false);
    }
  }

  public async editComment(text: string, id: string): Promise<void> {
    const payload = this.getPayload(text);

    await this.addToRecord(payload, id);
  }

  private async deleteComment(id: string): Promise<void> {
    this.isLoading.set(true);
    try {
      await this.commentsService.delete(id);
      this.deleted.emit();
      await this.loadComments();
      this.notificationService.showSuccess("Comment deleted");
    } catch (error) {
      this.notificationService.showError(error);
    } finally {
      this.isLoading.set(false);
    }
  }

  public onDelete(id: string): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: `Delete comment`,
        contentTranslatedText: "Are you sure that you want to delete this comment?",
        confirmButtonColor: "danger",
        confirmButtonTranslatedText: "Delete",
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        await this.deleteComment(id);
      }
    });
  }

  public isSubmitButtonDisabled(): boolean {
    return (
      this.formGroup.invalid ||
      this.formGroup.pending ||
      !this.formGroup.dirty ||
      !this.formGroup.controls["text"].value
    );
  }

  onCommentBoxClicked(): void {
    this.editableDiv.setfocus();
  }
}
