import { ChangeDetectionStrategy, Component, inject, signal } from "@angular/core";

import { RecordStateEnum, RoutingEnum } from "@shared/enums";
import { RiskAssessmentTemplateResourceType } from "@shared/risk-assessment-templates/constants";
import { AuthenticationService } from "@shared/services";
import { RouterService } from "@shared/services/router.service";

@Component({
  selector: "app-settings-risk-assessment-templates",
  templateUrl: "./settings-risk-assessment-templates.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsRiskAssessmentTemplatesComponent {
  readonly canAddModifyEntities = inject(AuthenticationService).canAddModifyEntities();

  readonly router = inject(RouterService);

  readonly recordState = RecordStateEnum;

  readonly resourceType = RiskAssessmentTemplateResourceType;

  readonly routing = RoutingEnum;

  readonly selectedTabIndex = signal(0);

  private readonly resourceTypeMap = {
    0: RiskAssessmentTemplateResourceType.ORGANISATION,
    1: RiskAssessmentTemplateResourceType.LOCATION,
    2: RiskAssessmentTemplateResourceType.PRODUCT,
    3: RiskAssessmentTemplateResourceType.DELIVERY,
  };

  onAdd() {
    this.router.navigate(
      this.router.getRiskAssessmentTemplatesLink({
        extraParams: {
          type: this.resourceTypeMap[this.selectedTabIndex()],
        },
      }),
    );
  }
}
