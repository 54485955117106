import { ChangeDetectionStrategy, Component } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

import { UnitOfMeasurementCellRendererService } from "@shared/cell-renderers/unit-of-measurement.cell-renderer.service";

@Component({
  template: `
    {{ getValue() }}
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnitOfMeasurementCellRendererComponent implements ICellRendererAngularComp {
  public params!: ICellRendererParams;

  constructor(private unitOfMeasureService: UnitOfMeasurementCellRendererService) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  getValue = (): string => {
    return this.unitOfMeasureService.getValue(this.getData(), this.params);
  };

  private getData = (): any => this.params.data ?? this.params.node.allLeafChildren[0].data;
}
