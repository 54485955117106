import { ChangeDetectionStrategy, Component, input } from "@angular/core";

@Component({
  selector: "iov-form-label",
  templateUrl: "./form-label.component.html",
  styleUrl: "./form-label.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormLabelComponent {
  label = input<string>();

  tooltip = input<string>();

  icon = input<string>("help");

  iconClass = input<string>();
}
