<div>
  <div class="name clickable" [class.hover-visibility-container]="shouldOpenInNewTab">
    <mat-icon class="filled-icon">local_shipping</mat-icon>
    <div class="text">{{ delivery.deliveryId }}</div>
    @if (shouldOpenInNewTab) {
      <app-external-link-icon></app-external-link-icon>
    }
  </div>
  <p class="delivery-dates">
    {{ delivery.sent | date: dateFormat }} - {{ delivery.delivered | date: dateFormat }}
  </p>
  <ul class="product-list">
    @for (item of delivery.summarisedItems; track item.id) {
      <li>
        {{ item.productName }}
        @if (item.totalQuantityFormatted) {
          ({{ item.totalQuantityFormatted }})
        } @else {
          ({{ item.totalQuantity | number: "1.0-" + item.unitOfMeasurement?.precision ?? "0" }}
          {{ item.unitOfMeasurement?.symbol }})
        }
      </li>
    }
  </ul>
  <div class="attachments">
    @if (showWarning || delivery.documents?.length) {
      <div class="border documents clickable">
        @if (showWarning) {
          <mat-icon
            svgIcon="alert-triangle"
            class="warn-icon"
            [class.mr-4]="delivery.documents?.length"
          ></mat-icon>
          <mat-icon svgIcon="document"></mat-icon>
          <span class="number selected-documents">
            {{ delivery?.documents?.length || "" }}
          </span>
        } @else {
          <mat-icon svgIcon="document"></mat-icon>
          <span class="number">{{ delivery?.documents?.length }}</span>
        }
      </div>
    }

    @if (delivery.items?.length) {
      <div class="border items clickable">
        <mat-icon svgIcon="items"></mat-icon>
        <span class="number">{{ delivery?.items?.length }}</span>
      </div>
    }
  </div>
  @if (delivery.agents?.length) {
    <div class="agents">
      @for (agent of delivery.agents; track agent.id) {
        <div class="agent-item">
          <div class="name-section">
            <mat-icon tp="Trader">swap_horiz</mat-icon>
            <p
              [tp]="agent.name"
              class="agent-name one-line-wrap-ellipsis clickable"
              [id]="agent.id"
            >
              {{ agent.name }}
            </p>
          </div>

          <div class="attachments">
            @if (agent.certificates?.length) {
              <div class="border agent-certificates certificates clickable" [id]="agent.id">
                <mat-icon svgIcon="certificates"></mat-icon>
                <span class="number selected-certificates">{{ agent.certificates?.length }}</span>
              </div>
            }
            @if (agent.documents?.length) {
              <div class="border agent-documents documents clickable" [id]="agent.id">
                <mat-icon svgIcon="document"></mat-icon>
                <span class="number">{{ agent.documents?.length }}</span>
              </div>
            }
          </div>
        </div>
      }
    </div>
  }
</div>
