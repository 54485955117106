import { HttpErrorResponse } from "@angular/common/http";
import { AfterViewInit, ChangeDetectionStrategy, Component, Input, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef } from "ag-grid-community";
import { EditApiKeyDialogComponent } from "src/app/components/user-settings";
import { DateCellRendererComponent } from "src/app/shared/cell-renderers";
import { ConfirmDialogResponseEnum, TableEnum } from "src/app/shared/enums";
import { IApiKey } from "src/app/shared/interfaces";
import { ApiKeysService } from "src/app/shared/services";
import { CellRendererUtils, ColumnUtils } from "src/app/shared/utils";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { ConfirmDialogComponent } from "../../../shared/confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-api-keys-table",
  templateUrl: "./api-keys-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiKeysTableComponent implements AfterViewInit {
  @Input()
  public apiKeys: IApiKey[] = null;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  @Input()
  public table = TableEnum.API_KEYS;

  @Input()
  public isSaveTableState = false;

  public isLoading = signal(true);

  public rowData: IApiKey[] = [];

  public columnDefs = signal<ColDef[]>([]);

  constructor(
    private apiKeysService: ApiKeysService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) {}

  public async ngAfterViewInit() {
    this.setColumnDefs();
    await this.getAll();
  }

  private setColumnDefs = (): void => {
    const columnDefs: ColDef[] = [
      { headerName: "Name", field: "name", lockVisible: true },
      {
        headerName: "Description",
        field: "note",
        valueFormatter: ColumnUtils.defaultValueFormatter(),
      },
      { headerName: "Token hint", field: "tokenHint" },
      { headerName: "Expiration date", field: "expiry", cellRenderer: DateCellRendererComponent },
      {
        headerName: "Enabled",
        field: "enabled",
        cellRenderer: CellRendererUtils.showIconIfValue,
        valueGetter: (cell: any) => !!cell.data?.enabled,
      },
    ];

    if (this.areButtonsEnabled) {
      const buttons = [
        {
          onClick: this.onRevoke,
          tooltip: "Revoke",
          icon: "delete",
        },
        {
          onClick: this.onEdit,
          tooltip: "Edit",
          icon: "edit",
        },
      ];

      columnDefs.push(ColumnUtils.buttons(buttons));
    }
    this.columnDefs.set(columnDefs);
  };

  public onEdit = (apiKey: IApiKey): void => {
    if (!this.areButtonsEnabled) {
      return;
    }
    const dialogRef = this.dialog.open(EditApiKeyDialogComponent, {
      data: {
        apiKey,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: { hasSaved: boolean }) => {
      if (result?.hasSaved) {
        await this.getAll();
      }
    });
  };

  public onRevoke = (element: IApiKey): void => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: "Revoke API key",
        contentTranslatedText: "Are you sure that you want to revoke this API key?",
        confirmButtonColor: "danger",
        confirmButtonTranslatedText: "Revoke",
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        await this.apiKeysService
          .delete(element.id)
          .then(async () => {
            this.notificationService.showSuccess(`API key revoked`);
            await this.getAll();
          })
          .catch((error: HttpErrorResponse) => {
            this.notificationService.showError(error);
          });
      }
    });
  };

  public getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    if (this.apiKeys) {
      this.rowData = this.apiKeys;
      this.isLoading.set(false);
    } else {
      try {
        this.rowData = await this.apiKeysService.getAll();
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };
}
