import { ChangeDetectionStrategy, Component } from "@angular/core";

import { AgRendererComponent } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

import { DeliveryStatusEnum } from "../enums";

@Component({
  template: `
    {{ value | enumToText }}
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnumsCellRendererComponent implements AgRendererComponent {
  value: DeliveryStatusEnum;

  agInit(params: ICellRendererParams): void {
    this.value = params.value;
  }

  refresh(params: ICellRendererParams): boolean {
    this.value = params.value;

    return true;
  }
}
