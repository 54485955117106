import { ChangeDetectionStrategy, Component } from "@angular/core";

import { RecordStateEnum } from "src/app/shared/enums";
import { AuthenticationService } from "src/app/shared/services";

import { RouterService } from "@shared/services/router.service";

@Component({
  templateUrl: "./locations.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationsComponent {
  public readonly recordStateEnum = RecordStateEnum;

  public readonly canAddModifyEntities = this.authenticationService.canAddModifyEntities();

  constructor(
    private router: RouterService,
    public authenticationService: AuthenticationService,
  ) {}

  public onAdd(): void {
    this.router.navigate(this.router.getLocationLink());
  }
}
