import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  HostListener,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  signal,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import * as d3 from "d3";
import { graphlib, render } from "dagre-d3";
import { debounceTime, Subject, Subscription } from "rxjs";
import { GraphScaler } from "src/app/shared/classes/graph-scaler.class";
import { ResourceTypeEnum } from "src/app/shared/enums";
import {
  ICertificate,
  IDeliveryExtended,
  IDocument,
  IItemExtended,
  IItemSupplyChain,
  ILocationExtended,
  IProcessType,
  IProduct,
  IRecordResponse,
} from "src/app/shared/interfaces";

import { FlowChartNodeComponent, FullScreenSupplyChainDialogComponent } from "@components/shared";
import { FlowChartTypeEnum } from "@components/shared/fullscreen-supply-chain-dialog/fullscreen-supply-chain-dialog.model";
import { ItemsSupplyChainModel as Model } from "@components/shared/items-supply-chain/items-supply-chain.component.model";
import { CommonConstants } from "@shared/constants";
import { RouterService } from "@shared/services/router.service";

import { ItemSupplyChainMapperService } from "./item-supply-chain-mapper.service";

@Component({
  selector: "app-items-supply-chain",
  templateUrl: "./items-supply-chain.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemsSupplyChainComponent
  extends GraphScaler
  implements OnChanges, AfterViewInit, AfterContentChecked, OnDestroy
{
  @Input()
  override isZoomEnabled = true;

  @Input()
  public override height: number;

  @Input()
  public itemIds: string[];

  @Input()
  public containerClass: string = "items-supply-chain-flow-chart";

  @Input()
  public svgContentClass: string;

  @Input()
  public fullScreenOptionEnabled: boolean = true;

  @Input()
  public allProcessTypes: IProcessType[] = [];

  @ViewChild("deliveryTemplate")
  public deliveryTemplate: TemplateRef<any>;

  @ViewChild("groupedProductTemplate")
  public groupedProductTemplate: TemplateRef<any>;

  @Input()
  public supplyChainItems: IItemSupplyChain[];

  public shouldGroupByProduct: boolean = false;

  public locationNodes: any[] = [];

  public isLoading = signal<boolean>(true);

  public hasError: boolean;

  private svg: d3.Selection<SVGElement, object, HTMLElement, any>;

  private svgGroup: any;

  private graph: graphlib.Graph;

  private zoom: any;

  private componentFactory: ComponentFactory<FlowChartNodeComponent>;

  private deliveriesRulesetsRecords: IRecordResponse[];

  private locationsRulesetsRecords: IRecordResponse[];

  private autoScaleGraphSubject = new Subject();

  private subscriptions = new Subscription();

  @Input()
  public override svgId: string = "node-svg";

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.autoScaleGraphSubject.next(true);
  }

  constructor(
    private _resolver: ComponentFactoryResolver,
    private _injector: Injector,
    private itemSupplyChainMapperService: ItemSupplyChainMapperService,
    private routerService: RouterService,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();

    this.subscriptions.add(
      this.autoScaleGraphSubject
        .pipe(debounceTime(CommonConstants.DEBOUNCE_SUPPLY_CHAIN_RESIZE_TIME_MS))
        .subscribe(() => this.scaleAndCenterGraph(`#${this.svgId}`)),
    );
  }

  async ngAfterViewInit(): Promise<void> {
    this.isLoading.set(true);
    this.componentFactory = this._resolver.resolveComponentFactory(FlowChartNodeComponent);

    try {
      if (!this.supplyChainItems) {
        this.supplyChainItems = await this.itemSupplyChainMapperService.mapItemSupplyChain(
          this.itemIds,
        );
      }

      this.allDocumentTypes = await this.documentTypesService.getAll();
      const deliveriesIds = this.supplyChainItems
        .map((i) => i.deliveries?.map((d) => d.id))
        .flat()
        .filter((id) => !!id);
      const locationsIds = this.supplyChainItems
        .map((i) => i.locations?.map((d) => d.id))
        .flat()
        .filter((id) => !!id);

      if (this.authenticationService.isRegularUser()) {
        this.deliveriesRulesetsRecords = await this.getRulesetRecords(
          deliveriesIds,
          ResourceTypeEnum.DELIVERY,
        );
        this.locationsRulesetsRecords = await this.getRulesetRecords(
          locationsIds,
          ResourceTypeEnum.LOCATION,
        );
      }
    } catch (error) {
      this.hasError = true;
      this.notificationService.showError(error);
    } finally {
      this.isLoading.set(false);
      this.drawGraph();
    }
  }

  public ngAfterContentChecked(): void {
    // This will keep trying until document is fully ready and so graph is drawn only once
    const drawnNodes = this.mainContainer()?.getElementsByClassName("flow-chart-node");

    if (drawnNodes && !drawnNodes.length) {
      this.drawGraph();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes["height"] &&
      changes["height"].previousValue &&
      changes["height"].previousValue !== changes["height"].currentValue
    ) {
      this.scaleAndCenterGraph(`#${this.svgId}`);
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onClickGroupDeliveriesByProduct(): void {
    this.shouldGroupByProduct = !this.shouldGroupByProduct;
    this.isLoading.set(true);

    this.changeDetectorRef.detectChanges();
    this.drawGraph();
    this.isLoading.set(false);
    this.scaleAndCenterGraph(`#${this.svgId}`);
    this.changeDetectorRef.detectChanges();
  }

  public getMainClass(): string {
    return this.containerClass;
  }

  public mainContainer() {
    return document.querySelector(`.${this.getMainClass()}`);
  }

  public fullScreen(): void {
    const dialogRef = this.dialog.open(FullScreenSupplyChainDialogComponent, {
      width: window.innerWidth + "px",
      height: window.innerHeight - 25 + "px",
      panelClass: CommonConstants.SUPPLY_CHAIN_FULL_SCREEN_PANEL_CLASS,
      data: {
        containerClass: "flow-chart-container-class-dialog",
        flowChartType: FlowChartTypeEnum.ITEM_SUPPLY_CHAIN,
        itemIds: this.itemIds,
        allProcessTypes: this.allProcessTypes,
      },
    });

    dialogRef.afterOpened().subscribe(() => {
      dialogRef.componentInstance.setIsOpened(true);
    });
  }

  override getSvg() {
    return this.svg;
  }

  override getSvgGroup() {
    return this.svgGroup;
  }

  override getZoom() {
    return this.zoom;
  }

  override getGraph() {
    return this.graph;
  }

  private uniqueDeliveriesById(deliveries: IDeliveryExtended[]): IDeliveryExtended[] {
    const map = new Map<string, IDeliveryExtended>();

    deliveries.filter((delivery) => delivery).forEach((delivery) => map.set(delivery.id, delivery));

    return Array.from(map.values());
  }

  private groupByProduct(deliveries: IDeliveryExtended[]): Model.IGroupedProduct[] {
    const groupedProducts: Model.IGroupedProduct[] = [];

    const uniqueDeliveries = this.uniqueDeliveriesById(deliveries);

    uniqueDeliveries.forEach((delivery) => {
      const items = delivery.items as IItemExtended[];

      const fromLocation = delivery.from;
      const toLocation = delivery.to;

      items.forEach((item) => {
        const product = item.product;

        const compoundId = this.buildIdForGroupedProduct(product, fromLocation, toLocation);

        const groupedProduct = groupedProducts.find((groupedProduct) => {
          return groupedProduct.compoundId === compoundId;
        });

        if (groupedProduct) {
          groupedProduct.quantitySum += item.deliveredQuantity;
        } else {
          groupedProducts.push({
            compoundId,
            product,
            fromLocation,
            toLocation,
            quantitySum: item.deliveredQuantity,
            defaultUnitOfMeasurement: product.defaultCustomUnit,
            baseUnitOfMeasurement: product.unitOfMeasurement,
          });
        }
      });
    });

    return groupedProducts;
  }

  private buildIdForGroupedProduct(
    product: IProduct,
    fromLocation: ILocationExtended,
    toLocation: ILocationExtended,
  ): string {
    return `${product.id}-${fromLocation.id}-${toLocation.id}`;
  }

  private drawGraph = (): void => {
    if (!this.supplyChainItems?.length || !this.componentFactory) {
      return;
    }
    this.graph = new graphlib.Graph().setGraph({}).setDefaultEdgeLabel(() => {
      return {};
    });

    (this.graph.graph() as any).rankdir = "LR";
    (this.graph.graph() as any).nodesep = 0;
    const allLocations = this.supplyChainItems.map((item) => item.locations).flat();
    const allDeliveries = this.supplyChainItems.map((item) => item.deliveries).flat();

    let groupedProducts: Model.IGroupedProduct[] = [];

    if (this.shouldGroupByProduct) {
      groupedProducts = this.groupByProduct(allDeliveries);
    }

    for (const item of this.supplyChainItems) {
      const locations = item.locations;
      const deliveries = item.deliveries;

      for (const location of locations) {
        const nodeComponent = this.componentFactory.create(this._injector);

        nodeComponent.instance.location = location;
        nodeComponent.instance.locationsRulesetsRecords = this.locationsRulesetsRecords;
        nodeComponent.instance.hasAvailableCertificates = !!location.certificates.length;
        nodeComponent.instance.hasAvailableDocuments = !!location.documents.length;
        nodeComponent.instance.isSafari = this.isSafari;
        nodeComponent.instance.shouldOpenInNewTab = true;
        nodeComponent.changeDetectorRef.detectChanges();

        const templateNativeElement = nodeComponent.location.nativeElement;

        this.graph.setNode(location.id, {
          label: templateNativeElement,
          labelType: "html",
        });
      }

      if (groupedProducts.length) {
        for (const groupedProduct of groupedProducts) {
          const nodeComponent = this.componentFactory.create(this._injector);

          nodeComponent.instance.groupedProduct = groupedProduct;
          nodeComponent.instance.class = "wide-card";
          nodeComponent.instance.template = this.groupedProductTemplate;
          nodeComponent.changeDetectorRef.detectChanges();

          const templateNativeElement = nodeComponent.location.nativeElement;

          this.graph.setNode(groupedProduct.compoundId, {
            label: templateNativeElement,
            labelType: "html",
          });

          const fromId = groupedProduct.fromLocation.id;
          const toId = groupedProduct.toLocation.id;
          const fromLocation = item.locations.find((n) => n.id === fromId);
          const toLocation = item.locations.find((n) => n.id === toId);

          if (fromLocation && toLocation) {
            this.graph.setEdge(fromLocation.id, groupedProduct.compoundId, { label: "" });
            this.graph.setEdge(groupedProduct.compoundId, toLocation.id, { label: "" });
          }
        }
      }

      if (!this.shouldGroupByProduct && deliveries?.length) {
        for (const delivery of deliveries) {
          const nodeComponent = this.componentFactory.create(this._injector);

          nodeComponent.instance.delivery = delivery;
          nodeComponent.instance.class = delivery.agents?.length ? "wide-card" : "";
          nodeComponent.instance.deliveriesRulesetsRecords = this.deliveriesRulesetsRecords;
          nodeComponent.instance.shouldOpenInNewTab = true;
          nodeComponent.instance.template = this.deliveryTemplate;
          nodeComponent.changeDetectorRef.detectChanges();

          // Access the native element of the template component
          const templateNativeElement = nodeComponent.location.nativeElement;

          // Add the native element as the label for the node in the graph
          this.graph.setNode(delivery.id, {
            label: templateNativeElement,
            labelType: "html",
          });

          const fromId = delivery.from.id;
          const toId = delivery.to.id;
          const fromLocation = item.locations.find((n) => n.id === fromId);
          const toLocation = item.locations.find((n) => n.id === toId);

          if (fromLocation && toLocation) {
            this.graph.setEdge(fromLocation.id, delivery.id, { label: "" });
            this.graph.setEdge(delivery.id, toLocation.id, { label: "" });
          }
        }
      }
    }

    this.svg = d3.select(`.${this.getMainClass()} svg#${this.svgId}`);
    this.svgGroup = this.svg.select("g");

    if (this.isZoomEnabled) {
      this.zoom = d3
        .zoom()
        .scaleExtent([0.03, 1])
        .on("zoom", (event) => {
          this.svgGroup.attr("transform", event.transform);
        });
      this.svg.call(this.zoom);
    }

    new render()(this.svgGroup, this.graph as any);

    for (const nodeElement of this.svgGroup.selectAll("g.node")) {
      const nodeId = nodeElement.__data__;
      const location = allLocations?.find((n) => n?.id === nodeId);
      const delivery = allDeliveries?.find((n) => n?.id === nodeId);
      const groupedProduct = groupedProducts?.find((n) => n?.compoundId === nodeId);

      if (location) {
        this.setupLocation(location, nodeElement);
      }

      if (delivery) {
        this.setupDelivery(delivery, nodeElement);
      }

      if (groupedProduct) {
        this.setupGroupedProduct(groupedProduct, nodeElement);
      }
    }
    this.scaleAndCenterGraph(`#${this.svgId}`);
  };

  private setupLocation(location: ILocationExtended, nodeElement: any) {
    this.setDocumentsTooltip(location.documents, nodeElement);
    this.setCertificatesTooltip(location.certificates, nodeElement);
    this.setProcessesTooltip(location.processes, nodeElement);
    this.setLocationTypesTooltips(nodeElement);

    const name = d3.select(nodeElement).select(".name");
    const organisation = d3.select(nodeElement).select(".organisation");
    const processesIcon = d3.select(nodeElement).select(".processes");
    const certificatesIcon = d3.select(nodeElement).select(".certificates");
    const documentsIcon = d3.select(nodeElement).select(".documents");

    certificatesIcon.classed("clickable", true);
    documentsIcon.classed("clickable", true);

    processesIcon.on("click", () => {
      this.setSelectedProcesses(location.processes, location.name);
    });

    certificatesIcon.on("click", () => {
      this.setSelectedCertificates(location.certificates, location.name);
    });

    documentsIcon.on("click", () => {
      this.setSelectedDocuments(location.documents, location.name);
      const record = this.locationsRulesetsRecords?.find((r) => r.uri.includes(location.id));

      this.setMissingDocuments(record);
    });

    organisation.on("click", () => {
      this.routerService.openNewTab(
        this.routerService.getOrganisationLink(location.organisationId, false),
      );
    });

    name.on("click", () => {
      this.routerService.openNewTab(this.routerService.getLocationLink(location.id, false));
    });
  }

  private setupGroupedProduct(groupedProduct: Model.IGroupedProduct, nodeElement: any) {
    const name = d3.select(nodeElement).select(".name");

    name.on("click", () => {
      const link = this.routerService.getProductLink(groupedProduct.product.id, false);

      this.routerService.openNewTab(link);
    });
  }

  private setupDelivery(delivery: IDeliveryExtended, nodeElement: any) {
    this.setDocumentsTooltip(delivery.documents, nodeElement);
    this.setItemsTooltip(delivery.items, nodeElement);

    const name = d3.select(nodeElement).select(".name");
    const itemIcon = d3.select(nodeElement).select(".items");
    const documentsIcon = d3.select(nodeElement).select(".documents");
    const agentNames = d3.select(nodeElement).select(".agent-name");
    const agentCertificates = d3.select(nodeElement).select(".agent-certificates");
    const agentDocuments = d3.select(nodeElement).select(".agent-documents");

    itemIcon.on("click", () => {
      this.setSelectedItems(delivery.items, delivery.deliveryId);
    });
    documentsIcon.on("click", () => {
      this.setSelectedDocuments(delivery.documents, delivery.deliveryId);
      const record = this.deliveriesRulesetsRecords?.find((r) => r.uri.includes(delivery.id));

      this.setMissingDocuments(record);
    });

    name.on("click", () => {
      const link = this.routerService.getDeliveryLink(delivery.id, false);

      this.routerService.openNewTab(link);
    });

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    agentNames.on("click", function () {
      const agentId = d3.select(this).attr("id");

      that.routerService.openNewTab(that.routerService.getOrganisationLink(agentId, false));
    });

    agentCertificates.on("click", function () {
      const agentId = d3.select(this).attr("id");
      const organisation = delivery.agents?.find((org) => org.id === agentId);

      if (organisation) {
        that.setSelectedCertificates(organisation.certificates, organisation.name);
      }
    });

    agentDocuments.on("click", function () {
      const agentId = d3.select(this).attr("id");
      const organisation = delivery.agents?.find((org) => org.id === agentId);

      if (organisation) {
        that.setSelectedDocuments(organisation.documents, organisation.name);
      }
    });

    agentCertificates.each((_, i, nodes: HTMLElement[]) => {
      const agentId = nodes[i].getAttribute("id");
      const certificates = delivery.agents?.find((org) => org.id === agentId)?.certificates;

      if (certificates?.length) {
        this.setAgentTooltip(certificates, nodes[i], "certificates");
      }
    });

    agentDocuments.each((_, i, nodes: HTMLElement[]) => {
      const agentId = nodes[i].getAttribute("id");
      const documents = delivery.agents.find((org) => org.id === agentId)?.documents;

      if (documents?.length) {
        this.setAgentTooltip(documents, nodes[i], "documents");
      }
    });
  }

  private setAgentTooltip(data: ICertificate[] | IDocument[], element: any, tooltipType: string) {
    if (!element) {
      return;
    }
    if (element._tippy) {
      element._tippy.destroy();
    }
    let tooltipData: string;

    switch (tooltipType) {
      case "certificates":
        tooltipData = this.getTooltip(data as ICertificate[], null, null, null, "Certificates");
        break;
      case "documents":
        tooltipData = this.getTooltip(null, data as IDocument[], null, null, "Documents");
        break;
    }
    if (tooltipData && data?.length) {
      this.tippyService.create(element, tooltipData, this.tooltipOptions);
    }
  }
}
