import { Inject, Injectable } from "@angular/core";

import { APP_CONFIG } from "@shared/tokens";

import { ApiService } from "./api.service";
import { CommonConstants } from "../../constants";
import { UserRoleEnum } from "../../enums";
import {
  IConfig,
  IOrganisation,
  IOrganisationMember,
  IOrganisationPayload,
  IPageableContent,
} from "../../interfaces";
import { FormUtils } from "../../utils";

@Injectable({
  providedIn: "root",
})
export class OrganisationsService {
  constructor(
    private apiService: ApiService,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {}

  private getBaseUrl = (): string => `${this.environment.baseUrl}organisations`;

  public get = async (id: string): Promise<IOrganisation> =>
    await this.apiService.get<IOrganisation>(`${this.getBaseUrl()}/${id}`).toPromise();

  public createOrUpdate = async (
    payload: IOrganisationPayload,
    id?: string,
  ): Promise<IOrganisation> => {
    if (id) {
      return await this.apiService
        .put<IOrganisation>(`${this.getBaseUrl()}/${id}`, payload)
        .toPromise();
    } else {
      return await this.apiService.post<IOrganisation>(`${this.getBaseUrl()}`, payload).toPromise();
    }
  };

  public getOrganisationMember = async (
    organisationId: string,
    memberId: string,
  ): Promise<IOrganisationMember> =>
    await this.apiService
      .get<IOrganisationMember>(`${this.getBaseUrl()}/${organisationId}/members/${memberId}`)
      .toPromise();

  public getOrganisationMembersPage = async (
    organisationId: string,
    status: string = undefined,
    firstName: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
  ): Promise<IPageableContent<IOrganisationMember>> => {
    const url = `${this.getBaseUrl()}/${organisationId}/members?${FormUtils.addUrlParams({
      status,
      firstName,
      size,
      page,
      sort,
    })}`;

    return await this.apiService.get<IPageableContent<IOrganisationMember>>(url).toPromise();
  };

  public async getAllOrganisationMembers(
    organisationId: string,
    status: string = undefined,
  ): Promise<IOrganisationMember[]> {
    const result: IOrganisationMember[] = [];
    let page = 0;
    let hasNextPage: boolean = true;

    while (hasNextPage) {
      const {
        content,
        page: { totalElements },
      } = await this.getOrganisationMembersPage(
        organisationId,
        status,
        undefined,
        CommonConstants.MAX_API_GET_ITEMS_SIZE,
        page,
        undefined,
      );

      result.push(...content);

      page++;
      hasNextPage = result.length < totalElements;
    }

    return result;
  }

  public setMemberStatus = async (
    organisationId: string,
    memberId: string,
    status: string,
  ): Promise<void> => {
    return await this.apiService
      .put<void>(`${this.getBaseUrl()}/${organisationId}/members/${memberId}`, { status })
      .toPromise();
  };

  public editMember = async (
    organisationId: string,
    memberId: string,
    role: UserRoleEnum,
  ): Promise<void> => {
    return await this.apiService
      .put<void>(`${this.getBaseUrl()}/${organisationId}/members/${memberId}/role`, { role })
      .toPromise();
  };
}
