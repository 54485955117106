import { computed, Injectable, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef } from "ag-grid-community";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { AddAnalysisDialogComponent } from "@components/locations";
import { ConfirmDialogComponent } from "@components/shared";
import {
  DateCellRendererComponent,
  DateRangeCellRendererComponent,
  LinkCellRendererComponent,
  QuickActionsMenuComponent,
  RoundNumberCellRendererComponent,
} from "@shared/cell-renderers";
import { CommonConstants } from "@shared/constants";
import { ExtensionProviderEnum, ConfirmDialogResponseEnum, RoutingEnum } from "@shared/enums";
import { AnalysisTypeEnum } from "@shared/enums/analysis-type.enum";
import { IAnalysis } from "@shared/interfaces";
import {
  AnalysisExtensionService,
  AuthenticationService,
  FeatureFlagService,
  LocationsService,
} from "@shared/services";
import { RouterService } from "@shared/services/router.service";
import { ColumnUtils, CommonUtils } from "@shared/utils";

@Injectable({
  providedIn: "root",
})
export class AnalysesTableService {
  analyses = signal<IAnalysis[]>([]);

  isLoading = signal<boolean>(false);

  analysisCounter = computed(() => (this.isLoading() ? null : this.analyses().length));

  isAnalysisDetailOverlayVisible = signal<boolean>(false);

  analysisDetailOverlayItem = signal<IAnalysis>(undefined);

  locationId = signal<string>(undefined);

  columnDefs = signal<ColDef[]>([]);

  constructor(
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private analysisExtensionService: AnalysisExtensionService,
    private notificationService: NotificationService,
    private routerService: RouterService,
    private locationsService: LocationsService,
    private featureFlagService: FeatureFlagService,
  ) {}

  public setLocationId = (locationId?: string): void => {
    this.locationId.set(locationId);
  };

  public async getAll(): Promise<void> {
    this.isLoading.set(true);
    try {
      let rowData: any = await this.analysisExtensionService.getAll(
        this.locationId(),
        ExtensionProviderEnum.ORBIFY,
      );

      if (!this.locationId()) {
        const uniqueLocationIds: any[] = [
          ...new Set(rowData.map((r: IAnalysis) => CommonUtils.getUriId(r.location))),
        ];

        const uniqueLocations = await this.locationsService.getByIdsGraphQL(uniqueLocationIds);

        rowData = rowData.map((a) => {
          const locationId = CommonUtils.getUriId(a.location);
          const location = uniqueLocations.find((l) => l.id === locationId);

          return {
            ...a,
            location: {
              id: location?.id,
              name: location?.name,
              types: location?.types,
            },
          };
        });
      }

      this.analyses.set(rowData);
      this.isLoading.set(false);
    } catch (error) {
      this.analyses.set([]);
      this.notificationService.showError(error);
    }
  }

  public add() {
    if (!this.locationId()) {
      return;
    }
    const dialogRef = this.dialog.open(AddAnalysisDialogComponent, {
      data: {
        locationId: this.locationId(),
      },
    });

    dialogRef.afterClosed().subscribe(async (result: { hasSaved: boolean }) => {
      if (result?.hasSaved) {
        await this.getAll();
      }
    });
  }

  public setColumnDefs() {
    const referenceColumnQuickActions = [];

    if (this.featureFlagService.isDevOrStgEnv() && this.locationId()) {
      referenceColumnQuickActions.push({
        icon: "info",
        tooltipTemplate: "orbifyDeforestation",
        show: (row: IAnalysis) =>
          row.parameters?.type === AnalysisTypeEnum.ORBIFY_DEFORESTATION &&
          this.isAnalysisReady(row),
      });
    }

    if (this.authenticationService.canAddModifyEntities()) {
      referenceColumnQuickActions.push({
        icon: "delete",
        tooltip: "Delete",
        click: (row: IAnalysis) => this.remove(row),
      });
    }

    const commonColumnDefs: any[] = [
      {
        headerName: "Reference",
        field: "parameters.reportId",
        suppressSizeToFit: true,
        suppressAutoSize: true,
        lockVisible: true,
        rowDrag: false,
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: {
          actions: referenceColumnQuickActions,
        },
      },
      {
        headerName: "Type",
        field: "parameters.type",
      },
      {
        headerName: "Name",
        field: "name",
      },
      {
        headerName: "Date range",
        field: "startTime",
        cellRenderer: DateRangeCellRendererComponent,
        cellRendererParams: {
          startDateParam: "startTime",
          endDateParam: "endTime",
        },
      },
    ];

    if (!this.locationId()) {
      commonColumnDefs.push(
        ...[
          {
            headerName: "Project area (ha)",
            field: "providerResponse.projectAreaHa",
            cellRenderer: RoundNumberCellRendererComponent,
          },
          {
            headerName: "Lost forest area (ha)",
            field: "providerResponse.lostForestAreaHa",
            cellRenderer: RoundNumberCellRendererComponent,
          },
          {
            headerName: "Lost forest area (%)",
            field: "providerResponse.lostForestAreaPercent",
            cellRenderer: RoundNumberCellRendererComponent,
          },
        ],
      );
    }

    commonColumnDefs.push(
      ...[
        {
          headerName: "Created",
          field: "created",
          cellRenderer: DateCellRendererComponent,
          cellRendererParams: {
            dateFormat: CommonConstants.DATE_TIME_FORMAT,
          },
        },
        {
          headerName: "Status",
          field: "status",
        },
      ],
    );

    if (this.locationId()) {
      this.columnDefs.set(commonColumnDefs);
    } else {
      const locationColumnDefs = [
        {
          headerName: "Location",
          field: "location.name",
          cellRenderer: LinkCellRendererComponent,
          cellRendererParams: {
            linkRoute: this.authenticationService.isSystemAdminOrganisation()
              ? RoutingEnum.ADMIN_LOCATIONS_DETAILS
              : undefined,
            linkRouteIdParam: "location.id",
            linkRouteFn: this.authenticationService.isSystemAdminOrganisation()
              ? undefined
              : this.routerService.getLocationLink,
          },
          suppressSizeToFit: true,
        },
        ColumnUtils.chips("Location type(s)", "location.types", {
          textParam: "type",
          showIcon: (locationType) => locationType.pointOfOrigin,
          icon: "target",
          iconTooltip: CommonConstants.POINT_OF_ORIGIN_CHIP_TEXT,
        }),
      ];

      this.columnDefs.set([...locationColumnDefs, ...commonColumnDefs]);
    }
  }

  public reset(): void {
    this.isLoading.set(false);
    this.isAnalysisDetailOverlayVisible.set(false);
    this.analysisDetailOverlayItem.set(undefined);
    this.locationId.set(undefined);
    this.columnDefs.set([]);
  }

  public closeView = (): void => {
    this.analysisDetailOverlayItem.set(undefined);
    this.isAnalysisDetailOverlayVisible.set(false);
  };

  public openView = (row: IAnalysis): void => {
    if (this.isAnalysisReady(row)) {
      this.analysisDetailOverlayItem.set(row);
      this.isAnalysisDetailOverlayVisible.set(true);
    } else if (row.status === "PENDING") {
      this.notificationService.showError(
        "This report is still being generated. It will be available shortly",
      );
    } else if (row.status === "ERROR") {
      this.notificationService.showError("An error occurred when generating this report");
    }
  };

  private isAnalysisReady = (row: IAnalysis): boolean =>
    row.status === "AVAILABLE" && !!row.providerResponse?.response;

  private remove(row: IAnalysis): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: "Delete confirmation",
        contentTranslatedText: `Are you sure you want delete this analysis report?`,
        confirmButtonColor: "danger",
        confirmButtonTranslatedText: "Delete",
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        try {
          this.isLoading.set(true);
          await this.analysisExtensionService.delete(row.id);
          this.notificationService.showSuccess("Analysis report deleted");
          await this.getAll();
        } catch (error) {
          this.notificationService.showError(error);
        }
      }
    });
  }
}
