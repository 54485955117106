<div class="container-space-between">
  <app-page-title title="User settings"></app-page-title>
</div>

<h2>Organisations</h2>

<ng-container
  [ngTemplateOutletContext]="{ settings: organisationsSettings }"
  [ngTemplateOutlet]="settingsTemplate"
></ng-container>

<h2>API</h2>

<ng-container
  [ngTemplateOutletContext]="{ settings: apiSettings }"
  [ngTemplateOutlet]="settingsTemplate"
></ng-container>

<ng-template #settingsTemplate let-settings="settings">
  <div class="settings-container flex-wrap">
    @for (setting of settings; track $index) {
      <iov-settings-card (pressed)="onGoToRoute(setting.route)" [setting]="setting" />
    }
  </div>
</ng-template>
