import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  TemplateRef,
  effect,
  inject,
  input,
  signal,
  viewChild,
  viewChildren,
} from "@angular/core";
import { FormArray, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";

import { SlideOverlayPageService } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.service";

import { ConfirmDialogComponent, DragDropListItemComponent } from "@components/index";
import { CopyModeEnum } from "@components/shared/value-and-copy-button/value-and-copy-button.model";
import { AuthenticationService } from "@shared/services";

import { CategoryFormGroup, IndicatorFormGroup, IndicatorType } from "./models";
import { RiskAssessmentTemplateOverlayIndicatorsFormService } from "./risk-assessment-template-overlay-indicators-form.service";

@Component({
  selector: "app-risk-assessment-template-overlay-indicators",
  templateUrl: "./risk-assessment-template-overlay-indicators.component.html",
  styleUrl: "./risk-assessment-template-overlay-indicators.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskAssessmentTemplateOverlayIndicatorsComponent {
  private readonly cd = inject(ChangeDetectorRef);

  private readonly dialog = inject(MatDialog);

  private readonly indicatorsFormService = inject(
    RiskAssessmentTemplateOverlayIndicatorsFormService,
  );

  private readonly authenticationService = inject(AuthenticationService);

  readonly overlay = inject(SlideOverlayPageService);

  readonly form = input.required<FormArray<FormGroup<IndicatorFormGroup | CategoryFormGroup>>>();

  private readonly dragDropListItems = viewChildren(DragDropListItemComponent);

  public readonly copyModeEnum = CopyModeEnum;

  readonly controller = viewChild("controller", { read: TemplateRef });

  readonly addedIndicatorIndex = signal<number>(undefined);

  readonly validationMessage =
    "There must be at least one indicator provided within or outside of a category";

  public readonly canAddModifyEntities = this.authenticationService.canAddModifyEntities();

  get controls() {
    return this.form().controls;
  }

  get isSubmitButtonDisabled() {
    return (
      !this.controls.some((control) => control.value._type === "indicator") ||
      this.controls[this.controls.length - 1].value._type === "category"
    );
  }

  constructor() {
    effect(() => this.scrollToDragDropListItem(this.addedIndicatorIndex()), {
      allowSignalWrites: true,
    });
  }

  add(type: IndicatorType) {
    type === "indicator"
      ? this.indicatorsFormService.addIndicatorFormGroup()
      : this.indicatorsFormService.addCategoryFormGroup();

    this.addedIndicatorIndex.set(this.controls.length - 1);
    this.cd.markForCheck();
  }

  onDrop(event: CdkDragDrop<DragDropListItemComponent>) {
    moveItemInArray(this.controls, event.previousIndex, event.currentIndex);
  }

  onMoveUp(index: number) {
    moveItemInArray(this.controls, index, index - 1);
  }

  onMoveDown(index: number) {
    moveItemInArray(this.controls, index, index + 1);
  }

  onRemove(index: number) {
    const controlValue = this.controls[index].getRawValue();

    const remove = () => {
      this.indicatorsFormService.remove(index);
      this.cd.markForCheck();
    };

    controlValue.name.length
      ? this.dialog.open(ConfirmDialogComponent, {
          data: {
            titleTranslatedText: `Remove indicator`,
            contentTranslatedText: `Are you sure you want to remove this ${controlValue._type}?`,
            confirmButtonColor: "danger",
            confirmButtonTranslatedText: "Remove",
            confirmButtonIcon: "delete",
            onSubmit: () => remove(),
          },
        })
      : remove();
  }

  isDeleteButtonDisabled(index: number) {
    return (
      this.controls.at(index).value._type === "indicator" &&
      this.controls.filter((control) => control.value._type === "indicator").length === 1
    );
  }

  private scrollToDragDropListItem(index: number) {
    const listItems = this.dragDropListItems();

    if (typeof index === "number" && listItems.length) {
      const item = listItems[index];

      if (item) {
        const element = item.content().nativeElement as HTMLElement;

        element.scrollIntoView({ behavior: "smooth" });
        element.querySelector<HTMLInputElement>(".name-field input").focus({ preventScroll: true });

        this.addedIndicatorIndex.set(undefined);
      }
    }
  }
}
