import { AfterViewInit, ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { ColDef } from "ag-grid-community";

import { CertificatesTableComponent } from "@components/shared";
import { LinkCellRendererComponent } from "@shared/cell-renderers";
import { ICertificateExtended, ILocationExtended } from "@shared/interfaces";

export interface ICertificateExtendedWithLocation extends ICertificateExtended {
  location: ILocationExtended;
}

@Component({
  selector: "app-delivery-report-certificates-summary",
  templateUrl: "./delivery-report-certificates-summary.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryReportCertificatesSummaryComponent
  extends CertificatesTableComponent
  implements AfterViewInit
{
  @Input()
  public override rowData: ICertificateExtendedWithLocation[] = [];

  public override async ngAfterViewInit(): Promise<void> {
    this.setColumnDefs();
    this.isLoading.set(false);
  }

  override additionalColumnDefs(): ColDef<ICertificateExtendedWithLocation>[] {
    return [
      {
        headerName: "Location",
        colId: "location",
        field: "location.name",
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteIdParam: "location.id",
          linkRouteFn: this.routerService.getLocationLink,
        },
      },
    ];
  }
}
