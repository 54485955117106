import { AfterViewInit, ChangeDetectionStrategy, Component, Input, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef } from "ag-grid-community";
import { EditCustomUnitDialogComponent } from "src/app/components/settings/settings-units-of-measurement";
import { EnumsCellRendererComponent } from "src/app/shared/cell-renderers";
import {
  ConfirmDialogResponseEnum,
  TableEnum,
  UnitOfMeasurementSourceTypeEnum,
} from "src/app/shared/enums";
import { IBaseUnit, ICustomUnitOfMeasurement } from "src/app/shared/interfaces";
import { AuthenticationService, UnitsOfMeasurementService } from "src/app/shared/services";
import { ColumnUtils, CommonUtils } from "src/app/shared/utils";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-units-of-measurement-table",
  templateUrl: "./units-of-measurement-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnitsOfMeasurementTableComponent implements AfterViewInit {
  @Input()
  public units: IBaseUnit[] = null;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isRecordStateFilterEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  @Input()
  public table = TableEnum.ADMIN_UNITS_OF_MEASUREMENT;

  @Input()
  public isSaveTableState = false;

  @Input()
  public unitSourceType: UnitOfMeasurementSourceTypeEnum = UnitOfMeasurementSourceTypeEnum.SYSTEM;

  @Input()
  public columns: string[] = ["name", "symbol", "type"];

  public isLoading = signal(true);

  public rowData: IBaseUnit[] | ICustomUnitOfMeasurement[] = [];

  public columnDefs = signal<ColDef[]>([]);

  private readonly canAddModifyEntities = this.authenticationService.canAddModifyEntities();

  constructor(
    private unitsOfMeasurementService: UnitsOfMeasurementService,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
  ) {}

  public async ngAfterViewInit() {
    this.setColumnDefs();
    await this.getAll();
  }

  private setColumnDefs = (): void => {
    const columnDefs: ColDef[] = [
      { headerName: "Name", field: "name", lockVisible: true },
      { headerName: "Symbol", field: "symbol" },
      { headerName: "Category", field: "type", cellRenderer: EnumsCellRendererComponent },
      { headerName: "System unit", field: "systemUnit.name" },
      { headerName: "Conversion factor", field: "conversionFactorWithSymbol" },
    ];

    if (this.areButtonsEnabled && this.canAddModifyEntities) {
      const buttons = [
        {
          tooltip: "Delete",
          icon: "delete",
          onClick: this.onDelete,
        },
        {
          tooltip: "Edit",
          icon: "edit",
          onClick: this.onEdit,
        },
      ];

      columnDefs.push(ColumnUtils.buttons(buttons));
    }
    this.columnDefs.set(CommonUtils.getVisibleColumnDefs(columnDefs, this.columns));
  };

  public onEdit = async (customUnit: ICustomUnitOfMeasurement): Promise<void> => {
    const dialogRef = this.dialog.open(EditCustomUnitDialogComponent, {
      width: "665px",
      data: { customUnit },
    });

    dialogRef.afterClosed().subscribe(async (result: { hasSaved: boolean }) => {
      if (result?.hasSaved) {
        await this.getAll();
      }
    });
  };

  public onDelete = async (unit: ICustomUnitOfMeasurement): Promise<void> => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: `Delete custom unit`,
        contentTranslatedText: "Are you sure that you want to delete this custom unit?",
        confirmButtonColor: "danger",
        confirmButtonTranslatedText: "Delete",
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        await this.remove(unit.id);
      }
    });
  };

  private remove = async (unitId: string): Promise<void> => {
    try {
      await this.unitsOfMeasurementService.delete(unitId);
      this.notificationService.showSuccess(`Custom unit deleted`);
      await this.getAll();
    } catch (error) {
      this.notificationService.showError(error);
    }
  };

  public getAll = async (): Promise<void> => {
    this.isLoading.set(true);
    if (this.units) {
      this.rowData = this.units;
      this.isLoading.set(false);
    } else {
      try {
        switch (this.unitSourceType) {
          case UnitOfMeasurementSourceTypeEnum.CUSTOM:
            const customUnits = await this.unitsOfMeasurementService.getAll();
            const systemUnits = await this.unitsOfMeasurementService.getSystemUnits();

            this.rowData = customUnits.map((unit) => {
              const systemUnit = systemUnits.find((u) => unit.baseUnit.includes(u.id));

              return {
                ...unit,
                conversionFactorWithSymbol: `${unit.conversionFactor} ${systemUnit.symbol}`,
                systemUnit,
              };
            });
            break;
          default:
            this.rowData = await this.unitsOfMeasurementService.getSystemUnits();
            break;
        }
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };
}
