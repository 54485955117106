import { ChangeDetectionStrategy, Component, DestroyRef, inject, viewChild } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatDialog } from "@angular/material/dialog";

import { filter, switchMap } from "rxjs";

import { InfoDialogComponent } from "@components/shared";
import { CommonConstants } from "@shared/constants";
import { RecordStateEnum, RoutingEnum } from "@shared/enums";
import { RiskLevelSet } from "@shared/risk-level-sets/models";
import { RiskLevelSetsTableComponent } from "@shared/risk-level-sets/ui";
import { AuthenticationService } from "@shared/services";

import {
  AddEditRiskLevelSetDialogComponent,
  AddEditRiskLevelSetDialogData,
} from "./add-edit-risk-level-set-dialog.component";

@Component({
  selector: "app-settings-risk-level-sets",
  templateUrl: "./settings-risk-level-sets.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsRiskLevelSetsComponent {
  private readonly destroyRef = inject(DestroyRef);

  private readonly dialog = inject(MatDialog);

  readonly canAddModifyEntities = inject(AuthenticationService).canAddModifyEntities();

  private readonly table = viewChild<RiskLevelSetsTableComponent>("riskLevelSetsTable");

  readonly recordState = RecordStateEnum;

  readonly routing = RoutingEnum;

  onEdit(riskLevelSet?: RiskLevelSet) {
    if (riskLevelSet?.recordState === RecordStateEnum.ARCHIVED) {
      this.dialog.open(InfoDialogComponent, {
        data: {
          icon: "error",
          iconColor: "red",
          contentTranslatedText: CommonConstants.EDIT_ARCHIVED_RECORD_ERROR_TEXT,
        },
      });

      return;
    }

    const dialogRef = this.dialog.open<
      AddEditRiskLevelSetDialogComponent,
      AddEditRiskLevelSetDialogData
    >(AddEditRiskLevelSetDialogComponent, {
      data: { riskLevelSet },
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter(Boolean),
        switchMap(() => this.table().store.fetchData()),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }
}
