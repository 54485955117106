import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { CardContentComponent } from "@design-makeover/components/cards/card-content/card-content.component";

import { IMaterial } from "@shared/interfaces";

@Component({
  selector: "iov-material-card-content",
  templateUrl: "./material-card-content.component.html",
  styleUrl: "./material-card-content.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialCardContentComponent extends CardContentComponent {
  @Input() material: IMaterial;
}
