import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatOptionModule, NativeDateAdapter } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatToolbarModule } from "@angular/material/toolbar";
import { RouterModule } from "@angular/router";

import { TippyDirective } from "@ngneat/helipopper";
import { SidebarItemComponent } from "src/app/design-makeover/components/sidebar/sidebar-item/sidebar-item.component";
import { SidebarMenuComponent } from "src/app/design-makeover/components/sidebar/sidebar-menu/sidebar-menu.component";

import { ButtonComponent } from "@design-makeover/components/buttons/button/button.component";
import { ButtonGroupComponent } from "@design-makeover/components/buttons/button-group/button-group.component";
import { CardContentComponent } from "@design-makeover/components/cards/card-content/card-content.component";
import { GroupedProductCardContentComponent } from "@design-makeover/components/cards/grouped-product-card-content/grouped-product-card-content.component";
import { CertificateContentCardComponent } from "@design-makeover/components/certificates/components/certificate-content-card.component";
import {
  CustomDateAdapter,
  DatepickerComponent,
  DateRangePickerComponent,
} from "@design-makeover/components/datepicker";
import { FlagComponent } from "@design-makeover/components/flag/flag.component";
import { FormFieldGroupComponent } from "@design-makeover/components/form/form-field-group/form-field-group.component";
import { FormHintComponent } from "@design-makeover/components/form/form-hint/form-hint.component";
import { FormInputReadonlyComponent } from "@design-makeover/components/form/form-input-readonly/form-input-readonly.component";
import { InputComponent } from "@design-makeover/components/inputs/input/input.component";
import { SlideOverlayContentComponent } from "@design-makeover/components/overlay/slide-overlay-content/slide-overlay-content.component";
import { SlideOverlayContentHeaderComponent } from "@design-makeover/components/overlay/slide-overlay-content-header/slide-overlay-content-header.component";
import { SlideOverlayHeaderComponent } from "@design-makeover/components/overlay/slide-overlay-header/slide-overlay-header.component";
import { SlideOverlayMenuItemDirective } from "@design-makeover/components/overlay/slide-overlay-menu-item/slide-overlay-menu-item.directive";
import { SlideOverlayPageComponent } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.component";
import { SlideOverlayWrapperComponent } from "@design-makeover/components/overlay/slide-overlay-wrapper/slide-overlay-wrapper.component";
import { RadioButtonGroupComponent } from "@design-makeover/components/radio-button/radio-button-group.component";

import {
  EntityTagsComponent,
  ExternalLinkIconComponent,
  ValueAndCopyButtonComponent,
} from "@components/shared";
import { LoaderComponent } from "@components/shared/loader/loader.component";

import { ButtonToggleComponent } from "./components/buttons/button-toggle/button-toggle.component";
import { CommentCardContentComponent } from "./components/cards/comment-card-content/comment-card-content.component";
import { DeliveryCardContentComponent } from "./components/cards/delivery-card-content/delivery-card-content.component";
import { DocumentContentCardComponent } from "./components/cards/documents/components/document-content-card/document-content-card.component";
import { DraggableCardComponent } from "./components/cards/draggable-card/draggable-card.component";
import { ItemCardContentComponent } from "./components/cards/item-card-content/item-card-content.component";
import { LocationCardContentComponent } from "./components/cards/location-card-content/location-card-content.component";
import { MaterialCardContentComponent } from "./components/cards/material-card-content/material-card-content.component";
import { ProductCardContentComponent } from "./components/cards/product-card-content/product-card-content.component";
import { UnitOfMeasurementCardComponent } from "./components/cards/unit-of-measurement-card/unit-of-measurement-card.component";
import { CheckboxComponent } from "./components/checkbox/checkbox.component";
import { DocumentListComponent } from "./components/document-list/document-list.component";
import { DocumentUploadStatusComponent } from "./components/document-upload-status/document-upload-status.component";
import { DragDropContainerComponent } from "./components/drag-drop-container/drag-drop-container.component";
import { DropdownButtonComponent } from "./components/dropdown/dropdown-button/dropdown-button.component";
import { DropdownMenuComponent } from "./components/dropdown/dropdown-menu/dropdown-menu.component";
import { DropdownMenuItemComponent } from "./components/dropdown/dropdown-menu-item/dropdown-menu-item.component";
import { EditableDivComponent } from "./components/editable-div/editable-div.component";
import { EmptySearchResultsComponent } from "./components/empty-search-results/empty-search-results.component";
import { FormInputComponent } from "./components/form/form-input/form-input.component";
import { FormLabelComponent } from "./components/form/form-label/form-label.component";
import { InputChipsComponent } from "./components/inputs/input-chips/input-chips.component";
import { InputPasswordComponent } from "./components/inputs/input-password/input-password.component";
import { InputSearchComponent } from "./components/inputs/input-search/input-search.component";
import { InputSelectComponent } from "./components/inputs/input-select/input-select.component";
import { RadioButtonComponent } from "./components/radio-button/radio-button.component";
import { RightSidePanelComponent } from "./components/right-side-panel/right-side-panel.component";
import { SettingsCardComponent } from "./components/settings-card/settings-card.component";
import { ShortcutAddButtonComponent } from "./components/shorcut-add-menu/shorcut-add-button/shortcut-add-button.component";
import { ShortcutAddMenuComponent } from "./components/shorcut-add-menu/shortcut-add-menu.component";
import { SidebarDropdownComponent } from "./components/sidebar/sidebar-dropdown/sidebar-dropdown.component";
import { StepDirective } from "./components/stepper/step.directive";
import { StepperComponent } from "./components/stepper/stepper/stepper.component";
import { TagGroupComponent } from "./components/tag/tag-group.component";
import { TagComponent } from "./components/tag/tag.component";
import { UploadFilesBoxComponent } from "./components/upload-files-box/upload-files-box.component";
import { UserAccountButtonComponent } from "./components/user-account-button/user-account-button.component";
import {
  AutocompletePositionUpdateDirective,
  BackNavigationDirective,
  NoScrollInputDirective,
} from "./directives";
import { EnumToTextPipe } from "./pipes/enum-to-text.pipe";
import { LocalTimePipe } from "./pipes/local-time.pipe";
import { SafeUrlPipe } from "./pipes/safe-url.pipe";
import { UnitConversionPipe } from "./pipes/unit-conversion.pipe";

@NgModule({
  providers: [
    {
      provide: NativeDateAdapter,
      useClass: CustomDateAdapter,
    },
  ],
  declarations: [
    ButtonComponent,
    ButtonGroupComponent,
    CardContentComponent,
    CertificateContentCardComponent,
    CertificateContentCardComponent,
    DateRangePickerComponent,
    DatepickerComponent,
    DocumentContentCardComponent,
    DraggableCardComponent,
    DraggableCardComponent,
    DropdownButtonComponent,
    DropdownMenuComponent,
    DropdownMenuItemComponent,
    EmptySearchResultsComponent,
    FlagComponent,
    FormHintComponent,
    FormInputComponent,
    FormLabelComponent,
    LocationCardContentComponent,
    ProductCardContentComponent,
    InputComponent,
    InputSearchComponent,
    InputSelectComponent,
    SidebarDropdownComponent,
    SidebarItemComponent,
    SidebarMenuComponent,
    InputPasswordComponent,
    ButtonToggleComponent,
    EmptySearchResultsComponent,
    SettingsCardComponent,
    UploadFilesBoxComponent,
    DocumentListComponent,
    RadioButtonComponent,
    RadioButtonGroupComponent,
    CheckboxComponent,
    DocumentUploadStatusComponent,
    MaterialCardContentComponent,
    ItemCardContentComponent,
    UserAccountButtonComponent,
    RightSidePanelComponent,
    TagComponent,
    TagGroupComponent,
    DragDropContainerComponent,
    DeliveryCardContentComponent,
    SafeUrlPipe,
    EnumToTextPipe,
    LocalTimePipe,
    UnitConversionPipe,
    SlideOverlayPageComponent,
    SlideOverlayHeaderComponent,
    SlideOverlayContentComponent,
    SlideOverlayContentHeaderComponent,
    SlideOverlayMenuItemDirective,
    FormFieldGroupComponent,
    FormInputReadonlyComponent,
    SlideOverlayWrapperComponent,
    UnitOfMeasurementCardComponent,
    CommentCardContentComponent,
    EditableDivComponent,
    InputChipsComponent,
    StepperComponent,
    StepDirective,
    ShortcutAddMenuComponent,
    ShortcutAddButtonComponent,
    GroupedProductCardContentComponent,
  ],
  imports: [
    CommonModule,
    DragDropModule,
    FormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatOptionModule,
    MatRadioButton,
    MatSidenavModule,
    MatToolbarModule,
    NgOptimizedImage,
    ReactiveFormsModule,
    RouterModule,
    TippyDirective,
    MatSlideToggleModule,
    MatRadioGroup,
    MatCheckbox,
    MatProgressBarModule,
    MatBadgeModule,
    AutocompletePositionUpdateDirective,
    NoScrollInputDirective,
    LoaderComponent,
    EntityTagsComponent,
    ValueAndCopyButtonComponent,
    ExternalLinkIconComponent,
    MatChipsModule,
    BackNavigationDirective,
  ],
  exports: [
    ButtonComponent,
    ButtonGroupComponent,
    CardContentComponent,
    CertificateContentCardComponent,
    CertificateContentCardComponent,
    DateRangePickerComponent,
    DatepickerComponent,
    DocumentContentCardComponent,
    DraggableCardComponent,
    DraggableCardComponent,
    DropdownButtonComponent,
    DropdownMenuComponent,
    DropdownMenuItemComponent,
    EmptySearchResultsComponent,
    FlagComponent,
    InputComponent,
    InputSearchComponent,
    InputSelectComponent,
    LocationCardContentComponent,
    SidebarItemComponent,
    SidebarMenuComponent,
    InputPasswordComponent,
    FormHintComponent,
    ButtonToggleComponent,
    SettingsCardComponent,
    UploadFilesBoxComponent,
    DocumentListComponent,
    RadioButtonComponent,
    RadioButtonGroupComponent,
    CheckboxComponent,
    DocumentUploadStatusComponent,
    MaterialCardContentComponent,
    ItemCardContentComponent,
    UserAccountButtonComponent,
    RightSidePanelComponent,
    TagComponent,
    TagGroupComponent,
    DragDropContainerComponent,
    DeliveryCardContentComponent,
    ProductCardContentComponent,
    EnumToTextPipe,
    LocalTimePipe,
    UnitConversionPipe,
    SlideOverlayPageComponent,
    SlideOverlayHeaderComponent,
    SlideOverlayContentComponent,
    SlideOverlayMenuItemDirective,
    FormFieldGroupComponent,
    FormInputReadonlyComponent,
    FormLabelComponent,
    SlideOverlayWrapperComponent,
    UnitOfMeasurementCardComponent,
    CommentCardContentComponent,
    EditableDivComponent,
    InputChipsComponent,
    StepperComponent,
    StepDirective,
    ShortcutAddMenuComponent,
    ShortcutAddButtonComponent,
    GroupedProductCardContentComponent,
  ],
})
export class DesignMakeoverModule {}
