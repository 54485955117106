@if (formGroup && chunkVisibleCustomFields?.length) {
  @if (!enableViewMode) {
    <div class="dashed-divider"></div>
  }

  <div class="row-container" [formGroup]="formGroup">
    <div *ngFor="let chunk of chunkVisibleCustomFields" class="row row-{{ columns }}">
      <div *ngFor="let customField of chunk" class="item">
        @switch (customField.type) {
          @case ("TEXT") {
            <iov-input
              [enableViewMode]="enableViewMode"
              [formControlName]="'cf_' + customField.id"
              [label]="customField.label"
            ></iov-input>
          }
        }
      </div>
    </div>
  </div>
}
