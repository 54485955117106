import { AfterViewInit, ChangeDetectionStrategy, Component, Input, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Router } from "@angular/router";

import { ColDef } from "ag-grid-community";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { DateCellRendererComponent, LinkCellRendererComponent } from "@shared/cell-renderers";
import { RoutingEnum, TableEnum } from "@shared/enums";
import { ISelectOption } from "@shared/interfaces";
import { AdminOrganisationsService, CommonService } from "@shared/services";
import { CellRendererUtils, CommonUtils } from "@shared/utils";

@Component({
  selector: "app-admin-organisations-table",
  templateUrl: "./admin-organisations-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminOrganisationsTableComponent implements AfterViewInit {
  @Input()
  public organisations: any[] = null;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  @Input()
  public table = TableEnum.ADMIN_ORGANISATIONS;

  @Input()
  public isSaveTableState = false;

  public isLoading = signal(true);

  public rowData: any[] = [];

  public columnDefs = signal<ColDef[]>([]);

  private countryOptions: ISelectOption[] = [];

  constructor(
    private adminOrganisationsService: AdminOrganisationsService,
    private notificationService: NotificationService,
    private commonService: CommonService,
    private router: Router,
  ) {
    this.commonService.countriesOptionsObservable$
      .pipe(takeUntilDestroyed())
      .subscribe((countriesOptions: ISelectOption[]) => {
        this.countryOptions = countriesOptions;
      });
  }

  public async ngAfterViewInit() {
    this.setColumnDefs();
    await this.getAll();
  }

  private setColumnDefs = (): void => {
    this.columnDefs.set([
      {
        headerName: "Name",
        field: "name",
        lockVisible: true,
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRoute: RoutingEnum.ADMIN_ORGANISATION_DETAILS,
          linkRouteIdParam: "id",
        },
      },
      {
        headerName: "Country",
        field: "address.countryName",
        cellRenderer: CellRendererUtils.country,
      },
      {
        headerName: "Date registered",
        field: "createdTime",
        cellRenderer: DateCellRendererComponent,
      },
    ]);
  };

  public onViewDetails = async (row: any): Promise<void> => {
    await this.router.navigate([`/${RoutingEnum.ADMIN_ORGANISATION_DETAILS}/${row.id}`]);
  };

  public getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    if (this.organisations) {
      this.rowData = CommonUtils.getElementsWithCountryName(
        this.countryOptions,
        this.organisations,
      );
      this.isLoading.set(false);
    } else {
      try {
        const organisations = await this.adminOrganisationsService.getAll();

        this.rowData = CommonUtils.getElementsWithCountryName(this.countryOptions, organisations);
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };
}
