import { ChangeDetectionStrategy, Component } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

import { CommonConstants } from "@shared/constants";

@Component({
  template: `
    {{ params.value ? (params.value | number: params["rounding"] ?? defaultNumberRounding) : "-" }}
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoundNumberCellRendererComponent implements ICellRendererAngularComp {
  public params!: ICellRendererParams;

  public readonly defaultNumberRounding = CommonConstants.DEFAULT_NUMBER_PIPE_ROUNDING;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
