import { Router } from "@angular/router";

import { ColDef } from "ag-grid-community";
import { round } from "lodash";

import { ReportsEudrModel } from "@components/reports/eudr/reports-eudr.model";
import { GeoJSONUtils } from "@shared/utils/geojson-utils";

import { FileUtils } from "./file.utils";
import { CommonConstants } from "../constants";
import {
  AttachmentTargetEnum,
  AttachmentTypeEnum,
  CustomFieldsResourceTypeEnum,
  RoutingEnum,
  UserRoleEnum,
} from "../enums";
import {
  IAttachment,
  IAttachmentPayload,
  IBaseUnit,
  ICustomUnitOfMeasurement,
  IDocument,
  IDocumentExtended,
  IDocumentType,
  IItem,
  IItemDetails,
  IItemExtended,
  ILocation,
  ILocationExtended,
  ILocationType,
  IMaterial,
  IProcessType,
  IProduct,
  IProductExtended,
  ISelectOption,
} from "../interfaces";

const textToClipboard = (text: string): void => {
  navigator.clipboard.writeText(text);
};

const getVisibleColumnDefs = (columnDefs: ColDef[], columns: string[]): ColDef[] => {
  const filteredColumnDefs = columnDefs.filter((colDef) =>
    columns.some(
      (columnName) =>
        (!colDef.colId && !colDef.field) ||
        (colDef.colId && columnName === colDef.colId) ||
        (!colDef.colId && colDef.field && colDef.field === columnName),
    ),
  );

  const orderedColumnDefs = columns
    .map((columnName) =>
      filteredColumnDefs.find(
        (colDef) =>
          (colDef.colId && columnName === colDef.colId) ||
          (!colDef.colId && colDef.field && colDef.field === columnName),
      ),
    )
    .filter((colDef) => !!colDef);

  // Let's add the columns that are not in 'columns' to the end of the array
  const additionalColumnDefs = filteredColumnDefs.filter(
    (colDef) => !colDef.field && !colDef.colId,
  );

  return [...orderedColumnDefs, ...additionalColumnDefs];
};

// TODO: Remove it. This method is not used anywhere.
const setNullForAllEmptyProperties = (object: object): object => {
  if (!object) {
    return null;
  }
  for (const propertyName in object) {
    const propertyValue = object[propertyName];

    if (typeof propertyValue === "string" && (!propertyValue || !propertyValue.trim())) {
      object[propertyName] = null;
    }
  }

  return object;
};
// TODO: Remove it. This method is not used anywhere.
const setPrimaryOrSecondaryValue = (
  object: object,
  primaryObject: object,
  secondaryObject: object,
): object => {
  if (!object) {
    return null;
  }
  for (const propertyName in object) {
    if (primaryObject && primaryObject[propertyName]) {
      object[propertyName] = primaryObject[propertyName];
    } else if (secondaryObject && secondaryObject[propertyName]) {
      object[propertyName] = secondaryObject[propertyName];
    }
  }

  return object;
};

// TODO: Remove it. This method is not used anywhere.
const removePropertiesFromObject = (object: object, properties: string[]): object => {
  if (!object) {
    return null;
  }
  if (!properties?.length) {
    return object;
  }
  for (const propertyName of properties) {
    delete object[propertyName];
  }

  return object;
};

const openInNewTab = (url: string): void => {
  window.open(url, "_blank");
};

const openRouteInNewTab = (router: Router, route: RoutingEnum | string): void => {
  const url = router.serializeUrl(router.createUrlTree([`/${route}`]));

  openInNewTab(url);
};

const getUriId = (uri: string): string => {
  if (!uri) {
    return null;
  }

  return uri.substring(uri.lastIndexOf("/") + 1);
};

const getTargetAttachment = (
  attachments: IAttachment[],
  targetType: AttachmentTargetEnum,
  attachmentType: AttachmentTypeEnum,
  attachmentId: string,
): IAttachment => {
  if (!attachments?.length) {
    return null;
  }

  return attachments.find(
    (a) =>
      a.targetUri.indexOf(`/${targetType.toLowerCase()}s/`) !== -1 &&
      a.attachmentUri.indexOf(`/${attachmentType.toLowerCase()}s/`) !== -1 &&
      getUriId(a.attachmentUri) === attachmentId,
  );
};

const getNamePropertyById = (array: any[], id: string): string => {
  if (!array?.length) {
    return null;
  }

  const item = array.find((a) => a.id === id);

  return item?.name;
};

const getElementWithCountryName = (countryOptions: ISelectOption[], element: any): void => {
  if (element?.address?.country) {
    const country = countryOptions.find((c) => c.value === element.address.country);

    element.address.countryName = country?.label ?? element.address.country;
  }
};

const getElementsWithCountryName = (countryOptions: ISelectOption[], elements: any): any => {
  if (!countryOptions?.length) {
    return elements;
  }
  if (Array.isArray(elements)) {
    for (const element of elements) {
      getElementWithCountryName(countryOptions, element);
    }
  } else {
    getElementWithCountryName(countryOptions, elements);
  }

  return elements;
};

const getElementWithProductInfo = (products: IProduct[], element: any): void => {
  if (element?.product) {
    const productId = getUriId(element.product);
    const product = products.find((c) => c.id === productId);

    if (product) {
      element.productName = product?.name;
      element.productId = product?.id;
    }
  }
};

const getElementsWithProductInfo = (products: IProduct[], elements: any): any => {
  if (Array.isArray(elements)) {
    for (const element of elements) {
      getElementWithProductInfo(products, element);
    }
  } else {
    getElementWithProductInfo(products, elements);
  }

  return elements;
};

const getElementWithLocationName = (locations: ILocation[], element: any): void => {
  if (element?.currentLocation) {
    const locationId = getUriId(element.currentLocation);
    const location = locations.find((c) => c.id === locationId);

    element.locationName = location?.name ?? "N/A";
  }
};

const getElementsWithLocationName = (locations: ILocation[], elements: any): any => {
  if (Array.isArray(elements)) {
    for (const element of elements) {
      getElementWithLocationName(locations, element);
    }
  } else {
    getElementWithLocationName(locations, elements);
  }

  return elements;
};

const getElementWithMaterialNames = (
  materials: IMaterial[],
  element: any,
  materialsPropertyName: string,
): void => {
  if (!element) {
    return null;
  }
  if (element[materialsPropertyName]?.length) {
    element.materialNames = [];
    for (const materialUri of element[materialsPropertyName]) {
      const materialId = getUriId(materialUri);

      const material = materials?.find((c) => c.id === materialId);

      if (material) {
        element.materialNames.push(material.name);
      }
    }
  }
};

const getElementsWithMaterialNames = (
  materials: IMaterial[],
  elements: any,
  materialsPropertyName: string,
): any => {
  if (Array.isArray(elements)) {
    for (const element of elements) {
      getElementWithMaterialNames(materials, element, materialsPropertyName);
    }
  } else {
    getElementWithMaterialNames(materials, elements, materialsPropertyName);
  }

  return elements;
};

const getElementWithMaterialData = (
  materials: IMaterial[],
  element: any,
  materialsPropertyName: string,
): void => {
  if (!element) {
    return null;
  }
  if (element[materialsPropertyName]?.length) {
    element.materialData = [];
    for (const materialUri of element[materialsPropertyName]) {
      const materialId = getUriId(materialUri);

      const material = materials?.find((c) => c.id === materialId);

      if (material) {
        element.materialData.push({ name: material.name, id: material.id });
      }
    }
  }
};

const getElementsWithMaterialData = (
  materials: IMaterial[],
  elements: any,
  materialsPropertyName: string,
): any => {
  if (Array.isArray(elements)) {
    for (const element of elements) {
      getElementWithMaterialData(materials, element, materialsPropertyName);
    }
  } else {
    getElementWithMaterialData(materials, elements, materialsPropertyName);
  }

  return elements;
};

const getElementWithLocationTypeNames = (locationTypes: ILocationType[], element: any): void => {
  if (!element) {
    return null;
  }
  if (element?.typesNames?.length) {
    return element;
  }
  element.typesNames = [];

  for (const locationTypeUri of element.types) {
    const locationTypeId = getUriId(locationTypeUri);
    const locationType = locationTypes?.find((c) => c.id === locationTypeId);

    if (locationType) {
      element?.typesNames.push(locationType.type);
    }
  }
};

const getElementsWithLocationTypeNames = (locationTypes: ILocationType[], elements: any): any => {
  if (Array.isArray(elements)) {
    for (const element of elements) {
      getElementWithLocationTypeNames(locationTypes, element);
    }
  } else {
    getElementWithLocationTypeNames(locationTypes, elements);
  }

  return elements;
};

const getElementWithType = <T>(allTypes: IProcessType[] | IDocumentType[], element: T): void => {
  if (!element) {
    return;
  }
  if (element["typeName"]) {
    return;
  }
  const typeId = getUriId(element["type"]);
  const type = allTypes?.find((c) => c.id === typeId);

  if (type) {
    element["typeName"] = type.name;
  }
};

const getElementsWithType = <T>(
  allTypes: IProcessType[] | IDocumentType[],
  elements: T[] | T,
): T[] | T => {
  if (Array.isArray(elements)) {
    for (const element of elements) {
      getElementWithType<T>(allTypes, element);
    }
  } else {
    getElementWithType<T>(allTypes, elements);
  }

  return elements;
};

const getItemsWithProductAndUnitOfMeasurement = (
  items: IItemDetails[],
  allProducts: IProduct[],
  allUnitOfMeasurements: IBaseUnit[],
): IItemDetails[] => {
  for (const item of items) {
    const productId = CommonUtils.getUriId(item.product);
    const product = allProducts.find((p) => p.id === productId);

    if (product) {
      item.productId = productId;
      item.productName = product?.name || "-";
      const baseUnitId = CommonUtils.getUriId(product.baseUnit);

      item.unitOfMeasurement = allUnitOfMeasurements.find((u) => u.id === baseUnitId);
    }
  }

  return items;
};

const getItemsWithProductAndAssignedUnit = (
  items: IItemDetails[],
  allProducts: IProductExtended[],
): IItemDetails[] => {
  for (const item of items) {
    const productId = CommonUtils.getUriId(item.product);
    const product = allProducts.find((p) => p.id === productId);

    if (product) {
      item.productId = productId;
      item.productName = product?.name || "-";
      item.unitOfMeasurement = product?.unitOfMeasurement;
    }
  }

  return items;
};

const convertExtendedItemToItem = (
  extendedItem: IItemExtended,
  activeOrganisationId: string,
  isSharedUser: boolean,
): IItem => {
  return {
    ...extendedItem,
    initialQuantity: extendedItem.initialQuantity,
    materials: extendedItem.materials.map(
      (material) => `/organisations/${activeOrganisationId}/materials/${material.id}`,
    ),
    product: `/organisations/${activeOrganisationId}/products/${extendedItem.product.id}`,
    currentLocation:
      isSharedUser || !extendedItem.currentLocation
        ? undefined
        : `/organisations/${activeOrganisationId}/locations/${extendedItem.currentLocation.id}`,
    createdAtLocation:
      isSharedUser || !extendedItem.createdAtLocation
        ? undefined
        : `/organisations/${activeOrganisationId}/locations/${extendedItem.createdAtLocation.id}`,
  };
};

const getAttachmentUri = (
  activeOrganisationId: string,
  attachmentType: AttachmentTypeEnum,
  attachmentId: string,
): string => {
  switch (attachmentType) {
    case AttachmentTypeEnum.CERTIFICATE:
      return `/organisations/${activeOrganisationId}/certificates/${attachmentId}`;
    case AttachmentTypeEnum.DOCUMENT:
      return `/organisations/${activeOrganisationId}/documents/${attachmentId}`;
    case AttachmentTypeEnum.PRODUCT:
      return `/organisations/${activeOrganisationId}/products/${attachmentId}`;
    case AttachmentTypeEnum.LINK:
      return `/organisations/${activeOrganisationId}/location-links/${attachmentId}`;
    case AttachmentTypeEnum.MATERIAL:
      return `/organisations/${activeOrganisationId}/materials/${attachmentId}`;
    default:
      return null;
  }
};

const getTargetUri = (
  activeOrganisationId: string,
  attachmentTarget: AttachmentTargetEnum,
  targetId: string,
): string => {
  switch (attachmentTarget) {
    case AttachmentTargetEnum.LOCATION:
      return `/organisations/${activeOrganisationId}/locations/${targetId}`;
    case AttachmentTargetEnum.ORGANISATION: {
      if (activeOrganisationId === targetId) {
        return `/organisations/${activeOrganisationId}`;
      }

      return `/organisations/${activeOrganisationId}/connections/${targetId}`;
    }
    case AttachmentTargetEnum.CERTIFICATE:
      return `/organisations/${activeOrganisationId}/certificates/${targetId}`;
    case AttachmentTargetEnum.ITEM:
      return `/organisations/${activeOrganisationId}/items/${targetId}`;
    case AttachmentTargetEnum.DELIVERY:
      return `/organisations/${activeOrganisationId}/deliveries/${targetId}`;
    case AttachmentTargetEnum.PROCESS:
      return `/organisations/${activeOrganisationId}/processes/${targetId}`;
    case AttachmentTargetEnum.PRODUCT:
      return `/organisations/${activeOrganisationId}/products/${targetId}`;
    case AttachmentTargetEnum.LINK:
      return `/organisations/${activeOrganisationId}/location-links/${targetId}`;
    default:
      return null;
  }
};

const getSaveAttachmentPayload = (
  activeOrganisationId: string,
  attachmentTarget: AttachmentTargetEnum,
  targetId: string,
  attachmentType: AttachmentTypeEnum,
  attachmentId: string,
): IAttachmentPayload => ({
  targetUri: getTargetUri(activeOrganisationId, attachmentTarget, targetId),
  attachmentUri: getAttachmentUri(activeOrganisationId, attachmentType, attachmentId),
});

const setDocumentsCanView = (documents: IDocument[] | IDocumentExtended[]): void => {
  if (!documents?.length) {
    return;
  }
  for (const document of documents) {
    const fileExtension = FileUtils.getFileExtension(document.name).toUpperCase();

    document.canView =
      CommonConstants.DOCUMENT_ALLOW_TO_VIEW_FILE_EXTENSIONS.includes(fileExtension);
  }
};

const getTableSlicedElements = (allElements: any[], pageIndex: number, pageSize: number): any[] =>
  allElements.slice(pageIndex * pageSize, pageIndex * pageSize + pageSize);

const handleApiCall = async <T>(apiCall: Promise<T>, errorMessage: string): Promise<T> => {
  try {
    return await apiCall;
  } catch (e) {
    const error = new Error(errorMessage + ": " + e.message);

    throw error;
  }
};

const capitaliseFirstLetter = (text: string): string => {
  if (!text) {
    return null;
  }

  return `${text[0].toUpperCase()}${text.slice(1).toLowerCase()}`;
};

const getRolesNames = (roles: string[]): string => {
  if (!roles?.length || !roles[0]) {
    return null;
  }

  return enumToText(roles[0]);
};

const enumToText = (text: string): string => {
  if (!text) {
    return null;
  }

  return capitaliseFirstLetter(text.replace("_", " ").toLowerCase());
};

const textToEnum = (text: string): string => {
  if (!text) {
    return null;
  }

  return text.toLocaleUpperCase().replace(" ", "_");
};

const getUserRolesOptions = (loggedInUserRoles: string[]): ISelectOption[] => {
  const result: ISelectOption[] = [];

  if (
    !loggedInUserRoles.some((role: UserRoleEnum) =>
      [UserRoleEnum.ACCOUNT_OWNER, UserRoleEnum.ADMIN].includes(role),
    )
  ) {
    return [];
  }

  if (loggedInUserRoles.some((role: UserRoleEnum) => [UserRoleEnum.ACCOUNT_OWNER].includes(role))) {
    result.push(
      ...[
        {
          label: "Account owner",
          value: UserRoleEnum.ACCOUNT_OWNER,
        },
        {
          label: "Admin",
          value: UserRoleEnum.ADMIN,
        },
      ],
    );
  }

  result.push(
    ...[
      {
        label: "Contributor",
        value: UserRoleEnum.CONTRIBUTOR,
      },
      {
        label: "Reviewer",
        value: UserRoleEnum.REVIEWER,
      },
    ],
  );

  return result;
};

const supplyChainBottomPadding = 30;
const minSupplyChainHeight = 300;

const getNonOverlaySupplyChainHeight = (): number => {
  const windowHeight = window.innerHeight;
  const tabContentTopMargin = 23;
  const sideNavContentPadding = 46;

  const topHeader = getHtmlElementHeightWithMargins("iov-sidebar-menu");
  const titleHeader = getHtmlElementHeightWithMargins(".container-space-between");
  const tabHeader = getHtmlElementHeightWithMargins(".mat-mdc-tab-header");
  const description = getHtmlElementHeightWithMargins(".supply-chain-description");

  const availableHeight =
    windowHeight -
    topHeader -
    sideNavContentPadding -
    titleHeader -
    tabHeader -
    tabContentTopMargin -
    description -
    supplyChainBottomPadding;

  return Math.max(minSupplyChainHeight, availableHeight);
};

const getOverlaySupplyChainHeight = (): number => {
  const windowHeight = window.innerHeight;

  const pageTitleContainer = getHtmlElementHeightWithMargins(".page-title-container");
  const headerContainer = getHtmlElementHeightWithMargins(
    ".slide-overlay-content-header-container",
  );
  const availableHeight =
    windowHeight - pageTitleContainer - headerContainer - supplyChainBottomPadding;

  return Math.max(minSupplyChainHeight, availableHeight);
};

const getOverviewPageHeight = (): number => {
  const windowHeight = window.innerHeight;
  const toolBarHeight = getHtmlElementHeightWithMargins(".mat-toolbar");
  const headerHeight = getHtmlElementHeightWithMargins(".container-space-between");
  const sidenavContentTopPadding = 16;

  let activeOrgHeight = 0;
  const activeOrg = document.querySelector(".active-organisation");

  if (activeOrg) {
    activeOrgHeight = getHtmlElementHeightWithMargins(".active-organisation");
  }

  let tabGroupHeight = 0;
  const tabGroup = document.querySelector(".mat-mdc-tab-group");

  if (tabGroup) {
    const tabGroupHeaderHeight = getHtmlElementHeightWithMargins(".mat-mdc-tab-header");
    const tabGroupBodyWrapperMargin = 23;

    tabGroupHeight = tabGroupHeaderHeight + tabGroupBodyWrapperMargin;
  }

  const searchAndFiltersHeight = getHtmlElementHeightWithMargins("app-search-and-filters");
  const gridMargin = 5;
  const hasBottomPaginator = !!document.querySelector(".grid-footer");
  const bottomPaginatorHeight = hasBottomPaginator ? 62 : 0;

  return (
    windowHeight -
    toolBarHeight -
    headerHeight -
    sidenavContentTopPadding -
    activeOrgHeight -
    tabGroupHeight -
    searchAndFiltersHeight -
    gridMargin -
    bottomPaginatorHeight
  );
};

/**
 * Returns the element height including margins
 * @param selector - css query selector
 * @returns {number}
 */
const getHtmlElementHeightWithMargins = (selector: string): number => {
  const element = document.querySelector(selector) as HTMLElement;

  if (!element) {
    console.warn(`No element found with the selector: ${selector}`);

    return 0;
  }
  const height = element.offsetHeight,
    style = window.getComputedStyle(element);

  return ["top", "bottom"]
    .map((side) => parseInt(style[`margin-${side}`]))
    .reduce((total, side) => total + side, height);
};

const formatFileSizeMessage = (fileSize: number): string => {
  return `${(fileSize / 1000000).toFixed(1)}MB`;
};

/**
 * Use examples: getObjectNestedProperty("document.body.style", someObj); getObjectNestedProperty("part.0.size", arrayObject)
 * @param path path to target nested property
 * @param obj object
 * @returns property value or null if path does not exist
 */
const getObjectNestedProperty = (path: string, obj: any): any => {
  return path.split(".").reduce(function (prev, curr) {
    return prev ? prev[curr] : null;
  }, obj || self);
};

const getParsedCustomFieldResourceType = (resourceType: CustomFieldsResourceTypeEnum): string => {
  switch (resourceType) {
    case CustomFieldsResourceTypeEnum.ORGANISATION:
      return "My organisation";
    case CustomFieldsResourceTypeEnum.CONNECTION:
      return "Organisations";
    default:
      return capitaliseFirstLetter(pluraliseEntity(resourceType.toLowerCase()));
  }
};

const formatQuantityWithDefaultUnit = (
  quantity: number,
  defaultUnitOfMeasurement: ICustomUnitOfMeasurement & IBaseUnit,
  unitOfMeasurement: IBaseUnit,
): string => {
  const convertedQuantity = quantity / defaultUnitOfMeasurement.conversionFactor;
  const defaultScale = defaultUnitOfMeasurement.displayScale ?? 0;
  const defaultSymbol = defaultUnitOfMeasurement.symbol ?? "";
  const unitPrecision = unitOfMeasurement.precision ?? 0;
  const unitSymbol = unitOfMeasurement.symbol ?? "";
  const roundedConvertedQuantity = round(convertedQuantity, defaultScale);
  const roundedQuantity = round(quantity, unitPrecision);
  const formattedConvertedQuantity = roundedConvertedQuantity.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: defaultScale,
  });
  const formattedRoundedQuantity = roundedQuantity.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: unitPrecision,
  });

  return `${formattedConvertedQuantity} ${defaultSymbol} (${formattedRoundedQuantity} ${unitSymbol})`;
};

export const scrollToBottom = (scrollableElementClass: string = "main-container"): void => {
  setTimeout(() => {
    const scrollableElement = document.getElementsByClassName(scrollableElementClass)[0];

    scrollableElement.scrollTop = scrollableElement.scrollHeight;
  }, 1);
};

export const loadScripts = (sources: string[]): void => {
  for (const source of sources) {
    const node = document.createElement("script");

    node.src = source;
    node.type = "text/javascript";
    node.async = false;
    document.getElementsByTagName("head")[0].appendChild(node);
  }
};

export const groupArrayByKey = (array, key) => {
  // Return the end result
  return array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);

    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
};

export const groupArrayByKeyAndCount = (array, key) => {
  const groupedBy = groupArrayByKey(array, key);
  const result = [];

  for (const propertyName in groupedBy) {
    result.push({ key: propertyName, count: groupedBy[propertyName].length });
  }

  return result;
};

export const pluraliseEntity = (text: string): string => {
  if (text.endsWith("y")) {
    return text.slice(0, -1) + "ies";
  } else if (text.endsWith("ss")) {
    return `${text}es`;
  } else {
    return `${text}s`;
  }
};

export const singlifyEntity = (text: string): string => {
  if (text.endsWith("ies")) {
    return text.slice(0, -3) + "y";
  } else if (text.endsWith("sses")) {
    return text.slice(0, -2);
  } else {
    return text.slice(0, -1);
  }
};

export const isNumber = (value: string): boolean => {
  return !isNaN(Number(value));
};

export const doesUrlPathHaveParams = (path: string, params: string[]): boolean => {
  if (!path || !params?.length) {
    return false;
  }

  const urlObj = new URL(`${document.location.origin}${path}`);

  if (urlObj.searchParams.size < params.length) {
    return false;
  }

  let result = true;

  for (const param of params) {
    const paramValue = urlObj.searchParams.get(param);

    if (!paramValue) {
      result = false;
      break;
    }
  }

  return result;
};

export const isSameUrlPathButOnly1ParamHasChanged = (
  path1: string,
  path2: string,
  param: string,
): boolean => {
  if (!path1 || !path2 || !param || path1 === path2) {
    return false;
  }

  const url1Obj = new URL(`${document.location.origin}${path1.toLocaleLowerCase()}`);
  const url2Obj = new URL(`${document.location.origin}${path2.toLocaleLowerCase()}`);

  param = param.toLowerCase();

  if (
    url1Obj.pathname !== url2Obj.pathname ||
    (!url1Obj.searchParams.size && !url2Obj.searchParams.size) ||
    (!url1Obj.searchParams.get(param) && !url2Obj.searchParams.get(param)) ||
    url1Obj.searchParams.get(param) === url2Obj.searchParams.get(param)
  ) {
    return false;
  }

  let result = true;

  url1Obj.searchParams.forEach((value, key) => {
    if (key.toLowerCase() !== param) {
      const url2Value = url2Obj.searchParams.get(key);

      if (value !== url2Value) {
        result = false;
      }
    }
  });

  return result;
};

export const getLocationAreaInHa = (location: ILocationExtended): string => {
  if (GeoJSONUtils.isPolygon(location.geoLocation)) {
    const feature = location.geoLocation.features[0];
    const areaInHa = (GeoJSONUtils.geometry(feature.geometry) / 10_000).toFixed(2);

    return `${areaInHa}`;
  }

  return (
    location.customFieldsWithValues?.find(
      (customField) => customField.label === ReportsEudrModel.areaCustomFieldLabel,
    )?.value || ""
  );
};

export const getDocumentTypesByDocuments = (documents: IDocument[]): IDocumentType[] => {
  const documentTypeMap: {
    [key: string]: IDocumentType;
  } = {};

  documents.forEach((document) => {
    if (!documentTypeMap[document.type.id]) {
      documentTypeMap[document.type.id] = {
        id: document.type.id,
        name: document.type.name,
        documents: [],
        createdTime: null,
      };
    }
    documentTypeMap[document.type.id].documents.push(document);
  });

  return Object.values(documentTypeMap);
};

export const CommonUtils = {
  textToClipboard,
  getVisibleColumnDefs,
  setNullForAllEmptyProperties,
  setPrimaryOrSecondaryValue,
  removePropertiesFromObject,
  openInNewTab,
  openRouteInNewTab,
  getUriId,
  getTargetAttachment,
  getNamePropertyById,
  getElementsWithCountryName,
  getElementsWithProductInfo,
  getElementsWithLocationName,
  getElementsWithMaterialNames,
  getElementsWithLocationTypeNames,
  getElementsWithType,
  getItemsWithProductAndUnitOfMeasurement,
  convertExtendedItemToItem,
  getAttachmentUri,
  getTargetUri,
  getSaveAttachmentPayload,
  setDocumentsCanView,
  getTableSlicedElements,
  handleApiCall,
  capitaliseFirstLetter,
  enumToText,
  textToEnum,
  getRolesNames,
  getUserRolesOptions,
  getNonOverlaySupplyChainHeight,
  getOverlaySupplyChainHeight,
  getOverviewPageHeight,
  getHtmlElementHeightWithMargins,
  formatFileSizeMessage,
  getObjectNestedProperty,
  getParsedCustomFieldResourceType,
  formatQuantityWithDefaultUnit,
  scrollToBottom,
  loadScripts,
  groupArrayByKey,
  groupArrayByKeyAndCount,
  pluraliseEntity,
  singlifyEntity,
  isNumber,
  getItemsWithProductAndAssignedUnit,
  getElementsWithMaterialData,
  doesUrlPathHaveParams,
  isSameUrlPathButOnly1ParamHasChanged,
  getLocationAreaInHa,
  getDocumentTypesByDocuments,
};
