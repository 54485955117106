<div [ngClass]="[size]" class="form-field-item no-padding">
  <iov-form-input
    (clearEvent)="handleClear()"
    (keyUpEvent)="handleSearch($event)"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [searchQuery]="searchQuery"
    [showClear]="showClearButton"
    class="input-search"
    inputType="search"
  >
    <mat-icon [class.disabled]="disabled" prefix-icon svgIcon="search"></mat-icon>
  </iov-form-input>
</div>
