<div class="create-items">
  @if (!hasProcessCompleted()) {
    <div class="exit-warning-text">Please, do not exit this page until all items are created.</div>
  }

  <div class="progress-container">
    <mat-progress-bar [value]="completionPercentage"></mat-progress-bar>
    <div class="progress-numbers">{{ processedItemsCount }} / {{ payloadsWithStatus.length }}</div>
  </div>

  <div class="list">
    @if (payloadsWithStatus.length) {
      @for (payloadWithStatus of payloadsWithStatus; track $index) {
        <div class="item">
          @switch (payloadWithStatus.status) {
            @case (statusEnum.PENDING) {
              <mat-icon class="rotate-animation loading-icon">cached</mat-icon>
            }
            @case (statusEnum.SUCCESS) {
              <mat-icon class="icon" svgIcon="action_completed"></mat-icon>
            }
            @case (statusEnum.ERROR) {
              <mat-icon class="icon" svgIcon="action_pending"></mat-icon>
            }
            @case (statusEnum.CANCELED) {
              <mat-icon class="icon" svgIcon="action_pending"></mat-icon>
              (Canceled)
            }
          }
          {{ payloadWithStatus.itemPayload.itemId }}
        </div>
      }
    }
  </div>
</div>
