import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

import { LanguageEnum } from "src/app/shared/enums";
import { LocalizationService } from "src/app/shared/services";

@Component({
  templateUrl: "./user-settings-language-dialog.component.html",
  styleUrls: ["./user-settings-language-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSettingsLanguageDialogComponent implements OnInit {
  public currentLanguage: LanguageEnum = null;

  public readonly languageEnum = LanguageEnum;

  public readonly availableLanguages: any = [
    {
      name: "English",
      code: LanguageEnum.ENGLISH,
      flag: "gb",
    },
    {
      name: "Español",
      code: LanguageEnum.SPANISH,
      flag: "es",
    },
  ];

  constructor(
    private dialogRef: MatDialogRef<UserSettingsLanguageDialogComponent>,
    private localizationService: LocalizationService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.currentLanguage = this.localizationService.getLanguage();
  }

  public onSelect = async (languageCode: LanguageEnum): Promise<void> => {
    if (languageCode !== this.currentLanguage) {
      this.localizationService.setLanguage(languageCode);
      document.location.reload();
    } else {
      this.dialogRef.close();
    }
  };
}
