import { FormGroup } from "@angular/forms";

import { BulkAddItemsModel } from "@components/items/bulk-add-items/bulk-add-items.component.model";

export namespace BulkAddItemsEnterItemsModel {
  export interface IField {
    label: string;
    value: string;
  }

  export const fixedFieldMapping = {
    [BulkAddItemsModel.FieldEnum.IS_FIXED_TAGS]: "Tags",
    [BulkAddItemsModel.FieldEnum.IS_FIXED_PRODUCT]: "Product",
    [BulkAddItemsModel.FieldEnum.IS_FIXED_MATERIALS]: "Materials",
    [BulkAddItemsModel.FieldEnum.IS_FIXED_INITIAL_QUANTITY]: "Initial quantity",
    [BulkAddItemsModel.FieldEnum.IS_FIXED_UNIT_OF_MEASUREMENT]: "Unit of measurement",
    [BulkAddItemsModel.FieldEnum.IS_FIXED_CREATED_AT_LOCATION]: "Created at location",
    [BulkAddItemsModel.FieldEnum.IS_FIXED_CREATION_DATE]: "Creation date",
    [BulkAddItemsModel.FieldEnum.IS_FIXED_CURRENT_LOCATION]: "Current location",
  };

  export interface IRowData {
    formGroup: FormGroup<BulkAddItemsModel.ItemFormGroup>;
  }

  export const duplicatedIdOnTableMessage: string = "This ID is duplicated";
  export const maxItemsCount: number = 100;
  export const editDialogMaxWidth: number = 350;
  export const editDialogHeight: number = 400;
  export const editDialogViewportMargin: number = 10;
}
