import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, Inject, OnInit, signal } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { LocationTypesService } from "src/app/shared/services";
import { CustomValidators } from "src/app/shared/validators";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { CommonConstants } from "@shared/constants";
import { ILocationType } from "@shared/interfaces";
import { FormUtils } from "@shared/utils";

@Component({
  templateUrl: "./edit-location-type-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditLocationTypeDialogComponent implements OnInit {
  public formGroup: UntypedFormGroup = null;

  public isLoading = signal(false);

  public isEditing = signal(false);

  public readonly pointOfOriginIconTooltip = CommonConstants.POINT_OF_ORIGIN_ADMIN_TEXT;

  constructor(
    public dialogRef: MatDialogRef<EditLocationTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { locationType?: any; isForceAdd?: boolean },
    private locationTypesService: LocationTypesService,
    private notificationService: NotificationService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.isEditing.set(!!this.data?.locationType?.id && !this.data.isForceAdd);

    const entityExistsValidatorArgs: any = {
      searchPropertyName: "type",
      searchPropertyErrorDisplayName: "name",
    };

    this.formGroup = new UntypedFormGroup({
      type: new UntypedFormControl(
        this.data?.locationType?.type,
        [CustomValidators.required],
        [
          CustomValidators.entityAlreadyExists(
            this.locationTypesService,
            this.data?.locationType?.id ?? null,
            entityExistsValidatorArgs,
          ),
        ],
      ),
      pointOfOrigin: new UntypedFormControl(this.data?.locationType?.pointOfOrigin ?? false),
    });
  }

  public isSubmitButtonDisabled = (): boolean => {
    if (!this.isEditing()) {
      return false;
    }

    return this.formGroup.invalid || this.formGroup.pending;
  };

  public onSubmit = async (): Promise<void> => {
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);
      this.notificationService.showError(CommonConstants.FILL_REQUIRED_FIELDS_MSG);

      return;
    }
    this.isLoading.set(true);

    const payload: any = {
      type: this.formGroup.controls["type"].value.trim(),
      pointOfOrigin: this.formGroup.controls["pointOfOrigin"].value,
    };

    await this.locationTypesService
      .createOrUpdate(payload, this.isEditing() ? this.data?.locationType?.id : undefined)
      .then((newType: ILocationType) => {
        this.notificationService.showSuccess(
          `Location type ${this.isEditing() ? "modified" : "created"}`,
        );
        this.onClose(true, newType);
      })
      .catch((error: HttpErrorResponse) => {
        this.notificationService.showError(error);
      })
      .finally(() => {
        this.isLoading.set(false);
      });
  };

  public onClose = (hasSaved = false, newType?: ILocationType): void => {
    this.dialogRef.close({ hasSaved, newType });
  };
}
