export enum OverlayTabEnum {
  DETAILS = "Details",
  DOCUMENTS = "Documents",
  CERTIFICATES = "Certificates",
  ATTACHED_TO = "Attached to",
  SUPPLY_CHAIN = "Supply chain",
  PROCESSES = "Processes",
  DELIVERIES = "Deliveries",
  LOCATIONS = "Locations",
  ITEMS = "Items",
  SHARES = "Shares",
  COMMENTS = "Comments",
  GPS_DATA = "GPS data",
  ANALYSES = "Analyses",
  LINKED_LOCATIONS = "Linked locations",
  INPUTS = "Inputs",
  OUTPUTS = "Outputs",
  RELATED_PRODUCTS = "Related products",
  INDICATORS = "Indicators",
}
