import { Inject, Injectable, inject } from "@angular/core";

import { Observable, firstValueFrom } from "rxjs";

import { APP_CONFIG } from "@shared/tokens";

import { ApiService } from "./api.service";
import { GraphService } from "./graph.service";
import { CommonConstants } from "../../constants";
import { RecordStateEnum } from "../../enums";
import {
  IPageableContent,
  IRecordState,
  ISupplyChain,
  ISupplyChainGraphDetail,
  ISupplyChainPayload,
  IConfig,
} from "../../interfaces";
import { FormUtils } from "../../utils";
import { AuthenticationService } from "../authentication.service";

@Injectable({
  providedIn: "root",
})
export class SupplyChainsService {
  graphService = inject(GraphService);

  private getBaseUrl = (): string =>
    `${this.environment.baseUrl}organisations/${this.authenticationService.getActiveOrganisationId()}/supply-chains`;

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {}

  public getPage = async (
    name: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
    recordState: RecordStateEnum = undefined,
  ): Promise<IPageableContent<ISupplyChain>> => {
    const url = `${this.getBaseUrl()}?${FormUtils.addUrlParams({ name, size, page, sort, recordState })}`;

    return await this.apiService.get<IPageableContent<ISupplyChain>>(url).toPromise();
  };

  public getPageSubscription = (
    name: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
    recordState: RecordStateEnum = undefined,
  ): Observable<IPageableContent<ISupplyChain>> => {
    const url = `${this.getBaseUrl()}?${FormUtils.addUrlParams({ name, size, page, sort, recordState })}`;

    return this.apiService.get<IPageableContent<ISupplyChain>>(url);
  };

  public async getAll(recordState: RecordStateEnum = undefined): Promise<ISupplyChain[]> {
    const result: ISupplyChain[] = [];
    let page = 0;
    let hasNextPage: boolean = true;

    while (hasNextPage) {
      const {
        content,
        page: { totalElements },
      } = await this.getPage(
        undefined,
        CommonConstants.MAX_API_GET_ITEMS_SIZE,
        page,
        undefined,
        recordState,
      );

      result.push(...content);

      page++;
      hasNextPage = result.length < totalElements;
    }

    return result;
  }

  public get = async (id: string): Promise<ISupplyChain> =>
    await this.apiService.get<ISupplyChain>(`${this.getBaseUrl()}/${id}`).toPromise();

  public delete = async (id: string): Promise<void> =>
    await this.apiService.delete<void>(`${this.getBaseUrl()}/${id}`).toPromise();

  public createOrUpdate = async (
    payload: ISupplyChainPayload,
    id?: string,
  ): Promise<ISupplyChain> => {
    if (id) {
      return await this.apiService
        .put<ISupplyChain>(`${this.getBaseUrl()}/${id}`, payload)
        .toPromise();
    } else {
      return await this.apiService.post<ISupplyChain>(`${this.getBaseUrl()}`, payload).toPromise();
    }
  };

  public setRecordState = async (payload: IRecordState, id: string): Promise<ISupplyChain> =>
    await this.apiService.put<ISupplyChain>(`${this.getBaseUrl()}/${id}`, payload).toPromise();

  public async querySupplyChain(query: string): Promise<ISupplyChainGraphDetail> {
    return await firstValueFrom(this.graphService.query({ query }));
  }
}
