<h1 title="">
  @if (isBackLinkEnabled) {
    <div (click)="onBack()" class="back-link clickable underline">
      <mat-icon svgIcon="arrow_back" class="back-icon"></mat-icon>
      {{ getBackText() }}
    </div>
  }

  <div class="title-container">
    @if (icon) {
      <mat-icon class="title-icon">{{ icon }}</mat-icon>
    }
    <span class="title">{{ title }}</span>
    @if (hint) {
      <mat-icon class="info-icon" svgIcon="help" [tp]="hint" tpPlacement="right"></mat-icon>
    }

    @if (tag) {
      <iov-tag-group class="ml-12 no-padding" [tags]="[tag]"></iov-tag-group>
    }
  </div>
</h1>
