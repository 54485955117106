import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { APP_CONFIG } from "@shared/tokens";

import { ApiService } from "./api.service";
import { CommonConstants } from "../../constants";
import {
  IConfig,
  IEmail,
  IOrganisationRegPayload,
  IPassword,
  ISharedTokenResponse,
  ITokenResponse,
  IUserPayload,
} from "../../interfaces";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private readonly baseUrl: string = `${this.environment.baseUrl}`;

  constructor(
    private apiService: ApiService,
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {}

  public login = async (username: string, password: string): Promise<ITokenResponse> => {
    const url = `${this.baseUrl}auth/token`;

    const payload = new URLSearchParams();

    payload.set("username", username);
    payload.set("password", password);
    payload.set("grant_type", "password");
    payload.set("client_id", CommonConstants.TOKEN_CLIENT_ID);

    const headers: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });

    return this.httpClient.post<ITokenResponse>(url, payload, { headers }).toPromise();
  };

  public shareLogin = async (shareId: string, code: string): Promise<ISharedTokenResponse> => {
    const url = `${this.baseUrl}auth/sharing/token`;

    const payload = new URLSearchParams();

    payload.set("share_id", shareId);
    payload.set("code", code);
    payload.set("grant_type", "password");
    payload.set("client_id", CommonConstants.TOKEN_CLIENT_ID);

    const headers: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });

    return this.httpClient.post<ISharedTokenResponse>(url, payload, { headers }).toPromise();
  };

  public registerOrganisation = async (payload: IOrganisationRegPayload): Promise<any> =>
    await this.apiService.post<any>(`${this.baseUrl}registrations`, payload).toPromise();

  public registerUser = async (invitationId: string, payload: IUserPayload): Promise<void> =>
    await this.apiService
      .post<void>(`${this.baseUrl}registrations?invitationId=${invitationId}`, payload)
      .toPromise();

  public resendRegisterOrganisationEmail = async (registrationId: string): Promise<void> =>
    await this.apiService
      .post<void>(`${this.baseUrl}registrations/${registrationId}/resend`)
      .toPromise();

  public verifyOrganisation = async (
    registrationId: string,
    registrationCode: string,
  ): Promise<void> =>
    await this.apiService
      .put<void>(`${this.baseUrl}registrations/${registrationId}/verify?t=${registrationCode}`)
      .toPromise();

  public resetPassword = async (payload: IPassword, token: string): Promise<void> =>
    await this.apiService
      .post<void>(`${this.baseUrl}auth/reset-password?t=${token}`, payload)
      .toPromise();

  public forgotPassword = async (payload: IEmail): Promise<void> =>
    await this.apiService.post<void>(`${this.baseUrl}auth/forgot-password`, payload).toPromise();

  public refreshToken = (refreshToken: string): Observable<ITokenResponse> => {
    const url = `${this.baseUrl}auth/token/refresh`;

    const payload = new URLSearchParams();

    payload.set("refresh_token", refreshToken);
    payload.set("grant_type", "refresh_token");
    payload.set("client_id", CommonConstants.TOKEN_CLIENT_ID);

    const headers: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });

    return this.httpClient.post<ITokenResponse>(url, payload, { headers });
  };
}
