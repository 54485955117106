/**
 * Enum representing the current state of a process.
 */
export enum ProcessStateEnum {
  /**
   * Allows users to add both inputs and outputs.
   * @remarks This state allows recording both inputs and outputs during the process.
   */
  INPUT_OUTPUT = "INPUT_OUTPUT",

  /**
   * Disables adding inputs, only allows adding outputs.
   * @remarks In this state, only outputs can be recorded, and inputs are disabled.
   */
  OUTPUT_ONLY = "OUTPUT_ONLY",

  /**
   * Disables recording of both new inputs and outputs.
   * @remarks Once in this state, neither inputs nor outputs can be added.
   */
  CLOSED = "CLOSED",
}
