import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  input,
  Input,
  OnChanges,
  Output,
  signal,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef } from "ag-grid-community";
import { ConfirmDialogComponent } from "src/app/components/shared";
import { ConfirmDialogResponseEnum, TableEnum } from "src/app/shared/enums";
import {
  IBaseUnit,
  IItem,
  IItemDetails,
  IMaterial,
  IProductExtended,
} from "src/app/shared/interfaces";
import { CommonUtils } from "src/app/shared/utils";

import { CardContentTypeEnum } from "@design-makeover/components/cards/card-content/card-content.model";
import { SlideOverlayPageService } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.service";
import { UnitConversionPipe } from "@design-makeover/pipes/unit-conversion.pipe";
import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { AddItemDialogComponent } from "@components/deliveries/components/add-item-dialog/add-item-dialog.component";
import { DeliveryOverlayService } from "@components/deliveries/pages/delivery-overlay/delivery-overlay.service";
import { EditItemQuantityDialogComponent } from "@components/index";
import { LinkCellRendererComponent, QuickActionsMenuComponent } from "@shared/cell-renderers";
import { AuthenticationService } from "@shared/services";
import { RouterService } from "@shared/services/router.service";

export interface IItemQuantityDialogPayload {
  id: string;
  deliveredQuantity: number;
}

@Component({
  selector: "app-slide-overlay-delivery-items",
  templateUrl: "./slide-overlay-delivery-items.component.html",
  styleUrls: ["./slide-overlay-delivery-items.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SlideOverlayDeliveryItemsComponent implements OnChanges, AfterViewInit {
  @ViewChild("attachmentsRightMenu") attachmentsRightMenu: TemplateRef<unknown>;

  @Input() public availableItems: IItemDetails[] = [];

  @Input() public selectedItems: IItem[] = [];

  @Input() public allUnitOfMeasurements: IBaseUnit[] = [];

  @Input() public allMaterials: IMaterial[] = [];

  public isLoadingItems = input<boolean>(false);

  @Input() public isReadOnly: boolean = false;

  @Output() public reloadItems: EventEmitter<string> = new EventEmitter();

  @Output() public addNewItem: EventEmitter<IItem> = new EventEmitter();

  @Output() public setSelectedItems: EventEmitter<IItem[]> = new EventEmitter();

  @Output() public updateDeliveredQuantity: EventEmitter<IItemQuantityDialogPayload> =
    new EventEmitter();

  @Output() public openBulkAddItemsSlideOver: EventEmitter<void> = new EventEmitter();

  public readonly contentCardTypeEnum = CardContentTypeEnum;

  isSelectedAttachmentsLoading = signal<boolean>(true);

  public searchAvailableText: string;

  public itemsColumnDefs = signal<ColDef[]>([]);

  public itemRowData: IItemDetails[] = [];

  protected readonly tableType = TableEnum.QUANTITY_SUMMARY;

  private readonly canAddModifyEntities = this.autheticationService.canAddModifyEntities();

  constructor(
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private router: RouterService,
    private unitConversionPipe: UnitConversionPipe,
    private deliveryOverlayService: DeliveryOverlayService,
    private autheticationService: AuthenticationService,
    public overlay: SlideOverlayPageService,
  ) {}

  public async ngAfterViewInit(): Promise<void> {
    this.setColumnDefs();
    await this.setRowsData(this.selectedItems);
  }

  public get allProducts(): IProductExtended[] {
    return this.deliveryOverlayService.allProducts;
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes["selectedItems"]) {
      await this.setRowsData(this.selectedItems);
    }
  }

  public onSearchAvailable = (value: string): void => {
    this.searchAvailableText = value;
    this.reloadItems.emit(this.searchAvailableText);
  };

  public onAddAll = (): void => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: "Add all confirmation",
        contentTranslatedText: `Are you sure you want to add all ${this.availableItems.length} items?`,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        const selectedItems = [...this.selectedItems];

        for (let i = 0; i < this.availableItems.length; i++) {
          selectedItems.push(this.onAdd(this.availableItems[i].id, false));
        }

        this.setSelectedItems.emit([...selectedItems]);
        this.notificationService.showSuccess("Items added");
      }
    });
  };

  public onAdd = (id: string, isSingleAdd = true): IItemDetails => {
    if (this.isReadOnly) {
      return null;
    }

    const item = this.availableItems.find((i) => i.id === id);
    const newItem: IItemDetails = {
      ...item,
      deliveredQuantity: item.deliveredQuantity ?? item.initialQuantity,
    };
    const selectedItems = [...this.selectedItems, newItem];

    if (isSingleAdd) {
      this.setSelectedItems.emit([...selectedItems]);
      this.notificationService.showSuccess("Item added");
    }

    return newItem;
  };

  public onRemove = (id: string): void => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: "Remove confirmation",
        contentTranslatedText: "Are you sure you want to remove this item from the delivery?",
        confirmButtonColor: "danger",
        confirmButtonTranslatedText: "Remove",
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        const selectedItems = this.selectedItems.filter((i) => i.id !== id);

        this.setSelectedItems.emit([...selectedItems]);
        this.notificationService.showSuccess("Item removed");
      }
    });
  };

  public onDuplicate(item: IItemDetails): void {
    this.onAddNewItem(item);
  }

  public async onAddNewItem(item?: IItemDetails): Promise<void> {
    const result = await this.overlay.openDialog<
      { hasSaved: boolean; item: IItem },
      { dialogTitle: string; originalItem: IItemDetails }
    >(AddItemDialogComponent, {
      dialogTitle: "Add item to delivery",
      originalItem: item,
    });

    if (result?.hasSaved) {
      await this.deliveryOverlayService.onReloadProducts();
      this.addNewItem.emit(result.item);
    }
  }

  public onEditItem = (item: IItemDetails): void => {
    const dialogRef = this.dialog.open(EditItemQuantityDialogComponent, {
      autoFocus: false,
      data: { item, allProducts: this.allProducts },
    });

    dialogRef
      .afterClosed()
      .subscribe(async (result: { hasSaved: boolean; data: IItemQuantityDialogPayload }) => {
        const currentQuantity = String(result.data.deliveredQuantity);
        let convertedValue = currentQuantity;
        const product = this.allProducts.find((p) => p.id === item.productId);
        const defaultCustomUnit = product?.defaultCustomUnit;

        if (defaultCustomUnit) {
          item.productDefaultUnit = defaultCustomUnit;
          convertedValue = this.unitConversionPipe.transform(
            currentQuantity,
            item.productDefaultUnit,
            item.unitOfMeasurement,
            true,
          );
        }

        result.data.deliveredQuantity = +convertedValue;

        if (result?.hasSaved) {
          this.updateDeliveredQuantity.emit(result.data);
        }
      });
  };

  private async setRowsData(items: IItem[]): Promise<void> {
    this.itemRowData = await this.getParsedItemData(items);
    this.isSelectedAttachmentsLoading.set(false);
  }

  private async getParsedItemData(selectedItems: IItem[]): Promise<IItemDetails[]> {
    if (!selectedItems) {
      return [];
    }

    const items = CommonUtils.getItemsWithProductAndAssignedUnit(selectedItems, this.allProducts);

    const newItems: IItem[] = [];

    for (const item of items) {
      const itemProductId = CommonUtils.getUriId(item.product);

      const product = this.allProducts.find((p) => p.id === itemProductId);
      const productDefaultUnit = product?.defaultCustomUnit;
      const baseUnit = product?.unitOfMeasurement;

      let newItem = { ...item, productDefaultUnit: null, valueWithDefaultUnit: null };

      if (productDefaultUnit) {
        const valueWithDefaultUnit = CommonUtils.formatQuantityWithDefaultUnit(
          item.deliveredQuantity ?? item.selectedQuantity ?? item.remainingQuantity,
          productDefaultUnit,
          baseUnit,
        );

        newItem = {
          ...newItem,
          productDefaultUnit,
          valueWithDefaultUnit,
          deliveredQuantity: item.deliveredQuantity,
          remainingQuantity: item.remainingQuantity,
        };
      }

      newItems.push(newItem);
    }

    return newItems;
  }

  private setColumnDefs(): void {
    this.itemsColumnDefs.set([
      {
        headerName: "ID",
        field: "itemId",
        lockVisible: true,
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: {
          linkRouteFn: this.router.getItemLink,
          linkRouteIdParam: "id",
          actions: [
            {
              svgIcon: "copy",
              tooltip: "Duplicate",
              show: () => !this.isReadOnly && this.canAddModifyEntities,
              click: (data: IItemDetails) => this.onDuplicate(data),
            },
            {
              icon: "close",
              tooltip: "Remove",
              show: () => !this.isReadOnly && this.canAddModifyEntities,
              click: (data: IItemDetails) => this.onRemove(data.id),
            },
          ],
        },
      },
      {
        headerName: "Product",
        field: "productName",
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteFn: this.router.getProductLink,
          linkRouteIdParam: "productId",
        },
      },
      {
        headerName: "Delivery q-ty",
        field: "deliveredQuantity",
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: {
          hasUnitOfMeasurement: true,
          precisionParam: "unitOfMeasurement.precision",
          symbolParam: "unitOfMeasurement.symbol",
          formattedQuantityParam: "valueWithDefaultUnit",
          actions: [
            {
              icon: "edit",
              tooltip: "Edit",
              show: () => !this.isReadOnly && this.canAddModifyEntities,
              click: (data: IItemDetails) => this.onEditItem(data),
            },
          ],
        },
      },
    ]);
  }

  public onOpenBulkAddItemsSlideOver(): void {
    this.openBulkAddItemsSlideOver.emit();
  }
}
