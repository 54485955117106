import { Injectable, Inject } from "@angular/core";

import { lastValueFrom, Observable } from "rxjs";

import { APP_CONFIG } from "@shared/tokens";

import { ApiService } from "./api.service";
import { IAvailableExtension, IConfig, IEnableExtensionPayload } from "../../interfaces";

@Injectable({
  providedIn: "root",
})
export class ExtensionsService {
  constructor(
    private apiService: ApiService,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {}

  public getAllAvailableForSystemAdmin = async (): Promise<IAvailableExtension[]> =>
    await lastValueFrom(
      this.apiService.get<IAvailableExtension[]>(`${this.environment.baseUrl}admin/extensions`),
    );

  public getAllAvailableForRegularUser = async (
    organisationId: string,
  ): Promise<IAvailableExtension[]> =>
    await lastValueFrom(
      this.apiService.get<IAvailableExtension[]>(
        `${this.environment.baseUrl}organisations/${organisationId}/extensions`,
      ),
    );

  public getAllEnabled = async (organisationId: string): Promise<string[]> =>
    await lastValueFrom(
      this.apiService.get<string[]>(
        `${this.environment.baseUrl}organisations/${organisationId}/extensions/enabled`,
      ),
    );

  public getAllEnabledObservable = (organisationId: string): Observable<string[]> =>
    this.apiService.get<string[]>(
      `${this.environment.baseUrl}organisations/${organisationId}/extensions/enabled`,
    );

  public enableExtension = async (payload: IEnableExtensionPayload): Promise<void> =>
    await lastValueFrom(
      this.apiService.post<void>(`${this.environment.baseUrl}admin/extensions/enable`, payload),
    );
}
