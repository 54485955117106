import { ChangeDetectionStrategy, Component, signal } from "@angular/core";

import { RecordStateEnum } from "src/app/shared/enums";
import { IProcessType } from "src/app/shared/interfaces";
import { AuthenticationService, ProcessTypesService } from "src/app/shared/services";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { RouterService } from "@shared/services/router.service";

@Component({
  templateUrl: "./processes.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcessesComponent {
  public readonly recordStateEnum = RecordStateEnum;

  public readonly canAddModifyEntities = this.authenticationService.canAddModifyEntities();

  public isLoadingProcessTypes = signal(true);

  public allProcessTypes: IProcessType[] = [];

  constructor(
    private routerService: RouterService,
    public authenticationService: AuthenticationService,
    private processTypesService: ProcessTypesService,
    private notificationService: NotificationService,
  ) {
    this.onReloadProcessTypes();
  }

  public async onAdd(): Promise<void> {
    await this.routerService.navigate(this.routerService.getProcessLink());
  }

  private onReloadProcessTypes = async (): Promise<void> => {
    this.isLoadingProcessTypes.set(true);
    try {
      this.allProcessTypes = await this.processTypesService.getAll();
      this.isLoadingProcessTypes.set(false);
    } catch (error) {
      this.notificationService.showError(error);
    }
  };
}
