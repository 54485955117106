import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { lastValueFrom } from "rxjs";
import { ConfirmDialogComponent } from "src/app/components";

@Injectable({
  providedIn: "root",
})
export class CanDeactiveDialogService {
  constructor(private dialog: MatDialog) {}

  async showDialog(isSubmitButtonDisabled: boolean) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: "Exit confirmation",
        contentTranslatedText:
          "You're about to leave this page. Would you like to save the changes you have made before leaving it?",
        confirmButtonTranslatedText: "Save & leave",
        confirmButtonIcon: "check",
        cancelButtonTranslatedText: "Cancel",
        thirdActionButtonColor: "danger",
        thirdActionButtonText: "Discard changes",
        isSubmitButtonDisabled,
      },
    });

    return await lastValueFrom(dialogRef.afterClosed());
  }

  async showUploadConfirmationDialog(dialogText?: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: "Exit confirmation",
        confirmButtonColor: "danger",
        contentTranslatedText:
          dialogText ?? "Upload in progress. Are you sure that you want to exit?",
      },
    });

    return await lastValueFrom(dialogRef.afterClosed());
  }

  async showOverlayMenuDialog(submitDisabled: boolean) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: "Change menu confirmation",
        contentTranslatedText:
          "You're about to leave this section. Would you like to save the changes you have made before leaving it?",
        confirmButtonTranslatedText: "Save & leave",
        confirmButtonIcon: "check",
        cancelButtonTranslatedText: "Cancel",
        thirdActionButtonColor: "danger",
        thirdActionButtonText: "Discard changes",
        isSubmitButtonDisabled: submitDisabled,
      },
    });

    return await lastValueFrom(dialogRef.afterClosed());
  }
}
