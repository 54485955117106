@if (isSelectedAttachmentsLoading()) {
  <app-loader></app-loader>
} @else {
  <div class="section-title">Product summary</div>

  @if (selectedItems?.length) {
    <app-quantity-summary-table
      [isSearchEnabled]="false"
      [isPaginatorEnabled]="false"
      [allProducts]="allProducts"
      [allMaterials]="allMaterials"
      [allUnitOfMeasurements]="allUnitOfMeasurements"
      [selectedItems]="selectedItems"
    ></app-quantity-summary-table>
  } @else {
    <div class="text-center empty-quantity-summary">
      Once you add items to this delivery, a summary of their quantities will show up here.
    </div>
  }

  <div class="section-title mt-24">Selected items</div>

  <section class="items-table">
    <app-table
      [columnDefs]="itemsColumnDefs()"
      [isLoading]="isSelectedAttachmentsLoading()"
      [isRecordStateFilterEnabled]="false"
      [rowData]="itemRowData"
      [isPaginatorEnabled]="!overlay.contentRightSidePanel()"
    ></app-table>
  </section>

  <ng-template #attachmentsRightMenu>
    <iov-right-side-panel
      itemType="Item"
      itemTypePlural="Items"
      [isLoadingItems]="isLoadingItems()"
      [availableItems]="availableItems"
      [addMenu]="menu"
      (pressAdd)="onAddNewItem()"
      (pressAddAll)="onAddAll()"
      class="overlay-right-panel"
      (pressRefresh)="reloadItems.emit(searchAvailableText)"
      (searchEvent)="onSearchAvailable($event)"
    >
      <div class="items-container">
        @for (item of availableItems; track item.id) {
          <iov-draggable-card (pressed)="onAdd(item.id)" [item]="item">
            <iov-item-card-content [item]="item"></iov-item-card-content>
          </iov-draggable-card>
        }
      </div>
    </iov-right-side-panel>
  </ng-template>
}

<mat-menu #menu class="dropdown-menu">
  <iov-dropdown-menu>
    <iov-dropdown-menu-item (pressed)="onAddNewItem()">
      <mat-icon svgIcon="items" />
      Add one item
    </iov-dropdown-menu-item>
    <iov-dropdown-menu-item (pressed)="onOpenBulkAddItemsSlideOver()">
      <mat-icon>library_add</mat-icon>
      Add multiple items
    </iov-dropdown-menu-item>
  </iov-dropdown-menu>
</mat-menu>
