<h2 mat-dialog-title>Select language</h2>

<mat-dialog-content>
  <div class="languages-container" cdkFocusRegionstart>
    <iov-button
      *ngFor="let language of availableLanguages"
      [type]="currentLanguage === language.code ? 'filled' : 'ghost'"
      (pressed)="onSelect(language.code)"
    >
      <app-flag [country]="language.flag"></app-flag>
      {{ language.name }}
    </iov-button>
  </div>
</mat-dialog-content>
