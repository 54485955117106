export enum TargetPathEnum {
  /**
   * In the condition, the country(ies) code(s) of the location(s)
   */
  COUNTRY = "address.country",

  /**
   * In the condition, identifies the URIs of location types
   */
  TYPES = "types",

  /**
   * Used in expectation
   */
  DOCUMENT = "documents",

  /**
   * Used in condition, identifies the URIs of document types
   */
  TYPE = "type",

  /**
   *  For delivery rulesets, this represents the location types for the location FROM parameters.
   */
  FROM_TYPES = "fromLocation.types",

  /**
   *For delivery rulesets, this represents the location types for the location TO parameters.
   */
  TO_TYPES = "toLocation.types",

  /**
   * For delivery rulesets, this represents the countries for the FROM parameters.
   */
  FROM_COUNTRIES = "fromLocation.address.country",

  /**
   * For delivery rulesets, this represents the countries for the TO parameters.
   */
  TO_COUNTRIES = "toLocation.address.country",

  /**
   * For delivery rulesets, this represents path for the exact FROM location
   */
  FROM_LOCATION = "fromLocation",

  /**
   * For delivery rulesets, this represents path for the exact TO location
   */
  TO_LOCATION = "toLocation",
}
