<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <h2 mat-dialog-title>{{ isEditing() ? "Edit" : "Add" }} standard</h2>
  @if (isLoading()) {
    <app-loader containerClass="on-dialog"></app-loader>
  } @else {
    <mat-dialog-content>
      <iov-input
        label="Name"
        formControlName="name"
        [autofocus]="!isLoading() && !isEditing()"
      ></iov-input>
      <div class="type-title">
        <h3>Standard types</h3>
        <button
          type="button"
          color="primary"
          mat-icon-button
          tp="Add new standard type"
          (click)="onAddStandardType()"
        >
          <mat-icon>add_circle</mat-icon>
        </button>
      </div>
      @for (controlName of formGroup.controls | keyvalue; track controlName.key) {
        @if (controlName.key.startsWith("new-type-")) {
          <div class="new-controls">
            <iov-input
              placeholder="New standard type name"
              class="full-width"
              [formControlName]="controlName.key"
            ></iov-input>
            <iov-button
              class="no-border"
              type="ghost"
              tp="Remove"
              (pressed)="removeFormControl(controlName.key)"
            >
              <mat-icon>delete</mat-icon>
            </iov-button>
          </div>
        }
      }
      @for (controlName of formGroup.controls | keyvalue; track controlName.key) {
        @if (controlName.key !== "name" && !controlName.key.startsWith("new-type-")) {
          <div class="new-controls">
            <iov-input
              placeholder="New standard type name"
              class="full-width"
              [formGroup]="formGroup"
              [formControlName]="controlName.key"
            ></iov-input>
            <iov-button
              type="ghost"
              class="no-border"
              (pressed)="toggleArchiveStatus(controlName.key)"
              [tp]="
                (controlName.key | getTypeRecordState: data?.standard?.standardTypes) ===
                recordStateEnum.ACTIVE
                  ? 'Archive'
                  : 'Unarchive'
              "
            >
              <mat-icon>
                {{
                  (controlName.key | getTypeRecordState: data?.standard?.standardTypes) ===
                  recordStateEnum.ACTIVE
                    ? "archive"
                    : "inventory"
                }}
              </mat-icon>
            </iov-button>
          </div>
        }
      }
    </mat-dialog-content>

    <mat-dialog-actions>
      <iov-button-group>
        <iov-button (pressed)="onClose()" type="ghost">Cancel</iov-button>
        <iov-button
          [disabled]="isSubmitButtonDisabled()"
          accessibilityRole="submit"
          cdkFocusInitial
        >
          <mat-icon icon-left>check</mat-icon>
          Save
        </iov-button>
      </iov-button-group>
    </mat-dialog-actions>
  }
</form>
