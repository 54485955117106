import { HttpEvent, HttpEventType } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { ActiveToast, ToastrService } from "ngx-toastr";
import { tap, catchError, EMPTY, Subject, debounceTime, BehaviorSubject } from "rxjs";

import { NotificationDownloadComponent } from "@design-makeover/components/notification";
import { SlideOverlayPageService } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.service";
import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { CommonConstants } from "@shared/constants";
import { IDocumentToDownload } from "@shared/interfaces";
import { FileUtils } from "@shared/utils";

import { DocumentsService } from "./api";
import { AuthenticationService } from "./authentication.service";

@Injectable({
  providedIn: "root",
})
export class DownloadDocumentsService {
  private documentsToDownload: IDocumentToDownload[] = [];

  private documentsSubject$ = new BehaviorSubject<IDocumentToDownload[]>([]);

  private toast: ActiveToast<any> = undefined;

  private closeToastSubject = new Subject<boolean>();

  constructor(
    private documentsService: DocumentsService,
    private toastService: ToastrService,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private overlay: SlideOverlayPageService,
  ) {
    this.closeToastSubject
      .pipe(debounceTime(CommonConstants.NOTIFICATION_DOWNLOADS_DURATION_MS))
      .subscribe((isClosing: boolean) => {
        if (isClosing) {
          this.closeToast();
        }
      });
  }

  public isDownloadingDocuments = (): boolean =>
    this.documentsToDownload.some((d) => !d.hasError && d.progress !== 100);

  public isDocumentBeingDownloaded = (documentId: string): boolean =>
    !!this.documentsToDownload.find(
      (d) => d.documentId === documentId && !d.hasError && d.progress !== 100,
    );

  public cancelDownload = (
    documentToDownload: IDocumentToDownload,
    isSingleCancel: boolean = true,
  ): void => {
    if (
      !documentToDownload.hasError &&
      documentToDownload.progress !== 100 &&
      documentToDownload.downloadSubscription &&
      !documentToDownload.downloadSubscription.closed
    ) {
      documentToDownload.downloadSubscription.unsubscribe();

      if (isSingleCancel) {
        const documentsToDownloadIndex = this.documentsToDownload.findIndex(
          (d) => d.documentId === documentToDownload.documentId,
        );

        if (documentsToDownloadIndex !== -1) {
          this.updateDocuments([
            ...this.documentsToDownload.slice(0, documentsToDownloadIndex),
            ...this.documentsToDownload.slice(documentsToDownloadIndex + 1),
          ]);
        }

        if (!this.documentsToDownload.length) {
          this.closeToast();
        } else if (!this.isDownloadingDocuments()) {
          this.closeToastSubject.next(true);
        }
      }
    }
  };

  public cancelAllDownloadsAndCloseToast = (): void => {
    for (const documentToDownload of this.documentsToDownload) {
      this.cancelDownload(documentToDownload, false);
    }
    this.closeToast();
    this.updateDocuments([]);
  };

  public add = (documentToDownload: IDocumentToDownload, urlPath?: string): void => {
    if (this.authenticationService.haveTokensExpired()) {
      this.notificationService.showError(CommonConstants.SESSION_HAS_EXPIRED_TEXT);
      this.overlay.close(true);
      this.authenticationService.logout();

      return;
    }
    const existingDocumentToDownload = this.documentsToDownload.find(
      (d) => d.documentId === documentToDownload.documentId,
    );

    if (existingDocumentToDownload) {
      if (existingDocumentToDownload.hasError) {
        existingDocumentToDownload.progress = 0;
        existingDocumentToDownload.hasError = false;
        this.showOrUpdateToast(existingDocumentToDownload, urlPath);
      }
    } else {
      documentToDownload.progress = 0;
      this.updateDocuments([...this.documentsToDownload, documentToDownload]);
      this.showOrUpdateToast(documentToDownload, urlPath);
    }
  };

  private showOrUpdateToast(newDocumentToDownload: IDocumentToDownload, urlPath?: string): void {
    this.closeToastSubject.next(false);
    if (this.toast?.toastRef) {
      this.documentsSubject$.next(this.documentsToDownload);
      this.startDownload(newDocumentToDownload, urlPath);
    } else {
      this.toast = this.toastService.info(undefined, undefined, {
        toastComponent: NotificationDownloadComponent,
        disableTimeOut: true,
        tapToDismiss: false,
        onActivateTick: true,
        payload: this.documentsSubject$.asObservable(),
      });
      this.toast.toastRef.afterActivate().subscribe(() => {
        this.startDownload(newDocumentToDownload, urlPath);
      });
    }
  }

  private startDownload = (documentToDownload: IDocumentToDownload, urlPath?: string): void => {
    documentToDownload.downloadSubscription = this.documentsService
      .downloadContentWithProgress(documentToDownload.documentId, urlPath)
      .pipe(
        tap((event: HttpEvent<HttpEventType.DownloadProgress | HttpEventType.Response>) => {
          switch (event.type) {
            case HttpEventType.DownloadProgress: {
              const progress = Math.round((event.loaded / event.total) * 100);

              documentToDownload.progress = progress >= 100 ? 99 : progress;
              this.updateDocuments(this.documentsToDownload);
              break;
            }
            case HttpEventType.Response: {
              documentToDownload.progress = 100;
              FileUtils.downloadFileBlob(event.body as any, documentToDownload.documentName);
              this.updateDocuments(this.documentsToDownload);
              this.onDownloadFinished();
              break;
            }
          }
        }),
        catchError((error) => {
          console.error(error);
          documentToDownload.hasError = true;

          this.updateDocuments(this.documentsToDownload);
          this.onDownloadFinished();

          return EMPTY;
        }),
      )
      .subscribe();
  };

  private onDownloadFinished = (): void => {
    if (!this.isDownloadingDocuments()) {
      this.closeToastSubject.next(true);
    }
  };

  private closeToast = (): void => {
    if (this.toast?.toastRef) {
      this.closeToastSubject.next(false);
      this.toastService.remove(this.toast.toastId);
      this.toast = undefined;
      this.updateDocuments(
        this.documentsToDownload.filter((d) => !d.hasError && d.progress !== 100),
      );
    }
  };

  private updateDocuments(documents: IDocumentToDownload[]): void {
    this.documentsToDownload = documents;
    this.documentsSubject$.next(this.documentsToDownload);
  }
}
