import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  ViewChild,
  effect,
  inject,
  signal,
  viewChild,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormGroup } from "@angular/forms";

import { Subscription, lastValueFrom, map, tap } from "rxjs";

import { SlideOverlayContentComponent } from "@design-makeover/components/overlay/slide-overlay-content/slide-overlay-content.component";
import { SlideOverlayPageClass } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.class";

import { CommonConstants } from "@shared/constants";
import { OverlayTabEnum, RecordStateEnum, RoutingEnum } from "@shared/enums";
import { IRecordState, ISelectOption } from "@shared/interfaces";
import { RiskAssessmentTemplatesApiService } from "@shared/risk-assessment-templates/api";
import { RiskAssessmentTemplateResourceType } from "@shared/risk-assessment-templates/constants";
import {
  RiskAssessmentTemplate,
  RiskAssessmentTemplatePayload,
} from "@shared/risk-assessment-templates/models";
import { RiskLevelSetsApiService } from "@shared/risk-level-sets/api";
import { RiskLevel, RiskLevelSet } from "@shared/risk-level-sets/models";
import { CommonUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

import { CategoryFormGroup, IndicatorFormGroup } from "./models";
import { RiskAssessmentTemplateOverlayIndicatorsFormService } from "./risk-assessment-template-overlay-indicators-form.service";
import { RiskAssessmentTemplateOverlayIndicatorsComponent } from "./risk-assessment-template-overlay-indicators.component";

interface RiskLevelOption extends ISelectOption {
  value: string;
}

@Component({
  selector: "app-risk-assessment-template-overlay",
  templateUrl: "./risk-assessment-template-overlay.component.html",
  styleUrl: "./risk-assessment-template-overlay.component.scss",
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [RiskAssessmentTemplateOverlayIndicatorsFormService],
})
export class RiskAssessmentTemplateOverlayComponent
  extends SlideOverlayPageClass
  implements OnInit
{
  @ViewChild("slideOverlayContent") override slideOverlayContent: SlideOverlayContentComponent;

  private readonly destroyRef = inject(DestroyRef);

  private readonly templatesApi = inject(RiskAssessmentTemplatesApiService);

  private readonly riskLevelSetsApi = inject(RiskLevelSetsApiService);

  private readonly indicatorsFormService = inject(
    RiskAssessmentTemplateOverlayIndicatorsFormService,
  );

  private readonly indicatorFormGroupSubscriptions = new Map<FormGroup, Subscription>();

  readonly indicators = viewChild(RiskAssessmentTemplateOverlayIndicatorsComponent);

  public readonly MAX_CHIPS_TEXT_LENGTH_TO_SHOW = CommonConstants.MAX_CHIPS_TEXT_LENGTH_TO_SHOW;

  readonly form = this.formBuilder.group({
    name: ["", [CustomValidators.required]],
    description: this.formBuilder.control<string>(null, [CustomValidators.maxLength(1000)]),
    indicators: this.formBuilder.array<FormGroup<IndicatorFormGroup | CategoryFormGroup>>([]),
    riskLevelSet: this.formBuilder.control<RiskLevelOption>(null, [CustomValidators.required]),
  });

  readonly formValueChanges$ = this.form.valueChanges.pipe(
    tap(() => this.updateHasFormValuesChanged(this.form, this.form.getRawValue())),
  );

  readonly riskLevelValueChanges$ = this.form.controls.riskLevelSet.valueChanges.pipe(
    tap((riskLevelSet) => this.setRiskLevels(riskLevelSet ? riskLevelSet.value : null)),
  );

  readonly indicatorAdded$ = this.indicatorsFormService.controlAdded$.pipe(
    tap(this.onIndicatorAdded.bind(this)),
  );

  readonly indicatorRemoved$ = this.indicatorsFormService.controlRemoved$.pipe(
    tap(this.onIndicatorRemoved.bind(this)),
  );

  get indicatorsCount() {
    let count = 0;

    this.element?.indicatorGroups?.forEach((group) => (count += group.indicators.length));

    return count;
  }

  get resourceType() {
    return this.route.snapshot.queryParams["type"];
  }

  override get isSubmitButtonDisabled() {
    return false;
  }

  override get requiresConfirmation(): boolean {
    return this.hasFormValuesChanged || this.hasInitialFormValueChanged(this.form.getRawValue());
  }

  override element: RiskAssessmentTemplate;

  override initialFormValue = this.form.getRawValue();

  override menuItems = signal(
    new Map([
      [OverlayTabEnum.DETAILS, { isEnabled: true }],
      [OverlayTabEnum.INDICATORS, { isEnabled: false }],
    ]),
  );

  private readonly riskLevelSets = signal<RiskLevelSet[]>([]);

  readonly riskLevelSetOptions = signal<RiskLevelOption[]>([]);

  readonly elementRiskLevels = signal<RiskLevel[]>([]);

  constructor() {
    super();

    effect(() => this.setRiskLevelSetOptions(this.riskLevelSets()), { allowSignalWrites: true });
  }

  async ngOnInit() {
    this.overlay.showLoading();

    if (!this.isOnCorrectOverlay(RoutingEnum.OVERLAY_RISK_ASSESSMENT_TEMPLATE)) {
      return;
    }

    await this.validateResourceTypeUrlParam();

    await this.fetchRiskLevelSets();

    this.indicatorsFormService.init(this.form.controls.indicators);

    if (this.recordId) {
      await this.reloadElement(this.recordId);
      await this.setMenuItemFromURLParam();
    }

    this.overlay.dismissLoading();
  }

  protected override async archiveRecord(id: string, payload: IRecordState) {
    await lastValueFrom(this.templatesApi.setRecordState(id, payload));
  }

  protected override async deleteRecord(id: string) {
    await lastValueFrom(this.templatesApi.delete(id));
  }

  protected override async reloadElement(id: string) {
    this.overlay.showLoading();

    try {
      this.element = await lastValueFrom(this.templatesApi.get(id));
      this.setRiskLevels(this.element.riskLevelSet);
      this.setEditMode();
      await this.setupForm();
      this.overlay.dismissLoading();
    } catch (error) {
      this.notification.showError(error);
    }
  }

  override async setupForm() {
    if (this.recordId) {
      const { description, indicatorGroups, name, riskLevelSet } = this.element;

      this.form.patchValue({
        name,
        description,
        riskLevelSet: this.riskLevelSetOptions().find((option) => option.value === riskLevelSet),
      });
      this.indicatorsFormService.set(indicatorGroups);
      this.initialFormValue = this.form.getRawValue();
      this.hasFormValuesChanged = false;
    }
  }

  override async save() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.showError(CommonConstants.FILL_REQUIRED_FIELDS_MSG);

      return false;
    }

    try {
      this.element = await lastValueFrom(
        this.templatesApi.createOrUpdate(this.buildPayload(), this.recordId),
      );
      this.hasFormValuesChanged = false;
      this.initialFormValue = this.form.getRawValue();

      this.notification.showSuccess(
        `Risk assessment template ${this.recordId ? "modified" : "created"}`,
      );

      return true;
    } catch (error) {
      this.notification.showError(error);

      return false;
    }
  }

  override async afterSave(isSaveOnly?: boolean) {
    if (isSaveOnly && !this.isEditing()) {
      await this.routerService.navigate(
        this.routerService.getRiskAssessmentTemplatesLink({
          id: this.element.id,
          extraParams: {
            type: this.resourceType,
          },
        }),
        { replaceUrl: true },
      );
    }
  }

  private buildPayload(): RiskAssessmentTemplatePayload {
    const { name, description, riskLevelSet } = this.form.getRawValue();

    return {
      name,
      description,
      riskLevelSet: riskLevelSet.value,
      appliesTo: this.resourceType,
      recordState: null,
      indicatorGroups: this.recordId
        ? this.indicatorsFormService.buildPayload()
        : [{ name: null, indicators: [{ guidance: "", indicator: "Name" }] }],
    };
  }

  private onIndicatorAdded(formGroup: FormGroup) {
    const subscription = formGroup.valueChanges
      .pipe(
        tap((value) => this.updateHasFormValuesChanged(formGroup, value)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();

    this.indicatorFormGroupSubscriptions.set(formGroup, subscription);
  }

  private onIndicatorRemoved(formGroup: FormGroup) {
    const subscription = this.indicatorFormGroupSubscriptions.get(formGroup);

    if (subscription) {
      subscription.unsubscribe();
      this.indicatorFormGroupSubscriptions.delete(formGroup);
    }

    this.hasFormValuesChanged = true;
  }

  private updateHasFormValuesChanged(formGroup: FormGroup, value: object) {
    this.hasFormValuesChanged = !formGroup.pristine && this.hasInitialFormValueChanged(value);
  }

  private async fetchRiskLevelSets() {
    try {
      const data = await lastValueFrom(
        this.riskLevelSetsApi
          .getAll({
            recordState: RecordStateEnum.ACTIVE,
            page: 0,
            size: CommonConstants.MAX_API_GET_ITEMS_SIZE,
          })
          .pipe(map((data) => data.content)),
      );

      this.riskLevelSets.set(data);
    } catch (error) {
      this.notification.showError(error);
    }
  }

  private setRiskLevelSetOptions(riskLevelSets: RiskLevelSet[]) {
    const options = riskLevelSets.map((item) => ({
      label: item.name,
      value: `/organisations/${this.activeOrganisationId}/risk-assessment/risk-level-sets/${item.id}`,
    }));

    this.riskLevelSetOptions.set(options);
  }

  private setRiskLevels(riskLevelSetUri: string) {
    if (!riskLevelSetUri) {
      this.elementRiskLevels.set([]);

      return;
    }

    const riskLevelSetId = CommonUtils.getUriId(riskLevelSetUri);

    const riskLevelSet = this.riskLevelSets().find(
      (riskLevelSet) => riskLevelSet.id === riskLevelSetId,
    );

    riskLevelSet && this.elementRiskLevels.set(riskLevelSet.riskLevels);
  }

  private async validateResourceTypeUrlParam(): Promise<void> {
    if (!Object.values(RiskAssessmentTemplateResourceType).includes(this.resourceType)) {
      await this.overlay.close(true);
      this.notification.showError("Resource type not found");
      await this.routerService.navigate(RoutingEnum.ADMIN_RISK_ASSESSMENT_TEMPLATES);

      return;
    }
  }
}
