import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

import { SlideOverlayContentComponent } from "@design-makeover/components/overlay/slide-overlay-content/slide-overlay-content.component";
import { SlideOverlayPageClass } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.class";
import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { SummaryComponentTypeEnum } from "@components/processes/edit-process/process-inputs-outputs/process-inputs-outputs.model";
import { ProcessOverlayService } from "@components/processes/pages/process-overlay/process-overlay.service";
import {
  OverlayCertificateAttachmentsComponent,
  OverlayDocumentAttachmentsComponent,
  ProcessInOutTableComponent,
} from "@components/shared";
import { CommonConstants } from "@shared/constants";
import {
  AttachmentTargetEnum,
  AttachmentTypeEnum,
  CustomFieldsResourceTypeEnum,
  EntityTypeEnum,
  OverlayTabEnum,
  ProcessStateEnum,
  RecordStateEnum,
  RoutingEnum,
} from "@shared/enums";
import {
  IBaseUnit,
  IMaterial,
  IProcess,
  IProcessPayload,
  IProductExtended,
  IRecordState,
  ISelectOption,
} from "@shared/interfaces";
import {
  CommonService,
  LocationsService,
  MaterialsService,
  ProcessesService,
  ProcessTypesService,
} from "@shared/services";
import { NavigationParams } from "@shared/services/router.service";
import { CommonUtils, CustomFieldsUtils, FormUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

@Component({
  selector: "app-process-overlay",
  templateUrl: "./process-overlay.component.html",
  styleUrl: "./process-overlay.component.scss",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ProcessOverlayComponent extends SlideOverlayPageClass implements OnInit, OnDestroy {
  @ViewChild("certificateSection") certificateSection: OverlayCertificateAttachmentsComponent;

  @ViewChild("slideOverlayContent") override slideOverlayContent: SlideOverlayContentComponent;

  @ViewChild("documentSection") documentSection: OverlayDocumentAttachmentsComponent;

  @ViewChild("processInputTable") processInputTable: ProcessInOutTableComponent;

  @ViewChild("processOutputTable") processOutputTable: ProcessInOutTableComponent;

  public formGroup: UntypedFormGroup;

  public override element: IProcess;

  public override entityType = EntityTypeEnum.PROCESSES;

  public override readonly attachmentTargetType = AttachmentTargetEnum.PROCESS;

  public readonly dateFormat = CommonConstants.DATE_FORMAT;

  public readonly attachmentTypeEnum = AttachmentTypeEnum;

  public readonly attachmentTargetEnum = AttachmentTargetEnum;

  public readonly summaryComponentEnum = SummaryComponentTypeEnum;

  public readonly recordStateEnum = RecordStateEnum;

  public readonly isSharedUser = this.authenticationService.isSharedUser();

  public allLocationsOptions: ISelectOption[] = [];

  public allProcessTypesOptions: ISelectOption[] = [];

  public allMaterials: IMaterial[] = [];

  public allUnitOfMeasurements: IBaseUnit[] = [];

  override menuItems = signal(
    new Map([
      [OverlayTabEnum.DETAILS, { isEnabled: true }],
      [OverlayTabEnum.INPUTS, { isEnabled: false }],
      [OverlayTabEnum.OUTPUTS, { isEnabled: false }],
      [OverlayTabEnum.CERTIFICATES, { isEnabled: false }],
      [OverlayTabEnum.DOCUMENTS, { isEnabled: false }],
      [OverlayTabEnum.COMMENTS, { isEnabled: false, isHidden: !this.isRegularUser }],
    ]),
  );

  private selectedLocationId: string;

  private selectedProcessTypeId: string;

  constructor(
    public processOverlayService: ProcessOverlayService,
    public processesService: ProcessesService,
    private commonService: CommonService,
    private processTypesService: ProcessTypesService,
    private locationsService: LocationsService,
    private materialsService: MaterialsService,
    private notificationService: NotificationService,
  ) {
    super();

    this.subscriptions.add(
      this.commonService.unitOfMeasurementsObservable$.subscribe(
        (unitOfMeasurements: IBaseUnit[]) => {
          this.allUnitOfMeasurements = unitOfMeasurements;
        },
      ),
    );
  }

  public override get isSubmitButtonDisabled(): boolean {
    if (!this.isEditing()) {
      return false;
    }

    return this.formGroup?.invalid || this.formGroup?.pending || !this.hasFormValuesChanged;
  }

  loadCounters(): void {
    if (this.recordId) {
      this.loadDocuments();
      this.loadCertificates();
      this.loadComments();
      this.loadInputCounter();
      this.loadOutputCounter();
    }
  }

  setCountersToLoadingState() {
    this.processOverlayService.setCountersToLoadingState();
  }

  setCountersEmptyState() {
    this.processOverlayService.setCountersEmptyState();
  }

  loadDocuments(): void {
    this.processOverlayService.loadDocumentCounter(this.recordId, this.attachmentTargetType);
  }

  loadCertificates(): void {
    this.processOverlayService.loadCertificateCounter(this.recordId, this.attachmentTargetType);
  }

  loadComments(): void {
    this.processOverlayService.loadCommentCounter(this.entityUri);
  }

  loadInputCounter(): void {
    this.processOverlayService.loadInputOutputCounter(this.recordId, "input");
  }

  loadOutputCounter(): void {
    this.processOverlayService.loadInputOutputCounter(this.recordId, "output");
  }

  public onInputsChanged(count: number): void {
    this.processOverlayService.inputCounter.set(count);
  }

  public onOutputsChanged(count: number): void {
    this.processOverlayService.outputCounter.set(count);
  }

  public async ngOnInit(): Promise<void> {
    this.overlay.showLoading();
    if (!this.isOnCorrectOverlay(RoutingEnum.OVERLAY_PROCESS)) {
      return;
    }

    await Promise.all([
      await this.onReloadProducts(),
      await this.setAllCustomFields(CustomFieldsResourceTypeEnum.PROCESS),
      (this.allMaterials = await this.materialsService.getAll()),
    ]);
    this.processOverlayService.certificateCounter.set(0);
    this.processOverlayService.documentCounter.set(0);
    this.processOverlayService.inputCounter.set(0);
    this.processOverlayService.outputCounter.set(0);

    if (this.recordId) {
      this.setCountersToLoadingState();

      await this.reloadElement(this.recordId);
      await this.setMenuItemFromURLParam();
      this.loadCounters();
    } else {
      await Promise.all([this.setAllLocationsOptions(), this.setAllProcessTypesOptions()]);
      this.setupForm();
      this.overlay.dismissLoading();
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.setCountersEmptyState();
  }

  public override async reloadElement(processId: string): Promise<void> {
    this.overlay.showLoading();

    try {
      this.element = await this.processesService.get(processId);
      this.setEditMode();
      this.selectedProcessTypeId = CommonUtils.getUriId(this.element?.type);
      this.selectedLocationId = CommonUtils.getUriId(this.element?.location);

      await Promise.all([this.setAllLocationsOptions(), this.setAllProcessTypesOptions()]);

      this.setupForm();
      this.overlay.dismissLoading();
    } catch (error) {
      this.notificationService.showError(error);
    }
  }

  public override setupForm(): void {
    const processType = this.allProcessTypesOptions?.find(
      (t) => t.value === this.selectedProcessTypeId,
    );
    const locationType = this.allLocationsOptions?.find((l) => l.value === this.selectedLocationId);

    const entityExistsValidatorArgs = {
      searchPropertyName: "processId",
      searchPropertyErrorDisplayName: "ID",
    };

    this.formGroup = new UntypedFormGroup({
      processId: new UntypedFormControl(
        this.element?.processId ?? null,
        [CustomValidators.required],
        [
          CustomValidators.entityAlreadyExists(
            this.processesService,
            this.element?.id ?? null,
            entityExistsValidatorArgs,
          ),
        ],
      ),
      type: new UntypedFormControl({ value: processType ?? null, disabled: this.isEditing() }, [
        CustomValidators.required,
      ]),
      location: new UntypedFormControl(
        { value: locationType ?? null, disabled: this.isEditing() },
        [CustomValidators.required],
      ),
      firstInputDate: new UntypedFormControl({
        value: this.element?.firstInputDate ?? null,
        disabled: true,
      }),
      lastOutputDate: new UntypedFormControl({
        value: this.element?.lastOutputDate ?? null,
        disabled: true,
      }),
    });

    this.visibleCustomFields = CustomFieldsUtils.getVisible(
      this.allCustomFields,
      this.element?.customFields,
    );
    CustomFieldsUtils.addToFormGroup(
      this.formGroup,
      this.visibleCustomFields,
      this.element?.customFields,
    );

    this.initialFormValue = this.formGroup.value;
    this.hasFormValuesChanged = false;
    this.subscriptions.add(
      this.formGroup.valueChanges.subscribe(() => {
        this.hasFormValuesChanged =
          !this.formGroup.pristine && this.hasInitialFormValueChanged(this.formGroup.value);
      }),
    );
  }

  public override async save(): Promise<boolean> {
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);
      this.notificationService.showError(CommonConstants.FILL_REQUIRED_FIELDS_MSG);

      return false;
    }

    try {
      await this.fetchOrCreateProcessType();
      const { processId, type, location } = this.formGroup.getRawValue();

      const payload: IProcessPayload = {
        processId,
        type: `/organisations/${this.activeOrganisationId}/process-types/${type.value}`,
        location: `/organisations/${this.activeOrganisationId}/locations/${location.value}`,
        state: ProcessStateEnum.INPUT_OUTPUT,
      };

      CustomFieldsUtils.addToPayload(
        payload,
        this.activeOrganisationId,
        this.formGroup,
        this.visibleCustomFields,
      );

      this.element = await this.processesService.createOrUpdate(payload, this.element?.id);
      this.hasFormValuesChanged = false;
      this.notificationService.showSuccess(`Process ${this.isEditing() ? "modified" : "created"}`);

      return true;
    } catch (error) {
      this.notificationService.showError(error);

      return false;
    }
  }

  public override async afterSave(isSaveOnly?): Promise<void> {
    if (isSaveOnly) {
      if (!this.isEditing()) {
        await this.routerService.navigate(this.routerService.getProcessLink(this.element.id), {
          replaceUrl: true,
        });
      }
    }
  }

  public getLocationLink = (id: string): NavigationParams => {
    return this.routerService.getLocationLink(id, false) as NavigationParams;
  };

  protected override recordName(): string {
    return this.element?.processId;
  }

  protected override async archiveRecord(id: string, payload: IRecordState): Promise<void> {
    await this.processesService.setRecordState(payload, id);
  }

  protected override async deleteRecord(id: string): Promise<void> {
    await this.processesService.delete(id);
  }

  private getAllLocations = async () => await this.locationsService.getAllGraphQL();

  private getAllProcessTypes = async () => await this.processTypesService.getAll();

  private onReloadProducts = async () => {
    await this.processOverlayService.onReloadProducts();
  };

  get allProducts(): IProductExtended[] {
    return this.processOverlayService.allProducts();
  }

  private setAllLocationsOptions = async () => {
    this.allLocationsOptions = (await this.getAllLocations())
      .filter(
        (location) =>
          location.recordState === RecordStateEnum.ACTIVE ||
          (this.selectedLocationId && location.id === this.selectedLocationId),
      )
      .map((l) => ({
        label: l.name,
        value: l.id,
      }));
  };

  private setAllProcessTypesOptions = async () => {
    this.allProcessTypesOptions = (await this.getAllProcessTypes())
      .filter(
        (type) =>
          type.recordState === RecordStateEnum.ACTIVE ||
          (this.selectedProcessTypeId && type.id === this.selectedProcessTypeId),
      )
      .map((t) => ({
        label: t.name,
        value: t.id,
      }));
  };

  private fetchOrCreateProcessType = async () => {
    const { type } = this.formGroup.getRawValue();
    const isNewType = !type.value;

    if (!isNewType) {
      return;
    }

    const newType = await this.processTypesService.createOrUpdate({ name: type.label.trim() });

    this.formGroup.controls["type"].setValue({ label: newType.name, value: newType.id });
  };
}
