<div class="container-flex-space-between">
  <div class="left-content">
    <div class="name" [tp]="name" tpPlacement="top-start" [tpOffset]="[-25, 0]">{{ name }}</div>
    <div class="number" [tp]="certificate.number" tpPlacement="top-start" [tpOffset]="[-25, 0]">
      {{ certificate.number }}
    </div>
    <div class="validity">
      Validity: {{ certificate.validFromDate | date: dateFormat }} -
      {{ certificate.validToDate | date: dateFormat }}
    </div>
  </div>
  <iov-button-group>
    @if (type === "add") {
      <iov-button size="medium" type="icon">
        <mat-icon icon-medium-18>add</mat-icon>
      </iov-button>
    } @else {
      <iov-button (pressed)="onClickRemove()" tp="Remove" size="medium" type="icon">
        <mat-icon icon-medium-18>close</mat-icon>
      </iov-button>
    }
  </iov-button-group>
</div>
