<app-table
  *ngIf="columnDefs().length"
  [table]="table"
  [isLoading]="isLoading()"
  [rowData]="rowData"
  [class]="class"
  [columnDefs]="columnDefs()"
  [areButtonsEnabled]="areButtonsEnabled"
  [isSearchEnabled]="isSearchEnabled"
  [isRecordStateFilterEnabled]="false"
  [isPaginatorEnabled]="isPaginatorEnabled"
  (selectionChanged)="selectionChanged($event)"
  [isSaveState]="isSaveTableState"
></app-table>
@if (rowData?.length) {
  <div class="container-flex-right dark-gray bold margin-top-10">
    {{ totalSummary }}
  </div>
}
