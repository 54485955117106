<div class="container-space-between">
  <app-page-title
    [goBackRoute]="routing.ADMIN"
    title="Risk assessment templates"
    backText="Back to Admin"
  />
  @if (canAddModifyEntities) {
    <iov-button-group>
      <iov-button (pressed)="onAdd()">
        <mat-icon icon-left>add</mat-icon>
        Add new
      </iov-button>
    </iov-button-group>
  }
</div>

<mat-tab-group
  animationDuration="0ms"
  mat-align-tabs="start"
  [mat-stretch-tabs]="false"
  [selectedIndex]="selectedTabIndex()"
  (selectedIndexChange)="selectedTabIndex.set($event)"
>
  <mat-tab>
    <ng-template mat-tab-label>
      Organisations ({{ organisationsTable.totalElements() }})
    </ng-template>
    <app-risk-assessment-templates-table
      #organisationsTable
      [isFixedBottomPaginator]="true"
      [resourceType]="resourceType.ORGANISATION"
    />
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>Locations ({{ locationsTable.totalElements() }})</ng-template>
    <app-risk-assessment-templates-table
      #locationsTable
      [isFixedBottomPaginator]="true"
      [resourceType]="resourceType.LOCATION"
    />
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>Products ({{ productsTable.totalElements() }})</ng-template>
    <app-risk-assessment-templates-table
      #productsTable
      [isFixedBottomPaginator]="true"
      [resourceType]="resourceType.PRODUCT"
    />
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>Deliveries ({{ deliveriesTable.totalElements() }})</ng-template>
    <app-risk-assessment-templates-table
      #deliveriesTable
      [isFixedBottomPaginator]="true"
      [resourceType]="resourceType.DELIVERY"
    />
  </mat-tab>
</mat-tab-group>
