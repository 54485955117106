<form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
  <h2 mat-dialog-title>Add analysis</h2>

  <app-loader *ngIf="isLoading()" containerClass="on-dialog"></app-loader>

  <ng-container *ngIf="!isLoading()">
    <mat-dialog-content>
      <iov-input-select
        [hint]="
          typeControl.valid && !typeControl.value?.enabled
            ? 'This extension isn\'t activated for your organisation. Please contact Interu support to activate it.'
            : ''
        "
        [hintClass]="!typeControl.value?.enabled ? 'info-hint hint-bg-yellow' : ''"
        [options]="typeOptions"
        formControlName="type"
        label="Type"
      ></iov-input-select>

      <iov-input autofocus formControlName="name" label="Name"></iov-input>

      <iov-daterangepicker
        class="no-padding-top-input"
        formControlName="time"
        [maxDate]="maxDate"
        label="Date range"
      ></iov-daterangepicker>
      @if (error) {
        <small class="red">
          {{ error }}
        </small>
      }
    </mat-dialog-content>

    <mat-dialog-actions>
      <iov-button-group>
        <iov-button (pressed)="onClose()" type="ghost">Cancel</iov-button>
        <iov-button
          [disabled]="isSubmitButtonDisabled()"
          accessibilityRole="submit"
          cdkFocusInitial
        >
          <mat-icon icon-left>check</mat-icon>
          Save
        </iov-button>
      </iov-button-group>
    </mat-dialog-actions>
  </ng-container>
</form>
