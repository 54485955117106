import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  signal,
} from "@angular/core";

import { ColDef } from "ag-grid-community";
import { Subscription } from "rxjs";

import { SlideOverlayPageService } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.service";
import { NotificationService } from "@design-makeover/services/notification/notification.service";

import {
  BadgeLinkCellRendererComponent,
  DateCellRendererComponent,
  QuickActionsMenuComponent,
} from "@shared/cell-renderers";
import { CommonConstants } from "@shared/constants";
import { OverlayTabEnum, RecordStateEnum, RoutingEnum, TableEnum } from "@shared/enums";
import { IDocument, IDocumentExtended, IDocumentType } from "@shared/interfaces";
import {
  AuthenticationService,
  DocumentsService,
  DownloadDocumentsService,
} from "@shared/services";
import { RouterService } from "@shared/services/router.service";
import { ColumnUtils, CommonUtils } from "@shared/utils";

@Component({
  selector: "app-documents-table",
  templateUrl: "./documents-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsTableComponent implements AfterViewInit, OnDestroy {
  @Input()
  public documents: IDocument[] = null;

  @Input()
  public documentsIds: string[] = null;

  @Input()
  public allDocumentTypes: IDocumentType[] = [];

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isRecordStateFilterEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  @Input()
  public recordState: RecordStateEnum = RecordStateEnum.ALL;

  @Input()
  public isShowSelectCheckbox = false;

  @Input()
  public canNavigateToDetail = true;

  @Input()
  public shouldOpenInNewTab = false;

  @Input()
  public table = TableEnum.DOCUMENTS;

  @Input()
  public isSaveTableState = false;

  @Input()
  public isInboundShared = false;

  @Input()
  public inboundSharedSenderOrgId: string = null;

  @Input()
  public allowClickOnNameRow: boolean = true;

  @Input()
  public columns: string[] = [
    "recordState",
    "name",
    "type.name",
    "issuance",
    "validityStart",
    "validityEnd",
    "tags",
  ];

  @Input()
  public class: string;

  @Input()
  public canRemove = false;

  @Output()
  public remove: EventEmitter<IDocument> = new EventEmitter();

  @Output()
  public setDocuments: EventEmitter<IDocument[]> = new EventEmitter();

  public columnDefs = signal<ColDef[]>([]);

  public isLoading = signal(true);

  public rowData: any[] = []; //todo use IDocumentExtended when graphQL implemented everywhere

  private subscriptions = new Subscription();

  constructor(
    private routerService: RouterService,
    private documentsService: DocumentsService,
    private notificationService: NotificationService,
    private overlay: SlideOverlayPageService,
    private authenticationService: AuthenticationService,
    private downloadDocumentsService: DownloadDocumentsService,
  ) {
    this.subscriptions.add(
      this.overlay.refreshTable$.subscribe(() => {
        this.getAll();
      }),
    );
  }

  public async ngAfterViewInit() {
    this.setColumnDefs();

    await this.getAll();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onDownload = async (document: IDocument): Promise<void> => {
    if (!document.contentType) {
      return;
    }

    let urlPath: string = undefined;

    if (this.isInboundShared && this.inboundSharedSenderOrgId) {
      urlPath = `organisations/${this.authenticationService.getActiveOrganisationId()}/record-sharing/inbound/records/${this.inboundSharedSenderOrgId}/documents/${document.id}/content`;
    }

    this.downloadDocumentsService.add(
      {
        documentId: document.id,
        documentName: document.name,
      },
      urlPath,
    );
  };

  public onView = async (document: IDocument): Promise<void> => {
    if (!document.contentType) {
      return;
    }

    let url = `${RoutingEnum.DOCUMENTS_VIEW}/${document.id}`;

    if (this.isInboundShared && this.inboundSharedSenderOrgId) {
      url = `${url}?senderOrgId=${this.inboundSharedSenderOrgId}`;
    }

    CommonUtils.openInNewTab(url);
  };

  public onRowClick = async (row: IDocument): Promise<void> => {
    if (this.canNavigateToDetail) {
      if (this.shouldOpenInNewTab) {
        if (this.inboundSharedSenderOrgId) {
          this.routerService.openNewTab(
            this.routerService.getSharedDocumentLink(row.id, false, {
              organisationId: this.inboundSharedSenderOrgId,
            }),
          );
        } else {
          this.routerService.openNewTab(this.routerService.getDocumentLink(row.id, false));
        }
      } else {
        if (this.inboundSharedSenderOrgId) {
          this.routerService.navigate(
            this.routerService.getSharedDocumentLink(row.id, false, {
              organisationId: this.inboundSharedSenderOrgId,
            }),
          );
        } else {
          this.routerService.navigate(this.routerService.getDocumentLink(row.id));
        }
      }
    } else {
      row.canView ? await this.onView(row) : await this.onDownload(row);
    }
  };

  private setColumnDefs = (): void => {
    const nameActions: any[] = [
      {
        click: this.onDownload,
        tooltip: (row: IDocument) =>
          !row.contentType ? CommonConstants.DOCUMENT_NO_CONTENT_TEXT : "Download",
        icon: "download",
        iconClass: (row: IDocument) => (!row.contentType ? "red" : ""),
      },
      {
        click: this.onView,
        tooltip: (row: IDocument) =>
          !row.contentType ? CommonConstants.DOCUMENT_NO_CONTENT_TEXT : "View",
        icon: "visibility",
        iconClass: (row: IDocument) => (!row.contentType ? "red" : ""),
        show: (row) => row.canView,
      },
    ];

    if (this.canRemove) {
      nameActions.unshift({
        icon: "close",
        tooltip: "Remove",
        show: () => this.canRemove,
        click: (document: IDocument) => this.remove.emit(document),
      });
    }

    const nameColOptions: ColDef = {
      suppressSizeToFit: true,
      suppressAutoSize: true,
      lockVisible: true,
      rowDrag: false,
      cellRenderer: QuickActionsMenuComponent,
      cellRendererParams: {
        linkRouteIdParam: "id",
        organisationId: "test id",
        linkRouteFn: this.isInboundShared
          ? (id) =>
              this.routerService.getSharedDocumentLink(id, false, {
                organisationId: this.inboundSharedSenderOrgId,
              })
          : this.routerService.getDocumentLink,
        openInNewTab: this.shouldOpenInNewTab,
        actions: nameActions,
      },
    };

    let columnDefs: ColDef[] = [
      ColumnUtils.recordState(),
      {
        colId: "name",
        headerName: "Name",
        field: "name",
        ...nameColOptions,
      },
      {
        colId: "documentName",
        headerName: "Document Name",
        field: "name",
        ...nameColOptions,
      },
      { colId: "type.name", headerName: "Type", field: "type.name" },
      { colId: "documentType", headerName: "Document Type", field: "type.name" },
      ColumnUtils.chips("Rulesets applied", "rulesetsNames"),
      {
        headerName: "Issued on",
        field: "issuance",
        cellRenderer: DateCellRendererComponent,
      },
      {
        headerName: "Valid from",
        field: "validityStart",
        cellRenderer: DateCellRendererComponent,
      },
      {
        headerName: "Valid to",
        field: "validityEnd",
        cellRenderer: DateCellRendererComponent,
      },
      {
        headerName: "Attached to",
        field: "attachedTo",
        cellClass: "container-flex-left",
        valueGetter: (cell: any) => String(cell.data?.attachedTo?.length ?? 0),
        cellRenderer: BadgeLinkCellRendererComponent,
        cellRendererParams: {
          tooltipArray: (row) => {
            if (!row.attachedTo?.length) {
              return undefined;
            }

            return CommonUtils.groupArrayByKeyAndCount(row.attachedTo, "type").map(
              (g) =>
                `${CommonUtils.pluraliseEntity(CommonUtils.capitaliseFirstLetter(g.key))}: ${g.count}`,
            );
          },
          tooltipTemplate: "keyCount",
          badgeValue: (row) => row.attachedTo?.length ?? 0,
          linkRouteIdParam: "id",
          linkRouteTab: CommonUtils.textToEnum(OverlayTabEnum.ATTACHED_TO),
          linkRouteFn: this.routerService.getDocumentLink,
        },
      },
    ];

    if (this.authenticationService.isRegularUser() && !this.isInboundShared) {
      columnDefs.push(ColumnUtils.tags("Tags"));
    }

    columnDefs = CommonUtils.getVisibleColumnDefs(columnDefs, this.columns);

    if (this.isShowSelectCheckbox) {
      columnDefs.unshift(ColumnUtils.selectCheckbox());
    }

    this.columnDefs.set(columnDefs);
  };

  private setDocumentType = (documents: IDocument[]): void => {
    for (const document of documents) {
      if (typeof document.type === "object" && document.type?.name) {
        document.typeName = document.type.name;
      } else {
        document.type ||= this.allDocumentTypes.find(
          (d) => d.id === CommonUtils.getUriId(document.type),
        );
      }
    }
  };

  private setDocumentsRulesetsNames(documents: IDocument[] | IDocumentExtended[]): void {
    if (
      !this.allDocumentTypes?.length ||
      !this.authenticationService.isRegularUser() ||
      !this.columnDefs().some((c) => c.field === "rulesetsNames")
    ) {
      return;
    }

    for (const document of documents) {
      document.rulesetsNames = this.allDocumentTypes.find(
        (t) => document.type.name === t.name,
      )?.rulesetsNames;
    }
  }

  private getAll = async (): Promise<void> => {
    this.rowData = [];
    this.isLoading.set(true);

    const tagsFieldPresent = this.columnDefs().some((c) => c.field === "tags");
    const attachedToFieldPresent = this.columnDefs().some((c) => c.field === "attachedTo");
    const fieldsToInclude = [];

    if (tagsFieldPresent) {
      fieldsToInclude.push("TAGS");
    }
    if (attachedToFieldPresent) {
      fieldsToInclude.push("ATTACHED_TO");
    }

    if (this.documents) {
      const documents = [...this.documents];

      this.setDocumentType(documents);
      CommonUtils.setDocumentsCanView(documents);
      this.setDocumentsRulesetsNames(documents);
      this.rowData = documents;
      this.isLoading.set(false);
    } else if (this.documentsIds) {
      try {
        if (!this.documentsIds.length) {
          this.rowData = [];
          this.isLoading.set(false);

          return;
        }
        const documents = await this.documentsService.getByIdsGraphQL(
          this.documentsIds,
          undefined,
          fieldsToInclude,
        );

        CommonUtils.setDocumentsCanView(documents);
        this.setDocumentsRulesetsNames(documents);
        this.rowData = documents;
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    } else {
      try {
        const documents = await this.documentsService.getAllGraphQL(
          undefined,
          undefined,
          fieldsToInclude,
        );

        CommonUtils.setDocumentsCanView(documents);
        this.setDocumentsRulesetsNames(documents);
        this.rowData = documents;
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };
}
