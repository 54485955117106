@if (isLoading()) {
  <app-loader></app-loader>
} @else {
  <form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
    <h2 mat-dialog-title>Edit delivery item</h2>

    <mat-dialog-content>
      <div class="row-container">
        <div class="row row-2">
          <div class="item">
            <iov-input
              [suffix]="
                item.productDefaultUnit
                  ? item.productDefaultUnit.symbol
                  : item.unitOfMeasurement?.symbol
              "
              [hint]="
                item.productDefaultUnit
                  ? (formGroup.controls['remainingQty'].value
                    | unitConversion: item.productDefaultUnit : item.unitOfMeasurement)
                  : ''
              "
              class="max-width-160 no-padding"
              formControlName="remainingQty"
              label="Remaining quantity"
              type="number"
            ></iov-input>
          </div>
          <div class="item">
            <iov-input
              [suffix]="
                item.productDefaultUnit
                  ? item.productDefaultUnit.symbol
                  : item.unitOfMeasurement?.symbol
              "
              [hint]="
                item.productDefaultUnit
                  ? (formGroup.controls['deliveredQty'].value
                    | unitConversion: item.productDefaultUnit : item.unitOfMeasurement)
                  : ''
              "
              class="max-width-160 no-padding"
              formControlName="deliveredQty"
              label="Delivery quantity"
              type="number"
            ></iov-input>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <iov-button-group>
        <iov-button (pressed)="onClose()" type="ghost">Cancel</iov-button>
        <iov-button [disabled]="isSubmitButtonDisabled" accessibilityRole="submit" cdkFocusInitial>
          <mat-icon icon-left>check</mat-icon>
          Save
        </iov-button>
      </iov-button-group>
    </mat-dialog-actions>
  </form>
}
