import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  input,
  model,
  OnInit,
  Output,
  signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";

import {
  EditDocumentTypeDialogComponent,
  EditLocationTypeDialogComponent,
} from "src/app/components/settings";

import { InputSelectOption } from "@design-makeover/components/inputs/input-select/input-select.model";
import { SlideOverlayPageService } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.service";
import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { AddConnectionDialogComponent } from "@components/locations";
import { AddLocationDialogComponent } from "@components/organisations";
import {
  AddCertificateDialogComponent,
  AddDocumentDialogComponent,
  ConfirmDialogComponent,
  InfoDialogComponent,
} from "@components/shared";
import { AddCertificateDialogModel } from "@components/shared/add-certificate-dialog/add-certificate-dialog.model";
import { ConfirmDialogResponseEnum, CrossOrgShareDataTypeEnum } from "@shared/enums";
import {
  ICertificate,
  ICheckExistenceMappedRecordsInfo,
  IDeliveryPayload,
  IDocument,
  IDocumentType,
  IInboundMapping,
  IInboundShare,
  ILocationExtended,
  ILocationType,
  IOrganisation,
  ISelectOption,
} from "@shared/interfaces";
import {
  RecordSharingService,
  AuthenticationService,
  ConnectionsService,
  DeliveriesService,
} from "@shared/services";
import { RouterService } from "@shared/services/router.service";
import { CommonUtils } from "@shared/utils";

import { UnmappedDependenciesService } from "../unmapped-dependencies.service";

@Component({
  selector: "app-trasfer-or-map-form",
  templateUrl: "./trasfer-or-map-form.component.html",
  styleUrl: "./trasfer-or-map-form.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrasferOrMapFormComponent implements OnInit {
  private recordSharingService = inject(RecordSharingService);

  private authenticationService = inject(AuthenticationService);

  private notificationsService = inject(NotificationService);

  private router = inject(RouterService);

  private connectionsService = inject(ConnectionsService);

  private unmappedDependenciesService = inject(UnmappedDependenciesService);

  private deliveriesService = inject(DeliveriesService);

  public sharedData = model<any[]>([]);

  public localRecords = input<InputSelectOption[]>([]);

  public locationTypesOptions = input<InputSelectOption[]>([]);

  public documentTypesOptions = input<InputSelectOption[]>([]);

  public shareRecordType = input<CrossOrgShareDataTypeEnum>(null);

  public inboundShare = model<IInboundShare>(null);

  public inputSelectPlaceholder = input<string>(null);

  public countryOptions = input<ISelectOption[]>([]);

  public isViewButtonDisabled = input(true);

  public isTransferButtonDisabled = input(false);

  public existingMappings = model<IInboundMapping[]>([]);

  @Output() newRecordCreated: EventEmitter<void> = new EventEmitter<void>();

  @Output() inboundMappingChanged: EventEmitter<void> = new EventEmitter<void>();

  public formGroup: FormGroup = new FormGroup({});

  private destroyRef = inject(DestroyRef);

  public isLoading = signal(true);

  private activeOrganisationId: string;

  private checkingForExistingRecords: boolean;

  public readonly inboundShareDataTypeEnum = CrossOrgShareDataTypeEnum;

  private overlay = inject(SlideOverlayPageService);

  constructor(private dialog: MatDialog) {
    this.activeOrganisationId = this.authenticationService.getActiveOrganisationId();
  }

  async ngOnInit(): Promise<void> {
    this.isLoading.set(true);
    this.sharedData().forEach((_, index) => {
      const controlName = `${index}`;
      const formControl = new FormControl();

      this.formGroup.addControl(controlName, formControl);
    });
    await this.checkRecordExistance();
    this.initializeFormControlSubscriptions();
  }

  private initializeFormControlSubscriptions(): void {
    Object.keys(this.formGroup.controls).forEach((controlName) => {
      const formControl = this.formGroup.get(controlName);

      formControl?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
        if (value) {
          const mappingExist = this.hasLocalMapping(value.value, "localUri");

          if (mappingExist && !this.checkingForExistingRecords) {
            this.notificationsService.showError(`A mapping for ${value.label} already exist`);
          }
        }
      });
    });
  }

  private async checkRecordExistance(): Promise<void> {
    this.checkingForExistingRecords = true;
    try {
      const records = await this.recordSharingService.getCheckExistingRecord(
        this.inboundShare().rootRecordUri,
      );

      const sharedData = this.sharedData().map((data, index) => {
        const mappedRecordInfo = records.mappedRecordsInfo.find(
          (r) => CommonUtils.getUriId(r.inboundRecord) === data.id,
        );

        if (mappedRecordInfo) {
          const localValue = this.localRecords().find(
            (t) => t.value === CommonUtils.getUriId(mappedRecordInfo.localRecord),
          );

          this.formGroup.controls[index].setValue(localValue);
        } else {
          this.formGroup.controls[index].setValue("");
        }
        const inBoundInfo = records.newRecords.find((nr) => CommonUtils.getUriId(nr) === data.id);
        const isNew = records.newRecords.some((nr) => CommonUtils.getUriId(nr) === data.id);
        const isExisting = records.existingRecordsInfo.some(
          (mr) => CommonUtils.getUriId(mr.inboundRecord) === data.id,
        );

        return { ...data, inBoundInfo, mappedRecordInfo, isExisting, isNew };
      });

      this.sharedData.update((_) => sharedData);
    } catch (error) {
      this.notificationsService.showError(error);
    } finally {
      this.isLoading.set(false);
      this.checkingForExistingRecords = false;
    }
  }

  public async transferInboundRecord(inboundRecord: any, index: number): Promise<void> {
    switch (this.shareRecordType()) {
      case CrossOrgShareDataTypeEnum.DOCUMENT_TYPES:
        this.addNewDocumentType(inboundRecord, index);
        break;
      case CrossOrgShareDataTypeEnum.LOCATION_TYPES:
        this.addNewLocationType(inboundRecord, index);
        break;
      case CrossOrgShareDataTypeEnum.CONNECTIONS:
        await this.addNewConnection(inboundRecord, index);
        break;
      case CrossOrgShareDataTypeEnum.LOCATIONS:
        this.addNewLocation(inboundRecord, index);
        break;
      case CrossOrgShareDataTypeEnum.DELIVERIES:
        this.addNewDelivery(inboundRecord, index);
        break;
      case CrossOrgShareDataTypeEnum.DOCUMENTS:
        this.addNewDocument(inboundRecord, index);
        break;
      case CrossOrgShareDataTypeEnum.CERTIFICATES:
        this.addNewCertificate(inboundRecord, index);
        break;
      default:
        break;
    }
  }

  private async addNewCertificate(certificate: ICertificate, index: number): Promise<void> {
    const result = await this.overlay.openDialog<
      AddCertificateDialogModel.IResult,
      AddCertificateDialogModel.IDialogData
    >(AddCertificateDialogComponent, {
      certificate,
    });

    if (result?.newCertificate) {
      this.newRecordCreated.emit();
      await this.addInboundMapping(index, result?.newCertificate);
    }
  }

  private async addNewDocument(document: IDocument, index: number): Promise<void> {
    const existingMappings = await this.recordSharingService.getAllMappings();

    this.existingMappings.update((_) => existingMappings);
    const inboundDocumentTypesIds = this.getInboundRecordIds(
      CrossOrgShareDataTypeEnum.DOCUMENT_TYPES,
    );

    if (
      this.unmappedDependenciesService.hasUnmappedDependencies(
        inboundDocumentTypesIds,
        this.existingMappings(),
        "inboundUri",
      )
    ) {
      this.showUnmappedDependenciesDialog();
    } else {
      await this.openAddDocumentDialog(index, document);
    }
  }

  private async addNewDelivery(inboundRecord: any, index: number): Promise<void> {
    try {
      const existingMappings = await this.recordSharingService.getAllMappings();
      const inboundFromLocationId = CommonUtils.getUriId(inboundRecord.from);
      const inboundToLocationId = CommonUtils.getUriId(inboundRecord.to);
      const mappedToLocationUri = existingMappings.find((m) =>
        m.inboundUri.includes(inboundToLocationId),
      )?.localUri;
      const mappedFromLocationUri = existingMappings.find((m) =>
        m.inboundUri.includes(inboundFromLocationId),
      )?.localUri;
      const payload: IDeliveryPayload = {
        deliveryId: inboundRecord.deliveryId,
        from: mappedFromLocationUri,
        to: mappedToLocationUri,
        sent: inboundRecord.sent,
        status: inboundRecord.status,
        delivered: inboundRecord.delivered,
        items: [],
      };

      const result = await this.deliveriesService.createOrUpdate(payload);

      this.newRecordCreated.emit();

      await this.addInboundMapping(index, result);
    } catch (error) {
      this.notificationsService.showError(error);
    }
  }

  private async addNewLocation(location: ILocationExtended, index: number): Promise<void> {
    const existingMappings = await this.recordSharingService.getAllMappings();

    this.existingMappings.update((_) => existingMappings);
    const inboundLocationTypesIds = this.getInboundRecordIds(
      CrossOrgShareDataTypeEnum.LOCATION_TYPES,
    );
    const inboundConnectionsIds = this.getInboundRecordIds(CrossOrgShareDataTypeEnum.CONNECTIONS);

    if (
      this.unmappedDependenciesService.hasUnmappedDependencies(
        [...inboundLocationTypesIds, ...inboundConnectionsIds],
        this.existingMappings(),
        "inboundUri",
      )
    ) {
      this.showUnmappedDependenciesDialog();
    } else {
      await this.openAddLocationDialog(
        index,
        location,
        inboundLocationTypesIds,
        inboundConnectionsIds,
      );
    }
  }

  private getInboundRecordIds(recordType: CrossOrgShareDataTypeEnum): string[] {
    return this.inboundShare()
      .recordUris.filter((uri) => uri.includes(recordType))
      .map((uri) => CommonUtils.getUriId(uri));
  }

  private showUnmappedDependenciesDialog(): void {
    this.dialog.open(InfoDialogComponent, {
      data: {
        titleTranslatedText: "Unmapped dependencies found",
        contentTranslatedText:
          "Seems this record has some unmapped dependencies. Please refresh the page, as new / updated data might have appeared.",
      },
    });
  }

  private async openAddLocationDialog(
    index: number,
    location: ILocationExtended,
    locationTypesIds: string[],
    connectionsIds: string[],
  ): Promise<void> {
    try {
      const mappedLocationTypes = this.getMappedLocationTypes(locationTypesIds);
      const mappedConnection = this.getMappedConnection(connectionsIds);

      let organisation: IOrganisation | undefined;

      if (mappedConnection) {
        organisation = await this.connectionsService.get(CommonUtils.getUriId(mappedConnection));
      }

      const locationTypes = this.getLocationTypesOptions(mappedLocationTypes);

      const dialogResult = await this.overlay.openDialog<
        {
          hasSaved: boolean;
          location: ILocationExtended;
        },
        {
          countryOptions: ISelectOption[];
          location: ILocationExtended;
          locationTypes: InputSelectOption[];
          organisation: IOrganisation;
        }
      >(AddLocationDialogComponent, {
        countryOptions: this.countryOptions(),
        location,
        organisation,
        locationTypes,
      });

      if (dialogResult?.hasSaved) {
        this.newRecordCreated.emit();
        await this.addInboundMapping(index, dialogResult?.location);
      }
    } catch (error) {
      this.notificationsService.showError(error);
    }
  }

  private async openAddDocumentDialog(index: number, document: IDocument): Promise<void> {
    try {
      const inboundTypeId = CommonUtils.getUriId(document.type);
      const mappedDocumentTypes = this.getMappedDocumentTypes([inboundTypeId]);
      const inboundDocumentUri = this.inboundShare().recordUris.find((uri) =>
        uri.includes(document.id),
      );

      const documentTypeOption = this.documentTypesOptions().find(
        (t) => t.value === mappedDocumentTypes[0],
      );

      const dialogRef = this.dialog.open(AddDocumentDialogComponent, {
        data: {
          document,
          documentTypesOptions: this.documentTypesOptions(),
          selectedDocumentType: documentTypeOption,
          inboundDocumentUri,
        },
      });

      dialogRef
        .afterClosed()
        .subscribe(async (result: { hasSaved: boolean; newDocument: IDocument }) => {
          if (result?.hasSaved) {
            this.newRecordCreated.emit();
            await this.addInboundMapping(index, result?.newDocument);
          }
        });
    } catch (error) {
      this.notificationsService.showError(error);
    }
  }

  private getMappedLocationTypes(locationTypesIds: string[]): string[] {
    return locationTypesIds.map((id) =>
      CommonUtils.getUriId(
        this.existingMappings().find((m) => m.inboundUri.includes(id))?.localUri,
      ),
    );
  }

  private getMappedDocumentTypes(documentTypesIds: string[]): string[] {
    return documentTypesIds.map((id) =>
      CommonUtils.getUriId(
        this.existingMappings().find((m) => m.inboundUri.includes(id))?.localUri,
      ),
    );
  }

  private getMappedConnection(connectionsIds: string[]): string | undefined {
    return connectionsIds.map(
      (id) => this.existingMappings().find((m) => m.inboundUri.includes(id))?.localUri,
    )[0];
  }

  private getLocationTypesOptions(mappedLocationTypes: string[]): InputSelectOption[] {
    return this.locationTypesOptions().filter((type) =>
      mappedLocationTypes.includes(type.value as string),
    );
  }

  private async addNewConnection(inboundRecord: IOrganisation, index: number) {
    const result = await this.overlay.openDialog<
      { savedOrganisation: IOrganisation },
      { connection: IOrganisation; countryOptions: ISelectOption[] }
    >(AddConnectionDialogComponent, {
      countryOptions: this.countryOptions(),
      connection: inboundRecord,
    });

    if (result?.savedOrganisation) {
      this.newRecordCreated.emit();
      await this.addInboundMapping(index, result?.savedOrganisation);
    }
  }

  private addNewLocationType(type: ILocationType, index: number): void {
    const dialogRef = this.dialog.open(EditLocationTypeDialogComponent, {
      data: {
        locationType: type,
        isForceAdd: true,
      },
    });

    dialogRef
      .afterClosed()
      .subscribe(async (result: { hasSaved: boolean; newType: ILocationType }) => {
        if (result?.hasSaved) {
          this.newRecordCreated.emit();
          await this.addInboundMapping(index, result?.newType);
        }
      });
  }

  private addNewDocumentType(type: IDocumentType, index: number): void {
    const dialogRef = this.dialog.open(EditDocumentTypeDialogComponent, {
      data: {
        documentType: type,
        isForceAdd: true,
      },
    });

    dialogRef
      .afterClosed()
      .subscribe(async (result: { hasSaved: boolean; newDocumentType: IDocumentType }) => {
        if (result?.hasSaved) {
          this.newRecordCreated.emit();
          await this.addInboundMapping(index, result?.newDocumentType);
        }
      });
  }

  public async addInboundMapping(index: number, newRecord: any = null): Promise<void> {
    try {
      const type = this.sharedData()[index];
      const formControlValue = this.formGroup.controls[index].value;
      const localTypeId = newRecord?.id ?? formControlValue.value;
      const typePath = this.shareRecordType().toLocaleLowerCase().replace("_", "-");
      const inboundUri = `/organisations/${this.activeOrganisationId}/record-sharing/inbound/records/${CommonUtils.getUriId(this.inboundShare().senderUri)}/${typePath}/${type.id}`;
      const localUri = `/organisations/${this.activeOrganisationId}/${typePath}/${localTypeId}`;

      await this.recordSharingService.addInboundMapping(inboundUri, localUri);
      await this.checkRecordExistance();
      this.notificationsService.showSuccess("New mapping added");
    } catch (error) {
      this.notificationsService.showError(error);
    } finally {
      this.inboundMappingChanged.emit();
    }
  }

  public removeMapping(mapping: ICheckExistenceMappedRecordsInfo): void {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          titleTranslatedText: "Delete confirmation",
          contentTranslatedText: `Are you sure you want to delete this mapping?`,
          confirmButtonColor: "danger",
          confirmButtonTranslatedText: "Delete",
          confirmButtonIcon: "delete",
        },
      })
      .afterClosed()
      .subscribe(async (response) => {
        if (response === ConfirmDialogResponseEnum.CONFIRM) {
          await this.delete(mapping.mappingId);
        }
      });
  }

  private async delete(mappingId: string): Promise<void> {
    try {
      await this.recordSharingService.removeInboundMapping(mappingId);
      await this.checkRecordExistance();
      this.notificationsService.showSuccess("Mapping removed");
    } catch (error) {
      this.notificationsService.showError(error);
    } finally {
      this.inboundMappingChanged.emit();
    }
  }

  public onView(record: any): void {
    switch (this.shareRecordType()) {
      case CrossOrgShareDataTypeEnum.LOCATIONS:
        this.router.navigate(
          this.router.getSharedLocationLink(record.id, false, {
            organisationId: CommonUtils.getUriId(this.inboundShare().senderUri),
          }),
        );
        break;
      case CrossOrgShareDataTypeEnum.CONNECTIONS:
        this.router.navigate(
          this.router.getSharedConnectionLink(record.id, false, {
            organisationId: CommonUtils.getUriId(this.inboundShare().senderUri),
          }),
        );
        break;
      case CrossOrgShareDataTypeEnum.DELIVERIES:
        this.router.navigate(
          this.router.getSharedDeliveryLink(record.id, false, {
            organisationId: CommonUtils.getUriId(this.inboundShare().senderUri),
          }),
        );
        break;
      case CrossOrgShareDataTypeEnum.DOCUMENTS:
        this.router.navigate(
          this.router.getSharedDocumentLink(record.id, false, {
            organisationId: CommonUtils.getUriId(this.inboundShare().senderUri),
          }),
        );
        break;
      case CrossOrgShareDataTypeEnum.CERTIFICATES:
        this.router.navigate(
          this.router.getSharedCertificateLink(record.id, false, {
            organisationId: CommonUtils.getUriId(this.inboundShare().senderUri),
          }),
        );
        break;
    }
  }

  private hasLocalMapping(idToCheck: string, list: string): boolean {
    return this.existingMappings().some((item) => item[list].includes(idToCheck));
  }
}
