import { Inject, Injectable } from "@angular/core";

import { APP_CONFIG } from "@shared/tokens";

import { ApiService } from "./api.service";
import { CommonConstants } from "../../constants";
import { AttachmentTypeEnum } from "../../enums";
import { IAttachment, IAttachmentPayload, IConfig } from "../../interfaces";
import { FormUtils } from "../../utils";
import { AuthenticationService } from "../authentication.service";

@Injectable({
  providedIn: "root",
})
export class AttachmentsService {
  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {}

  private getBaseUrl = (): string =>
    `${this.environment.baseUrl}organisations/${this.authenticationService.getActiveOrganisationId()}/attachments`;

  public getAll = async (
    attachmentType: AttachmentTypeEnum = undefined,
    targetUri: string = undefined,
    attachmentUri: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
    targetType: string = undefined,
  ): Promise<IAttachment[]> => {
    const url = `${this.getBaseUrl()}?${FormUtils.addUrlParams({
      attachmentType: attachmentType ? attachmentType.toUpperCase() : undefined,
      targetUri,
      attachmentUri,
      size,
      page,
      sort,
      targetType,
    })}`;

    return await this.apiService.get<IAttachment[]>(url).toPromise();
  };

  public get = async (id: string): Promise<IAttachment> => {
    return await this.apiService.get<IAttachment>(`${this.getBaseUrl()}/${id}`).toPromise();
  };

  public delete = async (id: string): Promise<void> =>
    await this.apiService.delete<void>(`${this.getBaseUrl()}/${id}`).toPromise();

  public create = async (payload: IAttachmentPayload): Promise<IAttachment> =>
    await this.apiService.post<IAttachment>(`${this.getBaseUrl()}`, payload).toPromise();
}
