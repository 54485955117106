import { IndividualMarker } from "@components/shared/map/individual-marker";
import { IGeographicalMapLocation } from "@components/shared/map/supply-chain-map.model";

export class GroupedMarker {
  position: google.maps.LatLngLiteral;

  markers: IndividualMarker[] = [];

  toGroupedMarkerList: GroupedMarker[] = [];

  constructor(markers: IndividualMarker[] = [], position?: google.maps.LatLngLiteral) {
    this.markers = markers;
    this.position = position || markers[0].position;
  }

  get locations(): IGeographicalMapLocation[] {
    return this.markers.map((m) => m.location);
  }

  get toLocations(): IGeographicalMapLocation[] {
    return this.markers.map((m) => m.toLocations).flat();
  }

  buildLinks(groupedMarkers: GroupedMarker[]): void {
    this.toLocations.forEach((toLocation) => {
      const toGroupedMarker = groupedMarkers.find((gm) => gm.locations.includes(toLocation));

      if (toGroupedMarker) {
        this.linkTo(toGroupedMarker);
      }
    });
  }

  private linkTo(groupedMarker: GroupedMarker): void {
    if (this === groupedMarker || this.toGroupedMarkerList.includes(groupedMarker)) {
      return;
    }

    this.toGroupedMarkerList.push(groupedMarker);
  }
}
