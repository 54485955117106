import { ChangeDetectionStrategy, Component, Inject, OnInit, signal } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { RecordStateEnum } from "src/app/shared/enums";
import { IStandard, IStandardType, IStandardWithTypes } from "src/app/shared/interfaces";
import { StandardsService, StandardTypesService } from "src/app/shared/services";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { CustomValidators } from "../../../../shared/validators";

@Component({
  selector: "app-edit-standard-dialog",
  templateUrl: "./edit-standard-dialog.component.html",
  styleUrl: "./edit-standard-dialog.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditStandardDialogComponent implements OnInit {
  public isLoading = signal(false);

  public isEditing = signal(false);

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl(null, [CustomValidators.required]),
  });

  public recordStateEnum = RecordStateEnum;

  private nextTypeId = 0;

  constructor(
    public dialogRef: MatDialogRef<EditStandardDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { standard?: IStandardWithTypes },
    private notificationService: NotificationService,
    private standardsService: StandardsService,
    private standardTypesService: StandardTypesService,
  ) {}

  public ngOnInit(): void {
    this.isEditing.set(!!this.data?.standard?.id);
    if (this.isEditing()) {
      this.formGroup.controls["name"].setValue(this.data.standard.name);

      if (this.data.standard.standardTypes && Array.isArray(this.data.standard.standardTypes)) {
        this.data.standard.standardTypes.forEach((standardType) => {
          const fieldId = standardType.id;
          const fieldName = standardType.fullName;

          if (!this.formGroup.controls[fieldId]) {
            this.formGroup.addControl(
              fieldId,
              new UntypedFormControl(
                { value: null, disabled: standardType.recordState === RecordStateEnum.ARCHIVED },
                [CustomValidators.required],
              ),
            );
          }
          if (this.isEditing()) {
            this.formGroup.controls[fieldId].setValue(fieldName);
          }
        });
      }
    }
  }

  public isSubmitButtonDisabled = (): boolean =>
    this.formGroup.invalid || this.formGroup.pending || !this.formGroup.dirty;

  public onAddStandardType() {
    this.formGroup.addControl(
      `new-type-${this.getNextId()}`,
      new UntypedFormControl(null, [CustomValidators.required]),
    );
  }

  public removeFormControl(key: string) {
    if (this.formGroup.controls[key]) {
      this.formGroup.removeControl(key);
    }
  }

  public getNextId(): number {
    this.nextTypeId++;

    return this.nextTypeId;
  }

  public onSubmit = async (): Promise<void> => {
    this.isLoading.set(true);
    const currentNameValue = this.formGroup.controls["name"].value.trim();

    const editedTypes = {};
    const newTypes = {};

    Object.keys(this.formGroup.controls).forEach((fieldName) => {
      const control = this.formGroup.controls[fieldName];

      if (fieldName.startsWith("new-type-")) {
        newTypes[fieldName] = control.value;
      } else if (control.dirty && fieldName !== "name") {
        editedTypes[fieldName] = control.value;
      }
    });

    try {
      let newStandard: IStandard;

      if (this.formGroup.controls["name"].dirty) {
        const standardPayload: IStandard = {
          id: this.isEditing() ? this.data.standard.id : undefined,
          name: currentNameValue,
        };

        newStandard = await this.standardsService.createOrUpdate(
          standardPayload,
          this.data?.standard?.id,
        );
      }

      Object.keys(editedTypes).forEach(async (typeId) => {
        const editedType: IStandardType = {
          id: typeId,
          fullName: editedTypes[typeId],
        };

        await this.standardTypesService.createOrUpdate(this.data?.standard?.id, editedType, typeId);
      });

      const standardId = this.isEditing() ? this.data?.standard?.id : newStandard.id;

      Object.keys(newTypes).forEach(async (fieldName) => {
        const newType: IStandardType = {
          id: null,
          fullName: newTypes[fieldName],
        };

        await this.standardTypesService.createOrUpdate(standardId, newType, null);
      });

      this.notificationService.showSuccess(`Standard ${this.isEditing() ? "modified" : "created"}`);
      this.onClose(true);
    } catch (error) {
      this.notificationService.showError(error);
    } finally {
      this.isLoading.set(false);
    }
  };

  public toggleArchiveStatus = async (id: string) => {
    const index = this.data?.standard?.standardTypes.findIndex((t) => t.id === id);

    if (index !== -1) {
      try {
        const standardType = { ...this.data?.standard?.standardTypes[index] };
        const isArchived = standardType.recordState === RecordStateEnum.ARCHIVED;

        const updatedStandardType = await this.standardTypesService.setRecordState(
          {
            recordState: isArchived ? RecordStateEnum.ACTIVE : RecordStateEnum.ARCHIVED,
          },
          this.data?.standard.id,
          id,
        );

        standardType.recordState =
          standardType.recordState === RecordStateEnum.ARCHIVED
            ? RecordStateEnum.ACTIVE
            : RecordStateEnum.ARCHIVED;

        this.data?.standard?.standardTypes.splice(index, 1, updatedStandardType);
        // This forces the getTypeRecordState pipe to recognize the change:
        this.data.standard.standardTypes = [...this.data.standard.standardTypes];

        //Disable the formControl for this type:
        isArchived
          ? this.formGroup.controls[id].enable({ emitEvent: false })
          : this.formGroup.controls[id].disable({ emitEvent: false });
        this.notificationService.showSuccess(isArchived ? "Unarchived" : "Archived");
      } catch (e) {
        this.notificationService.showError(e);
      }
    }
  };

  public onClose = (hasSaved = false): void => {
    this.dialogRef.close({ hasSaved });
  };
}
