import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  TemplateRef,
  input,
  output,
  viewChild,
} from "@angular/core";

@Component({
  selector: "app-drag-drop-list-item",
  templateUrl: "./drag-drop-list-item.component.html",
  styleUrl: "./drag-drop-list-item.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DragDropListItemComponent {
  readonly isDeleteDisabled = input(false);

  readonly deleteDisabledText = input("This item cannot be deleted");

  readonly moveUp = output();

  readonly moveDown = output();

  readonly remove = output();

  readonly template = viewChild(TemplateRef<DragDropListItemComponent>, { read: TemplateRef });

  readonly content = viewChild("content", { read: ElementRef });
}
