<div class="container-space-between">
  <app-page-title
    [goBackRoute]="routingEnum.SUPPLY_CHAINS"
    [title]="(orgName ?? 'Full') + ' supply chain'"
    backText="Back to Supply chains"
    hint="This supply chain is based on all of the locations recorded by your organisation."
  >
    >
  </app-page-title>
</div>
@if (isLoading()) {
  <app-loader></app-loader>
} @else {
  @if (allLocations?.length) {
    <div class="overflow-y-hidden">
      <mat-tab-group
        animationDuration="0ms"
        [selectedIndex]="selectedTabIndex"
        (selectedIndexChange)="onTabIndexChange($event)"
        mat-stretch-tabs="false"
        mat-align-tabs="start"
      >
        <mat-tab>
          <ng-template mat-tab-label>Graph</ng-template>
          @if (selectedTabIndex === 0 && supplyChainHeight) {
            <app-supply-chain-flow-chart
              [locations]="allLocations"
              [allDocumentTypes]="allDocumentTypes"
              [locationsRulesetsRecords]="locationsRulesetsRecords"
              [addLocationOptionEnabled]="canAddModifyEntities"
              [onlyDisplayActiveAttachments]="true"
              [links]="allLocationLinks"
              [height]="supplyChainHeight"
            ></app-supply-chain-flow-chart>
          }
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>Map</ng-template>
          @if (selectedTabIndex === 1 && geographicalSupplyChainMap) {
            <app-supply-chain-map [supplyChain]="geographicalSupplyChainMap"></app-supply-chain-map>
          }
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>Locations</ng-template>
          <app-locations-table
            [locations]="allLocations"
            [columns]="[
              'recordState',
              'name',
              'organisationName',
              'types',
              'address.countryName',
              'tags',
            ]"
          ></app-locations-table>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>Certificates</ng-template>
          <app-supply-chain-locations
            [locations]="allLocations"
            [isShowCertificates]="true"
            [areCertificatesButtonsEnabled]="true"
          ></app-supply-chain-locations>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>Documents</ng-template>
          <app-supply-chain-locations
            [locations]="allLocations"
            [allDocumentTypes]="allDocumentTypes"
            [isShowDocuments]="true"
          ></app-supply-chain-locations>
        </mat-tab>
      </mat-tab-group>
    </div>
  } @else {
    <div class="container-flex-center">
      <p>Please record locations to view the organisation supply chain.</p>
      @if (canAddModifyEntities) {
        <div class="supply-chain-buttons right-50">
          <iov-button (pressed)="addLocation()" tp="Add Location" type="filled">
            <mat-icon svgIcon="plus"></mat-icon>
          </iov-button>
        </div>
      }
    </div>
  }
}
