import { ChangeDetectionStrategy, Component } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

import { BaseActionsCellRenderer } from "@shared/cell-renderers/base-actions.cell-renderer";
import { CommonConstants } from "@shared/constants";
import { CellRendererUtils, CommonUtils } from "@shared/utils";

@Component({
  template: `
    <div class="cell-container" [class]="params()['cellContainerClass']">
      @if (params().value?.length) {
        <div class="tags-container" [class.with-buttons]="buttons().length">
          @for (chip of params().value; track $index) {
            @if (hasLink) {
              <iov-form-link
                class="iov-tag"
                [ngClass]="params()['classParam'] ? getChipClass(chip) : ''"
                [link]="getChipLink(chip)"
                [openInNewTab]="params()['openInNewTab'] ?? false"
              >
                <ng-container
                  [ngTemplateOutlet]="chipContent"
                  [ngTemplateOutletContext]="{ chip, chipText: getChipText(chip) }"
                ></ng-container>
              </iov-form-link>
            } @else {
              <div class="iov-tag" [ngClass]="params()['classParam'] ? getChipClass(chip) : ''">
                <ng-container
                  [ngTemplateOutlet]="chipContent"
                  [ngTemplateOutletContext]="{ chip, chipText: getChipText(chip) }"
                ></ng-container>
              </div>
            }
          }
          <ng-template #chipContent let-chip="chip" let-chipText="chipText">
            @if (params()["showIcon"] && params()["showIcon"](chip)) {
              <mat-icon [tpIsLazy]="true" [tp]="params()['iconTooltip'] ?? ''">
                {{ params()["icon"] }}
              </mat-icon>
            }
            <div
              class="text"
              [tpIsLazy]="true"
              [tp]="chipText?.length > MAX_CHIPS_TEXT_LENGTH_TO_SHOW ? chipText : ''"
            >
              {{ chipText }}
            </div>
          </ng-template>
        </div>
      } @else {
        -
      }

      <div class="cell-actions">
        @for (button of buttons(); track button) {
          @if (show(button)) {
            <iov-button
              stopPropagation
              [tp]="tooltip(button)"
              [tpIsLazy]="true"
              [disabled]="isDisabled(button)"
              (pressed)="button.click && button.click(this.data(), $event)"
              type="icon"
            >
              @if (button.svgIcon) {
                <mat-icon
                  [class]="iconClass(button)"
                  icon-small
                  [svgIcon]="button.svgIcon"
                ></mat-icon>
              } @else {
                <mat-icon [class]="iconClass(button)" icon-small>{{ button.icon }}</mat-icon>
              }
            </iov-button>
          }
        }
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./chips.cell-renderer.scss"],
})
export class ChipsCellRendererComponent
  extends BaseActionsCellRenderer
  implements ICellRendererAngularComp
{
  public readonly MAX_CHIPS_TEXT_LENGTH_TO_SHOW = CommonConstants.MAX_CHIPS_TEXT_LENGTH_TO_SHOW;

  public hasLink = false;

  agInit(params: ICellRendererParams): void {
    this.params.set(params);

    this.hasLink = !!(
      this.params()["linkRoute"] ||
      this.params()["linkRouteIdParam"] ||
      this.params()["linkRouteFn"]
    );
  }

  getChipClass = (chip: any): string => {
    return CommonUtils.getObjectNestedProperty(this.params()["classParam"], chip);
  };

  getChipText = (chip: any): string => {
    return typeof chip === "string"
      ? chip
      : CommonUtils.getObjectNestedProperty(this.params()["textParam"], chip);
  };

  getChipLink = (chip: any): any => CellRendererUtils.getLink(this.params(), chip);
}
