<iov-slide-overlay-header
  [canArchive]="false"
  [shouldDisplayTags]="false"
  [isInboundShare]="true"
  [title]="title()"
  icon="organisations"
></iov-slide-overlay-header>
<iov-slide-overlay-content #slideOverlayContent>
  <ng-template
    [overlay-menu-show-edit-button]="false"
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="menu-info"
    overlay-menu-title="Organisation details"
  >
    <iov-form-field-group [name]="mainInformationText">
      <div [formGroup]="formGroup" class="row-container">
        <div class="row">
          <div class="item">
            <iov-input enableViewMode formControlName="name" label="Name"></iov-input>
          </div>
        </div>
        <div class="row">
          <div class="item">
            <iov-input
              enableViewMode
              [viewModeTemplate]="urlTemplate"
              formControlName="website"
              label="Website"
            ></iov-input>
          </div>
        </div>
      </div>
    </iov-form-field-group>
    <iov-form-field-group name="Address">
      <div [formGroup]="formGroup" class="row-container">
        <div class="row">
          <div class="item">
            <iov-input enableViewMode formControlName="street" label="Address"></iov-input>
          </div>
        </div>
        <div class="row">
          <div class="item">
            <iov-input enableViewMode formControlName="region" label="State / Region"></iov-input>
          </div>
        </div>
        <div class="row">
          <div class="item">
            <iov-input enableViewMode formControlName="zipCode" label="ZIP / Postcode"></iov-input>
          </div>
        </div>
        <div class="row">
          <div class="item">
            <iov-input-select
              [options]="countryOptions"
              [viewModeTemplate]="countryViewMode"
              enableViewMode
              formControlName="country"
              label="Country"
            ></iov-input-select>
            <ng-template #countryViewMode let-label="label" let-option>
              <iov-form-input-readonly [label]="label" [value]="option.label">
                <app-flag [country]="option.value" input-readonly-prefix></app-flag>
              </iov-form-input-readonly>
            </ng-template>
          </div>
        </div>
      </div>
    </iov-form-field-group>
  </ng-template>
  <ng-template #urlTemplate let-data>
    <div class="form-field-item hover-visibility-container">
      <iov-form-label [label]="data.label"></iov-form-label>
      <div class="form-field-input-readonly">
        @if (data.value) {
          <div class="one-line-wrap-ellipsis">
            <a
              class="blue-link"
              [href]="data.value"
              target="_blank"
              tpPlacement="top-start"
              [tp]="data.value"
            >
              {{ data.value }}
              <app-external-link-icon></app-external-link-icon>
            </a>
          </div>
        } @else {
          -
        }
      </div>
    </div>
  </ng-template>
  <ng-template
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.DOCUMENTS).isEnabled"
    [overlay-menu-indicator]="element()?.documents?.length"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-title]="overlayTabEnum.DOCUMENTS"
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="document"
  >
    <article class="field-group-container">
      <app-documents-table
        [isInboundShared]="true"
        [documents]="element()?.documents"
        [inboundSharedSenderOrgId]="organisationIdQueryParam"
      ></app-documents-table>
    </article>
  </ng-template>
  <ng-template
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.CERTIFICATES).isEnabled"
    [overlay-menu-indicator]="element()?.certificates?.length"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="overlayTabEnum.CERTIFICATES"
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="certificates"
  >
    <article class="field-group-container">
      <app-certificates-table
        [isInboundShared]="true"
        [inboundSharedSenderOrgId]="organisationIdQueryParam"
        [certificates]="element()?.certificates"
      ></app-certificates-table>
    </article>
  </ng-template>
</iov-slide-overlay-content>
