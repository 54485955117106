export const COUNTRIES_COORDINATES: {
  country: string;
  lat: number;
  lng: number;
  name: string;
}[] = [
  {
    country: "AD",
    lat: 42.546245,
    lng: 1.601554,
    name: "Andorra",
  },
  {
    country: "AE",
    lat: 23.424076,
    lng: 53.847818,
    name: "United Arab Emirates",
  },
  {
    country: "AF",
    lat: 33.93911,
    lng: 67.709953,
    name: "Afghanistan",
  },
  {
    country: "AG",
    lat: 17.060816,
    lng: -61.796428,
    name: "Antigua and Barbuda",
  },
  {
    country: "AI",
    lat: 18.220554,
    lng: -63.068615,
    name: "Anguilla",
  },
  {
    country: "AL",
    lat: 41.153332,
    lng: 20.168331,
    name: "Albania",
  },
  {
    country: "AM",
    lat: 40.069099,
    lng: 45.038189,
    name: "Armenia",
  },
  {
    country: "AN",
    lat: 12.226079,
    lng: -69.060087,
    name: "Netherlands Antilles",
  },
  {
    country: "AO",
    lat: -11.202692,
    lng: 17.873887,
    name: "Angola",
  },
  {
    country: "AQ",
    lat: -75.250973,
    lng: -0.071389,
    name: "Antarctica",
  },
  {
    country: "AR",
    lat: -38.416097,
    lng: -63.616672,
    name: "Argentina",
  },
  {
    country: "AS",
    lat: -14.270972,
    lng: -170.132217,
    name: "American Samoa",
  },
  {
    country: "AT",
    lat: 47.516231,
    lng: 14.550072,
    name: "Austria",
  },
  {
    country: "AU",
    lat: -25.274398,
    lng: 133.775136,
    name: "Australia",
  },
  {
    country: "AW",
    lat: 12.52111,
    lng: -69.968338,
    name: "Aruba",
  },
  {
    country: "AZ",
    lat: 40.143105,
    lng: 47.576927,
    name: "Azerbaijan",
  },
  {
    country: "BA",
    lat: 43.915886,
    lng: 17.679076,
    name: "Bosnia and Herzegovina",
  },
  {
    country: "BB",
    lat: 13.193887,
    lng: -59.543198,
    name: "Barbados",
  },
  {
    country: "BD",
    lat: 23.684994,
    lng: 90.356331,
    name: "Bangladesh",
  },
  {
    country: "BE",
    lat: 50.503887,
    lng: 4.469936,
    name: "Belgium",
  },
  {
    country: "BF",
    lat: 12.238333,
    lng: -1.561593,
    name: "Burkina Faso",
  },
  {
    country: "BG",
    lat: 42.733883,
    lng: 25.48583,
    name: "Bulgaria",
  },
  {
    country: "BH",
    lat: 25.930414,
    lng: 50.637772,
    name: "Bahrain",
  },
  {
    country: "BI",
    lat: -3.373056,
    lng: 29.918886,
    name: "Burundi",
  },
  {
    country: "BJ",
    lat: 9.30769,
    lng: 2.315834,
    name: "Benin",
  },
  {
    country: "BM",
    lat: 32.321384,
    lng: -64.75737,
    name: "Bermuda",
  },
  {
    country: "BN",
    lat: 4.535277,
    lng: 114.727669,
    name: "Brunei",
  },
  {
    country: "BO",
    lat: -16.290154,
    lng: -63.588653,
    name: "Bolivia",
  },
  {
    country: "BR",
    lat: -14.235004,
    lng: -51.92528,
    name: "Brazil",
  },
  {
    country: "BS",
    lat: 25.03428,
    lng: -77.39628,
    name: "Bahamas",
  },
  {
    country: "BT",
    lat: 27.514162,
    lng: 90.433601,
    name: "Bhutan",
  },
  {
    country: "BV",
    lat: -54.423199,
    lng: 3.413194,
    name: "Bouvet Island",
  },
  {
    country: "BW",
    lat: -22.328474,
    lng: 24.684866,
    name: "Botswana",
  },
  {
    country: "BY",
    lat: 53.709807,
    lng: 27.953389,
    name: "Belarus",
  },
  {
    country: "BZ",
    lat: 17.189877,
    lng: -88.49765,
    name: "Belize",
  },
  {
    country: "CA",
    lat: 56.130366,
    lng: -106.346771,
    name: "Canada",
  },
  {
    country: "CC",
    lat: -12.164165,
    lng: 96.870956,
    name: "Cocos [Keeling] Islands",
  },
  {
    country: "CD",
    lat: -4.038333,
    lng: 21.758664,
    name: "Congo [DRC]",
  },
  {
    country: "CF",
    lat: 6.611111,
    lng: 20.939444,
    name: "Central African Republic",
  },
  {
    country: "CG",
    lat: -0.228021,
    lng: 15.827659,
    name: "Congo [Republic]",
  },
  {
    country: "CH",
    lat: 46.818188,
    lng: 8.227512,
    name: "Switzerland",
  },
  {
    country: "CI",
    lat: 7.539989,
    lng: -5.54708,
    name: "Côte d'Ivoire",
  },
  {
    country: "CK",
    lat: -21.236736,
    lng: -159.777671,
    name: "Cook Islands",
  },
  {
    country: "CL",
    lat: -35.675147,
    lng: -71.542969,
    name: "Chile",
  },
  {
    country: "CM",
    lat: 7.369722,
    lng: 12.354722,
    name: "Cameroon",
  },
  {
    country: "CN",
    lat: 35.86166,
    lng: 104.195397,
    name: "China",
  },
  {
    country: "CO",
    lat: 4.570868,
    lng: -74.297333,
    name: "Colombia",
  },
  {
    country: "CR",
    lat: 9.748917,
    lng: -83.753428,
    name: "Costa Rica",
  },
  {
    country: "CU",
    lat: 21.521757,
    lng: -77.781167,
    name: "Cuba",
  },
  {
    country: "CV",
    lat: 16.002082,
    lng: -24.013197,
    name: "Cape Verde",
  },
  {
    country: "CX",
    lat: -10.447525,
    lng: 105.690449,
    name: "Christmas Island",
  },
  {
    country: "CY",
    lat: 35.126413,
    lng: 33.429859,
    name: "Cyprus",
  },
  {
    country: "CZ",
    lat: 49.817492,
    lng: 15.472962,
    name: "Czech Republic",
  },
  {
    country: "DE",
    lat: 51.165691,
    lng: 10.451526,
    name: "Germany",
  },
  {
    country: "DJ",
    lat: 11.825138,
    lng: 42.590275,
    name: "Djibouti",
  },
  {
    country: "DK",
    lat: 56.26392,
    lng: 9.501785,
    name: "Denmark",
  },
  {
    country: "DM",
    lat: 15.414999,
    lng: -61.370976,
    name: "Dominica",
  },
  {
    country: "DO",
    lat: 18.735693,
    lng: -70.162651,
    name: "Dominican Republic",
  },
  {
    country: "DZ",
    lat: 28.033886,
    lng: 1.659626,
    name: "Algeria",
  },
  {
    country: "EC",
    lat: -1.831239,
    lng: -78.183406,
    name: "Ecuador",
  },
  {
    country: "EE",
    lat: 58.595272,
    lng: 25.013607,
    name: "Estonia",
  },
  {
    country: "EG",
    lat: 26.820553,
    lng: 30.802498,
    name: "Egypt",
  },
  {
    country: "EH",
    lat: 24.215527,
    lng: -12.885834,
    name: "Western Sahara",
  },
  {
    country: "ER",
    lat: 15.179384,
    lng: 39.782334,
    name: "Eritrea",
  },
  {
    country: "ES",
    lat: 40.463667,
    lng: -3.74922,
    name: "Spain",
  },
  {
    country: "ET",
    lat: 9.145,
    lng: 40.489673,
    name: "Ethiopia",
  },
  {
    country: "FI",
    lat: 61.92411,
    lng: 25.748151,
    name: "Finland",
  },
  {
    country: "FJ",
    lat: -16.578193,
    lng: 179.414413,
    name: "Fiji",
  },
  {
    country: "FK",
    lat: -51.796253,
    lng: -59.523613,
    name: "Falkland Islands [Islas Malvinas]",
  },
  {
    country: "FM",
    lat: 7.425554,
    lng: 150.550812,
    name: "Micronesia",
  },
  {
    country: "FO",
    lat: 61.892635,
    lng: -6.911806,
    name: "Faroe Islands",
  },
  {
    country: "FR",
    lat: 46.227638,
    lng: 2.213749,
    name: "France",
  },
  {
    country: "GA",
    lat: -0.803689,
    lng: 11.609444,
    name: "Gabon",
  },
  {
    country: "GB",
    lat: 55.378051,
    lng: -3.435973,
    name: "United Kingdom",
  },
  {
    country: "GD",
    lat: 12.262776,
    lng: -61.604171,
    name: "Grenada",
  },
  {
    country: "GE",
    lat: 42.315407,
    lng: 43.356892,
    name: "Georgia",
  },
  {
    country: "GF",
    lat: 3.933889,
    lng: -53.125782,
    name: "French Guiana",
  },
  {
    country: "GG",
    lat: 49.465691,
    lng: -2.585278,
    name: "Guernsey",
  },
  {
    country: "GH",
    lat: 7.946527,
    lng: -1.023194,
    name: "Ghana",
  },
  {
    country: "GI",
    lat: 36.137741,
    lng: -5.345374,
    name: "Gibraltar",
  },
  {
    country: "GL",
    lat: 71.706936,
    lng: -42.604303,
    name: "Greenland",
  },
  {
    country: "GM",
    lat: 13.443182,
    lng: -15.310139,
    name: "Gambia",
  },
  {
    country: "GN",
    lat: 9.945587,
    lng: -9.696645,
    name: "Guinea",
  },
  {
    country: "GP",
    lat: 16.995971,
    lng: -62.067641,
    name: "Guadeloupe",
  },
  {
    country: "GQ",
    lat: 1.650801,
    lng: 10.267895,
    name: "Equatorial Guinea",
  },
  {
    country: "GR",
    lat: 39.074208,
    lng: 21.824312,
    name: "Greece",
  },
  {
    country: "GS",
    lat: -54.429579,
    lng: -36.587909,
    name: "South Georgia and the South Sandwich Islands",
  },
  {
    country: "GT",
    lat: 15.783471,
    lng: -90.230759,
    name: "Guatemala",
  },
  {
    country: "GU",
    lat: 13.444304,
    lng: 144.793731,
    name: "Guam",
  },
  {
    country: "GW",
    lat: 11.803749,
    lng: -15.180413,
    name: "Guinea-Bissau",
  },
  {
    country: "GY",
    lat: 4.860416,
    lng: -58.93018,
    name: "Guyana",
  },
  {
    country: "GZ",
    lat: 31.354676,
    lng: 34.308825,
    name: "Gaza Strip",
  },
  {
    country: "HK",
    lat: 22.396428,
    lng: 114.109497,
    name: "Hong Kong",
  },
  {
    country: "HM",
    lat: -53.08181,
    lng: 73.504158,
    name: "Heard Island and McDonald Islands",
  },
  {
    country: "HN",
    lat: 15.199999,
    lng: -86.241905,
    name: "Honduras",
  },
  {
    country: "HR",
    lat: 45.1,
    lng: 15.2,
    name: "Croatia",
  },
  {
    country: "HT",
    lat: 18.971187,
    lng: -72.285215,
    name: "Haiti",
  },
  {
    country: "HU",
    lat: 47.162494,
    lng: 19.503304,
    name: "Hungary",
  },
  {
    country: "ID",
    lat: -0.789275,
    lng: 113.921327,
    name: "Indonesia",
  },
  {
    country: "IE",
    lat: 53.41291,
    lng: -8.24389,
    name: "Ireland",
  },
  {
    country: "IL",
    lat: 31.046051,
    lng: 34.851612,
    name: "Israel",
  },
  {
    country: "IM",
    lat: 54.236107,
    lng: -4.548056,
    name: "Isle of Man",
  },
  {
    country: "IN",
    lat: 20.593684,
    lng: 78.96288,
    name: "India",
  },
  {
    country: "IO",
    lat: -6.343194,
    lng: 71.876519,
    name: "British Indian Ocean Territory",
  },
  {
    country: "IQ",
    lat: 33.223191,
    lng: 43.679291,
    name: "Iraq",
  },
  {
    country: "IR",
    lat: 32.427908,
    lng: 53.688046,
    name: "Iran",
  },
  {
    country: "IS",
    lat: 64.963051,
    lng: -19.020835,
    name: "Iceland",
  },
  {
    country: "IT",
    lat: 41.87194,
    lng: 12.56738,
    name: "Italy",
  },
  {
    country: "JE",
    lat: 49.214439,
    lng: -2.13125,
    name: "Jersey",
  },
  {
    country: "JM",
    lat: 18.109581,
    lng: -77.297508,
    name: "Jamaica",
  },
  {
    country: "JO",
    lat: 30.585164,
    lng: 36.238414,
    name: "Jordan",
  },
  {
    country: "JP",
    lat: 36.204824,
    lng: 138.252924,
    name: "Japan",
  },
  {
    country: "KE",
    lat: -0.023559,
    lng: 37.906193,
    name: "Kenya",
  },
  {
    country: "KG",
    lat: 41.20438,
    lng: 74.766098,
    name: "Kyrgyzstan",
  },
  {
    country: "KH",
    lat: 12.565679,
    lng: 104.990963,
    name: "Cambodia",
  },
  {
    country: "KI",
    lat: -3.370417,
    lng: -168.734039,
    name: "Kiribati",
  },
  {
    country: "KM",
    lat: -11.875001,
    lng: 43.872219,
    name: "Comoros",
  },
  {
    country: "KN",
    lat: 17.357822,
    lng: -62.782998,
    name: "Saint Kitts and Nevis",
  },
  {
    country: "KP",
    lat: 40.339852,
    lng: 127.510093,
    name: "North Korea",
  },
  {
    country: "KR",
    lat: 35.907757,
    lng: 127.766922,
    name: "South Korea",
  },
  {
    country: "KW",
    lat: 29.31166,
    lng: 47.481766,
    name: "Kuwait",
  },
  {
    country: "KY",
    lat: 19.513469,
    lng: -80.566956,
    name: "Cayman Islands",
  },
  {
    country: "KZ",
    lat: 48.019573,
    lng: 66.923684,
    name: "Kazakhstan",
  },
  {
    country: "LA",
    lat: 19.85627,
    lng: 102.495496,
    name: "Laos",
  },
  {
    country: "LB",
    lat: 33.854721,
    lng: 35.862285,
    name: "Lebanon",
  },
  {
    country: "LC",
    lat: 13.909444,
    lng: -60.978893,
    name: "Saint Lucia",
  },
  {
    country: "LI",
    lat: 47.166,
    lng: 9.555373,
    name: "Liechtenstein",
  },
  {
    country: "LK",
    lat: 7.873054,
    lng: 80.771797,
    name: "Sri Lanka",
  },
  {
    country: "LR",
    lat: 6.428055,
    lng: -9.429499,
    name: "Liberia",
  },
  {
    country: "LS",
    lat: -29.609988,
    lng: 28.233608,
    name: "Lesotho",
  },
  {
    country: "LT",
    lat: 55.169438,
    lng: 23.881275,
    name: "Lithuania",
  },
  {
    country: "LU",
    lat: 49.815273,
    lng: 6.129583,
    name: "Luxembourg",
  },
  {
    country: "LV",
    lat: 56.879635,
    lng: 24.603189,
    name: "Latvia",
  },
  {
    country: "LY",
    lat: 26.3351,
    lng: 17.228331,
    name: "Libya",
  },
  {
    country: "MA",
    lat: 31.791702,
    lng: -7.09262,
    name: "Morocco",
  },
  {
    country: "MC",
    lat: 43.750298,
    lng: 7.412841,
    name: "Monaco",
  },
  {
    country: "MD",
    lat: 47.411631,
    lng: 28.369885,
    name: "Moldova",
  },
  {
    country: "ME",
    lat: 42.708678,
    lng: 19.37439,
    name: "Montenegro",
  },
  {
    country: "MG",
    lat: -18.766947,
    lng: 46.869107,
    name: "Madagascar",
  },
  {
    country: "MH",
    lat: 7.131474,
    lng: 171.184478,
    name: "Marshall Islands",
  },
  {
    country: "MK",
    lat: 41.608635,
    lng: 21.745275,
    name: "Macedonia [FYROM]",
  },
  {
    country: "ML",
    lat: 17.570692,
    lng: -3.996166,
    name: "Mali",
  },
  {
    country: "MM",
    lat: 21.913965,
    lng: 95.956223,
    name: "Myanmar [Burma]",
  },
  {
    country: "MN",
    lat: 46.862496,
    lng: 103.846656,
    name: "Mongolia",
  },
  {
    country: "MO",
    lat: 22.198745,
    lng: 113.543873,
    name: "Macau",
  },
  {
    country: "MP",
    lat: 17.33083,
    lng: 145.38469,
    name: "Northern Mariana Islands",
  },
  {
    country: "MQ",
    lat: 14.641528,
    lng: -61.024174,
    name: "Martinique",
  },
  {
    country: "MR",
    lat: 21.00789,
    lng: -10.940835,
    name: "Mauritania",
  },
  {
    country: "MS",
    lat: 16.742498,
    lng: -62.187366,
    name: "Montserrat",
  },
  {
    country: "MT",
    lat: 35.937496,
    lng: 14.375416,
    name: "Malta",
  },
  {
    country: "MU",
    lat: -20.348404,
    lng: 57.552152,
    name: "Mauritius",
  },
  {
    country: "MV",
    lat: 3.202778,
    lng: 73.22068,
    name: "Maldives",
  },
  {
    country: "MW",
    lat: -13.254308,
    lng: 34.301525,
    name: "Malawi",
  },
  {
    country: "MX",
    lat: 23.634501,
    lng: -102.552784,
    name: "Mexico",
  },
  {
    country: "MY",
    lat: 4.210484,
    lng: 101.975766,
    name: "Malaysia",
  },
  {
    country: "MZ",
    lat: -18.665695,
    lng: 35.529562,
    name: "Mozambique",
  },
  {
    country: "NA",
    lat: -22.95764,
    lng: 18.49041,
    name: "Namibia",
  },
  {
    country: "NC",
    lat: -20.904305,
    lng: 165.618042,
    name: "New Caledonia",
  },
  {
    country: "NE",
    lat: 17.607789,
    lng: 8.081666,
    name: "Niger",
  },
  {
    country: "NF",
    lat: -29.040835,
    lng: 167.954712,
    name: "Norfolk Island",
  },
  {
    country: "NG",
    lat: 9.081999,
    lng: 8.675277,
    name: "Nigeria",
  },
  {
    country: "NI",
    lat: 12.865416,
    lng: -85.207229,
    name: "Nicaragua",
  },
  {
    country: "NL",
    lat: 52.132633,
    lng: 5.291266,
    name: "Netherlands",
  },
  {
    country: "NO",
    lat: 60.472024,
    lng: 8.468946,
    name: "Norway",
  },
  {
    country: "NP",
    lat: 28.394857,
    lng: 84.124008,
    name: "Nepal",
  },
  {
    country: "NR",
    lat: -0.522778,
    lng: 166.931503,
    name: "Nauru",
  },
  {
    country: "NU",
    lat: -19.054445,
    lng: -169.867233,
    name: "Niue",
  },
  {
    country: "NZ",
    lat: -40.900557,
    lng: 174.885971,
    name: "New Zealand",
  },
  {
    country: "OM",
    lat: 21.512583,
    lng: 55.923255,
    name: "Oman",
  },
  {
    country: "PA",
    lat: 8.537981,
    lng: -80.782127,
    name: "Panama",
  },
  {
    country: "PE",
    lat: -9.189967,
    lng: -75.015152,
    name: "Peru",
  },
  {
    country: "PF",
    lat: -17.679742,
    lng: -149.406843,
    name: "French Polynesia",
  },
  {
    country: "PG",
    lat: -6.314993,
    lng: 143.95555,
    name: "Papua New Guinea",
  },
  {
    country: "PH",
    lat: 12.879721,
    lng: 121.774017,
    name: "Philippines",
  },
  {
    country: "PK",
    lat: 30.375321,
    lng: 69.345116,
    name: "Pakistan",
  },
  {
    country: "PL",
    lat: 51.919438,
    lng: 19.145136,
    name: "Poland",
  },
  {
    country: "PM",
    lat: 46.941936,
    lng: -56.27111,
    name: "Saint Pierre and Miquelon",
  },
  {
    country: "PN",
    lat: -24.703615,
    lng: -127.439308,
    name: "Pitcairn Islands",
  },
  {
    country: "PR",
    lat: 18.220833,
    lng: -66.590149,
    name: "Puerto Rico",
  },
  {
    country: "PS",
    lat: 31.952162,
    lng: 35.233154,
    name: "Palestinian Territories",
  },
  {
    country: "PT",
    lat: 39.399872,
    lng: -8.224454,
    name: "Portugal",
  },
  {
    country: "PW",
    lat: 7.51498,
    lng: 134.58252,
    name: "Palau",
  },
  {
    country: "PY",
    lat: -23.442503,
    lng: -58.443832,
    name: "Paraguay",
  },
  {
    country: "QA",
    lat: 25.354826,
    lng: 51.183884,
    name: "Qatar",
  },
  {
    country: "RE",
    lat: -21.115141,
    lng: 55.536384,
    name: "Réunion",
  },
  {
    country: "RO",
    lat: 45.943161,
    lng: 24.96676,
    name: "Romania",
  },
  {
    country: "RS",
    lat: 44.016521,
    lng: 21.005859,
    name: "Serbia",
  },
  {
    country: "RU",
    lat: 61.52401,
    lng: 105.318756,
    name: "Russia",
  },
  {
    country: "RW",
    lat: -1.940278,
    lng: 29.873888,
    name: "Rwanda",
  },
  {
    country: "SA",
    lat: 23.885942,
    lng: 45.079162,
    name: "Saudi Arabia",
  },
  {
    country: "SB",
    lat: -9.64571,
    lng: 160.156194,
    name: "Solomon Islands",
  },
  {
    country: "SC",
    lat: -4.679574,
    lng: 55.491977,
    name: "Seychelles",
  },
  {
    country: "SD",
    lat: 12.862807,
    lng: 30.217636,
    name: "Sudan",
  },
  {
    country: "SE",
    lat: 60.128161,
    lng: 18.643501,
    name: "Sweden",
  },
  {
    country: "SG",
    lat: 1.352083,
    lng: 103.819836,
    name: "Singapore",
  },
  {
    country: "SH",
    lat: -24.143474,
    lng: -10.030696,
    name: "Saint Helena",
  },
  {
    country: "SI",
    lat: 46.151241,
    lng: 14.995463,
    name: "Slovenia",
  },
  {
    country: "SJ",
    lat: 77.553604,
    lng: 23.670272,
    name: "Svalbard and Jan Mayen",
  },
  {
    country: "SK",
    lat: 48.669026,
    lng: 19.699024,
    name: "Slovakia",
  },
  {
    country: "SL",
    lat: 8.460555,
    lng: -11.779889,
    name: "Sierra Leone",
  },
  {
    country: "SM",
    lat: 43.94236,
    lng: 12.457777,
    name: "San Marino",
  },
  {
    country: "SN",
    lat: 14.497401,
    lng: -14.452362,
    name: "Senegal",
  },
  {
    country: "SO",
    lat: 5.152149,
    lng: 46.199616,
    name: "Somalia",
  },
  {
    country: "SR",
    lat: 3.919305,
    lng: -56.027783,
    name: "Suriname",
  },
  {
    country: "ST",
    lat: 0.18636,
    lng: 6.613081,
    name: "São Tomé and Príncipe",
  },
  {
    country: "SV",
    lat: 13.794185,
    lng: -88.89653,
    name: "El Salvador",
  },
  {
    country: "SY",
    lat: 34.802075,
    lng: 38.996815,
    name: "Syria",
  },
  {
    country: "SZ",
    lat: -26.522503,
    lng: 31.465866,
    name: "Swaziland",
  },
  {
    country: "TC",
    lat: 21.694025,
    lng: -71.797928,
    name: "Turks and Caicos Islands",
  },
  {
    country: "TD",
    lat: 15.454166,
    lng: 18.732207,
    name: "Chad",
  },
  {
    country: "TF",
    lat: -49.280366,
    lng: 69.348557,
    name: "French Southern Territories",
  },
  {
    country: "TG",
    lat: 8.619543,
    lng: 0.824782,
    name: "Togo",
  },
  {
    country: "TH",
    lat: 15.870032,
    lng: 100.992541,
    name: "Thailand",
  },
  {
    country: "TJ",
    lat: 38.861034,
    lng: 71.276093,
    name: "Tajikistan",
  },
  {
    country: "TK",
    lat: -8.967363,
    lng: -171.855881,
    name: "Tokelau",
  },
  {
    country: "TL",
    lat: -8.874217,
    lng: 125.727539,
    name: "Timor-Leste",
  },
  {
    country: "TM",
    lat: 38.969719,
    lng: 59.556278,
    name: "Turkmenistan",
  },
  {
    country: "TN",
    lat: 33.886917,
    lng: 9.537499,
    name: "Tunisia",
  },
  {
    country: "TO",
    lat: -21.178986,
    lng: -175.198242,
    name: "Tonga",
  },
  {
    country: "TR",
    lat: 38.963745,
    lng: 35.243322,
    name: "Turkey",
  },
  {
    country: "TT",
    lat: 10.691803,
    lng: -61.222503,
    name: "Trinidad and Tobago",
  },
  {
    country: "TV",
    lat: -7.109535,
    lng: 177.64933,
    name: "Tuvalu",
  },
  {
    country: "TW",
    lat: 23.69781,
    lng: 120.960515,
    name: "Taiwan",
  },
  {
    country: "TZ",
    lat: -6.369028,
    lng: 34.888822,
    name: "Tanzania",
  },
  {
    country: "UA",
    lat: 48.379433,
    lng: 31.16558,
    name: "Ukraine",
  },
  {
    country: "UG",
    lat: 1.373333,
    lng: 32.290275,
    name: "Uganda",
  },
  {
    country: "US",
    lat: 37.09024,
    lng: -95.712891,
    name: "United States",
  },
  {
    country: "UY",
    lat: -32.522779,
    lng: -55.765835,
    name: "Uruguay",
  },
  {
    country: "UZ",
    lat: 41.377491,
    lng: 64.585262,
    name: "Uzbekistan",
  },
  {
    country: "VA",
    lat: 41.902916,
    lng: 12.453389,
    name: "Vatican City",
  },
  {
    country: "VC",
    lat: 12.984305,
    lng: -61.287228,
    name: "Saint Vincent and the Grenadines",
  },
  {
    country: "VE",
    lat: 6.42375,
    lng: -66.58973,
    name: "Venezuela",
  },
  {
    country: "VG",
    lat: 18.420695,
    lng: -64.639968,
    name: "British Virgin Islands",
  },
  {
    country: "VI",
    lat: 18.335765,
    lng: -64.896335,
    name: "U.S. Virgin Islands",
  },
  {
    country: "VN",
    lat: 14.058324,
    lng: 108.277199,
    name: "Vietnam",
  },
  {
    country: "VU",
    lat: -15.376706,
    lng: 166.959158,
    name: "Vanuatu",
  },
  {
    country: "WF",
    lat: -13.768752,
    lng: -177.156097,
    name: "Wallis and Futuna",
  },
  {
    country: "WS",
    lat: -13.759029,
    lng: -172.104629,
    name: "Samoa",
  },
  {
    country: "XK",
    lat: 42.602636,
    lng: 20.902977,
    name: "Kosovo",
  },
  {
    country: "YE",
    lat: 15.552727,
    lng: 48.516388,
    name: "Yemen",
  },
  {
    country: "YT",
    lat: -12.8275,
    lng: 45.166244,
    name: "Mayotte",
  },
  {
    country: "ZA",
    lat: -30.559482,
    lng: 22.937506,
    name: "South Africa",
  },
  {
    country: "ZM",
    lat: -13.133897,
    lng: 27.849332,
    name: "Zambia",
  },
  {
    country: "ZW",
    lat: -19.015438,
    lng: 29.154857,
    name: "Zimbabwe",
  },
];
