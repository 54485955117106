<div class="container-space-between">
  <app-page-title
    title="API keys"
    backText="Back to User settings"
    [isGoBackUrlHistory]="true"
  ></app-page-title>

  <iov-button-group>
    <iov-button (pressed)="onAdd()">
      <mat-icon icon-left>add</mat-icon>
      Add new
    </iov-button>
  </iov-button-group>
</div>

<app-api-keys-table
  [isSaveTableState]="true"
  [isFixedBottomPaginator]="true"
  #apiKeysTable
></app-api-keys-table>
