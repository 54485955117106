<div class="row-container row row-with-index" [formGroup]="formGroup">
  <div class="index">
    {{ index + 1 }}
  </div>

  <iov-input
    formControlName="scientificName"
    tooltipIcon="help"
    iconClass="warn-icon"
    tooltip="Value taken from the custom field ‘Scientific name’ on the material. Please ensure such a field exists and is filled in."
    [label]="reportsEudrModel.MaterialCustomFieldEnum.SCIENTIFIC_NAME"
  ></iov-input>
  <iov-input
    formControlName="commonName"
    tooltipIcon="help"
    iconClass="warn-icon"
    tooltip="Value taken from the custom field ‘Common name’ on the material. Please ensure such a field exists and is filled in."
    [label]="reportsEudrModel.MaterialCustomFieldEnum.COMMON_NAME"
  ></iov-input>
</div>
