import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { RoutingEnum } from "src/app/shared/enums";
import { AuthenticationService, AuthService } from "src/app/shared/services";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

@Component({
  templateUrl: "./verify.component.html",
  styleUrls: ["./verify.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerifyComponent implements OnInit {
  constructor(
    public authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private authService: AuthService,
  ) {}

  public async ngOnInit(): Promise<void> {
    const registrationId = this.route.snapshot.queryParams["rid"];
    const registrationCode = this.route.snapshot.queryParams["t"];
    const registrationEmail = this.route.snapshot.queryParams["email"];

    if (!registrationId || !registrationCode) {
      this.notificationService.showError("Missing verification parameters");
      await this.router.navigate([`/${RoutingEnum.LOGIN}`]);

      return;
    }

    await this.authService
      .verifyOrganisation(registrationId, registrationCode)
      .then(async () => {
        this.notificationService.showSuccess("Organisation verified");
        await this.router.navigate([`/${RoutingEnum.LOGIN}`], {
          state: { email: registrationEmail },
        });
      })
      .catch(async (error: HttpErrorResponse) => {
        this.notificationService.showError(error);
        await this.router.navigate([`/${RoutingEnum.LOGIN}`]);
      });
  }
}
