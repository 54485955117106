<div [hidden]="overlayHidden()" class="slide-over">
  <div (click)="dismiss()" (keyup.escape)="dismiss()" class="overlay" tabindex="0"></div>
  <div [@slideOver]="contentHidden() ? 'closed' : 'open'" class="slide-content">
    <iov-button (click)="dismiss()" class="slide-button-close" type="icon">
      <mat-icon svgIcon="close"></mat-icon>
    </iov-button>
    @if (!isClosingOverlay && isShowGoBackButton) {
      <iov-button (click)="onBack()" class="slide-button-back">
        <mat-icon svgIcon="arrow_back" class="icon"></mat-icon>
        Go back
      </iov-button>
    }
    <div class="slide-form-container">
      <ng-template #dynamicContent iovBackNavigation [canExitFn]="canExit.bind(this)"></ng-template>
    </div>
  </div>
</div>
