<div class="files-container">
  @for (file of files; let index = $index; track index) {
    <div class="filename">
      <div class="left-content">
        <mat-icon class="icon" svgIcon="document"></mat-icon>
        <span
          class="clickable"
          tp="View file"
          tabindex="0"
          (click)="onClickViewFileEvent(index)"
          (keyup.enter)="onClickViewFileEvent(index)"
        >
          {{ file.info.name }}
        </span>
      </div>
      <div class="right-content">
        <iov-button
          (click)="onClickDeleteFileEvent(index)"
          class="no-border"
          tp="Remove document"
          size="medium"
          type="icon"
        >
          <mat-icon>delete</mat-icon>
        </iov-button>
      </div>
    </div>
    <div class="file hover-visibility-container" [formGroup]="file.formGroup">
      <div class="general-details">
        <iov-input
          class="flex-grow-1"
          formControlName="name"
          label="Document Name"
          [additionalErrors]="validationError(file)"
        ></iov-input>

        <iov-input-select
          label="Type"
          formControlName="type"
          [addOnWrite]="true"
          [options]="documentTypeOptions"
          [tooltip]="
            'Determines which type of document this is. E.g. Delivery order, Purchase contract, etc.'
          "
          class="flex-grow-1"
        ></iov-input-select>
      </div>
      <iov-form-label label="Tags"></iov-form-label>
      <app-entity-tags
        (tagsChanged)="onTagsChanged(file, $event)"
        [entityType]="entityTypeEnum.DOCUMENTS"
        [setEditMode]="true"
      ></app-entity-tags>
      <div class="date-details">
        <div>
          <iov-checkbox
            formControlName="isDatesEnabled"
            label="Specify issuance date and/or validity period"
          ></iov-checkbox>
        </div>
        @if (file.formGroup.controls["isDatesEnabled"].value) {
          <div class="dates">
            <iov-datepicker
              label="Issuance date"
              formControlName="issuance"
              [maxDate]="
                file.formGroup.controls['validityStart'].value ??
                file.formGroup.controls['validityEnd'].value
              "
              class="datepicker"
            ></iov-datepicker>
            <iov-datepicker
              label="Valid from"
              formControlName="validityStart"
              [minDate]="file.formGroup.controls['issuance'].value"
              [maxDate]="file.formGroup.controls['validityEnd'].value"
              class="datepicker"
            ></iov-datepicker>
            <iov-datepicker
              label="Valid to"
              formControlName="validityEnd"
              [minDate]="
                file.formGroup.controls['validityStart'].value ??
                file.formGroup.controls['issuance'].value
              "
              class="datepicker"
            ></iov-datepicker>
          </div>
        }
      </div>
    </div>
  }
</div>
