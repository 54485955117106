import { Inject, Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { APP_CONFIG } from "@shared/tokens";

import { ApiService } from "./api.service";
import { CommonConstants } from "../../constants";
import { RecordStateEnum } from "../../enums";
import {
  IConfig,
  IDocumentType,
  IDocumentTypePayload,
  IPageableContent,
  IRecordState,
} from "../../interfaces";
import { FormUtils } from "../../utils";
import { AuthenticationService } from "../authentication.service";

@Injectable({
  providedIn: "root",
})
export class DocumentTypesService {
  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {}

  public get baseUrl(): string {
    return `${this.environment.baseUrl}organisations/${this.authenticationService.getActiveOrganisationId()}/document-types`;
  }

  public getPage = async (
    name: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
    recordState: RecordStateEnum = undefined,
  ): Promise<IPageableContent<IDocumentType>> => {
    const url = `${this.baseUrl}?${FormUtils.addUrlParams({ name, size, page, sort, recordState })}`;

    return await this.apiService.get<IPageableContent<IDocumentType>>(url).toPromise();
  };

  public getPageSubscription = (
    name: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
    recordState: RecordStateEnum = undefined,
  ): Observable<IPageableContent<IDocumentType>> => {
    const url = `${this.baseUrl}?${FormUtils.addUrlParams({ name, size, page, sort, recordState })}`;

    return this.apiService.get<IPageableContent<IDocumentType>>(url);
  };

  public async getAll(recordState: RecordStateEnum = undefined): Promise<IDocumentType[]> {
    const result: IDocumentType[] = [];
    let page = 0;
    let hasNextPage: boolean = true;

    while (hasNextPage) {
      const {
        content,
        page: { totalElements },
      } = await this.getPage(
        undefined,
        CommonConstants.MAX_API_GET_ITEMS_SIZE,
        page,
        undefined,
        recordState,
      );

      result.push(...content);

      page++;
      hasNextPage = result.length < totalElements;
    }

    return result;
  }

  public delete = async (id: string): Promise<void> =>
    await this.apiService.delete<void>(`${this.baseUrl}/${id}`).toPromise();

  public createOrUpdate = async (
    payload: IDocumentTypePayload,
    id?: string,
  ): Promise<IDocumentType> => {
    if (id) {
      return await this.apiService.put<IDocumentType>(`${this.baseUrl}/${id}`, payload).toPromise();
    } else {
      return await this.apiService.post<IDocumentType>(`${this.baseUrl}`, payload).toPromise();
    }
  };

  public setRecordState = async (payload: IRecordState, id: string): Promise<IDocumentType> =>
    await this.apiService.put<IDocumentType>(`${this.baseUrl}/${id}`, payload).toPromise();
}
