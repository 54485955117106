@if (isLoading()) {
  <app-loader></app-loader>
} @else {
  <div class="full-width-items-container">
    @for (item of parsedItems; track $index) {
      <div class="item hover-visibility-container">
        <iov-location-card-content
          [location]="item"
          [type]="cardContentTypeEnum.REMOVE"
          (remove)="onRemove(item.linkId)"
        ></iov-location-card-content>
      </div>
    }
  </div>
}
