import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  template: `
    <mat-slide-toggle
      *ngIf="isVisible()"
      [tp]="getTooltip()"
      [tpIsLazy]="true"
      [checked]="params.value"
      [disabled]="params['isDisabled']"
      (click)="$event.stopPropagation()"
      (change)="onStatusChange()"
    ></mat-slide-toggle>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlideOverCellRendererComponent implements ICellRendererAngularComp {
  public params!: ICellRendererParams;

  private cd = inject(ChangeDetectorRef);

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  public isVisible = (): boolean => !this.params["hide"] || !this.params["hide"](this.getData());

  getTooltip = (): string => {
    const tooltip = this.params["tooltip"];

    if (!tooltip) {
      return "";
    }
    if (typeof tooltip === "string") {
      return tooltip;
    }

    return tooltip(this.getData());
  };

  onStatusChange = async (): Promise<void> => {
    await this.params["statusChange"](this.getData());
    this.params.value = !this.params.value;
    this.cd.markForCheck();
  };

  private getData = (): any => this.params.data ?? this.params.node.allLeafChildren[0].data;
}
