import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ValidationErrors } from "@angular/forms";

import { EntityTypeEnum, UploadFileErrorTypeEnum } from "@shared/enums";
import { IFileUpload, ISelectOption, ITagExtended } from "@shared/interfaces";

@Component({
  selector: "iov-document-list",
  templateUrl: "./document-list.component.html",
  styleUrl: "./document-list.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentListComponent {
  @Input() files: IFileUpload[] = [];

  @Input() documentTypeOptions: ISelectOption[] = [];

  public readonly entityTypeEnum = EntityTypeEnum;

  public readonly uploadFileErrorTypeEnum = UploadFileErrorTypeEnum;

  @Output() clickViewFile: EventEmitter<number> = new EventEmitter();

  @Output() clickRemoveFile: EventEmitter<number> = new EventEmitter();

  onClickViewFileEvent(index: number): void {
    this.clickViewFile.emit(index);
  }

  onClickDeleteFileEvent(index: number): void {
    this.clickRemoveFile.emit(index);
  }

  validationError(file: IFileUpload): ValidationErrors {
    if (file.errors[0]?.type === UploadFileErrorTypeEnum.DUPLICATED_NAME) {
      return { fileDuplication: "This name is already being used by another document." };
    }

    return {};
  }

  onTagsChanged(file: IFileUpload, tags: ITagExtended[]): void {
    file.formGroup.controls["tags"].setValue(tags);
  }
}
