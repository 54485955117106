<article class="field-group-container no-top-padding" cdkDropListGroup>
  <div class="content">
    <h3>
      Products
      <mat-icon class="info-icon" tp="Specify how this product is related with other products.">
        help
      </mat-icon>
    </h3>
    <mat-tab-group
      animationDuration="0ms"
      class="full-width"
      [selectedIndex]="selectedTabIndex"
      (selectedIndexChange)="onTabChanged($event)"
    >
      <mat-tab>
        <ng-template mat-tab-label>
          Created from
          @if (createdFromProducts.length) {
            <div class="share-indicator link-indicator">
              {{ createdFromProducts.length }}
            </div>
          }
        </ng-template>
        @if (isLoading()) {
          <app-loader></app-loader>
        } @else {
          <iov-drag-drop-container
            itemType="products"
            (itemDropped)="onItemDropped($event)"
          ></iov-drag-drop-container>
          <app-product-list-items
            [items]="createdFromProducts"
            (remove)="onRemove($event)"
          ></app-product-list-items>
        }
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          Used in
          @if (usedInProducts.length) {
            <div class="share-indicator link-indicator">
              {{ usedInProducts.length }}
            </div>
          }
        </ng-template>
        @if (isLoading()) {
          <app-loader></app-loader>
        } @else {
          <iov-drag-drop-container
            itemType="products"
            (itemDropped)="onItemDropped($event)"
          ></iov-drag-drop-container>
          <app-product-list-items
            [items]="usedInProducts"
            (remove)="onRemove($event)"
          ></app-product-list-items>
        }
      </mat-tab>
    </mat-tab-group>
  </div>

  <ng-template #attachmentsRightMenu>
    <iov-right-side-panel
      class="overlay-right-panel"
      itemType="Product"
      itemTypePlural="Products"
      [isLoadingItems]="isLoadingProducts()"
      [availableItems]="availableProducts"
      [displayCloseButton]="false"
      (pressAdd)="onAddNewProduct()"
      (pressAddAll)="onAddAll()"
      (pressRefresh)="onReloadProducts()"
      (searchEvent)="onSearchAvailable($event)"
    >
      <div
        class="items-container"
        cdkDropList
        [cdkDropListSortingDisabled]="true"
        [cdkDropListData]="availableProducts"
      >
        @for (item of availableProducts; track item.id) {
          <iov-draggable-card (pressed)="onAdd(item.id)" [item]="item">
            <iov-product-card-content
              [product]="item"
              [type]="cardContentTypeEnum.ADD"
            ></iov-product-card-content>
          </iov-draggable-card>
        }
      </div>
    </iov-right-side-panel>
  </ng-template>
</article>

<ng-template #overlayTopButtons>
  <iov-button size="large" tp="Add new" tpPlacement="bottom" type="icon">
    <mat-icon icon-medium-18>add</mat-icon>
  </iov-button>
</ng-template>
