import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { Type } from "@design-makeover/components/flag/flag.model";

@Component({
  selector: "iov-flag",
  templateUrl: "./flag.component.html",
  styleUrls: ["./flag.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlagComponent {
  @Input() public country: string;

  @Input() public size: Type = "small";
}
