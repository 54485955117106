<app-search-and-filters
  (recordStateChanged)="onRecordStateChanged($event)"
  (searchChanged)="onSearchChanged($event)"
  *ngIf="isSearchEnabled"
  [isRecordStateFilterEnabled]="isRecordStateFilterEnabled"
  [recordState]="recordState"
  [searchText]="searchText"
></app-search-and-filters>

@if (isLoading()) {
  <app-loader></app-loader>
} @else {
  @if (!rowData()?.length) {
    <iov-empty-search-results></iov-empty-search-results>
  } @else {
    <ag-grid-angular
      #grid
      class="grid ag-theme-material"
      [class]="class"
      [class.no-displayed-rows]="grid?.api?.getDisplayedRowCount() === 0"
      [ngClass]="grid?.api?.getDisplayedRowCount() === 0 ? 'no-rows' : 'has-rows'"
      [domLayout]="domLayout"
      [style.height]="heightStyle"
      [initialState]="initialState"
      [rowData]="rowData()"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [masterDetail]="masterDetail"
      [detailCellRendererParams]="detailCellRendererParams"
      [detailCellRenderer]="detailCellRenderer"
      [getRowClass]="getRowClass"
      [getRowHeight]="getRowHeight"
      [pagination]="isPaginatorEnabled"
      [suppressPaginationPanel]="!isPaginatorEnabled || isFixedBottomPaginator"
      [paginationPageSizeSelector]="tableAvailablePageSizes"
      [paginationPageSize]="grid?.api?.paginationGetPageSize() ?? initialPageSize"
      [rowClassRules]="rowClassRules"
      [tooltipShowDelay]="100"
      [tooltipHideDelay]="3000"
      [quickFilterText]="searchText"
      [suppressContextMenu]="true"
      [suppressCellFocus]="true"
      [overlayNoRowsTemplate]="overlayNoRowsTemplate"
      [localeText]="localeText"
      [suppressRowClickSelection]="true"
      [rowSelection]="'multiple'"
      [popupParent]="popupParent"
      [groupSelectsChildren]="true"
      [groupSelectsFiltered]="true"
      (gridReady)="onGridReady()"
      (gridSizeChanged)="onGridSizeChanged()"
      (rowClicked)="onRowClicked($event)"
      (stateUpdated)="onStateUpdated($event)"
      (selectionChanged)="onSelectionChanged($event)"
      (firstDataRendered)="onFirstDataRendered()"
      (paginationChanged)="onPaginationChanged()"
      (columnRowGroupChanged)="onColumnRowGroupChanged()"
    ></ag-grid-angular>
    <div *ngIf="isFixedBottomPaginator && !!grid?.api" class="grid-footer">
      <div class="page-size">
        Records per page:
        <div class="page-size-select clickable" [matMenuTriggerFor]="recordsPerPageMenu">
          {{ grid.api.paginationGetPageSize() }}
          <mat-icon svgIcon="keyboard_arrow_down"></mat-icon>
        </div>
        <mat-menu #recordsPerPageMenu="matMenu">
          @for (pageSize of tableAvailablePageSizes; track pageSize) {
            <button
              type="button"
              mat-menu-item
              (click)="onBottomPaginationPageSizeChanged(pageSize)"
            >
              {{ pageSize }}
            </button>
          }
        </mat-menu>
      </div>
      <div class="elements">
        {{ getFirstElementShownNumber() }} to {{ geLastElementShownNumber() }} of
        {{ grid.api.paginationGetRowCount() | number: "1.0-0" }}
      </div>
      <div class="pages">
        <button
          type="button"
          mat-icon-button
          color="primary"
          [disabled]="!canGoToPrevPage()"
          (click)="grid.api.paginationGoToFirstPage()"
        >
          <mat-icon>first_page</mat-icon>
        </button>
        <button
          type="button"
          mat-icon-button
          color="primary"
          [disabled]="!canGoToPrevPage()"
          (click)="grid.api.paginationGoToPreviousPage()"
        >
          <mat-icon>navigate_before</mat-icon>
        </button>
        Page {{ grid.api.paginationGetRowCount() ? grid.api.paginationGetCurrentPage() + 1 : 0 }} of
        {{ grid.api.paginationGetTotalPages() }}
        <button
          type="button"
          mat-icon-button
          color="primary"
          [disabled]="!canGoToNextPage()"
          (click)="grid.api.paginationGoToNextPage()"
        >
          <mat-icon>navigate_next</mat-icon>
        </button>
        <button
          type="button"
          mat-icon-button
          color="primary"
          [disabled]="!canGoToNextPage()"
          (click)="grid.api.paginationGoToLastPage()"
        >
          <mat-icon>last_page</mat-icon>
        </button>
      </div>
    </div>
  }
}
