<div
  class="drop-container"
  [ngClass]="class"
  cdkDropList
  [cdkDropListSortingDisabled]="true"
  (cdkDropListDropped)="onItemDropped($event)"
>
  @if (placeHolder) {
    <span>{{ placeHolder }}</span>
  } @else {
    <span>Drag and drop {{ itemType }} here or click "+" button.</span>
  }
</div>
