import { Inject, Injectable } from "@angular/core";

import { APP_CONFIG } from "@shared/tokens";

import { ApiService } from "./api.service";
import { IConfig, ITag, ITagPayload } from "../../interfaces";
import { FormUtils } from "../../utils";
import { AuthenticationService } from "../authentication.service";

@Injectable({
  providedIn: "root",
})
export class TagsService {
  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {}

  private getBaseUrl = (): string =>
    `${this.environment.baseUrl}organisations/${this.authenticationService.getActiveOrganisationId()}/tags`;

  public getAll = async (
    record: string = undefined,
    definition: string = undefined,
  ): Promise<ITag[]> => {
    const url = `${this.getBaseUrl()}?${FormUtils.addUrlParams({ record, definition })}`;

    return await this.apiService.get<ITag[]>(url).toPromise();
  };

  public removeFromRecord = async (id: string): Promise<void> =>
    await this.apiService.delete<void>(`${this.getBaseUrl()}/${id}`).toPromise();

  public addToRecord = async (payload: ITagPayload): Promise<ITag> =>
    await this.apiService.post<ITag>(`${this.getBaseUrl()}`, payload).toPromise();
}
