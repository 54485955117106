import { AfterViewInit, ChangeDetectionStrategy, Component, Input, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef } from "ag-grid-community";
import { EditStandardDialogComponent } from "src/app/components/settings";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { CommonConstants } from "@shared/constants";
import { ConfirmDialogResponseEnum, RecordStateEnum, TableEnum } from "@shared/enums";
import { IStandard, IStandardWithTypes } from "@shared/interfaces";
import { StandardTypesService, StandardsService } from "@shared/services";
import { ColumnUtils } from "@shared/utils";

import { ConfirmDialogComponent, InfoDialogComponent } from "../..";

@Component({
  selector: "app-standards-table",
  templateUrl: "./standards-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StandardsTableComponent implements AfterViewInit {
  @Input()
  public standards: IStandardWithTypes[] = null;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isRecordStateFilterEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  @Input()
  public recordState: RecordStateEnum = RecordStateEnum.ALL;

  @Input()
  public table = TableEnum.ADMIN_STANDARDS;

  @Input()
  public isSaveTableState = false;

  public isLoading = signal(true);

  public rowData: IStandardWithTypes[] = [];

  public columnDefs = signal<ColDef[]>([]);

  constructor(
    private standardsService: StandardsService,
    private standardTypesService: StandardTypesService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) {}

  public async ngAfterViewInit() {
    this.setColumnDefs();
    await this.getAll();
  }

  private setColumnDefs = (): void => {
    const columnDefs: ColDef[] = [
      ColumnUtils.recordState(),
      { headerName: "Name", field: "name", lockVisible: true },
      ColumnUtils.chips("Standard types", "typesNames"),
    ];

    if (this.areButtonsEnabled) {
      const buttons = [
        {
          onClick: this.onToggleArchiveStatus,
          tooltip: (row) => (row.recordState === RecordStateEnum.ACTIVE ? "Archive" : "Unarchive"),
          icon: (row) => (row.recordState === RecordStateEnum.ACTIVE ? "archive" : "inventory"),
        },
        {
          onClick: this.onEdit,
          tooltip: "Edit",
          icon: "edit",
        },
      ];

      columnDefs.push(ColumnUtils.buttons(buttons));
    }
    this.columnDefs.set(columnDefs);
  };

  public onEdit = async (standard: IStandardWithTypes): Promise<void> => {
    if (!this.areButtonsEnabled) {
      return;
    }
    if (standard.recordState === RecordStateEnum.ARCHIVED) {
      this.dialog.open(InfoDialogComponent, {
        data: {
          icon: "error",
          iconColor: "red",
          contentTranslatedText: CommonConstants.EDIT_ARCHIVED_RECORD_ERROR_TEXT,
        },
      });

      return;
    }
    const dialogRef = this.dialog.open(EditStandardDialogComponent, {
      data: {
        standard,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: { hasSaved: boolean }) => {
      if (result?.hasSaved) {
        await this.getAll();
      }
    });
  };

  public onToggleArchiveStatus = async (element: IStandardWithTypes): Promise<void> => {
    const isArchived = element.recordState === RecordStateEnum.ARCHIVED;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: `${isArchived ? "Unarchive" : "Archive"} confirmation`,
        contentTranslatedText: `Are you sure that you want to ${isArchived ? "unarchive" : "archive"} this record?`,
        confirmButtonTranslatedText: isArchived ? "Unarchive" : "Archive",
        confirmButtonIcon: isArchived ? "inventory" : "archive",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        try {
          this.isLoading.set(true);
          const wasArchived = element.recordState === RecordStateEnum.ARCHIVED;

          await this.standardsService.setRecordState(
            {
              recordState: wasArchived ? RecordStateEnum.ACTIVE : RecordStateEnum.ARCHIVED,
            },
            element.id,
          );
          this.notificationService.showSuccess(wasArchived ? "Unarchived" : "Archived");
          await this.getAll();
        } catch (error) {
          this.notificationService.showError(error);
          this.isLoading.set(false);
        }
      }
    });
  };

  private getParsedRowData = async (standards: IStandard[]): Promise<IStandardWithTypes[]> => {
    const result = [];

    const promises = standards.map(async (standard) => {
      const standardTypes = await this.standardTypesService.getPage(standard.id);
      const typesNames = standardTypes.map((s) => s.fullName);

      result.push({ ...standard, standardTypes, typesNames });
    });

    await Promise.all(promises);

    return result;
  };

  public getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    if (this.standards) {
      this.rowData = await this.getParsedRowData(this.standards);
      this.isLoading.set(false);
    } else {
      try {
        const standards = await this.standardsService.getAll();

        this.rowData = await this.getParsedRowData(standards);
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };
}
