<section class="slide-overlay-menu" [class.slide-overlay-menu-expandable]="canExpandMenu">
  @for (menuItem of menuItems; track menuItem; let idx = $index) {
    <button
      class="slide-overlay-menu-item"
      [class.active]="menuItem.active()"
      [class.disabled]="menuItem.disabled()"
      (click)="selectMenuItem(menuItem)"
      [tp]="canExpandMenu ? '' : menuItem.title()"
      tpPlacement="right"
      [tpOffset]="[0, 10]"
      [tpIsLazy]="true"
    >
      <div class="slide-overlay-menu-item-wrapper">
        @if (menuItem.svgIcon()) {
          <mat-icon [svgIcon]="menuItem.svgIcon()" class="slide-overlay-menu-item-icon"></mat-icon>
        } @else if (menuItem.icon()) {
          <mat-icon class="slide-overlay-menu-item-icon">{{ menuItem.icon() }}</mat-icon>
        }
        <section class="slide-overlay-menu-item-indicator">
          <ng-container
            *ngIf="menuItem.isIndicatorTemplateRef(); else checkNumber"
            [ngTemplateOutlet]="menuItem.indicatorTemplateRef()"
          ></ng-container>
        </section>

        <ng-template #checkNumber>
          <div
            *ngIf="menuItem.isIndicatorNumberAndGreaterThanZero(); else checkNull"
            class="share-indicator"
          >
            {{ menuItem.indicator() }}
          </div>
        </ng-template>

        <ng-template #checkNull>
          <div *ngIf="menuItem.isIndicatorLoading()" class="share-indicator">
            <mat-icon class="rotate-animation loading-icon">cached</mat-icon>
          </div>
        </ng-template>
      </div>
      @if (canExpandMenu) {
        <span class="slide-overlay-menu-item-label">{{ menuItem.title() }}</span>
      }
    </button>
  }
</section>

<section [class.side-menu-active]="overlay.contentRightSidePanel()" class="slide-overlay-content">
  @if (selectedContent) {
    <iov-slide-overlay-content-header
      [title]="selectedMenuItem.title()"
      [controllersTemplate]="selectedMenuItem.menuControllersTemplate()"
      [showEditButton]="selectedMenuItem.showMenuEditButton() && canAddModifyEntities"
      [showSaveButton]="selectedMenuItem.showMenuSaveButton()"
    ></iov-slide-overlay-content-header>
    <div class="slide-overlay-content-content" [class]="contentClass">
      @if (overlay.loading()) {
        <app-loader></app-loader>
      } @else {
        <ng-container [ngTemplateOutlet]="selectedContent"></ng-container>
      }
    </div>
  }
</section>
@if (overlay.contentRightSidePanel()) {
  <section class="slide-overlay-content-side-menu">
    <ng-container [ngTemplateOutlet]="overlay.contentRightSidePanel()"></ng-container>
  </section>
}
