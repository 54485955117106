<iov-button class="mr" type="icon-primary" size="medium" [matMenuTriggerFor]="menu">
  <mat-icon>add</mat-icon>
</iov-button>
<mat-menu #menu class="shortcut-add-dropdown">
  <div class="menu-container">
    <div class="menu-section-container">
      <iov-shortcut-add-button
        icon="locations"
        text="Add Location"
        (pressed)="onClickShortcut(entityTypeEnum.LOCATIONS)"
      ></iov-shortcut-add-button>
      <iov-shortcut-add-button
        icon="organisations"
        text="Add Organisation"
        (pressed)="onClickShortcut(entityTypeEnum.ORGANISATIONS)"
      ></iov-shortcut-add-button>
    </div>
    <div class="divider"></div>
    <div class="menu-section-container">
      @if (isAdminMenuEnabled) {
        <iov-shortcut-add-button
          icon="products"
          text="Add Product"
          (pressed)="onClickShortcut(entityTypeEnum.PRODUCTS)"
        ></iov-shortcut-add-button>
      }
      <iov-shortcut-add-button
        icon="items"
        text="Add Item"
        (pressed)="onClickShortcut(entityTypeEnum.ITEMS)"
      ></iov-shortcut-add-button>
      <iov-shortcut-add-button
        icon="deliveries"
        text="Add Delivery"
        (pressed)="onClickShortcut(entityTypeEnum.DELIVERIES)"
      ></iov-shortcut-add-button>
      <iov-shortcut-add-button
        icon="processes"
        text="Add Process"
        (pressed)="onClickShortcut(entityTypeEnum.PROCESSES)"
      ></iov-shortcut-add-button>
    </div>
    <div class="divider"></div>
    <div class="menu-section-container">
      <iov-shortcut-add-button
        icon="document"
        text="Add Document"
        (pressed)="onClickShortcut(entityTypeEnum.DOCUMENTS)"
      ></iov-shortcut-add-button>
      <iov-shortcut-add-button
        icon="certificates"
        text="Add Certificate"
        (pressed)="onClickShortcut(entityTypeEnum.CERTIFICATES)"
      ></iov-shortcut-add-button>
    </div>
  </div>
</mat-menu>
