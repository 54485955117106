@if (isLoading()) {
  <app-loader></app-loader>
} @else {
  @if (unitsOfMeasurement.length) {
    <div class="default-unit-text">
      <small class="container-flex">
        Default unit of measurement
        <mat-icon
          tp="Determines which unit of measurement will be used to display the quantities of
                    items with this product by default."
        >
          help
        </mat-icon>
      </small>
    </div>
  }

  <div class="full-width-items-container">
    @for (unit of unitsOfMeasurement; track $index) {
      <div class="item hover-visibility-container">
        <iov-unit-of-measurement-card
          [type]="cardContentTypeEnum.REMOVE"
          [isSystemUnit]="$index === 0"
          [defaultUnitOfMeasurementId]="defaultUnitOfMeasurementId"
          [isToggleDisabled]="isToggleDisabled"
          (remove)="onRemove(unit.id)"
          (defaultUnitChanged)="onDefaultUnitChanged($event)"
          [unitOfMeasurement]="unit"
        ></iov-unit-of-measurement-card>
      </div>
    }
  </div>
}
