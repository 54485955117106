<div class="right-side-panel">
  <div class="top-content">
    <h3 class="title">{{ safeTitle }}</h3>
    @if (displayTopButtons) {
      <iov-button-group class="cg-12 fz-18">
        <iov-button
          (pressed)="addMenu ? null : onAddNewItem()"
          tp="Add new"
          tpPlacement="bottom"
          size="medium"
          type="icon"
          [matMenuTriggerFor]="addMenu"
        >
          <mat-icon icon-medium-18>add</mat-icon>
        </iov-button>
        <iov-button
          (pressed)="onPressRefresh()"
          tp="Refresh"
          tpPlacement="bottom"
          size="medium"
          type="icon"
        >
          <mat-icon icon-medium-18 svgIcon="refresh"></mat-icon>
        </iov-button>
      </iov-button-group>
    }
    @if (overlay.contentRightSidePanel()) {
      <iov-button-group class="cg-12" style="margin-left: auto">
        @if (overlayTopButtons) {
          <ng-container [ngTemplateOutlet]="overlayTopButtons"></ng-container>
        }
        @if (displayCloseButton) {
          <iov-button
            (pressed)="onCloseSideMenu()"
            tp="Close"
            tpPlacement="bottom"
            size="large"
            type="icon"
          >
            <mat-icon icon-medium-18>close</mat-icon>
          </iov-button>
        }
      </iov-button-group>
    }
  </div>

  <div class="no-items-container">
    <ng-content select="[subtitle]"></ng-content>
  </div>

  @if (displayContent) {
    <iov-input-search
      (searchEvent)="onSearchEvent($event)"
      [placeholder]="searchPlaceholder"
    ></iov-input-search>

    @if (!isLoadingItems()) {
      @if (availableItems.length) {
        <div class="add-all">
          <div class="found-text">
            <ng-container [ngPlural]="availableItems.length">
              <ng-template ngPluralCase="=1">
                <span class="fw-700">1&nbsp;</span>
                <span class="lowercase">{{ itemType }}</span>
                found
              </ng-template>
              <ng-template ngPluralCase="other">
                <span class="fw-700">{{ availableItems.length }}&nbsp;</span>
                <span class="lowercase">{{ itemTypePlural }}</span>
                found
              </ng-template>
            </ng-container>
          </div>
          <div class="button">
            <iov-button
              class="no-border no-padding-right content-underline"
              type="ghost"
              (pressed)="onAddAll()"
            >
              <mat-icon icon-left svgIcon="plus"></mat-icon>
              <span class="underline">Add all</span>
            </iov-button>
          </div>
        </div>

        <ng-content></ng-content>
      } @else {
        <div class="no-items-container no-items-container-text">
          @if (noItemsFoundText) {
            <p>{{ noItemsFoundText }}</p>
          } @else {
            <p>
              No
              <span class="lowercase">{{ itemType }}</span>
              found
            </p>
          }

          @if (addItemsText) {
            <p>{{ addItemsText }}</p>
          } @else {
            <p>
              To add
              <span class="lowercase">{{ itemTypePlural }}</span>
              , click the "+" button
            </p>
            @if (isAddOpenInNewTab) {
              <p>Once added, return to this screen and click the refresh button</p>
            }
          }
        </div>
      }
    } @else {
      <app-loader></app-loader>
    }
  }
</div>
