<form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
  <h2 mat-dialog-title>Invite user</h2>

  <app-loader *ngIf="isLoading()" containerClass="on-dialog"></app-loader>

  <ng-container *ngIf="!isLoading()">
    <mat-dialog-content>
      <iov-input
        [autofocus]="!isLoading()"
        formControlName="email"
        label="Email"
        type="email"
      ></iov-input>
      <iov-input-select
        [options]="userRolesOptions"
        formControlName="role"
        label="Role"
      ></iov-input-select>
    </mat-dialog-content>

    <mat-dialog-actions>
      <iov-button-group>
        <iov-button (pressed)="onClose()" type="ghost">Cancel</iov-button>
        <iov-button [disabled]="isSubmitButtonDisabled()" accessibilityRole="submit">
          <mat-icon icon-left>send</mat-icon>
          Send invitation
        </iov-button>
      </iov-button-group>
    </mat-dialog-actions>
  </ng-container>
</form>
