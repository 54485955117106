import { IGeoLocation } from "@shared/interfaces";

import { CommonConstants } from "../constants";
import { GeoJsonTypeEnum } from "../enums";

const regexLat = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/;
const regexLon = /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/;

const isValidCoordinates = (lat: string, lon: string): boolean =>
  isValidLatitude(lat) && isValidLongitude(lon);

const isValidLatitude = (lat: string): boolean => regexLat.test(lat);

const isValidLongitude = (lon: string): boolean => regexLon.test(lon);

const getObject = (type: string, coordinates: any[]): any => ({
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type,
        coordinates,
      },
      properties: {
        name: type,
      },
    },
  ],
});

const isValid = (geojson: any): boolean => {
  if (
    (geojson?.type === "FeatureCollection" &&
      geojson.features?.length &&
      geojson.features[0]?.type === "Feature" &&
      geojson.features[0].geometry?.coordinates?.length) ||
    geojson?.geometry?.type === GeoJsonTypeEnum.MULTI_POLYGON
  ) {
    const type =
      (geojson.features?.length && geojson.features[0]?.geometry?.type) || geojson?.geometry?.type;

    return CommonConstants.GEO_JSON_VALID_TYPES.includes(type);
  }

  return false;
};

const isValidPolygonOrMultiPolygon = (geojson: IGeoLocation): boolean =>
  isValid(geojson) &&
  geojson.features?.length &&
  [GeoJsonTypeEnum.POLYGON, GeoJsonTypeEnum.MULTI_POLYGON].includes(
    geojson.features[0].geometry?.type,
  );

const isPolygon = (geojson: IGeoLocation): boolean =>
  geojson?.features?.length && geojson.features[0].geometry?.type === GeoJsonTypeEnum.POLYGON;

const isPoint = (geojson: IGeoLocation): boolean =>
  geojson?.features[0]?.geometry?.type === GeoJsonTypeEnum.POINT &&
  !!geojson?.features[0]?.geometry?.coordinates?.length;

const wgs84 = {
  RADIUS: 6371008.8,
};

function rad(degrees: number): number {
  return (degrees * Math.PI) / 180;
}

/**
 * This method calculates the area of a geodetic ring in square meters.
 * This method is based on the algorithm described in:
 * Robert G. Chamberlain and William H. Duquette, "Some Algorithms for
 * Polygons on a Sphere", JPL Publication 07-03.
 *
 * @param coords - Coordinates of the ring.
 * @returns The area of the ring in square meters.
 */
function ringArea(coords: number[][]): number {
  let area = 0;
  const coordsLength = coords.length;

  if (coordsLength > 2) {
    for (let i = 0; i < coordsLength; i++) {
      let lowerIndex, middleIndex, upperIndex;

      if (i === coordsLength - 2) {
        lowerIndex = coordsLength - 2;
        middleIndex = coordsLength - 1;
        upperIndex = 0;
      } else if (i === coordsLength - 1) {
        lowerIndex = coordsLength - 1;
        middleIndex = 0;
        upperIndex = 1;
      } else {
        lowerIndex = i;
        middleIndex = i + 1;
        upperIndex = i + 2;
      }

      const p1 = coords[lowerIndex];
      const p2 = coords[middleIndex];
      const p3 = coords[upperIndex];

      area += (rad(p3[0]) - rad(p1[0])) * Math.sin(rad(p2[1]));
    }

    area = (area * wgs84.RADIUS * wgs84.RADIUS) / 2;
  }

  return area;
}

function polygonArea(coords: number[][][]): number {
  let area = 0;

  if (coords && coords.length > 0) {
    area += Math.abs(ringArea(coords[0]));
    for (let i = 1; i < coords.length; i++) {
      area -= Math.abs(ringArea(coords[i]));
    }
  }

  return area;
}
/**
 * This method calculates the total area of a polygon or multipolygon
 * based on a GeoJSON object in square meters.
 *
 * @param geojson - GeoJSON object representing a polygon or multipolygon.
 * @returns The total area in square meters.
 */
const geometry = (geojson: any): number => {
  let area = 0;

  switch (geojson.type) {
    case "Polygon":
      return polygonArea(geojson.coordinates);
    case "MultiPolygon":
      for (let i = 0; i < geojson.coordinates.length; i++) {
        area += polygonArea(geojson.coordinates[i]);
      }

      return area;
    case "Point":
    case "MultiPoint":
    case "LineString":
    case "MultiLineString":
      return 0;
    case "GeometryCollection":
      for (let i = 0; i < geojson.geometries.length; i++) {
        area += geometry(geojson.geometries[i]);
      }

      return area;
  }

  return area;
};

export const GeoJSONUtils = {
  isValidCoordinates,
  isValidLatitude,
  isValidLongitude,
  getObject,
  isValid,
  isValidPolygonOrMultiPolygon,
  isPolygon,
  isPoint,
  geometry,
};
