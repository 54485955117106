import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormArray, FormGroup } from "@angular/forms";

@Component({
  selector: "app-reports-eudr-producer-info",
  templateUrl: "./reports-eudr-producer-info.component.html",
  styleUrls: ["./reports-eudr-producer-info.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ReportsEudrProducerInfoComponent {
  @Input() index: number;

  @Input() formGroup: FormGroup;

  public get productionPlacesFormGroups(): FormGroup[] {
    return (this.formGroup.controls["productionPlaces"] as FormArray).controls as FormGroup[];
  }
}
