import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  signal,
} from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import {
  MatAutocomplete,
  MatAutocompleteModule,
  MatAutocompleteOrigin,
  MatAutocompleteSelectedEvent,
  MatOption,
} from "@angular/material/autocomplete";
import { MatButton } from "@angular/material/button";
import { MatChipInputEvent, MatChipsModule } from "@angular/material/chips";
import { MatDialog } from "@angular/material/dialog";
import { MatIcon } from "@angular/material/icon";
import { MatInput } from "@angular/material/input";

import { TippyDirective } from "@ngneat/helipopper";
import { Observable, map, startWith } from "rxjs";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { EditTagDialogComponent } from "@components/settings";
import { CommonConstants } from "@shared/constants";
import { EntityTypeEnum } from "@shared/enums";
import {
  ITag,
  ITagDefinition,
  ITagDefinitionPayload,
  ITagExtended,
  ITagPayload,
} from "@shared/interfaces";
import { AuthenticationService, TagDefinitionsService, TagsService } from "@shared/services";
import { CommonUtils } from "@shared/utils";

@Component({
  selector: "app-entity-tags",
  templateUrl: "./entity-tags.component.html",
  styleUrls: ["./entity-tags.component.scss"],
  imports: [
    CommonModule,
    MatChipsModule,
    MatIcon,
    MatInput,
    MatAutocomplete,
    MatAutocompleteOrigin,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatOption,
    MatButton,
    TippyDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class EntityTagsComponent implements OnInit {
  @Input()
  public entityType: EntityTypeEnum;

  @Input()
  public elementId: string;

  @Input()
  public setEditMode: boolean = false;

  @Input()
  public initialTags: ITag[] = [];

  @Output()
  public tagsChanged = new EventEmitter<ITagExtended[]>();

  @ViewChild("tagInput") tagInput: ElementRef<HTMLInputElement>;

  public isLoading = signal(true);

  public isEditing = signal(false);

  public showTags = false;

  public canRemove = true;

  public selectedTags: ITagExtended[] = [];

  public filteredTagsDefinitions: Observable<ITagDefinition[]>;

  public formControl = new FormControl(null);

  public readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  private allTagDefinitions: ITagDefinition[] = [];

  private activeOrganisationId: string;

  private elementUri: string;

  public readonly MAX_CHIPS_TEXT_LENGTH_TO_SHOW = CommonConstants.MAX_CHIPS_TEXT_LENGTH_TO_SHOW;

  constructor(
    private tagDefinitionsService: TagDefinitionsService,
    private tagsService: TagsService,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
  ) {
    this.showTags =
      this.authenticationService.isRegularUser() &&
      !this.authenticationService.isSystemAdminOrganisation();
    this.activeOrganisationId = this.authenticationService.getActiveOrganisationId();
  }

  public async ngOnInit(): Promise<void> {
    if (!this.showTags) {
      return;
    }
    if (this.setEditMode) {
      this.isEditing.set(true);
    }
    this.elementUri = `/organisations/${this.activeOrganisationId}/${this.entityType}/${this.elementId}`;
    await this.loadAllTags(this.initialTags);

    if (this.initialTags.length) {
      this.onTagsChanged();
    }

    this.filteredTagsDefinitions = this.formControl.valueChanges.pipe(
      startWith(null),
      map((text: string | null) => this._filter(text)),
    );

    this.isLoading.set(false);
  }

  public onAddNew = async (event: MatChipInputEvent): Promise<void> => {
    const newTagTitle = (event.value || "").trim();

    if (newTagTitle) {
      const lowerCaseNewTitle = newTagTitle.toLowerCase();
      const existingTagDefinition = this.allTagDefinitions.find(
        (t) => t.title.toLowerCase() === lowerCaseNewTitle,
      );

      if (existingTagDefinition) {
        const isAlreadySelected = this.selectedTags.some(
          (t) => t.tagDefinition.id === existingTagDefinition.id,
        );

        if (!isAlreadySelected) {
          await this.addTagToRecord(existingTagDefinition);
        }
      } else {
        try {
          const newTagDefinition = await this.addTagDefinition(newTagTitle);

          await this.addTagToRecord(newTagDefinition);
        } catch (error) {
          this.notificationService.showError(error);
        }
      }
    }
    event.chipInput.clear();
    this.formControl.setValue(null);
  };

  private onTagsChanged(): void {
    this.tagsChanged.emit(this.selectedTags);
  }

  public onRemove = async (tag: ITagExtended): Promise<void> => {
    const selectedTagIndex = this.selectedTags.findIndex(
      (t) => t.tagDefinition.id === tag.tagDefinition.id,
    );

    if (selectedTagIndex === -1) {
      return;
    }

    try {
      if (this.elementId) {
        await this.tagsService.removeFromRecord(tag.id);
        this.notificationService.showSuccess("Tag removed");
      }
      this.selectedTags.splice(selectedTagIndex, 1);
      this.triggerFilteredValuesUpdate();
    } catch (error) {
      this.notificationService.showError(error);
    }
  };

  public onOptionSelected = async (event: MatAutocompleteSelectedEvent): Promise<void> => {
    this.tagInput.nativeElement.value = "";
    this.formControl.setValue(null);
    const tagDefinition = this.allTagDefinitions.find((t) => t.id === event.option.value);

    if (this.elementId) {
      await this.addTagToRecord(tagDefinition);
    } else {
      const definition = `/organisations/${this.activeOrganisationId}/tag-definitions/${tagDefinition.id}`;

      this.selectedTags.push({ tagDefinition, definition, entity: null, id: null });
      this.triggerFilteredValuesUpdate();
    }
  };

  public onEdit = async (tagDefinition: ITagDefinition): Promise<void> => {
    const dialogRef = this.dialog.open(EditTagDialogComponent, {
      data: {
        tagDefinition,
      },
    });

    dialogRef
      .afterClosed()
      .subscribe(async (result: { hasSaved: boolean; tag: ITagDefinition }) => {
        if (result?.hasSaved) {
          await this.loadAllTags();
          this.triggerFilteredValuesUpdate();
        }
      });
  };

  public onToggleEditMode = (): void => {
    this.isEditing.set(!this.isEditing());
    if (this.isEditing()) {
      setTimeout(() => {
        this.tagInput.nativeElement.focus();
      }, 1);
    }
  };

  private loadAllTags = async (initialTags: ITag[] = []): Promise<void> => {
    let selectedTags: ITag[] = this.selectedTags;

    if (initialTags.length) {
      selectedTags = this.initialTags;
    }

    await Promise.all([
      (this.allTagDefinitions = await this.tagDefinitionsService.getAll()),
      (selectedTags = this.elementId
        ? await this.tagsService.getAll(this.elementUri)
        : selectedTags),
    ]);

    this.selectedTags = selectedTags.map((s) => this.getTagExtended(s));
  };

  private getTagExtended = (tag: ITag): ITagExtended => {
    const tagDefinition = this.allTagDefinitions.find(
      (t) => t.id === CommonUtils.getUriId(tag.definition),
    );

    return {
      ...tag,
      tagDefinition,
    };
  };

  private addTagDefinition = async (title: string): Promise<ITagDefinition> => {
    const payload: ITagDefinitionPayload = {
      title,
      color: CommonConstants.DEFAULT_TAG_COLOUR,
    };
    const newTag = await this.tagDefinitionsService.createOrUpdate(payload);

    this.allTagDefinitions.push(newTag);

    return newTag;
  };

  private addTagToRecord = async (tagDefinition: ITagDefinition): Promise<void> => {
    const definition = `/organisations/${this.activeOrganisationId}/tag-definitions/${tagDefinition.id}`;

    try {
      if (this.elementId) {
        const payload: ITagPayload = { entity: this.elementUri, definition };
        const recordTag = await this.tagsService.addToRecord(payload);

        this.notificationService.showSuccess("Tag added");
        this.selectedTags.push(this.getTagExtended(recordTag));
      } else {
        this.selectedTags.push({ tagDefinition, definition, entity: null, id: null });
      }

      this.triggerFilteredValuesUpdate();
    } catch (error) {
      this.notificationService.showError(error);
    }
  };

  private triggerFilteredValuesUpdate = (): void => {
    this.formControl.updateValueAndValidity({ onlySelf: true, emitEvent: true });
    this.onTagsChanged();
  };

  private _filter(value: string): ITagDefinition[] {
    const filterValue = value ? value.toLowerCase() : null;

    return this.allTagDefinitions.filter(
      (tag) =>
        !this.selectedTags.some((s) => s.tagDefinition.id === tag.id) &&
        (!filterValue || tag.title.toLowerCase().includes(filterValue)),
    );
  }
}
