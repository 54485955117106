import { Directive, inject, Signal, signal } from "@angular/core";

import { IRowNode } from "ag-grid-community";
import { BehaviorSubject, Subscription } from "rxjs";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { RulesetUtils } from "@shared/utils/ruleset.utils";

import { ResourceTypeEnum } from "../enums";
import { IRecordResponse } from "../interfaces";
import { AuthenticationService, RulesetsService } from "../services";

@Directive({})
export abstract class TableWithRulesetsClass<T> {
  protected rowData = signal<T[]>([]);

  protected shouldGetRulesets: Signal<boolean> = signal<boolean>(false);

  protected rulesetsService = inject(RulesetsService);

  protected authenticationService = inject(AuthenticationService);

  protected notificationService = inject(NotificationService);

  protected rulesetRecordsSubject = new BehaviorSubject<IRecordResponse[]>([]);

  protected newRecord: any;

  protected abstract resourceType: ResourceTypeEnum;

  protected subscriptions = new Subscription();

  async onRowsVisibleUpdated(rows: IRowNode<any>[]) {
    if (this.shouldGetRulesets()) {
      const ids = rows.reduce((acc: string[], row) => {
        const id = row.group ? row.childrenAfterGroup?.[0]?.data?.id : row.data?.id;

        if (id && acc.findIndex((existingId) => existingId === id) === -1) {
          acc.push(id);
        }

        return acc;
      }, []);

      await this.getRulesetRecords(ids);
    }
  }

  protected async getRulesetRecords(currentPageData: string[]): Promise<void> {
    try {
      const route = RulesetUtils.getResourceRoute(this.resourceType);
      const organisationId = this.authenticationService.getActiveOrganisationId();

      let newRecordRulesetResponse: IRecordResponse[] = [];

      if (this.newRecord) {
        const newRecordUri = `/organisations/${organisationId}/${route}/${this.newRecord.id}`;
        const currentPageDataNewRecordIndex = currentPageData.findIndex(
          (id) => id === this.newRecord.id,
        );

        if (currentPageDataNewRecordIndex !== -1) {
          currentPageData.splice(currentPageDataNewRecordIndex, 1);
        }
        newRecordRulesetResponse =
          await this.rulesetsService.getInstantRulesetRecords(newRecordUri);
      }
      const uris = currentPageData.map(
        (entity) => `/organisations/${organisationId}/${route}/${entity}`,
      );

      const records = await this.rulesetsService.getRulesetBasedOnRecord({
        resourceType: this.resourceType,
        records: uris,
      });

      this.rulesetRecordsSubject.next([...records, ...newRecordRulesetResponse]);
    } catch (error) {
      this.notificationService.showError(error);
    } finally {
      this.newRecord = null;
    }
  }

  protected getRulesetIconTooltip = (): string =>
    `This ${this.resourceType.toLowerCase()} does not adhere to one or more rulesets applied to it.`;
}
