import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { IDocument } from "src/app/shared/interfaces";

import { CardContentComponent } from "@design-makeover/components/cards/card-content/card-content.component";

@Component({
  selector: "iov-document-content-card",
  templateUrl: "./document-content-card.component.html",
  styleUrl: "./document-content-card.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentContentCardComponent extends CardContentComponent {
  @Input() document: IDocument;

  get displayTooltipIcon(): boolean {
    const { issuance, validityStart, validityEnd } = this.document;

    return !!issuance || !!validityStart || !!validityEnd;
  }
}
