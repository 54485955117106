import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  signal,
} from "@angular/core";

import { ColDef } from "ag-grid-community";
import { Subscription } from "rxjs";

import { SlideOverlayPageService } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.service";
import { NotificationService } from "@design-makeover/services/notification/notification.service";

import {
  DateCellRendererComponent,
  LinkCellRendererComponent,
  QuickActionsMenuComponent,
} from "@shared/cell-renderers";
import { RecordStateEnum, TableEnum } from "@shared/enums";
import { ICertificate, ICertificateExtended, IStandard } from "@shared/interfaces";
import { AuthenticationService, CertificatesService, StandardTypesService } from "@shared/services";
import { RouterService } from "@shared/services/router.service";
import { ColumnUtils, CommonUtils } from "@shared/utils";

@Component({
  selector: "app-certificates-table",
  templateUrl: "./certificates-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CertificatesTableComponent implements AfterViewInit, OnDestroy {
  @Input()
  public certificates: ICertificate[] | ICertificateExtended[] = null;

  @Input()
  public certificatesIds: string[] = null;

  @Input()
  public allStandards: IStandard[] = [];

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isRecordStateFilterEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  @Input()
  public recordState: RecordStateEnum = RecordStateEnum.ALL;

  @Input()
  public isShowSelectCheckbox = false;

  @Input()
  public shouldOpenInNewTab = false;

  @Input()
  public table = TableEnum.CERTIFICATES;

  @Input()
  public isSaveTableState = false;

  @Input()
  public isInboundShared = false;

  @Input()
  public columns: string[] = [
    "recordState",
    "number",
    "standard.name",
    "standardType.fullName",
    "issuanceDate",
    "validFromDate",
    "validToDate",
    "tags",
  ];

  @Input()
  public class: string;

  @Input()
  public canRemove = false;

  @Output()
  public remove: EventEmitter<ICertificate> = new EventEmitter();

  @Output()
  public setCertificates: EventEmitter<ICertificate[]> = new EventEmitter();

  @Input()
  public inboundSharedSenderOrgId: string = null;

  public isLoading = signal(true);

  public rowData: ICertificateExtended[] = [];

  public columnDefs = signal<ColDef[]>([]);

  private subscriptions = new Subscription();

  constructor(
    protected routerService: RouterService,
    private certificatesService: CertificatesService,
    private notificationService: NotificationService,
    private standardTypesService: StandardTypesService,
    private overlay: SlideOverlayPageService,
    private authenticationService: AuthenticationService,
  ) {
    this.subscriptions.add(
      this.overlay.refreshTable$.subscribe(() => {
        this.getAll();
      }),
    );
  }

  public async ngAfterViewInit() {
    this.setColumnDefs();

    await this.getAll();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onRowClick = (row: ICertificate): void => {
    if (!this.areButtonsEnabled) {
      return;
    }

    if (this.shouldOpenInNewTab) {
      if (this.inboundSharedSenderOrgId) {
        this.routerService.openNewTab(
          this.routerService.getSharedCertificateLink(row.id, false, {
            organisationId: this.inboundSharedSenderOrgId,
          }),
        );
      } else {
        this.routerService.openNewTab(this.routerService.getCertificateLink(row.id, false));
      }
    } else {
      if (this.inboundSharedSenderOrgId) {
        this.routerService.navigate(
          this.routerService.getSharedCertificateLink(row.id, false, {
            organisationId: this.inboundSharedSenderOrgId,
          }),
        );
      } else {
        this.routerService.navigate(this.routerService.getCertificateLink(row.id));
      }
    }
  };

  protected additionalColumnDefs(): ColDef[] {
    return [];
  }

  protected setColumnDefs = (): void => {
    const numberActions = [];

    if (this.canRemove) {
      numberActions.push({
        icon: "close",
        tooltip: "Remove",
        show: () => this.canRemove,
        click: (certificate: ICertificate) => this.remove.emit(certificate),
      });
    }

    let columnDefs: ColDef<ICertificateExtended>[] = [
      ColumnUtils.recordState(),
      ...this.additionalColumnDefs(),
      {
        headerName: "Number",
        field: "number",
        lockVisible: true,
        suppressSizeToFit: true,
        suppressAutoSize: true,
        rowDrag: false,
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: {
          linkRouteIdParam: "id",
          linkRouteFn: this.inboundSharedSenderOrgId
            ? (id) =>
                this.routerService.getSharedCertificateLink(id, false, {
                  organisationId: this.inboundSharedSenderOrgId,
                })
            : this.routerService.getCertificateLink,
          openInNewTab: this.shouldOpenInNewTab,
          actions: numberActions,
        },
      },
      { headerName: "Standard", field: "standard.name" },
      {
        headerName: "Standard type",
        field: "standardType.fullName",
        valueFormatter: ColumnUtils.defaultValueFormatter(),
      },
      {
        headerName: "Issued on",
        field: "issuanceDate",
        cellRenderer: DateCellRendererComponent,
      },
      {
        headerName: "Valid from",
        field: "validFromDate",
        cellRenderer: DateCellRendererComponent,
      },
      {
        headerName: "Valid to",
        field: "validToDate",
        cellRenderer: DateCellRendererComponent,
      },
      {
        headerName: "Documents",
        field: "documents",
        valueGetter: (cell) => {
          if (!cell.data.documents?.length) {
            return "-";
          }

          return cell.data.documents.map((document) => ({ name: document.name, id: document.id }));
        },
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteIdParam: "id",
          linkRouteFn: this.routerService.getDocumentLink,
          textParam: "name",
        },
      },
    ];

    if (this.authenticationService.isRegularUser() && !this.isInboundShared) {
      columnDefs.push(ColumnUtils.tags("Tags"));
    }
    columnDefs = CommonUtils.getVisibleColumnDefs(columnDefs, this.columns);

    if (this.isShowSelectCheckbox) {
      columnDefs.unshift(ColumnUtils.selectCheckbox());
    }

    this.columnDefs.set(columnDefs);
  };

  private setStandardAndStandardType = async (
    certificates: ICertificate[] | ICertificateExtended[],
  ): Promise<void> => {
    for (const certificate of certificates) {
      if (certificate.standard?.id) {
        continue;
      }
      const standardId = CommonUtils.getUriId(certificate.standard);

      certificate.standard = this.allStandards.find((s: IStandard) => s.id === standardId);

      if (certificate.standardType) {
        const standardTypeId = CommonUtils.getUriId(certificate.standardType);

        certificate.standardType = await this.standardTypesService.get(standardId, standardTypeId);
      }
    }
  };

  private getAll = async (): Promise<void> => {
    this.rowData = [];
    this.isLoading.set(true);

    const tagsFieldPresent = this.columnDefs().some((c) => c.field === "tags");
    const documentsFieldPresent = this.columnDefs().some((c) => c.field === "documents");

    const fieldsToInclude = [];

    if (tagsFieldPresent) {
      fieldsToInclude.push("TAGS");
    }

    if (documentsFieldPresent) {
      fieldsToInclude.push("DOCUMENTS");
    }

    if (this.certificates) {
      const rowData = this.certificates;

      await this.setStandardAndStandardType(rowData);
      this.rowData = rowData;
      this.isLoading.set(false);
    } else if (this.certificatesIds) {
      if (!this.certificatesIds.length) {
        this.rowData = [];
        this.isLoading.set(false);

        return;
      }
      this.rowData = await this.certificatesService.getByIdsGraphQL(
        this.certificatesIds,
        undefined,
        fieldsToInclude,
      );
      this.isLoading.set(false);
    } else {
      try {
        this.rowData = await this.certificatesService.getAllGraphQL(
          undefined,
          undefined,
          fieldsToInclude,
        );
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };
}
