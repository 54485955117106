@if (isLoading()) {
  <app-loader></app-loader>
} @else {
  @if (!itemIds?.length) {
    <div class="container-flex-center">
      <h4>Supply chains are only available for deliveries with items in them.</h4>
    </div>
  } @else {
    <div class="supply-chain-buttons">
      <iov-button class="page-button-more" size="large" type="icon" [matMenuTriggerFor]="menu">
        <mat-icon svgIcon="more-horizontal"></mat-icon>
      </iov-button>
      @if (fullScreenOptionEnabled) {
        <iov-button (pressed)="fullScreen()" tp="Full screen" type="outlined">
          <mat-icon svgIcon="maximize-2"></mat-icon>
        </iov-button>
      }
    </div>
    <mat-menu #menu class="dropdown-menu">
      <iov-dropdown-menu>
        <iov-dropdown-menu-item (pressed)="onClickGroupDeliveriesByProduct()">
          @if (this.shouldGroupByProduct) {
            <mat-icon>ad_group_off</mat-icon>
            Ungroup
          } @else {
            <mat-icon>ad_group</mat-icon>
            Group deliveries by product
          }
        </iov-dropdown-menu-item>
      </iov-dropdown-menu>
    </mat-menu>
    <div
      class="flow-chart-container"
      [style.height]="height + 'px'"
      [style.cursor]="isZoomEnabled ? 'move' : 'unset'"
      [ngClass]="containerClass"
    >
      <svg class="svg-content" [class]="svgContentClass" [id]="svgId">
        <g>
          @if (hasError) {
            <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="black">
              Could not load the supply chain.
            </text>
          }
        </g>
      </svg>
    </div>

    <iov-slide-overlay-wrapper [close]="!isSlideOverVisible()" (clickClose)="closeSlideOver()">
      <div class="full-width">
        <div class="slide-over-content full-width full-height">
          <div class="container-space-between">
            <app-page-title [title]="slideOverTitle" (back)="closeSlideOver()"></app-page-title>
          </div>
          <div class="overlay-wrapper-content-max-width">
            @if (selectedItems?.length) {
              <app-items-table
                [items]="selectedItems"
                [shouldOpenInNewTab]="true"
                [columns]="[
                  'itemId',
                  'product.name',
                  'materials',
                  'initialQuantity',
                  'deliveredQuantity',
                ]"
              ></app-items-table>
            } @else if (selectedProcesses?.length) {
              <app-processes-table
                [shouldOpenInNewTab]="true"
                [processes]="selectedProcesses"
                [allProcessTypes]="allProcessTypes"
                [columns]="[
                  'recordState',
                  'processId',
                  'type.name',
                  'location.name',
                  'firstInputDate',
                  'lastOutputDate',
                ]"
              ></app-processes-table>
            } @else if (selectedCertificates?.length) {
              <app-certificates-table
                [certificates]="selectedCertificates"
                [shouldOpenInNewTab]="true"
              ></app-certificates-table>
            } @else if (selectedDocuments?.length || missingDocumentTypes?.length) {
              @if (missingDocumentTypes?.length && !isLoadingMissingData()) {
                <p class="dark-gray bold">Missing documents ({{ missingDocumentTypes.length }})</p>
                <app-document-types-table
                  [documentTypes]="missingDocumentTypes"
                  [isSearchEnabled]="false"
                  [areButtonsEnabled]="false"
                  [columns]="['type', 'rulesetsNames']"
                ></app-document-types-table>
              }
              <p *ngIf="missingDocumentTypes?.length" class="dark-gray bold">Attached documents</p>
              <app-documents-table
                [documents]="selectedDocuments"
                [shouldOpenInNewTab]="true"
              ></app-documents-table>
            }
          </div>
        </div>
      </div>
    </iov-slide-overlay-wrapper>
  }
}
@if (itemIds?.length) {
  <ng-template #deliveryTemplate let-delivery let-records="deliveriesRulesetsRecords">
    @if (delivery) {
      <iov-delivery-card-content
        [shouldOpenInNewTab]="true"
        [delivery]="delivery"
        [showWarning]="!(delivery.id | conditionsMetCheckerPipe: records)"
      ></iov-delivery-card-content>
    }
  </ng-template>
  <ng-template #groupedProductTemplate let-groupedProduct>
    @if (groupedProduct) {
      <iov-grouped-product-card-content
        [groupedProduct]="groupedProduct"
      ></iov-grouped-product-card-content>
    }
  </ng-template>
}
