import { Inject, Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { APP_CONFIG } from "@shared/tokens";

import { ApiService } from "./api.service";
import { CommonConstants } from "../../constants";
import { IApiKey, IApiKeyPayload, IApiKeyResponse, IConfig } from "../../interfaces";
import { FormUtils } from "../../utils";
import { AuthenticationService } from "../authentication.service";

@Injectable({
  providedIn: "root",
})
export class ApiKeysService {
  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {}

  private getBaseUrl = (): string =>
    `${this.environment.baseUrl}users/${this.authenticationService.getUserId()}/api-tokens`;

  public getAll = async (
    name: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
  ): Promise<IApiKey[]> => {
    const url = `${this.getBaseUrl()}?${FormUtils.addUrlParams({ name, size, page, sort })}`;

    return await this.apiService.get<IApiKey[]>(url).toPromise();
  };

  public getPageSubscription = (
    name: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
  ): Observable<IApiKey[]> => {
    const url = `${this.getBaseUrl()}?${FormUtils.addUrlParams({ name, size, page, sort })}`;

    return this.apiService.get<IApiKey[]>(url);
  };

  public delete = async (id: string): Promise<void> =>
    await this.apiService.delete<void>(`${this.getBaseUrl()}/${id}`).toPromise();

  public createOrUpdate = async (
    payload: IApiKeyPayload,
    id?: string,
  ): Promise<IApiKeyResponse> => {
    if (id) {
      return await this.apiService
        .put<IApiKeyResponse>(`${this.getBaseUrl()}/${id}`, payload)
        .toPromise();
    } else {
      return await this.apiService
        .post<IApiKeyResponse>(`${this.getBaseUrl()}`, payload)
        .toPromise();
    }
  };
}
