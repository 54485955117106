import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

import {
  ICertificateDetails,
  ICustomUnitOfMeasurement,
  IDocument,
  IItemDetails,
  ILocation,
  ILocationExtended,
  IMaterial,
  IProduct,
} from "src/app/shared/interfaces";

@Component({
  selector: "iov-draggable-card",
  templateUrl: "./draggable-card.component.html",
  styleUrl: "./draggable-card.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DraggableCardComponent {
  @Input() draggable: boolean = true;

  @Input() item:
    | IDocument
    | ICertificateDetails
    | ILocationExtended
    | ILocation
    | IMaterial
    | IItemDetails
    | IProduct
    | ICustomUnitOfMeasurement;

  @Output() pressed: EventEmitter<void> = new EventEmitter();

  onClick(): void {
    this.pressed.emit();
  }
}
