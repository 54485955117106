import { Inject, Injectable } from "@angular/core";

import { BehaviorSubject, Observable } from "rxjs";

import { APP_CONFIG } from "@shared/tokens";

import { ApiService } from "./api.service";
import { CommonConstants } from "../../constants";
import { InvitationStatusEnum } from "../../enums";
import { IConfig, IInvitation, IInvitationPayload, IPageableContent } from "../../interfaces";
import { FormUtils } from "../../utils";
import { AuthenticationService } from "../authentication.service";

@Injectable({
  providedIn: "root",
})
export class InvitationsService {
  private pendingInvitationsCountSubject: BehaviorSubject<number>;

  public pendingInvitationsCountObservable$: Observable<number>;

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {
    this.pendingInvitationsCountSubject = new BehaviorSubject<number>(0);
    this.pendingInvitationsCountObservable$ = this.pendingInvitationsCountSubject.asObservable();
  }

  private getBaseUrl = (): string => `${this.environment.baseUrl}invitations`;

  public get = async (id: string): Promise<IInvitation> =>
    await this.apiService.get<IInvitation>(`${this.getBaseUrl()}/${id}`).toPromise();

  public delete = async (id: string): Promise<void> =>
    await this.apiService.delete<void>(`${this.getBaseUrl()}/${id}`).toPromise();

  public invite = async (payload: IInvitationPayload): Promise<IInvitation> => {
    return await this.apiService.post<IInvitation>(`${this.getBaseUrl()}`, payload).toPromise();
  };

  public accept = async (id: string): Promise<void> => {
    return await this.apiService.post<void>(`${this.getBaseUrl()}/${id}/accept`).toPromise();
  };

  public reject = async (id: string): Promise<void> => {
    return await this.apiService.post<void>(`${this.getBaseUrl()}/${id}/reject`).toPromise();
  };

  public resendInvitation = async (id: string): Promise<void> => {
    return await this.apiService.post<void>(`${this.getBaseUrl()}/${id}/resend`).toPromise();
  };

  public deleteInvitation = async (id: string): Promise<void> => {
    return await this.apiService.delete<void>(`${this.getBaseUrl()}/${id}`).toPromise();
  };

  public getPage = async (
    recipient: string = undefined,
    invitingOrgId: string = undefined,
    status: InvitationStatusEnum = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
  ): Promise<IPageableContent<IInvitation>> => {
    const url = `${this.getBaseUrl()}?${FormUtils.addUrlParams({
      recipient: recipient ? encodeURIComponent(recipient) : undefined,
      invitingOrgId,
      status,
      size,
      page,
      sort,
    })}`;

    const invitations = await this.apiService.get<IPageableContent<IInvitation>>(url).toPromise();

    if (recipient && recipient === this.authenticationService.getUserEmail()) {
      this.pendingInvitationsCountSubject.next(
        invitations?.content?.filter((i) => i.status === InvitationStatusEnum.PENDING).length ?? 0,
      );
    }

    return invitations;
  };

  public async getAll(
    recipient: string = undefined,
    invitingOrgId: string = undefined,
    status: InvitationStatusEnum = undefined,
  ): Promise<IInvitation[]> {
    const result: IInvitation[] = [];
    let page = 0;
    let hasNextPage: boolean = true;

    while (hasNextPage) {
      const {
        content,
        page: { totalElements },
      } = await this.getPage(
        recipient,
        invitingOrgId,
        status,
        CommonConstants.MAX_API_GET_ITEMS_SIZE,
        page,
        undefined,
      );

      result.push(...content);

      page++;
      hasNextPage = result.length < totalElements;
    }

    return result;
  }
}
