<div class="slide-over-content">
  @if (element.validityStatus === "INVALID") {
    <div class="invalid-report text-center bold">This report is no longer valid</div>
  }
  @if (isLoading()) {
    <app-loader></app-loader>
  }
  @if (analysisSafeUrl()) {
    <iframe
      #iframe
      [src]="analysisSafeUrl()"
      [style.opacity]="isLoading() ? '0' : '1'"
      allow="fullscreen"
      frameBorder="0"
      height="99%"
      width="100%"
    ></iframe>
  }
</div>
<div class="slide-over-buttons">
  <iov-button-group>
    <iov-button (pressed)="onFullscreen()" type="ghost">
      <mat-icon svgIcon="maximize-2" icon-left></mat-icon>
      Full screen
    </iov-button>
  </iov-button-group>
</div>
