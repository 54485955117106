import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Router } from "@angular/router";

import { RecordStateEnum, RoutingEnum } from "src/app/shared/enums";
import { AuthenticationService } from "src/app/shared/services";

@Component({
  templateUrl: "./supply-chains.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplyChainsComponent {
  public readonly recordStateEnum = RecordStateEnum;

  public readonly routingEnum = RoutingEnum;

  public readonly canAddModifyEntities = this.authenticationService.canAddModifyEntities();

  constructor(
    private router: Router,
    public authenticationService: AuthenticationService,
  ) {}

  public onNavigate = async (route: RoutingEnum): Promise<void> => {
    await this.router.navigate([`/${route}`]);
  };
}
