import { ChangeDetectionStrategy, Component, inject, input, OnInit, signal } from "@angular/core";

import { InputSelectOption } from "@design-makeover/components/inputs/input-select/input-select.model";

import { DeliveryReportLocationTypeGroupModel } from "@components/reports/delivery-report/location-details/delivery-report-location-type-group.model";
import { CommonConstants } from "@shared/constants";
import { GeoJsonTypeEnum } from "@shared/enums";
import { IDocumentType, ILocationExtended } from "@shared/interfaces";
import { NavigationParams, RouterService } from "@shared/services/router.service";
import { CommonUtils } from "@shared/utils";

@Component({
  selector: "app-delivery-report-location-detail",
  templateUrl: "./delivery-report-location-detail.component.html",
  styleUrls: ["./delivery-report-location-detail.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryReportLocationDetailComponent implements OnInit {
  public locationWithMissingDocumentTypes =
    input.required<DeliveryReportLocationTypeGroupModel.ILocationWithMissingDocumentTypes>();

  public location: ILocationExtended;

  public certificateIds: string[] = [];

  public area: string;

  public geoLocationType: GeoJsonTypeEnum;

  public locationTypesTags: InputSelectOption[] = [];

  public documentTypes: IDocumentType[] = [];

  public isLoadingDocumentTypesTable = signal<boolean>(true);

  public readonly documentTypesRulesetWarningTooltipText: string =
    "This location does not adhere to one or more rulesets applied to it.";

  public readonly additionalInformationText = CommonConstants.ADDITIONAL_INFORMATION_TEXT;

  private routerService: RouterService = inject(RouterService);

  public async ngOnInit(): Promise<void> {
    this.location = this.locationWithMissingDocumentTypes().location;

    await this.setValues();
  }

  private async setValues(): Promise<void> {
    this.locationTypesTags = this.location.types.map((type) => {
      return { label: type.type, icon: type.pointOfOrigin ? "target" : null };
    });

    this.certificateIds = this.location.certificates.map((certificate) => certificate.id);
    this.area = CommonUtils.getLocationAreaInHa(this.location);
    this.geoLocationType = this.location.geoLocation?.features[0]?.geometry?.type;

    this.setDocumentTypes();
  }

  private async setDocumentTypes(): Promise<void> {
    const documentTypes = CommonUtils.getDocumentTypesByDocuments(this.location.documents);

    this.documentTypes = [
      ...documentTypes,
      ...this.locationWithMissingDocumentTypes().missingDocumentTypesByRuleSet,
    ];

    this.isLoadingDocumentTypesTable.set(false);
  }

  public getLocationLink(): NavigationParams {
    return <NavigationParams>this.routerService.getLocationLink(this.location.id, false);
  }
}
