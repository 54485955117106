import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { EntityTypeEnum } from "@shared/enums";
import { RouterService } from "@shared/services/router.service";

@Component({
  selector: "iov-shortcut-add-menu",
  templateUrl: "./shortcut-add-menu.component.html",
  styleUrls: ["./shortcut-add-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShortcutAddMenuComponent {
  @Input() isAdminMenuEnabled: boolean;

  public readonly entityTypeEnum = EntityTypeEnum;

  constructor(private routerService: RouterService) {}

  onClickShortcut = async (resourceType: EntityTypeEnum): Promise<void> => {
    await this.routerService.navigateByType(resourceType);
  };
}
