import { AfterViewInit, Component, EventEmitter, Input, Output, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef } from "ag-grid-community";
import {
  DateCellRendererComponent,
  QuickActionsMenuComponent,
} from "src/app/shared/cell-renderers";
import { CommonConstants } from "src/app/shared/constants";
import { ConfirmDialogResponseEnum, RoutingEnum, TableEnum } from "src/app/shared/enums";
import { IShare } from "src/app/shared/interfaces";
import { SharesService, AuthenticationService } from "src/app/shared/services";
import { ColumnUtils, CommonUtils } from "src/app/shared/utils";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-shares-table",
  templateUrl: "./shares-table.component.html",
})
export class SharesTableComponent implements AfterViewInit {
  @Input()
  public shares: IShare[] = null;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public table = TableEnum.SHARES;

  @Input()
  public isSaveTableState = false;

  @Input()
  public isInOverlay: boolean = false;

  @Input()
  public columns: string[] = ["recipient", "recordedTime"];

  @Output()
  public deleted: EventEmitter<void> = new EventEmitter<void>();

  public isLoading = signal(true);

  public rowData: IShare[] = [];

  public columnDefs = signal<ColDef[]>([]);

  public readonly sharedOnColDef: ColDef = {
    headerName: "Shared on",
    field: "recordedTime",
    cellRenderer: DateCellRendererComponent,
    cellRendererParams: {
      dateFormat: CommonConstants.DATE_TIME_FORMAT,
    },
  };

  private readonly canAddModifyEntities = this.authenticationService.canAddModifyEntities();

  constructor(
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private sharesService: SharesService,
    private authenticationService: AuthenticationService,
  ) {}

  async ngAfterViewInit(): Promise<void> {
    this.setColumnDefs();
    await this.getAll();
  }

  private setColumnDefs = (): void => {
    if (this.isInOverlay) {
      this.setColumnDefsForOverlay();

      return;
    }

    const columnDefs: ColDef[] = [{ headerName: "Email", field: "recipient" }, this.sharedOnColDef];

    if (this.areButtonsEnabled && this.canAddModifyEntities) {
      const buttons = [
        {
          onClick: this.onCopy,
          icon: "content_copy",
          tooltip: "Copy share link",
        },
        {
          onClick: this.onResend,
          icon: "email",
          tooltip: "Re-send share link",
        },
        {
          onClick: this.onDelete,
          icon: "delete",
          tooltip: "Delete share",
        },
      ];

      columnDefs.push(ColumnUtils.buttons(buttons));
    }
    this.columnDefs.set(CommonUtils.getVisibleColumnDefs(columnDefs, this.columns));
  };

  private setColumnDefsForOverlay = (): void => {
    const emailColDef: ColDef = {
      headerName: "Email",
      field: "recipient",
    };

    let emailColActionButtons: ColDef = {};

    if (this.authenticationService.canAddModifyEntities()) {
      emailColActionButtons = {
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: {
          actions: [
            {
              click: this.onCopy,
              tooltip: "Copy share link",
              icon: "content_copy",
            },
            {
              click: this.onResend,
              icon: "email",
              tooltip: "Re-send share link",
            },
            {
              click: this.onDelete,
              icon: "delete",
              tooltip: "Delete share",
            },
          ],
        },
      };
    }

    const columnDefs: ColDef[] = [
      {
        ...emailColDef,
        ...emailColActionButtons,
      },
      this.sharedOnColDef,
    ];

    this.columnDefs.set(CommonUtils.getVisibleColumnDefs(columnDefs, this.columns));
  };

  public getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    if (this.shares) {
      this.rowData = this.shares;
      this.isLoading.set(false);
    } else {
      try {
        const shares = await this.sharesService.getAll();

        this.rowData = shares;
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };

  private onDelete = (share: IShare) => {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          titleTranslatedText: "Delete confirmation",
          contentTranslatedText: `Are you sure you want to delete this share?
                    This will remove access to this record for ${share.recipient}.`,
          confirmButtonColor: "danger",
          confirmButtonTranslatedText: "Delete",
          confirmButtonIcon: "delete",
        },
      })
      .afterClosed()
      .subscribe(async (response) => {
        if (response === ConfirmDialogResponseEnum.CONFIRM) {
          await this.delete(share.id);
        }
      });
  };

  private onCopy = (share: IShare): void => {
    const sharedUrl = `${window.location.origin}/${RoutingEnum.SHARING}?shareId=${share.id}&orgId=${this.authenticationService.getActiveOrganisationId()}&recipient=${share.recipient}`;

    CommonUtils.textToClipboard(sharedUrl);
    this.notificationService.showSuccess("Share link copied");
  };

  private onResend = async (share: IShare): Promise<void> => {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          titleTranslatedText: "Re-send confirmation",
          contentTranslatedText: `Are you sure you want to re-send this share link?`,
          confirmButtonTranslatedText: "Re-send",
          confirmButtonIcon: "email",
        },
      })
      .afterClosed()
      .subscribe(async (response) => {
        if (response === ConfirmDialogResponseEnum.CONFIRM) {
          await this.resend(share);
        }
      });
  };

  private resend = async (share: IShare): Promise<void> => {
    try {
      await this.sharesService.reSendEmail(share.id);
      this.notificationService.showSuccess(`Link re-sent to ${share.recipient}`);
    } catch (error) {
      this.notificationService.showError(error);
    }
  };

  private delete = async (shareId: string): Promise<void> => {
    try {
      await this.sharesService.delete(shareId);
      this.notificationService.showSuccess("Share deleted");
      this.deleted.emit();
    } catch (error) {
      this.notificationService.showError(error);
    }
  };
}
