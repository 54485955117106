<div class="container-space-between">
  <app-page-title
    title="Documents"
    hint="View and manage all of the documents that your organisation
        has captured related to its supply chain, products, deliveries, etc."
  ></app-page-title>
  <iov-button-group [visible]="canAddModifyEntities">
    <iov-button (pressed)="onAdd()">
      <mat-icon icon-left>add</mat-icon>
      Add new
    </iov-button>
  </iov-button-group>
</div>
<app-documents-table
  [allowClickOnNameRow]="false"
  [recordState]="recordStateEnum.ACTIVE"
  [isSaveTableState]="true"
  [isFixedBottomPaginator]="true"
  [columns]="[
    'recordState',
    'name',
    'type.name',
    'issuance',
    'validityStart',
    'validityEnd',
    'attachedTo',
    'tags',
  ]"
></app-documents-table>
