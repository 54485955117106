export namespace BulkAddItemsEditFieldDialogModel {
  export enum FieldTypeEnum {
    INPUT = "input",
    SELECT = "select",
    CHIPS = "chips",
    TAGS = "tags",
    DATE_PICKER = "datepicker",
  }

  export const defaultFieldType = FieldTypeEnum.SELECT;
}
