import { ChangeDetectionStrategy, Component } from "@angular/core";

import { RecordStateEnum } from "@shared/enums";
import { AuthenticationService } from "@shared/services";
import { RouterService } from "@shared/services/router.service";

@Component({
  templateUrl: "./items.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemsComponent {
  public readonly recordStateEnum = RecordStateEnum;

  public readonly canAddModifyEntities = this.authenticationService.canAddModifyEntities();

  constructor(
    private routerService: RouterService,
    public authenticationService: AuthenticationService,
  ) {}

  public onAdd = async (): Promise<void> => {
    await this.routerService.navigate(this.routerService.getItemLink());
  };

  public async onOpenBulkAddItemsSlideOver(): Promise<void> {
    await this.routerService.navigate(this.routerService.getBulkAddItemsLink());
  }
}
