import moment from "moment";

const isValidDate = (value: Date | moment.Moment | string): boolean => {
  return isDate(value) || isMoment(value) || isISOStringDate(value);
};

const isDate = (value: Date | moment.Moment | string): boolean => {
  return value instanceof Date && !isNaN(value.getTime());
};

const isMoment = (value: Date | moment.Moment | string): boolean => {
  return moment.isMoment(value);
};

const isISOStringDate = (value: Date | moment.Moment | string): boolean => {
  return (
    typeof value === "string" && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/.test(value)
  );
};

const getFormattedDateForPipe = (value: string): string => {
  if (!value) {
    return "";
  }

  // format date so that is a valid date also in Safari.
  const formattedDate = value.replace("Z", "");

  if (formattedDate.length === 10) {
    // date only (no time)
    return `${formattedDate}T00:00:00Z`;
  }

  return `${formattedDate.replace(/\s/, "T")}Z`;
};

export const DateUtils = {
  isValidDate,
  getFormattedDateForPipe,
};
