<div class="container-space-between">
  <app-page-title
    [goBackRoute]="routingEnum.INBOX"
    backText="Back to Inbox"
    title="Transfer or map received data"
  ></app-page-title>
</div>
<div class="form-page-container container">
  <div class="element-content">
    @if (isLoading()) {
      <div *ngFor="let _ of [1, 2]" class="skeleton-container">
        <div class="skeleton-header"></div>
        <div class="skeleton-content">
          <div class="skeleton-item" *ngFor="let _ of [].constructor(5)"></div>
        </div>
      </div>
    } @else {
      @if (sharedLocationTypes()?.length) {
        <p class="label">Location types</p>
        <app-trasfer-or-map-form
          [shareRecordType]="inboundShareDataTypeEnum.LOCATION_TYPES"
          [existingMappings]="existingMappings"
          [sharedData]="sharedLocationTypes()"
          inputSelectPlaceholder="Select a location type"
          (newRecordCreated)="reloadLocationTypes()"
          (inboundMappingChanged)="checkUnmappedDependencies()"
          [inboundShare]="inboundShare"
          [localRecords]="locationTypesOptions()"
        ></app-trasfer-or-map-form>
      }
      @if (sharedDocumentTypes()?.length) {
        <p class="label">Document types</p>

        <app-trasfer-or-map-form
          [shareRecordType]="inboundShareDataTypeEnum.DOCUMENT_TYPES"
          [existingMappings]="existingMappings"
          (newRecordCreated)="reloadDocumentTypes()"
          [countryOptions]="countryOptions()"
          inputSelectPlaceholder="Select a document type"
          (inboundMappingChanged)="checkUnmappedDependencies()"
          [inboundShare]="inboundShare"
          [sharedData]="sharedDocumentTypes()"
          [localRecords]="documentTypesOptions()"
        ></app-trasfer-or-map-form>
      }

      @if (sharedDocuments()?.length) {
        <p class="label container-flex" [ngClass]="{ disabled: hasUnmappedDocumentDependencies() }">
          Documents
          @if (hasUnmappedDocumentDependencies()) {
            <mat-icon>lock</mat-icon>
          }
        </p>
        <app-trasfer-or-map-form
          [shareRecordType]="inboundShareDataTypeEnum.DOCUMENTS"
          (newTypeCreated)="reloadDocuments()"
          inputSelectPlaceholder="Select a document"
          [isTransferButtonDisabled]="hasUnmappedDocumentDependencies()"
          (inboundMappingChanged)="checkUnmappedDependencies()"
          inputSelectPlaceholder="Select a document"
          [isViewButtonDisabled]="false"
          [inboundShare]="inboundShare"
          [sharedData]="sharedDocuments()"
          (newRecordCreated)="reloadDocuments()"
          [localRecords]="documentsOptions()"
          [existingMappings]="existingMappings"
          [documentTypesOptions]="documentTypesOptions()"
        ></app-trasfer-or-map-form>
      }

      @if (sharedConnections()?.length) {
        <p class="label">Organisations</p>

        <app-trasfer-or-map-form
          [shareRecordType]="inboundShareDataTypeEnum.CONNECTIONS"
          [existingMappings]="existingMappings"
          [isViewButtonDisabled]="false"
          (newRecordCreated)="reloadOrganisations()"
          inputSelectPlaceholder="Select an organisation"
          (inboundMappingChanged)="checkUnmappedDependencies()"
          [countryOptions]="countryOptions()"
          [inboundShare]="inboundShare"
          [sharedData]="sharedConnections()"
          [localRecords]="connectionsOptions()"
        ></app-trasfer-or-map-form>
      }
      @if (sharedLocations()?.length) {
        <p class="label container-flex" [ngClass]="{ disabled: hasUnmappedLocationDependencies() }">
          Locations
          @if (hasUnmappedLocationDependencies()) {
            <mat-icon>lock</mat-icon>
          }
        </p>

        <app-trasfer-or-map-form
          [shareRecordType]="inboundShareDataTypeEnum.LOCATIONS"
          [existingMappings]="existingMappings"
          (newRecordCreated)="reloadLocations()"
          [isViewButtonDisabled]="false"
          [isTransferButtonDisabled]="hasUnmappedLocationDependencies()"
          [inboundShare]="inboundShare"
          [countryOptions]="countryOptions()"
          (inboundMappingChanged)="checkUnmappedDependencies()"
          inputSelectPlaceholder="Select a location"
          [sharedData]="sharedLocations()"
          [locationTypesOptions]="locationTypesOptions()"
          [localRecords]="locationsOptions()"
        ></app-trasfer-or-map-form>
      }

      @if (sharedCertificates()?.length) {
        <p class="label">Certificates</p>

        <app-trasfer-or-map-form
          [shareRecordType]="inboundShareDataTypeEnum.CERTIFICATES"
          [existingMappings]="existingMappings"
          (newRecordCreated)="reloadCertificates()"
          inputSelectPlaceholder="Select a certificate"
          (inboundMappingChanged)="checkUnmappedDependencies()"
          [inboundShare]="inboundShare"
          [isViewButtonDisabled]="false"
          [sharedData]="sharedCertificates()"
          [localRecords]="certificatesOptions()"
        ></app-trasfer-or-map-form>
      }

      @if (sharedDeliveries()?.length) {
        <p class="label container-flex" [ngClass]="{ disabled: hasUnmappedDeliveryDependencies() }">
          Deliveries
          @if (hasUnmappedDeliveryDependencies()) {
            <mat-icon>lock</mat-icon>
          }
        </p>

        <app-trasfer-or-map-form
          [shareRecordType]="inboundShareDataTypeEnum.DELIVERIES"
          [existingMappings]="existingMappings"
          (newRecordCreated)="reloadDeliveries()"
          [isViewButtonDisabled]="false"
          [isTransferButtonDisabled]="hasUnmappedDeliveryDependencies()"
          (inboundMappingChanged)="checkUnmappedDependencies()"
          [inboundShare]="inboundShare"
          [countryOptions]="countryOptions()"
          inputSelectPlaceholder="Select a delivery"
          [sharedData]="sharedDeliveries()"
          [localRecords]="deliveriesOptions()"
        ></app-trasfer-or-map-form>
      }
    }
  </div>
</div>
