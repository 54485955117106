import { COUNTRIES_COORDINATES } from "src/app/shared/constants";
import { GeoJsonTypeEnum } from "src/app/shared/enums";
import { IGeoLocation } from "src/app/shared/interfaces";

import { GeographicalSupplyChainMap } from "@components/shared/map/geographical-supply-map";
import { IGeographicalMapLocation } from "@components/shared/map/supply-chain-map.model";

export class IndividualMarker {
  location: IGeographicalMapLocation;

  toLocations: IGeographicalMapLocation[];

  constructor(location: IGeographicalMapLocation, supplyChain: GeographicalSupplyChainMap) {
    this.location = location;
    this.toLocations = this.getToLocations(supplyChain);
  }

  get geoLocation(): IGeoLocation {
    return this.location.geoLocation;
  }

  get icon(): google.maps.Icon {
    return {
      anchor: new google.maps.Point(10, 15),
      url: `../../../assets/icons/${this.iconName}.svg`,
    };
  }

  get iconName(): string {
    return this.geoLocation ? "geo-pinpoint-bordered" : "geo-pinpoint-dashed";
  }

  get coordinatesByCountry(): google.maps.LatLngLiteral {
    const country = this.location.address?.country;

    const countryCoordinates = COUNTRIES_COORDINATES.find((c) => c.country === country);

    return new google.maps.LatLng(countryCoordinates?.lat || 0, countryCoordinates?.lng).toJSON();
  }

  get position(): google.maps.LatLngLiteral {
    if (!this.geoLocation) {
      return this.coordinatesByCountry;
    }

    const coordinates = this.geoLocation.features.map((feature) => {
      const { geometry } = feature;

      if (geometry.type === GeoJsonTypeEnum.POINT) {
        return new google.maps.LatLng(
          (geometry.coordinates as number[])[1],
          (geometry.coordinates as number[])[0],
        ).toJSON();
      } else {
        return this.calculateGeographicalCenter(
          geometry.coordinates[0] as [number, number][] | number[][][],
          geometry.type === GeoJsonTypeEnum.MULTI_POLYGON,
        );
      }
    });

    const coordinateValues = coordinates.map((coord) => [coord.lng, coord.lat]);

    return this.calculateGeographicalCenter(coordinateValues as [number, number][]);
  }

  private calculateGeographicalCenter(
    coordinates: [number, number][] | number[][][],
    isMultiPolygon: boolean = false,
  ): google.maps.LatLngLiteral {
    const safeCoordinates = isMultiPolygon ? coordinates.flat() : coordinates;

    let totalLat = 0;
    let totalLng = 0;
    const numPoints = safeCoordinates.length;

    for (const coord of safeCoordinates) {
      totalLat += +coord[1];
      totalLng += +coord[0];
    }

    const avgLat = totalLat / numPoints;
    const avgLon = totalLng / numPoints;

    return new google.maps.LatLng(avgLat, avgLon).toJSON();
  }

  private getToLocations(supplyChain: GeographicalSupplyChainMap) {
    return supplyChain.links
      .filter((link) => link.from === this.location.id)
      .map((link) => IndividualMarker.findLocationById(supplyChain, link.to));
  }

  static findLocationById(
    supplyChain: GeographicalSupplyChainMap,
    locationId: string,
  ): IGeographicalMapLocation {
    return supplyChain.locations.find((l) => l.id === locationId);
  }
}
