<h2 mat-dialog-title>Add organisation</h2>
@if (isLoading()) {
  <app-loader></app-loader>
} @else {
  <form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
    <mat-dialog-content>
      <h4>{{ mainInformationText }}</h4>
      <div class="row-container">
        <div class="row row-2">
          <div class="item">
            <iov-input [autofocus]="true" formControlName="name" label="Name"></iov-input>
          </div>
          <div class="item">
            <iov-input [type]="'url'" formControlName="website" label="Website"></iov-input>
          </div>
        </div>
      </div>

      <h4>Headquarters</h4>
      <div class="row-container">
        <div class="row row-2">
          <div class="item">
            <iov-input formControlName="street" label="Address"></iov-input>
          </div>
          <div class="item">
            <iov-input formControlName="region" label="Region"></iov-input>
          </div>
          <div class="item">
            <iov-input formControlName="zipCode" label="ZIP / Postcode"></iov-input>
          </div>
          <div class="item">
            <iov-input-select
              [options]="data.countryOptions"
              formControlName="country"
              label="Country"
            ></iov-input-select>
          </div>
        </div>
      </div>

      @if (visibleCustomFields?.length) {
        <app-custom-fields-edit
          [columns]="1"
          [formGroup]="formGroup"
          [visibleCustomFields]="visibleCustomFields"
        ></app-custom-fields-edit>
      }
    </mat-dialog-content>

    <mat-dialog-actions>
      <iov-button-group>
        <iov-button (pressed)="onClose()" type="ghost">Cancel</iov-button>
        <iov-button accessibilityRole="submit">
          <mat-icon icon-left>check</mat-icon>
          Save
        </iov-button>
      </iov-button-group>
    </mat-dialog-actions>
  </form>
}
