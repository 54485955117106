import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  effect,
  inject,
  signal,
  viewChildren,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { Subject, switchMap, tap } from "rxjs";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { CommonConstants } from "@shared/constants";
import { TagsColorEnum } from "@shared/enums";
import { ISelectOption } from "@shared/interfaces";
import { RiskLevelSetsApiService } from "@shared/risk-level-sets/api";
import { RiskLevel, RiskLevelSet, RiskLevelSetPayload } from "@shared/risk-level-sets/models";
import { CommonUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

export interface AddEditRiskLevelSetDialogData {
  riskLevelSet?: RiskLevelSet;
}

interface ColorOption extends ISelectOption {
  value: TagsColorEnum;
}

interface RiskLevelFormGroup {
  color: FormControl<ColorOption>;
  title: FormControl<string>;
}

@Component({
  templateUrl: "./add-edit-risk-level-set-dialog.component.html",
  styleUrl: "./add-edit-risk-level-set-dialog.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEditRiskLevelSetDialogComponent {
  private readonly fb = inject(FormBuilder);

  private readonly dialogRef: MatDialogRef<AddEditRiskLevelSetDialogComponent> =
    inject(MatDialogRef);

  private readonly data: AddEditRiskLevelSetDialogData = inject(MAT_DIALOG_DATA);

  private readonly api = inject(RiskLevelSetsApiService);

  private readonly notification = inject(NotificationService);

  private readonly titleFields = viewChildren("titleField", { read: ElementRef });

  private readonly submitTrigger$ = new Subject<RiskLevelSetPayload>();

  readonly submit$ = this.submitTrigger$.pipe(
    tap(() => this.isLoading.set(true)),
    switchMap((payload) => this.api.createOrUpdate(payload, this.data.riskLevelSet?.id)),
    tap({
      next: () => {
        this.isLoading.set(false);
        this.notification.showSuccess(
          `Risk level set ${this.isEditing() ? "modified" : "created"}`,
        );
        this.close(true);
      },
      error: (error) => {
        this.isLoading.set(false);
        this.notification.showError(error);
      },
    }),
  );

  readonly form = this.fb.group({
    name: [
      "",
      [CustomValidators.required],
      [CustomValidators.entityAlreadyExists(this.api, this.data.riskLevelSet?.id ?? null)],
    ],
    riskLevels: this.fb.array<FormGroup<RiskLevelFormGroup>>([]),
  });

  private readonly titleFieldIndex = signal<number>(-1);

  readonly isLoading = signal(true);

  readonly isEditing = signal(false);

  readonly colorOptions: ColorOption[] = Object.values(TagsColorEnum).map((value) => ({
    label: CommonUtils.enumToText(TagsColorEnum[value]),
    value,
  }));

  private readonly colorMap = this.colorOptions.reduce(
    (map, { value, label }) => ({
      ...map,
      [value]: label,
    }),
    {},
  );

  public readonly minAmountOfRiskLevels = 2;

  public readonly maxAmountOfRiskLevels = 10;

  get isAddButtonDisabled() {
    return this.riskLevels.length === this.maxAmountOfRiskLevels;
  }

  get riskLevels() {
    return this.form.controls.riskLevels;
  }

  constructor() {
    this.data.riskLevelSet?.id && this.isEditing.set(true);

    if (this.isEditing()) {
      const { name, riskLevels } = this.data.riskLevelSet;

      this.form.patchValue({ name });

      riskLevels.forEach((riskLevel) => this.add(riskLevel));
    } else {
      for (let i = 0; i < this.minAmountOfRiskLevels; i++) {
        this.add();
      }
    }

    this.isLoading.set(false);

    effect(() => this.focusInput(this.titleFieldIndex()));
  }

  add(riskLevel?: RiskLevel) {
    const group = this.fb.group({
      title: ["", [CustomValidators.required, CustomValidators.maxLength(50)]],
      color: this.fb.control(null, CustomValidators.required),
    });

    riskLevel &&
      group.setValue({
        title: riskLevel.title,
        color: { label: this.colorMap[riskLevel.color], value: riskLevel.color },
      });

    group.updateValueAndValidity();

    this.riskLevels.push(group);
    this.form.updateValueAndValidity();

    !riskLevel && this.titleFieldIndex.set(this.riskLevels.length - 1);
  }

  remove(index: number) {
    if (index > this.minAmountOfRiskLevels - 1) {
      this.riskLevels.removeAt(index);
      this.form.updateValueAndValidity();
    }
  }

  submit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.showError(CommonConstants.FILL_REQUIRED_FIELDS_MSG);

      return;
    }

    this.submitTrigger$.next(this.buildPayload());
  }

  close(hasSaved?: boolean) {
    this.dialogRef.close(hasSaved);
  }

  private buildPayload(): RiskLevelSetPayload {
    const { name, riskLevels } = this.form.getRawValue();

    return {
      name,
      riskLevels: riskLevels.map(({ color, title }) => ({ title, color: color.value })),
      recordState: undefined,
    };
  }

  private focusInput(index: number) {
    if (this.titleFieldIndex() >= 0 && this.titleFields().length > 0) {
      const field = this.titleFields()[index];

      if (field) {
        const input = field.nativeElement as HTMLInputElement;

        input.querySelector("input").focus();
      }
    }
  }
}
