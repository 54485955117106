import { ChangeDetectionStrategy, Component, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ApiKeysTableComponent } from ".";
import { EditApiKeyDialogComponent } from "./edit-api-key-dialog/edit-api-key-dialog.component";

@Component({
  templateUrl: "./user-settings-api-keys.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSettingsApiKeysComponent {
  @ViewChild("apiKeysTable") apiKeysTable: ApiKeysTableComponent;

  constructor(private dialog: MatDialog) {}

  public onAdd = (): void => {
    const dialogRef = this.dialog.open(EditApiKeyDialogComponent);

    dialogRef.afterClosed().subscribe(async (result: { hasSaved: boolean }) => {
      if (result?.hasSaved) {
        await this.apiKeysTable.getAll();
      }
    });
  };
}
