import { inject, Injectable, signal } from "@angular/core";

import { InputSelectOption } from "@design-makeover/components/inputs/input-select/input-select.model";

import { CustomFieldsResourceTypeEnum } from "@shared/enums";
import {
  IBaseUnit,
  ICustomField,
  IDelivery,
  ILocationExtended,
  IMaterial,
  IProductExtended,
  ISelectOption,
} from "@shared/interfaces";
import { CustomFieldsService, MaterialsService, ProductsService } from "@shared/services";
import { CommonUtils } from "@shared/utils";

@Injectable({
  providedIn: "root",
})
export class DeliveryReportService {
  public allProducts: IProductExtended[] = [];

  public allMaterials: IMaterial[] = [];

  public productsService: ProductsService = inject(ProductsService);

  public materialsService: MaterialsService = inject(MaterialsService);

  private customFieldsService: CustomFieldsService = inject(CustomFieldsService);

  public allUnitOfMeasurements: IBaseUnit[] = [];

  public allCustomDeliveryFields: ICustomField[] = [];

  public countryOptions = signal<ISelectOption[]>([]);

  public async loadProducts(): Promise<void> {
    this.allProducts = await this.productsService.getAllGraphQL(undefined, undefined, [
      "MATERIALS",
      "CUSTOM_FIELDS",
    ]);
  }

  public async loadMaterials(): Promise<void> {
    this.allMaterials = await this.materialsService.getAll();
  }

  public async loadAllCustomDeliveryFields(): Promise<void> {
    this.allCustomDeliveryFields = await this.customFieldsService.getAll(
      CustomFieldsResourceTypeEnum.DELIVERY,
    );
  }

  public getCustomFieldValuesForDelivery(delivery: IDelivery): { label: string; value: string }[] {
    return this.allCustomDeliveryFields.map((customField) => {
      const deliveryCustomField = (delivery.customFields || []).find(
        (field) => CommonUtils.getUriId(field.uri) === customField.id,
      );

      return {
        label: customField.label,
        value: deliveryCustomField?.value || "-",
      };
    });
  }

  public static getLocationTypesForLocation(location: ILocationExtended): InputSelectOption[] {
    return location.types.map((type) => {
      return { label: type.type, icon: type.pointOfOrigin ? "target" : null };
    });
  }
}
