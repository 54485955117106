import { animate, state, style, transition, trigger } from "@angular/animations";

export const slideOverAnimation = trigger("slideOver", [
  state(
    "open",
    style({
      transform: "translateX(0)",
    }),
  ),
  state(
    "closed",
    style({
      transform: "translateX(100vw)",
    }),
  ),
  transition("open <=> closed", animate("300ms ease-in-out")),
]);
