<app-loader *ngIf="isLoading()"></app-loader>
<div *ngIf="!isLoading()" class="full-width-items-container">
  @for (certificate of certificateDetails; track $index) {
    <div class="item hover-visibility-container">
      <iov-certificate-content-card
        [certificate]="certificate"
        [type]="contentCardTypeEnum.REMOVE"
        (remove)="onRemove(certificate.id)"
      />
    </div>
  }
</div>
