import { getDeliveryProperties } from "./deliveries.query";
import { QueriesUtils } from "./queries.utils";

/**
 * Generates a GraphQL query string to fetch inbound deliveries by their IDs.
 *
 * @param orgId - The organization ID.
 * @param senderId - The sender ID.
 * @param ids - An array of delivery IDs to fetch.
 * @param first - The number of items to fetch after the cursor.
 * @param last - The number of items to fetch before the cursor.
 * @param after - The cursor to start fetching items after.
 * @param before - The cursor to start fetching items before.
 * @param include - An array of properties to include in the query.
 * @returns The GraphQL query string.
 */
export const getInboundDeliveriesByIdsGraphQLQuery = (
  orgId: string,
  senderId: string,
  ids: string[],
  first: number,
  last: number,
  after: string,
  before: string,
  include: string[],
): string => {
  const afterCursorClause = first > 0 ? QueriesUtils.generateClause("after", after) : "";
  const firstClause = first > 0 ? QueriesUtils.generateClause("first", first) : "";
  const beforeCursorClause = last > 0 ? QueriesUtils.generateClause("before", before) : "";
  const lastClause = last > 0 ? QueriesUtils.generateClause("last", last) : "";

  return `
      {
          inboundLoadDeliveriesByIds(   
          orgId: "${orgId}",
          senderId: "${senderId}",
          ids: ${QueriesUtils.getParsedIds(ids)},
          ${afterCursorClause ? `${afterCursorClause},` : ""}
          ${beforeCursorClause ? `${beforeCursorClause},` : ""}
          ${firstClause ? `${firstClause},` : ""}
          ${lastClause ? `${lastClause},` : ""}
      ) {       
        ${getDeliveryProperties(include)}  
        }
      }
      `;
};
