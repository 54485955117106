@if (isLoading()) {
  <app-loader></app-loader>
} @else {
  <mat-accordion>
    <mat-expansion-panel class="mat-elevation-z0 section-box" [expanded]="false">
      <mat-expansion-panel-header class="section-header">
        <mat-panel-title class="section-title">
          <iov-form-link [link]="getDeliveryLink()" [styledLink]="true" [maintainFontSize]="true">
            <div class="delivery-name">{{ delivery().deliveryId }}</div>
          </iov-form-link>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="location-info">
        From:
        <iov-form-link
          [link]="getLocationLink(delivery().from.id)"
          [styledLink]="true"
          [maintainFontSize]="true"
        >
          <span class="value">{{ delivery().from.name }}</span>
        </iov-form-link>
        <iov-tag-group [tags]="fromLocationTypes"></iov-tag-group>
        <app-flag [country]="delivery().from.address.country"></app-flag>
        {{ fromLocationCountryName }}
      </div>

      <div class="location-info">
        To:
        <iov-form-link
          [link]="getLocationLink(delivery().to.id)"
          [styledLink]="true"
          [maintainFontSize]="true"
        >
          <span class="value">{{ delivery().to.name }}</span>
        </iov-form-link>

        <iov-tag-group [tags]="toLocationTypes"></iov-tag-group>
        <app-flag [country]="delivery().to.address.country"></app-flag>
        {{ toLocationCountryName }}
      </div>

      <div class="sections">
        <app-quantity-summary-table
          [isSearchEnabled]="false"
          [isPaginatorEnabled]="false"
          [allProducts]="deliveryReportService.allProducts"
          [allMaterials]="deliveryReportService.allMaterials"
          [allUnitOfMeasurements]="deliveryReportService.allUnitOfMeasurements"
          [selectedItems]="items"
          [columns]="[
            'productName',
            'materialNames',
            'materialData',
            'totalQuantity',
            'customFields',
          ]"
        ></app-quantity-summary-table>

        @if (customFieldsValues.length) {
          <div>
            <h4>{{ additionalInformationText }}</h4>
            <div class="information grid">
              @for (customFieldValue of customFieldsValues; track customFieldValue.label) {
                <div class="column">
                  <div class="header">{{ customFieldValue.label }}</div>
                  <div class="value">{{ customFieldValue.value }}</div>
                </div>
              }
            </div>
          </div>
        }

        <div>
          <h4>Certificates</h4>
          <app-certificates-table
            [certificates]="delivery().certificates"
            [isRecordStateFilterEnabled]="false"
            [isSearchEnabled]="false"
            [isPaginatorEnabled]="false"
            [columns]="[
              'number',
              'standard.name',
              'standardType.fullName',
              'issuanceDate',
              'validFromDate',
              'validToDate',
              'documents',
            ]"
          ></app-certificates-table>
        </div>

        <div>
          <h4>Documents</h4>
          <app-document-types-table
            [documentTypes]="documentTypes"
            [areButtonsEnabled]="false"
            [isRecordStateFilterEnabled]="false"
            [isPaginatorEnabled]="false"
            [isSearchEnabled]="false"
            [columns]="['documentType', 'documentNames']"
          ></app-document-types-table>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
}
