import { ComponentType } from "@angular/cdk/overlay";
import {
  Component,
  inject,
  signal,
  ChangeDetectionStrategy,
  AfterViewInit,
  viewChild,
  ViewContainerRef,
  OnDestroy,
} from "@angular/core";

import { distinctUntilChanged, filter, Subscription } from "rxjs";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { BulkAddSlideOverBridgeService } from "@components/shared/bulk-add-slide-over/bulk-add-slide-over-bridge.service";
import { BulkAddSlideOverClass } from "@components/shared/bulk-add-slide-over/bulk-add-slide-over.class";
import { BulkAddSlideOverService } from "@components/shared/bulk-add-slide-over/bulk-add-slide-over.service";
import { CommonConstants } from "@shared/constants";
import { AuthenticationService } from "@shared/services";

@Component({
  selector: "app-bulk-add-slide-over",
  templateUrl: "./bulk-add-slide-over.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkAddSlideOverComponent implements AfterViewInit, OnDestroy {
  public isClosed = signal<boolean>(true);

  public isLoading = signal<boolean>(true);

  protected content = viewChild("dynamicContent", { read: ViewContainerRef });

  private subscriptions = new Subscription();

  private bulkAddSlideOverService: BulkAddSlideOverService = inject(BulkAddSlideOverService);

  private bridge: BulkAddSlideOverBridgeService = inject(BulkAddSlideOverBridgeService);

  private notificationService: NotificationService = inject(NotificationService);

  private authenticationService: AuthenticationService = inject(AuthenticationService);

  public ngAfterViewInit(): void {
    const subscription = this.bulkAddSlideOverService.trigger$
      .pipe(
        filter((params) => params !== null),
        distinctUntilChanged(
          (previousParams, currentParams) => previousParams?.bulkAdd === currentParams?.bulkAdd,
        ),
      )
      .subscribe((params) => {
        if (this.authenticationService.haveTokensExpired()) {
          this.notificationService.showError(CommonConstants.SESSION_HAS_EXPIRED_TEXT);
          this.bulkAddSlideOverService.forceClose();
          this.authenticationService.logout();

          return;
        }

        const component = this.bridge.getComponent(params.bulkAdd);

        if (component) {
          this.clear();
          this.createView(component);
          this.isClosed.set(false);
        } else {
          this.isClosed.set(true);
          this.clear();
        }
      });

    this.subscriptions.add(subscription);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public async onCloseSlideOverlay(): Promise<void> {
    const canExit = await this.bulkAddSlideOverService.canClose();

    if (canExit) {
      this.afterClose();
      this.bulkAddSlideOverService.toggle(null);
    }
  }

  public afterClose(): void {
    this.bulkAddSlideOverService.closeSlideOver();

    if (this.bulkAddSlideOverService.componentRef) {
      this.bulkAddSlideOverService.componentRef.instance.afterClose();
    }
  }

  public async canExit(): Promise<boolean> {
    return await this.bulkAddSlideOverService.componentCanDeactivate();
  }

  public get slideOverWrapperClass(): string {
    if (this.bulkAddSlideOverService.componentRef) {
      return this.bulkAddSlideOverService.componentRef.instance.slideOverWrapperClass();
    }

    return "";
  }

  private clear(): void {
    this.bulkAddSlideOverService.componentRef = undefined;
    this.content().clear();
  }

  private createView(component: ComponentType<BulkAddSlideOverClass>): void {
    const componentRef = this.content().createComponent(component);

    this.bulkAddSlideOverService.assignComponentRef(componentRef);
  }
}
