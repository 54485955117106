<form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
  <h2 mat-dialog-title *ngIf="!isShowSavedApiKey">{{ isEditing() ? "Edit" : "Add" }} API key</h2>

  <app-loader *ngIf="isLoading()" containerClass="on-dialog"></app-loader>

  <ng-container *ngIf="!isLoading()">
    <mat-dialog-content>
      <div *ngIf="isShowSavedApiKey" class="text-center">
        <p>Here's your new key</p>
        <p>Copy it now! This is the only time we'll show it to you.</p>
        <br />

        <app-value-and-copy-button
          [value]="savedApiKey"
          [showCopyIcon]="false"
        ></app-value-and-copy-button>
      </div>

      <ng-container *ngIf="!isShowSavedApiKey">
        <iov-input
          [autofocus]="!isLoading() && !isEditing()"
          formControlName="name"
          label="Name"
        ></iov-input>

        <iov-input formControlName="note" label="Description"></iov-input>

        @if (isEditing()) {
          <iov-datepicker label="Expiration date" formControlName="expiry"></iov-datepicker>

          <iov-button-toggle formControlName="enabled">Enabled</iov-button-toggle>
        } @else {
          <iov-input-select
            [options]="expiryOptions"
            formControlName="expiry"
            label="Expiration date"
          ></iov-input-select>
        }
      </ng-container>
    </mat-dialog-content>

    <mat-dialog-actions>
      <iov-button (pressed)="onClose(true)" [visible]="isShowSavedApiKey">Close</iov-button>

      <iov-button-group [visible]="!isShowSavedApiKey">
        <iov-button (pressed)="onClose()" type="ghost">Cancel</iov-button>
        <iov-button
          [disabled]="isSubmitButtonDisabled()"
          [visible]="!isShowSavedApiKey"
          accessibilityRole="submit"
        >
          <mat-icon icon-left>check</mat-icon>
          Save
        </iov-button>
      </iov-button-group>
    </mat-dialog-actions>
  </ng-container>
</form>
