<mat-checkbox
  [disableRipple]="true"
  [checked]="checked"
  [disabled]="disabled"
  (change)="onChangeEvent($event)"
>
  <div class="label" [class.disabled]="disabled">
    {{ label }}
  </div>
</mat-checkbox>
