import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  signal,
  OnDestroy,
  inject,
} from "@angular/core";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { Subscription } from "rxjs";
import {
  AttachmentTypeEnum,
  CustomFieldsResourceTypeEnum,
  RecordStateEnum,
  StartEndEnum,
} from "src/app/shared/enums";
import {
  IAttachmentPayload,
  ICertificate,
  ICertificatePayload,
  ICustomField,
  ISelectOption,
  IStandard,
  IStandardType,
} from "src/app/shared/interfaces";
import {
  AttachmentsService,
  AuthenticationService,
  CertificatesService,
  CustomFieldsService,
  StandardsService,
  StandardTypesService,
} from "src/app/shared/services";
import { CommonUtils, CustomFieldsUtils, FormUtils } from "src/app/shared/utils";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { AddLocationDialogComponent } from "@components/organisations";
import { AddCertificateDialogModel as Model } from "@components/shared/add-certificate-dialog/add-certificate-dialog.model";
import { CommonConstants } from "@shared/constants";
import { CustomValidators } from "@shared/validators";

@Component({
  templateUrl: "./add-certificate-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddCertificateDialogComponent implements OnInit, OnDestroy {
  public isLoading = signal<boolean>(true);

  private allCustomFields: ICustomField[] = [];

  public visibleCustomFields: ICustomField[] = [];

  public readonly mainInformationText = CommonConstants.MAIN_INFORMATION_TEXT;

  public formGroup: UntypedFormGroup = null;

  public element: ICertificate = null;

  public standardOptions = signal<ISelectOption[]>([]);

  public standardTypeOptions = signal<ISelectOption[]>([]);

  private certificatesService = inject(CertificatesService);

  private notificationService = inject(NotificationService);

  private standardsService = inject(StandardsService);

  private standardTypesService = inject(StandardTypesService);

  private authenticationService = inject(AuthenticationService);

  private readonly activeOrganisationId = this.authenticationService.getActiveOrganisationId();

  private hasSavedCertificate: boolean = false;

  private hasAttachedCertificate: boolean = false;

  private subscriptions = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<AddLocationDialogComponent>,
    private attachmentsService: AttachmentsService,
    private customFieldsService: CustomFieldsService,
    @Inject(MAT_DIALOG_DATA) public data: Model.IDialogData,
  ) {}

  public async ngOnInit(): Promise<void> {
    await Promise.all([
      (this.allCustomFields = await this.customFieldsService.getAll(
        CustomFieldsResourceTypeEnum.CERTIFICATE,
      )),
      await this.onReloadStandardOptions(),
    ]);

    await this.setupForm();
    this.isLoading.set(false);
  }

  private getSavePayload = (): ICertificatePayload => {
    const standardValue = this.formGroup.controls["standard"].value.value;
    const standardTypeValue = this.formGroup.controls["standardType"].value?.value;

    const payload = {
      id: undefined,
      number: this.formGroup.controls["number"].value.trim(),
      standard: `/common/standards/${standardValue}`,
      standardType: standardTypeValue
        ? `/common/standards/${standardValue}/types/${standardTypeValue}`
        : null,
      issuanceDate: FormUtils.getDateValueForPayload(this.formGroup.controls["issuanceDate"].value),
      validFromDate: FormUtils.getDateValueForPayload(
        this.formGroup.controls["validFromDate"].value,
      ),
      validToDate: FormUtils.getDateValueForPayload(
        this.formGroup.controls["validToDate"].value,
        StartEndEnum.END,
      ),
      url: FormUtils.addUrlProtocol(this.formGroup.controls["url"].value),
    };

    CustomFieldsUtils.addToPayload(
      payload,
      this.activeOrganisationId,
      this.formGroup,
      this.visibleCustomFields,
    );

    return payload;
  };

  public onCancel(): void {
    this.dialogRef.close();
  }

  public async onSubmit(): Promise<void> {
    const isValid = this.findAndMarkInvalidControls();

    if (!isValid) {
      return;
    }

    this.isLoading.set(true);
    const payload = this.getSavePayload();

    try {
      const response: ICertificate = await this.certificatesService.createOrUpdate(payload);

      this.hasSavedCertificate = true;
      this.formGroup.disable();

      if (this.data?.attachTargetUri) {
        await this.createAttachment(response.id);
      }
      this.isLoading.set(false);
      this.dialogRef.close({
        hasSavedCertificate: this.hasSavedCertificate,
        hasAttachedCertificate: this.hasAttachedCertificate,
        newCertificate: response,
      });
    } catch (error) {
      this.isLoading.set(false);
      this.notificationService.showError(error);
    }
  }

  public isSubmitButtonDisabled(): boolean {
    return this.formGroup.invalid || this.formGroup.pending;
  }

  private findAndMarkInvalidControls(): boolean {
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);
      this.notificationService.showError(CommonConstants.FILL_REQUIRED_FIELDS_MSG);

      return false;
    }

    return true;
  }

  private async createAttachment(certificateId: string): Promise<void> {
    const payload: IAttachmentPayload = {
      targetUri: this.data.attachTargetUri,
      attachmentUri: CommonUtils.getAttachmentUri(
        this.activeOrganisationId,
        AttachmentTypeEnum.CERTIFICATE,
        certificateId,
      ),
    };

    await this.attachmentsService.create(payload);
    this.hasAttachedCertificate = true;
  }

  private async setupForm(): Promise<void> {
    const entityExistsValidatorArgs: any = {
      searchPropertyName: "number",
      searchPropertyErrorDisplayName: "number",
    };

    let standardValue = null;
    let standardTypeValue = null;

    if (this.data?.certificate) {
      standardValue = this.standardOptions().find(
        (s) => s.value === CommonUtils.getUriId(this.data?.certificate?.standard),
      );

      await this.updateStandardTypeOptions(standardValue);

      standardTypeValue = this.standardTypeOptions().find(
        (s) => s.value === CommonUtils.getUriId(this.data?.certificate?.standardType),
      );
    }

    this.formGroup = new UntypedFormGroup(
      {
        number: new UntypedFormControl(
          this.data?.certificate?.number,
          [CustomValidators.required],
          [
            CustomValidators.entityAlreadyExists(
              this.certificatesService,
              null,
              entityExistsValidatorArgs,
            ),
          ],
        ),
        standard: new UntypedFormControl(standardValue, [CustomValidators.required]),
        standardType: new UntypedFormControl(standardTypeValue),
        issuanceDate: new UntypedFormControl(this.data?.certificate?.issuanceDate),
        validFromDate: new UntypedFormControl(this.data?.certificate?.validFromDate),
        validToDate: new UntypedFormControl(this.data?.certificate?.validToDate),
        url: new UntypedFormControl(this.data?.certificate?.url, [CustomValidators.url()]),
      },
      CustomValidators.dateRules(CommonConstants.CERTIFICATE_DATE_RULES),
    );

    this.visibleCustomFields = CustomFieldsUtils.getVisible(this.allCustomFields, null);
    CustomFieldsUtils.addToFormGroup(this.formGroup, this.visibleCustomFields, null);

    this.subscriptions.add(
      this.formGroup.controls["standard"].valueChanges.subscribe(
        async (newStandard: ISelectOption) => {
          if (this.formGroup.enabled) {
            await this.updateStandardTypeOptions(newStandard);
          }
        },
      ),
    );
  }

  private onReloadStandardOptions = async (): Promise<void> => {
    this.standardOptions.set([]);
    try {
      const standards = await this.standardsService.getAll();
      const selectedStandardId = this.element?.standard
        ? CommonUtils.getUriId(this.element.standard)
        : null;

      this.standardOptions.set(
        standards
          .filter(
            (s) =>
              s.recordState === RecordStateEnum.ACTIVE ||
              (selectedStandardId && s.id === selectedStandardId),
          )
          .map((s: IStandard) => ({
            label: s.name,
            value: s.id,
          })),
      );
    } catch (error) {
      this.notificationService.showError(error);
    }
  };

  private updateStandardTypeOptions = async (standard: ISelectOption): Promise<void> => {
    try {
      this.standardTypeOptions.set([]);
      if (this.formGroup) {
        this.formGroup.controls["standardType"].setValue(null);
      }
      if (!standard?.value) {
        return;
      }
      const selectedStandardTypeId =
        CommonUtils.getUriId(this.element?.standardType) ??
        CommonUtils.getUriId(this.data?.certificate?.standardType);

      const standardTypes = await this.standardTypesService.getPage(standard.value as string);

      this.standardTypeOptions.set(
        standardTypes
          .filter(
            (s) =>
              s.recordState === RecordStateEnum.ACTIVE ||
              (selectedStandardTypeId && s.id === selectedStandardTypeId),
          )
          .map((s: IStandardType) => ({
            label: s.fullName,
            value: s.id,
          })),
      );
    } catch (error) {
      this.notificationService.showError(error);
    }
  };

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
