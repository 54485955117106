<form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
  <h2 mat-dialog-title>Edit user</h2>

  <app-loader *ngIf="isLoading()" containerClass="on-dialog"></app-loader>

  <ng-container *ngIf="!isLoading()">
    <mat-dialog-content>
      <iov-input formControlName="firstName" label="First name"></iov-input>

      <iov-input formControlName="lastName" label="Last name"></iov-input>

      <iov-input formControlName="emailAddress" label="Email" type="email"></iov-input>

      <iov-input-select
        [emitRawValue]="true"
        [options]="userRolesOptions"
        formControlName="role"
        label="Role"
      ></iov-input-select>
    </mat-dialog-content>

    <mat-dialog-actions>
      <iov-button-group>
        <iov-button (pressed)="onClose()" type="ghost">Cancel</iov-button>
        <iov-button
          [disabled]="isSubmitButtonDisabled()"
          accessibilityRole="submit"
          cdkFocusInitial
        >
          <mat-icon icon-left>check</mat-icon>
          Save
        </iov-button>
      </iov-button-group>
    </mat-dialog-actions>
  </ng-container>
</form>
