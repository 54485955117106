<div class="row-container">
  <form [formGroup]="formGroup">
    <div class="row row-4 header-row">
      <div class="details-label">Received record</div>
      <div class="details-label"></div>
      <div class="details-label">Your record</div>
      <div class="details-label"></div>
    </div>
    @if (isLoading()) {
      <div *ngFor="let _ of sharedData()" class="row row-4 info-box with-border">
        <div class="details-value">
          <div class="skeleton"></div>
        </div>
        <div class="details-value">
          <div class="skeleton"></div>
        </div>
        <div class="details-value">
          <div class="skeleton"></div>
        </div>
        <div class="details-value">
          <div class="skeleton"></div>
        </div>
      </div>
    } @else {
      @for (type of sharedData(); track type.id; let idx = $index) {
        <div class="row row-4 info-box with-border">
          <div class="details-value type-flex">
            <div class="tags-container">
              @if (type.isNew || type.isExisting) {
                <div class="iov-tag" [ngClass]="{ BLUE: type.isNew, ORANGE: type.isExisting }">
                  @if (type.isNew) {
                    New
                  } @else {
                    Existing
                  }
                </div>
              }
            </div>
            <p>{{ type.name || type.type || type.deliveryId || type.number }}</p>
          </div>
          <div class="details-value">
            @if (type.mappedRecordInfo) {
              <iov-button
                class="large"
                type="icon"
                tp="Delete"
                (pressed)="removeMapping(type.mappedRecordInfo)"
              >
                <mat-icon>delete</mat-icon>
              </iov-button>
            } @else {
              @if (formGroup.controls[idx].value) {
                <iov-button class="large" type="icon" (pressed)="addInboundMapping(idx)" tp="Add">
                  <mat-icon>check</mat-icon>
                </iov-button>
              } @else {
                <iov-button
                  class="large"
                  tp="Transfer record to own records"
                  type="icon"
                  [disabled]="isTransferButtonDisabled()"
                  (pressed)="transferInboundRecord(type, idx)"
                >
                  <mat-icon>arrow_forward</mat-icon>
                </iov-button>
              }
            }
          </div>
          <div class="details-value">
            <iov-input-select
              class="no-padding"
              [showOptionalHint]="false"
              [placeholder]="inputSelectPlaceholder()"
              [options]="localRecords()"
              [disabled]="!!type.mappedRecordInfo"
              [formControlName]="idx"
            ></iov-input-select>
          </div>
          <div class="details-value">
            <iov-button
              [disabled]="isViewButtonDisabled()"
              class="large"
              type="icon"
              tp="View"
              (pressed)="onView(type)"
            >
              <mat-icon>visibility</mat-icon>
            </iov-button>
          </div>
        </div>
      }
    }
  </form>
</div>
