<a
  class="sidebar-item"
  [class.active]="active"
  [routerLink]="routePath"
  mat-list-item
  #tooltipTarget
>
  <mat-icon
    [svgIcon]="icon"
    [tp]="tooltipContent"
    tpPlacement="right"
    [tpTriggerTarget]="tooltipTarget._elementRef.nativeElement"
    [tpIsEnabled]="showTooltip"
    [tpOffset]="[0, 10]"
    [matBadge]="badge"
    matBadgeSize="small"
    matBadgePosition="after"
    matBadgeColor="warn"
    [matBadgeHidden]="!badge"
  />
  <span #contentRef>
    <ng-content></ng-content>
  </span>
</a>
