<div
  class="organisation-switcher clickable"
  (menuClosed)="updateDropdownState()"
  (menuOpened)="updateDropdownState()"
  [matMenuTriggerFor]="orgMenu"
  [class.no-top-border]="hasNoTopBorder"
>
  <div class="description" [tp]="currentOrganisation.name">
    {{ currentOrganisation.name }}
  </div>
  <mat-icon svgIcon="keyboard_arrow_down"></mat-icon>
</div>
<mat-menu #orgMenu="matMenu" class="organisation-menu">
  <button
    type="button"
    mat-menu-item
    class="has-icon create-organisation"
    (click)="createOrganisation()"
  >
    <mat-icon svgIcon="group-216"></mat-icon>
    <mat-icon svgIcon="plus-2" class="add-organisation-icon"></mat-icon>
    <span>Add organisation</span>
  </button>

  @for (organisation of userData.availableOrganisations; track organisation.id) {
    <button
      type="button"
      class="organisation-button"
      mat-menu-item
      [class.active]="currentOrganisation.id === organisation.id"
      (click)="changeOrganisation($index)"
      [tp]="organisation.name"
      tpPlacement="right"
      [tpDelay]="500"
    >
      <span class="organisation-name">{{ organisation.name }}</span>

      @if (currentOrganisation.id === organisation.id) {
        <span class="active-icon"><mat-icon svgIcon="check-1"></mat-icon></span>
      }
    </button>
  }
</mat-menu>
