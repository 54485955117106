@if ({ isCollapsed: sidebarCollapsedState$ | async }; as sidebar) {
  <mat-toolbar [ngClass]="{ 'flex-end': !hasOrganisationOptions }">
    <div *ngIf="hasOrganisationOptions" class="container-flex">
      <mat-icon inline="true" class="logo" svgIcon="logo-symbol-night"></mat-icon>
      <div *ngIf="currentOrganisation" class="user-data-dropdown-container">
        <iov-sidebar-dropdown
          [currentUrl]="currentUrl"
          [currentOrganisation]="currentOrganisation"
          [userData]="userData"
        ></iov-sidebar-dropdown>
      </div>
      @if (isBackToInitialSharedPageVisible) {
        <iov-button (pressed)="onBackToInitialSharedPage()" type="ghost">
          Back to main shared record
        </iov-button>
      }
    </div>
    @if (userData) {
      <div class="container-flex">
        @if (currentOrganisation && !currentOrganisation.isSystemAdmin && canAddModifyEntities) {
          <iov-shortcut-add-menu [isAdminMenuEnabled]="isAdminMenuEnabled"></iov-shortcut-add-menu>
        }
        <iov-user-account-button
          [userType]="userType"
          [userName]="userName"
          [userData]="userData"
          [pendingInvitationsCount]="pendingInvitationsCount"
        ></iov-user-account-button>
      </div>
    }
  </mat-toolbar>
  <div
    class="main-container"
    [ngClass]="{ 'menu-collapsed': sidebar.isCollapsed, 'no-border-top': !hasOrganisationOptions }"
  >
    @if (!hasOrganisationOptions) {
      <div class="no-organisations-container">
        @if (urlStartsWithInvitations) {
          <div class="router-container">
            <router-outlet></router-outlet>
          </div>
        } @else {
          <div class="info">
            <p>It seems that you are not part of any organisation</p>
            <iov-button (pressed)="createOrganisation()">
              <mat-icon>add</mat-icon>
              Create new organisation
            </iov-button>
          </div>
        }
      </div>
    } @else {
      <mat-sidenav-container class="sidenav-container">
        @if (userType === authUserTypeEnum.REGULAR && !!currentOrganisation) {
          <mat-sidenav mode="side" opened [fixedInViewport]="true" role="navigation">
            <mat-nav-list>
              <ng-container
                [ngTemplateOutlet]="sidebarMenuItemTemplate"
                [ngTemplateOutletContext]="{
                  items: currentOrganisation.isSystemAdmin ? systemAdminMenuItems : menuItems,
                }"
              ></ng-container>
            </mat-nav-list>
          </mat-sidenav>
        }
        <ng-template #sidebarMenuItemTemplate let-items="items">
          @for (item of items; track $index) {
            @if (!item.isDisabledFn || !item.isDisabledFn()) {
              <iov-sidebar-item
                [routePath]="[item.route]"
                [showTooltip]="sidebar.isCollapsed"
                [active]="isActiveRoute(item.route, item.isActiveRouteExact)"
                [icon]="item.icon"
                [badge]="item.badgeFn ? item.badgeFn() : undefined"
              >
                <span class="menu-title">{{ item.title }}</span>
              </iov-sidebar-item>
            }
          }
        </ng-template>

        <mat-sidenav-content
          [ngClass]="
            sidebar.isCollapsed
              ? 'sidenav-content-with-collapsed-menu'
              : 'sidenav-content-with-expanded-menu'
          "
        >
          @if (userType === authUserTypeEnum.REGULAR) {
            <div
              class="menu-collapse-button-container"
              [class]="sidebar.isCollapsed ? 'collapsed' : 'expanded'"
            >
              <button
                type="button"
                [tp]="(sidebar.isCollapsed ? 'Expand' : 'Collapse') + ' menu'"
                tpPlacement="right"
                mat-mini-fab
                (click)="onToggleMenuCollapse()"
              >
                <mat-icon
                  [svgIcon]="'chevron-' + (sidebar.isCollapsed ? 'right' : 'left')"
                ></mat-icon>
              </button>
            </div>
          }
          <router-outlet></router-outlet>
        </mat-sidenav-content>
      </mat-sidenav-container>
    }
  </div>
}
