<iov-slide-overlay-header
  [canArchive]="false"
  [title]="title()"
  icon="certificates"
></iov-slide-overlay-header>
<iov-slide-overlay-content #slideOverlayContent>
  <ng-template
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="menu-info"
    overlay-menu-title="Certificate details"
  >
    <iov-form-field-group [name]="mainInformationText">
      <app-certificate-overlay-main-information
        class="full-width"
        [formGroup]="formGroup"
        [standardTypeOptions]="standardTypeOptions()"
        [standardOptions]="standardOptions()"
      ></app-certificate-overlay-main-information>
    </iov-form-field-group>
  </ng-template>
  <ng-template
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.ATTACHED_TO).isEnabled"
    [overlay-menu-indicator]="selectedAttachments()?.length"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="overlayTabEnum.ATTACHED_TO"
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="paperclip"
  >
    <article class="field-group-container no-padding-bottom">
      <app-attachments-table
        [isInboundShared]="true"
        [attachments]="selectedAttachments()"
      ></app-attachments-table>
    </article>
  </ng-template>
  <ng-template
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.DOCUMENTS).isEnabled"
    [overlay-menu-indicator]="attachedDocuments()?.length"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="overlayTabEnum.DOCUMENTS"
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="document"
  >
    <article class="field-group-container">
      <app-documents-table
        [isInboundShared]="true"
        [documents]="attachedDocuments()"
        [inboundSharedSenderOrgId]="organisationIdQueryParam"
      ></app-documents-table>
    </article>
  </ng-template>
</iov-slide-overlay-content>
