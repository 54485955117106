import { ChangeDetectionStrategy, Component, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { RecordStateEnum, RoutingEnum } from "src/app/shared/enums";

import { EditProcessTypeDialogComponent } from "..";
import { ProcessTypesTableComponent } from "../../shared";

@Component({
  templateUrl: "./settings-process-types.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsProcessTypesComponent {
  @ViewChild("processTypesTable") processTypesTable: ProcessTypesTableComponent;

  public readonly routingEnum = RoutingEnum;

  public readonly recordStateEnum = RecordStateEnum;

  constructor(private dialog: MatDialog) {}

  public onAdd = async (): Promise<void> => {
    const dialogRef = this.dialog.open(EditProcessTypeDialogComponent);

    dialogRef.afterClosed().subscribe(async (result: { hasSaved: boolean }) => {
      if (result?.hasSaved) {
        await this.processTypesTable.getAll();
      }
    });
  };
}
