import { Injectable, Inject } from "@angular/core";

import { Observable, lastValueFrom } from "rxjs";

import { APP_CONFIG } from "@shared/tokens";

import { ApiService } from "./api.service";
import { CommonConstants } from "../../constants";
import { RecordStateEnum } from "../../enums";
import { IPageableContent, IRecordState, IProcessType, IConfig } from "../../interfaces";
import { FormUtils } from "../../utils";
import { AuthenticationService } from "../authentication.service";

@Injectable({
  providedIn: "root",
})
export class ProcessTypesService {
  private getBaseUrl = (): string =>
    `${this.environment.baseUrl}organisations/${this.authenticationService.getActiveOrganisationId()}/process-types`;

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {}

  public getPage = async (
    name: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
    recordState: RecordStateEnum = undefined,
  ): Promise<IPageableContent<IProcessType>> => {
    const url = `${this.getBaseUrl()}?${FormUtils.addUrlParams({ name, size, page, sort, recordState })}`;

    return await lastValueFrom(this.apiService.get<IPageableContent<IProcessType>>(url));
  };

  public getPageSubscription = (
    name: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
    recordState: RecordStateEnum = undefined,
  ): Observable<IPageableContent<IProcessType>> => {
    const url = `${this.getBaseUrl()}?${FormUtils.addUrlParams({ name, size, page, sort, recordState })}`;

    return this.apiService.get<IPageableContent<IProcessType>>(url);
  };

  public async getAll(recordState: RecordStateEnum = undefined): Promise<IProcessType[]> {
    const result: IProcessType[] = [];
    let page = 0;
    let hasNextPage: boolean = true;

    while (hasNextPage) {
      const {
        content,
        page: { totalElements },
      } = await this.getPage(
        undefined,
        CommonConstants.MAX_API_GET_ITEMS_SIZE,
        page,
        undefined,
        recordState,
      );

      result.push(...content);

      page++;
      hasNextPage = result.length < totalElements;
    }

    return result;
  }

  public get = async (processId: string): Promise<IProcessType> =>
    await lastValueFrom(this.apiService.get<IProcessType>(`${this.getBaseUrl()}/${processId}`));

  public createOrUpdate = async (payload: IProcessType, id?: string): Promise<IProcessType> => {
    if (id) {
      return await lastValueFrom(
        this.apiService.put<IProcessType>(`${this.getBaseUrl()}/${id}`, payload),
      );
    } else {
      return await lastValueFrom(
        this.apiService.post<IProcessType>(`${this.getBaseUrl()}`, payload),
      );
    }
  };

  public setRecordState = async (payload: IRecordState, id: string): Promise<IProcessType> =>
    await lastValueFrom(this.apiService.put<IProcessType>(`${this.getBaseUrl()}/${id}`, payload));
}
