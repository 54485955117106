import { inject } from "@angular/core";

import { TippyService } from "@ngneat/helipopper";
import { CreateOptions } from "@ngneat/helipopper/lib/tippy.types";

import { CommonConstants } from "../constants";
import { ICertificate, IDocument, IItem, IProcessExtended } from "../interfaces";

export abstract class SetNodeTooltipClass {
  protected readonly tooltipOptions: Partial<CreateOptions> = {
    allowHTML: true,
    placement: "bottom",
  };

  protected tippyService = inject(TippyService);

  protected setDocumentsTooltip = (documents: IDocument[], nodeElement: any): void => {
    const documentsNode = nodeElement.querySelector(".documents");

    if (!documentsNode) {
      return;
    }
    if (documentsNode._tippy) {
      documentsNode._tippy.destroy();
    }
    if (documents?.length) {
      this.tippyService.create(
        documentsNode,
        this.getTooltip(null, documents, null, null, "Documents"),
        this.tooltipOptions,
      );
    }
  };

  protected setItemsTooltip = (items: IItem[], nodeElement: any): void => {
    const itemNode = nodeElement.querySelector(".items");

    if (!itemNode) {
      return;
    }
    if (itemNode._tippy) {
      itemNode._tippy.destroy();
    }
    if (items?.length) {
      this.tippyService.create(
        itemNode,
        this.getTooltip(null, null, items, null, "Items"),
        this.tooltipOptions,
      );
    }
  };

  protected setProcessesTooltip = (processes: IProcessExtended[], nodeElement: any): void => {
    const itemNode = nodeElement.querySelector(".processes");

    if (!itemNode) {
      return;
    }
    if (itemNode._tippy) {
      itemNode._tippy.destroy();
    }
    if (processes?.length) {
      this.tippyService.create(
        itemNode,
        this.getTooltip(null, null, null, processes, "Processes"),
        this.tooltipOptions,
      );
    }
  };

  protected setCertificatesTooltip = (certificates: ICertificate[], nodeElement: any): void => {
    const certificatesNode = nodeElement.querySelector(".certificates");

    if (!certificatesNode) {
      return;
    }
    if (certificatesNode._tippy) {
      certificatesNode._tippy.destroy();
    }
    if (certificates?.length) {
      this.tippyService.create(
        certificatesNode,
        this.getTooltip(certificates, null, null, null, "Certificates"),
        this.tooltipOptions,
      );
    }
  };

  protected getTooltip = (
    certificates: ICertificate[] = [],
    documents: IDocument[] = [],
    items: IItem[] = [],
    processes: IProcessExtended[] = [],
    type: string,
  ): string => {
    let result = `<div class="node-tooltip"><div class="title text-center">${type}</div><ul>`;
    let additionalEntitiesCount = 0;
    const maxEntitiesToDisplay = CommonConstants.MAX_TOOLTIP_LINES_AMOUNT;

    switch (type) {
      case "Documents":
        additionalEntitiesCount = Math.max(0, documents.length - maxEntitiesToDisplay);
        documents.slice(0, maxEntitiesToDisplay).forEach((doc) => {
          result += `<li><b>${doc.typeName ?? doc.type?.name ?? "NA"}:</b> ${doc.name}</li>`;
        });
        break;
      case "Certificates":
        additionalEntitiesCount = Math.max(0, certificates.length - maxEntitiesToDisplay);
        certificates.slice(0, maxEntitiesToDisplay).forEach((cert) => {
          result += `<li><b>${cert.standard?.name ?? "N/A"}${
            cert.standardType ? ` ${cert.standardType.fullName}` : ""
          }:</b> ${cert.number}</li>`;
        });
        break;
      case "Items":
        additionalEntitiesCount = Math.max(0, items.length - maxEntitiesToDisplay);
        items.slice(0, maxEntitiesToDisplay).forEach((item) => {
          result += `
                    <li><b>${item.productName}:</b> ${item.itemId}</li>`;
        });
        break;
      case "Processes":
        additionalEntitiesCount = Math.max(0, processes.length - maxEntitiesToDisplay);
        processes.slice(0, maxEntitiesToDisplay).forEach((process) => {
          result += `
                    <li><b>${process?.type?.name}:</b> ${process.processId}</li>`;
        });
        break;
    }
    if (additionalEntitiesCount) {
      result += `<li>${additionalEntitiesCount} more...</li>`;
    }

    return `${result}</ul></div>`;
  };

  protected setLocationTypesTooltips = (nodeElement: any): void => {
    const locationTypeNodes = nodeElement.querySelectorAll(".location-type-text");

    if (!locationTypeNodes?.length) {
      return;
    }

    for (const locationTypeNode of locationTypeNodes) {
      if (locationTypeNode._tippy) {
        locationTypeNode._tippy.destroy();
      }
      const textContent = locationTypeNode.textContent;

      if (textContent?.length > 9) {
        this.tippyService.create(locationTypeNode, textContent, this.tooltipOptions);
      }
    }

    const pointOfOriginNodes = nodeElement.querySelectorAll(".point-of-origin");

    if (!pointOfOriginNodes?.length) {
      return;
    }

    for (const pointOfOriginNode of pointOfOriginNodes) {
      if (pointOfOriginNode._tippy) {
        pointOfOriginNode._tippy.destroy();
      }
      this.tippyService.create(
        pointOfOriginNode,
        CommonConstants.POINT_OF_ORIGIN_CHIP_TEXT,
        this.tooltipOptions,
      );
    }
  };
}
