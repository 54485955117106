import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  signal,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { Subscription } from "rxjs";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { ExtensionProviderEnum, StartEndEnum } from "@shared/enums";
import { AnalysisTypeEnum } from "@shared/enums/analysis-type.enum";
import { ICreateAnalysis, IExtensionOption } from "@shared/interfaces";
import {
  AnalysisExtensionService,
  AuthenticationService,
  ExtensionsService,
} from "@shared/services";
import { FormUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

@Component({
  templateUrl: "./add-analysis-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddAnalysisDialogComponent implements OnInit, OnDestroy {
  public typeOptions: IExtensionOption[] = [];

  public formGroup: UntypedFormGroup = null;

  public isLoading = signal(true);

  public readonly maxDate = new Date();

  public error: string;

  private activeOrganisationId: string;

  private subscriptions = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<AddAnalysisDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { locationId: string },
    private analysisExtensionService: AnalysisExtensionService,
    private extensionsService: ExtensionsService,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService,
  ) {
    this.activeOrganisationId = this.authenticationService.getActiveOrganisationId();

    const entityExistsValidatorArgs: any = {
      locationId: this.data.locationId,
    };

    this.formGroup = new UntypedFormGroup({
      type: new UntypedFormControl(null, [CustomValidators.required]),
      name: new UntypedFormControl(
        null,
        [CustomValidators.required],
        [
          CustomValidators.entityAlreadyExists(
            this.analysisExtensionService,
            undefined,
            entityExistsValidatorArgs,
          ),
        ],
      ),
      time: new UntypedFormControl(
        [null, null],
        [CustomValidators.required, CustomValidators.dateRange],
      ),
    });

    this.subscriptions.add(
      this.formGroup.controls["type"].valueChanges.subscribe((type: IExtensionOption) => {
        const otherFormControlNames = ["name", "time"];

        FormUtils.disableControls(this.formGroup, otherFormControlNames);
        if (type?.enabled) {
          FormUtils.enableControls(this.formGroup, otherFormControlNames);
        }
      }),
    );
  }

  public async ngOnInit(): Promise<void> {
    const enabledExtensions = await this.extensionsService.getAllEnabled(
      this.authenticationService.getActiveOrganisationId(),
    );

    this.typeOptions = [
      {
        label: AnalysisTypeEnum.ORBIFY_DEFORESTATION,
        value: ExtensionProviderEnum.ORBIFY,
        enabled: enabledExtensions.includes(ExtensionProviderEnum.ORBIFY),
      },
    ];

    this.formGroup.controls["type"].setValue(this.typeOptions[0] ?? null);

    this.isLoading.set(false);
  }

  get typeControl() {
    return this.formGroup.controls["type"];
  }

  public isSubmitButtonDisabled = (): boolean =>
    this.formGroup.invalid || this.formGroup.pending || !this.typeControl?.value?.enabled;

  public onSubmit = async (): Promise<void> => {
    this.isLoading.set(true);
    this.error = null;
    const payload: ICreateAnalysis = {
      location: `/organisations/${this.activeOrganisationId}/locations/${this.data.locationId}`,
      providerId: this.formGroup.controls["type"].value.value,
      name: this.formGroup.controls["name"].value.trim(),
      parameters: {
        type: this.formGroup.controls["type"].value.label,
      },
      startTime: FormUtils.getDateValueForPayload(this.formGroup.controls["time"].value[0]),
      endTime: FormUtils.getDateValueForPayload(
        this.formGroup.controls["time"].value[1],
        StartEndEnum.END,
      ),
    };

    try {
      await this.analysisExtensionService.create(payload);
      this.notificationService.showSuccess(
        `The analysis report is being generated and will be available to view shortly`,
      );
      this.onClose(true);
    } catch (error) {
      const errorMsg: string =
        error.error?.errors?.length && error.error?.errors[0]?.message
          ? error.error?.errors[0]?.message
          : null;

      if (errorMsg?.includes("Area submitted exceeds")) {
        this.error = errorMsg;
      } else {
        this.notificationService.showError(error);
      }
    } finally {
      this.isLoading.set(false);
    }
  };

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onClose = (hasSaved = false): void => {
    this.dialogRef.close({ hasSaved });
  };
}
