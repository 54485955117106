<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <h2 mat-dialog-title>{{ isEditing() ? "Edit" : "Add" }} location type</h2>

  <app-loader *ngIf="isLoading()" containerClass="on-dialog"></app-loader>

  <ng-container *ngIf="!isLoading()">
    <mat-dialog-content>
      <iov-input
        [autofocus]="!isLoading() && !isEditing()"
        formControlName="type"
        label="Name"
      ></iov-input>
      <iov-button-toggle formControlName="pointOfOrigin">
        Point of origin
        <mat-icon class="info-icon" svgIcon="help" [tp]="pointOfOriginIconTooltip"></mat-icon>
      </iov-button-toggle>
    </mat-dialog-content>

    <mat-dialog-actions>
      <iov-button-group>
        <iov-button (pressed)="onClose()" type="ghost">Cancel</iov-button>
        <iov-button [disabled]="isSubmitButtonDisabled()" accessibilityRole="submit">
          <mat-icon icon-left>check</mat-icon>
          Save
        </iov-button>
      </iov-button-group>
    </mat-dialog-actions>
  </ng-container>
</form>
