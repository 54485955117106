<section
  [class.more-padding-bottom]="
    !(isRegularUser && shouldDisplayTags() && instance?.entityType && instance?.recordId)
  "
  class="page-title-container"
>
  <div class="row gap-10">
    @if (isSvgIcon()) {
      <mat-icon class="page-icon" [svgIcon]="icon()" />
    } @else {
      <mat-icon class="page-icon">{{ icon() }}</mat-icon>
    }
    <div class="page-title">
      {{ title() }}
      @if (isRecordActive) {
        <iov-tag-group class="ml-12 no-padding" [tags]="['Archived']"></iov-tag-group>
      }
    </div>

    <section class="right-side-buttons">
      @if (overlay.viewMode()) {
        @if (!this.overlay.loading()) {
          <ng-content select="[right-side-button]"></ng-content>
        }
        @if (shouldShowMoreActionsButton()) {
          <iov-button class="page-button-more" size="large" type="icon" [matMenuTriggerFor]="menu">
            <mat-icon svgIcon="more-horizontal"></mat-icon>
          </iov-button>
        }
      }
    </section>
  </div>

  @if (isRegularUser && instance?.entityType && shouldDisplayTags()) {
    <div class="entity-tag-container">
      <app-entity-tags
        [initialTags]="initialTags()"
        [entityType]="instance.entityType"
        [elementId]="instance.recordId"
        (tagsChanged)="onTagsChanged($event)"
      ></app-entity-tags>
    </div>
  }
</section>

<mat-menu #menu class="dropdown-menu">
  <iov-dropdown-menu>
    @if (isShowConnectionButtons) {
      @if (isAddConectionEnabled) {
        <iov-dropdown-menu-item (pressed)="addConnectionHandler()">
          <mat-icon>link</mat-icon>
          Connect organisation
        </iov-dropdown-menu-item>
      } @else {
        <iov-dropdown-menu-item (pressed)="editConnectionHandler()">
          <mat-icon>edit</mat-icon>
          Edit connection
        </iov-dropdown-menu-item>
        <iov-dropdown-menu-item (pressed)="removeConnectionHandler()">
          <mat-icon>cancel</mat-icon>
          Remove connection
        </iov-dropdown-menu-item>
      }
    }
    @if (canDuplicate()) {
      <iov-dropdown-menu-item (pressed)="duplicateHandler()">
        <mat-icon svgIcon="copy"></mat-icon>
        Duplicate
        <mat-icon
          class="duplicate-icon"
          tp="This will only duplicate the item details and will allow the user to edit them
                    before saving the new item."
          svgIcon="help"
        ></mat-icon>
      </iov-dropdown-menu-item>
    }
    @if (canArchive()) {
      <iov-dropdown-menu-item (pressed)="toggleArchive()">
        <mat-icon>{{ isRecordActive ? "inventory" : "archive" }}</mat-icon>
        {{ isRecordActive ? "Unarchive" : "Archive" }}
      </iov-dropdown-menu-item>
    }
    @if (canShare()) {
      <iov-dropdown-menu-item (pressed)="shareHandler()">
        <mat-icon>share</mat-icon>
        Share
      </iov-dropdown-menu-item>
    }
    @if (canSend()) {
      <iov-dropdown-menu-item (pressed)="sendHandler()">
        <mat-icon>send</mat-icon>
        Send
      </iov-dropdown-menu-item>
    }
    @if (canDelete()) {
      <iov-dropdown-menu-item (pressed)="deleteHandler()">
        <mat-icon>delete</mat-icon>
        Delete
      </iov-dropdown-menu-item>
    }
    <ng-content select="[additional-options]"></ng-content>
  </iov-dropdown-menu>
</mat-menu>
