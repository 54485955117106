<app-table
  (rowClick)="onClick($event)"
  *ngIf="columnDefs().length"
  [areButtonsEnabled]="areButtonsEnabled()"
  [columnDefs]="columnDefs()"
  [isFixedBottomPaginator]="isFixedBottomPaginator()"
  [isLoading]="isLoading()"
  [isPaginatorEnabled]="isPaginatorEnabled()"
  [isRecordStateFilterEnabled]="isRecordStateFilterEnabled()"
  [isSaveState]="isSaveTableState()"
  [isSearchEnabled]="isSearchEnabled()"
  [recordState]="recordState()"
  [rowData]="rowData()"
  [table]="table()"
></app-table>
