import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

import { UploadFileStatusEnum } from "src/app/shared/enums";
import { IFileUpload, IFileUploadStatusText } from "src/app/shared/interfaces";

@Component({
  selector: "iov-document-upload-status",
  templateUrl: "./document-upload-status.component.html",
  styleUrl: "./document-upload-status.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentUploadStatusComponent {
  @Input() uploadStatusTexts: IFileUploadStatusText[];

  @Input() files: IFileUpload[] = [];

  @Input() isAttachProcess: boolean;

  @Output() clickRetryUploadEvent: EventEmitter<IFileUpload> = new EventEmitter();

  @Output() clickViewFile: EventEmitter<number> = new EventEmitter();

  public readonly uploadFileStatusEnum = UploadFileStatusEnum;

  onClickRetryUpload(file: IFileUpload): void {
    this.clickRetryUploadEvent.emit(file);
  }

  onClickViewFileEvent(index: number): void {
    this.clickViewFile.emit(index);
  }
}
