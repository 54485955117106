import { Injectable } from "@angular/core";
import { DateAdapter } from "@angular/material/core";
import { MatCalendar, yearsPerPage } from "@angular/material/datepicker";

@Injectable({
  providedIn: "root",
})
export class DatepickerService<D> {
  constructor(private dateAdapter: DateAdapter<D>) {}

  formatMinAndMaxYearLabels(
    calendar: MatCalendar<D>,
  ): [minYearLabel: string, maxYearLabel: string] {
    const activeYear = this.dateAdapter.getYear(calendar.activeDate);

    const offset = this.getActiveOffset(
      this.dateAdapter,
      calendar.activeDate,
      calendar.minDate,
      calendar.maxDate,
    );

    const minYearOfPage = activeYear - offset;
    const maxYearOfPage = minYearOfPage + yearsPerPage - 1;
    const minYearLabel = this.dateAdapter.getYearName(
      this.dateAdapter.createDate(minYearOfPage, 0, 1),
    );
    const maxYearLabel = this.dateAdapter.getYearName(
      this.dateAdapter.createDate(maxYearOfPage, 0, 1),
    );

    return [minYearLabel, maxYearLabel];
  }

  private getActiveOffset<D>(
    dateAdapter: DateAdapter<D>,
    activeDate: D,
    minDate: D | null,
    maxDate: D | null,
  ): number {
    const activeYear = dateAdapter.getYear(activeDate);
    const startingYear = this.getStartingYear(dateAdapter, minDate, maxDate);

    return this.euclideanModulo(activeYear - startingYear, yearsPerPage);
  }

  private getStartingYear<D>(
    dateAdapter: DateAdapter<D>,
    minDate: D | null,
    maxDate: D | null,
  ): number {
    let startingYear = 0;

    if (maxDate) {
      const maxYear = dateAdapter.getYear(maxDate);

      startingYear = maxYear - yearsPerPage + 1;
    } else if (minDate) {
      startingYear = dateAdapter.getYear(minDate);
    }

    return startingYear;
  }

  private euclideanModulo(a: number, b: number): number {
    return ((a % b) + b) % b;
  }
}
