export type Mode = ModeEnum;
export enum ModeEnum {
  MONTH = "month",
  YEAR = "year",
}

export enum CalendarViewEnum {
  MONTH = "month",
  YEAR = "year",
  MULTIYEAR = "multi-year",
}
