import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, Inject, OnInit, signal } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import moment from "moment";
import { IApiKey, IApiKeyPayload, IApiKeyResponse, ISelectOption } from "src/app/shared/interfaces";
import { ApiKeysService, AuthenticationService } from "src/app/shared/services";
import { FormUtils } from "src/app/shared/utils";
import { CustomValidators } from "src/app/shared/validators";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { StartEndEnum } from "../../../../shared/enums";

@Component({
  templateUrl: "./edit-api-key-dialog.component.html",
  styleUrls: ["./edit-api-key-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditApiKeyDialogComponent implements OnInit {
  public formGroup: UntypedFormGroup = null;

  public isLoading = signal(true);

  public isEditing = signal(false);

  public isShowSavedApiKey = false;

  public savedApiKey: string = null;

  public readonly expiryOptions: ISelectOption[] = [
    { label: "1 day", value: 1 },
    { label: "7 days", value: 7 },
    { label: "30 days", value: 30 },
  ];

  private activeOrganisationId: string = null;

  constructor(
    public dialogRef: MatDialogRef<EditApiKeyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { apiKey?: IApiKey },
    private apiKeysService: ApiKeysService,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService,
  ) {
    this.activeOrganisationId = this.authenticationService.getActiveOrganisationId();
  }

  public async ngOnInit(): Promise<void> {
    this.isEditing.set(!!this.data?.apiKey?.id);

    this.setupForm();

    this.isLoading.set(false);
  }

  private setupForm = (): void => {
    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl(
        this.data?.apiKey?.name ?? null,
        [CustomValidators.required],
        [CustomValidators.entityAlreadyExists(this.apiKeysService, this.data?.apiKey?.id ?? null)],
      ),
      note: new UntypedFormControl(this.data?.apiKey?.note ?? null),
      expiry: new UntypedFormControl(this.data?.apiKey?.expiry ?? null, [
        CustomValidators.required,
        CustomValidators.date,
      ]),
      enabled: new UntypedFormControl(this.isEditing() ? this.data.apiKey.enabled : true, [
        CustomValidators.required,
      ]),
    });
  };

  public isSubmitButtonDisabled = (): boolean => this.formGroup.invalid || this.formGroup.pending;

  public onSubmit = async (): Promise<void> => {
    this.isLoading.set(true);

    const payload: IApiKeyPayload = {
      organisation: `/organisations/${this.activeOrganisationId}`,
      name: this.formGroup.controls["name"].value.trim(),
      note: this.formGroup.controls["note"].value
        ? this.formGroup.controls["note"].value.trim()
        : undefined,
    };

    if (this.isEditing()) {
      payload.enabled = this.formGroup.controls["enabled"].value;
      payload.expiry = FormUtils.getDateValueForPayload(this.formGroup.controls["expiry"].value);
    } else {
      const expiryDate = moment().add(+this.formGroup.controls["expiry"].value.value, "days");

      payload.expiry = FormUtils.getDateValueForPayload(expiryDate, StartEndEnum.END);
    }

    await this.apiKeysService
      .createOrUpdate(payload, this.data?.apiKey?.id)
      .then((response: IApiKeyResponse) => {
        this.notificationService.showSuccess(
          `API key ${this.isEditing() ? "modified" : "created"}`,
        );
        if (this.isEditing()) {
          this.onClose(true);
        } else {
          this.savedApiKey = response.token;
          this.isShowSavedApiKey = true;
        }
      })
      .catch((error: HttpErrorResponse) => {
        this.notificationService.showError(error);
      })
      .finally(() => {
        this.isLoading.set(false);
      });
  };

  public onClose = (hasSaved = false): void => {
    this.dialogRef.close({ hasSaved });
  };
}
