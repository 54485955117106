import { ChangeDetectionStrategy, Component } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  template: `
    <div class="container-flex-right on-hover-visibility-show">
      <ng-container *ngFor="let button of params['buttons']">
        <button
          *ngIf="isVisible(button.isVisible)"
          type="button"
          (click)="$event.stopPropagation(); onClick(button.onClick)"
          mat-icon-button
          [tp]="getTooltip(button.tooltip)"
          [tpIsLazy]="true"
        >
          <mat-icon [class]="getIconClass(button.iconClass)">{{ getIcon(button.icon) }}</mat-icon>
        </button>
      </ng-container>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonsCellRendererComponent implements ICellRendererAngularComp {
  public params!: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  isVisible = (isVisible: boolean | Function): boolean => {
    if (isVisible === undefined) {
      return true;
    }
    if (typeof isVisible === "boolean") {
      return isVisible;
    }

    return isVisible(this.getData());
  };

  getTooltip = (tooltip: string | Function): string => {
    if (!tooltip) {
      return "";
    }
    if (typeof tooltip === "string") {
      return tooltip;
    }

    return tooltip(this.getData());
  };

  getIcon = (icon: string | Function): string => {
    if (typeof icon === "string") {
      return icon;
    }

    return icon(this.getData());
  };

  getIconClass = (iconClass: string | Function): string => {
    if (!iconClass) {
      return "";
    }
    if (typeof iconClass === "string") {
      return iconClass;
    }

    return iconClass(this.getData());
  };

  onClick = async (clickFunction: Function): Promise<void> => {
    await clickFunction(this.getData());
  };

  private getData = (): any => this.params.data ?? this.params.node.allLeafChildren[0].data;
}
