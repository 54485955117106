import { Injectable, inject } from "@angular/core";

import { IRecordState } from "@shared/interfaces";
import { ApiService, AuthenticationService } from "@shared/services";
import { APP_CONFIG } from "@shared/tokens";
import { FormUtils } from "@shared/utils";

import {
  PageableRiskAssessmentTemplates,
  RiskAssessmentTemplate,
  RiskAssessmentTemplatePayload,
  RiskAssessmentTemplatesParams,
} from "../models";

@Injectable({ providedIn: "root" })
export class RiskAssessmentTemplatesApiService {
  private readonly api = inject(ApiService);

  private readonly authService = inject(AuthenticationService);

  private readonly environment = inject(APP_CONFIG);

  private readonly getBaseUrl = () =>
    `${this.environment.baseUrl}organisations/${this.authService.getActiveOrganisationId()}/risk-assessment/templates`;

  getAll(params: RiskAssessmentTemplatesParams) {
    const query = FormUtils.addUrlParams(params);

    return this.api.get<PageableRiskAssessmentTemplates>(`${this.getBaseUrl()}?${query}`);
  }

  get(id: string) {
    return this.api.get<RiskAssessmentTemplate>(`${this.getBaseUrl()}/${id}`);
  }

  createOrUpdate(payload: RiskAssessmentTemplatePayload, id?: string) {
    return id
      ? this.api.put<RiskAssessmentTemplate>(`${this.getBaseUrl()}/${id}`, payload)
      : this.api.post<RiskAssessmentTemplate>(this.getBaseUrl(), payload);
  }

  delete(id: string) {
    return this.api.delete<void>(`${this.getBaseUrl()}/${id}`);
  }

  setRecordState(id: string, payload: IRecordState) {
    return this.api.put<RiskAssessmentTemplate>(`${this.getBaseUrl()}/${id}`, payload);
  }
}
