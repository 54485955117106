import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { CommonUtils } from "src/app/shared/utils";

import { ButtonType } from "@design-makeover/components/buttons/button/button.model";
import { NotificationService } from "@design-makeover/services/notification/notification.service";

@Component({
  selector: "app-copy-text-button",
  templateUrl: "./copy-text-button.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyTextButtonComponent {
  @Input()
  public text: string;

  @Input()
  public buttonText: string;

  @Input()
  public isShowLinkText?: boolean = true;

  @Input()
  public buttonType?: ButtonType;

  public isShowingText = false;

  private readonly translations: any = {
    copied: "Copied",
  };

  constructor(private notificationService: NotificationService) {}

  public onButtonClick = (): void => {
    CommonUtils.textToClipboard(this.text);
    this.notificationService.showSuccess(this.translations.copied);
  };

  public onToggleShowText = (): void => {
    this.isShowingText = !this.isShowingText;
  };
}
