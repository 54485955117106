import { HttpErrorResponse } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  signal,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { Observable, Subject, Subscription } from "rxjs";

import { InputChipsComponent } from "@design-makeover/components/inputs/input-chips/input-chips.component";
import { InputSelectOption } from "@design-makeover/components/inputs/input-select/input-select.model";
import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { CommonConstants } from "@shared/constants";
import { CustomFieldsResourceTypeEnum, RecordStateEnum } from "@shared/enums";
import {
  IAttachmentPayload,
  ICustomField,
  ILocationDetails,
  ILocationPayload,
  IOrganisation,
  ISelectOption,
} from "@shared/interfaces";
import {
  AttachmentsService,
  AuthenticationService,
  ConnectionsService,
  CustomFieldsService,
  LocationsService,
  LocationTypesService,
  OrganisationsService,
} from "@shared/services";
import { CustomFieldsUtils, FormUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

@Component({
  templateUrl: "./add-location-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddLocationDialogComponent implements OnInit {
  @ViewChild("locationTypesInputChips") locationTypesInputChips: InputChipsComponent;

  public organisationOptions: InputSelectOption[] = [];

  public formGroup: UntypedFormGroup;

  public isLoading = signal(true);

  public readonly sameAsOrganisationAddressText = CommonConstants.SAME_AS_ORG_ADDRESS_TEXT;

  public readonly mainInformationText = CommonConstants.MAIN_INFORMATION_TEXT;

  private activeOrganisation: IOrganisation;

  private allOrganisations: IOrganisation[] = [];

  private refreshTableSubject = new Subject<unknown>();

  private subscriptions = new Subscription();

  private allCustomFields: ICustomField[] = [];

  public visibleCustomFields: ICustomField[] = [];

  constructor(
    public dialogRef: MatDialogRef<AddLocationDialogComponent>,
    public locationTypesService: LocationTypesService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      countryOptions: InputSelectOption[];
      location?: ILocationDetails;
      locationTypes?: ISelectOption[];
      organisation?: IOrganisation;
    },
    private locationsService: LocationsService,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService,
    private connectionsService: ConnectionsService,
    private organisationsService: OrganisationsService,
    private attachmentsService: AttachmentsService,
    private customFieldsService: CustomFieldsService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.isLoading.set(true);
    const activeOrganisationId = this.authenticationService.getActiveOrganisationId();

    await Promise.all([
      (this.activeOrganisation = await this.organisationsService.get(activeOrganisationId)),
      (this.allCustomFields = await this.customFieldsService.getAll(
        CustomFieldsResourceTypeEnum.LOCATION,
      )),
    ]);

    if (this.data.organisation) {
      const selectedOrganisation = {
        value: this.data.organisation.id,
        label: this.data.organisation.name,
      };

      this.allOrganisations = [this.data.organisation];
      this.organisationOptions = [selectedOrganisation];
    } else {
      await this.getOrganisations();
    }

    this.setupForm();

    this.isLoading.set(false);
  }

  private setupForm = (): void => {
    let organisationValue: any;

    if (this.data.organisation) {
      organisationValue = {
        value: this.data.organisation.id,
        label: this.data.organisation.name,
      };
    }

    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl(
        this.data?.location?.name ?? null,
        [CustomValidators.required],
        [CustomValidators.entityAlreadyExists(this.locationsService)],
      ),
      organisation: new UntypedFormControl(
        { value: organisationValue, disabled: !!organisationValue },
        [CustomValidators.required],
      ),
      types: new UntypedFormControl(this.data?.locationTypes ?? [], [CustomValidators.required]),
      street: new UntypedFormControl(this.data?.location?.address?.street ?? null),
      region: new UntypedFormControl(this.data?.location?.address?.region ?? null),
      zipCode: new UntypedFormControl(this.data?.location?.address?.zipCode ?? null),
      country: new UntypedFormControl(
        this.data?.countryOptions?.find((c) => c.value === this.data?.location?.address?.country) ??
          null,
        [CustomValidators.required],
      ),
      addressAsOrganisationAddress: new UntypedFormControl(false),
    });

    this.visibleCustomFields = CustomFieldsUtils.getVisible(this.allCustomFields, null);
    CustomFieldsUtils.addToFormGroup(this.formGroup, this.visibleCustomFields, null);

    this.subscriptions.add(
      this.formGroup.controls["addressAsOrganisationAddress"].valueChanges.subscribe(() => {
        this.setAddressAsOrganisationAddress(
          this.formGroup.controls["addressAsOrganisationAddress"].value,
        );
      }),
    );

    this.subscriptions.add(
      this.formGroup.controls["organisation"].valueChanges.subscribe((organisation) => {
        if (organisation?.value) {
          this.setAddressAsOrganisationAddress(
            this.formGroup.controls["addressAsOrganisationAddress"].value,
          );
        } else {
          FormUtils.enableControls(this.formGroup, ["street", "region", "zipCode", "country"]);
        }
      }),
    );
  };

  get refreshTable$(): Observable<unknown> {
    return this.refreshTableSubject.asObservable();
  }

  public getOrganisations = async (): Promise<void> => {
    await this.connectionsService
      .getAll(RecordStateEnum.ACTIVE)
      .then(async (response: IOrganisation[]) => {
        this.allOrganisations = response;

        const options = this.allOrganisations.map(
          (organisation: IOrganisation) =>
            <ISelectOption>{
              label: organisation.name,
              value: organisation.id,
            },
        );

        this.setOrganisationOptions(options);
      })
      .catch((error) => {
        this.notificationService.showError(error);
      });
  };

  private setOrganisationOptions = (organisationOptionsToAdd: ISelectOption[]): void => {
    const organisationOptions: InputSelectOption[] = [];

    organisationOptions.push({
      label: this.activeOrganisation.name,
      value: this.activeOrganisation.id,
    });
    if (organisationOptionsToAdd?.length) {
      organisationOptions.push(...organisationOptionsToAdd);
    }

    this.organisationOptions = [...organisationOptions];
  };

  private setAddressAsOrganisationAddress(isChecked: boolean): void {
    if (!isChecked) {
      FormUtils.enableControls(this.formGroup, ["street", "region", "zipCode", "country"]);

      return;
    }

    const organisationId = this.formGroup.controls["organisation"].value?.value;

    if (!organisationId) {
      FormUtils.enableControls(this.formGroup, ["street", "region", "zipCode", "country"]);

      return;
    }

    const selectedOrganisation = this.isActiveOrganisationSelected()
      ? this.activeOrganisation
      : this.allOrganisations.find((o) => o.id === organisationId);

    if (!selectedOrganisation) {
      FormUtils.enableControls(this.formGroup, ["street", "region", "zipCode", "country"]);

      return;
    }

    this.formGroup.controls["street"].setValue(selectedOrganisation.address.street);
    this.formGroup.controls["region"].setValue(selectedOrganisation.address.region);
    this.formGroup.controls["zipCode"].setValue(selectedOrganisation.address.zipCode);
    FormUtils.disableControls(this.formGroup, ["street", "region", "zipCode"]);
    const countryValue = this.data.countryOptions.find(
      (c) => c.value === selectedOrganisation.address.country,
    );

    if (countryValue) {
      this.formGroup.controls["country"].setValue(countryValue);
      this.formGroup.controls["country"].disable();
    }
  }

  public onSubmit = async (): Promise<void> => {
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);
      this.notificationService.showError(CommonConstants.FILL_REQUIRED_FIELDS_MSG);

      return;
    }
    this.isLoading.set(true);

    const payload = await this.getSavePayload();

    try {
      const location = await this.locationsService.createOrUpdate(payload);

      await this.createOrganisationAttachment(location);
      this.notificationService.showSuccess("Location created");
      this.onClose(true, location);
    } catch (error) {
      this.notificationService.showError(error);
    } finally {
      this.isLoading.set(false);
    }
  };

  public onClose = (hasSaved = false, location?: ILocationDetails): void => {
    this.dialogRef.close({ hasSaved, location });
  };

  private isActiveOrganisationSelected = (): boolean =>
    this.formGroup.controls["organisation"].value?.value === this.activeOrganisation.id;

  private createOrganisationAttachment = async (location: ILocationDetails): Promise<void> => {
    const payload: IAttachmentPayload = {
      targetUri: this.isActiveOrganisationSelected()
        ? `/organisations/${this.activeOrganisation.id}`
        : `/organisations/${this.activeOrganisation.id}/connections/${this.formGroup.controls["organisation"].value.value}`,
      attachmentUri: `/organisations/${this.activeOrganisation.id}/locations/${location.id}`,
    };

    await this.attachmentsService.create(payload).catch((error: HttpErrorResponse) => {
      this.notificationService.showError(error);
    });
  };

  private async getSavePayload(): Promise<ILocationPayload> {
    const unsavedTypes = this.formGroup.get("types").value.filter((item) => !item.value);

    if (unsavedTypes.length) {
      for (let i = 0; i < unsavedTypes.length; i++) {
        const item = unsavedTypes[i];

        await this.locationTypesInputChips.createTag(item, true);
      }
    }

    const { controls } = this.formGroup;

    const payload: ILocationPayload = {
      name: controls["name"].value.trim(),
      types: this.formGroup
        .get("types")
        .value.map(
          (locationType: InputSelectOption) =>
            `/organisations/${this.activeOrganisation.id}/location-types/${locationType.value}`,
        ),
      address: {
        street: controls["street"].value,
        region: controls["region"].value,
        zipCode: controls["zipCode"].value,
        country: controls["country"].value.value,
      },
    };

    CustomFieldsUtils.addToPayload(
      payload,
      this.activeOrganisation.id,
      this.formGroup,
      this.visibleCustomFields,
    );

    return payload;
  }
}
