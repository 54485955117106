<iov-slide-overlay-wrapper
  [class]="slideOverWrapperClass"
  [close]="isClosed()"
  (clickClose)="onCloseSlideOverlay()"
>
  <div class="full-width">
    <ng-template
      #dynamicContent
      iovBackNavigation
      [canExitFn]="canExit.bind(this)"
      [afterCloseFn]="afterClose.bind(this)"
    ></ng-template>
    @if (isClosed()) {
      <div class="slide-over-content full-height">
        <app-loader></app-loader>
      </div>
    }
  </div>
</iov-slide-overlay-wrapper>
