<iov-slide-overlay-header
  [canArchive]="false"
  [shouldDisplayTags]="false"
  [isInboundShare]="true"
  [title]="title()"
  icon="document"
>
  <ng-container right-side-button>
    @if (canViewContent) {
      <iov-button
        (pressed)="viewContent()"
        class="page-button-more"
        size="large"
        type="icon"
        [tp]="contentTooltip('View')"
      >
        <mat-icon [class.red]="!hasContent">visibility</mat-icon>
      </iov-button>
    }
    <iov-button
      (pressed)="downloadContent()"
      class="page-button-more"
      size="large"
      type="icon"
      [tp]="contentTooltip('Download')"
    >
      <mat-icon [class.red]="!hasContent">download</mat-icon>
    </iov-button>
  </ng-container>
</iov-slide-overlay-header>
<iov-slide-overlay-content #slideOverlayContent>
  <ng-template
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="menu-info"
    overlay-menu-title="Document details"
  >
    <iov-form-field-group [name]="mainInformationText">
      <app-edit-document-main-details
        [formGroup]="formGroup"
        [fileExtension]="fileExtension"
        (clickOnName)="onNameClick()"
        [hasContent]="true"
        [isEditing]="false"
        [documentTypesOptions]="documentTypesOptions()"
      ></app-edit-document-main-details>
    </iov-form-field-group>
  </ng-template>
  <ng-template
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.ATTACHED_TO).isEnabled"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="overlayTabEnum.ATTACHED_TO"
    [overlay-menu-indicator]="element()?.attachedTo?.length"
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="paperclip"
  >
    <article class="field-group-container no-padding-bottom">
      <app-attachments-table
        [isInboundShared]="true"
        [attachments]="selectedAttachments()"
      ></app-attachments-table>
    </article>
  </ng-template>
</iov-slide-overlay-content>
