<iov-form-label label="Item IDs"></iov-form-label>
<div>
  <iov-radio-button-group>
    <iov-radio-button
      (changeEvent)="onModeChange(modeEnum.MANUAL_ENTRY)"
      [checked]="this.formGroup.controls['mode'].value === modeEnum.MANUAL_ENTRY"
      label="Manual Entry"
    ></iov-radio-button>
    &nbsp;
    <iov-radio-button
      (changeEvent)="onModeChange(modeEnum.AUTO_GENERATE)"
      [checked]="this.formGroup.controls['mode'].value === modeEnum.AUTO_GENERATE"
      [disabled]="true"
      label="Auto-generate"
    ></iov-radio-button>
  </iov-radio-button-group>
</div>

<br />

<iov-form-label label="Fixed"></iov-form-label>
<div [formGroup]="formGroup" class="row-container">
  <div class="row fields-row">
    <div class="item">
      <iov-checkbox [formControlName]="fieldEnum.IS_FIXED_TAGS"></iov-checkbox>
    </div>
    <div class="item">
      <iov-form-label label="Tags"></iov-form-label>
      <app-entity-tags
        [entityType]="enityTypeEnum.ITEMS"
        (tagsChanged)="onTagsChanged($event)"
      ></app-entity-tags>
    </div>
  </div>
  <div class="row fields-row">
    <div class="item">
      <iov-checkbox [formControlName]="fieldEnum.IS_FIXED_PRODUCT"></iov-checkbox>
    </div>
    <div class="item">
      <iov-input-select
        [formControlName]="fieldEnum.PRODUCT"
        [options]="productOptions"
        label="Product"
        placeholder="Select product"
      ></iov-input-select>
    </div>
  </div>
  <div class="row fields-row">
    <div class="item">
      <iov-checkbox [formControlName]="fieldEnum.IS_FIXED_MATERIALS"></iov-checkbox>
    </div>
    <div class="item">
      @if (isOldMaterialsEnabled) {
        <iov-input-chips
          [allowCreateTag]="false"
          [allowDeleteTag]="false"
          [options]="materialOptions"
          [tooltip]="
            formGroup.controls['product'].value
              ? ''
              : 'Please, first select a product to see which materials are allowed.'
          "
          [formControlName]="fieldEnum.MATERIALS"
          label="Materials"
        ></iov-input-chips>
      } @else {
        <div class="tag-group-container">
          <iov-form-label
            label="Materials"
            [tooltip]="
              formGroup.controls['product'].value
                ? ''
                : 'Please select a product to see which materials it comprises of.'
            "
          ></iov-form-label>
          @if (formGroup.controls["materials"].value?.length) {
            <iov-tag-group [tags]="formGroup.controls['materials'].value"></iov-tag-group>
          } @else {
            -
          }
        </div>
      }
    </div>
  </div>
  <div class="row fields-row">
    <div class="item">
      <iov-checkbox [formControlName]="fieldEnum.IS_FIXED_UNIT_OF_MEASUREMENT"></iov-checkbox>
    </div>
    <div class="item">
      <iov-input-select
        [options]="formGroup.controls[fieldEnum.UNIT_OF_MEASUREMENT_OPTIONS].value"
        [formControlName]="fieldEnum.UNIT_OF_MEASUREMENT"
        label="Unit of measurement"
      ></iov-input-select>
    </div>
  </div>
  <div class="row fields-row">
    <div class="item">
      <iov-checkbox [formControlName]="fieldEnum.IS_FIXED_INITIAL_QUANTITY"></iov-checkbox>
    </div>
    <div class="item">
      <iov-input
        [hint]="
          selectedUnitOfMeasurement?.id !== systemUnitOfMeasurement?.id &&
          formGroup.controls[fieldEnum.INITIAL_QUANTITY].value
            ? ('' + formGroup.controls[fieldEnum.INITIAL_QUANTITY].value
              | unitConversion: selectedUnitOfMeasurement : systemUnitOfMeasurement)
            : ''
        "
        [suffix]="selectedUnitOfMeasurement?.symbol"
        [formControlName]="fieldEnum.INITIAL_QUANTITY"
        label="Initial quantity"
        type="number"
      ></iov-input>
    </div>
  </div>
  <div class="row fields-row">
    <div class="item">
      <iov-checkbox [formControlName]="fieldEnum.IS_FIXED_CREATED_AT_LOCATION"></iov-checkbox>
    </div>
    <div class="item">
      <iov-input-select
        [options]="locationOptions"
        [formControlName]="fieldEnum.CREATED_AT_LOCATION"
        label="Created at location"
        tooltip="Provide information about where the item was first created /
                    got a new identifier."
      ></iov-input-select>
    </div>
  </div>
  <div class="row fields-row">
    <div class="item">
      <iov-checkbox [formControlName]="fieldEnum.IS_FIXED_CREATION_DATE"></iov-checkbox>
    </div>
    <div class="item">
      @if (dateType === dateTypeEnum.EXACT) {
        <iov-datepicker
          #datePicker
          class="no-padding"
          label="Creation date"
          [formControlName]="fieldEnum.CREATED_FROM"
        ></iov-datepicker>
      } @else if (dateType === dateTypeEnum.RANGE) {
        <iov-daterangepicker
          #dateRangePicker
          class="no-padding"
          label="Creation date"
          [formControlName]="fieldEnum.CREATED_RANGE"
        ></iov-daterangepicker>
      }

      <div class="checkbox-row mb-14">
        <iov-checkbox
          [formControlName]="fieldEnum.IS_RANGE_DATE_TYPE"
          label="Range (from/to)"
        ></iov-checkbox>
      </div>
    </div>
  </div>
  <div class="row fields-row">
    <div class="item">
      <iov-checkbox [formControlName]="fieldEnum.IS_FIXED_CURRENT_LOCATION"></iov-checkbox>
    </div>
    <div class="item">
      <iov-input-select
        [options]="locationOptions"
        [formControlName]="fieldEnum.CURRENT_LOCATION"
        label="Current / last location"
        tooltip="Provide information on what is the item's current location
                    (if the item still physically exists) or what was its last location if it has
                    been already transformed into other items / destroyed."
      ></iov-input-select>
    </div>
  </div>
</div>
