import { Inject, Injectable, inject } from "@angular/core";

import { Observable, firstValueFrom } from "rxjs";
import { IItemsSupplyChainResponse } from "src/app/components/shared/items-supply-chain/item-supply-chain.interface";

import { APP_CONFIG } from "@shared/tokens";

import { ApiService } from "./api.service";
import { GraphService } from "./graph.service";
import { CommonConstants } from "../../constants";
import { RecordStateEnum } from "../../enums";
import {
  IItem,
  IItemPayload,
  IPageableContent,
  IRecordState,
  IConfig,
  IItemExtended,
  IItemGraphQLFilter,
  IItemGraphQLResponse,
  IItemByIdsGraphQLResponse,
} from "../../interfaces";
import { getItemsByIdsGraphQLQuery, getItemsGraphQLQuery } from "../../queries";
import { getItemsSupplyChainQuery } from "../../queries/items-supply-chain.query";
import { CommonUtils, FormUtils } from "../../utils";
import { AuthenticationService } from "../authentication.service";

@Injectable({
  providedIn: "root",
})
export class ItemsService {
  private graphService = inject(GraphService);

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {}

  private getBaseUrl = (): string =>
    `${this.environment.baseUrl}organisations/${this.authenticationService.getActiveOrganisationId()}/items`;

  public getPage = async (
    currentLocation: string = undefined,
    itemId: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
    recordState: RecordStateEnum = undefined,
  ): Promise<IPageableContent<IItem>> => {
    const url = `${this.getBaseUrl()}?${FormUtils.addUrlParams({
      currentLocation,
      itemId,
      size,
      page,
      sort,
      recordState,
    })}`;

    return await this.apiService.get<IPageableContent<IItem>>(url).toPromise();
  };

  public getPageSubscription = (
    itemId: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
    recordState: RecordStateEnum = undefined,
  ): Observable<IPageableContent<IItem>> => {
    const url = `${this.getBaseUrl()}?${FormUtils.addUrlParams({ itemId, size, page, sort, recordState })}`;

    return this.apiService.get<IPageableContent<IItem>>(url);
  };

  public async getAllGraphQL(
    filter: IItemGraphQLFilter = null,
    first: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    include: string[] = [],
  ): Promise<IItemExtended[]> {
    const result: IItemExtended[] = [];
    let cursor: string | undefined = undefined;
    let hasNextPage: boolean = true;
    const activeOrganisationId = this.authenticationService.getActiveOrganisationId();

    while (hasNextPage) {
      const query = getItemsGraphQLQuery(
        activeOrganisationId,
        filter,
        first,
        null,
        cursor,
        null,
        include,
      );
      const { content, pageInfo, totalCount } = await this.graphService.fetchSinglePage<
        IItemGraphQLResponse,
        IItemExtended
      >("loadItems", query, this.graphQLPropertiesToAddFunction);

      result.push(...content);

      cursor = pageInfo.endCursor;
      hasNextPage = result.length < totalCount;
    }

    return result;
  }

  public async getByIdsGraphQL(
    ids: string[],
    first: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    include: string[] = [],
  ): Promise<IItemExtended[]> {
    if (!ids.length) {
      return [];
    }
    const result: IItemExtended[] = [];
    let cursor: string | undefined = undefined;
    let hasNextPage: boolean = true;
    const activeOrganisationId = this.authenticationService.getActiveOrganisationId();

    while (hasNextPage) {
      const query = getItemsByIdsGraphQLQuery(
        activeOrganisationId,
        ids,
        first,
        null,
        cursor,
        null,
        include,
      );
      const { content, pageInfo, totalCount } = await this.graphService.fetchSinglePage<
        IItemByIdsGraphQLResponse,
        IItemExtended
      >("loadItemsByIds", query, this.graphQLPropertiesToAddFunction);

      result.push(...content);

      cursor = pageInfo.endCursor;
      hasNextPage = result.length < totalCount;
    }

    return result;
  }

  public get = async (id: string): Promise<IItem> =>
    await this.apiService.get<IItem>(`${this.getBaseUrl()}/${id}`).toPromise();

  public delete = async (id: string): Promise<void> =>
    await this.apiService.delete<void>(`${this.getBaseUrl()}/${id}`).toPromise();

  public createOrUpdate = async (payload: IItemPayload, id?: string): Promise<IItem> => {
    if (id) {
      return await this.apiService.put<IItem>(`${this.getBaseUrl()}/${id}`, payload).toPromise();
    } else {
      return await this.apiService.post<IItem>(`${this.getBaseUrl()}`, payload).toPromise();
    }
  };

  public setRecordState = async (payload: IRecordState, id: string): Promise<IItem> =>
    await this.apiService.put<IItem>(`${this.getBaseUrl()}/${id}`, payload).toPromise();

  public getItemsSupplyChain = async (
    orgId: string,
    itemIds: string[],
  ): Promise<IItemsSupplyChainResponse> => {
    const query = getItemsSupplyChainQuery(orgId, itemIds);

    return await firstValueFrom(this.graphService.query({ query }));
  };

  private graphQLPropertiesToAddFunction = (edge: any): any => {
    return {
      materialNames: edge.node.materials?.map((m) => m.name),
      certificates: edge.node.certificates,
      documents: edge.node.documents,
      initialQuantityFormatted: edge.node.product.defaultCustomUnit
        ? CommonUtils.formatQuantityWithDefaultUnit(
            edge.node.initialQuantity,
            edge.node.product.defaultCustomUnit,
            edge.node.product.unitOfMeasurement,
          )
        : null,
      remainingQuantityFormatted: edge.node.product.defaultCustomUnit
        ? CommonUtils.formatQuantityWithDefaultUnit(
            edge.node.remainingQuantity,
            edge.node.product.defaultCustomUnit,
            edge.node.product.unitOfMeasurement,
          )
        : null,
    };
  };
}
