@if (isVisible) {
  @if (isLoading()) {
    <app-loader></app-loader>
  } @else {
    <form class="comment-input-container" [formGroup]="formGroup">
      <iov-editable-div #editableDiv formControlName="text"></iov-editable-div>
      <div
        class="container-flex-right"
        (keydown)="onCommentBoxClicked()"
        (click)="onCommentBoxClicked()"
        tabindex="0"
      >
        <iov-button [disabled]="isSubmitButtonDisabled()" (pressed)="onSave()">Comment</iov-button>
      </div>
    </form>
    <div class="full-width-items-container">
      @for (comment of comments; track comment.id) {
        <div class="item hover-visibility-container">
          <iov-comment-card-content
            [comment]="comment"
            (deleteComment)="onDelete(comment.id)"
            (editComment)="editComment($event, comment.id)"
          ></iov-comment-card-content>
        </div>
      }
    </div>
  }
}
