import { ChangeDetectionStrategy, Component, OnInit, signal } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { CommonConstants } from "@shared/constants";
import { IBaseUnit, IProduct, IProductPayload, ISelectOption } from "@shared/interfaces";
import { ProductsService, UnitsOfMeasurementService } from "@shared/services";
import { CommonUtils, FormUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

@Component({
  templateUrl: "./add-product-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddProductDialogComponent implements OnInit {
  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl(
      null,
      [CustomValidators.required],
      [CustomValidators.entityAlreadyExists(this.productsService, null)],
    ),
    baseUnit: new UntypedFormControl(null, [CustomValidators.required]),
  });

  public isLoading = signal(false);

  public unitOfMeasurementTypesOptions: ISelectOption[] = [];

  public systemUnitsOfMeasurement: IBaseUnit[] = [];

  constructor(
    private dialogRef: MatDialogRef<AddProductDialogComponent>,
    private productsService: ProductsService,
    private notificationService: NotificationService,
    private unitsOfMeasurementService: UnitsOfMeasurementService,
  ) {}

  public async ngOnInit(): Promise<void> {
    await this.setUnitsOfMeasurementData();
  }

  public async onSubmit(): Promise<void> {
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);
      this.notificationService.showError(CommonConstants.FILL_REQUIRED_FIELDS_MSG);

      return;
    }
    this.isLoading.set(true);

    const baseUnit = `/common/base-units/${this.formGroup.controls["baseUnit"].value.value}`;
    const defaultUnit = baseUnit;

    const payload: IProductPayload = {
      baseUnit,
      defaultUnit,
      name: this.formGroup.controls["name"].value.trim(),
      allowedMaterials: [],
    };

    const createdProduct = await this.productsService.createOrUpdate(payload);

    createdProduct.customUnits = [];

    this.notificationService.showSuccess("Product created");

    this.isLoading.set(false);

    this.onClose(true, createdProduct);
  }

  private async setUnitsOfMeasurementData(): Promise<void> {
    this.systemUnitsOfMeasurement = await this.unitsOfMeasurementService.getSystemUnits();

    this.unitOfMeasurementTypesOptions = this.systemUnitsOfMeasurement.map((unit) => ({
      label: CommonUtils.capitaliseFirstLetter(unit.type),
      value: unit.id,
    }));
  }

  public onClose(hasSaved: boolean = false, createdProduct?: IProduct): void {
    this.dialogRef.close({ hasSaved, createdProduct });
  }
}
