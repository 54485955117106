import { computed, Directive, input, signal, TemplateRef } from "@angular/core";

@Directive({
  selector: "[iovSlideOverlayMenuItem]",
})
export class SlideOverlayMenuItemDirective {
  icon = input<string>(undefined, { alias: "overlay-menu-icon" });

  svgIcon = input<string>(undefined, { alias: "overlay-menu-svg-icon" });

  title = input.required<string>({ alias: "overlay-menu-title" });

  disabled = input<boolean>(false, { alias: "overlay-menu-disabled" });

  showMenuSaveButton = input<boolean>(true, { alias: "overlay-menu-show-save-button" });

  showMenuEditButton = input<boolean>(true, { alias: "overlay-menu-show-edit-button" });

  menuControllersTemplate = input<TemplateRef<unknown>>(undefined, {
    alias: "overlay-menu-buttons-template",
  });

  indicator = input<TemplateRef<unknown> | number | null>(undefined, {
    alias: "overlay-menu-indicator",
  });

  active = signal<boolean>(false);

  indicatorTemplateRef = computed(() => this.indicator() as TemplateRef<unknown>);

  isIndicatorTemplateRef = computed(() => this.indicator() instanceof TemplateRef);

  isIndicatorNumberAndGreaterThanZero = computed(
    () => typeof this.indicator() === "number" && (this.indicator() as number) > 0,
  );

  isIndicatorLoading = computed(() => this.indicator() === null);

  constructor(public templateRef: TemplateRef<unknown>) {}
}
