<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <h2 mat-dialog-title>Send {{ targetTypeText }}</h2>
  @if (isLoading()) {
    <app-loader containerClass="on-dialog"></app-loader>
  } @else {
    <mat-dialog-content [formGroup]="formGroup">
      <iov-input-select
        [options]="connectionsOptions"
        formControlName="connection"
        label="Connected organisations"
        hintClass="info-hint hint-bg-gray"
        [hint]="hint"
      ></iov-input-select>
    </mat-dialog-content>
    <mat-dialog-actions>
      <iov-button-group>
        <iov-button (pressed)="onClose()" type="ghost">Cancel</iov-button>
        <iov-button
          accessibilityRole="submit"
          cdkFocusInitial
          [disabled]="formGroup.invalid || formGroup.pending"
        >
          <mat-icon icon-left>send</mat-icon>
          Send
        </iov-button>
      </iov-button-group>
    </mat-dialog-actions>
  }
</form>
