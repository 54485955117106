<div class="container-space-between">
  <app-page-title title="Processes"></app-page-title>

  <iov-button-group [visible]="canAddModifyEntities">
    <iov-button (pressed)="onAdd()">
      <mat-icon icon-left>add</mat-icon>
      Add new
    </iov-button>
  </iov-button-group>
</div>

<app-processes-table
  *ngIf="!isLoadingProcessTypes()"
  [allProcessTypes]="allProcessTypes"
  [recordState]="recordStateEnum.ACTIVE"
  [isSaveTableState]="true"
  [isFixedBottomPaginator]="true"
></app-processes-table>
