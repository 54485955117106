import { ChangeDetectionStrategy, Component, Inject, OnInit, signal } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { SendTargetTypeEnum } from "src/app/shared/enums";
import { ISelectOption } from "src/app/shared/interfaces";
import {
  AuthenticationService,
  ConnectionsService,
  RecordSharingService,
} from "src/app/shared/services";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { CommonUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

@Component({
  templateUrl: "./send-dialog.component.html",
  styleUrl: "./send-dialog.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendDialogComponent implements OnInit {
  public readonly sendTargetTypeEnum = SendTargetTypeEnum;

  public isLoading = signal(true);

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    connection: new UntypedFormControl(null, [CustomValidators.required]),
  });

  public connectionsOptions: ISelectOption[] = [];

  public targetTypeText: string;

  public hint: string;

  constructor(
    public dialogRef: MatDialogRef<SendDialogComponent>,
    private recordSharingService: RecordSharingService,
    private connectionsService: ConnectionsService,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      sendTargetType: SendTargetTypeEnum;
      rootRecordUri: string;
    },
  ) {}

  public async ngOnInit(): Promise<void> {
    const connectionsWithPublicUri = await this.connectionsService.getAll(undefined, true);

    this.connectionsOptions = connectionsWithPublicUri.map(
      (c) => ({ label: c.name, value: c.id }) as ISelectOption,
    );
    this.targetTypeText = CommonUtils.enumToText(this.data?.sendTargetType).toLowerCase();
    this.hint = `Note: this action will send the current ${this.targetTypeText} information along with all of the related data (organisation, documents, certificates). Any changes made to the 
      ${this.targetTypeText} or related data will not be automatically sent.`;
    this.isLoading.set(false);
  }

  public onClose = (hasSaved = false): void => {
    this.dialogRef.close({ hasSaved });
  };

  public onSubmit = async (): Promise<void> => {
    try {
      this.isLoading.set(true);
      const payload = await this.getSendPayload();

      await this.recordSharingService.createOutboundShare(payload);
      this.notificationService.showSuccess(
        `${CommonUtils.capitaliseFirstLetter(this.targetTypeText)} sent`,
      );
      this.onClose(true);
    } catch (error) {
      this.notificationService.showError(error);
    } finally {
      this.isLoading.set(false);
    }
  };

  private removeUrisFromArray = (array: string[], urisToRemove: string[]): void => {
    for (const uriToRemove of urisToRemove) {
      const index = array.findIndex((r: string) => r === uriToRemove);

      if (index !== -1) {
        array.splice(index, 1);
      }
    }
  };

  private async getSendPayload(): Promise<any> {
    const activeOrganisationId = this.authenticationService.getActiveOrganisationId();

    const crossOrgShare = this.data.sendTargetType === SendTargetTypeEnum.DELIVERY;

    const recordUris = await this.recordSharingService.getShareStructure(
      this.data.rootRecordUri,
      crossOrgShare,
    );

    const exactRecordUrisToRemove = [
      this.data.rootRecordUri,
      `/organisations/${activeOrganisationId}`,
    ];

    this.removeUrisFromArray(recordUris, exactRecordUrisToRemove);

    const startWithRecordUrisToRemove = [
      `/organisations/${activeOrganisationId}/extensions/geospatial/`,
    ];

    for (const recordUri of startWithRecordUrisToRemove) {
      const urisToRemove = recordUris.filter((r: string) => r.startsWith(recordUri));

      this.removeUrisFromArray(recordUris, urisToRemove);
    }

    const connectionId = this.formGroup.controls["connection"].value.value;

    return {
      receiverUri: `/organisations/${activeOrganisationId}/connections/${connectionId}`,
      rootRecordUri: this.data.rootRecordUri,
      recordUris,
    };
  }
}
