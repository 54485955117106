<div cdkDropList (cdkDropListDropped)="drop.emit($event)">
  @if (items().length) {
    @for (item of items(); track item; let first = $first; let last = $last) {
      <mat-card
        cdkDrag
        cdkDragLockAxis="y"
        [cdkDragData]="item"
        [cdkDragDisabled]="enableViewMode()"
      >
        <div class="card-content">
          <div class="left-column">
            @if (!enableViewMode()) {
              <mat-icon cdkDragHandle>drag_indicator</mat-icon>
            }
          </div>
          <div class="right-column">
            <ng-container
              *ngTemplateOutlet="
                item.template();
                context: { enableViewMode: enableViewMode(), isFirst: first, isLast: last }
              "
            />
          </div>
        </div>
      </mat-card>
    }
  } @else {
    <p class="placeholder">{{ placeholder() }}</p>
  }
</div>
