import { getConnectionProperties } from "./connections.query";
import { QueriesUtils } from "./queries.utils";

/**
 * Retrieves inbound connections by their IDs using a GraphQL query.
 *
 * @param orgId - The ID of the organization.
 * @param senderId - The ID of the sender.
 * @param ids - An array of connection IDs.
 * @param first - The maximum number of connections to retrieve from the beginning.
 * @param last - The maximum number of connections to retrieve from the end.
 * @param after - The cursor to start retrieving connections after.
 * @param before - The cursor to start retrieving connections before.
 * @param include - An array of properties to include in the retrieved connections.
 * @returns The GraphQL query string.
 */
export const getInboundConnectionsByIdsGraphQLQuery = (
  orgId: string,
  senderId: string,
  ids: string[],
  first: number,
  last: number,
  after: string,
  before: string,
  include: string[],
): string => {
  const afterCursorClause = first > 0 ? QueriesUtils.generateClause("after", after) : "";
  const firstClause = first > 0 ? QueriesUtils.generateClause("first", first) : "";
  const beforeCursorClause = last > 0 ? QueriesUtils.generateClause("before", before) : "";
  const lastClause = last > 0 ? QueriesUtils.generateClause("last", last) : "";

  return `
    {
        inboundLoadConnectionsByIds(   
        orgId: "${orgId}",
        senderId: "${senderId}",
        ids: ${QueriesUtils.getParsedIds(ids)},
        ${afterCursorClause ? `${afterCursorClause},` : ""}
        ${beforeCursorClause ? `${beforeCursorClause},` : ""}
        ${firstClause ? `${firstClause},` : ""}
        ${lastClause ? `${lastClause},` : ""}
    ) {       
              ${getConnectionProperties(include)}   

        }
    }
    `;
};
