import { AfterViewInit, ChangeDetectionStrategy, Component, Input, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef } from "ag-grid-community";
import { SlideOverCellRendererComponent } from "src/app/shared/cell-renderers";
import { ConfirmDialogResponseEnum, TableEnum } from "src/app/shared/enums";
import { IEnableExtensionPayload, IExtension } from "src/app/shared/interfaces";
import { ExtensionsService } from "src/app/shared/services";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { ConfirmDialogComponent } from "@components/shared";
import { CellRendererUtils } from "@shared/utils";

@Component({
  selector: "app-admin-organisation-extensions-table",
  templateUrl: "./admin-organisation-extensions-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminOrganisationExtensionTableComponent implements AfterViewInit {
  @Input()
  public organisationId: string;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  @Input()
  public table = TableEnum.ADMIN_ORGANISATION_EXTENSIONS;

  @Input()
  public isSaveTableState = false;

  public isLoading = signal(true);

  public rowData: IExtension[] = [];

  public columnDefs = signal<ColDef[]>([]);

  constructor(
    private notificationService: NotificationService,
    private extensionsService: ExtensionsService,
    private dialog: MatDialog,
  ) {}

  public async ngAfterViewInit() {
    this.setColumnDefs();
    await this.getAll();
  }

  private setColumnDefs = (): void => {
    this.columnDefs.set([
      {
        headerName: "Name",
        field: "providerId",
        lockVisible: true,
        cellRenderer: CellRendererUtils.capitaliseFirstLetter,
      },
      {
        headerName: "Active",
        field: "enable",
        resizable: false,
        suppressSizeToFit: true,
        suppressAutoSize: true,
        cellClass: "slide-over-cell-renderer-container",
        cellRenderer: SlideOverCellRendererComponent,
        cellRendererParams: {
          statusChange: this.onTryStatusChange,
          isDisabled: false,
          tooltip: (row) => (row.enable ? "Deactivate" : "Activate"),
        },
      },
    ]);
  };

  public onTryStatusChange = async (element: IExtension) => {
    const isActivating = !element.enable;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: `${isActivating ? "Activate" : "Deactivate"} extension`,
        contentTranslatedText: `Are you sure that you want to ${isActivating ? "activate" : "deactivate"} this extension?`,
        confirmButtonColor: `${isActivating ? undefined : "danger"}`,
        confirmButtonTranslatedText: `${isActivating ? "Activate" : "Deactivate"}`,
        confirmButtonIcon: `${isActivating ? "check" : "close"}`,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        this.statusChange(element);
      } else {
        await this.getAll(); // TODO find a better way to restore the 'enable' value on the UI
      }
    });
  };

  private statusChange = async (element: IExtension) => {
    element.enable = !element.enable;
    const payload: IEnableExtensionPayload = {
      enable: element.enable,
      organisation: `/organisations/${this.organisationId}`,
      providerId: element.providerId,
    };

    try {
      await this.extensionsService.enableExtension(payload);
      this.notificationService.showSuccess(
        `Extension ${element.enable ? "activated" : "deactivated"}`,
      );
    } catch (error) {
      this.notificationService.showError(error);
      await this.getAll(); // TODO find a better way to restore the 'enable' value on the UI
    }
  };

  private getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    try {
      const availableExtensions = await this.extensionsService.getAllAvailableForSystemAdmin();
      const enabled = await this.extensionsService.getAllEnabled(this.organisationId);

      this.rowData = availableExtensions.map((availableExtension) => ({
        providerId: availableExtension.name,
        enable: enabled.some((e) => e === availableExtension.name),
      }));
      this.isLoading.set(false);
    } catch (error) {
      this.notificationService.showError(error);
    }
  };
}
