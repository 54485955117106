import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

import { ISelectOption } from "@shared/interfaces";

@Component({
  selector: "app-location-overlay-address",
  templateUrl: "./location-overlay-address.component.html",
  styleUrl: "./location-overlay-address.component.scss",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class LocationOverlayAddressComponent {
  @Input() formGroup: FormGroup;

  @Input() countryOptions: ISelectOption[] = [];
}
