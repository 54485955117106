import { QueriesUtils } from "./queries.utils";

/**
 * Retrieves the GraphQL query for fetching an inbound supply chain.
 *
 * @param orgId - The ID of the organization.
 * @param senderId - The ID of the sender.
 * @param id - The ID of the inbound supply chain.
 * @param include - An array of fields to include in the query.
 * @returns The GraphQL query string.
 */
export const getInboundSupplyChainGraphQLQuery = (
  orgId: string,
  senderId: string,
  id: string,
  include: string[],
): string => {
  return `
      {
        inboundLoadSupplyChain(   
          orgId: "${orgId}",
          senderId: "${senderId}",
          id: "${id}"
        ) {
        id
        name
        description
          ${QueriesUtils.getQueryIncludes(include)}
      }
}
    `;
};
