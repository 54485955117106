import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

import { Subscription } from "rxjs";
import { CommonConstants } from "src/app/shared/constants";
import { RecordStateEnum } from "src/app/shared/enums";

@Component({
  selector: "app-search-and-filters",
  templateUrl: "./search-and-filters.component.html",
  styleUrls: ["./search-and-filters.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchAndFiltersComponent implements OnInit, OnDestroy {
  @Input()
  public searchText: string = null;

  @Input()
  public isRecordStateFilterEnabled: boolean = true;

  @Input()
  public recordState: RecordStateEnum = RecordStateEnum.ALL;

  @Input()
  public canClearSelection: boolean = false;

  @Output()
  public searchChanged: EventEmitter<string> = new EventEmitter();

  @Output()
  public recordStateChanged: EventEmitter<RecordStateEnum> = new EventEmitter();

  public formGroup: UntypedFormGroup = null;

  public readonly recordStateOptions = CommonConstants.RECORD_STATE_OPTIONS;

  private subscriptions = new Subscription();

  public async ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      recordState: new UntypedFormControl(this.recordState),
    });

    if (this.isRecordStateFilterEnabled) {
      this.subscriptions.add(
        this.formGroup.controls["recordState"].valueChanges.subscribe(
          async (recordState: RecordStateEnum) => {
            this.recordStateChanged.emit(
              recordState === RecordStateEnum.ALL ? undefined : recordState,
            );
          },
        ),
      );
    }
  }

  public onSearch = async (value: string): Promise<void> => {
    this.searchChanged.emit(value.trim());
  };

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
