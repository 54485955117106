import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  input,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";

import { InputChipsComponent } from "@design-makeover/components/inputs/input-chips/input-chips.component";
import { SlideOverlayPageService } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.service";

import { AddConnectionDialogComponent } from "@components/locations/components/add-connection-dialog/add-connection-dialog.component";
import { RoutingEnum } from "@shared/enums";
import { ISelectOption } from "@shared/interfaces";
import { AuthenticationService, LocationTypesService } from "@shared/services";
import { NavigationParams, RouterService } from "@shared/services/router.service";

interface ConnectionResult {
  hasSaved;
  savedOrganisation;
}

@Component({
  selector: "app-location-overlay-main-information",
  templateUrl: "./location-overlay-main-information.component.html",
  styleUrl: "./location-overlay-main-information.component.scss",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class LocationOverlayMainInformationComponent {
  @Input() formGroup: FormGroup;

  public isEditing = input<boolean>(false);

  @Input() organisationOptions: ISelectOption[] = [];

  @Input() countryOptions: ISelectOption[] = [];

  @Input() isInboundShare = false;

  @Output() reloadOrganisations: EventEmitter<string> = new EventEmitter();

  @ViewChild("locationTypesInputChips") locationTypesInputChips: InputChipsComponent;

  protected readonly routingEnum = RoutingEnum;

  public readonly isSharedUser = this.authenticationService.isSharedUser();

  constructor(
    protected overlay: SlideOverlayPageService,
    public locationTypesService: LocationTypesService,
    private router: RouterService,
    private authenticationService: AuthenticationService,
  ) {}

  public async onAddOrganisationClicked(): Promise<void> {
    const result = await this.overlay.openDialog<
      ConnectionResult,
      { countryOptions: ISelectOption[] }
    >(AddConnectionDialogComponent, {
      countryOptions: this.countryOptions,
    });

    if (result?.hasSaved) {
      this.reloadOrganisations.emit(result.savedOrganisation.id);
    }
  }

  getOrganisationLink(organisationId: string): NavigationParams {
    // if (this.isInboundShare) {
    //     return <NavigationParams>this.router.getSharedOrganisationLink(organisationId, false, {
    //         organisationId: this.route.snapshot.queryParams["organisationId"],
    //     });
    // }
    return <NavigationParams>this.router.getOrganisationLink(organisationId, false);
  }
}
