<iov-slide-overlay-header
  [canDelete]="true"
  [canDuplicate]="true"
  [initialTags]="initialTags"
  [title]="title()"
  icon="items"
></iov-slide-overlay-header>
<iov-slide-overlay-content #slideOverlayContent>
  <ng-template
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="menu-info"
    overlay-menu-title="Item details"
  >
    <iov-form-field-group [name]="mainInformationText">
      <div [formGroup]="formGroup" class="row-container">
        <div class="row row-2">
          <div class="item">
            <iov-input
              [autofocus]="!isEditing()"
              enableViewMode
              formControlName="itemId"
              hint="E.g. stock / batch number, etc."
              label="ID"
            ></iov-input>
          </div>
          <div class="item">
            <iov-input-select
              [options]="productOptions"
              [viewModeTemplate]="productViewTemplate"
              enableViewMode
              formControlName="product"
              label="Product"
            >
              <ng-container action>
                <iov-button
                  (pressed)="onAddProductClicked()"
                  class="icon-add-circle"
                  tp="Add new product"
                  type="icon"
                >
                  <mat-icon icon-small>add</mat-icon>
                </iov-button>
              </ng-container>
            </iov-input-select>
          </div>
        </div>
        <div class="item">
          @if (isOldMaterialsEnabled) {
            <iov-input-chips
              [options]="materialOptions"
              [tooltip]="
                formGroup.controls['product'].value
                  ? ''
                  : 'Please, first select a product to see which materials are allowed.'
              "
              enableViewMode
              formControlName="materials"
              label="Materials"
              [isTagClickable]="!overlay.editMode()"
              (tagPressed)="openMaterialOverlay($event)"
            >
              @if (formGroup.controls["product"].value) {
                <ng-container action [ngTemplateOutlet]="addMaterialBtnTemplate"></ng-container>
              }
            </iov-input-chips>
          } @else {
            <div class="tag-group-container">
              <div class="container-flex-left">
                <iov-form-label
                  label="Materials"
                  [tooltip]="
                    formGroup.controls['product'].value
                      ? ''
                      : 'Please select a product to see which materials it comprises of.'
                  "
                ></iov-form-label>
                @if (formGroup.controls["product"]?.value && overlay.editMode()) {
                  <ng-container [ngTemplateOutlet]="addMaterialBtnTemplate"></ng-container>
                }
              </div>
              @if (formGroup.controls["materials"].value?.length) {
                <iov-tag-group
                  [tags]="formGroup.controls['materials'].value"
                  [isTagClickable]="!overlay.editMode()"
                  (tagPressed)="openMaterialOverlay($event)"
                ></iov-tag-group>
              } @else {
                -
              }
            </div>
          }
        </div>
        @if (overlay.editMode()) {
          <div class="item">
            <iov-input-select
              enableViewMode
              [options]="unitOfMeasurementOptions"
              formControlName="unitOfMeasurement"
              label="Unit of measurement"
            ></iov-input-select>
          </div>
        }

        <div class="item">
          <iov-input
            [hint]="
              itemDefaultUnitOfMeasurement?.id === unitOfMeasurement?.id
                ? ''
                : (formGroup.controls['initialQuantity'].value
                  | unitConversion: itemDefaultUnitOfMeasurement : unitOfMeasurement)
            "
            [suffix]="itemDefaultUnitOfMeasurement?.symbol"
            [viewModeTemplate]="initialQuantityViewTemplate"
            enableViewMode
            formControlName="initialQuantity"
            label="Initial quantity"
            type="number"
          ></iov-input>
        </div>
        @if (element?.id || duplicateItemId) {
          <div class="item">
            <iov-input
              enableViewMode
              [suffix]="itemDefaultUnitOfMeasurement?.symbol"
              [hint]="
                itemDefaultUnitOfMeasurement?.id === unitOfMeasurement?.id
                  ? ''
                  : (formGroup.controls['remainingQuantity'].value
                    | unitConversion: itemDefaultUnitOfMeasurement : unitOfMeasurement)
              "
              [viewModeTemplate]="remainingQuantityViewTemplate"
              formControlName="remainingQuantity"
              label="Remaining quantity"
              type="number"
            ></iov-input>
          </div>
        }
        <div class="row row-1">
          <div class="item">
            <iov-input-select
              [options]="createdAtLocationOptions"
              [viewModeTemplate]="locationViewTemplate"
              enableViewMode
              formControlName="createdAtLocation"
              label="Created at location"
              tooltip="Provide information about where the item was first created /
                    got a new identifier."
            ></iov-input-select>
          </div>
        </div>
        <div class="item">
          @if (datesType === dateTypeEnum.EXACT) {
            <iov-datepicker
              #datePicker
              enableViewMode
              class="no-padding"
              label="Creation date"
              formControlName="createdFrom"
            ></iov-datepicker>
          } @else if (datesType === dateTypeEnum.RANGE) {
            <iov-daterangepicker
              #datePicker
              enableViewMode
              class="no-padding"
              label="Creation date"
              formControlName="createdRange"
            ></iov-daterangepicker>
          }

          @if (overlay.editMode()) {
            <div class="checkbox-row mb-14">
              <iov-checkbox
                formControlName="isRangeCreationDate"
                label="Range (from/to)"
              ></iov-checkbox>
            </div>
          }
        </div>
        <div class="row row-1">
          <div class="item">
            <iov-input-select
              [options]="currentLocationOptions"
              [viewModeTemplate]="locationViewTemplate"
              class="no-padding"
              enableViewMode
              formControlName="currentLocation"
              label="Current / last location"
              tooltip="Provide information on what is the item's current location
                    (if the item still physically exists) or what was its last location if it has
                    been already transformed into other items / destroyed."
            ></iov-input-select>
          </div>

          @if (overlay.editMode()) {
            <div class="checkbox-row">
              <iov-checkbox
                formControlName="isSameAsCreatedAtLocation"
                label="Same as created at location"
              ></iov-checkbox>
            </div>
          }
        </div>
      </div>
    </iov-form-field-group>
    @if (visibleCustomFields?.length) {
      <iov-form-field-group [name]="additionalInformationText">
        <app-custom-fields-edit
          [columns]="1"
          [elementCustomFields]="element?.customFields"
          [formGroup]="formGroup"
          [enableViewMode]="true"
          [visibleCustomFields]="visibleCustomFields"
        ></app-custom-fields-edit>
      </iov-form-field-group>
    }
  </ng-template>
  <ng-template
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.PROCESSES).isEnabled"
    [overlay-menu-indicator]="itemOverlayService.linkedProcessCount()"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="overlayTabEnum.PROCESSES"
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="processes"
  >
    <article class="field-group-container">
      @if (attachedProcesses) {
        <app-processes-table
          [allProcessTypes]="allProcessTypes"
          [columns]="[
            'recordState',
            'processId',
            'type.name',
            'location.name',
            'firstInputDate',
            'lastOutputDate',
          ]"
          [processes]="attachedProcesses"
        ></app-processes-table>
      } @else {
        <app-loader></app-loader>
      }
    </article>
  </ng-template>
  <ng-template
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.DELIVERIES).isEnabled"
    [overlay-menu-indicator]="itemOverlayService.linkedDeliveryCount()"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="overlayTabEnum.DELIVERIES"
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="deliveries"
  >
    <article class="field-group-container">
      @if (deliveriesIds) {
        <app-deliveries-table
          [columns]="['recordState', 'deliveryId', 'sent', 'from.name', 'to.name', 'tags']"
          [deliveriesIds]="deliveriesIds"
        ></app-deliveries-table>
      } @else {
        <app-loader></app-loader>
      }
    </article>
  </ng-template>
  <ng-template
    [overlay-menu-buttons-template]="certificateController"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.CERTIFICATES).isEnabled"
    [overlay-menu-indicator]="itemOverlayService.certificateCounter()"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="overlayTabEnum.CERTIFICATES"
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="certificates"
  >
    <app-overlay-certificate-attachments
      #certificateSection
      [attachmentTargetId]="element?.id"
      [attachmentTargetType]="attachmentTargetEnum.ITEM"
      [overlayService]="itemOverlayService"
    ></app-overlay-certificate-attachments>
  </ng-template>
  <ng-template
    [overlay-menu-buttons-template]="documentController"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.DOCUMENTS).isEnabled"
    [overlay-menu-indicator]="itemOverlayService.documentCounter()"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="overlayTabEnum.DOCUMENTS"
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="document"
  >
    <app-overlay-document-attachments
      #documentSection
      [attachmentTargetId]="element?.id"
      [attachmentTargetType]="attachmentTargetEnum.ITEM"
      [isReadOnly]="isRecordArchived"
      [overlayService]="itemOverlayService"
    ></app-overlay-document-attachments>
  </ng-template>
  <ng-template
    [overlay-menu-buttons-template]="supplyChainController"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.SUPPLY_CHAIN).isEnabled"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="overlayTabEnum.SUPPLY_CHAIN"
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="supply-chain"
  >
    @if (supplyChainTabIndex === supplyChainTabEnum.GRAPH) {
      <app-items-supply-chain
        [allProcessTypes]="allProcessTypes"
        [height]="supplyChainHeight"
        [itemIds]="itemIds"
      ></app-items-supply-chain>
    } @else if (supplyChainTabIndex === supplyChainTabEnum.LOCATIONS) {
      <article class="field-group-container">
        @if (isLoadingSupplyChainLocations()) {
          <app-loader></app-loader>
        } @else {
          <app-locations-table
            [locationsIds]="supplyChainLocationIds"
            [columns]="[
              'recordState',
              'name',
              'organisationName',
              'types',
              'address.countryName',
              'geoLocation',
              'tags',
            ]"
          ></app-locations-table>
        }
      </article>
    } @else if (supplyChainTabIndex === supplyChainTabEnum.MAP) {
      <article class="field-group-container">
        <app-items-supply-chain-map [itemIds]="itemIds" />
      </article>
    }
  </ng-template>
  <ng-template
    *ngIf="!menuItems().get(overlayTabEnum.COMMENTS).isHidden"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.COMMENTS).isEnabled"
    [overlay-menu-indicator]="itemOverlayService.commentCounter()"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="overlayTabEnum.COMMENTS"
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="comment"
  >
    <article class="field-group-container">
      <app-comments
        (added)="onCommentAdded(itemOverlayService)"
        (deleted)="onCommentDeleted(itemOverlayService)"
        [entityId]="element?.id"
        [entityType]="entityType"
      ></app-comments>
    </article>
  </ng-template>
  <ng-template #documentController>
    @if (!overlay.contentRightSidePanel() && isRecordActive && canAddModifyEntities) {
      <iov-button
        (pressed)="overlay.openSideMenu(documentSection?.attachmentsRightMenu)"
        [disabled]="overlay.loading()"
        type="ghost"
      >
        <mat-icon class="action-icon" icon-left>add</mat-icon>
        Add
      </iov-button>
    }
  </ng-template>
  <ng-template #certificateController>
    @if (!overlay.contentRightSidePanel() && isRecordActive && canAddModifyEntities) {
      <iov-button
        (pressed)="overlay.openSideMenu(certificateSection?.attachmentsRightMenu)"
        [disabled]="overlay.loading()"
        type="ghost"
      >
        <mat-icon class="action-icon" icon-left>add</mat-icon>
        Add
      </iov-button>
    }
  </ng-template>
  <ng-template #locationViewTemplate let-label="label" let-option>
    <div class="form-field-item">
      <iov-form-label [label]="label"></iov-form-label>
      <div class="form-field-input-readonly">
        @if (option.label && option.value) {
          <iov-form-link [link]="getLocationLink(option.value)" [styledLink]="true">
            {{ option.label }}
          </iov-form-link>
        } @else {
          @if (isSharedUser) {
            <app-not-shared-data></app-not-shared-data>
          } @else {
            <app-loader containerClass="size-small"></app-loader>
          }
        }
      </div>
    </div>
  </ng-template>
  <ng-template #productViewTemplate let-label="label" let-option let-searchText="searchText">
    @if (authenticationService.canGoToAdminSection()) {
      <div class="form-field-item">
        <iov-form-label [label]="label"></iov-form-label>
        <iov-form-link [link]="getProductLink(option.value)" [styledLink]="true">
          {{ option.label }}
        </iov-form-link>
      </div>
    } @else {
      <iov-form-input-readonly [value]="searchText" [label]="label"></iov-form-input-readonly>
    }
  </ng-template>
  <ng-template #supplyChainController>
    <div class="supply-chain-tabs">
      <mat-tab-group
        animationDuration="0ms"
        (selectedIndexChange)="onSelectSupplyChainTab($event)"
        [selectedIndex]="supplyChainTabIndex"
      >
        <mat-tab>
          <ng-template mat-tab-label>Graph</ng-template>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>Locations</ng-template>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>Map</ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </ng-template>
  <ng-template #initialQuantityViewTemplate let-data>
    @if (unitOfMeasurement) {
      @if (hasDifferentDefaultUnit && defaultUnitOfMeasurement) {
        <iov-form-input-readonly
          [label]="data.label"
          [value]="initialQuantityFormatted"
        ></iov-form-input-readonly>
      } @else {
        <iov-form-input-readonly
          [label]="data.label"
          [value]="
            (element.initialQuantity | number: '1.0-' + unitOfMeasurement?.precision ?? '0') +
            ' ' +
            unitOfMeasurement?.symbol
          "
        ></iov-form-input-readonly>
      }
    } @else if (isSharedUser) {
      <app-not-shared-data></app-not-shared-data>
    }
  </ng-template>
  <ng-template #remainingQuantityViewTemplate let-data>
    @if (unitOfMeasurement) {
      @if (hasDifferentDefaultUnit && defaultUnitOfMeasurement) {
        <iov-form-input-readonly
          [label]="data.label"
          [value]="remainingQuantityFormatted"
        ></iov-form-input-readonly>
      } @else {
        <iov-form-input-readonly
          [label]="data.label"
          [value]="
            (element.remainingQuantity | number: '1.0-' + unitOfMeasurement?.precision ?? '0') +
            ' ' +
            unitOfMeasurement?.symbol
          "
        ></iov-form-input-readonly>
      }
    } @else if (isSharedUser) {
      <app-not-shared-data></app-not-shared-data>
    }
  </ng-template>
</iov-slide-overlay-content>
<ng-template #addMaterialBtnTemplate>
  <iov-button
    (pressed)="onAddMaterialClicked()"
    class="icon-add-circle"
    tp="Add new material"
    type="icon"
  >
    <mat-icon icon-small>add</mat-icon>
  </iov-button>
</ng-template>
