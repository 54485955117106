import { Injectable } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Params, Router, UrlTree } from "@angular/router";

import { BulkAddSlideOverModel } from "@components/shared/bulk-add-slide-over/bulk-add-slide-over.model";
import { EntityTypeEnum, RoutingEnum } from "@shared/enums";
import { CommonUtils } from "@shared/utils";

export interface NavigationParams {
  commands: unknown[];
  extras?: NavigationExtras;
}

interface RoutingLinkParams {
  extraParams?: any;
  id?: string;
  serializeTree?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class RouterService {
  private history: string[] = [];

  private readonly pathsWithNoBackButton = [
    `/${RoutingEnum.LOCATIONS}`,
    `/${RoutingEnum.ORGANISATIONS}`,
    `/${RoutingEnum.SUPPLY_CHAINS}`,
    `/${RoutingEnum.CERTIFICATES}`,
    `/${RoutingEnum.DOCUMENTS}`,
    `/${RoutingEnum.ITEMS}`,
    `/${RoutingEnum.DELIVERIES}`,
    `/${RoutingEnum.PROCESSES}`,
    `/${RoutingEnum.INBOX}`,
    `/${RoutingEnum.INVITATIONS}`,
    `/${RoutingEnum.USER_SETTINGS}`,
    `/${RoutingEnum.ADMIN}`,
    `/${RoutingEnum.ADMIN_ORGANISATIONS}`,
    `/${RoutingEnum.ADMIN_USERS}`,
  ];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  private getCurrentHistory = (): string => {
    if (!this.history.length) {
      return "";
    }

    return this.history[this.history.length - 1];
  };

  addHistory = (newPath: string): void => {
    const currentPath = this.getCurrentHistory();

    if (currentPath !== newPath) {
      if (
        this.isCurrentHistoryADetailsOverlay() &&
        CommonUtils.isSameUrlPathButOnly1ParamHasChanged(currentPath, newPath, "tab")
      ) {
        this.removeLastHistoryElement();
      }

      if (this.pathsWithNoBackButton.includes(newPath)) {
        this.clearHistory();
      }

      this.history.push(newPath);
    }
  };

  removeLastHistoryElement = (): void => {
    this.history.pop();
  };

  clearHistory = (): void => {
    this.history = [];
  };

  hasHistory = (): boolean => !!this.history.length;

  goBackInHistory = (): void => {
    this.removeLastHistoryElement();
    this.router.navigateByUrl(this.getCurrentHistory());
  };

  private isCurrentHistoryADetailsOverlay = (): boolean => {
    const currentHistory = this.getCurrentHistory();

    return CommonUtils.doesUrlPathHaveParams(currentHistory, ["view", "id"]);
  };

  isPreviousHistoryADetailsOverlay = (): boolean => {
    if (this.history.length <= 1) {
      return false;
    }
    const previousPath = this.history[this.history.length - 2];

    return CommonUtils.doesUrlPathHaveParams(previousPath, ["view", "id"]);
  };

  updateCurrentUrlParams = (queryParams?: Params, replaceUrl = true): void => {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: "merge",
      replaceUrl,
    });
  };

  openNewTab = (route: NavigationParams | string | unknown[]): void => {
    let urlTree: UrlTree;

    if (Array.isArray(route)) {
      urlTree = this.router.createUrlTree(route as string[]);
    } else if (typeof route === "string") {
      urlTree = this.router.createUrlTree([route]);
    } else if (typeof route === "object" && route !== null && "commands" in route) {
      const { commands, extras } = route as NavigationParams;

      const navigationExtras: NavigationExtras = { ...extras };

      urlTree = this.router.createUrlTree(commands, navigationExtras);
    } else {
      throw new Error("Invalid route parameter");
    }

    const url = this.router.serializeUrl(urlTree);

    CommonUtils.openInNewTab(url);
  };

  navigate = (
    route: NavigationParams | string | unknown[],
    extras?: NavigationExtras,
  ): Promise<boolean> => {
    if (Array.isArray(route)) {
      return this.router.navigate(route as string[], extras);
    } else if (typeof route === "string") {
      return this.router.navigateByUrl(route, extras);
    } else if (typeof route === "object" && route !== null && "commands" in route) {
      const { commands, extras: paramsExtras } = route as NavigationParams;

      return this.router.navigate(commands, { ...paramsExtras, ...extras });
    } else {
      throw new Error("Invalid route parameter");
    }
  };

  getSharedLocationLink = (
    id: string,
    serializeTree: boolean = true,
    extraParams?: any,
  ): NavigationParams | string => {
    return this.createViewOutletUrl(
      serializeTree,
      RoutingEnum.OVERLAY_SHARED_LOCATION,
      id,
      extraParams,
    );
  };

  getSharedDeliveryLink = (
    id: string,
    serializeTree: boolean = true,
    extraParams?: any,
  ): NavigationParams | string => {
    return this.createViewOutletUrl(
      serializeTree,
      RoutingEnum.OVERLAY_SHARED_DELIVERY,
      id,
      extraParams,
    );
  };

  getSharedConnectionLink = (
    id: string,
    serializeTree: boolean = true,
    extraParams?: any,
  ): NavigationParams | string => {
    return this.createViewOutletUrl(
      serializeTree,
      RoutingEnum.OVERLAY_SHARED_ORGANISATION,
      id,
      extraParams,
    );
  };

  getSharedOrganisationLink = (
    id: string,
    serializeTree: boolean = true,
    extraParams?: any,
  ): NavigationParams | string => {
    return this.createViewOutletUrl(
      serializeTree,
      RoutingEnum.OVERLAY_SHARED_ORGANISATION,
      id,
      extraParams,
    );
  };

  getSharedDocumentLink = (
    id: string,
    serializeTree: boolean = true,
    extraParams?: any,
  ): NavigationParams | string => {
    return this.createViewOutletUrl(
      serializeTree,
      RoutingEnum.OVERLAY_SHARED_DOCUMENT,
      id,
      extraParams,
    );
  };

  getSharedCertificateLink = (
    id: string,
    serializeTree: boolean = true,
    extraParams?: any,
  ): NavigationParams | string => {
    return this.createViewOutletUrl(
      serializeTree,
      RoutingEnum.OVERLAY_SHARED_CERTIFICATE,
      id,
      extraParams,
    );
  };

  getAdminLocationLink = (
    id?: string,
    serializeTree: boolean = true,
  ): NavigationParams | string => {
    return this.createPrimaryOutletUrl(
      serializeTree,
      `/${RoutingEnum.ADMIN_LOCATIONS_DETAILS}/${id}`,
    );
  };

  getLocationLink = (
    id?: string,
    serializeTree: boolean = true,
    extraParams?: any,
  ): NavigationParams | string => {
    if (id) {
      return this.createViewOutletUrl(serializeTree, RoutingEnum.OVERLAY_LOCATION, id, extraParams);
    } else {
      return this.createViewOutletUrl(serializeTree, RoutingEnum.OVERLAY_LOCATION);
    }
  };

  getOrganisationLink = (
    id?: string,
    serializeTree: boolean = true,
    extraParams?: any,
  ): NavigationParams | string => {
    if (id) {
      return this.createViewOutletUrl(
        serializeTree,
        RoutingEnum.OVERLAY_ORGANISATION,
        id,
        extraParams,
      );
    } else {
      return this.createViewOutletUrl(serializeTree, RoutingEnum.OVERLAY_ORGANISATION);
    }
  };

  getDocumentLink = (
    id: string,
    serializeTree: boolean = true,
    extraParams?: any,
  ): NavigationParams | string => {
    if (id) {
      return this.createViewOutletUrl(serializeTree, RoutingEnum.OVERLAY_DOCUMENT, id, extraParams);
    } else {
      return this.createViewOutletUrl(serializeTree, RoutingEnum.OVERLAY_DOCUMENT);
    }
  };

  getCertificateLink = (
    id?: string,
    serializeTree: boolean = true,
    extraParams?: any,
  ): NavigationParams | string => {
    if (id) {
      return this.createViewOutletUrl(
        serializeTree,
        RoutingEnum.OVERLAY_CERTIFICATE,
        id,
        extraParams,
      );
    } else {
      return this.createViewOutletUrl(serializeTree, RoutingEnum.OVERLAY_CERTIFICATE);
    }
  };

  getDeliveryLink = (
    id?: string,
    serializeTree: boolean = true,
    extraParams?: any,
  ): NavigationParams | string => {
    if (id) {
      return this.createViewOutletUrl(serializeTree, RoutingEnum.OVERLAY_DELIVERY, id, extraParams);
    } else {
      return this.createViewOutletUrl(serializeTree, RoutingEnum.OVERLAY_DELIVERY);
    }
  };

  getItemLink = (
    id?: string,
    serializeTree: boolean = true,
    extraParams?: any,
  ): NavigationParams | string => {
    if (id) {
      return this.createViewOutletUrl(serializeTree, RoutingEnum.OVERLAY_ITEM, id, extraParams);
    } else {
      return this.createViewOutletUrl(serializeTree, RoutingEnum.OVERLAY_ITEM);
    }
  };

  getProcessLink = (
    id?: string,
    serializeTree: boolean = true,
    extraParams?: any,
  ): NavigationParams | string => {
    if (id) {
      return this.createViewOutletUrl(serializeTree, RoutingEnum.OVERLAY_PROCESS, id, extraParams);
    } else {
      return this.createViewOutletUrl(serializeTree, RoutingEnum.OVERLAY_PROCESS);
    }
  };

  getProductLink = (
    id?: string,
    serializeTree: boolean = true,
    extraParams?: any,
  ): NavigationParams | string => {
    if (id) {
      return this.createViewOutletUrl(serializeTree, RoutingEnum.OVERLAY_PRODUCT, id, extraParams);
    } else {
      return this.createViewOutletUrl(serializeTree, RoutingEnum.OVERLAY_PRODUCT);
    }
  };

  getMaterialLink = (
    id?: string,
    serializeTree: boolean = true,
    extraParams?: any,
  ): NavigationParams | string => {
    if (id) {
      return this.createViewOutletUrl(serializeTree, RoutingEnum.OVERLAY_MATERIAL, id, extraParams);
    } else {
      return this.createViewOutletUrl(serializeTree, RoutingEnum.OVERLAY_MATERIAL);
    }
  };

  getBulkAddItemsLink = (
    serializeTree: boolean = true,
    extraParams?: any,
  ): NavigationParams | string => {
    return this.createBulkAddOutletUrl(
      serializeTree,
      BulkAddSlideOverModel.ResourceTypeEnum.ITEMS,
      extraParams,
    );
  };

  getRiskAssessmentTemplatesLink = (params?: RoutingLinkParams) =>
    this.getLink({
      route: RoutingEnum.OVERLAY_RISK_ASSESSMENT_TEMPLATE,
      ...params,
    });

  navigateByType = async (
    resourceType: EntityTypeEnum,
    id?: string,
    extraParams?: any,
  ): Promise<void> => {
    switch (resourceType) {
      case EntityTypeEnum.LOCATIONS:
        {
          await this.navigate(this.getLocationLink(id, undefined, extraParams));
        }
        break;
      case EntityTypeEnum.ORGANISATIONS:
        {
          await this.navigate(this.getOrganisationLink(id, undefined, extraParams));
        }
        break;
      case EntityTypeEnum.PRODUCTS:
        {
          await this.navigate(this.getProductLink(id, undefined, extraParams));
        }
        break;
      case EntityTypeEnum.ITEMS:
        {
          await this.navigate(this.getItemLink(id, undefined, extraParams));
        }
        break;
      case EntityTypeEnum.DELIVERIES:
        {
          await this.navigate(this.getDeliveryLink(id, undefined, extraParams));
        }
        break;
      case EntityTypeEnum.PROCESSES:
        {
          await this.navigate(this.getProcessLink(id, undefined, extraParams));
        }
        break;
      case EntityTypeEnum.DOCUMENTS:
        {
          await this.navigate(
            id
              ? this.getDocumentLink(id, undefined, extraParams)
              : [`/${RoutingEnum.DOCUMENTS_UPLOAD}`],
          );
        }
        break;
      case EntityTypeEnum.CERTIFICATES:
        {
          await this.navigate(this.getCertificateLink(id, undefined, extraParams));
        }
        break;
    }
  };

  closeViewOutlet = (): void => {
    const currentUrlTree = this.router.parseUrl(this.router.url);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { view, id, bulkAdd, organisationId, tab, type, ...newQueryParams } =
      currentUrlTree.queryParams;

    const newUrlTree = this.router.createUrlTree([], {
      ...currentUrlTree,
      queryParams: newQueryParams,
    });

    this.router.navigateByUrl(newUrlTree);
  };

  private createPrimaryOutletUrl(serializeTree: boolean, route: string): NavigationParams | string {
    const navigation: NavigationParams = {
      commands: [route],
      extras: {},
    };

    if (serializeTree) {
      return this.serializeViewOutletUrl(navigation);
    }

    return navigation;
  }

  private createViewOutletUrl(
    serializeTree: boolean,
    route: string,
    id?: string,
    extraParams?: any,
  ): NavigationParams | string {
    const navigation: NavigationParams = {
      commands: [],
      extras: {
        relativeTo: this.activatedRoute,
        queryParams: { view: route, id },
      },
    };

    if (extraParams) {
      for (const param in extraParams) {
        navigation.extras.queryParams[param] = extraParams[param];
      }
    }

    if (serializeTree) {
      return this.serializeViewOutletUrl(navigation);
    }

    return navigation;
  }

  private createBulkAddOutletUrl(
    serializeTree: boolean,
    route: string,
    extraParams?: any,
  ): NavigationParams | string {
    const navigation: NavigationParams = {
      commands: [],
      extras: {
        relativeTo: this.activatedRoute,
        queryParams: { bulkAdd: route },
      },
    };

    if (extraParams) {
      for (const param in extraParams) {
        navigation.extras.queryParams[param] = extraParams[param];
      }
    }

    if (serializeTree) {
      return this.serializeViewOutletUrl(navigation);
    }

    return navigation;
  }

  private serializeViewOutletUrl(route: NavigationParams): string {
    const urlTree = this.router.createUrlTree(route.commands, route.extras);

    return this.router.serializeUrl(urlTree);
  }

  private getLink = ({
    route,
    id,
    serializeTree = true,
    extraParams,
  }: { route: RoutingEnum } & RoutingLinkParams): NavigationParams | string =>
    this.createViewOutletUrl(serializeTree, route, id ? id : null, extraParams);
}
