<iov-dropdown-button
  (clickLabel)="onClickLabel()"
  [disabledMainButton]="disabledMainButton"
  [labelIcon]="labelIcon"
  [label]="label"
>
  <iov-dropdown-menu>
    @if (isCrossOrgSharingEnabled && isAccountOwnerOrAdmin && isConnection) {
      @if (isAddConectionEnabled) {
        <iov-dropdown-menu-item (pressed)="onAddConnection()">
          <mat-icon>link</mat-icon>
          Connect organisation
        </iov-dropdown-menu-item>
      } @else {
        <iov-dropdown-menu-item (pressed)="onEditConnection()">
          <mat-icon>edit</mat-icon>
          Edit connection
        </iov-dropdown-menu-item>
        <iov-dropdown-menu-item (pressed)="onRemoveConnection()">
          <mat-icon>cancel</mat-icon>
          Remove connection
        </iov-dropdown-menu-item>
      }
    }
    @if (isShareEnabled) {
      <iov-dropdown-menu-item (pressed)="share.emit()">
        <mat-icon>share</mat-icon>
        Share
      </iov-dropdown-menu-item>
    }
    @if (isDuplicateEnabled) {
      <iov-dropdown-menu-item (pressed)="onDuplicate()">
        <mat-icon svgIcon="copy"></mat-icon>
        Duplicate
        <mat-icon
          class="duplicate-icon"
          tp="This will only duplicate the item details and will allow the user to edit them before saving the new item."
          svgIcon="help"
        ></mat-icon>
      </iov-dropdown-menu-item>
    }
    @if (isArchiveEnabled) {
      <iov-dropdown-menu-item (pressed)="onToggleArchiveStatus()">
        <mat-icon>
          {{ element?.recordState === recordStateEnum.ARCHIVED ? "inventory" : "archive" }}
        </mat-icon>
        {{ element?.recordState === recordStateEnum.ARCHIVED ? "Unarchive" : "Archive" }}
      </iov-dropdown-menu-item>
    }
    @if (isDeleteEnabled) {
      <iov-dropdown-menu-item (pressed)="onDelete()">
        <mat-icon>delete</mat-icon>
        Delete
      </iov-dropdown-menu-item>
    }
    @if (canSend()) {
      <iov-dropdown-menu-item (pressed)="sendHandler()">
        <mat-icon>send</mat-icon>
        Send
      </iov-dropdown-menu-item>
    }
    <ng-content></ng-content>
  </iov-dropdown-menu>
</iov-dropdown-button>
