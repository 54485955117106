import { HttpClient, HttpEvent, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable, inject } from "@angular/core";

import { lastValueFrom, Observable } from "rxjs";

import { getDocumentsByIdsGraphQLQuery, getDocumentsGraphQLQuery } from "@shared/queries";
import { APP_CONFIG } from "@shared/tokens";

import { ApiService } from "./api.service";
import { GraphService } from "./graph.service";
import { CommonConstants } from "../../constants";
import { RecordStateEnum } from "../../enums";
import {
  IConfig,
  IDocument,
  IDocumentByIdsGraphQLResponse,
  IDocumentExtended,
  IDocumentGraphQLFilter,
  IDocumentGraphQLResponse,
  IDocumentPayload,
  IPageableContent,
  IRecordState,
} from "../../interfaces";
import { FileUtils, FormUtils } from "../../utils";
import { AuthenticationService } from "../authentication.service";

@Injectable({
  providedIn: "root",
})
export class DocumentsService {
  private graphService = inject(GraphService);

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {}

  public getPage = async (
    hasContent = true,
    name: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
    recordState: RecordStateEnum = undefined,
  ): Promise<IPageableContent<IDocument>> => {
    return await this.getPageSubscription(
      hasContent,
      name,
      size,
      page,
      sort,
      recordState,
    ).toPromise();
  };

  public getPageSubscription = (
    hasContent = true,
    name: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
    recordState: RecordStateEnum = undefined,
  ): Observable<IPageableContent<IDocument>> => {
    const urlParams: any = { name, size, page, sort, recordState };

    if (hasContent) {
      urlParams.hasContent = true;
    }
    const url = `${this.getBaseUrl()}?${FormUtils.addUrlParams(urlParams)}`;

    return this.apiService.get<IPageableContent<IDocument>>(url);
  };

  public async getAll(
    hasContent = true,
    recordState: RecordStateEnum = undefined,
  ): Promise<IDocument[]> {
    const result: IDocument[] = [];
    let page = 0;
    let hasNextPage: boolean = true;

    while (hasNextPage) {
      const {
        content,
        page: { totalElements },
      } = await this.getPage(
        hasContent,
        undefined,
        CommonConstants.MAX_API_GET_ITEMS_SIZE,
        page,
        undefined,
        recordState,
      );

      result.push(...content);

      page++;
      hasNextPage = result.length < totalElements;
    }

    return result;
  }

  public async getAllGraphQL(
    filter: IDocumentGraphQLFilter = null,
    first: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    include: string[] = [],
  ): Promise<IDocumentExtended[]> {
    const result: IDocumentExtended[] = [];
    let cursor: string | undefined = undefined;
    let hasNextPage: boolean = true;
    const activeOrganisationId = this.authenticationService.getActiveOrganisationId();

    while (hasNextPage) {
      const query = getDocumentsGraphQLQuery(
        activeOrganisationId,
        filter,
        first,
        null,
        cursor,
        null,
        include,
      );
      const { content, pageInfo, totalCount } = await this.graphService.fetchSinglePage<
        IDocumentGraphQLResponse,
        IDocumentExtended
      >("loadDocuments", query);

      result.push(...content);

      cursor = pageInfo.endCursor;
      hasNextPage = result.length < totalCount;
    }

    return result;
  }

  public async getByIdsGraphQL(
    ids: string[],
    first: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    include: string[] = [],
  ): Promise<IDocumentExtended[]> {
    if (!ids.length) {
      return [];
    }
    const result: IDocumentExtended[] = [];
    let cursor: string | undefined = undefined;
    let hasNextPage: boolean = true;
    const activeOrganisationId = this.authenticationService.getActiveOrganisationId();

    while (hasNextPage) {
      const query = getDocumentsByIdsGraphQLQuery(
        activeOrganisationId,
        ids,
        first,
        null,
        cursor,
        null,
        include,
      );
      const { content, pageInfo, totalCount } = await this.graphService.fetchSinglePage<
        IDocumentByIdsGraphQLResponse,
        IDocumentExtended
      >("loadDocumentsByIds", query);

      result.push(...content);

      cursor = pageInfo.endCursor;
      hasNextPage = result.length < totalCount;
    }

    return result;
  }

  public get = async (id: string): Promise<IDocument> =>
    await this.apiService.get<IDocument>(`${this.getBaseUrl()}/${id}`).toPromise();

  public delete = async (id: string): Promise<void> =>
    await this.apiService.delete<void>(`${this.getBaseUrl()}/${id}`).toPromise();

  public createOrUpdate = async (payload: IDocumentPayload, id?: string): Promise<IDocument> => {
    if (id) {
      return await this.apiService
        .put<IDocument>(`${this.getBaseUrl()}/${id}`, payload)
        .toPromise();
    } else {
      return await this.apiService.post<IDocument>(`${this.getBaseUrl()}`, payload).toPromise();
    }
  };

  public setRecordState = async (payload: IRecordState, id: string): Promise<IDocument> =>
    await this.apiService.put<IDocument>(`${this.getBaseUrl()}/${id}`, payload).toPromise();

  public uploadContent = (id: string, file: File): Observable<HttpEvent<any>> => {
    const headers = new HttpHeaders({
      "Content-Type": file.type,
    });

    return this.httpClient.put(`${this.getBaseUrl()}/${id}/content`, file, {
      headers,
      reportProgress: true,
      observe: "events",
    });
  };

  public downloadContentWithProgress = (
    id: string,
    urlPath: string,
  ): Observable<HttpEvent<any>> => {
    const uri = urlPath
      ? `${this.environment.baseUrl}${urlPath}`
      : `${this.getBaseUrl()}/${id}/content`;

    return this.httpClient.get(`${uri}`, {
      responseType: "blob",
      reportProgress: true,
      observe: "events",
    });
  };

  public downloadContent = (id: string, name: string): Promise<void> => {
    return this.httpClient
      .get(`${this.getBaseUrl()}/${id}/content`, { responseType: "blob" })
      .toPromise()
      .then((fileBlob) => {
        FileUtils.downloadFileBlob(fileBlob, name);
      });
  };

  public transferInboundBinaryData = async (
    documentUri: string,
    localDocumentId: string,
  ): Promise<void> => {
    const urlParams: any = { documentUri };

    const url = `${this.getBaseUrl()}/${localDocumentId}/content/copy-from?${FormUtils.addUrlParams(urlParams)}`;

    await lastValueFrom(this.httpClient.post(`${url}`, null));
  };

  private getBaseUrl = (): string =>
    `${this.environment.baseUrl}organisations/${this.authenticationService.getActiveOrganisationId()}/documents`;
}
