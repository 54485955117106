import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { CardContentComponent } from "@design-makeover/components/cards/card-content/card-content.component";

import { CommonConstants } from "@shared/constants";
import { ICertificateExtended } from "@shared/interfaces";

@Component({
  selector: "iov-certificate-content-card",
  templateUrl: "./certificate-content-card.component.html",
  styleUrl: "./certificate-content-card.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CertificateContentCardComponent extends CardContentComponent {
  @Input() certificate: ICertificateExtended;

  readonly dateFormat = CommonConstants.DATE_FORMAT;

  get name(): string {
    return `${this.certificate.standard?.name} ${this.certificate.standardType?.fullName ?? ""}`.trim();
  }
}
