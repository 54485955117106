import { GeoJsonTypeEnum, LanguageEnum, RecordStateEnum, TagsColorEnum } from "../enums";
import { ISelectOption } from "../interfaces";

const NOTIFICATION_DURATION_MS = 3500;
const NOTIFICATION_ERROR_DURATION_MS = 5000;
const NOTIFICATION_DOWNLOADS_DURATION_MS = 5000;
const DEBOUNCE_TIME_MS = 500;
const DEBOUNCE_TABLE_SET_HEIGHT_TIME_MS = 50;
const DEBOUNCE_TABLE_RESIZE_TIME_MS = 50;
const DEBOUNCE_TABLE_SAVE_STATE_TIME_MS = 50;
const DEBOUNCE_TABLE_SHOW_HIDE_NO_RECORDS_TIME_MS = 25;
const DEBOUNCE_SUPPLY_CHAIN_RESIZE_TIME_MS = 50;

const DATE_FORMAT = "MMM dd, yyyy";
const DATE_TIME_FORMAT = "MMM dd, yyyy - HH:mm";

const TOKEN_CLIENT_ID = "1";

const MAX_DOCUMENT_SIZE_IN_MB = 500;
const MAX_GEO_JSON_SIZE_IN_MB = 1;
const DOCUMENT_UPLOAD_ALLOWED_FILE_EXTENSIONS = [
  "PDF",
  "PNG",
  "JPG",
  "JPEG",
  "DOC",
  "DOCX",
  "XLS",
  "XLSX",
  "PPT",
  "PPTX",
  "CSV",
];
const DOCUMENT_ALLOW_TO_VIEW_FILE_EXTENSIONS = ["PDF", "PNG", "JPG", "JPEG"];

const GEOJSON_UPLOAD_ALLOWED_FILE_EXTENSIONS = ["GEOJSON", "JSON"];

const GEO_JSON_VALID_TYPES = [
  GeoJsonTypeEnum.POINT,
  GeoJsonTypeEnum.POLYGON,
  GeoJsonTypeEnum.MULTI_POLYGON,
];

const DEFAULT_NUMBER_PIPE_ROUNDING = "1.0-2";

const DEFAULT_LANGUAGE_CODE = LanguageEnum.ENGLISH;
const AVAILABLE_LANGUAGE_CODES = [LanguageEnum.ENGLISH, LanguageEnum.SPANISH];

const MAX_API_GET_ITEMS_SIZE = 2000;
const COMMENTS_MAX_LENGTH = 2000;

const TABLES_AVAILABLE_PAGE_SIZES = [10, 25, 50, 100];
const DEFAULT_TABLES_PAGE_SIZE = TABLES_AVAILABLE_PAGE_SIZES[0];

const RECORD_STATE_OPTIONS: ISelectOption[] = [
  { label: "All", value: RecordStateEnum.ALL },
  { label: "Active", value: RecordStateEnum.ACTIVE },
  { label: "Archived", value: RecordStateEnum.ARCHIVED },
];

const CERTIFICATE_DATE_RULES = [
  {
    name: "issuanceDate",
    rules: [
      { type: ">", target: "validFromDate", errorMessage: "Cannot be later than valid from date" },
      { type: ">", target: "validToDate", errorMessage: "Cannot be later than valid to date" },
    ],
  },
  {
    name: "validFromDate",
    rules: [
      { type: "<", target: "issuanceDate", errorMessage: "Cannot be before than issuance date" },
      { type: ">", target: "validToDate", errorMessage: "Cannot be later than valid to date" },
    ],
  },
  {
    name: "validToDate",
    rules: [
      { type: "<", target: "issuanceDate", errorMessage: "Cannot be before than issuance date" },
      { type: "<", target: "validFromDate", errorMessage: "Cannot be before than valid from date" },
    ],
  },
];
const MAX_TOOLTIP_LINES_AMOUNT = 10;

const EDIT_ARCHIVED_RECORD_ERROR_TEXT = "Editing an archived record is not allowed";

const DEFAULT_TAG_COLOUR = TagsColorEnum.LIGHT_GREY;

const MAX_CHIPS_TEXT_LENGTH_TO_SHOW = 25; // if this value changes, we need to change the value on .max-chip-characters

const POINT_OF_ORIGIN_ADMIN_TEXT =
  "Indicates that any location of this type is one where the raw materials originate from.";

const POINT_OF_ORIGIN_CHIP_TEXT =
  "This location type is marked as a point of origin, meaning, this is a location where raw materials originate from.";
const FILL_REQUIRED_FIELDS_MSG = "Please fill in all required fields.";
const SAME_ORG_NAME_ERROR_MSG = "Cannot be the same as your main organisation name.";
const SAME_AS_ORG_ADDRESS_TEXT = "Same as organisation address";

const SUPPLY_CHAIN_FULL_SCREEN_PANEL_CLASS = "full-screen-supply-chain-dialog";

const SESSION_HAS_EXPIRED_TEXT = "Session has expired. Please, log in again";

const DOCUMENT_NO_CONTENT_TEXT = "This document has no content";

const MAIN_INFORMATION_TEXT = "Main information";

const ADDITIONAL_INFORMATION_TEXT = "Additional information";

export const CommonConstants = {
  NOTIFICATION_DURATION_MS,
  NOTIFICATION_ERROR_DURATION_MS,
  NOTIFICATION_DOWNLOADS_DURATION_MS,
  DEBOUNCE_TIME_MS,
  DEBOUNCE_TABLE_RESIZE_TIME_MS,
  DEBOUNCE_TABLE_SET_HEIGHT_TIME_MS,
  DEBOUNCE_TABLE_SAVE_STATE_TIME_MS,
  DEBOUNCE_TABLE_SHOW_HIDE_NO_RECORDS_TIME_MS,
  DEBOUNCE_SUPPLY_CHAIN_RESIZE_TIME_MS,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  TOKEN_CLIENT_ID,
  MAX_DOCUMENT_SIZE_IN_MB,
  MAX_GEO_JSON_SIZE_IN_MB,
  DOCUMENT_UPLOAD_ALLOWED_FILE_EXTENSIONS,
  DOCUMENT_ALLOW_TO_VIEW_FILE_EXTENSIONS,
  GEOJSON_UPLOAD_ALLOWED_FILE_EXTENSIONS,
  GEO_JSON_VALID_TYPES,
  DEFAULT_NUMBER_PIPE_ROUNDING,
  DEFAULT_LANGUAGE_CODE,
  AVAILABLE_LANGUAGE_CODES,
  MAX_API_GET_ITEMS_SIZE,
  TABLES_AVAILABLE_PAGE_SIZES,
  DEFAULT_TABLES_PAGE_SIZE,
  RECORD_STATE_OPTIONS,
  CERTIFICATE_DATE_RULES,
  MAX_TOOLTIP_LINES_AMOUNT,
  EDIT_ARCHIVED_RECORD_ERROR_TEXT,
  DEFAULT_TAG_COLOUR,
  MAX_CHIPS_TEXT_LENGTH_TO_SHOW,
  COMMENTS_MAX_LENGTH,
  POINT_OF_ORIGIN_ADMIN_TEXT,
  POINT_OF_ORIGIN_CHIP_TEXT,
  FILL_REQUIRED_FIELDS_MSG,
  SAME_ORG_NAME_ERROR_MSG,
  SAME_AS_ORG_ADDRESS_TEXT,
  SUPPLY_CHAIN_FULL_SCREEN_PANEL_CLASS,
  SESSION_HAS_EXPIRED_TEXT,
  DOCUMENT_NO_CONTENT_TEXT,
  MAIN_INFORMATION_TEXT,
  ADDITIONAL_INFORMATION_TEXT,
};
