import { QueriesUtils } from "./queries.utils";
import { ILocationGraphQLFilter } from "../interfaces";

/**
 * Generates a GraphQL query to load locations based on specified parameters.
 *
 * @param {string} orgId - (Required) The organization ID for which locations are queried.
 * @param {ILocationGraphQLFilter} filter - An object containing filtering criteria for locations.
 * @param {number} first - The number of locations to retrieve in a single page (for forward pagination).
 * @param {number} last - The number of locations to retrieve in a single page (for backward pagination).
 * @param {string} after - The cursor indicating the end of the previous page for forward pagination.
 * @param {string} before - The cursor indicating the start of the next page for backward pagination.
 * @param {string[]} include - Additional fields to include in the query.
 * @returns {string} The generated GraphQL query for loading locations.
 */

export const getLocationsGraphQLQuery = (
  orgId: string,
  filter: ILocationGraphQLFilter,
  first: number,
  last: number,
  after: string,
  before: string,
  include: string[],
): string => {
  const filterClause = QueriesUtils.generateFilterClause(filter);
  const afterCursorClause = first > 0 ? QueriesUtils.generateClause("after", after) : "";
  const firstClause = first > 0 ? QueriesUtils.generateClause("first", first) : "";
  const beforeCursorClause = last > 0 ? QueriesUtils.generateClause("before", before) : "";
  const lastClause = last > 0 ? QueriesUtils.generateClause("last", last) : "";

  return `
{
    loadLocations(   
    orgId: "${orgId}",
    ${filterClause ? `${filterClause},` : ""}
    ${afterCursorClause ? `${afterCursorClause},` : ""}
    ${beforeCursorClause ? `${beforeCursorClause},` : ""}
    ${firstClause ? `${firstClause},` : ""}
    ${lastClause ? `${lastClause},` : ""}
) {
        ${getLocationProperties(include)}             
    }
}
`;
};

/**
 * Generates a GraphQL query to load locations based on specified parameters.
 *
 * @param {string} orgId - (Required) The organization ID for which locations are queried.
 * @param {string[]} ids - (Required) A list of locations ids
 * @param {number} first - The number of locations to retrieve in a single page (for forward pagination).
 * @param {number} last - The number of locations to retrieve in a single page (for backward pagination).
 * @param {string} after - The cursor indicating the end of the previous page for forward pagination.
 * @param {string} before - The cursor indicating the start of the next page for backward pagination.
 * @param {string[]} include - Additional fields to include in the query.
 * @returns {string} The generated GraphQL query for loading locations.
 */

export const getLocationsByIdsGraphQLQuery = (
  orgId: string,
  ids: string[],
  first: number,
  last: number,
  after: string,
  before: string,
  include: string[],
): string => {
  const afterCursorClause = first > 0 ? QueriesUtils.generateClause("after", after) : "";
  const firstClause = first > 0 ? QueriesUtils.generateClause("first", first) : "";
  const beforeCursorClause = last > 0 ? QueriesUtils.generateClause("before", before) : "";
  const lastClause = last > 0 ? QueriesUtils.generateClause("last", last) : "";

  return `
{
    loadLocationsByIds(   
    orgId: "${orgId}",
    ids: ${QueriesUtils.getParsedIds(ids)},
    ${afterCursorClause ? `${afterCursorClause},` : ""}
    ${beforeCursorClause ? `${beforeCursorClause},` : ""}
    ${firstClause ? `${firstClause},` : ""}
    ${lastClause ? `${lastClause},` : ""}
) {
        ${getLocationProperties(include)}      
    }
}
`;
};

const getLocationProperties = (include: string[]): string => `
        pageInfo {
            hasNextPage 
            hasPreviousPage
            startCursor
            endCursor
        }
        totalCount
        edges {
            cursor
            node {
                id
                name
                recordState
                types {
                    type
                    pointOfOrigin
                }
                address {
                    country
                }
                connections {
                    id
                    name
                }
                ${QueriesUtils.getQueryIncludes(include)}
            }            
        } 
`;
