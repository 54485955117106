import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  signal,
} from "@angular/core";

import { ColDef } from "ag-grid-community";
import { Subscription } from "rxjs";

import { SlideOverlayPageService } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.service";
import { NotificationService } from "@design-makeover/services/notification/notification.service";

import {
  FirstExistingValueCellRendererComponent,
  LinkCellRendererComponent,
} from "@shared/cell-renderers";
import { FeatureFlagEnum, RecordStateEnum, TableEnum } from "@shared/enums";
import { IProduct, IProductExtended } from "@shared/interfaces";
import { AuthenticationService, FeatureFlagService, ProductsService } from "@shared/services";
import { RouterService } from "@shared/services/router.service";
import { ColumnUtils, CommonUtils } from "@shared/utils";

@Component({
  selector: "app-products-table",
  templateUrl: "./products-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductsTableComponent implements AfterViewInit, OnDestroy {
  @Input()
  public products: IProductExtended[] = null;

  @Input()
  public productsIds: string[] = null;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isRecordStateFilterEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  @Input()
  public recordState: RecordStateEnum = RecordStateEnum.ALL;

  @Input()
  public table = TableEnum.PRODUCTS;

  @Input()
  public isSaveTableState = false;

  @Input()
  public columns: string[] = [
    "recordState",
    "name",
    "unitOfMeasurement.name",
    "allowedMaterials",
    "materials",
    "tags",
  ];

  public isLoading = signal(true);

  public rowData: IProductExtended[] = [];

  public columnDefs = signal<ColDef[]>([]);

  private subscriptions = new Subscription();

  public readonly isOldMaterialsEnabled = !this.featureFlagService.isEnabled(
    FeatureFlagEnum.NEW_MATERIALS_BEHAVIOUR,
  );

  constructor(
    private routerService: RouterService,
    private productsService: ProductsService,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService,
    private overlay: SlideOverlayPageService,
    private featureFlagService: FeatureFlagService,
  ) {
    this.subscriptions.add(
      this.overlay.refreshTable$.subscribe(() => {
        this.getAll();
      }),
    );
  }

  public async ngAfterViewInit() {
    this.setColumnDefs();
    await this.getAll();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setColumnDefs = (): void => {
    const columnDefs: ColDef[] = [
      ColumnUtils.recordState(),
      {
        headerName: "Name",
        field: "name",
        lockVisible: true,
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteIdParam: "id",
          linkRouteFn: this.routerService.getProductLink,
        },
      },
      {
        headerName: "Unit of measurement",
        field: "unitOfMeasurement.name",
        cellRenderer: FirstExistingValueCellRendererComponent,
        cellRendererParams: {
          propertiesOrderedByPriority: ["defaultCustomUnit.name", "unitOfMeasurement.name"],
        },
      },
    ];

    if (this.isOldMaterialsEnabled) {
      columnDefs.push(
        ColumnUtils.chips("Allowed materials", "allowedMaterials", {
          textParam: "name",
          linkRouteIdParam: "id",
          linkRouteFn: this.routerService.getMaterialLink,
        }),
      );
    } else {
      columnDefs.push(
        ColumnUtils.chips("Materials", "materials", {
          textParam: "name",
          linkRouteIdParam: "id",
          linkRouteFn: this.routerService.getMaterialLink,
        }),
      );
    }

    if (this.authenticationService.isRegularUser()) {
      columnDefs.push(ColumnUtils.tags("Tags"));
    }
    this.columnDefs.set(CommonUtils.getVisibleColumnDefs(columnDefs, this.columns));
  };

  public onViewDetails = (row: IProduct): void => {
    if (!this.areButtonsEnabled) {
      return;
    }

    this.routerService.navigate(this.routerService.getProductLink(row.id));
  };

  private getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    const tagsFieldPresent = this.columnDefs().some((c) => c.field === "tags");
    const fieldsToInclude = ["DEFAULT_CUSTOM_UNIT"];

    if (tagsFieldPresent) {
      fieldsToInclude.push("TAGS");
    }
    if (this.isOldMaterialsEnabled) {
      fieldsToInclude.push("ALLOWED_MATERIALS");
    } else {
      fieldsToInclude.push("MATERIALS");
    }

    if (this.products) {
      this.rowData = this.products;
      this.isLoading.set(false);
    } else if (this.productsIds) {
      try {
        if (!this.productsIds.length) {
          this.rowData = [];
          this.isLoading.set(false);

          return;
        }
        this.rowData = await this.productsService.getByIdsGraphQL(
          this.productsIds,
          undefined,
          fieldsToInclude,
        );

        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    } else {
      try {
        this.rowData = await this.productsService.getAllGraphQL(
          undefined,
          undefined,
          fieldsToInclude,
        );
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };
}
