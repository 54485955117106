import {
  AfterViewInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnInit,
  Output,
  signal,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from "@angular/forms";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";

import { RecordStateEnum } from "src/app/shared/enums";

import { InputSelectOption } from "@design-makeover/components/inputs/input-select/input-select.model";
import { InputSelectService } from "@design-makeover/components/inputs/input-select/input-select.service";
import { SlideOverlayPageService } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.service";
import { FormControlService } from "@design-makeover/services/form-control.service";

import { CommonConstants } from "@shared/constants";

@Component({
  selector: "iov-input-chips",
  templateUrl: "./input-chips.component.html",
  styleUrl: "./input-chips.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputChipsComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class InputChipsComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @ViewChild("autoInput") inputElementRef?: ElementRef;

  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

  @Input() class: string;

  @Input() hint: string;

  @Input() label: string;

  @Input() options: InputSelectOption[] = [];

  @Input() tooltip: string;

  @Input() tagUrl: string;

  @Input() placeholder: string;

  @Input({ transform: booleanAttribute }) disabled: boolean = false;

  @Input({ transform: booleanAttribute }) emitRawValue: boolean = false;

  @Input({ transform: booleanAttribute }) inputOnlyMode: boolean = false;

  @Input({ transform: booleanAttribute }) showClear: boolean = true;

  @Input({ transform: booleanAttribute }) allowDeleteTag: boolean = false;

  @Input({ transform: booleanAttribute }) allowCreateTag: boolean = false;

  @Input({ transform: booleanAttribute }) enableViewMode: boolean = false;

  @Input({ transform: booleanAttribute }) isGetTagsFromForm: boolean = false;

  @Input({ transform: booleanAttribute }) isTagClickable: boolean = false;

  @Input() viewModeTemplate: TemplateRef<unknown>;

  @Input() prefixTemplate: TemplateRef<unknown>;

  @Output() tagCreatedEvent: EventEmitter<void> = new EventEmitter();

  @Output() tagPressed: EventEmitter<InputSelectOption> = new EventEmitter<InputSelectOption>();

  ngControl: NgControl;

  filteredOptions: InputSelectOption[] = [];

  public isOptional = signal<boolean>(false);

  public isDisabled = signal<boolean>(false);

  loadingOptionTags: boolean = false;

  searchText: string = "";

  searchOption: InputSelectOption = { label: "" };

  selectedRawTags: unknown[] = [];

  selectedTags: InputSelectOption[] = [];

  readonly recordStateEnum = RecordStateEnum;

  readonly MAX_CHIPS_TEXT_LENGTH_TO_SHOW = CommonConstants.MAX_CHIPS_TEXT_LENGTH_TO_SHOW;

  private isInputSearchMade: boolean = false;

  constructor(
    private injector: Injector,
    private inputSelectService: InputSelectService,
    private formControlService: FormControlService,
    protected overlay: SlideOverlayPageService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  get dropdownIndicatorIcon(): string {
    return this.inputSelectService.getDropdownIcon(this.autocompleteTrigger?.panelOpen);
  }

  get hasValidationError(): boolean {
    return this.ngControl.errors && (this.ngControl.dirty || this.ngControl.touched);
  }

  get shouldHideClearButton(): boolean {
    const isEmpty = this.inputElementRef && !this.inputElementRef.nativeElement.value.length;

    return !this.showClear || this.isDisabled() || this.disabled || isEmpty;
  }

  get hasSelectedTags(): boolean {
    return !!this.selectedTags.length;
  }

  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl, null);
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  async ngAfterViewInit(): Promise<void> {
    await this.initialize();

    if (this.ngControl) {
      this.isOptional.set(this.formControlService.calculateOptionalFlag(this.ngControl.control));
    }
    this.changeDetectorRef.detectChanges();
  }

  getDisplayLabel = (option: InputSelectOption): string => option.label ?? "-";

  async writeValue(value: unknown): Promise<void> {
    if (this.isGetTagsFromForm) {
      return;
    }
    this.processSelectedTags(value);
  }

  registerOnChange(fn: (_: unknown | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled.set(isDisabled);
  }

  onAutocompleteOptionSelected = async (selectedTag: InputSelectOption): Promise<void> => {
    this.handleAddTag(selectedTag);
    this.removeTemporaryTag();
    this.forceBlurInput();
  };

  onInputClear(): void {
    this.removeTemporaryTag();
    this.clearInput();
    this.focusInput();
  }

  onInputFocus(): void {
    if (this.autocompleteTrigger.panelOpen) {
      return;
    }

    this.setFilteredOptions();
  }

  onInputKeyUp(event: KeyboardEvent): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;

    if (event.key === "Enter") {
      this.handleKeyEnter(target.value);

      return;
    }
    this.isInputSearchMade = true;
    this.handleSearch(target.value);
  }

  onAutocompleteClose(): void {
    if (this.isInputSearchMade && this.filteredOptions.length) {
      this.isInputSearchMade = false;

      if (!this.allowCreateTag || (!this.searchOption?.temporary && !this.searchOption?.loading)) {
        this.updateSelection(this.filteredOptions[0]);
      }

      this.removeTemporaryTag();
    }
  }

  onToggleDropdown(): void {
    if (this.disabled) {
      return;
    }
    if (this.autocompleteTrigger.panelOpen) {
      this.closeAutocompletePanel();
    } else {
      this.focusInput();
    }
  }

  onTagClick(tag: InputSelectOption): void {
    this.tagPressed.emit(tag);
  }

  async removeTag(tagOption: InputSelectOption): Promise<void> {
    if (tagOption.value) {
      tagOption.loading = true;
      try {
        if (this.allowDeleteTag) {
          await this.inputSelectService.removeTag(this.tagUrl, tagOption.value);
          this.options = this.options.filter((option) => option.value !== tagOption.value);
        }
      } finally {
        tagOption.loading = false;
        this.selectedTags = this.selectedTags.filter((option) => option.value !== tagOption.value);
        this.emitTagValue();
      }
    } else {
      this.removeTagWithoutId(tagOption);
    }
    this.clearInput();
    this.onTouched();
    this.closeAutocompletePanel();
  }

  async createTag(tagOption: InputSelectOption, creationOnly: boolean = false): Promise<void> {
    if (!creationOnly) {
      this.prepareTagCreation(tagOption);
    }
    try {
      const response = await this.inputSelectService.createTag(this.tagUrl, {
        type: tagOption.label,
        name: tagOption.label,
      });

      tagOption.value = response.id;
      tagOption.temporary = false;

      if (!creationOnly) {
        this.tagCreatedEvent.emit();
      }
    } catch (error) {
      if (!creationOnly) {
        this.removeTagWithoutId(tagOption);
      }
    } finally {
      if (!creationOnly) {
        this.completeTagCreation(tagOption);
      }
    }
  }

  private focusInput(): void {
    this.inputElementRef.nativeElement.focus();
  }

  private clearInput(): void {
    this.inputElementRef.nativeElement.value = "";
    this.isInputSearchMade = false;
  }

  private forceBlurInput = (): void => {
    setTimeout(() => this.inputElementRef.nativeElement.blur(), 10);
  };

  private prepareTagCreation(tagToCreate: InputSelectOption): void {
    tagToCreate.loading = true;
    this.clearInput();

    this.closeAutocompletePanel();
  }

  private closeAutocompletePanel = (): void => {
    if (this.autocompleteTrigger.panelOpen) {
      this.autocompleteTrigger.closePanel();
    }
  };

  private completeTagCreation(tagToCreate: InputSelectOption): void {
    tagToCreate.loading = false;
    this.searchOption = null;
  }

  private removeTemporaryTag(): void {
    const temporaryTag = this.selectedTags.find((tag) => tag.temporary);

    if (temporaryTag) {
      this.removeTagWithoutId(temporaryTag);
    }
  }

  private async handleKeyEnter(value: string): Promise<void> {
    const label: string = value.trim();

    if (!this.searchOption?.label || !this.allowCreateTag) {
      return;
    }

    const isTagAlreadySelected = this.searchTag(this.selectedTags, label);

    if (isTagAlreadySelected) {
      return;
    }

    const isTagBeingCreated = this.isTagBeingCreated(this.selectedTags, label);

    if (isTagBeingCreated) {
      return;
    }

    const existingTag = this.searchTag(this.filteredOptions, label);

    if (existingTag) {
      this.handleAddTag(existingTag);
      this.closeAutocompletePanel();
    } else {
      const tag = this.selectedTags.find(
        (tag) => tag.label === label && !tag.value && !tag.loading,
      );

      await this.createTag(tag);
      this.removeTemporaryTag();
      this.forceBlurInput();
    }
  }

  private removeTagWithoutId(tag?: InputSelectOption): void {
    if (!tag) {
      if (!this.searchOption) {
        return;
      }

      tag = this.searchOption;
    }

    this.options = this.options.filter(
      (option) => option.label.toLowerCase() !== tag.label.toLowerCase(),
    );
    this.selectedTags = this.selectedTags.filter(
      (option) => option.label.toLowerCase() !== tag.label.toLowerCase(),
    );
    this.searchOption = null;
    this.emitTagValue();
  }

  private searchTag(options: InputSelectOption[], label: string): InputSelectOption {
    const labelLowerCase = label.toLowerCase();

    return options.find((tag) => tag.label.toLowerCase() === labelLowerCase && tag.value) || null;
  }

  private isTagBeingCreated(options: InputSelectOption[], label: string): InputSelectOption {
    const labelLowerCase = label.toLowerCase();

    return options.find((tag) => tag.label.toLowerCase() === labelLowerCase && tag.loading) || null;
  }

  private handleSearch(value: string): void {
    const label = value.trim();

    this.setFilteredOptions(label);

    if (!this.allowCreateTag) {
      return;
    }

    if (!label) {
      this.removeTemporaryTag();

      return;
    }

    const existsTag = this.searchTag(this.options, label);
    const isTagAlreadySelected = this.searchTag(this.selectedTags, label);

    if (existsTag) {
      if (isTagAlreadySelected) {
        this.removeTemporaryTag();
      } else {
        this.searchOption = existsTag;
        this.onTouched();
      }

      return;
    }

    const isTagBeingCreated = this.isTagBeingCreated(this.options, label);

    if (isTagBeingCreated) {
      return;
    }

    const existingTag = this.searchOption && this.options.find((tag) => !tag.value && !tag.loading);

    if (existingTag) {
      this.searchOption = existingTag;
    } else {
      this.searchOption = { label, temporary: true };
      this.options.push(this.searchOption);
      this.handleAddTag(this.searchOption);
    }

    this.searchOption.label = label;
    this.onTouched();
  }

  private processSelectedTags(value?: unknown): void {
    if (value) {
      this.selectedRawTags = this.inputSelectService.extractValues(value);
    }
    this.selectedTags = this.inputSelectService.findSelectedValues(
      this.selectedRawTags,
      this.options,
    );
  }

  private setFilteredOptions(value?: string): void {
    const filteredOptions = this.options.filter(
      (option) =>
        (!option.recordState ||
          (!!option.recordState && option.recordState === RecordStateEnum.ACTIVE)) &&
        !this.selectedTags.some((selectedTag) => selectedTag.value === option.value),
    );

    this.filteredOptions = this.inputSelectService.filterOptions(
      value ?? this.inputElementRef.nativeElement.value,
      filteredOptions,
    );
  }

  private async initialize(): Promise<void> {
    if (this.isGetTagsFromForm) {
      this.selectedTags = this.ngControl?.value ?? [];
    } else if (this.tagUrl) {
      this.loadingOptionTags = true;
      const options = await this.inputSelectService.getTags(this.tagUrl);

      this.options = options || [];
      this.processSelectedTags();
      this.loadingOptionTags = false;
    }
  }

  private onChange: (_: unknown | null) => void = () => {};

  private onTouched: () => void = () => {};

  private updateSelection(option: InputSelectOption): void {
    this.handleAddTag(option);
  }

  private handleAddTag(option: InputSelectOption): void {
    this.selectedTags.push(option);
    this.emitTagValue();
    if (option.value) {
      this.clearInput();
    }
    this.onTouched();
  }

  private emitTagValue(): void {
    if (this.emitRawValue) {
      this.onChange(this.selectedTags.map((item) => item.value));
    } else {
      this.onChange(this.selectedTags);
    }
  }
}
