import { Inject, Injectable } from "@angular/core";

import { lastValueFrom, Observable } from "rxjs";

import { ExtensionProviderEnum } from "@shared/enums";
import { APP_CONFIG } from "@shared/tokens";

import { ApiService } from "./api.service";
import { CommonConstants } from "../../constants";
import { IAnalysis, IConfig, ICreateAnalysis, IPageableContent } from "../../interfaces";
import { FormUtils } from "../../utils";
import { AuthenticationService } from "../authentication.service";

@Injectable({
  providedIn: "root",
})
export class AnalysisExtensionService {
  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {}

  public getPage = async (
    name: string = undefined,
    locationId: string,
    providerId: string = ExtensionProviderEnum.ORBIFY,
    status: string = undefined, // PENDING, AVAILABLE, ERROR
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
  ): Promise<IPageableContent<IAnalysis>> => {
    return await this.getPageSubscription(
      name,
      locationId,
      providerId,
      status,
      size,
      page,
      sort,
    ).toPromise();
  };

  public getPageSubscription = (
    name: string = undefined,
    locationId: string,
    providerId: string = ExtensionProviderEnum.ORBIFY,
    status: string = undefined, // PENDING, AVAILABLE, ERROR
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
  ): Observable<IPageableContent<IAnalysis>> => {
    const url = `${this.getBaseUrl()}?${FormUtils.addUrlParams({
      name,
      locationId,
      providerId,
      status,
      size,
      page,
      sort,
    })}`;

    return this.apiService.get<IPageableContent<IAnalysis>>(url);
  };

  public async getAll(
    locationId: string,
    providerId: string = ExtensionProviderEnum.ORBIFY,
  ): Promise<IAnalysis[]> {
    const result: IAnalysis[] = [];
    let page = 0;
    let hasNextPage: boolean = true;

    while (hasNextPage) {
      const {
        content,
        page: { totalElements },
      } = await this.getPage(
        undefined,
        locationId,
        providerId,
        undefined,
        CommonConstants.MAX_API_GET_ITEMS_SIZE,
        page,
        undefined,
      );

      result.push(...content);

      page++;
      hasNextPage = result.length < totalElements;
    }

    return result;
  }

  public get = async (id: string): Promise<IAnalysis> =>
    await lastValueFrom(this.apiService.get<IAnalysis>(`${this.getBaseUrl()}/${id}`));

  public delete = async (id: string): Promise<void> =>
    await this.apiService.delete<void>(`${this.getBaseUrl()}/${id}`).toPromise();

  public create = async (payload: ICreateAnalysis): Promise<IAnalysis> =>
    await lastValueFrom(this.apiService.post<IAnalysis>(`${this.getBaseUrl()}`, payload));

  private getBaseUrl = (): string =>
    `${
      this.environment.baseUrl
    }organisations/${this.authenticationService.getActiveOrganisationId()}/extensions/geospatial`;
}
