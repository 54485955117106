<div class="container-space-between">
  <app-page-title
    [title]="translations.organisations"
    hint="Record and manage external organisations that your organisation deals with."
  ></app-page-title>

  <iov-button-group [visible]="!isLoading() && authenticationService.canAddModifyEntities()">
    <iov-button (pressed)="onAdd()">
      <mat-icon icon-left>add</mat-icon>
      Add new
    </iov-button>
  </iov-button-group>
</div>

@if (isLoading()) {
  <app-loader></app-loader>
} @else {
  <mat-card class="active-organisation" (click)="onViewDetails(activeOrganisation.id)">
    <div class="details">
      <div class="title" i18n="@@organisations.your-organisation">Your organisation</div>
      <div class="subtitle">
        {{ activeOrganisation.name }}
        <app-flag [country]="activeOrganisation.address.country"></app-flag>
        {{ activeOrganisation.address.countryName }}
      </div>
    </div>
  </mat-card>

  <app-connections-table
    [recordState]="recordStateEnum.ACTIVE"
    [isSaveTableState]="true"
    [isFixedBottomPaginator]="true"
    [allowClickOnNameRow]="false"
  ></app-connections-table>
}
