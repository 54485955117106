<iov-slide-overlay-header [title]="title()" icon="certificates"></iov-slide-overlay-header>
<iov-slide-overlay-content #slideOverlayContent>
  <ng-template
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="menu-info"
    overlay-menu-title="Certificate details"
  >
    <iov-form-field-group [name]="mainInformationText">
      <app-certificate-overlay-main-information
        class="full-width"
        [formGroup]="formGroup"
        [standardTypeOptions]="standardTypeOptions()"
        [standardOptions]="standardOptions()"
      ></app-certificate-overlay-main-information>
    </iov-form-field-group>
    @if (visibleCustomFields?.length) {
      <iov-form-field-group [name]="additionalInformationText">
        <app-custom-fields-edit
          [columns]="1"
          [elementCustomFields]="element?.customFields"
          [formGroup]="formGroup"
          [enableViewMode]="true"
          [visibleCustomFields]="visibleCustomFields"
        ></app-custom-fields-edit>
      </iov-form-field-group>
    }
  </ng-template>
  <ng-template
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.ATTACHED_TO).isEnabled"
    [overlay-menu-indicator]="selectedAttachments?.length"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="overlayTabEnum.ATTACHED_TO"
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="paperclip"
  >
    <article class="field-group-container no-padding-bottom">
      <app-attachments-table [attachments]="selectedAttachments"></app-attachments-table>
    </article>
  </ng-template>
  <ng-template
    [overlay-menu-buttons-template]="documentController"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.DOCUMENTS).isEnabled"
    [overlay-menu-indicator]="certificateOverlay.documentCounter()"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="overlayTabEnum.DOCUMENTS"
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="document"
  >
    <app-overlay-document-attachments
      #documentSection
      [attachmentTargetId]="element?.id"
      [attachmentTargetType]="attachmentTargetEnum.CERTIFICATE"
      [isReadOnly]="isRecordArchived"
      [overlayService]="certificateOverlay"
    ></app-overlay-document-attachments>
  </ng-template>
  <ng-template
    *ngIf="!menuItems().get(overlayTabEnum.COMMENTS).isHidden"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.COMMENTS).isEnabled"
    [overlay-menu-indicator]="certificateOverlay.commentCounter()"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="overlayTabEnum.COMMENTS"
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="comment"
  >
    <article class="field-group-container">
      <app-comments
        (added)="onCommentAdded(certificateOverlay)"
        (deleted)="onCommentDeleted(certificateOverlay)"
        [entityId]="element?.id"
        [entityType]="entityType"
      ></app-comments>
    </article>
  </ng-template>
  <ng-template #documentController>
    @if (!overlay.contentRightSidePanel() && isRecordActive && canAddModifyEntities) {
      <iov-button
        (pressed)="overlay.openSideMenu(documentSection?.attachmentsRightMenu)"
        [disabled]="overlay.loading()"
        type="ghost"
      >
        <mat-icon class="action-icon" icon-left>add</mat-icon>
        Add
      </iov-button>
    }
  </ng-template>
</iov-slide-overlay-content>
