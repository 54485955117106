import { HttpErrorResponse } from "@angular/common/http";
import { AfterViewInit, ChangeDetectionStrategy, Component, Input, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef } from "ag-grid-community";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { ConfirmDialogResponseEnum, TableEnum } from "@shared/enums";
import { IOrganisation } from "@shared/interfaces";
import { AuthenticationService, OrganisationsService, UsersService } from "@shared/services";
import { CellRendererUtils, ColumnUtils, CommonUtils } from "@shared/utils";

import { ConfirmDialogComponent, SelectOrganisationDialogComponent } from "../..";

@Component({
  selector: "app-memberships-table",
  templateUrl: "./memberships-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MembershipsTableComponent implements AfterViewInit {
  @Input()
  public memberships: IOrganisation[] = null;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  @Input()
  public table = TableEnum.MEMBERSHIPS;

  @Input()
  public isSaveTableState = false;

  public isLoading = signal(true);

  public rowData: IOrganisation[] = [];

  public columnDefs = signal<ColDef[]>([]);

  constructor(
    private usersService: UsersService,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService,
    private organisationsService: OrganisationsService,
    private dialog: MatDialog,
  ) {}

  public async ngAfterViewInit() {
    this.setColumnDefs();
    await this.getAll();
  }

  private setColumnDefs = (): void => {
    const columnDefs: ColDef[] = [
      { headerName: "Organisation", field: "name", lockVisible: true },
      { headerName: "Role", field: "roles", cellRenderer: CellRendererUtils.roleNames },
    ];

    if (this.areButtonsEnabled) {
      const buttons = [
        {
          onClick: this.onLeaveOrganisation,
          tooltip: "Leave organisation",
          icon: "logout",
        },
      ];

      columnDefs.push(ColumnUtils.buttons(buttons));
    }
    this.columnDefs.set(columnDefs);
  };

  public onLeaveOrganisation = (organisation: IOrganisation): void => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: "Leave organisation",
        contentTranslatedText: "Are you sure that you want to leave this organisation?",
        confirmButtonColor: "danger",
        confirmButtonTranslatedText: "Leave",
        confirmButtonIcon: "logout",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        this.isLoading.set(true);
        const userId = this.authenticationService.getUserId();

        await this.usersService
          .leaveOrganisation(userId, organisation.id)
          .then(async () => {
            this.notificationService.showSuccess(`You left '${organisation.name}'`);
            const previouslyActiveOrgId = this.authenticationService.getActiveOrganisationId();

            await this.authenticationService.removeAvailableOrganisation(organisation.id);
            this.isLoading.set(false);

            if (this.authenticationService.getAvailableOrganisations()?.length) {
              if (organisation.id === previouslyActiveOrgId) {
                this.onOpenSelectOrganisationDialog();
              } else {
                await this.getAll();
              }
            }
          })
          .catch((error: HttpErrorResponse) => {
            this.notificationService.showError(error);
            this.isLoading.set(false);
          });
      }
    });
  };

  private onOpenSelectOrganisationDialog = (): void => {
    const dialogRef = this.dialog.open(SelectOrganisationDialogComponent);

    dialogRef.afterClosed().subscribe(async () => {
      await this.getAll();
    });
  };

  private getParsedRowData = async (memberships: any[]): Promise<any[]> => {
    const result = [];

    for (const userOrganisation of memberships) {
      const organisationId = CommonUtils.getUriId(userOrganisation.organisation);
      const organisation = await this.organisationsService.get(organisationId);

      const memberId = CommonUtils.getUriId(userOrganisation.membership);
      const member = await this.organisationsService.getOrganisationMember(
        organisationId,
        memberId,
      );

      result.push({ id: organisationId, name: organisation.name, roles: member.roles });
    }

    return result;
  };

  public getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    if (this.memberships) {
      this.rowData = await this.getParsedRowData(this.memberships);
      this.isLoading.set(false);
    } else {
      try {
        const userId = this.authenticationService.getUserId();
        const memberships = await this.usersService.getAllUserOrganisationMemberships(userId);

        this.rowData = await this.getParsedRowData(memberships);
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };
}
