<form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
  <h2 mat-dialog-title>{{ isEditing() ? "Edit" : "Add" }} tag</h2>

  <app-loader *ngIf="isLoading()" containerClass="on-dialog"></app-loader>

  <ng-container *ngIf="!isLoading()">
    <mat-dialog-content>
      <iov-input
        [autofocus]="!isLoading() && !isEditing()"
        formControlName="title"
        label="Name"
      ></iov-input>

      <iov-input-select
        [options]="colorOptions"
        [prefixTemplate]="colorBox"
        formControlName="color"
        label="Color"
      ></iov-input-select>

      <ng-template #colorBox let-option>
        <div [class]="option.value" class="color-box"></div>
      </ng-template>
    </mat-dialog-content>

    <mat-dialog-actions>
      <iov-button-group>
        <iov-button (pressed)="onClose()" type="ghost">Cancel</iov-button>
        <iov-button [disabled]="isSubmitButtonDisabled()" accessibilityRole="submit">
          <mat-icon icon-left>check</mat-icon>
          Save
        </iov-button>
      </iov-button-group>
    </mat-dialog-actions>
  </ng-container>
</form>
