<div [formGroup]="formGroup" class="row-container">
  <div class="row">
    <div class="item">
      <iov-input enableViewMode formControlName="number" label="Number"></iov-input>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <iov-input-select
        [options]="standardOptions()"
        enableViewMode
        formControlName="standard"
        label="Standard"
      ></iov-input-select>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <iov-input-select
        [disabled]="!standardTypeOptions()?.length || formGroup.disabled"
        [options]="standardTypeOptions()"
        enableViewMode
        formControlName="standardType"
        label="Standard type"
      ></iov-input-select>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <iov-datepicker
        [maxDate]="
          formGroup.controls['validFromDate'].value ?? formGroup.controls['validToDate'].value
        "
        enableViewMode
        formControlName="issuanceDate"
        label="Issuance date"
      ></iov-datepicker>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <iov-datepicker
        [maxDate]="formGroup.controls['validToDate'].value"
        [minDate]="formGroup.controls['issuanceDate'].value"
        enableViewMode
        formControlName="validFromDate"
        label="Valid from"
      ></iov-datepicker>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <iov-datepicker
        [minDate]="
          formGroup.controls['validFromDate'].value ?? formGroup.controls['issuanceDate'].value
        "
        enableViewMode
        formControlName="validToDate"
        label="Valid to"
      ></iov-datepicker>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <iov-input
        enableViewMode
        [viewModeTemplate]="urlTemplate"
        formControlName="url"
        label="URL"
        tooltip="Specify a link to an external source that contains up-to-date information about this
                   certificate for ease of navigating to it to get the most recent information."
        type="url"
      ></iov-input>
    </div>
  </div>
</div>
<ng-template #urlTemplate let-data>
  <div class="form-field-item hover-visibility-container">
    <iov-form-label [label]="data.label"></iov-form-label>
    <div class="form-field-input-readonly">
      @if (data.value) {
        <div class="one-line-wrap-ellipsis">
          <a
            class="blue-link"
            [href]="data.value"
            target="_blank"
            tpPlacement="top-start"
            [tp]="data.value"
          >
            {{ data.value }}
            <app-external-link-icon></app-external-link-icon>
          </a>
        </div>
      } @else {
        -
      }
    </div>
  </div>
</ng-template>
