import { QueriesUtils } from "./queries.utils";
import { ICertificateGraphQLFilter } from "../interfaces";

/**
 * Generates a GraphQL query to load certificates based on specified parameters.
 *
 * @param {string} orgId - (Required) The organization ID for which certificates are queried.
 * @param {ICertificateGraphQLFilter} filter - An object containing filtering criteria for certificates.
 * @param {number} first - The number of certificates to retrieve in a single page (for forward pagination).
 * @param {number} last - The number of certificates to retrieve in a single page (for backward pagination).
 * @param {string} after - The cursor indicating the end of the previous page for forward pagination.
 * @param {string} before - The cursor indicating the start of the next page for backward pagination.
 * @param {string[]} include - Additional fields to include in the query.
 * @returns {string} The generated GraphQL query for loading certificates.
 */

export const getCertificatesGraphQLQuery = (
  orgId: string,
  filter: ICertificateGraphQLFilter,
  first: number,
  last: number,
  after: string,
  before: string,
  include: string[],
): string => {
  const filterClause = QueriesUtils.generateFilterClause(filter);
  const afterCursorClause = first > 0 ? QueriesUtils.generateClause("after", after) : "";
  const firstClause = first > 0 ? QueriesUtils.generateClause("first", first) : "";
  const beforeCursorClause = last > 0 ? QueriesUtils.generateClause("before", before) : "";
  const lastClause = last > 0 ? QueriesUtils.generateClause("last", last) : "";

  return `
{
    loadCertificates(   
    orgId: "${orgId}",
    ${filterClause ? `${filterClause},` : ""}
    ${afterCursorClause ? `${afterCursorClause},` : ""}
    ${beforeCursorClause ? `${beforeCursorClause},` : ""}
    ${firstClause ? `${firstClause},` : ""}
    ${lastClause ? `${lastClause},` : ""}
) {
        ${getCertificateProperties(include)}      
    }
}
`;
};

/**
 * Generates a GraphQL query to load certificates based on specified parameters.
 *
 * @param {string} orgId - (Required) The organization ID for which certificates are queried.
 * @param {string[]} ids - (Required) A list of certificates ids
 * @param {ICertificateGraphQLFilter} filter - An object containing filtering criteria for certificates.
 * @param {number} first - The number of certificates to retrieve in a single page (for forward pagination).
 * @param {number} last - The number of certificates to retrieve in a single page (for backward pagination).
 * @param {string} after - The cursor indicating the end of the previous page for forward pagination.
 * @param {string} before - The cursor indicating the start of the next page for backward pagination.
 * @param {string[]} include - Additional fields to include in the query.
 * @returns {string} The generated GraphQL query for loading certificates.
 */

export const getCertificatesByIdsGraphQLQuery = (
  orgId: string,
  ids: string[],
  first: number,
  last: number,
  after: string,
  before: string,
  include: string[],
): string => {
  const afterCursorClause = first > 0 ? QueriesUtils.generateClause("after", after) : "";
  const firstClause = first > 0 ? QueriesUtils.generateClause("first", first) : "";
  const beforeCursorClause = last > 0 ? QueriesUtils.generateClause("before", before) : "";
  const lastClause = last > 0 ? QueriesUtils.generateClause("last", last) : "";

  return `
{
    loadCertificatesByIds(   
    orgId: "${orgId}",
    ids: ${QueriesUtils.getParsedIds(ids)},
    ${afterCursorClause ? `${afterCursorClause},` : ""}
    ${beforeCursorClause ? `${beforeCursorClause},` : ""}
    ${firstClause ? `${firstClause},` : ""}
    ${lastClause ? `${lastClause},` : ""}
) {       
  ${getCertificateProperties(include)}
    }
}
`;
};

export const getCertificateProperties = (include: string[]): string => `
        pageInfo {
            hasNextPage 
            hasPreviousPage
            startCursor
            endCursor
        }
        totalCount
        edges {
            cursor
            node {
                id
                number
                issuanceDate
                validFromDate
                validToDate
                standard {
                    id
                    name
                }
                standardType {
                    id
                    fullName
                }
                recordState
                ${QueriesUtils.getQueryIncludes(include)}
            }            
        } 
`;
