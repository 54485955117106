@if (downloadProgress === 100) {
  <div class="downloaded-container">
    <div class="title-container">
      <div>{{ document.name }}</div>
      <a [href]="documentUrl" [download]="document.name" tp="Download">
        <mat-icon>download</mat-icon>
      </a>
    </div>
    @switch (documentExtension) {
      @case ("pdf") {
        <iframe [src]="documentUrl" frameBorder="0"></iframe>
      }
      @default {
        <div class="text-center">
          <img [src]="documentUrl" [alt]="document.name" />
        </div>
      }
    }
  </div>
} @else {
  <div class="downloading-container">
    <div class="info">
      @if (errorMessage) {
        <div class="red">{{ errorMessage }}</div>
      } @else {
        Opening {{ document ? document.name : "document" }}
        <div class="progress-container">
          <mat-progress-bar [value]="downloadProgress"></mat-progress-bar>
          {{ downloadProgress }}%
        </div>
      }
    </div>
  </div>
}
