<div class="form-label">
  <label [for]="label()" class="input-select-label">{{ label() }}</label>
  @if (tooltip()) {
    <mat-icon
      class="suffix-icon error"
      [class]="iconClass()"
      [svgIcon]="icon()"
      [tp]="tooltip()"
    ></mat-icon>
  }
  <ng-content select="[action]"></ng-content>
</div>
