import { AfterViewInit, ChangeDetectionStrategy, Component, Input, signal } from "@angular/core";
import { Router } from "@angular/router";

import { ColDef } from "ag-grid-community";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { DateCellRendererComponent, LinkCellRendererComponent } from "@shared/cell-renderers";
import { CommonConstants } from "@shared/constants";
import { RoutingEnum, TableEnum } from "@shared/enums";
import { UsersService } from "@shared/services";
import { CommonUtils } from "@shared/utils";

@Component({
  selector: "app-admin-users-table",
  templateUrl: "./admin-users-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminUsersTableComponent implements AfterViewInit {
  @Input()
  public users: any[] = null;

  @Input()
  public allOrganisations: any[] = [];

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  @Input()
  public table = TableEnum.ADMIN_USERS;

  @Input()
  public isSaveTableState = false;

  public isLoading = signal(true);

  public rowData: any[] = [];

  public columnDefs = signal<ColDef[]>([]);

  constructor(
    private usersService: UsersService,
    private notificationService: NotificationService,
    private router: Router,
  ) {}

  public async ngAfterViewInit() {
    this.setColumnDefs();
    await this.getAll();
  }

  private setColumnDefs = (): void => {
    this.columnDefs.set([
      {
        headerName: "Organisation(s)",
        field: "organisationNames",
        suppressSizeToFit: true,
      },
      {
        headerName: "First name",
        field: "firstName",
        lockVisible: true,
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRoute: RoutingEnum.ADMIN_USER_DETAILS,
          linkRouteIdParam: "id",
        },
      },
      {
        headerName: "Last name",
        field: "lastName",
        lockVisible: true,
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRoute: RoutingEnum.ADMIN_USER_DETAILS,
          linkRouteIdParam: "id",
        },
      },
      { headerName: "Email", field: "emailAddress" },
      {
        headerName: "Last active",
        field: "lastActive",
        cellRenderer: DateCellRendererComponent,
        cellRendererParams: {
          dateFormat: CommonConstants.DATE_TIME_FORMAT,
        },
      },
    ]);
  };

  public onViewDetails = async (user: any): Promise<void> => {
    await this.router.navigate([`/${RoutingEnum.ADMIN_USER_DETAILS}/${user.id}`]);
  };

  private setOrganisationsNames = (users: any[]): void => {
    for (const user of users) {
      user.organisationNames = [];
      for (const organsiationInfo of user.organisations) {
        const organisationId = CommonUtils.getUriId(organsiationInfo.organisation);
        const organisation = this.allOrganisations.find((o) => o.id === organisationId);

        if (organisation) {
          user.organisationNames.push(organisation.name);
        }
      }
    }
  };

  public getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    if (this.users) {
      const rowData = this.users;

      this.setOrganisationsNames(rowData);
      this.rowData = rowData;
      this.isLoading.set(false);
    } else {
      try {
        const rowData = await this.usersService.getAll();

        this.setOrganisationsNames(rowData);
        this.rowData = rowData;
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };
}
