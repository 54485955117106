import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  OnDestroy,
  QueryList,
  TemplateRef,
} from "@angular/core";

import { Subscription } from "rxjs";

import { StepDirective } from "@design-makeover/components/stepper/step.directive";
import { StepperService } from "@design-makeover/components/stepper/stepper.service";

@Component({
  selector: "iov-stepper",
  templateUrl: "./stepper.component.html",
  styleUrl: "./stepper.component.scss",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class StepperComponent implements AfterViewInit, OnDestroy {
  @ContentChildren(StepDirective) steps: QueryList<StepDirective> = QueryList.prototype;

  currentStep: TemplateRef<unknown>;

  private subscriptions: Subscription = new Subscription();

  constructor(private stepper: StepperService) {}

  ngAfterViewInit(): void {
    this.stepper.setSteps(this.steps);
    const subscription = this.stepper.getCurrentStep$().subscribe((step) => {
      this.onSelect(step, true);
    });

    this.subscriptions.add(subscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onSelect(step: StepDirective, force: boolean = false): void {
    if ((step.disabled || step.active) && !force) {
      return;
    }

    const steps = this.steps.toArray();
    const selectedStepIndex = steps.findIndex((s) => s === step);

    steps.forEach((s, index) => {
      s.active = false;

      const isEnabled =
        (s.canGoBackTo && index === selectedStepIndex - 1) ||
        (s.canGoForwardTo && index === selectedStepIndex + 1);

      s.disabled = !isEnabled;
    });

    step.active = true;
    step.disabled = false;
    this.currentStep = step.templateRef;
  }
}
