import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

import { ColDef } from "ag-grid-community";
import { Subscription } from "rxjs";
import { LinkCellRendererComponent } from "src/app/shared/cell-renderers";
import { FeatureFlagEnum, RecordStateEnum, TableEnum } from "src/app/shared/enums";
import { IConnectionExtended, ISelectOption } from "src/app/shared/interfaces";
import {
  AuthenticationService,
  CommonService,
  ConnectionsService,
  FeatureFlagService,
} from "src/app/shared/services";
import { CellRendererUtils, ColumnUtils, CommonUtils } from "src/app/shared/utils";

import { SlideOverlayPageService } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.service";
import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { RouterService } from "@shared/services/router.service";

@Component({
  selector: "app-connections-table",
  templateUrl: "./connections-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectionsTableComponent implements AfterViewInit, OnDestroy {
  @Input()
  public connections: IConnectionExtended[] = null;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isRecordStateFilterEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public subscribeToOverlayRefreshTable = true;

  @Input()
  public isFixedBottomPaginator = false;

  @Input()
  public recordState: RecordStateEnum = RecordStateEnum.ALL;

  @Input()
  public table = TableEnum.ORGANISATIONS;

  @Input()
  public isSaveTableState = false;

  @Input()
  public allowClickOnNameRow: boolean = true;

  @Input()
  public columns: string[] = ["recordState", "name", "address.countryName", "tags"];

  public isLoading = signal(true);

  public rowData: IConnectionExtended[] = [];

  public columnDefs = signal<ColDef[]>([]);

  private countryOptions: ISelectOption[];

  private subscriptions = new Subscription();

  constructor(
    private routerService: RouterService,
    private connectionsService: ConnectionsService,
    private notificationService: NotificationService,
    private commonService: CommonService,
    private overlay: SlideOverlayPageService,
    private authenticationService: AuthenticationService,
    private featureFlagService: FeatureFlagService,
  ) {
    this.commonService.countriesOptionsObservable$
      .pipe(takeUntilDestroyed())
      .subscribe((countriesOptions: ISelectOption[]) => {
        this.countryOptions = countriesOptions;
      });
  }

  public async ngAfterViewInit() {
    if (this.subscribeToOverlayRefreshTable) {
      this.subscriptions.add(this.overlay.refreshTable$.subscribe(() => this.getAll()));
    }

    this.setColumnDefs();

    await this.getAll();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onViewDetails = (row: IConnectionExtended): void => {
    if (!this.areButtonsEnabled) {
      return;
    }

    this.routerService.navigate(this.routerService.getOrganisationLink(row.id));
  };

  private setColumnDefs = (): void => {
    const isCrossOrgSharingEnabled = this.featureFlagService.isEnabled(
      FeatureFlagEnum.CROSS_ORGANISATION_SHARING,
    );
    const columnDefs: ColDef[] = [
      ColumnUtils.recordState(),
      {
        headerName: "Name",
        field: "name",
        lockVisible: true,
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          icon: "link",
          iconTooltip: "Connected",
          showIcon: (data: IConnectionExtended) => isCrossOrgSharingEnabled && !!data.publicUri,
          linkRouteIdParam: "id",
          linkRouteFn: this.routerService.getOrganisationLink,
        },
      },
      {
        headerName: "Country",
        field: "address.countryName",
        cellRenderer: CellRendererUtils.country,
      },
      {
        headerName: "Address",
        field: "address.street",
      },
      {
        headerName: "ZIP / Post code",
        field: "address.zipCode",
      },
      {
        headerName: "Region/State",
        field: "address.region",
      },
      {
        headerName: "Certificates",
        field: "certificates",
        valueGetter: (cell: { data: IConnectionExtended }) => {
          return cell.data.certificates
            .map((certificate) => {
              const values: string[] = [
                certificate.standard.name,
                certificate.standardType?.fullName,
                certificate.number,
              ];

              return values.filter((value) => !!value).join(" - ");
            })
            .join(", ");
        },
      },
      {
        headerName: "EORI",
        field: "eori",
        valueGetter: this.getCustomFieldValue("EORI"),
      },
      {
        headerName: "Email",
        field: "email",
        valueGetter: this.getCustomFieldValue("Email"),
      },
    ];

    if (this.authenticationService.isRegularUser()) {
      columnDefs.push(ColumnUtils.tags("Tags"));
    }
    this.columnDefs.set(CommonUtils.getVisibleColumnDefs(columnDefs, this.columns));
  };

  private getCustomFieldValue =
    (field: string) =>
    (cell: { data: IConnectionExtended }): string => {
      return (cell.data.customFields || []).find((cf) => cf.definition.label === field)?.value;
    };

  private getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    if (this.connections) {
      this.rowData = CommonUtils.getElementsWithCountryName(this.countryOptions, this.connections);
      this.isLoading.set(false);
    } else {
      try {
        const organisations = await this.connectionsService.getAllGraphQL(
          undefined,
          undefined,
          this.columnDefs().some((c) => c.field === "tags") ? ["TAGS"] : undefined,
        );

        this.rowData = CommonUtils.getElementsWithCountryName(this.countryOptions, organisations);
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };
}
