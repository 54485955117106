import { EudrProducer } from "@components/reports/eudr/eudr-producer";

import { ILocationExtended } from "../../../shared/interfaces/location.interface";
import { IOrganisation } from "../../../shared/interfaces/organisation.interface";

export class EudrProducerGroup {
  public organisation: IOrganisation;

  public locations: ILocationExtended[];

  constructor(organisation: IOrganisation, locations: ILocationExtended[]) {
    this.organisation = organisation;
    this.locations = locations;
  }

  public static buildProducers(
    locationGroups: Record<
      string,
      {
        organisation: IOrganisation;
        organisationName: string;
        countryName: string;
        locations: ILocationExtended[];
      }
    >,
  ) {
    return Object.keys(locationGroups).map((key) => {
      const locationGroup = locationGroups[key];

      return new EudrProducer(locationGroup.organisation, locationGroup.locations);
    });
  }
}
