import { ChangeDetectionStrategy, Component, DestroyRef, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { BehaviorSubject } from "rxjs";

import { NavigationParams } from "@shared/services/router.service";
import { CellRendererUtils } from "@shared/utils";

import { IRecordResponse } from "../interfaces";

@Component({
  template: `
    <div class="container-flex">
      @if (
        params?.value && !(entityId | conditionsMetCheckerPipe: (rulesetRecordsSubject | async))
      ) {
        <mat-icon class="cell-icon warn-icon" [tp]="iconTooltip" [tpIsLazy]="true">
          warning
        </mat-icon>
      }
      <a
        [routerLink]="link.commands"
        [relativeTo]="link.extras.relativeTo"
        [queryParams]="link.extras.queryParams"
        [queryParamsHandling]="link.extras.queryParamsHandling"
        (click)="onClick($event)"
      >
        {{ params.value }}
      </a>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RulesetIconNameCellRendererComponent implements ICellRendererAngularComp {
  public params!: ICellRendererParams;

  public link: NavigationParams;

  public rulesetRecordsSubject: BehaviorSubject<IRecordResponse[]>;

  public entityId: string;

  public iconTooltip: string;

  private destroyRef = inject(DestroyRef);

  public agInit(params: ICellRendererParams): void {
    this.params = params;
    const data = this.getData();

    this.link = CellRendererUtils.getLink(params, data);
    this.entityId = data.id;
    this.iconTooltip = params["iconTooltip"];
    this.rulesetRecordsSubject = params["rulesetRecordsSubject"];
    this.rulesetRecordsSubject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (
        this.params?.api["autoSizeGridSubject"] &&
        typeof this.params.api["autoSizeGridSubject"].next === "function"
      ) {
        this.params.api["autoSizeGridSubject"].next(true);
      }
    });
  }

  public refresh(): boolean {
    return false;
  }

  public onClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  private getData = (): any => this.params.data ?? this.params.node.allLeafChildren[0].data;
}
