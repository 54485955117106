import { FormGroup, UntypedFormControl } from "@angular/forms";

import { sortBy } from "lodash";

import { CommonUtils } from "./common.utils";
import { RecordStateEnum } from "../enums";
import { ICustomField, IElementCustomField } from "../interfaces";

const getVisible = (
  allCustomFields: ICustomField[],
  elementCustomFields: IElementCustomField[],
): ICustomField[] =>
  sortBy(
    allCustomFields?.filter(
      (cf) =>
        cf.recordState !== RecordStateEnum.ARCHIVED ||
        elementCustomFields?.some((e) => CommonUtils.getUriId(e.uri) === cf.id),
    ) ?? [],
    "label",
  );

const addToFormGroup = (
  formGroup: FormGroup,
  visibleCustomFields: ICustomField[],
  elementCustomFields: IElementCustomField[],
): void => {
  if (!visibleCustomFields?.length) {
    return;
  }
  for (const visibleCustomField of visibleCustomFields) {
    const initialValue = elementCustomFields?.find(
      (e) => CommonUtils.getUriId(e.uri) === visibleCustomField.id,
    )?.value;

    formGroup.addControl(`cf_${visibleCustomField.id}`, new UntypedFormControl(initialValue));
  }
};

const addToPayload = (
  payload: any,
  activeOrganisationId: string,
  formGroup: FormGroup,
  visibleCustomFields: ICustomField[],
): void => {
  if (!visibleCustomFields?.length) {
    return;
  }
  payload.customFields = [];
  for (const visibleCustomField of visibleCustomFields) {
    const value = formGroup.controls[`cf_${visibleCustomField.id}`].value;

    if (value) {
      payload.customFields.push({
        uri: `/organisations/${activeOrganisationId}/custom-field-definitions/${visibleCustomField.id}`,
        value,
      });
    }
  }
};

export const CustomFieldsUtils = {
  getVisible,
  addToFormGroup,
  addToPayload,
};
