<div class="auth-container sign-up">
  <mat-icon class="logo-icon" svgIcon="logo-night"></mat-icon>
  <div class="outer-container">
    <div class="container text-center" [class.verify]="currentStep === 'verify'">
      @switch (currentStep) {
        @case ("initial") {
          <div class="title">
            @if (hasInvitation) {
              <h2>Sign up to accept invitation from {{ invitation.organisationName }}</h2>
            } @else {
              <h1>Sign up</h1>
            }
          </div>

          <div class="subtitle">To create your account, first provide your personal details.</div>

          @if (isLoading()) {
            <app-loader></app-loader>
          } @else {
            <div class="action-button-container">
              <form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
                <iov-input
                  label="Name"
                  class="no-padding"
                  formControlName="firstName"
                  autofocus
                ></iov-input>

                <iov-input
                  label="Last name"
                  class="no-padding"
                  formControlName="lastName"
                ></iov-input>

                <iov-input
                  type="email"
                  class="no-padding"
                  label="Email"
                  formControlName="email"
                  autocomplete="on"
                ></iov-input>

                <iov-input-password
                  label="Password"
                  class="no-padding"
                  formControlName="password"
                  showRequirementsTooltip
                  autocomplete="new-password"
                ></iov-input-password>

                @if (!hasInvitation) {
                  <h3>Organisation</h3>

                  <iov-input-select
                    [options]="countryOptions"
                    class="no-padding"
                    formControlName="country"
                    label="Country"
                  ></iov-input-select>

                  <iov-input
                    label="Name"
                    class="no-padding"
                    formControlName="organisationName"
                  ></iov-input>
                }

                <iov-button
                  [disabled]="isSubmitButtonDisabled"
                  size="large"
                  class="fresh-green mt-20"
                  accessibilityRole="submit"
                  cdkFocusInitial
                >
                  Sign up{{ hasInvitation ? " & accept invitation" : "" }}
                </iov-button>
              </form>
            </div>

            <div class="switch-mode-container">
              <span>Already have an account?</span>
              <a [routerLink]="'/' + routingEnum.LOGIN">Login</a>
            </div>
          }
        }
        @case ("verify") {
          <div class="title">
            <h1>Verify your email</h1>
          </div>

          @if (isLoading()) {
            <app-loader></app-loader>
          } @else {
            <div class="subtitle">
              <p>
                We sent a verification link to your email:
                <br />
                <b class="email-address">{{ formGroup.controls["email"].value }}</b>
              </p>
              <p class="mt-24">
                If you haven’t received the email, please check your spam folder or give it a while
                (up to 10 minutes). Alternatively, you can
                <a class="clickable underline" (click)="onResendEmail()">
                  request a new verification email
                </a>
                (will make the previous one invalid).
                <br />
                <br />
                Entered the wrong email?
                <span>Back to</span>
                <a class="clickable underline" (click)="onChangeStep('initial')">Sign up</a>
              </p>

              <div class="switch-mode-container">
                <span>Back to</span>
                <a [routerLink]="'/' + routingEnum.LOGIN">Login</a>
              </div>
            </div>
          }
        }
      }
    </div>
  </div>
</div>
