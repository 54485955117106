import { Inject, Injectable } from "@angular/core";

import { lastValueFrom } from "rxjs";

import { APP_CONFIG } from "@shared/tokens";

import { ApiService } from "./api.service";
import { IConfig, IRecordState, IStandardType } from "../../interfaces";

@Injectable({
  providedIn: "root",
})
export class StandardTypesService {
  private readonly baseUrl: string = `${this.environment.baseUrl}common/standards`;

  constructor(
    private apiService: ApiService,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {}

  public getPage = async (standardId: string): Promise<IStandardType[]> =>
    await this.apiService.get<IStandardType[]>(`${this.baseUrl}/${standardId}/types`).toPromise();

  public get = async (standardId: string, standardTypeId: string): Promise<IStandardType> =>
    await this.apiService
      .get<IStandardType>(`${this.baseUrl}/${standardId}/types/${standardTypeId}`)
      .toPromise();

  public createOrUpdate = async (
    standardId: string,
    payload: IStandardType,
    standardTypeId?: string,
  ): Promise<IStandardType> => {
    if (standardTypeId) {
      return await lastValueFrom(
        this.apiService.put<IStandardType>(
          `${this.baseUrl}/${standardId}/types/${standardTypeId}`,
          payload,
        ),
      );
    } else {
      return await lastValueFrom(
        this.apiService.post<IStandardType>(`${this.baseUrl}/${standardId}/types`, payload),
      );
    }
  };

  public setRecordState = async (
    payload: IRecordState,
    standardId: string,
    standardTypeId: string,
  ): Promise<IStandardType> =>
    await lastValueFrom(
      this.apiService.put<IStandardType>(
        `${this.baseUrl}/${standardId}/types/${standardTypeId}`,
        payload,
      ),
    );
}
