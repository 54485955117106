<div class="commodity row-container" [formGroup]="formGroup">
  <div class="title">
    <div class="index">
      <div class="circle">{{ index + 1 }}</div>
    </div>
    <div class="text">
      <div>{{ formGroup.value["hsCodeTitle"] }}</div>
    </div>
  </div>

  <div class="row commodities-description-row">
    @if (displayFields) {
      <iov-input
        formControlName="description"
        label="Commodity(-ies) or Product(s) Description"
      ></iov-input>

      <iov-input
        tooltipIcon="alert-triangle"
        iconClass="warn-icon"
        [tooltip]="
          formGroup.value['displayUnitWarning']
            ? 'Cannot display the sum, as not all items in this product group are measured in either Volume or Mass.'
            : null
        "
        formControlName="netMass"
        type="number"
        label="Net mass (kg)"
      ></iov-input>
      <iov-input
        tooltipIcon="alert-triangle"
        iconClass="warn-icon"
        [tooltip]="
          formGroup.value['displayUnitWarning']
            ? 'Cannot display the sum, as not all items in this product group are measured in either Volume or Mass.'
            : null
        "
        formControlName="volume"
        type="number"
        label="Volume (m3)"
      ></iov-input>
      <iov-input
        type="number"
        formControlName="supplementaryUnits"
        label="Supplementary units"
      ></iov-input>
    } @else {
      No information on points of origin could be found.
    }
  </div>
  @if (materialsInfoFormGroups.length) {
    <div class="materials-info" formArrayName="materialsInfo">
      <div *ngFor="let materialInfo of materialsInfoFormGroups; let j = index" [formGroupName]="j">
        <app-reports-eudr-material-info [formGroup]="materialInfo" [index]="j" />
      </div>
    </div>
  }
  <div class="producers-info" formArrayName="producersInfo">
    <div *ngFor="let producerInfo of producersInfoFormGroups; let j = index" [formGroupName]="j">
      <app-reports-eudr-producer-info [formGroup]="producerInfo" [index]="j" />
    </div>
  </div>
</div>
