import {
  customUnitOfMeasurementFields,
  getLinkFields,
  locationFields,
  materialsFields,
} from "./queries.constants";
import { RecordStateEnum } from "../enums";
import { IItemGraphQLFilter, ILocationGraphQLFilter } from "../interfaces";

const getParsedIds = (ids: string[]): string => `[${ids.map((id) => `"${id}"`).join(",")}]`;

const generateFilterClause = (filter: ILocationGraphQLFilter | IItemGraphQLFilter) => {
  if (!filter) {
    return "";
  }

  const clauses = [];

  for (const key in filter) {
    switch (key) {
      case "recordState":
        if (
          filter["recordState"] !== undefined &&
          filter["recordState"] !== null &&
          filter["recordState"] !== RecordStateEnum.ALL
        ) {
          clauses.push(`recordState: ${filter["recordState"]}`);
        }
        break;
      case "hasContent":
        if (filter["hasContent"]) {
          clauses.push(`hasContent: true`);
        }
        break;
      default:
        if (filter[key] !== undefined && filter[key] !== null) {
          clauses.push(`${key}: "${filter[key]}"`);
        }
        break;
    }
  }

  return clauses.length > 0 ? `filter: { ${clauses.join(",")} }` : "";
};

const generateClause = (key: string, value: string | number | null): string => {
  return value !== null && value !== undefined
    ? `${key}: ${typeof value === "string" ? `"${value}"` : value}`
    : "";
};

const certificatesFields = `
    id
    number
    issuanceDate
    validFromDate
    validToDate
    standardType {
        id
        fullName
        recordState
    }
    standard {
        id
        name
        recordState
    }
    recordState
`;

const getQueryIncludes = (include: string[] = []): string => `
    ${
      include.includes("DOCUMENTS")
        ? `documents {
                id
                name
                issuance
                validityStart
                validityEnd
                contentType
                contentHash
                createdTime
                originalHash
                type {
                    id
                    name
                }
                recordState             
            }`
        : ""
    }
    ${
      include.includes("CERTIFICATES")
        ? `certificates {
              ${certificatesFields}
          }`
        : ""
    }
    ${
      include.includes("CERTIFICATES_WITH_DOCUMENTS")
        ? `certificates {
                ${certificatesFields}
                documents {
                  id
                  name
                }
            }`
        : ""
    }
    ${
      include.includes("TAGS")
        ? `tags {
                title
                color
            }`
        : ""
    }
    ${
      include.includes("ALLOWED_MATERIALS")
        ? `allowedMaterials {
             ${materialsFields}
            }`
        : ""
    }
    ${include.includes("MATERIALS") ? `materials { ${materialsFields} }` : ""}
    ${
      include.includes("DEFAULT_CUSTOM_UNIT")
        ? `defaultCustomUnit {
                id
                name
                symbol
                displayScale
                conversionFactor
            }`
        : ""
    }
    ${
      include.includes("AGENTS")
        ? `agents {
            id
            name
        }`
        : ""
    }
    ${
      include.includes("FULL_LOCATION_LINKS")
        ? `locationLinks {
        id
        from { ${locationFields} }
        to { ${locationFields} }
    }`
        : ""
    }
    ${include.includes("LOCATION_LINKS") ? getLinkFields(true) : ""}
    ${include.includes("INBOUND_LOCATION_LINKS") ? getLinkFields(false) : ""}
    ${include.includes("GEOLOCATION") ? `geoLocation` : ""}
    ${
      include.includes("CUSTOM_FIELDS")
        ? `customFields {
            definition {
                type
                label
            }
            value
        }`
        : ""
    }
    ${
      include.includes("ATTACHED_TO")
        ? `attachedTo {
            id
            type
        }`
        : ""
    }
    ${
      include.includes("DELIVERIES")
        ? `deliveries {
            id
            deliveryId
        }`
        : ""
    }

        ${
          include.includes("LOCATIONS")
            ? `locations {
            location {
            ${locationFields} 
            }
        }`
            : ""
        }

    ${include.includes("CUSTOM_UNITS") ? `customUnits { ${customUnitOfMeasurementFields}  }` : ""}
      ${
        include.includes("FULL_ADDRESS")
          ? `address {
                    street
                    country
                    region
                    zipCode
                }`
          : ""
      }
    `;

export const QueriesUtils = {
  getParsedIds,
  generateFilterClause,
  generateClause,
  getQueryIncludes,
};
