import {
  ChangeDetectionStrategy,
  Component,
  effect,
  EventEmitter,
  input,
  Output,
  signal,
  untracked,
} from "@angular/core";

import { slideOverAnimation } from "@shared/animations";

@Component({
  selector: "iov-slide-overlay-wrapper",
  templateUrl: "./slide-overlay-wrapper.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideOverAnimation],
})
export class SlideOverlayWrapperComponent {
  closeOverlay = signal<boolean>(true);

  closeContent = input<boolean>(true, { alias: "close" });

  class = input<string>("");

  @Output() clickClose: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
    effect(
      (onCleanup) => {
        const state = this.closeContent();
        let timer: number;

        untracked(() => {
          if (state) {
            timer = setTimeout(() => {
              this.closeOverlay.set(state);
            }, 300);
          } else {
            this.closeOverlay.set(state);
          }
        });
        onCleanup(() => {
          clearTimeout(timer);
        });
      },
      {
        allowSignalWrites: true,
      },
    );
  }

  protected async dismiss(): Promise<void> {
    this.closeOverlay.set(true);
  }

  public onClickClose(): void {
    this.clickClose.emit();
  }

  public onClickOutside(): void {
    this.onClickClose();
  }
}
