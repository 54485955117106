<div class="container-space-between">
  <app-page-title title="Users"></app-page-title>
</div>

@if (!isLoadingOrganisations()) {
  <app-admin-users-table
    [allOrganisations]="allOrganisations"
    [isSaveTableState]="true"
    [isFixedBottomPaginator]="true"
  ></app-admin-users-table>
} @else {
  <app-loader></app-loader>
}
