<div class="container-space-between">
  <app-page-title
    title="Units of measurement"
    backText="Back to Admin"
    [goBackRoute]="routingEnum.ADMIN"
  ></app-page-title>

  @if (authenticationService.canAddModifyEntities()) {
    <iov-button-group>
      <iov-button (pressed)="onAdd()">
        <mat-icon icon-left>add</mat-icon>
        Add new
      </iov-button>
    </iov-button-group>
  }
</div>
<mat-tab-group
  animationDuration="0ms"
  [mat-stretch-tabs]="false"
  mat-align-tabs="start"
  [selectedIndex]="selectedTabIndex"
  (selectedIndexChange)="selectedTabIndex = $event"
>
  <mat-tab>
    <ng-template mat-tab-label>
      {{ unitSourceTypes.SYSTEM | enumToText }} ({{ systemUnitsTable?.rowData?.length }})
    </ng-template>
    <app-units-of-measurement-table
      #systemUnitsTable
      [areButtonsEnabled]="false"
      [isRecordStateFilterEnabled]="false"
      [isSaveTableState]="true"
      [isFixedBottomPaginator]="true"
    ></app-units-of-measurement-table>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      {{ unitSourceTypes.CUSTOM | enumToText }} ({{ customUnitsTable?.rowData?.length }})
    </ng-template>
    <app-units-of-measurement-table
      [unitSourceType]="unitSourceTypes.CUSTOM"
      [columns]="['name', 'symbol', 'type', 'systemUnit.name', 'conversionFactorWithSymbol']"
      #customUnitsTable
      [isSaveTableState]="true"
      [isFixedBottomPaginator]="true"
      [isRecordStateFilterEnabled]="false"
    ></app-units-of-measurement-table>
  </mat-tab>
</mat-tab-group>
