import { AfterViewInit, ChangeDetectionStrategy, Component, Input, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef } from "ag-grid-community";
import { EditProcessTypeDialogComponent } from "src/app/components/settings";
import { CommonConstants } from "src/app/shared/constants";
import { ConfirmDialogResponseEnum, RecordStateEnum, TableEnum } from "src/app/shared/enums";
import { IProcessType } from "src/app/shared/interfaces";
import { ProcessTypesService } from "src/app/shared/services";
import { ColumnUtils } from "src/app/shared/utils";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { ConfirmDialogComponent, InfoDialogComponent } from "../..";

@Component({
  selector: "app-process-types-table",
  templateUrl: "./process-types-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcessTypesTableComponent implements AfterViewInit {
  @Input()
  public processTypes: IProcessType[] = null;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isRecordStateFilterEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  @Input()
  public recordState: RecordStateEnum = RecordStateEnum.ALL;

  @Input() public table = TableEnum.PROCESS_TYPES;

  @Input() public isSaveTableState = false;

  public isLoading = signal(true);

  public rowData: IProcessType[] = [];

  public columnDefs = signal<ColDef[]>([]);

  constructor(
    private processTypesService: ProcessTypesService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) {}

  public async ngAfterViewInit() {
    this.setColumnDefs();

    await this.getAll();
  }

  private setColumnDefs = (): void => {
    const columnDefs: ColDef[] = [
      ColumnUtils.recordState(),
      { headerName: "Name", field: "name", lockVisible: true },
    ];

    if (this.areButtonsEnabled) {
      const buttons = [
        {
          onClick: this.onToggleArchiveStatus,
          tooltip: (row) => (row.recordState === RecordStateEnum.ACTIVE ? "Archive" : "Unarchive"),
          icon: (row) => (row.recordState === RecordStateEnum.ACTIVE ? "archive" : "inventory"),
        },
        {
          onClick: this.onEdit,
          tooltip: "Edit",
          icon: "edit",
        },
      ];

      columnDefs.push(ColumnUtils.buttons(buttons));
    }
    this.columnDefs.set(columnDefs);
  };

  public onEdit = async (element: IProcessType): Promise<void> => {
    if (!this.areButtonsEnabled) {
      return;
    }
    if (element.recordState === RecordStateEnum.ARCHIVED) {
      this.dialog.open(InfoDialogComponent, {
        data: {
          icon: "error",
          iconColor: "red",
          contentTranslatedText: CommonConstants.EDIT_ARCHIVED_RECORD_ERROR_TEXT,
        },
      });

      return;
    }
    const dialogRef = this.dialog.open(EditProcessTypeDialogComponent, {
      data: {
        processType: element,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: { hasSaved: boolean }) => {
      if (result?.hasSaved) {
        await this.getAll();
      }
    });
  };

  public onToggleArchiveStatus = async (element: IProcessType): Promise<void> => {
    const isArchived = element.recordState === RecordStateEnum.ARCHIVED;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: `${isArchived ? "Unarchive" : "Archive"} confirmation`,
        contentTranslatedText: `Are you sure that you want to ${isArchived ? "unarchive" : "archive"} this record?`,
        confirmButtonTranslatedText: isArchived ? "Unarchive" : "Archive",
        confirmButtonIcon: isArchived ? "inventory" : "archive",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        try {
          this.isLoading.set(true);
          const wasArchived = element.recordState === RecordStateEnum.ARCHIVED;

          await this.processTypesService.setRecordState(
            {
              recordState: wasArchived ? RecordStateEnum.ACTIVE : RecordStateEnum.ARCHIVED,
            },
            element.id,
          );
          this.notificationService.showSuccess(wasArchived ? "Unarchived" : "Archived");
          await this.getAll();
        } catch (error) {
          this.notificationService.showError(error);
          this.isLoading.set(false);
        }
      }
    });
  };

  public getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    if (this.processTypes) {
      this.rowData = this.processTypes;
      this.isLoading.set(false);
    } else {
      try {
        this.rowData = await this.processTypesService.getAll();
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };
}
