import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  signal,
} from "@angular/core";
import { FormGroup } from "@angular/forms";

import { UnitConversionPipe } from "@design-makeover/pipes/unit-conversion.pipe";
import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { BulkAddItemsModel } from "@components/items/bulk-add-items/bulk-add-items.component.model";
import { BulkAddItemsService } from "@components/items/bulk-add-items/bulk-add-items.service";
import { BulkAddItemsCreateItemsModel as Model } from "@components/items/bulk-add-items/create-items/bulk-add-items-create-items.model";
import { DateTypeEnum, StartEndEnum } from "@shared/enums";
import {
  IBaseUnit,
  ICustomUnitOfMeasurement,
  IItemPayloadWithTags,
  IProduct,
  ISelectOption,
} from "@shared/interfaces";
import { AuthenticationService } from "@shared/services";
import { CommonUtils, FormUtils } from "@shared/utils";

@Component({
  selector: "app-bulk-add-items-create-items",
  templateUrl: "./bulk-add-items-create-items.component.html",
  styleUrls: ["./bulk-add-items-create-items.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class BulkAddItemsCreateItemsComponent implements AfterViewInit {
  public bulkAddItemsService: BulkAddItemsService = inject(BulkAddItemsService);

  private authenticationService: AuthenticationService = inject(AuthenticationService);

  private unitConversionPipe: UnitConversionPipe = inject(UnitConversionPipe);

  private notificationService: NotificationService = inject(NotificationService);

  @Input() public formGroup: FormGroup<BulkAddItemsModel.SetValuesFormGroup>;

  @Output() public creationProcessComplete: EventEmitter<void> = new EventEmitter();

  public payloadsWithStatus: Model.IPayloadWithStatus[] = [];

  public hasProcessCompleted = signal<boolean>(false);

  private activeOrganisationId: string;

  public readonly statusEnum = Model.StatusEnum;

  private readonly fieldEnum = BulkAddItemsModel.FieldEnum;

  public async ngAfterViewInit(): Promise<void> {
    this.activeOrganisationId = this.authenticationService.getActiveOrganisationId();

    this.payloadsWithStatus = this.formGroup.controls[this.fieldEnum.ITEMS].controls.map((item) =>
      this.getPayloadWithStatusForItem(item),
    );

    const items = await this.bulkAddItemsService.createItems(this.payloadsWithStatus);

    const successfulItemsCreatedCount = this.payloadsWithStatus.filter(
      (payload) => payload.status === this.statusEnum.SUCCESS,
    ).length;

    if (successfulItemsCreatedCount) {
      this.notificationService.showSuccess(
        `${successfulItemsCreatedCount} item(s) created successfully.`,
      );

      if (this.bulkAddItemsService.delivery()) {
        try {
          await this.bulkAddItemsService.addItemsToDelivery(items);
        } catch (error) {
          this.notificationService.showError("Failed to add items to the delivery.");
        }
      }
    } else {
      this.notificationService.showError("No items were created");
    }

    this.hasProcessCompleted.set(true);

    this.creationProcessComplete.emit();
  }

  public get processedItemsCount(): number {
    const processedStatus = [this.statusEnum.ERROR, this.statusEnum.SUCCESS];

    return this.payloadsWithStatus.filter((payloadWithStatus) =>
      processedStatus.includes(payloadWithStatus.status),
    ).length;
  }

  private get allProducts(): IProduct[] {
    return this.bulkAddItemsService.allProducts;
  }

  public get completionPercentage(): number {
    return (this.processedItemsCount * 100) / this.payloadsWithStatus.length;
  }

  private get baseUnitOfMeasurements(): IBaseUnit[] {
    return this.bulkAddItemsService.baseUnitOfMeasurements;
  }

  private get customUnitOfMeasurements(): ICustomUnitOfMeasurement[] {
    return this.bulkAddItemsService.customUnitOfMeasurements;
  }

  private getPayloadWithStatusForItem(
    formGroup: FormGroup<BulkAddItemsModel.ItemFormGroup>,
  ): Model.IPayloadWithStatus {
    const values = formGroup.getRawValue();
    const { fieldEnum } = this;
    const uriPrefix = `/organisations/${this.activeOrganisationId}`;

    let initialQuantity = values[fieldEnum.INITIAL_QUANTITY];
    let deliveredQuantity = values[fieldEnum.DELIVERY_QUANTITY];

    const productId = values[fieldEnum.PRODUCT].value;
    const product = this.allProducts.find((product) => product.id === productId);

    const baseUnitId = CommonUtils.getUriId(product.baseUnit);
    const baseUnit = this.baseUnitOfMeasurements.find((unit) => unit.id === baseUnitId);

    const unitOfMeasurementOption = values[fieldEnum.UNIT_OF_MEASUREMENT];

    if (baseUnit.id !== unitOfMeasurementOption.value) {
      const selectedUnitOfMeasurement = [
        ...this.baseUnitOfMeasurements,
        ...this.customUnitOfMeasurements,
      ].find((unit) => {
        return unit.id === unitOfMeasurementOption.value;
      });

      initialQuantity = +this.unitConversionPipe.transform(
        `${initialQuantity}`,
        selectedUnitOfMeasurement,
        baseUnit,
        true,
      );

      deliveredQuantity = +this.unitConversionPipe.transform(
        `${deliveredQuantity}`,
        selectedUnitOfMeasurement,
        baseUnit,
        true,
      );
    }

    const payload: IItemPayloadWithTags = {
      id: undefined,
      itemId: values[fieldEnum.ITEM_ID].trim(),
      product: `${uriPrefix}/products/${values[fieldEnum.PRODUCT].value}`,
      materials: values[fieldEnum.MATERIALS].map(
        (materialOption: ISelectOption) => `${uriPrefix}/materials/${materialOption.value}`,
      ),
      currentLocation: `${uriPrefix}/locations/${values[fieldEnum.CURRENT_LOCATION].value}`,
      createdAtLocation: `${uriPrefix}/locations/${values[fieldEnum.CREATED_AT_LOCATION].value}`,
      tags: values[fieldEnum.TAGS],
      initialQuantity,
      deliveredQuantity,
    };

    switch (values[fieldEnum.DATE_TYPE]) {
      case DateTypeEnum.EXACT:
        payload.created = {
          type: DateTypeEnum.EXACT,
          on: FormUtils.getDateValueForPayload(values[fieldEnum.CREATED_FROM]),
        };
        break;
      case DateTypeEnum.RANGE:
        payload.created = {
          type: DateTypeEnum.RANGE,
          start: FormUtils.getDateValueForPayload(values[fieldEnum.CREATED_RANGE][0]),
          end: FormUtils.getDateValueForPayload(
            values[fieldEnum.CREATED_RANGE][1],
            StartEndEnum.END,
          ),
        };
        break;
    }

    return { itemPayload: payload, status: this.statusEnum.PENDING };
  }
}
