import { Inject, Injectable, inject } from "@angular/core";

import { Observable, firstValueFrom } from "rxjs";

import { APP_CONFIG } from "@shared/tokens";

import { ApiService } from "./api.service";
import { GraphService } from "./graph.service";
import { CommonConstants } from "../../constants";
import { RecordStateEnum } from "../../enums";
import {
  IPageableContent,
  IProduct,
  IProductPayload,
  IRecordState,
  IConfig,
  IProductExtended,
  IProductGraphQLFilter,
  IProductGraphQLResponse,
  IProductByIdsGraphQLResponse,
} from "../../interfaces";
import { IProductSupplyChainResponse } from "../../interfaces/product-supply-chain.interface";
import {
  getProductsByIdsGraphQLQuery,
  getProductsGraphQLQuery,
  getProductSupplyChainQuery,
} from "../../queries";
import { FormUtils } from "../../utils";
import { AuthenticationService } from "../authentication.service";

@Injectable({
  providedIn: "root",
})
export class ProductsService {
  private graphService = inject(GraphService);

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {}

  private getBaseUrl = (): string =>
    `${this.environment.baseUrl}organisations/${this.authenticationService.getActiveOrganisationId()}/products`;

  public getPage = async (
    name: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
    recordState: RecordStateEnum = undefined,
  ): Promise<IPageableContent<IProduct>> => {
    const url = `${this.getBaseUrl()}?${FormUtils.addUrlParams({ name, size, page, sort, recordState })}`;

    return await this.apiService.get<IPageableContent<IProduct>>(url).toPromise();
  };

  public getPageSubscription = (
    name: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
    recordState: RecordStateEnum = undefined,
  ): Observable<IPageableContent<IProduct>> => {
    const url = `${this.getBaseUrl()}?${FormUtils.addUrlParams({ name, size, page, sort, recordState })}`;

    return this.apiService.get<IPageableContent<IProduct>>(url);
  };

  public async getAll(recordState: RecordStateEnum = undefined): Promise<IProduct[]> {
    const result: IProduct[] = [];
    let page = 0;
    let hasNextPage: boolean = true;

    while (hasNextPage) {
      const {
        content,
        page: { totalElements },
      } = await this.getPage(
        undefined,
        CommonConstants.MAX_API_GET_ITEMS_SIZE,
        page,
        undefined,
        recordState,
      );

      result.push(...content);

      page++;
      hasNextPage = result.length < totalElements;
    }

    return result;
  }

  public async getAllGraphQL(
    filter: IProductGraphQLFilter = null,
    first: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    include: string[] = [],
  ): Promise<IProductExtended[]> {
    const result: IProductExtended[] = [];
    let cursor: string | undefined = undefined;
    let hasNextPage: boolean = true;

    const activeOrganisationId = this.authenticationService.getActiveOrganisationId();

    while (hasNextPage) {
      const query = getProductsGraphQLQuery(
        activeOrganisationId,
        filter,
        first,
        null,
        cursor,
        null,
        include,
      );
      const { content, pageInfo, totalCount } = await this.graphService.fetchSinglePage<
        IProductGraphQLResponse,
        IProductExtended
      >("loadProducts", query, this.graphQLPropertiesToAddFunction);

      result.push(...content);

      cursor = pageInfo.endCursor;
      hasNextPage = result.length < totalCount;
    }

    return result;
  }

  public async getByIdsGraphQL(
    ids: string[],
    first: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    include: string[] = [],
  ): Promise<IProductExtended[]> {
    if (!ids.length) {
      return [];
    }
    const result: IProductExtended[] = [];
    let cursor: string | undefined = undefined;
    let hasNextPage: boolean = true;
    const activeOrganisationId = this.authenticationService.getActiveOrganisationId();

    while (hasNextPage) {
      const query = getProductsByIdsGraphQLQuery(
        activeOrganisationId,
        ids,
        first,
        null,
        cursor,
        null,
        include,
      );
      const { content, pageInfo, totalCount } = await this.graphService.fetchSinglePage<
        IProductByIdsGraphQLResponse,
        IProductExtended
      >("loadProductsByIds", query, this.graphQLPropertiesToAddFunction);

      result.push(...content);

      cursor = pageInfo.endCursor;
      hasNextPage = result.length < totalCount;
    }

    return result;
  }

  public get = async (id: string): Promise<IProduct> =>
    await this.apiService.get<IProduct>(`${this.getBaseUrl()}/${id}`).toPromise();

  public delete = async (id: string): Promise<void> =>
    await this.apiService.delete<void>(`${this.getBaseUrl()}/${id}`).toPromise();

  public createOrUpdate = async (payload: IProductPayload, id?: string): Promise<IProduct> => {
    if (id) {
      return await this.apiService.put<IProduct>(`${this.getBaseUrl()}/${id}`, payload).toPromise();
    } else {
      return await this.apiService.post<IProduct>(`${this.getBaseUrl()}`, payload).toPromise();
    }
  };

  public setRecordState = async (payload: IRecordState, id: string): Promise<IProduct> =>
    await this.apiService.put<IProduct>(`${this.getBaseUrl()}/${id}`, payload).toPromise();

  public getProductSupplyChain = async (
    orgId: string,
    productId: string,
    includes: string[] = [],
  ): Promise<IProductSupplyChainResponse> => {
    const query = getProductSupplyChainQuery(orgId, productId, includes);

    return await firstValueFrom(this.graphService.query({ query }));
  };

  private graphQLPropertiesToAddFunction = (edge: any): any => {
    return {
      certificates: edge.node.certificates,
      documents: edge.node.documents,
    };
  };
}
