<h2 mat-dialog-title>Add certificate</h2>

@if (isLoading()) {
  <app-loader></app-loader>
} @else {
  <form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
    <mat-dialog-content>
      <h4>{{ mainInformationText }}</h4>
      <div class="row-container">
        <div class="item">
          <iov-input formControlName="number" label="Number"></iov-input>
        </div>
        <div class="item">
          <iov-input-select
            [options]="standardOptions()"
            formControlName="standard"
            label="Standard"
          ></iov-input-select>
        </div>
        <div class="item">
          <iov-input-select
            [formGroup]="formGroup"
            [options]="standardTypeOptions()"
            [disabled]="!standardTypeOptions()?.length"
            formControlName="standardType"
            label="Standard type"
          ></iov-input-select>
        </div>
        <div class="item">
          <iov-datepicker
            [maxDate]="
              formGroup.controls['validFromDate'].value ?? formGroup.controls['validToDate'].value
            "
            formControlName="issuanceDate"
            label="Issuance date"
          ></iov-datepicker>
        </div>
        <div class="item">
          <iov-datepicker
            [maxDate]="formGroup.controls['validToDate'].value"
            [minDate]="formGroup.controls['issuanceDate'].value"
            formControlName="validFromDate"
            label="Valid from"
          ></iov-datepicker>
        </div>
        <div class="item">
          <iov-datepicker
            [minDate]="
              formGroup.controls['validFromDate'].value ?? formGroup.controls['issuanceDate'].value
            "
            formControlName="validToDate"
            label="Valid to"
          ></iov-datepicker>
        </div>

        <div class="item">
          <iov-input
            formControlName="url"
            label="URL"
            tooltip="Specify a link to an external source that contains up-to-date information about this
                         certificate for ease of navigating to it to get the most recent information."
            type="url"
          ></iov-input>
        </div>
      </div>
      @if (visibleCustomFields?.length) {
        <app-custom-fields-edit
          [columns]="1"
          [formGroup]="formGroup"
          [visibleCustomFields]="visibleCustomFields"
        ></app-custom-fields-edit>
      }
    </mat-dialog-content>

    <mat-dialog-actions>
      <iov-button-group class="bottom-buttons">
        <iov-button (pressed)="onCancel()" type="ghost">Cancel</iov-button>
        <iov-button accessibilityRole="submit" [disabled]="isSubmitButtonDisabled()">
          <mat-icon icon-left>check</mat-icon>
          Save
        </iov-button>
      </iov-button-group>
    </mat-dialog-actions>
  </form>
}
