export enum EntityTypeEnum {
  LOCATIONS = "locations",
  CONNECTIONS = "connections",
  CERTIFICATES = "certificates",
  DOCUMENTS = "documents",
  ITEMS = "items",
  DELIVERIES = "deliveries",
  PROCESSES = "processes",
  PRODUCTS = "products",
  SUPPLY_CHAINS = "supply-chains",
  ORGANISATIONS = "organisations",
  MATERIALS = "materials",
}
