import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  signal,
  ViewChild,
} from "@angular/core";
import {
  ClusterIconStyle,
  GoogleMap,
  MapInfoWindow,
  MapMarkerClusterer,
} from "@angular/google-maps";

import { GroupedMarker } from "src/app/components/shared/map/grouped-marker";
import { IndividualMarker } from "src/app/components/shared/map/individual-marker";
import {
  CLUSTER_INFO_WINDOW_OFFSET_Y,
  CLUSTER_STYLES,
  HYBRID_MODE,
  ICluster,
  IGeographicalMapLocation,
  IWindowInfoExtended,
  MAP_OPTIONS,
  MARKER_CLUSTERER_IMAGE_PATH,
  MARKER_INFO_WINDOW_OFFSET_Y,
  POLYLINE_OPTIONS,
  ZOOM_BUTTONS_SELECTOR,
} from "src/app/components/shared/map/supply-chain-map.model";

import { GeographicalSupplyChainMap } from "@components/shared/map/geographical-supply-map";
import { NavigationParams, RouterService } from "@shared/services/router.service";

@Component({
  selector: "app-supply-chain-map",
  templateUrl: "./supply-chain-map.component.html",
  styleUrls: ["./supply-chain-map.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplyChainMapComponent implements OnInit, AfterViewInit {
  @Input() supplyChain: GeographicalSupplyChainMap;

  @ViewChild(GoogleMap) googleMap: GoogleMap;

  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;

  public isLoading = signal(true);

  individualMarkers: IndividualMarker[] = [];

  groupedMarkers: GroupedMarker[] = [];

  selectedMarkers: IndividualMarker[] = [];

  infoWindowExtended: IWindowInfoExtended = {
    clusterOrMarker: null,
    infoWindow: null,
  };

  bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();

  verticesList: google.maps.LatLngLiteral[][] = [];

  mapOptions: google.maps.MapOptions;

  infoWindowOptions: google.maps.InfoWindowOptions;

  polylineOptions: google.maps.PolylineOptions = POLYLINE_OPTIONS;

  readonly markerClustererImagePath: string = MARKER_CLUSTERER_IMAGE_PATH;

  readonly clusterStyles: ClusterIconStyle[] = CLUSTER_STYLES;

  readonly markerOptions: google.maps.MarkerOptions = { title: null };

  constructor(private routerService: RouterService) {}

  ngOnInit(): void {
    this.supplyChain.locations.forEach((location) => {
      const individualMarker = new IndividualMarker(location, this.supplyChain);

      this.bounds.extend(individualMarker.position);
      this.individualMarkers.push(individualMarker);
    });
  }

  ngAfterViewInit(): void {
    if (this.googleMap) {
      this.initializeMap();
    }
  }

  onMapIdle(): void {
    const map = this.googleMap.googleMap;

    if (map.mapTypes.get(HYBRID_MODE).name === "Satellite") {
      return;
    }

    map.mapTypes.get(HYBRID_MODE).name = "Satellite";
    map.setOptions({ mapTypeControl: true });

    this.resetZoom();
  }

  onResetZoomClick(): void {
    this.resetZoom();
  }

  onZoomChange(): void {
    this.forceCloseInfoWindow();

    const currentZoom = this.googleMap.googleMap.getZoom();

    this.polylineOptions.icons[0].icon.scale = 1.25 + currentZoom / 3;
  }

  onZoomInClick(): void {
    (document.querySelectorAll(ZOOM_BUTTONS_SELECTOR)[0] as HTMLButtonElement).click();
  }

  onZoomOutClick(): void {
    (document.querySelectorAll(ZOOM_BUTTONS_SELECTOR)[1] as HTMLButtonElement).click();
  }

  onClusteringEnd(clusterer: MapMarkerClusterer): void {
    const groupedMarkers: GroupedMarker[] = [];

    const visibleClusters = clusterer.getClusters().filter((cluster) => cluster.getSize() > 1);
    const locationIdsInClusters = visibleClusters
      .map((cluster) => cluster.getMarkers().map((m) => m.get("id")))
      .flat();

    for (const marker of this.individualMarkers) {
      if (locationIdsInClusters.includes(marker.location.id)) {
        continue;
      }

      groupedMarkers.push(new GroupedMarker([marker]));
    }

    for (const cluster of visibleClusters) {
      const locations = cluster
        .getMarkers()
        .map((marker) => this.findLocationById(marker.get("id")));
      const markers = locations.map((location) =>
        this.individualMarkers.find((m) => m.location.id === location.id),
      );

      const position = new google.maps.LatLng(
        cluster.getCenter().lat(),
        cluster.getCenter().lng(),
      ).toJSON();

      groupedMarkers.push(new GroupedMarker(markers, position));
    }

    groupedMarkers.forEach((groupedMarker) => groupedMarker.buildLinks(groupedMarkers));

    this.verticesList = this.buildVertices(groupedMarkers);
  }

  onMapClick(): void {
    this.forceCloseInfoWindow();
  }

  onMapTypeIdChange(): void {
    const strokeColor = this.googleMap.googleMap.getMapTypeId() === HYBRID_MODE ? "#fff" : "#000";

    this.polylineOptions = { ...this.polylineOptions, strokeColor };
  }

  onMarkerClick(_event: google.maps.MapMouseEvent, individualMarker: IndividualMarker): void {
    if (!this.resetInfoWindow(individualMarker)) {
      return;
    }

    this.selectedMarkers = [individualMarker];

    this.infoWindowOptions = {
      pixelOffset: new google.maps.Size(0, MARKER_INFO_WINDOW_OFFSET_Y),
      position: individualMarker.position,
    };

    this.infoWindow.open();
  }

  onClusterClick(cluster: ICluster): void {
    if (!this.resetInfoWindow(cluster)) {
      return;
    }

    this.selectedMarkers = (cluster.getMarkers() as google.maps.Marker[])
      .map((marker) => {
        return this.individualMarkers.find((m) => m.location.id === marker.get("id"));
      })
      .sort((m1, m2) => m1.location.name.localeCompare(m2.location.name));

    this.infoWindowOptions = {
      pixelOffset: new google.maps.Size(0, CLUSTER_INFO_WINDOW_OFFSET_Y),
      position: new google.maps.LatLng(
        cluster.getCenter().lat(),
        cluster.getCenter().lng(),
      ).toJSON(),
    };

    this.infoWindow.open();
  }

  onMarkerInitialized(marker: google.maps.Marker, location: IGeographicalMapLocation): void {
    marker.set("id", location.id);
  }

  getLocationLink(location: IGeographicalMapLocation): NavigationParams {
    return this.routerService.getLocationLink(location.id, false) as NavigationParams;
  }

  private initializeMap(): void {
    this.mapOptions = MAP_OPTIONS;
    this.infoWindowExtended.infoWindow = this.infoWindow;
    this.isLoading.set(false);
  }

  private resetZoom(): void {
    this.googleMap.fitBounds(this.bounds);
  }

  private forceCloseInfoWindow(): void {
    this.infoWindow.close();
    this.infoWindowExtended.clusterOrMarker = null;
  }

  private resetInfoWindow(clusterOrMarker: ICluster | IndividualMarker): boolean {
    this.infoWindow.close();

    if (
      this.infoWindowExtended.clusterOrMarker &&
      this.infoWindowExtended.clusterOrMarker === clusterOrMarker
    ) {
      this.infoWindowExtended.clusterOrMarker = null;

      return false;
    }

    this.infoWindowExtended.clusterOrMarker = clusterOrMarker;

    return true;
  }

  private buildVertices(groupedMarkers: GroupedMarker[]): google.maps.LatLngLiteral[][] {
    const verticesList: google.maps.LatLngLiteral[][] = [];

    for (let i = 0; i < groupedMarkers.length; i++) {
      const groupedMarker = groupedMarkers[i];

      for (let j = 0; j < groupedMarker.toGroupedMarkerList.length; j++) {
        const toGroupedMarker = groupedMarker.toGroupedMarkerList[j];
        const vertices = [
          new google.maps.LatLng(groupedMarker.position.lat, groupedMarker.position.lng).toJSON(),
          new google.maps.LatLng(
            toGroupedMarker.position.lat,
            toGroupedMarker.position.lng,
          ).toJSON(),
        ];

        verticesList.push(vertices);
      }
    }

    return verticesList;
  }

  private findLocationById(locationId: string): IGeographicalMapLocation {
    return IndividualMarker.findLocationById(this.supplyChain, locationId);
  }
}
