<form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
  <h2 *ngIf="data.titleTranslatedText" mat-dialog-title>{{ data.titleTranslatedText }}</h2>
  <mat-dialog-content>
    <p *ngIf="data.contentTranslatedText">{{ data.contentTranslatedText }}</p>
    <div *ngIf="data.contentTranslatedHTML" [innerHTML]="data.contentTranslatedHTML"></div>

    <mat-form-field
      *ngIf="data.confirmPhraseTranslatedText"
      [formGroup]="formGroup"
      class="full-width"
    >
      <input
        [placeholder]="'translations.confirmPhrasePlaceholderTranslatedText'"
        autocomplete="new-password"
        formControlName="confirmPhrase"
        matInput
        type="text"
      />
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions>
    <iov-button-group>
      <iov-button (pressed)="onClose()" type="ghost">
        <mat-icon *ngIf="data.cancelButtonIcon" icon-left>{{ data.cancelButtonIcon }}</mat-icon>
        <span *ngIf="data.cancelButtonTranslatedText">{{ data.cancelButtonTranslatedText }}</span>
        <span *ngIf="!data.cancelButtonTranslatedText">Cancel</span>
      </iov-button>

      @if (data.thirdActionButtonText) {
        <iov-button (pressed)="thirdActionPerfomed()" [color]="data.thirdActionButtonColor">
          {{ data.thirdActionButtonText }}
        </iov-button>
      }
      <iov-button
        [color]="data.confirmButtonColor"
        [disabled]="isSubmitButtonDisabled() || data.isSubmitButtonDisabled"
        accessibilityRole="submit"
        cdkFocusInitial
      >
        <mat-icon icon-left>{{ data.confirmButtonIcon ?? "check" }}</mat-icon>
        <span *ngIf="data.confirmButtonTranslatedText">{{ data.confirmButtonTranslatedText }}</span>
        <span *ngIf="!data.confirmButtonTranslatedText">Confirm</span>
      </iov-button>
    </iov-button-group>
  </mat-dialog-actions>
</form>
