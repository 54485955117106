import { ChangeDetectionStrategy, Component, input, OnInit } from "@angular/core";

import { DeliveryReportOtherDeliveriesModel as Model } from "@components/reports/delivery-report/other-deliveries/delivery-report-other-deliveries.model";
import { IDeliveryExtended } from "@shared/interfaces";

@Component({
  selector: "app-delivery-report-other-deliveries",
  templateUrl: "./delivery-report-other-deliveries.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryReportOtherDeliveriesComponent implements OnInit {
  public deliveries = input.required<IDeliveryExtended[]>();

  public deliveriesByCountries: Model.IDeliveriesByCountries = {};

  public ngOnInit(): void {
    this.deliveriesByCountries = this.deliveries().reduce((acc, delivery) => {
      const country = delivery.from.address.country;

      if (!acc[country]) {
        acc[country] = [];
      }
      acc[country].push(delivery);

      return acc;
    }, {} as Model.IDeliveriesByCountries);
  }
}
