import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-reports-eudr-production-place",
  templateUrl: "./reports-eudr-production-place.component.html",
  styleUrls: ["./reports-eudr-production-place.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ReportsEudrProductionPlaceComponent {
  @Input() index: number;

  @Input() formGroup: FormGroup;
}
