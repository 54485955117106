import { HttpErrorResponse } from "@angular/common/http";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  signal,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef } from "ag-grid-community";
import { ConfirmDialogComponent } from "src/app/components";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { ConfirmDialogResponseEnum, RoutingEnum, TableEnum, UserTypeEnum } from "@shared/enums";
import { IInvitation } from "@shared/interfaces";
import { AuthenticationService, InvitationsService } from "@shared/services";
import { CellRendererUtils, ColumnUtils, CommonUtils } from "@shared/utils";

@Component({
  selector: "app-users-invited-table",
  templateUrl: "./users-invited-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersInvitedTableComponent implements AfterViewInit {
  @Input()
  public invitations: IInvitation[] = null;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  @Output()
  public totalElementsChanged: EventEmitter<number> = new EventEmitter();

  @Input()
  public table = TableEnum.USERS_INVITED;

  @Input()
  public isSaveTableState = false;

  public isLoading = signal(true);

  public rowData: IInvitation[] = [];

  public columnDefs = signal<ColDef[]>([]);

  constructor(
    private invitationsService: InvitationsService,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
  ) {}

  public async ngAfterViewInit() {
    this.setColumnDefs();
    await this.getAll();
  }

  private setColumnDefs = (): void => {
    const columnDefs: ColDef[] = [
      { headerName: "Email", field: "email", lockVisible: true },
      { headerName: "Roles", field: "roles", cellRenderer: CellRendererUtils.roleNames },
      {
        headerName: "Status",
        field: "status",
        cellRenderer: CellRendererUtils.capitaliseFirstLetter,
      },
    ];

    if (this.areButtonsEnabled) {
      const buttons = [
        {
          onClick: this.onCopy,
          tooltip: "Copy invitation link",
          icon: "content_copy",
        },
        {
          onClick: this.onResendInvitation,
          tooltip: "Resend invitation",
          icon: "mail",
        },
        {
          onClick: this.onDelete,
          tooltip: "Delete invitation",
          icon: "delete",
        },
      ];

      columnDefs.push(ColumnUtils.buttons(buttons));
    }
    this.columnDefs.set(columnDefs);
  };

  public onCopy = (invitation: IInvitation): void => {
    const { email, orgName, userType } = invitation;
    const emailUrlEncoded = encodeURIComponent(email);
    const orgNameUrlEncoded = encodeURIComponent(orgName);
    const invitationLink =
      userType === UserTypeEnum.NEW
        ? `${window.location.origin}/${RoutingEnum.SIGN_UP}?invitationId=${invitation.id}&email=${emailUrlEncoded}&org=${orgNameUrlEncoded}`
        : `${window.location.origin}/${RoutingEnum.INVITATIONS}?acceptInvitationId=${invitation.id}&org=${orgNameUrlEncoded}`;

    CommonUtils.textToClipboard(invitationLink);
    this.notificationService.showSuccess("Invitation link copied");
  };

  public onDelete = (invitation: IInvitation): void => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: `Delete invitation`,
        contentTranslatedText: "Are you sure that you want to delete this invitation?",
        confirmButtonColor: "danger",
        confirmButtonTranslatedText: "Delete",
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        await this.invitationsService
          .deleteInvitation(invitation.id)
          .then(async () => {
            this.notificationService.showSuccess(`Invitation deleted`);
            await this.getAll();
          })
          .catch((error: HttpErrorResponse) => {
            this.notificationService.showError(error);
          });
      }
    });
  };

  public onResendInvitation = (invitation: IInvitation): void => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: `Resend confirmation`,
        contentTranslatedText: "Are you sure that you want to resend the invitation email?",
        confirmButtonTranslatedText: "Resend",
        confirmButtonIcon: "mail",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        await this.invitationsService
          .resendInvitation(invitation.id)
          .then(async () => {
            this.notificationService.showSuccess(`Invitation sent`);
            await this.getAll();
          })
          .catch((error: HttpErrorResponse) => {
            this.notificationService.showError(error);
          });
      }
    });
  };

  public getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    if (this.invitations) {
      this.rowData = this.invitations;
      this.totalElementsChanged.emit(this.rowData.length);
      this.isLoading.set(false);
    } else {
      try {
        this.rowData = await this.invitationsService.getAll(
          undefined,
          this.authenticationService.getActiveOrganisationId(),
        );
        this.totalElementsChanged.emit(this.rowData.length);
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };
}
