<h2 mat-dialog-title>{{ isEditing() ? "Edit" : "Connect" }} organisation</h2>
@if (isLoading()) {
  <app-loader></app-loader>
} @else {
  <form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
    <mat-dialog-content>
      @if (!isEditing()) {
        <p class="no-top-margin">
          Please enter the organisation's code to connect to it. The other organisation will have to
          do the same process for a connection to be successful.
        </p>
      }
      <iov-input formControlName="organisationId" label="Organisation code"></iov-input>
    </mat-dialog-content>
    <mat-dialog-actions>
      <iov-button-group>
        <iov-button (pressed)="onClose()" type="ghost">Cancel</iov-button>
        <iov-button accessibilityRole="submit">
          <mat-icon icon-left>{{ isEditing() ? "check" : "link" }}</mat-icon>
          {{ isEditing() ? "Save" : "Connect" }}
        </iov-button>
      </iov-button-group>
    </mat-dialog-actions>
  </form>
}
