import { Inject, Injectable } from "@angular/core";

import { GrowthBook } from "@growthbook/growthbook";

import { EnvironmentEnum, FeatureAttributeEnum, FeatureFlagEnum } from "@shared/enums";
import { IConfig, IFeatureFlags } from "@shared/interfaces";
import { APP_CONFIG } from "@shared/tokens";

@Injectable({
  providedIn: "root",
})
export class FeatureFlagService {
  private gb: GrowthBook;

  constructor(@Inject(APP_CONFIG) private environment: IConfig) {}

  public init = async (organisationId: string, userId: string): Promise<void> => {
    if (this.gb) {
      return;
    }
    if (!this.environment.growthbookClientKey) {
      console.error(`Growthbook - No client key`);

      return;
    }
    const attributes: any = {};

    if (organisationId) {
      attributes[FeatureAttributeEnum.ORGANISATION_ID] = organisationId;
    }
    if (userId) {
      attributes[FeatureAttributeEnum.USER_ID] = userId;
    }
    this.gb = new GrowthBook<IFeatureFlags>({
      apiHost: "https://cdn.growthbook.io",
      clientKey: this.environment.growthbookClientKey,
      enableDevMode: this.environment.name === EnvironmentEnum.DEVELOPMENT,
      attributes,
    });

    await this.gb.init({ streaming: true, skipCache: true, timeout: 0 });

    // this.gb.setRenderer(() => {
    //     // this is triggered when a flag is updated
    // });
  };

  public setAttribute = async (key: FeatureAttributeEnum, value?: string): Promise<void> => {
    if (!this.gb) {
      return;
    }
    const attributes = this.gb.getAttributes() ?? {};

    if (attributes[key] !== value) {
      attributes[key] = value ?? undefined;

      await this.gb.setAttributes(attributes);
    }
  };

  public isEnabled = (key: FeatureFlagEnum): boolean => {
    if (!this.gb) {
      console.error(`Growthbook is not inititialised - isEnabled (${key})`);

      return false;
    }
    try {
      const isEnabled = this.gb.getFeatureValue<boolean>(key, false) ?? false;

      return isEnabled;
    } catch {
      console.error(`Growthbook error - isEnabled (${key})`);

      return false;
    }
  };

  public isDevOrStgEnv = (): boolean =>
    [EnvironmentEnum.DEVELOPMENT, EnvironmentEnum.STAGING].includes(
      this.environment.name as EnvironmentEnum,
    );

  public getEnvName = (): string => this.environment.name;
}
