import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class LoaderComponent {
  @Input()
  public containerClass?: string = "on-table";
}
