import { AfterViewInit, ChangeDetectionStrategy, Component, Input, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef } from "ag-grid-community";
import { ConfirmDialogComponent, EditTagDialogComponent } from "src/app/components";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { ConfirmDialogResponseEnum, TableEnum } from "@shared/enums";
import { ITagDefinition } from "@shared/interfaces";
import { TagDefinitionsService } from "@shared/services";
import { CellRendererUtils, ColumnUtils } from "@shared/utils";

@Component({
  selector: "app-tags-table",
  templateUrl: "./tags-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagsTableComponent implements AfterViewInit {
  @Input()
  public tags: ITagDefinition[] = null;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  @Input()
  public table = TableEnum.TAGS;

  @Input()
  public isSaveTableState = false;

  public isLoading = signal(true);

  public rowData: ITagDefinition[] = [];

  public columnDefs = signal<ColDef[]>([]);

  constructor(
    private tagDefinitionsService: TagDefinitionsService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) {}

  public async ngAfterViewInit() {
    this.setColumnDefs();
    await this.getAll();
  }

  private setColumnDefs = (): void => {
    const columnDefs: ColDef[] = [
      { headerName: "Name", field: "title", lockVisible: true },
      {
        headerName: "Color",
        field: "color",
        cellClass: "container-flex",
        cellRenderer: CellRendererUtils.colorBox,
      },
    ];

    if (this.areButtonsEnabled) {
      const buttons = [
        {
          onClick: this.onDelete,
          tooltip: "Delete",
          icon: "delete",
        },
        {
          onClick: this.onEdit,
          tooltip: "Edit",
          icon: "edit",
        },
      ];

      columnDefs.push(ColumnUtils.buttons(buttons));
    }
    this.columnDefs.set(columnDefs);
  };

  public onDelete = async (element: ITagDefinition): Promise<void> => {
    if (!this.areButtonsEnabled) {
      return;
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: "Delete confirmation",
        contentTranslatedText: `Are you sure you want delete this tag? Deleting it will remove it from all records.`,
        confirmButtonColor: "danger",
        confirmButtonTranslatedText: "Delete",
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        try {
          this.isLoading.set(true);
          await this.tagDefinitionsService.delete(element.id);
          this.notificationService.showSuccess("Tag deleted");
          await this.getAll();
        } catch (error) {
          this.notificationService.showError(error);
          this.isLoading.set(false);
        }
      }
    });
  };

  public onEdit = async (element: ITagDefinition): Promise<void> => {
    if (!this.areButtonsEnabled) {
      return;
    }
    const dialogRef = this.dialog.open(EditTagDialogComponent, {
      data: {
        tagDefinition: element,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: { hasSaved: boolean }) => {
      if (result?.hasSaved) {
        await this.getAll();
      }
    });
  };

  public getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    if (this.tags) {
      this.rowData = this.tags;
      this.isLoading.set(false);
    } else {
      try {
        this.rowData = await this.tagDefinitionsService.getAll();
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };
}
