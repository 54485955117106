import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { HttpMethodsEnum } from "../../enums";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private httpClient: HttpClient) {}

  public get<T>(endpoint: string): Observable<T> {
    return this.call<T>(HttpMethodsEnum.GET, endpoint, null, null);
  }

  public post<T>(endpoint: string, payload?: any): Observable<T> {
    return this.call<T>(HttpMethodsEnum.POST, endpoint, payload);
  }

  public put<T>(endpoint: string, payload?: any): Observable<T> {
    return this.call<T>(HttpMethodsEnum.PUT, endpoint, payload);
  }

  public delete<T>(endpoint: string, payload?: any): Observable<T> {
    return this.call<T>(HttpMethodsEnum.DELETE, endpoint, payload);
  }

  private call<T>(
    method: string,
    url: string,
    body: any = null,
    params: any = null,
  ): Observable<T> {
    const headers: HttpHeaders = new HttpHeaders({
      accept: "application/json, text/plain",
      "Content-Type": "application/json",
    });

    const httpOptions: any = {
      headers,
      params: params ?? undefined,
      body: body ?? undefined,
    };

    return this.httpClient.request(method, url, httpOptions) as unknown as Observable<T>;
  }
}
