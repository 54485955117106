import { KeyValue } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject, input, OnInit } from "@angular/core";

import { DeliveryReportService } from "@components/reports/delivery-report/delivery-report.service";
import { IDeliveryExtended } from "@shared/interfaces";

@Component({
  selector: "app-delivery-report-other-deliveries-by-group",
  templateUrl: "./delivery-report-other-deliveries-by-group.component.html",
  styleUrls: ["./delivery-report-other-deliveries-by-group.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryReportOtherDeliveriesByGroupComponent implements OnInit {
  public deliveriesByCountry = input.required<KeyValue<string, IDeliveryExtended[]>>();

  public countryName: string;

  private deliveryReportService: DeliveryReportService = inject(DeliveryReportService);

  public ngOnInit(): void {
    this.countryName = this.deliveryReportService
      .countryOptions()
      .find((option) => option.value === this.deliveriesByCountry().key).label;
  }
}
