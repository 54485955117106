import { Inject, Injectable } from "@angular/core";

import { Observable, lastValueFrom } from "rxjs";

import { APP_CONFIG } from "@shared/tokens";

import { ApiService } from "./api.service";
import { CommonConstants } from "../../constants";
import { UnitOfMeasurementCategoryTypeEnum } from "../../enums";
import {
  IBaseUnit,
  IConfig,
  ICustomUnitOfMeasurement,
  IPageableContent,
  IUnitOfMeasurementPayload,
} from "../../interfaces";
import { FormUtils } from "../../utils";
import { AuthenticationService } from "../authentication.service";

@Injectable({
  providedIn: "root",
})
export class UnitsOfMeasurementService {
  private readonly baseUrl: string = `${this.environment.baseUrl}`;

  constructor(
    private authenticationService: AuthenticationService,
    private apiService: ApiService,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {}

  private getBaseUrl = (): string =>
    `${this.environment.baseUrl}organisations/${this.authenticationService.getActiveOrganisationId()}/units-of-measurement`;

  public async getUnitTypes(): Promise<UnitOfMeasurementCategoryTypeEnum[]> {
    return lastValueFrom(
      this.apiService.get<UnitOfMeasurementCategoryTypeEnum[]>(
        `${this.baseUrl}common/base-unit-types`,
      ),
    );
  }

  public async getSystemUnits(): Promise<IBaseUnit[]> {
    return lastValueFrom(this.apiService.get<IBaseUnit[]>(`${this.baseUrl}common/base-units`));
  }

  public getPageSubscription = (
    search: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
  ): Observable<IPageableContent<ICustomUnitOfMeasurement>> => {
    const url = `${this.getBaseUrl()}?${FormUtils.addUrlParams({ search, size, page, sort })}`;

    return this.apiService.get<IPageableContent<ICustomUnitOfMeasurement>>(url);
  };

  public getPage = async (
    search: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
    type: string = undefined,
  ): Promise<IPageableContent<ICustomUnitOfMeasurement>> => {
    const url = `${this.getBaseUrl()}?${FormUtils.addUrlParams({ search, size, page, sort, type })}`;

    return await this.apiService.get<IPageableContent<ICustomUnitOfMeasurement>>(url).toPromise();
  };

  public async get(id: string): Promise<ICustomUnitOfMeasurement> {
    return lastValueFrom(
      this.apiService.get<ICustomUnitOfMeasurement>(`${this.getBaseUrl()}/${id}`),
    );
  }

  public async getBaseUnit(id: string): Promise<ICustomUnitOfMeasurement> {
    return lastValueFrom(
      this.apiService.get<ICustomUnitOfMeasurement>(`${this.baseUrl}common/base-units/${id}`),
    );
  }

  public async getAll(type: string = undefined): Promise<ICustomUnitOfMeasurement[]> {
    const result: ICustomUnitOfMeasurement[] = [];
    let page = 0;
    let hasNextPage: boolean = true;

    while (hasNextPage) {
      const {
        content,
        page: { totalElements },
      } = await this.getPage(
        undefined,
        CommonConstants.MAX_API_GET_ITEMS_SIZE,
        page,
        undefined,
        type,
      );

      result.push(...content);

      page++;
      hasNextPage = result.length < totalElements;
    }

    return result;
  }

  public createOrUpdate = async (
    payload: IUnitOfMeasurementPayload,
    id?: string,
  ): Promise<IBaseUnit> => {
    if (id) {
      return await lastValueFrom(
        this.apiService.put<IBaseUnit>(`${this.getBaseUrl()}/${id}`, payload),
      );
    } else {
      return await lastValueFrom(this.apiService.post<IBaseUnit>(`${this.getBaseUrl()}`, payload));
    }
  };

  public async getDefaultUnit(defaultUnitId: string): Promise<ICustomUnitOfMeasurement | null> {
    try {
      return await this.get(defaultUnitId);
    } catch {
      return null;
    }
  }

  public delete = async (id: string): Promise<void> =>
    await lastValueFrom(this.apiService.delete<void>(`${this.getBaseUrl()}/${id}`));
}
