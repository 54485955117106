import { Inject, Injectable } from "@angular/core";

import { lastValueFrom } from "rxjs";

import { IConfig, IPageableContent } from "@shared/interfaces";
import { IComment, ICommentPayload } from "@shared/interfaces/comment.interface";
import { APP_CONFIG } from "@shared/tokens";
import { FormUtils } from "@shared/utils";

import { ApiService } from "./api.service";
import { AuthenticationService } from "../authentication.service";

@Injectable({
  providedIn: "root",
})
export class CommentsService {
  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {}

  private getBaseUrl = (): string =>
    `${this.environment.baseUrl}organisations/${this.authenticationService.getActiveOrganisationId()}/comments`;

  public getAll = async (entity: string): Promise<IPageableContent<IComment>> => {
    const url = `${this.getBaseUrl()}?${FormUtils.addUrlParams({ entity })}`;

    return await lastValueFrom(this.apiService.get<IPageableContent<IComment>>(url));
  };

  public delete = async (id: string): Promise<void> =>
    await lastValueFrom(this.apiService.delete<void>(`${this.getBaseUrl()}/${id}`));

  public createOrUpdate = async (
    payload: ICommentPayload,
    id?: string,
  ): Promise<ICommentPayload> => {
    if (id) {
      return await lastValueFrom(
        this.apiService.put<ICommentPayload>(`${this.getBaseUrl()}/${id}`, payload),
      );
    } else {
      return await lastValueFrom(
        this.apiService.post<ICommentPayload>(`${this.getBaseUrl()}`, payload),
      );
    }
  };
}
