import { Location } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { Router } from "@angular/router";

import { RoutingEnum } from "src/app/shared/enums";
import { AuthenticationService } from "src/app/shared/services";

import { RouterService } from "@shared/services/router.service";

@Component({
  selector: "app-page-title",
  templateUrl: "./page-title.component.html",
  styleUrls: ["./page-title.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageTitleComponent implements OnInit {
  @Input()
  public title: string;

  @Input()
  public icon: string;

  @Input()
  public tag: string = null;

  @Input()
  public goBackRoute: RoutingEnum = null;

  @Input()
  public goBackRouteState: any = null;

  @Input()
  public isGoBackUrlHistory = false;

  @Input()
  public hint: string;

  @Input()
  public backText?: string;

  @Output() back: EventEmitter<void> = new EventEmitter();

  public isBackLinkEnabled = false;

  constructor(
    private router: Router,
    private routerService: RouterService,
    private authenticationService: AuthenticationService,
    private location: Location,
  ) {}

  public ngOnInit(): void {
    this.isBackLinkEnabled = this.getIsBackLinkEnabled();
  }

  public getBackText = (): string => {
    if (this.authenticationService.isRegularUser()) {
      return this.backText ?? "Back";
    }

    return "Back";
  };

  public getIsBackLinkEnabled = (): boolean => {
    if (this.authenticationService.isRegularUser()) {
      return this.isGoBackUrlHistory || !!this.goBackRoute || this.back.observed;
    }
    const defaultRoute = this.authenticationService.getDefaultRoute();

    return !this.location.isCurrentPathEqualTo(`/${defaultRoute}`);
  };

  public onBack = async (): Promise<void> => {
    if (this.back.observed) {
      this.back.emit();
    } else if (this.isGoBackUrlHistory && this.routerService.hasHistory()) {
      this.routerService.goBackInHistory();
    } else if (this.goBackRoute) {
      this.routerService.removeLastHistoryElement();
      let extras = undefined;

      if (this.goBackRouteState) {
        extras = { state: this.goBackRouteState };
      }
      await this.router.navigate([`/${this.goBackRoute}`], extras);
    }
  };
}
