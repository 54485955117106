import { ChangeDetectionStrategy, Component, inject, input, OnInit } from "@angular/core";

import { IConnectionExtended, IDocumentType } from "@shared/interfaces";
import { NavigationParams, RouterService } from "@shared/services/router.service";
import { CommonUtils } from "@shared/utils";

@Component({
  selector: "app-delivery-report-organisation-details",
  templateUrl: "./delivery-report-organisation-details.component.html",
  styles: [
    `
      .organisation-name {
        font-size: 18px;
        font-weight: 500;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryReportOrganisationDetailsComponent implements OnInit {
  public organisation = input.required<IConnectionExtended>();

  public documentTypes: IDocumentType[] = [];

  private routerService: RouterService = inject(RouterService);

  public ngOnInit(): void {
    this.documentTypes = CommonUtils.getDocumentTypesByDocuments(this.organisation().documents);
  }

  public getOrganisationLink(): NavigationParams {
    return <NavigationParams>this.routerService.getOrganisationLink(this.organisation().id, false);
  }
}
