import { ComponentType } from "@angular/cdk/overlay";
import { ComponentRef, computed, Injectable, signal, TemplateRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";

import { BehaviorSubject, lastValueFrom, Observable, Subject } from "rxjs";

import { SlideOverlayPageClass } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.class";
import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { ConfirmDialogComponent } from "@components/shared";
import { ConfirmDialogResponseEnum } from "@shared/enums";
import { ITagExtended, ITagPayload } from "@shared/interfaces";
import { TagsService } from "@shared/services";

@Injectable({
  providedIn: "root",
})
export class SlideOverlayPageService {
  editMode = signal<boolean>(false);

  loading = signal<boolean>(true);

  contentRightSidePanel = signal<TemplateRef<unknown>>(undefined);

  viewMode = computed(() => !this.editMode());

  canDeactivateInProgress = signal<boolean>(false);

  componentRef = signal<ComponentRef<SlideOverlayPageClass>>(undefined);

  private overlaySubject: BehaviorSubject<{ view: string; id: string; hasSaved?: boolean }> =
    new BehaviorSubject(null);

  private refreshTableSubject = new Subject<unknown>();

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private tagsService: TagsService,
  ) {}

  get trigger$(): Observable<{ view: string; id: string; hasSaved?: boolean }> {
    return this.overlaySubject.asObservable();
  }

  get refreshTable$(): Observable<unknown> {
    return this.refreshTableSubject.asObservable();
  }

  async componentCanDeactivate(): Promise<boolean> {
    this.canDeactivateInProgress.set(true);

    const canClose = await this.canClose();

    if (canClose) {
      this.canDeactivateInProgress.set(false);
      const params = this.route.snapshot.queryParams;

      this.toggle(params["view"], params["id"]);
    } else {
      this.canDeactivateInProgress.set(true);
    }

    return canClose;
  }

  async canClose(): Promise<boolean> {
    let canClose = true;

    if (this.componentRef() && typeof this.componentRef()?.instance.canClose === "function") {
      canClose = await this.componentRef()?.instance.canClose();
    }

    return canClose;
  }

  updateTable(): void {
    this.refreshTableSubject.next(this.componentRef().instance.element);
  }

  async saveTagsForNewRecord(
    selectedTagsForNewRecord: ITagExtended[],
    entityUri: string,
  ): Promise<void> {
    const promises = selectedTagsForNewRecord.map((tag) => {
      const payload: ITagPayload = { entity: entityUri, definition: tag.definition };

      return this.tagsService.addToRecord(payload);
    });

    await Promise.all(promises);
  }

  assignComponentRef(componentRef: ComponentRef<SlideOverlayPageClass>): void {
    this.componentRef.set(componentRef);
  }

  enableEditMode(): void {
    this.editMode.set(true);
  }

  disableEditMode(): void {
    this.editMode.set(false);
  }

  async enableViewMode(force = false): Promise<boolean> {
    if (!force && this.componentRef()) {
      const canExit = await this.componentRef().instance.canChangeMenuItem();

      if (canExit) {
        this.componentRef().instance.handleDiscardChanges();
        this.disableEditMode();
      }

      return canExit;
    }

    this.disableEditMode();

    return true;
  }

  showLoading(): void {
    this.loading.set(true);
  }

  dismissLoading(): void {
    this.loading.set(false);
  }

  toggle(view: string, id?: string): void {
    const hasSaved = this.componentRef()?.instance?.hasSaved;

    this.overlaySubject.next({ view, id, hasSaved });
  }

  async save(isSaveOnly: boolean = true): Promise<void> {
    if (!this.componentRef()) {
      return;
    }

    const { instance } = this.componentRef();

    this.showLoading();
    const shouldUpdateTable = instance.shouldUpdateTable;
    const isNewRecord = !instance?.element?.id;

    const successful = await instance.save();

    if (successful) {
      if (isNewRecord) {
        await this.saveTagsForNewRecord(instance.selectedTagsForNewRecord, instance.entityUri);
      }

      if (shouldUpdateTable) {
        this.updateTable();
      }

      instance.onHasSaved();

      await instance.afterSave(isSaveOnly);
      await this.enableViewMode(true);
    }
    this.dismissLoading();
  }

  async delete(deleteFn: (id: string) => Promise<void>): Promise<void> {
    if (!this.componentRef()?.instance?.element?.id) {
      return;
    }

    const id: string = this.componentRef().instance.element.id;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: "Delete record",
        contentTranslatedText: `Are you sure that you want to delete this record?`,
        confirmButtonColor: "danger",
        confirmButtonTranslatedText: "Delete",
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        const shouldUpdateTable = this.componentRef().instance.shouldUpdateTable;

        try {
          await deleteFn(id);

          if (shouldUpdateTable) {
            this.updateTable();
          }

          this.notificationService.showSuccess("Record deleted");
          this.close();
        } catch (error) {
          this.notificationService.showError(error);
        }
      }
    });
  }

  async close(force: boolean = false): Promise<void> {
    if (this.componentRef() && !force) {
      const canClose = await this.canClose();

      if (canClose) {
        this.overlaySubject.next(null);
      }
    } else {
      this.overlaySubject.next(null);
    }
  }

  openDialog<R, D>(component: ComponentType<unknown>, data: D): Promise<R> {
    const dialogRef = this.dialog.open(component, {
      panelClass: "overlay-dialog",
      backdropClass: "overlay-dialog-backdrop",
      width: "430px",
      height: "100%",
      position: {
        top: "0",
        right: "0",
      },
      data,
    });

    return lastValueFrom(dialogRef.afterClosed());
  }

  isSideMenuOpen(content: TemplateRef<unknown>): boolean {
    return this.contentRightSidePanel() === content;
  }

  openSideMenu(content: TemplateRef<unknown>): void {
    this.contentRightSidePanel.set(content);
  }

  closeSideMenu(): void {
    this.contentRightSidePanel.set(undefined);
  }
}
