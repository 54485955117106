import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  signal,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

import { SlideOverlayContentComponent } from "@design-makeover/components/overlay/slide-overlay-content/slide-overlay-content.component";
import { SlideOverlayPageClass } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.class";

import { CommonConstants } from "@shared/constants";
import { AttachmentTypeEnum, OverlayTabEnum, RoutingEnum } from "@shared/enums";
import { IAttachment, ICertificateExtended, IDocument, ISelectOption } from "@shared/interfaces";
import { RecordSharingService } from "@shared/services";
import { CommonUtils } from "@shared/utils";
import { InboundSharedRecordUtils } from "@shared/utils/inboud-shared-record.utils";

@Component({
  selector: "app-shared-certificate-overlay",
  templateUrl: "./shared-certificate-overlay.component.html",
  styleUrls: ["../certificate-overlay-common.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SharedCertificateOverlayComponent extends SlideOverlayPageClass implements OnInit {
  private recordSharingService = inject(RecordSharingService);

  public formGroup: UntypedFormGroup;

  override element = signal<ICertificateExtended>(null);

  public standardOptions = signal<ISelectOption[]>([]);

  public standardTypeOptions = signal<ISelectOption[]>([]);

  public selectedAttachments = signal<IAttachment[]>([]);

  public attachedDocuments = signal<IDocument[]>([]);

  @ViewChild("slideOverlayContent") override slideOverlayContent: SlideOverlayContentComponent;

  override menuItems = signal(
    new Map([
      [OverlayTabEnum.DETAILS, { isEnabled: true }],
      [OverlayTabEnum.ATTACHED_TO, { isEnabled: true }],
      [OverlayTabEnum.DOCUMENTS, { isEnabled: true }],
    ]),
  );

  async ngOnInit(): Promise<void> {
    this.overlay.showLoading();
    if (!this.isOnCorrectOverlay(RoutingEnum.OVERLAY_SHARED_CERTIFICATE)) {
      return;
    }
    if (!this.recordId || !this.organisationIdQueryParam) {
      this.notification.showError("Missing parameters");

      return;
    }

    const inboundCertificates = await this.recordSharingService.getInboundCertificatesByIdsGraphQL(
      [this.recordId],
      this.organisationIdQueryParam,
      CommonConstants.MAX_API_GET_ITEMS_SIZE,
    );

    this.element.set(inboundCertificates[0]);
    this.setupForm();
    await this.setupSelectedAttachments();
    await this.setMenuItemFromURLParam();
  }

  override setupForm() {
    const standardValue: ISelectOption = {
      value: this.element().standard.id,
      label: this.element().standard.name,
    };
    const standardTypeValue = this.element()?.standardType
      ? { value: this.element().standardType.id, label: this.element().standardType.fullName }
      : null;

    this.standardOptions.set([standardValue]);
    if (standardTypeValue) {
      this.standardTypeOptions.set([standardTypeValue]);
    }

    this.formGroup = new UntypedFormGroup({
      number: new UntypedFormControl(this.element()?.number ?? null),
      standard: new UntypedFormControl(standardValue ?? null),
      standardType: new UntypedFormControl(standardTypeValue ?? null),
      issuanceDate: new UntypedFormControl(this.element()?.issuanceDate ?? null),
      validFromDate: new UntypedFormControl(this.element()?.validFromDate ?? null),
      validToDate: new UntypedFormControl(this.element()?.validToDate ?? null),
      url: new UntypedFormControl(null), //TODO: graphql query does not return url
    });
    this.overlay.dismissLoading();
  }

  async setupSelectedAttachments(): Promise<void> {
    const attachmentUri = `/organisations/${this.activeOrganisationId}/record-sharing/inbound/records/${this.organisationIdQueryParam}/certificates/${this.element().id}`;
    const attachments = await InboundSharedRecordUtils.setupSelectedAttachments(
      this.element(),
      AttachmentTypeEnum.CERTIFICATE,
      this.organisationIdQueryParam,
      this.activeOrganisationId,
      this.recordSharingService,
      this.routerService,
    );

    const documentAtached = await this.recordSharingService.getInbountAttachments(
      this.organisationIdQueryParam,
      AttachmentTypeEnum.DOCUMENT,
      null,
      attachmentUri,
    );

    const documentsIds = documentAtached.map((doc) => CommonUtils.getUriId(doc.attachmentUri));
    const documents = (await this.recordSharingService.getInboundDocumentsByIdsGraphQL(
      documentsIds,
      this.organisationIdQueryParam,
    )) as any[];

    this.attachedDocuments.set(documents);

    this.selectedAttachments.set(attachments);
  }

  protected override recordName(): string {
    return this.element()?.number;
  }
}
