@if (safeTags.length > 0) {
  <div class="tags-container flex-wrap" [ngClass]="class">
    @for (tag of safeTags; track tag.value) {
      <iov-tag
        [tag]="tag"
        [maxTextLengthToShow]="maxTextLengthToShow"
        [canRemove]="canRemove"
        (remove)="onRemove($event)"
        [isTagClickable]="isTagClickable"
        (pressed)="onTagPressed($event)"
      ></iov-tag>
    }
  </div>
}
