import { HttpErrorResponse } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

import { RecordStateEnum, TagSelectorTypeEnum } from "src/app/shared/enums";
import { IDocumentType, ILocationType, ISelectOption } from "src/app/shared/interfaces";
import { DocumentTypesService, LocationTypesService } from "src/app/shared/services";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

@Component({
  selector: "app-tags-selector",
  templateUrl: "./tags-selector.component.html",
  styleUrls: ["./tags-selector.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagsSelectorComponent implements OnInit {
  destroyRef = inject(DestroyRef);

  @Input()
  public type: TagSelectorTypeEnum;

  @Input()
  public placeholder: string;

  @Input()
  public selectedIds: string[] = [];

  @Input()
  public suffixIcon: string;

  @Input()
  public allCountriesOption: ISelectOption[] = [];

  @Input()
  public allLocationsOption: ISelectOption[] = [];

  @Input()
  public suffixIconTooltip: string;

  @Input()
  public hintLeft: string;

  @Input()
  public canDelete: boolean = true;

  @Input()
  public canCreate: boolean = true;

  @Input()
  public isNewValueAllowed: boolean = true;

  @Output()
  public setSelectedIds: EventEmitter<string[]> = new EventEmitter();

  @Output()
  public stringValueChanged: EventEmitter<string> = new EventEmitter();

  @Output()
  public newTagCreated: EventEmitter<void> = new EventEmitter();

  public formGroup = new UntypedFormGroup({
    tags: new UntypedFormControl(null),
  });

  public isLoading = signal(true);

  public availableOptions: ISelectOption[] = [];

  public selectedOptions: ISelectOption[] = [];

  private allOptions: ISelectOption[] = [];

  private service: any;

  constructor(
    private notificationService: NotificationService,
    private locationTypesService: LocationTypesService,
    private documentTypesService: DocumentTypesService,
  ) {}

  public async ngOnInit(): Promise<void> {
    switch (this.type) {
      case TagSelectorTypeEnum.LOCATION_TYPES:
        this.service = this.locationTypesService;
        break;
      case TagSelectorTypeEnum.DOCUMENT_TYPES:
        this.service = this.documentTypesService;
        break;
    }
    await this.reloadAllOptions();
  }

  public onOptionSelected = (option: ISelectOption): void => {
    this.isLoading.set(true);
    this.formGroup.controls["tags"].setValue(null);
    this.selectedIds.push(option.value.toString());
    this.setSelectedIds.emit(this.selectedIds);
    this.setAvailableAndSelectedOptions();
    this.isLoading.set(false);
  };

  public onCreateNewOption = async (value: string): Promise<void> => {
    if (!value || typeof value !== "string" || !value.trim() || !this.canCreate) {
      return;
    }
    this.isLoading.set(true);

    this.formGroup.controls["tags"].setValue(null);
    value = value.trim();

    const payload: any = {};

    switch (this.type) {
      case TagSelectorTypeEnum.LOCATION_TYPES:
        payload.type = value;
        break;
      case TagSelectorTypeEnum.DOCUMENT_TYPES:
        payload.name = value;
        break;
    }

    await this.service
      .createOrUpdate(payload)
      .then(async (response: any) => {
        this.newTagCreated.emit();
        this.selectedIds.push(response.id);
        this.setSelectedIds.emit(this.selectedIds);
        this.allOptions.push({ label: value, value: response.id });
        this.setAvailableAndSelectedOptions();
      })
      .catch((error: HttpErrorResponse) => {
        this.notificationService.showError(error);
      })
      .finally(() => {
        this.isLoading.set(false);
      });
  };

  public onDelete = async (value: string): Promise<void> => {
    this.isLoading.set(true);
    const index = this.selectedIds.findIndex((s) => s === value);

    this.selectedIds.splice(index, 1);
    this.setSelectedIds.emit(this.selectedIds);
    this.setAvailableAndSelectedOptions();
    if (this.canDelete) {
      await this.service
        .delete(value)
        .then(async () => {
          const indexAllOptions = this.allOptions.findIndex((s) => s.value === value);

          this.allOptions.splice(indexAllOptions, 1);
          this.setAvailableAndSelectedOptions();
        })
        .catch(() => {
          console.log("Could not delete tag (in use)");
        });
    }
    this.isLoading.set(false);
  };

  getAllCountries() {
    this.allOptions = this.allCountriesOption;
    this.setAvailableAndSelectedOptions();
    this.isLoading.set(false);
  }

  async getAllLocationTypes() {
    await this.service
      .getAll()
      .then(async (response: ILocationType[]) => {
        this.allOptions = response
          .filter(
            (t) =>
              t.recordState === RecordStateEnum.ACTIVE ||
              (this.selectedIds?.length && this.selectedIds.includes(t.id)),
          )
          .map((t) => ({ label: t.type, value: t.id }));
        this.setAvailableAndSelectedOptions();
        this.isLoading.set(false);
      })
      .catch((error) => {
        this.notificationService.showError(error);
      });
  }

  private setAvailableAndSelectedOptions = (): void => {
    this.availableOptions = this.allOptions.filter(
      (o) => !this.selectedIds.some((s) => s === o.value),
    );
    this.selectedOptions = this.allOptions.filter((o) =>
      this.selectedIds.some((s) => s === o.value),
    );
  };

  private reloadAllOptions = async (): Promise<void> => {
    this.isLoading.set(true);
    this.allOptions = [];
    this.availableOptions = [];
    this.selectedOptions = [];
    try {
      switch (this.type) {
        case TagSelectorTypeEnum.LOCATION_TYPES:
          await this.getAllLocationTypes();
          break;
        case TagSelectorTypeEnum.COUNTRIES:
          this.getAllCountries();
          break;
        case TagSelectorTypeEnum.DOCUMENT_TYPES:
          this.getAllDocumentTypes();
          break;
        case TagSelectorTypeEnum.LOCATIONS:
          this.getAllLocations();
          break;
      }
    } catch (error) {
      this.notificationService.showError(error);
    }
  };

  getAllLocations() {
    this.allOptions = this.allLocationsOption;
    this.setAvailableAndSelectedOptions();
    this.isLoading.set(false);
  }

  resetAvailableLocations(availableLocations: ISelectOption[]) {
    if (this.type === TagSelectorTypeEnum.LOCATIONS) {
      this.allOptions = [...availableLocations];
      this.availableOptions = this.allOptions.filter(
        (o) => !this.selectedIds.includes(o.value as string),
      );
      this.selectedOptions = this.allOptions.filter((o) =>
        this.selectedIds.includes(o.value as string),
      );
    }
  }

  private async getAllDocumentTypes() {
    const documentTypes: IDocumentType[] = await this.service.getAll();

    this.allOptions = documentTypes
      .filter(
        (t) =>
          t.recordState === RecordStateEnum.ACTIVE ||
          (this.selectedIds?.length && this.selectedIds.includes(t.id)),
      )
      .map((t) => ({ label: t.name, value: t.id }));
    this.setAvailableAndSelectedOptions();
    this.isLoading.set(false);
  }
}
