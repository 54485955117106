import { Injectable, QueryList, signal } from "@angular/core";

import { BehaviorSubject, Observable } from "rxjs";

import { StepDirective } from "@design-makeover/components/stepper/step.directive";

@Injectable({
  providedIn: "root",
})
export class StepperService {
  public steps: QueryList<StepDirective>;

  public currentStepIndex = signal<number>(null);

  private currentStepSubject: BehaviorSubject<StepDirective>;

  constructor() {}

  setSteps(steps: QueryList<StepDirective>): void {
    this.steps = steps;
    this.currentStepIndex.set(0);
    this.currentStepSubject = new BehaviorSubject<StepDirective>(this.steps.first);
  }

  getCurrentStep$(): Observable<StepDirective> {
    return this.currentStepSubject.asObservable();
  }

  goToNextStep(): void {
    if (this.currentStepIndex() < this.steps.length - 1) {
      for (let i = 0; i <= this.currentStepIndex(); i++) {
        this.steps.toArray()[i].navigated = true;
      }

      this.currentStepIndex.set(this.currentStepIndex() + 1);
      const nextStep = this.steps.toArray()[this.currentStepIndex()];

      this.currentStepSubject.next(nextStep);
    }
  }

  goToPreviousStep(): void {
    if (this.currentStepIndex() > 0) {
      this.currentStepIndex.set(this.currentStepIndex() - 1);
      const previousStep = this.steps.toArray()[this.currentStepIndex()];

      this.currentStepSubject.next(previousStep);
    }
  }
}
