<h2 mat-dialog-title>Add document</h2>
@if (isLoading()) {
  <app-loader containerClass="on-dialog"></app-loader>
} @else {
  <form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
    <mat-dialog-content>
      <iov-input
        [autofocus]="!isLoading()"
        formControlName="name"
        [suffix]="'.' + fileExtension"
        label="Name"
      ></iov-input>
      <iov-input-select
        [options]="data?.documentTypesOptions"
        [disabled]="!data?.documentTypesOptions.length"
        formControlName="type"
        label="Type"
        [addOnWrite]="true"
      ></iov-input-select>
    </mat-dialog-content>

    <mat-dialog-actions>
      <iov-button-group>
        <iov-button (pressed)="onClose()" type="ghost">Cancel</iov-button>
        <iov-button accessibilityRole="submit">
          <mat-icon icon-left>check</mat-icon>
          Save
        </iov-button>
      </iov-button-group>
    </mat-dialog-actions>
  </form>
}
