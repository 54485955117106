import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from "@angular/core";

import { Observable } from "rxjs";
import { IAvailableOrganisation, ISidebarMenuItem, IUserData } from "src/app/shared/interfaces";

import { AuthUserTypeEnum, RoutingEnum } from "@shared/enums";
import { AuthenticationService } from "@shared/services";
import { OrganisationService } from "@shared/services/feature/organisation/organisation.service";
import { CommonUtils } from "@shared/utils";

import { SidebarMenuService } from "./sidebar-menu.service";

@Component({
  selector: "iov-sidebar-menu",
  templateUrl: "./sidebar-menu.component.html",
  styleUrls: ["./sidebar-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarMenuComponent implements OnChanges {
  @Input() isCrossOrgSharingEnabled: boolean;

  @Input() isAccountOwnerOrAdminOrContributor: boolean;

  @Input() isSharedUser: boolean;

  @Input() isAdminMenuEnabled: boolean;

  @Input() canAddModifyEntities: boolean;

  @Input() userType: AuthUserTypeEnum;

  @Input() userName: string;

  @Input() pendingInvitationsCount: number;

  @Input() newInboundSharesCount: number;

  @Input() currentUrl: string;

  @Input() currentOrganisation: IAvailableOrganisation;

  @Input() userData: IUserData;

  public sidebarCollapsedState$: Observable<boolean> = this.sidebarMenuService.menuCollapsedState$;

  public isBackToInitialSharedPageVisible = false;

  public readonly authUserTypeEnum = AuthUserTypeEnum;

  private readonly adminMenuItem: ISidebarMenuItem = {
    title: "Admin",
    route: `/${RoutingEnum.ADMIN}`,
    icon: "admin",
    isActiveRouteExact: true,
    isDisabledFn: () => !this.isAdminMenuEnabled,
  };

  public readonly systemAdminMenuItems: ISidebarMenuItem[] = [
    {
      title: "Organisations",
      route: `/${RoutingEnum.ADMIN_ORGANISATIONS}`,
      icon: "organisations",
    },
    {
      title: "Users",
      route: `/${RoutingEnum.ADMIN_USERS}`,
      icon: "users",
    },
    this.adminMenuItem,
  ];

  public readonly menuItems: ISidebarMenuItem[] = [
    {
      title: "Locations",
      route: `/${RoutingEnum.LOCATIONS}`,
      icon: "locations",
    },
    {
      title: "Organisations",
      route: `/${RoutingEnum.ORGANISATIONS}`,
      icon: "organisations",
    },
    {
      title: "Supply chains",
      route: `/${RoutingEnum.SUPPLY_CHAINS}`,
      icon: "supply-chain",
    },
    {
      title: "Certificates",
      route: `/${RoutingEnum.CERTIFICATES}`,
      icon: "certificates",
    },
    {
      title: "Documents",
      route: `/${RoutingEnum.DOCUMENTS}`,
      icon: "document",
    },
    {
      title: "Items",
      route: `/${RoutingEnum.ITEMS}`,
      icon: "items",
    },
    {
      title: "Deliveries",
      route: `/${RoutingEnum.DELIVERIES}`,
      icon: "deliveries",
    },
    {
      title: "Processes",
      route: `/${RoutingEnum.PROCESSES}`,
      icon: "processes",
    },
    {
      title: "Inbox",
      route: `/${RoutingEnum.INBOX}`,
      icon: "inbox-menu",
      badgeFn: () => this.newInboundSharesCount,
      isDisabledFn: () =>
        !this.isCrossOrgSharingEnabled || !this.isAccountOwnerOrAdminOrContributor,
    },
    this.adminMenuItem,
  ];

  constructor(
    private sidebarMenuService: SidebarMenuService,
    private organisationService: OrganisationService,
    private authenticationService: AuthenticationService,
  ) {}

  get hasOrganisationOptions(): boolean {
    return !!this.userData?.availableOrganisations?.length;
  }

  get urlStartsWithInvitations(): boolean {
    return this.currentUrl.startsWith("/invitations");
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges["currentUrl"]) {
      if (this.isSharedUser) {
        const mainShareLinkRoute = `/${this.authenticationService.getMainShareLinkRoute()}`;

        this.isBackToInitialSharedPageVisible =
          this.currentUrl !== mainShareLinkRoute &&
          !CommonUtils.isSameUrlPathButOnly1ParamHasChanged(
            this.currentUrl,
            mainShareLinkRoute,
            "tab",
          );
      } else {
        this.isBackToInitialSharedPageVisible = false;
      }
    }
  }

  public onBackToInitialSharedPage = async (): Promise<void> => {
    await this.authenticationService.goToMainShareLink();
  };

  public isActiveRoute = (url: RoutingEnum, isExact?: boolean): boolean =>
    isExact ? this.currentUrl === url : this.currentUrl.startsWith(url);

  onToggleMenuCollapse(): void {
    this.sidebarMenuService.toggleMenu({ persist: true });
  }

  createOrganisation(): void {
    this.organisationService.createOrganisation();
  }
}
