import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";

import { AnalysesTableService } from "@components/shared/tables/analyses-table/analyses-table.service";
import { RoutingEnum } from "@shared/enums";

@Component({
  templateUrl: "./settings-orbify.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AnalysesTableService],
})
export class SettingsOrbifyComponent implements OnInit {
  public readonly routingEnum = RoutingEnum;

  constructor(private analysesTableService: AnalysesTableService) {}

  public async ngOnInit(): Promise<void> {
    this.analysesTableService.setLocationId(undefined);
    this.analysesTableService.setColumnDefs();
    await this.analysesTableService.getAll();
  }
}
