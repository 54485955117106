import { inject, Inject, Injectable } from "@angular/core";

import { BehaviorSubject, lastValueFrom, Observable } from "rxjs";

import { CommonConstants } from "@shared/constants";
import { AttachmentTypeEnum, InboundShareStatusEnum } from "@shared/enums";
import {
  getInboundCertificatesByIdsGraphQLQuery,
  getInboundDocumentsByIdsGraphQLQuery,
  getInboundLocationsByIdsGraphQLQuery,
  getInboundSupplyChainGraphQLQuery,
  getInboundConnectionsByIdsGraphQLQuery,
  getInboundDeliveriesByIdsGraphQLQuery,
} from "@shared/queries";
import { APP_CONFIG } from "@shared/tokens";
import { FormUtils } from "@shared/utils";

import { ApiService } from "./api.service";
import { GraphService } from "./graph.service";
import {
  IAttachment,
  ICertificateExtended,
  ICertificateGraphQLResponse,
  ICheckExistenceRecord,
  IConfig,
  IDocumentExtended,
  IInboundMapping,
  IInboundShare,
  IDocumentByIdsGraphQLResponse,
  ILocationExtended,
  IOutboundShare,
  ILocationGraphQLResponse,
  IConnectionGraphQLResponse,
  IConnectionExtended,
  ISupplyChainGraphQl,
  IDeliveryExtended,
  IDeliveryGraphQLResponse,
} from "../../interfaces";
import { AuthenticationService } from "../authentication.service";

@Injectable({
  providedIn: "root",
})
export class RecordSharingService {
  private newInboundSharesCountSubject: BehaviorSubject<number>;

  private graphService = inject(GraphService);

  public newInboundSharesCountObservable$: Observable<number>;

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {
    this.newInboundSharesCountSubject = new BehaviorSubject<number>(0);
    this.newInboundSharesCountObservable$ = this.newInboundSharesCountSubject.asObservable();
  }

  public get baseUrl(): string {
    return `${this.environment.baseUrl}organisations/${this.authenticationService.getActiveOrganisationId()}/record-sharing`;
  }

  public getAllOutboundShares = async (): Promise<IOutboundShare[]> => {
    return await this.apiService
      .get<IOutboundShare[]>(`${this.baseUrl}/outbound/shares`)
      .toPromise();
  };

  public getAllInboundShares = async (
    status: InboundShareStatusEnum = undefined,
    hasRootRecord = true,
  ): Promise<IInboundShare[]> => {
    let url = `${this.baseUrl}/inbound/shares?${FormUtils.addUrlParams({ status })}`;

    if (hasRootRecord) {
      url += `${url.endsWith("?") ? "" : "&"}hasRootRecord=true`;
    }
    const allInboundShares = await this.apiService.get<IInboundShare[]>(url).toPromise();

    this.newInboundSharesCountSubject.next(
      allInboundShares.filter((i) => i.status === InboundShareStatusEnum.NEW)?.length ?? 0,
    );

    return allInboundShares;
  };

  public getInboundShare = async (id: string): Promise<IInboundShare> => {
    return await this.apiService
      .get<IInboundShare>(`${this.baseUrl}/inbound/shares/${id}`)
      .toPromise();
  };

  public getShareStructure = async (
    recordUri: string,
    crossOrgShare: boolean = false,
  ): Promise<string[]> => {
    let url = `${this.environment.baseUrl}organisations/${this.authenticationService.getActiveOrganisationId()}/sharing/share-structure?record=${recordUri}`;

    if (crossOrgShare) {
      url += `&crossOrgShare=true`;
    }

    return await lastValueFrom(this.apiService.get<string[]>(url));
  };

  public createOutboundShare = async (payload: {
    receiverUri: string;
    rootRecordUri: string;
    recordUris: string[];
  }): Promise<any> =>
    await lastValueFrom(this.apiService.post<any>(`${this.baseUrl}/outbound/shares`, payload));

  public deleteInboundShare = async (id: string): Promise<void> =>
    await this.apiService.delete<void>(`${this.baseUrl}/inbound/shares/${id}`).toPromise();

  public setInboundShareStatus = async (
    payload: { status: InboundShareStatusEnum },
    id: string,
  ): Promise<void> =>
    await this.apiService
      .put<void>(`${this.baseUrl}/inbound/shares/meta/${id}`, payload)
      .toPromise();

  public getSharedRecord = async (
    externalOrganisationId: string,
    entityType: string,
    entityId: string,
  ): Promise<any> => {
    return await this.apiService
      .get<any>(
        `${this.baseUrl}/inbound/records/${externalOrganisationId}/${entityType}/${entityId}`,
      )
      .toPromise();
  };

  public getCheckExistingRecord = async (record: string): Promise<ICheckExistenceRecord> => {
    return await this.apiService
      .post<ICheckExistenceRecord>(
        `${this.baseUrl}/inbound/records/check-existence?${FormUtils.addUrlParams({ record })}`,
      )
      .toPromise();
  };

  public acceptInboundShare = async (shareId: string): Promise<void> =>
    await this.apiService
      .post<void>(`${this.baseUrl}/inbound/shares/${shareId}/accept`)
      .toPromise();

  public addInboundMapping = async (
    inboundUri: string,
    localUri: string,
  ): Promise<IInboundMapping> =>
    await this.apiService
      .post<IInboundMapping>(`${this.baseUrl}/inbound/mappings`, { inboundUri, localUri })
      .toPromise();

  public getInboundMapping = async (
    inboundRecordUri: string,
    localRecordUri: string,
  ): Promise<void> =>
    await this.apiService
      .get<void>(
        `${this.baseUrl}/record-sharing/inbound/mappings?${FormUtils.addUrlParams({ inboundRecordUri, localRecordUri })}`,
      )
      .toPromise();

  public removeInboundMapping = async (mappingId: string): Promise<void> =>
    await this.apiService.delete<void>(`${this.baseUrl}/inbound/mappings/${mappingId}`).toPromise();

  public getAllMappings = async (): Promise<IInboundMapping[]> =>
    await this.apiService.get<IInboundMapping[]>(`${this.baseUrl}/inbound/mappings`).toPromise();

  public async getInboundCertificatesByIdsGraphQL(
    ids: string[],
    senderId: string,
    first: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    include: string[] = [],
  ): Promise<ICertificateExtended[]> {
    if (!ids.length) {
      return [];
    }
    const result: ICertificateExtended[] = [];
    let cursor: string | undefined = undefined;
    let hasNextPage: boolean = true;
    const activeOrganisationId = this.authenticationService.getActiveOrganisationId();

    while (hasNextPage) {
      const query = getInboundCertificatesByIdsGraphQLQuery(
        activeOrganisationId,
        senderId,
        ids,
        first,
        null,
        cursor,
        null,
        include,
      );
      const { content, pageInfo, totalCount } = await this.graphService.fetchSinglePage<
        ICertificateGraphQLResponse,
        ICertificateExtended
      >("inboundLoadCertificatesByIds", query);

      result.push(...content);

      cursor = pageInfo.endCursor;
      hasNextPage = result.length < totalCount;
    }

    return result;
  }

  public async getInboundDocumentsByIdsGraphQL(
    ids: string[],
    senderId: string,
    first: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    include: string[] = [],
  ): Promise<IDocumentExtended[]> {
    if (!ids.length) {
      return [];
    }
    const result: IDocumentExtended[] = [];
    let cursor: string | undefined = undefined;
    let hasNextPage: boolean = true;
    const activeOrganisationId = this.authenticationService.getActiveOrganisationId();

    while (hasNextPage) {
      const query = getInboundDocumentsByIdsGraphQLQuery(
        activeOrganisationId,
        senderId,
        ids,
        first,
        null,
        cursor,
        null,
        include,
      );
      const { content, pageInfo, totalCount } = await this.graphService.fetchSinglePage<
        IDocumentByIdsGraphQLResponse,
        IDocumentExtended
      >("inboundLoadDocumentsByIds", query);

      result.push(...content);

      cursor = pageInfo.endCursor;
      hasNextPage = result.length < totalCount;
    }

    return result;
  }

  public async getInboundLocationsByIdsGraphQL(
    ids: string[],
    senderId: string,
    first: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    include: string[] = [],
  ): Promise<ILocationExtended[]> {
    if (!ids.length) {
      return [];
    }
    const result: ILocationExtended[] = [];
    let cursor: string | undefined = undefined;
    let hasNextPage: boolean = true;
    const activeOrganisationId = this.authenticationService.getActiveOrganisationId();

    while (hasNextPage) {
      const query = getInboundLocationsByIdsGraphQLQuery(
        activeOrganisationId,
        senderId,
        ids,
        first,
        null,
        cursor,
        null,
        include,
      );
      const { content, pageInfo, totalCount } = await this.graphService.fetchSinglePage<
        ILocationGraphQLResponse,
        ILocationExtended
      >("inboundLoadLocationsByIds", query);

      result.push(...content);

      cursor = pageInfo.endCursor;
      hasNextPage = result.length < totalCount;
    }

    return result;
  }

  public async getInboundDeliveriesByIdsGraphQL(
    ids: string[],
    senderId: string,
    first: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    include: string[] = [],
  ): Promise<IDeliveryExtended[]> {
    if (!ids.length) {
      return [];
    }
    const result: IDeliveryExtended[] = [];
    let cursor: string | undefined = undefined;
    let hasNextPage: boolean = true;
    const activeOrganisationId = this.authenticationService.getActiveOrganisationId();

    while (hasNextPage) {
      const query = getInboundDeliveriesByIdsGraphQLQuery(
        activeOrganisationId,
        senderId,
        ids,
        first,
        null,
        cursor,
        null,
        include,
      );
      const { content, pageInfo, totalCount } = await this.graphService.fetchSinglePage<
        IDeliveryGraphQLResponse,
        IDeliveryExtended
      >("inboundLoadDeliveriesByIds", query);

      result.push(...content);

      cursor = pageInfo.endCursor;
      hasNextPage = result.length < totalCount;
    }

    return result;
  }

  public async getInboundConnectionsByIdsGraphQL(
    ids: string[],
    senderId: string,
    first: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    include: string[] = [],
  ): Promise<IConnectionExtended[]> {
    if (!ids.length) {
      return [];
    }
    const result: IConnectionExtended[] = [];
    let cursor: string | undefined = undefined;
    let hasNextPage: boolean = true;
    const activeOrganisationId = this.authenticationService.getActiveOrganisationId();

    while (hasNextPage) {
      const query = getInboundConnectionsByIdsGraphQLQuery(
        activeOrganisationId,
        senderId,
        ids,
        first,
        null,
        cursor,
        null,
        include,
      );
      const { content, pageInfo, totalCount } = await this.graphService.fetchSinglePage<
        IConnectionGraphQLResponse,
        IConnectionExtended
      >("inboundLoadConnectionsByIds", query);

      result.push(...content);

      cursor = pageInfo.endCursor;
      hasNextPage = result.length < totalCount;
    }

    return result;
  }

  public async getInbountAttachments(
    senderId: string,
    attachmentType: AttachmentTypeEnum,
    attachmentUri: string,
    targetUri: string = null,
  ): Promise<IAttachment[]> {
    return await this.apiService
      .get<
        IAttachment[]
      >(`${this.baseUrl}/inbound/records/${senderId}/attachments?${FormUtils.addUrlParams({ attachmentType: attachmentType ? attachmentType.toUpperCase() : undefined, attachmentUri, targetUri })}`)
      .toPromise();
  }

  public async getInboundSupplyChainGraphQl(
    senderId: string,
    id: string,
    includes: string[] = [],
  ): Promise<ISupplyChainGraphQl> {
    const query = getInboundSupplyChainGraphQLQuery(
      this.authenticationService.getActiveOrganisationId(),
      senderId,
      id,
      includes,
    );
    const response = await lastValueFrom(
      this.graphService.query<{ inboundLoadSupplyChain: ISupplyChainGraphQl }>({
        query,
      }),
    );

    return response.inboundLoadSupplyChain;
  }
}
