<div class="slide-over-content">
  <div class="container-space-between">
    <app-page-title [title]="title"></app-page-title>
  </div>

  @if (isLoading()) {
    <app-loader></app-loader>
  } @else {
    <div class="right-side-panel-container" cdkDropListGroup>
      <div class="content" [formGroup]="formGroup">
        <mat-accordion>
          <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>{{ mainInformationText }}</mat-panel-title>
            </mat-expansion-panel-header>
            <h5 class="date-title">
              Date {{ type === "input" ? "added" : "produced" }}
              <mat-icon
                class="info-icon"
                [tp]="
                  type === 'input'
                    ? 'Specify the exact date or a period when this input has been added'
                    : 'Specify the exact date or a period when this output has been produced'
                "
              >
                help
              </mat-icon>
            </h5>

            <iov-radio-button-group>
              <iov-radio-button
                (changeEvent)="onChangeDatesType(dateTypeEnum.EXACT)"
                [checked]="datesType === 'EXACT'"
                value="EXACT"
                label="Exact date"
              ></iov-radio-button>
              &nbsp;
              <iov-radio-button
                (changeEvent)="onChangeDatesType(dateTypeEnum.RANGE)"
                [checked]="datesType === 'RANGE'"
                value="RANGE"
                label="Range (from/to)"
              ></iov-radio-button>
            </iov-radio-button-group>
            <br />
            <br />
            <div class="row-container">
              <div class="row row-1">
                <div class="item">
                  @if (datesType === dateTypeEnum.EXACT) {
                    <iov-datepicker #datePicker formControlName="date"></iov-datepicker>
                  }

                  @if (datesType === dateTypeEnum.RANGE) {
                    <iov-daterangepicker
                      class="no-padding-top-input"
                      #datePicker
                      formControlName="rangeDate"
                    ></iov-daterangepicker>
                  }
                </div>
              </div>
              <div class="row row-1">
                <iov-input label="Note" formControlName="note"></iov-input>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion>
          <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>Quantity summary</mat-panel-title>
            </mat-expansion-panel-header>

            <div *ngIf="!selectedItems()?.length" class="text-center empty-quantity-summary">
              Once you add {{ type }}s to this process, a summary of their quantities will show up
              here.
            </div>
            <app-quantity-summary-table
              #summaryTable
              *ngIf="selectedItems()?.length"
              class="no-min-height"
              [selectedItems]="selectedItems()"
              [allProducts]="allProducts"
              [allMaterials]="allMaterials"
              [allUnitOfMeasurements]="allUnitOfMeasurements"
              [isPaginatorEnabled]="false"
            ></app-quantity-summary-table>
          </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion>
          <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>Items</mat-panel-title>
            </mat-expansion-panel-header>

            <iov-drag-drop-container
              [itemType]="'items'"
              (itemDropped)="onItemDropped($event)"
            ></iov-drag-drop-container>
            <app-add-process-selected-items
              [type]="type"
              [processLocation]="processLocation"
              [items]="selectedItems()"
              [allProducts]="allProducts"
              [allMaterials]="allMaterials"
              [allUnitOfMeasurements]="allUnitOfMeasurements"
              (selectedQuantityChanged)="onSelectedQuantityChanged($event)"
              (remove)="onRemove($event)"
            ></app-add-process-selected-items>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <iov-right-side-panel
        itemType="Item"
        itemTypePlural="Items"
        [isLoadingItems]="isLoadingItems()"
        [availableItems]="availableItems()"
        (pressAdd)="onAddNewItem()"
        (pressAddAll)="onAddAll()"
        (pressRefresh)="onReloadItems()"
        (searchEvent)="onSearchAvailable($event)"
      >
        <div
          class="items-container"
          cdkDropList
          [cdkDropListSortingDisabled]="true"
          [cdkDropListData]="availableItems()"
        >
          @for (item of availableItems(); track item.id) {
            <iov-draggable-card (pressed)="onAdd(item.id)" [item]="item">
              <iov-item-card-content [item]="item"></iov-item-card-content>
            </iov-draggable-card>
          }
        </div>
      </iov-right-side-panel>
    </div>
  }
</div>
<div class="slide-over-buttons">
  <iov-button-group [visible]="!isLoading()">
    <iov-button (pressed)="onCancel()" type="ghost">Cancel</iov-button>
    <iov-button (pressed)="onSave()">
      <mat-icon icon-left>check</mat-icon>
      Save
    </iov-button>
  </iov-button-group>
</div>
