<iov-slide-overlay-header
  [canShare]="true"
  (share)="confirmShareDialog()"
  [canSend]="isCrossOrgSharingEnabled && isAccountOwnerOrAdminOrContributor"
  (send)="confirmSendDialog()"
  [title]="title()"
  icon="locations"
></iov-slide-overlay-header>
<iov-slide-overlay-content #slideOverlayContent>
  <ng-template
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="menu-info"
    overlay-menu-title="Location details"
  >
    <iov-form-field-group [name]="mainInformationText">
      <app-location-overlay-main-information
        #informationView
        (reloadOrganisations)="reloadOrganisations($event)"
        [countryOptions]="allCountries"
        [formGroup]="formGroup"
        [isEditing]="isEditing()"
        [organisationOptions]="organisationOptions"
      ></app-location-overlay-main-information>
    </iov-form-field-group>
    <iov-form-field-group name="Address">
      <app-location-overlay-address-checkbox
        [allOrganisations]="allOrganisations"
        [countryOptions]="allCountries"
        [formGroup]="formGroup"
        group-name-content
      ></app-location-overlay-address-checkbox>

      <app-location-overlay-address
        [countryOptions]="allCountries"
        [formGroup]="formGroup"
      ></app-location-overlay-address>
    </iov-form-field-group>
    @if (visibleCustomFields?.length) {
      <iov-form-field-group [name]="additionalInformationText">
        <app-custom-fields-edit
          [columns]="1"
          [elementCustomFields]="element?.customFields"
          [formGroup]="formGroup"
          [enableViewMode]="true"
          [visibleCustomFields]="visibleCustomFields"
        ></app-custom-fields-edit>
      </iov-form-field-group>
    }
  </ng-template>
  <ng-template
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.GPS_DATA).isEnabled"
    [overlay-menu-indicator]="gpsIndicator"
    [overlay-menu-title]="overlayTabEnum.GPS_DATA"
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="map"
  >
    <app-location-overlay-gps
      #locationGpsView
      [formGroup]="formGroup"
      [isEditing]="isEditing()"
    ></app-location-overlay-gps>
  </ng-template>
  <ng-template #gpsIndicator>
    @if (formGroup?.controls["geoLocation"]?.value) {
      <div class="gps-indicator"></div>
    }
  </ng-template>
  <ng-template
    *ngIf="!menuItems().get(overlayTabEnum.LINKED_LOCATIONS).isHidden"
    [overlay-menu-buttons-template]="linkedController"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.LINKED_LOCATIONS).isEnabled"
    [overlay-menu-indicator]="locationOverlay.linkedLocationCounter()"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="overlayTabEnum.LINKED_LOCATIONS"
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="linked-locations"
  >
    <app-location-overlay-links
      #linkedLocationView
      (changes)="onHasSaved()"
      [allCountries]="allCountries"
      [allLocationTypes]="allLocationTypes"
      [isReadOnly]="isRecordArchived"
      [locationId]="recordId"
    ></app-location-overlay-links>
  </ng-template>
  <ng-template #linkedController>
    @if (!overlay.contentRightSidePanel() && isRecordActive && canAddModifyEntities) {
      <iov-button
        (pressed)="overlay.openSideMenu(linkedLocationView?.linkedSideMenu)"
        [disabled]="overlay.loading()"
        type="ghost"
      >
        <mat-icon class="action-icon" icon-left>add</mat-icon>
        Add
      </iov-button>
    }
  </ng-template>
  <ng-template
    [overlay-menu-buttons-template]="certificateController"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.CERTIFICATES).isEnabled"
    [overlay-menu-indicator]="locationOverlay.certificateCounter()"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="overlayTabEnum.CERTIFICATES"
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="certificates"
  >
    <app-overlay-certificate-attachments
      #certificateView
      (changes)="onHasSaved()"
      [attachmentTargetId]="element?.id"
      [attachmentTargetType]="attachmentTargetEnum.LOCATION"
      [isReadOnly]="isRecordArchived"
      [overlayService]="locationOverlay"
    ></app-overlay-certificate-attachments>
  </ng-template>
  <ng-template #certificateController>
    @if (!overlay.contentRightSidePanel() && isRecordActive && canAddModifyEntities) {
      <iov-button
        (pressed)="overlay.openSideMenu(certificateView?.attachmentsRightMenu)"
        [disabled]="overlay.loading()"
        type="ghost"
      >
        <mat-icon class="action-icon" icon-left>add</mat-icon>
        Add
      </iov-button>
    }
  </ng-template>
  <ng-template
    [overlay-menu-buttons-template]="documentController"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.DOCUMENTS).isEnabled"
    [overlay-menu-indicator]="documentLinkedCounter"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="overlayTabEnum.DOCUMENTS"
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="document"
  >
    <app-overlay-document-attachments
      #documentView
      (changes)="onHasSaved()"
      [attachmentTargetId]="element?.id"
      [attachmentTargetType]="attachmentTargetEnum.LOCATION"
      [isReadOnly]="isRecordArchived"
      [overlayService]="locationOverlay"
    ></app-overlay-document-attachments>
  </ng-template>
  <ng-template #documentController>
    @if (!overlay.contentRightSidePanel() && isRecordActive && canAddModifyEntities) {
      <iov-button
        (pressed)="overlay.openSideMenu(documentView?.attachmentsRightMenu)"
        [disabled]="overlay.loading()"
        type="ghost"
      >
        <mat-icon class="action-icon" icon-left>add</mat-icon>
        Add
      </iov-button>
    }
  </ng-template>
  <ng-template #documentLinkedCounter>
    @if (locationOverlay.documentCounter() === null) {
      <div class="share-indicator">
        <mat-icon class="rotate-animation loading-icon">cached</mat-icon>
      </div>
    } @else {
      @if (locationOverlay.hasMissingDocumentTypes()) {
        <div class="indicator">
          <mat-icon
            class="warn-icon"
            svgIcon="alert-triangle"
            tp="One or more documents are missing that are required by a ruleset(s)."
          />
        </div>
      }
      @if (locationOverlay.documentCounter()) {
        <div class="share-indicator">
          {{ locationOverlay.documentCounter() }}
        </div>
      }
    }
  </ng-template>
  <ng-template
    [overlay-menu-buttons-template]="analysisController"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.ANALYSES).isEnabled"
    [overlay-menu-indicator]="analysesTableService.analysisCounter()"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="overlayTabEnum.ANALYSES"
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="analysis"
  >
    <app-analyses-table
      #analysesTableView
      tableContainerClass="field-group-container"
    ></app-analyses-table>
  </ng-template>
  <ng-template #analysisController>
    @if (isRecordActive && canAddModifyEntities) {
      <iov-button (pressed)="analysesTableService.add()" type="ghost">
        <mat-icon class="action-icon" icon-left>add</mat-icon>
        Add
      </iov-button>
    }
  </ng-template>
  @if (!menuItems().get(overlayTabEnum.SHARES).isHidden && locationOverlay.shareCounter()) {
    <ng-template
      [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.SHARES).isEnabled"
      [overlay-menu-show-edit-button]="false"
      [overlay-menu-show-save-button]="false"
      [overlay-menu-title]="overlayTabEnum.SHARES"
      [overlay-menu-indicator]="locationOverlay.shareCounter()"
      iovSlideOverlayMenuItem
      overlay-menu-icon="share"
    >
      <article class="field-group-container">
        @if (isLoadingShares()) {
          <app-loader></app-loader>
        } @else {
          <app-shares-table
            [isInOverlay]="true"
            [shares]="locationOverlay.shares()"
            (deleted)="this.onShareDeleted()"
          ></app-shares-table>
        }
      </article>
    </ng-template>
  }
  <ng-template
    *ngIf="!menuItems().get(overlayTabEnum.COMMENTS).isHidden"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.COMMENTS).isEnabled"
    [overlay-menu-indicator]="locationOverlay.commentCounter()"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="overlayTabEnum.COMMENTS"
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="comment"
  >
    <article class="field-group-container">
      <app-comments
        (added)="onCommentAdded(locationOverlay)"
        (deleted)="onCommentDeleted(locationOverlay)"
        [entityId]="element?.id"
        [entityType]="entityType"
      ></app-comments>
    </article>
  </ng-template>
</iov-slide-overlay-content>
