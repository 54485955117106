@if (formValueChanges$ | async) {}
@if (riskLevelValueChanges$ | async) {}
@if (indicatorAdded$ | async) {}
@if (indicatorRemoved$ | async) {}

<iov-slide-overlay-header [canDelete]="true" [title]="title()" [isSvgIcon]="false" icon="book_2" />

<iov-slide-overlay-content #slideOverlayContent>
  <ng-template
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="menu-info"
    overlay-menu-title="Template details"
  >
    <iov-form-field-group [name]="mainInformationText">
      <div [formGroup]="form" class="full-width">
        <iov-input enableViewMode formControlName="name" label="Name" />

        <div [ngClass]="{ 'form-field-item': overlay.editMode() }">
          <iov-editable-div
            class="bordered"
            placeholder="Type your description here…"
            formControlName="description"
            label="Description"
            [isTextarea]="true"
            [isResizable]="true"
            [isOptional]="true"
            [enableViewMode]="overlay.viewMode()"
          />
        </div>

        <iov-input-select
          formControlName="riskLevelSet"
          label="Risk level set"
          [options]="riskLevelSetOptions()"
          [enableViewMode]="overlay.viewMode()"
        />
        <div class="tags-container flex-wrap">
          @for (riskLevel of elementRiskLevels(); track riskLevel.title) {
            <div
              class="tag-chip"
              [ngClass]="riskLevel.color"
              [tp]="riskLevel.title.length > MAX_CHIPS_TEXT_LENGTH_TO_SHOW ? riskLevel.title : ''"
              [tpIsLazy]="true"
            >
              {{ riskLevel.title }}
            </div>
          }
        </div>
      </div>
    </iov-form-field-group>
  </ng-template>

  <ng-template
    iovSlideOverlayMenuItem
    overlay-menu-icon="speed"
    [overlay-menu-title]="overlayTabEnum.INDICATORS"
    [overlay-menu-buttons-template]="indicators()?.controller()"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.INDICATORS).isEnabled"
    [overlay-menu-indicator]="indicatorsCount"
  >
    <app-risk-assessment-template-overlay-indicators [form]="form.controls.indicators" />
  </ng-template>
</iov-slide-overlay-content>
