export enum AttachmentTypeEnum {
  LOCATION = "Location",
  DOCUMENT = "Document",
  CERTIFICATE = "Certificate",
  ITEM = "Item",
  PROCESS = "Process",
  DELIVERY = "Delivery",
  PRODUCT = "Product",
  LINK = "Link",
  MATERIAL = "Material",
}
