import { ChangeDetectionStrategy, Component, input, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

import { ISelectOption } from "@shared/interfaces";

@Component({
  selector: "app-certificate-overlay-main-information",
  templateUrl: "./certificate-overlay-main-information.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CertificateOverlayMainInformationComponent {
  @Input() formGroup: FormGroup;

  standardOptions = input<ISelectOption[]>([]);

  standardTypeOptions = input<ISelectOption[]>([]);
}
