import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  input,
  signal,
  untracked,
} from "@angular/core";

import { ColDef } from "ag-grid-community";
import { Subscription } from "rxjs";
import {
  DateCellRendererComponent,
  LinkCellRendererComponent,
} from "src/app/shared/cell-renderers";
import { RecordStateEnum, TableEnum } from "src/app/shared/enums";
import {
  ILocationExtended,
  IProcess,
  IProcessExtended,
  IProcessType,
} from "src/app/shared/interfaces";
import { AuthenticationService, LocationsService, ProcessesService } from "src/app/shared/services";
import { ColumnUtils, CommonUtils } from "src/app/shared/utils";

import { SlideOverlayPageService } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.service";
import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { RouterService } from "@shared/services/router.service";

@Component({
  selector: "app-processes-table",
  templateUrl: "./processes-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcessesTableComponent {
  //todo use only IProcessExtended when GraphQL is used for reference tables
  processes = input<IProcessExtended[] | IProcess[]>(null);

  shouldOpenInNewTab = input<boolean>(false);

  allProcessTypes = input<IProcessType[]>(null);

  areButtonsEnabled = input<boolean>(true);

  isSearchEnabled = input<boolean>(true);

  isRecordStateFilterEnabled = input<boolean>(true);

  isPaginatorEnabled = input<boolean>(true);

  isFixedBottomPaginator = input<boolean>(false);

  recordState = input<RecordStateEnum>(RecordStateEnum.ALL);

  table = input<TableEnum>(TableEnum.PROCESSES);

  isSaveTableState = input<boolean>(false);

  columns = input<string[]>([
    "recordState",
    "processId",
    "type.name",
    "location.name",
    "firstInputDate",
    "lastOutputDate",
    "tags",
  ]);

  isLoading = signal<boolean>(true);

  rowData = signal<IProcessExtended[]>([]);

  private subscriptions = new Subscription();

  columnDefs = computed<ColDef[]>(() => this.setColumnDefs());

  constructor(
    private routerService: RouterService,
    private processesService: ProcessesService,
    private notificationService: NotificationService,
    private locationsService: LocationsService,
    private authenticationService: AuthenticationService,
    private overlay: SlideOverlayPageService,
  ) {
    this.subscriptions.add(
      this.overlay.refreshTable$.subscribe(() => {
        this.getAll();
      }),
    );

    effect(async () => {
      this.processes();
      this.columnDefs();
      untracked(() => {
        this.getAll();
      });
    });
  }

  public onClick = async (row: IProcess): Promise<void> => {
    if (!this.areButtonsEnabled) {
      return;
    }

    if (this.shouldOpenInNewTab()) {
      this.routerService.openNewTab(this.routerService.getProcessLink(row.id, false));
    } else {
      this.routerService.navigate(this.routerService.getProcessLink(row.id));
    }
  };

  private setColumnDefs = (): ColDef[] => {
    const columnDefs: ColDef[] = [
      ColumnUtils.recordState(),
      {
        headerName: "ID",
        field: "processId",
        lockVisible: true,
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteIdParam: "id",
          linkRouteFn: this.routerService.getProcessLink,
          openInNewTab: this.shouldOpenInNewTab(),
        },
        suppressSizeToFit: true,
      },
      { headerName: "Type", field: "type.name" },
      {
        headerName: "Location",
        field: "location.name",
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteIdParam: "location.id",
          linkRouteFn: this.routerService.getLocationLink,
          openInNewTab: this.shouldOpenInNewTab(),
        },
      },
      {
        headerName: "First input date",
        field: "firstInputDate",
        cellRenderer: DateCellRendererComponent,
      },
      {
        headerName: "Last output date",
        field: "lastOutputDate",
        cellRenderer: DateCellRendererComponent,
      },
    ];

    if (this.authenticationService.isRegularUser()) {
      columnDefs.push(ColumnUtils.tags("Tags"));
    }

    return CommonUtils.getVisibleColumnDefs(columnDefs, this.columns());
  };

  private getParsedRowData = async (processes: any): Promise<void> => {
    const allProcessTypes = this.allProcessTypes();

    for (let process of processes) {
      if (typeof process?.location === "string") {
        const location = await CommonUtils.handleApiCall(
          this.locationsService.get(CommonUtils.getUriId(process.location)),
          "Failed to fetch location",
        );

        process.location = location as unknown as ILocationExtended;
        // It's ok like this because this will be refactored when we use graphql everywhere
      }
      if (typeof process?.type === "string") {
        process = CommonUtils.getElementsWithType<IProcessExtended>(
          allProcessTypes,
          process,
        ) as IProcessExtended;
      }
    }
  };

  private getAll = async (): Promise<void> => {
    const processes = this.processes();
    const columnDefs = this.columnDefs();

    await untracked(async () => {
      this.isLoading.set(true);
      if (processes) {
        await this.getParsedRowData(processes);
        this.rowData.set(processes as IProcessExtended[]);
      } else {
        try {
          const rowData = await this.processesService.getAllGraphQL(
            undefined,
            undefined,
            columnDefs.some((c) => c.field === "tags") ? ["TAGS"] : undefined,
          );

          this.rowData.set(rowData);
        } catch (error) {
          this.notificationService.showError(error);
        }
      }
      this.isLoading.set(false);
    });
  };
}
