import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Input,
  QueryList,
  TemplateRef,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { SlideOverlayMenuItemDirective } from "@design-makeover/components/overlay/slide-overlay-menu-item/slide-overlay-menu-item.directive";
import { SlideOverlayPageService } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.service";
import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { CommonConstants } from "@shared/constants";
import { FeatureFlagEnum, OverlayTabEnum } from "@shared/enums";
import { AuthenticationService, FeatureFlagService } from "@shared/services";
import { RouterService } from "@shared/services/router.service";
import { CommonUtils } from "@shared/utils";

@Component({
  selector: "iov-slide-overlay-content",
  templateUrl: "./slide-overlay-content.component.html",
  styleUrls: ["./slide-overlay-content.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SlideOverlayContentComponent implements AfterViewInit {
  @ContentChildren(SlideOverlayMenuItemDirective)
  menuItems: QueryList<SlideOverlayMenuItemDirective> = new QueryList();

  selectedContent: TemplateRef<unknown>;

  selectedMenuItem: SlideOverlayMenuItemDirective;

  @Input() contentClass: string;

  protected readonly TemplateRef = TemplateRef;

  public readonly canAddModifyEntities = this.authenticationService.canAddModifyEntities();

  public readonly canExpandMenu = this.featureFlagService.isEnabled(
    FeatureFlagEnum.OVERLAY_NAVIGATION_EXPANSION,
  );

  constructor(
    protected overlay: SlideOverlayPageService,
    private changeDetectorRef: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private routerService: RouterService,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private featureFlagService: FeatureFlagService,
  ) {}

  async ngAfterViewInit(): Promise<void> {
    const idParam = this.route.snapshot.queryParams["id"];
    const tabParam = this.route.snapshot.queryParams["tab"];

    if (!idParam || !tabParam) {
      await this.selectDefaultMenuItem();
    } else {
      const tabTitle = CommonUtils.enumToText(tabParam);

      if (!this.doesMenuItemExist(tabTitle) && tabTitle !== OverlayTabEnum.SHARES) {
        await this.selectDefaultMenuItem();
      }
    }
  }

  private doesMenuItemExist = (title: string): boolean => {
    if (!title) {
      return false;
    }

    title = title.toLowerCase();

    return this.menuItems.some((m) => m.title().toLowerCase() === title);
  };

  async selectDefaultMenuItem(): Promise<void> {
    await this.selectMenuItem(this.menuItems.first, false);
  }

  async selectMenuItem(
    menuItem: SlideOverlayMenuItemDirective,
    disableEditMode: boolean = true,
  ): Promise<void> {
    if (this.authenticationService.haveTokensExpired()) {
      this.notificationService.showError(CommonConstants.SESSION_HAS_EXPIRED_TEXT);
      this.overlay.close(true);
      this.authenticationService.logout();

      return;
    }

    let canChangeMenuItem: boolean;

    if (menuItem.disabled() || menuItem.active()) {
      return;
    }

    if (disableEditMode) {
      canChangeMenuItem = await this.overlay.enableViewMode();
    } else {
      canChangeMenuItem = true;
    }

    if (canChangeMenuItem) {
      this.menuItems.toArray().forEach((item) => item.active.set(false));
      menuItem.active.set(true);

      this.overlay.closeSideMenu();
      this.selectedMenuItem = menuItem;
      this.selectedContent = menuItem.templateRef;

      const isFirstMenuItemSelected = this.menuItems.toArray()[0].active();

      const queryParams = {
        tab: isFirstMenuItemSelected ? null : CommonUtils.textToEnum(menuItem.title()),
      };

      this.routerService.updateCurrentUrlParams(queryParams);

      this.changeDetectorRef.detectChanges();
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }
}
