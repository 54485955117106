import { ComponentRef, inject, Injectable, signal } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { BehaviorSubject, Observable } from "rxjs";

import { BulkAddSlideOverClass } from "@components/shared/bulk-add-slide-over/bulk-add-slide-over.class";
import { BulkAddSlideOverModel as Model } from "@components/shared/bulk-add-slide-over/bulk-add-slide-over.model";
import { RouterService } from "@shared/services/router.service";

@Injectable({
  providedIn: "root",
})
export class BulkAddSlideOverService {
  componentRef: ComponentRef<BulkAddSlideOverClass>;

  canDeactivateInProgress = signal<boolean>(false);

  private route: ActivatedRoute = inject(ActivatedRoute);

  private routerService: RouterService = inject(RouterService);

  private bulkAddSlideOverSubject: BehaviorSubject<{ bulkAdd: Model.ResourceTypeEnum }> =
    new BehaviorSubject(null);

  public toggle(bulkAddResouceType: Model.ResourceTypeEnum): void {
    this.bulkAddSlideOverSubject.next({ bulkAdd: bulkAddResouceType });
  }

  public forceClose(): void {
    this.bulkAddSlideOverSubject.next(null);
  }

  get trigger$(): Observable<{ bulkAdd: Model.ResourceTypeEnum }> {
    return this.bulkAddSlideOverSubject.asObservable();
  }

  assignComponentRef(componentRef: ComponentRef<BulkAddSlideOverClass>): void {
    this.componentRef = componentRef;
  }

  async componentCanDeactivate(): Promise<boolean> {
    this.canDeactivateInProgress.set(true);

    const canClose = await this.canClose();

    if (canClose) {
      this.canDeactivateInProgress.set(false);
      const params = this.route.snapshot.queryParams;

      this.toggle(params["bulkAdd"]);
    } else {
      this.canDeactivateInProgress.set(true);
    }

    return canClose;
  }

  async canClose(): Promise<boolean> {
    let canClose = true;

    if (this.componentRef && typeof this.componentRef?.instance.canClose === "function") {
      canClose = await this.componentRef?.instance.canClose();
    }

    return canClose;
  }

  public closeSlideOver(): void {
    this.routerService.closeViewOutlet();
  }
}
