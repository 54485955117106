import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  signal,
} from "@angular/core";

import { AttachmentTargetEnum, AttachmentTypeEnum } from "src/app/shared/enums";
import { IAttachment } from "src/app/shared/interfaces";
import {
  AttachmentsService,
  AuthenticationService,
  LocationsService,
  ConnectionsService,
} from "src/app/shared/services";
import { CommonUtils } from "src/app/shared/utils";

import { CardContentTypeEnum } from "@design-makeover/components/cards/card-content/card-content.model";

@Component({
  selector: "app-location-list-items",
  templateUrl: "./location-list-items.component.html",
  styleUrls: ["./location-list-items.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationListItemsComponent implements OnChanges {
  @Input()
  public items: any[];

  @Input()
  public allLocationTypes: any[] = [];

  @Input()
  public countryOptions: any[] = [];

  @Input()
  public propertyName: string;

  @Input()
  public isShowOrganisation = true;

  @Input()
  public loggedInOrganisationName: string = null;

  @Output()
  public remove: EventEmitter<string> = new EventEmitter();

  readonly cardContentTypeEnum = CardContentTypeEnum;

  public isLoading = signal(true);

  public parsedItems: any[] = [];

  private activeOrganisationId: string = null;

  constructor(
    private locationsService: LocationsService,
    private connectionsService: ConnectionsService,
    private attachmentsService: AttachmentsService,
    private authenticationService: AuthenticationService,
  ) {
    this.activeOrganisationId = this.authenticationService.getActiveOrganisationId();
  }

  public async ngOnChanges(): Promise<void> {
    this.isLoading.set(true);
    this.parsedItems = [];
    if (this.items?.length && this.allLocationTypes?.length) {
      for (const item of this.items) {
        const locationId = CommonUtils.getUriId(item[this.propertyName]);
        let location = await this.locationsService.get(locationId);

        location = CommonUtils.getElementsWithCountryName(this.countryOptions, location);
        location = CommonUtils.getElementsWithLocationTypeNames(this.allLocationTypes, location);

        if (this.isShowOrganisation) {
          const organisationAttachment = await this.getOrganisationAttachment(locationId);

          if (organisationAttachment) {
            const organisationId = CommonUtils.getUriId(organisationAttachment.targetUri);

            if (organisationId === this.activeOrganisationId) {
              location.organisationName = this.loggedInOrganisationName;
            } else {
              const organisation = await this.connectionsService.get(organisationId);

              location.organisationName = organisation?.name;
            }
          }
        }
        this.parsedItems.push({ ...location, linkId: item.id });
      }
    }
    this.isLoading.set(false);
  }

  private getOrganisationAttachment = async (locationId: string): Promise<IAttachment> => {
    return await this.attachmentsService
      .getAll(
        AttachmentTypeEnum.LOCATION,
        null,
        `/organisations/${this.activeOrganisationId}/locations/${locationId}`,
      )
      .then((attachments: IAttachment[]) => {
        return CommonUtils.getTargetAttachment(
          attachments,
          AttachmentTargetEnum.ORGANISATION,
          AttachmentTypeEnum.LOCATION,
          locationId,
        );
      });
  };

  public onRemove = (linkId: string): void => {
    this.remove.emit(linkId);
  };
}
