import { Inject, Injectable } from "@angular/core";

import { Observable, lastValueFrom } from "rxjs";

import { APP_CONFIG } from "@shared/tokens";

import { ApiService } from "./api.service";
import { CommonConstants } from "../../constants";
import { RecordStateEnum, ResourceTypeEnum } from "../../enums";
import {
  IConfig,
  IPageableContent,
  IRecordPayload,
  IRecordResponse,
  IRecordState,
  IRuleset,
} from "../../interfaces";
import { FormUtils } from "../../utils";
import { AuthenticationService } from "../authentication.service";

@Injectable({
  providedIn: "root",
})
export class RulesetsService {
  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {}

  private getBaseUrl = (): string =>
    `${this.environment.baseUrl}organisations/${this.authenticationService.getActiveOrganisationId()}/rulesets`;

  public getPage = async (
    resourceType: ResourceTypeEnum,
    name: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
    recordState: RecordStateEnum = undefined,
  ): Promise<IPageableContent<IRuleset>> => {
    const url = `${this.getBaseUrl()}?${FormUtils.addUrlParams({ resourceType, name, size, page, sort, recordState })}`;

    return await lastValueFrom(this.apiService.get<IPageableContent<IRuleset>>(url));
  };

  public getPageSubscription = (
    resourceType: ResourceTypeEnum,
    name: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
    recordState: RecordStateEnum = undefined,
  ): Observable<IPageableContent<IRuleset>> => {
    const url = `${this.getBaseUrl()}?${FormUtils.addUrlParams({ resourceType, name, size, page, sort, recordState })}`;

    return this.apiService.get<IPageableContent<IRuleset>>(url);
  };

  public async getAll(
    resourceType: ResourceTypeEnum,
    recordState: RecordStateEnum = undefined,
  ): Promise<IRuleset[]> {
    const result: IRuleset[] = [];
    let page = 0;
    let hasNextPage: boolean = true;

    while (hasNextPage) {
      const {
        content,
        page: { totalElements },
      } = await this.getPage(
        resourceType,
        undefined,
        CommonConstants.MAX_API_GET_ITEMS_SIZE,
        page,
        undefined,
        recordState,
      );

      result.push(...content);

      page++;
      hasNextPage = result.length < totalElements;
    }

    return result;
  }

  public get = async (rulesetId: string): Promise<IRuleset> =>
    await lastValueFrom(this.apiService.get<IRuleset>(`${this.getBaseUrl()}/${rulesetId}`));

  public delete = async (id: string): Promise<void> =>
    await this.apiService.delete<void>(`${this.getBaseUrl()}/${id}`).toPromise();

  public createOrUpdate = async (payload: IRuleset, id?: string): Promise<IRuleset> => {
    if (id) {
      return await lastValueFrom(
        this.apiService.put<IRuleset>(`${this.getBaseUrl()}/${id}`, payload),
      );
    } else {
      return await lastValueFrom(this.apiService.post<IRuleset>(`${this.getBaseUrl()}`, payload));
    }
  };

  public setActiveState = async (payload: { active: boolean }, id: string): Promise<IRuleset> =>
    await lastValueFrom(
      this.apiService.put<IRuleset>(`${this.getBaseUrl()}/${id}/status`, payload),
    );

  public setRecordState = async (payload: IRecordState, id: string): Promise<IRuleset> =>
    await lastValueFrom(this.apiService.put<IRuleset>(`${this.getBaseUrl()}/${id}`, payload));

  public getInstantRulesetRecords = async (recordUri: string): Promise<IRecordResponse[]> =>
    await lastValueFrom(
      this.apiService.get<IRecordResponse[]>(
        `${this.getBaseUrl()}/query/records/instant?record=${recordUri}`,
      ),
    );

  public getRulesetBasedOnRecord = async (payload: IRecordPayload): Promise<IRecordResponse[]> =>
    await lastValueFrom(
      this.apiService.post<IRecordResponse[]>(`${this.getBaseUrl()}/query/records`, payload),
    );
}
