import { AfterViewInit, ChangeDetectionStrategy, Component, Input, signal } from "@angular/core";

import { ColDef } from "ag-grid-community";
import { AttachmentTargetEnum, TableEnum } from "src/app/shared/enums";
import { IAttachment } from "src/app/shared/interfaces";
import {
  AuthenticationService,
  CertificatesService,
  ConnectionsService,
  DeliveriesService,
  ItemsService,
  LocationsService,
  OrganisationsService,
  ProcessesService,
  ProductsService,
} from "src/app/shared/services";
import { CommonUtils } from "src/app/shared/utils";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { LinkCellRendererComponent } from "@shared/cell-renderers";
import { RouterService } from "@shared/services/router.service";

@Component({
  selector: "app-attachments-table",
  templateUrl: "./attachments-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentsTableComponent implements AfterViewInit {
  @Input()
  public attachments: IAttachment[] = null;

  @Input()
  public isInboundShared = false;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public table = TableEnum.ATTACHMENTS;

  @Input()
  public isSaveTableState = false;

  @Input()
  public allowRowClick = true;

  public isLoading = signal(true);

  public rowData: IAttachment[] = [];

  public columnDefs = signal<ColDef[]>([
    { headerName: "Type", field: "targetType" },
    {
      headerName: "ID / Name",
      field: "targetName",
      lockVisible: true,
      cellRenderer: LinkCellRendererComponent,
      cellRendererParams: {
        linkRouteIdParam: "targetId",
        linkRouteFn: "targetFn",
      },
    },
  ]);

  constructor(
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private locationsService: LocationsService,
    private certificatesService: CertificatesService,
    private itemsService: ItemsService,
    private organisationsService: OrganisationsService,
    private connectionsService: ConnectionsService,
    private deliveriesService: DeliveriesService,
    private processesService: ProcessesService,
    private productsService: ProductsService,
    private routerService: RouterService,
  ) {}

  public async ngAfterViewInit() {
    await this.getAll();
  }

  public onViewDetails = async (row: IAttachment): Promise<void> => {
    if (!this.allowRowClick) {
      return;
    }

    const getLinkFn = row.targetFn;

    this.routerService.navigate(getLinkFn(row.targetId));
  };

  private populateAttachmentDetails = async (
    attachments: IAttachment[],
  ): Promise<IAttachment[]> => {
    return await Promise.all(
      attachments?.map(async (attachment) => {
        const { targetType, entityId, targetFn } = this.getTargetTypeAndId(attachment.targetUri);
        const targetName = await this.getEntityName(entityId, targetType);

        return { ...attachment, targetType, targetId: entityId, targetName, targetFn };
      }),
    );
  };

  private getEntityName = async (id: string, targetType: AttachmentTargetEnum): Promise<string> => {
    let name: string;

    try {
      switch (targetType) {
        case AttachmentTargetEnum.CERTIFICATE:
          name = (await this.certificatesService.get(id)).number;
          break;
        case AttachmentTargetEnum.LOCATION:
          name = (await this.locationsService.get(id)).name;
          break;
        case AttachmentTargetEnum.ITEM:
          name = (await this.itemsService.get(id)).itemId;
          break;
        case AttachmentTargetEnum.PROCESS:
          name = (await this.processesService.get(id)).processId;
          break;
        case AttachmentTargetEnum.PRODUCT:
          name = (await this.productsService.get(id)).name;
          break;
        case AttachmentTargetEnum.ORGANISATION: {
          const service =
            this.authenticationService.getActiveOrganisationId() === id
              ? this.organisationsService
              : this.connectionsService;

          name = (await service.get(id)).name;
          break;
        }
        case AttachmentTargetEnum.DELIVERY:
          name = (await this.deliveriesService.get(id)).deliveryId;
          break;
      }
    } catch (error) {
      this.notificationService.showError(error);
    }

    return name;
  };

  private getTargetTypeAndId = (
    targetUri: string,
  ): {
    targetType: AttachmentTargetEnum;
    entityId: string;
    targetFn;
  } => {
    let targetType: AttachmentTargetEnum;
    let targetFn;
    const entityId = CommonUtils.getUriId(targetUri);

    if (targetUri.includes("connections")) {
      targetType = AttachmentTargetEnum.ORGANISATION;
      targetFn = this.routerService.getOrganisationLink;
    } else if (targetUri.includes("locations")) {
      targetType = AttachmentTargetEnum.LOCATION;
      targetFn = this.routerService.getLocationLink;
    } else if (targetUri.includes("certificates")) {
      targetType = AttachmentTargetEnum.CERTIFICATE;
      targetFn = this.routerService.getCertificateLink;
    } else if (targetUri.includes("deliveries")) {
      targetType = AttachmentTargetEnum.DELIVERY;
      targetFn = this.routerService.getDeliveryLink;
    } else if (targetUri.includes("items")) {
      targetType = AttachmentTargetEnum.ITEM;
      targetFn = this.routerService.getItemLink;
    } else if (targetUri.includes("processes")) {
      targetType = AttachmentTargetEnum.PROCESS;
      targetFn = this.routerService.getProcessLink;
    } else if (targetUri.includes("products")) {
      targetType = AttachmentTargetEnum.PRODUCT;
      targetFn = this.routerService.getProductLink;
    } else if (targetUri.includes("organisations")) {
      targetType = AttachmentTargetEnum.ORGANISATION;
      targetFn = this.routerService.getOrganisationLink;
    }

    return { targetType, targetFn, entityId };
  };

  private getAll = async (): Promise<void> => {
    this.isLoading.set(true);
    this.rowData = this.isInboundShared
      ? this.attachments
      : await this.populateAttachmentDetails(this.attachments);
    this.isLoading.set(false);
  };
}
