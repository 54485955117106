import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  signal,
} from "@angular/core";

import { ColDef } from "ag-grid-community";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { DateCellRendererComponent } from "@shared/cell-renderers";
import { InvitationStatusEnum, TableEnum } from "@shared/enums";
import { IInvitation } from "@shared/interfaces";
import { AuthenticationService, InvitationsService } from "@shared/services";
import { CellRendererUtils, ColumnUtils } from "@shared/utils";

@Component({
  selector: "app-invitations-table",
  templateUrl: "./invitations-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvitationsTableComponent implements AfterViewInit {
  @Input()
  public invitations: IInvitation[] = null;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  @Output()
  public tryAccept: EventEmitter<IInvitation> = new EventEmitter();

  @Output()
  public tryReject: EventEmitter<IInvitation> = new EventEmitter();

  @Input() public table = TableEnum.INVITATIONS;

  @Input() public isSaveTableState = false;

  public isLoading = signal(true);

  public rowData: IInvitation[] = [];

  public columnDefs = signal<ColDef[]>([]);

  constructor(
    private invitationsService: InvitationsService,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService,
  ) {}

  public async ngAfterViewInit() {
    this.setColumnDefs();
    await this.getAll();
  }

  private setColumnDefs = (): void => {
    const columnDefs: ColDef[] = [
      { headerName: "Organisation", field: "orgName", lockVisible: true },
      { headerName: "Role", field: "role", cellRenderer: CellRendererUtils.roleNames },
      { headerName: "Date", field: "recordedTime", cellRenderer: DateCellRendererComponent },
    ];

    if (this.areButtonsEnabled) {
      const buttons = [
        {
          onClick: (row) => this.tryAccept.emit(row),
          tooltip: "Accept invitation",
          icon: "check_circle",
        },
        {
          onClick: (row) => this.tryReject.emit(row),
          tooltip: "Reject invitation",
          icon: "block",
        },
      ];

      columnDefs.push(ColumnUtils.buttons(buttons));
    }
    this.columnDefs.set(columnDefs);
  };

  public getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    if (this.invitations) {
      this.rowData = this.invitations;
      this.isLoading.set(false);
    } else {
      try {
        this.rowData = await this.invitationsService.getAll(
          this.authenticationService.getUserEmail(),
          undefined,
          InvitationStatusEnum.PENDING,
        );
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };
}
