import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Router } from "@angular/router";

import { ResourceTypeEnum, RoutingEnum } from "src/app/shared/enums";
import { AuthenticationService } from "src/app/shared/services";

@Component({
  templateUrl: "./rulesets.component.html",
  styleUrl: "./rulesets.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RulesetsComponent {
  public readonly userCanAddOrModifyRulesets = this.authenticationService.canAddOrModifyRulesets;

  public readonly resourceTypeEnum = ResourceTypeEnum;

  public readonly routingEnum = RoutingEnum;

  public selectedTabIndex = 0;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {
    const selectedTabResourceType =
      this.router.getCurrentNavigation()?.extras?.state?.["selectedTabResourceType"];

    if (selectedTabResourceType) {
      switch (selectedTabResourceType) {
        case this.resourceTypeEnum.LOCATION:
          this.selectedTabIndex = 0;
          break;
        case this.resourceTypeEnum.DELIVERY:
          this.selectedTabIndex = 1;
          break;
      }
    }
  }

  onAdd = async (): Promise<void> => {
    switch (this.selectedTabIndex) {
      case 0:
        await this.router.navigateByUrl(`${RoutingEnum.ADMIN_RULESETS_ADD_LOCATION}`);
        break;
      case 1:
        await this.router.navigateByUrl(`${RoutingEnum.ADMIN_RULESETS_ADD_DELIVERY}`);
        break;
    }
  };
}
