import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

import { ReportsEudrModel } from "@components/reports/eudr/reports-eudr.model";

@Component({
  selector: "app-reports-eudr-material-info",
  templateUrl: "./reports-eudr-material-info.component.html",
  styleUrls: ["./reports-eudr-material-info.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportsEudrMaterialInfoComponent {
  @Input() index: number;

  @Input() formGroup: FormGroup;

  public readonly reportsEudrModel: typeof ReportsEudrModel = ReportsEudrModel;
}
