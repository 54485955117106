import {
  customUnitOfMeasurementFields,
  idAndNameFields,
  unitOfMeasurementFields,
} from "./queries.constants";
import { QueriesUtils } from "./queries.utils";
import { IProductGraphQLFilter } from "../interfaces";

/**
 * Generates a GraphQL query to load products based on specified parameters.
 *
 * @param {string} orgId - (Required) The organization ID for which products are queried.
 * @param {IProductGraphQLFilter} filter - An object containing filtering criteria for products.
 * @param {number} first - The number of products to retrieve in a single page (for forward pagination).
 * @param {number} last - The number of products to retrieve in a single page (for backward pagination).
 * @param {string} after - The cursor indicating the end of the previous page for forward pagination.
 * @param {string} before - The cursor indicating the start of the next page for backward pagination.
 * @param {string[]} include - Additional fields to include in the query.
 * @returns {string} The generated GraphQL query for loading products.
 */

export const getProductsGraphQLQuery = (
  orgId: string,
  filter: IProductGraphQLFilter,
  first: number,
  last: number,
  after: string,
  before: string,
  include: string[],
): string => {
  const filterClause = QueriesUtils.generateFilterClause(filter);
  const afterCursorClause = first > 0 ? QueriesUtils.generateClause("after", after) : "";
  const firstClause = first > 0 ? QueriesUtils.generateClause("first", first) : "";
  const beforeCursorClause = last > 0 ? QueriesUtils.generateClause("before", before) : "";
  const lastClause = last > 0 ? QueriesUtils.generateClause("last", last) : "";

  return `
{
    loadProducts(   
    orgId: "${orgId}",
    ${filterClause ? `${filterClause},` : ""}
    ${afterCursorClause ? `${afterCursorClause},` : ""}
    ${beforeCursorClause ? `${beforeCursorClause},` : ""}
    ${firstClause ? `${firstClause},` : ""}
    ${lastClause ? `${lastClause},` : ""}
) {
        ${getProductProperties(include)}  
    }
}
`;
};

/**
 * Generates a GraphQL query to load products based on specified parameters.
 *
 * @param {string} orgId - (Required) The organization ID for which products are queried.
 * @param {string[]} ids - (Required) A list of products ids
 * @param {number} first - The number of products to retrieve in a single page (for forward pagination).
 * @param {number} last - The number of products to retrieve in a single page (for backward pagination).
 * @param {string} after - The cursor indicating the end of the previous page for forward pagination.
 * @param {string} before - The cursor indicating the start of the next page for backward pagination.
 * @param {string[]} include - Additional fields to include in the query.
 * @returns {string} The generated GraphQL query for loading products.
 */

export const getProductsByIdsGraphQLQuery = (
  orgId: string,
  ids: string[],
  first: number,
  last: number,
  after: string,
  before: string,
  include: string[],
): string => {
  const afterCursorClause = first > 0 ? QueriesUtils.generateClause("after", after) : "";
  const firstClause = first > 0 ? QueriesUtils.generateClause("first", first) : "";
  const beforeCursorClause = last > 0 ? QueriesUtils.generateClause("before", before) : "";
  const lastClause = last > 0 ? QueriesUtils.generateClause("last", last) : "";

  return `
  {
      loadProductsByIds(   
      orgId: "${orgId}",
      ids: ${QueriesUtils.getParsedIds(ids)},
      ${afterCursorClause ? `${afterCursorClause},` : ""}
      ${beforeCursorClause ? `${beforeCursorClause},` : ""}
      ${firstClause ? `${firstClause},` : ""}
      ${lastClause ? `${lastClause},` : ""}
  ) {
          ${getProductProperties(include)}      
      }
  }
  `;
};

const getProductProperties = (include: string[]): string => `
        pageInfo {
            hasNextPage 
            hasPreviousPage
            startCursor
            endCursor
        }
        totalCount
        edges {
            cursor
            node {
                ${idAndNameFields}
                unitOfMeasurement {
                    ${unitOfMeasurementFields}                
                }
                defaultCustomUnit { 
                    ${customUnitOfMeasurementFields}
                }
                recordState
                ${QueriesUtils.getQueryIncludes(include)}
            }            
        } 
`;
