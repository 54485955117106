import { ComponentType } from "@angular/cdk/overlay";
import { Injectable } from "@angular/core";

import { BulkAddItemsComponent } from "@components/items";
import { BulkAddSlideOverClass } from "@components/shared/bulk-add-slide-over/bulk-add-slide-over.class";
import { RoutingEnum } from "@shared/enums";

@Injectable({
  providedIn: "root",
})
export class BulkAddSlideOverBridgeService {
  getComponent(bulkAddResourceType: string): ComponentType<BulkAddSlideOverClass> | null {
    switch (bulkAddResourceType) {
      case RoutingEnum.ITEMS:
        return BulkAddItemsComponent;
      default:
        return null;
    }
  }
}
