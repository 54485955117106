import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { HttpErrorResponse } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";

import { cloneDeep, isEqual } from "lodash";
import { debounceTime, Subject } from "rxjs";
import { CommonConstants } from "src/app/shared/constants";
import { ConfirmDialogResponseEnum, RecordStateEnum, RoutingEnum } from "src/app/shared/enums";
import {
  IDocument,
  ILocation,
  ILocationExtended,
  ILocationLink,
  ILocationLinkDetail,
  ISelectOption,
  ISupplyChain,
  ISupplyChainDetail,
  ISupplyChainLocation,
  ISupplyChainPayload,
} from "src/app/shared/interfaces";
import { LocationsService } from "src/app/shared/services";
import { CommonUtils, FormUtils } from "src/app/shared/utils";
import { CustomValidators } from "src/app/shared/validators";

import { CardContentTypeEnum } from "@design-makeover/components/cards/card-content/card-content.model";
import { SlideOverlayPageService } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.service";
import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { AddLocationDialogComponent } from "@components/organisations";

import { ConfirmDialogComponent } from "../../shared";
import { SupplyChainAbstractClass } from "../supply-chain-abstract.class";

@Component({
  templateUrl: "./edit-supply-chain.component.html",
  styleUrls: ["./edit-supply-chain.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditSupplyChainComponent extends SupplyChainAbstractClass {
  public formGroup: UntypedFormGroup = null;

  public isEditing = signal(false);

  public isLoadingAvailableLocations = signal(true);

  public isLoadingSelectedLocations = signal(true);

  public isReloadingSupplyChainFlowChart = signal(false);

  public availableLocations: ILocationExtended[] = [];

  public selectedLocations = signal<any[]>([]);

  public initialSelectedLocations: ILocationExtended[] = [];

  public element: ISupplyChainDetail = null;

  public readonly isZoomEnabled = true;

  public searchAvailableText: string = null;

  readonly cardContentTypeEnum = CardContentTypeEnum;

  private allLocations: ILocationExtended[] = [];

  private searchAvailableSubject = new Subject();

  private destroyRef = inject(DestroyRef);

  private hasSelectedLocationsChanged: boolean;

  public readonly mainInformationText = CommonConstants.MAIN_INFORMATION_TEXT;

  public allLocationLinks: ILocationLink[] = [];

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private locationsService: LocationsService,
    private dialog: MatDialog,
    private slideOverlayPageService: SlideOverlayPageService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();

    this.searchAvailableSubject
      .pipe(takeUntilDestroyed(), debounceTime(CommonConstants.DEBOUNCE_TIME_MS))
      .subscribe(async (searchAvailableText: string) => {
        this.searchAvailableText = searchAvailableText;
        await this.onReloadLocations();
      });
  }

  override async loadData(id: string): Promise<void> {
    this.isLoading.set(true);
    try {
      if (id) {
        this.element = await this.getSupplyChainDetail(id);
        if (this.element === null) {
          throw "Record not found";
        }
        this.locationLinks = this.element.links;

        if (this.element.recordState === RecordStateEnum.ARCHIVED) {
          this.notificationService.showError(CommonConstants.EDIT_ARCHIVED_RECORD_ERROR_TEXT);
          await this.router.navigate([`/${RoutingEnum.SUPPLY_CHAINS_DETAILS}/${this.element.id}`]);

          return;
        }
        const locations = this.element.locations;

        //Filter non selected & archived elements.
        for (const location of locations) {
          location.certificates = location.certificates.filter(
            (c: any) => c.recordState === RecordStateEnum.ACTIVE || c.isSelected,
          );
          location.documents = location.documents.filter(
            (c: any) => c.recordState === RecordStateEnum.ACTIVE || c.isSelected,
          );
        }
        this.selectedLocations.set(locations);
        this.initialSelectedLocations = cloneDeep(this.selectedLocations());
        this.setHasSelectedLocationsChanged();
        this.isEditing.set(true);
      }

      await Promise.all([
        (this.allLocationLinks = await this.getAllLinks()),
        this.onReloadLocations(),
      ]);

      this.setupForm();
      this.isLoadingAvailableLocations.set(false);
      this.isLoadingSelectedLocations.set(false);
      this.isLoading.set(false);
    } catch (error) {
      this.notificationService.showError(error);
    }
  }

  public override isSubmitButtonDisabled = (): boolean => {
    if (!this.isEditing()) {
      return false;
    }

    return (
      !this.formGroup ||
      this.formGroup.invalid ||
      this.formGroup.pending ||
      !(this.hasFormValuesChanged || this.hasSelectedLocationsChanged)
    );
  };

  public onCancel(): void {
    const route = this.isEditing()
      ? `/${RoutingEnum.SUPPLY_CHAINS_DETAILS}/${this.element.id}`
      : `/${RoutingEnum.SUPPLY_CHAINS}`;

    this.router.navigate([route]);
  }

  public onSave = async (isSaveOnly = true): Promise<void> => {
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);
      this.notificationService.showError(CommonConstants.FILL_REQUIRED_FIELDS_MSG);

      return;
    }

    this.isLoading.set(true);
    const payload = this.getSavePayload();

    await this.supplyChainsService
      .createOrUpdate(payload, this.element?.id)
      .then(async (response: ISupplyChain) => {
        this.hasFormValuesChanged = false;
        this.hasSelectedLocationsChanged = false;
        this.notificationService.showSuccess(
          `Supply chain ${this.isEditing() ? "modified" : "created"}`,
        );
        if (isSaveOnly) {
          if (this.isEditing()) {
            await this.reloadElement(this.element.id);
          } else {
            await this.router.navigate([`/${RoutingEnum.SUPPLY_CHAINS_EDIT}/${response.id}`], {
              replaceUrl: true,
            });
          }
        }
      })
      .catch((error: HttpErrorResponse) => {
        this.notificationService.showError(error);
      })
      .finally(() => {
        this.isLoading.set(false);
      });
  };

  public onReloadLocations = async (): Promise<void> => {
    this.isLoadingAvailableLocations.set(true);
    try {
      const locations = await this.locationsService.getAllGraphQL(undefined, undefined, [
        "CERTIFICATES",
        "DOCUMENTS",
      ]);

      this.allLocations = locations.map((l) => ({
        ...l,
        address: {
          ...l.address,
          countryName: this.countryOptions.find((c) => c.value === l.address.country)?.label,
        },
      }));
      this.availableLocations = this.getAvailableLocations();
      this.isLoadingAvailableLocations.set(false);
    } catch (error) {
      this.notificationService.showError(error);
    }
  };

  public onSearchAvailable = (text: string): void => {
    this.searchAvailableSubject.next(text);
  };

  public onItemDropped = (event: CdkDragDrop<ILocation>): void => {
    this.onAdd(event.item.data.id);
  };

  public onAddAll = (): void => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: "Add all confirmation",
        contentTranslatedText: `Are you sure you want to add all ${this.availableLocations.length} locations?`,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        this.isLoadingAvailableLocations.set(true);
        this.isLoadingSelectedLocations.set(true);

        while (this.availableLocations.length > 0) {
          this.onAdd(this.availableLocations[0].id, false);
        }
        this.setSelectedLocationsLinks();

        this.setHasSelectedLocationsChanged();

        this.changeDetectorRef.detectChanges();
        this.isLoadingAvailableLocations.set(false);
        this.isLoadingSelectedLocations.set(false);
      }
    });
  };

  public onAdd = (id: string, isSingleAdd = true): void => {
    if (isSingleAdd) {
      this.isLoadingAvailableLocations.set(true);
      this.isLoadingSelectedLocations.set(true);
    }

    const itemIndex = this.availableLocations.findIndex((i: ILocationExtended) => i.id === id);
    const item = this.availableLocations[itemIndex];

    this.setLocationsCertificatesAsSelected(item);
    this.setLocationsDocumentsAsSelected(item);

    this.selectedLocations.set([...this.selectedLocations(), item]);
    this.availableLocations.splice(itemIndex, 1);

    if (isSingleAdd) {
      this.setSelectedLocationsLinks();
      this.setHasSelectedLocationsChanged();
      this.changeDetectorRef.detectChanges();
      this.isLoadingAvailableLocations.set(false);
      this.isLoadingSelectedLocations.set(false);
    }
  };

  public onRemove = async (id: string): Promise<void> => {
    this.isLoadingAvailableLocations.set(true);
    this.isLoadingSelectedLocations.set(true);

    const itemIndex = this.selectedLocations().findIndex((i: any) => i.id === id);
    const item = this.selectedLocations()[itemIndex];

    this.locationLinks = this.locationLinks.filter(
      (link) => link.from.id !== id && link.to.id !== id,
    );

    for (const location of this.selectedLocations()) {
      location.links = location.links.filter((link) => link.from.id !== id && link.to.id !== id);
    }

    item.links = undefined;
    item.certificates = undefined;
    item.documents = undefined;
    this.availableLocations.push(item);
    const selectedLocations = [...this.selectedLocations()];

    selectedLocations.splice(itemIndex, 1);
    this.selectedLocations.set(selectedLocations);

    await this.onReloadLocations();
    this.setSelectedLocationsLinks();
    this.setHasSelectedLocationsChanged();

    this.isLoadingAvailableLocations.set(false);
    this.isLoadingSelectedLocations.set(false);
  };

  public onSelectedLocationsChanged = (locations: any[]): void => {
    this.isReloadingSupplyChainFlowChart.set(true);
    this.changeDetectorRef.detectChanges();
    this.selectedLocations.set([...locations]);
    this.setHasSelectedLocationsChanged();
    this.isReloadingSupplyChainFlowChart.set(false);
  };

  public onAddNewLocation = async (): Promise<void> => {
    const dialogResult = await this.slideOverlayPageService.openDialog<
      { hasSaved: boolean },
      { countryOptions: ISelectOption[] }
    >(AddLocationDialogComponent, {
      countryOptions: this.countryOptions,
    });

    if (dialogResult?.hasSaved) {
      this.onReloadLocations();
    }
  };

  private getSavePayload = (): ISupplyChainPayload => {
    const payload: ISupplyChainPayload = {
      id: this.element?.id ?? undefined,
      name: this.formGroup.controls["name"].value.trim(),
      description: this.formGroup.controls["description"].value
        ? this.formGroup.controls["description"].value.trim()
        : undefined,
    };

    const locations = [];
    const links = [];

    for (const selectedLocation of this.selectedLocations()) {
      const location: ISupplyChainLocation = {
        location: `/organisations/${this.activeOrganisation.id}/locations/${selectedLocation.id}`,
        certificates: [],
        documents: [],
      };

      for (const selectedCertificate of selectedLocation.certificates.filter((c) => c.isSelected)) {
        location.certificates.push(
          `/organisations/${this.activeOrganisation.id}/certificates/${selectedCertificate.id}`,
        );
      }
      for (const selectedDocument of selectedLocation.documents.filter((c) => c.isSelected)) {
        location.documents.push(
          `/organisations/${this.activeOrganisation.id}/documents/${selectedDocument.id}`,
        );
      }

      for (const locationLink of this.locationLinks) {
        links.push({
          locationFrom: `/organisations/${this.activeOrganisation.id}/locations/${locationLink.from.id}`,
          locationTo: `/organisations/${this.activeOrganisation.id}/locations/${locationLink.to.id}`,
        });
      }

      locations.push(location);
    }
    payload.links = links;
    payload.locations = locations;

    return payload;
  };

  private reloadElement = async (id: string): Promise<void> => {
    await this.loadData(id);
  };

  private setSelectedLocationsLinks = (): void => {
    const selectedLocationsIds = this.selectedLocations().map((s: any) => s.id);

    const newLinks: ILocationLinkDetail[] = [];

    for (const location of this.selectedLocations()) {
      location.links = [];

      for (const link of this.allLocationLinks) {
        const fromId = CommonUtils.getUriId(link.from);
        const toId = CommonUtils.getUriId(link.to);

        if (
          [fromId, toId].includes(location.id) &&
          ((fromId !== location.id && selectedLocationsIds.includes(fromId)) ||
            (toId !== location.id && selectedLocationsIds.includes(toId)))
        ) {
          const from = this.allLocations.find((l) => l.id === fromId);
          const to = this.allLocations.find((l) => l.id === toId);

          const newLink: ILocationLinkDetail = {
            from: {
              id: fromId,
              name: from?.name,
            },
            to: {
              id: toId,
              name: to?.name,
            },
          };

          const isDuplicateInLocation = location.links.some(
            (existingLink) =>
              existingLink.from.id === newLink.from.id && existingLink.to.id === newLink.to.id,
          );

          if (!isDuplicateInLocation) {
            location.links.push(newLink);
          }

          const isDuplicateInGlobal = this.locationLinks.some(
            (existingLink) =>
              existingLink.from.id === newLink.from.id && existingLink.to.id === newLink.to.id,
          );

          if (!isDuplicateInGlobal) {
            newLinks.push(newLink);
          }
        }
      }
    }

    this.locationLinks = [...this.locationLinks, ...newLinks];
  };

  private getAvailableLocations = (): ILocationExtended[] => {
    return this.allLocations.filter((l) => {
      // Do not display already added locations
      return (
        l.recordState === RecordStateEnum.ACTIVE &&
        !this.selectedLocations().some((i) => i.id === l.id) &&
        (!this.searchAvailableText ||
          l.name.toLowerCase().includes(this.searchAvailableText.toLowerCase()))
      );
    });
  };

  private setupForm = (): void => {
    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl(
        this.element?.name ?? null,
        [CustomValidators.required],
        [
          CustomValidators.entityAlreadyExists(
            this.supplyChainsService,
            this.element?.id ?? this.route.snapshot.params["id"] ?? null,
          ),
        ],
      ),
      description: new UntypedFormControl(this.element?.description ?? null),
    });
    this.initialFormValue = this.formGroup.value;
    this.hasFormValuesChanged = false;
    this.formGroup.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.hasFormValuesChanged =
        !this.formGroup.pristine && this.hasInitialFormValueChanged(this.formGroup.value);
    });
  };

  private setLocationsDocumentsAsSelected = (location: ILocationExtended): void => {
    location.documents = location.documents.map((d) => ({ ...d, isSelected: true }));
  };

  private setLocationsCertificatesAsSelected = (location: ILocationExtended): void => {
    location.certificates = location.certificates.map((c) => ({ ...c, isSelected: true }));
  };

  public setHasSelectedLocationsChanged = (): void => {
    const initialLocations = this.initialSelectedLocations?.map((loc: any) => {
      delete loc.selectedDocuments;
      delete loc.selectedCertificates;
      delete loc.allSelectedDocuments;
      delete loc.allSelectedCertificates;
      delete loc.filteredAndSelectedCertificates;
      delete loc.filteredAndSelectedDocuments;

      return loc;
    });
    let selectedLocations = cloneDeep(this.selectedLocations());

    selectedLocations = selectedLocations.map((loc) => {
      delete loc.selectedDocuments;
      delete loc.selectedCertificates;
      delete loc.allSelectedDocuments;
      delete loc.allSelectedCertificates;
      delete loc.filteredAndSelectedCertificates;
      delete loc.filteredAndSelectedDocuments;
      if (loc.documents?.length) {
        loc.documents.forEach((doc: IDocument) => {
          delete doc.canView;
          delete doc.typeName;
        });
      }

      return loc;
    });
    this.hasSelectedLocationsChanged = !isEqual(initialLocations, selectedLocations);
  };

  override requiresConfirmation(): boolean {
    return this.hasFormValuesChanged || this.hasSelectedLocationsChanged;
  }

  override saveBeforeClosing = async (): Promise<void> => await this.onSave(false);
}
