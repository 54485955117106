<h2 mat-dialog-title>Create new organisation</h2>
@if (isLoading()) {
  <app-loader></app-loader>
} @else {
  <form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
    <mat-dialog-content>
      <h4>{{ mainInformationText }}</h4>
      <iov-input [autofocus]="true" formControlName="name" label="Name"></iov-input>

      <iov-input-select
        [options]="countryOptions"
        formControlName="country"
        label="Country"
      ></iov-input-select>

      <iov-button-toggle formControlName="specifyAddress">Specify full address</iov-button-toggle>
      <br />
      <br />
      @if (isSpecifyAddress) {
        <iov-input formControlName="street" label="Address"></iov-input>

        <iov-input formControlName="region" label="Region"></iov-input>

        <iov-input formControlName="zipCode" label="ZIP / Postcode"></iov-input>
      }
    </mat-dialog-content>

    <mat-dialog-actions>
      <iov-button-group>
        <iov-button (pressed)="onClose()" type="ghost">Cancel</iov-button>
        <iov-button accessibilityRole="submit">
          <mat-icon icon-left>check</mat-icon>
          Create organisation
        </iov-button>
      </iov-button-group>
    </mat-dialog-actions>
  </form>
}
