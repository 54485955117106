<article class="field-group-container">
  <ng-container [formGroup]="formGroup">
    @if (overlay.editMode()) {
      <div class="row-container">
        @if (!hasGeoLocationValue) {
          <div class="map-type-selection">
            <iov-radio-button-group>
              <iov-radio-button
                (changeEvent)="mapDetailsType = 'coordinates'"
                [checked]="mapDetailsType === 'coordinates'"
                label="GPS coordinates"
                value="coordinates"
              ></iov-radio-button>
              &nbsp;
              <iov-radio-button
                (changeEvent)="mapDetailsType = 'upload-file'"
                [checked]="mapDetailsType === 'upload-file'"
                label="Upload file"
                tp="Only point and polygon features are accepted"
                tpPlacement="right"
                value="upload-file"
              ></iov-radio-button>
            </iov-radio-button-group>
          </div>
        }
        @if (mapDetailsType === "coordinates" && !hasGeoLocationValue) {
          <div class="location-gps-info">
            Please enter the coordinates in decimal degrees format, e.g. “42.141592”
          </div>
          <div class="row row-2">
            <div class="item">
              <iov-input
                formControlName="gpsX"
                label="GPS X (Latitude)"
                tooltip="Enter values between -90 and +90 degrees.
                        Use positive for north, negative for south.
                        Use up to 6 decimals for accuracy (e.g., 51.5074 for London).
                        Avoid spaces or special characters."
              ></iov-input>
            </div>
            <div class="item">
              <iov-input
                formControlName="gpsY"
                label="GPS Y (Longitude)"
                tooltip="Enter values between -180 and +180 degrees.
                        Use positive for east, negative for west.
                        Use up to 6 decimals for accuracy (e.g., -0.1278 for London).
                        Avoid spaces or special characters."
              ></iov-input>
            </div>
          </div>
        }
      </div>

      @if (mapDetailsType === "coordinates") {
        @if (!formGroup.controls["geoLocation"].value) {
          <div class="location-empty-info">Enter GPS coordinates to show them on the map.</div>
        }
      }

      @if (mapDetailsType === "upload-file") {
        @if (!hasGeoLocationValue) {
          <iov-upload-files-box
            footerLeftText="Upload a JSON (.json) file with geo data
                    in geojson format and it will be displayed on a map"
            [files]="[file]"
            [maxSizeInMb]="commonConstants.MAX_GEO_JSON_SIZE_IN_MB"
            [supportedFileTypes]="commonConstants.GEOJSON_UPLOAD_ALLOWED_FILE_EXTENSIONS"
            [validate]="true"
            [footerRightText]="uploadBoxFooterRightText"
            (filesSelected)="onFileSelected($event[0])"
          ></iov-upload-files-box>
          <br />
        }
      }
    }

    @if (hasGeoLocationValue) {
      <app-map
        (delete)="onDeleteMap()"
        [canDelete]="overlay.editMode()"
        [geoLocation]="formGroup.controls['geoLocation'].value"
        [locationName]="formGroup.controls['name'].value"
        [scrollWheelEnabled]="true"
      ></app-map>
    } @else if (!formGroup.controls["geoLocation"]?.value && overlay.viewMode()) {
      <p class="no-location-gps">This location has no GPS data.</p>
    }
  </ng-container>
</article>
