import { ClusterIconStyle, MapInfoWindow } from "@angular/google-maps";

import { IndividualMarker } from "src/app/components/shared/map/individual-marker";

import { IGeoLocation } from "@shared/interfaces";

export interface IWindowInfoExtended {
  clusterOrMarker: ICluster | IndividualMarker;
  infoWindow: MapInfoWindow;
}

export interface ICluster {
  getCenter(): google.maps.LatLng;
  getMarkers(): google.maps.Marker[];
}

export interface IGeographicalMapLocation {
  id: string;
  name: string;
  geoLocation: IGeoLocation;
  address?: {
    country?: string;
  };
}

export const CLUSTER_INFO_WINDOW_OFFSET_Y: number = -12;
export const MARKER_INFO_WINDOW_OFFSET_Y: number = -16;
export const ZOOM_BUTTONS_SELECTOR = ".gm-bundled-control .gm-control-active";
export const HYBRID_MODE = "hybrid";

export const CLUSTER_STYLES: ClusterIconStyle[] = [
  {
    textColor: "#000",
    anchorText: [3, -1.5],
    textSize: 12,
    height: 23,
    width: 23,
    url: "/assets/icons/map-cluster.svg",
  },
];

export const POLYLINE_OPTIONS: google.maps.PolylineOptions = {
  strokeWeight: 0.95,
  geodesic: true,
  icons: [
    {
      icon: {
        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        strokeWeight: 2,
        fillOpacity: 1,
        scale: 1.25,
      },
      offset: "55%",
    },
  ],
};

export const MAP_STYLES: google.maps.MapTypeStyle[] = [
  {
    featureType: "administrative",
    elementType: "all",
    stylers: [
      {
        saturation: "-100",
      },
    ],
  },
  {
    featureType: "administrative.province",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 65,
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry.fill",
    stylers: [
      {
        lightness: "0",
      },
      {
        saturation: "0",
      },
      {
        gamma: "1",
      },
      {
        color: "#f4f4f4",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "labels.text.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#737373",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: "50",
      },
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [
      {
        saturation: "-100",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "all",
    stylers: [
      {
        lightness: "30",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "all",
    stylers: [
      {
        lightness: "40",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      { invert_lightness: true },
      { hue: "#ff003b" },
      { saturation: -100 },
      { lightness: 100 } /* generates "white" color */,
    ],
  },
  {
    featureType: "water",
    elementType: "labels",
    stylers: [
      {
        lightness: -25,
      },
      {
        saturation: -100,
      },
    ],
  },
];

export const MAP_OPTIONS: google.maps.MapOptions = {
  mapTypeControl: true,
  minZoom: 2.3,
  streetViewControl: false,
  fullscreenControl: false,
  zoomControl: true,
  scrollwheel: true,
  rotateControl: false,
  styles: MAP_STYLES,
  mapTypeControlOptions: {
    position: google.maps.ControlPosition.BOTTOM_LEFT,
    mapTypeIds: ["hybrid", "roadmap"],
    style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
  },
};

export const MARKER_CLUSTERER_IMAGE_PATH: string =
  "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m";
