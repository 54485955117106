import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  signal,
  SimpleChanges,
} from "@angular/core";

import { ColDef } from "ag-grid-community";
import {
  BadgeLinkCellRendererComponent,
  LinkCellRendererComponent,
  UnitOfMeasurementCellRendererComponent,
} from "src/app/shared/cell-renderers";
import { CustomFieldsResourceTypeEnum, FeatureFlagEnum, TableEnum } from "src/app/shared/enums";
import {
  IBaseUnit,
  ICustomField,
  IItem,
  IMaterial,
  IProductExtended,
} from "src/app/shared/interfaces";
import { ColumnUtils, CommonUtils } from "src/app/shared/utils";

import { CustomFieldsService, FeatureFlagService } from "@shared/services";
import { RouterService } from "@shared/services/router.service";

@Component({
  selector: "app-quantity-summary-table",
  templateUrl: "./quantity-summary-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuantitySummaryTableComponent implements AfterViewInit, OnChanges {
  @Input()
  public class: string;

  @Input()
  public selectedItems: IItem[] = [];

  @Input()
  public allProducts: IProductExtended[] = [];

  @Input()
  public allMaterials: IMaterial[] = [];

  @Input()
  public allUnitOfMeasurements: IBaseUnit[] = [];

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = false;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public table = TableEnum.QUANTITY_SUMMARY;

  @Input()
  public isSaveTableState = false;

  @Input()
  public columns: string[] = ["productName", "materialNames", "totalQuantity"];

  public isLoading = signal(true);

  protected allCustomFields: ICustomField[] = [];

  public rowData: any[] = [];

  private readonly chipsOptions: Partial<ColDef> = {
    cellClass: "container-flex chips-constrained",
    autoHeight: true,
    maxWidth: 260,
  };

  private readonly isOldMaterialsEnabled = !this.featureFlagService.isEnabled(
    FeatureFlagEnum.NEW_MATERIALS_BEHAVIOUR,
  );

  public columnDefs = signal<ColDef[]>([]);

  constructor(
    private router: RouterService,
    private featureFlagService: FeatureFlagService,
    private customFieldsService: CustomFieldsService,
  ) {}

  public async ngAfterViewInit() {
    if (this.columns.includes("customFields")) {
      this.allCustomFields = await this.customFieldsService.getAll(
        CustomFieldsResourceTypeEnum.PRODUCT,
      );
    }

    const columnDefs: ColDef[] = [
      {
        headerName: "Total quantity",
        field: "totalQuantity",
        cellRenderer: UnitOfMeasurementCellRendererComponent,
        cellRendererParams: {
          precisionParam: "quantityPrecision",
          symbolParam: "quantitySymbol",
          formattedQuantityParam: "valueWithDefaultUnit",
        },
      },
    ];

    if (this.isOldMaterialsEnabled) {
      columnDefs.push({
        headerName: "Product",
        field: "productName",
        lockVisible: true,
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteFn: this.router.getProductLink,
          linkRouteIdParam: "productId",
        },
      });
      columnDefs.push(
        ColumnUtils.chips(
          "Materials",
          "materialData",
          { textParam: "name", linkRouteIdParam: "id", linkRouteFn: this.router.getMaterialLink },
          this.chipsOptions,
        ),
      );
    } else {
      columnDefs.push({
        headerName: "Product",
        field: "productName",
        cellClass: "container-flex-left",
        lockVisible: true,
        valueGetter: (cell: any) => String(cell.data?.productName ?? "-"),
        cellRenderer: BadgeLinkCellRendererComponent,
        cellRendererParams: {
          tooltipArray: (row) => {
            if (!row?.materials?.length) {
              return undefined;
            }

            return row.materials.map((m) => `${m.category}: ${m.name}`);
          },
          badgeValue: (row) => row.productName,
          badgeIcon: "category",
          tooltipTemplate: "keyCount",
          tooltipHeader: "Materials",
          linkRouteIdParam: "productId",
          linkRouteFn: this.router.getProductLink,
        },
      });
    }

    this.allCustomFields.forEach((customField) => {
      columnDefs.push({
        headerName: customField.label,
        field: customField.label,
        valueGetter: (cell: any) => {
          const customFieldLabel = cell.colDef.field;

          return cell.data.customFields.find((cf) => cf.definition.label === customFieldLabel)
            ?.value;
        },
      });
    });

    this.columnDefs.set(
      CommonUtils.getVisibleColumnDefs(columnDefs, [
        ...this.columns,
        ...this.allCustomFields.map((cf) => cf.label),
      ]),
    );

    await this.getAll();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes["selectedItems"] && !changes["selectedItems"].isFirstChange()) {
      await this.getAll();
    }
  }

  public refreshItemsQuantity = (data: { deliveredQuantity: number; id: string }): void => {
    const selectedItem = this.selectedItems.find((i) => i.id === data.id) as any;

    if (selectedItem) {
      selectedItem.deliveredQuantity = data.deliveredQuantity;
      const itemsWithSameProductId = this.selectedItems.filter(
        (i) => i.productId === selectedItem.productId,
      );
      const totalQuantity = itemsWithSameProductId.reduce((total, item) => {
        return total + (item.deliveredQuantity ?? item.selectedQuantity ?? item.remainingQuantity);
      }, 0);
      const rowDataItem = this.rowData.find((r) => r.productId === selectedItem.productId);

      if (rowDataItem) {
        rowDataItem.totalQuantity = totalQuantity;
        if (rowDataItem.valueWithDefaultUnit) {
          rowDataItem.valueWithDefaultUnit = CommonUtils.formatQuantityWithDefaultUnit(
            rowDataItem.totalQuantity,
            selectedItem.productDefaultUnit,
            selectedItem.unitOfMeasurement,
          );
        }
      }
    }
  };

  private getParsedRowData = async (selectedItems: IItem[]): Promise<any[]> => {
    if (!selectedItems) {
      return [];
    }

    const result: any[] = [];

    for (const item of selectedItems) {
      const itemProductId = CommonUtils.getUriId(item.product);
      const itemMaterialsIds = item.materials.map((m: any) => CommonUtils.getUriId(m));

      const existingItem = result.find((r) => {
        if (this.isOldMaterialsEnabled) {
          return (
            r.productId === itemProductId &&
            r.materialsIds.length === itemMaterialsIds.length &&
            r.materialsIds.every((m) => itemMaterialsIds.includes(m))
          );
        } else {
          return r.productId === itemProductId;
        }
      });

      if (existingItem) {
        existingItem.totalQuantity +=
          item.deliveredQuantity ?? item.selectedQuantity ?? item.remainingQuantity;
      } else {
        const product = this.allProducts.find((p) => p.id === itemProductId);

        const unitOfMeasurement = product?.unitOfMeasurement;

        const materialData: { id: string; name: string }[] = [];

        for (const itemMaterialId of itemMaterialsIds) {
          const material = this.allMaterials.find((m) => m.id === itemMaterialId);

          if (material) {
            materialData.push({ name: material.name, id: material.id });
          }
        }
        result.push({
          productId: itemProductId,
          productName: product?.name || "-",
          materialsIds: itemMaterialsIds,
          materialData,
          materials: product?.materials,
          totalQuantity: item.deliveredQuantity ?? item.selectedQuantity ?? item.remainingQuantity,
          quantitySymbol: unitOfMeasurement?.symbol,
          quantityPrecision: unitOfMeasurement?.precision ?? 0,
          customFields: product?.customFields,
        });
      }
      const currentItem = existingItem || result[result.length - 1];
      const product = this.allProducts.find((p) => p.id === itemProductId);
      const defaultUnit = product?.defaultCustomUnit;
      const baseUnit = product?.unitOfMeasurement;

      if (defaultUnit) {
        const productDefaultUnit = defaultUnit;

        currentItem.valueWithDefaultUnit = CommonUtils.formatQuantityWithDefaultUnit(
          currentItem.totalQuantity,
          productDefaultUnit,
          baseUnit,
        );
      }
    }

    return result;
  };

  private getAll = async (): Promise<void> => {
    this.isLoading.set(true);
    this.rowData = await this.getParsedRowData([...this.selectedItems]);
    this.isLoading.set(false);
  };
}
