<div class="auth-container">
  <mat-icon class="logo-icon" svgIcon="logo-night"></mat-icon>
  <div class="outer-container">
    <div class="container text-center">
      <div class="title">
        <h1>New password</h1>
      </div>

      <div class="subtitle">Set up your new password below.</div>

      @if (isLoading()) {
        <app-loader></app-loader>
      } @else {
        <div class="action-button-container">
          <form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
            <iov-input-password
              label="New password"
              formControlName="password"
              showRequirementsTooltip
              autofocus
              autocomplete="new-password"
            ></iov-input-password>

            <iov-input-password
              label="Confirm new password"
              formControlName="confirmPassword"
              class="no-padding mt-neg-12"
              autocomplete="new-password"
            ></iov-input-password>
            <iov-form-hint
              [errors]="formGroup.errors"
              [showError]="hasGroupValidationErrors"
            ></iov-form-hint>

            <div class="submit-button">
              <iov-button
                [disabled]="isSubmitButtonDisabled"
                class="fresh-green"
                accessibilityRole="submit"
                size="large"
              >
                Save new password
              </iov-button>
            </div>
          </form>
        </div>

        <div class="switch-mode-container">
          <span>Back to</span>
          <a [routerLink]="'/' + routingEnum.LOGIN">Login</a>
        </div>
      }
    </div>
  </div>
</div>
