<div class="accept-container">
  <mat-dialog-content cdkFocusRegionstart>
    <p class="text-center">{{ data.organisationName }} would like you to join the organisation</p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <iov-button-group>
      <iov-button (pressed)="onClose()" type="ghost">Cancel</iov-button>
      <iov-button (pressed)="onClose(false)" color="danger">
        <mat-icon icon-left>close</mat-icon>
        Reject
      </iov-button>
      <iov-button (pressed)="onClose(true)">
        <mat-icon icon-left>check</mat-icon>
        Accept
      </iov-button>
    </iov-button-group>
  </mat-dialog-actions>
</div>
