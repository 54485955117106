import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  signal,
} from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";

import { Subscription } from "rxjs";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { CommonConstants } from "@shared/constants";
import { RoutingEnum } from "@shared/enums";
import { IOrganisation, IUser } from "@shared/interfaces";
import { AdminOrganisationsService, UsersService } from "@shared/services";
import { CommonUtils } from "@shared/utils";

@Component({
  templateUrl: "./admin-user-details.component.html",
  styleUrls: ["./admin-user-details.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminUserDetailsComponent implements AfterViewInit, OnDestroy {
  public isLoading = signal(true);

  public element: any = null;

  public readonly routingEnum = RoutingEnum;

  public readonly dateTimeFormat = CommonConstants.DATE_TIME_FORMAT;

  public readonly mainInformationText = CommonConstants.MAIN_INFORMATION_TEXT;

  private allOrganisations: IOrganisation[] = null;

  private subscriptions = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private usersService: UsersService,
    private notificationService: NotificationService,
    private adminOrganisationsService: AdminOrganisationsService,
  ) {}

  public async ngAfterViewInit(): Promise<void> {
    await this.onReloadOrganisations();
    this.subscriptions.add(
      this.route.params.subscribe(async (params: Params) => {
        if (params["id"]) {
          await this.reloadData(params["id"]);
        }
      }),
    );
  }

  public reloadData = async (id: string): Promise<void> => {
    this.isLoading.set(true);
    await this.usersService
      .get(id)
      .then(async (element: IUser) => {
        element.organisationNames = [];
        const userOrganisations = await this.usersService.getUserOrganisationMembershipsPage(
          element.id,
        );

        for (const userOrganisation of userOrganisations.content) {
          const organisationId = CommonUtils.getUriId(userOrganisation.organisation);
          const organisation = this.allOrganisations.find((o) => o.id === organisationId);

          if (organisation) {
            element.organisationNames.push(organisation.name);
          }
        }

        this.element = element;

        this.isLoading.set(false);
      })
      .catch((error) => {
        this.notificationService.showError(error, true);
      });
  };

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private onReloadOrganisations = async (): Promise<void> => {
    await this.adminOrganisationsService
      .getAll()
      .then((response: any[]) => {
        this.allOrganisations = response;
      })
      .catch((error) => {
        this.notificationService.showError(error);
      });
  };
}
