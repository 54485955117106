import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  booleanAttribute,
  input,
} from "@angular/core";

import { CardContentTypeEnum } from "@design-makeover/components/cards/card-content/card-content.model";

import { IBaseUnit, ICustomEvent, ICustomUnitOfMeasurement } from "@shared/interfaces";

@Component({
  selector: "app-units-of-measurement-list-items",
  templateUrl: "./units-of-measurement-list-items.component.html",
  styleUrl: "./units-of-measurement-list-items.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnitsOfMeasurementListItemsComponent {
  @Input()
  public unitsOfMeasurement: ICustomUnitOfMeasurement[] & IBaseUnit[];

  public isLoading = input<boolean>(false);

  @Output()
  remove: EventEmitter<string> = new EventEmitter<string>();

  public readonly cardContentTypeEnum = CardContentTypeEnum;

  @Input()
  defaultUnitOfMeasurementId: string;

  @Input({ transform: booleanAttribute })
  isToggleDisabled: boolean;

  @Output()
  defaultUnitChanged: EventEmitter<ICustomEvent> = new EventEmitter<ICustomEvent>();

  onRemove(id: string): void {
    this.remove.emit(id);
  }

  onDefaultUnitChanged(event: ICustomEvent): void {
    this.defaultUnitChanged.emit(event);
  }
}
