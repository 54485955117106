<div class="container-space-between">
  <app-page-title title="Deliveries"></app-page-title>

  <iov-button-group [visible]="canAddModifyEntities">
    <iov-button (pressed)="onAdd()">
      <mat-icon icon-left>add</mat-icon>
      Add new
    </iov-button>
  </iov-button-group>
</div>

<app-deliveries-table
  [recordState]="recordStateEnum.ACTIVE"
  [columns]="[
    'recordState',
    'deliveryIdWithRulesets',
    'from.name',
    'to.name',
    'status',
    'sent',
    'delivered',
    'tags',
  ]"
  [isSaveTableState]="true"
  [isFixedBottomPaginator]="true"
></app-deliveries-table>
