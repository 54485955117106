import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  booleanAttribute,
} from "@angular/core";

import { InputSelectOption } from "@design-makeover/components/inputs/input-select/input-select.model";

import { CommonConstants } from "@shared/constants";

@Component({
  selector: "iov-tag",
  templateUrl: "./tag.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent {
  @Input() tag: InputSelectOption;

  @Input() class: string;

  @Input({ transform: booleanAttribute }) isTagClickable: boolean = false;

  @Input({ transform: booleanAttribute }) canRemove: boolean = false;

  @Input() maxTextLengthToShow = CommonConstants.MAX_CHIPS_TEXT_LENGTH_TO_SHOW;

  @Output() remove: EventEmitter<InputSelectOption> = new EventEmitter<InputSelectOption>();

  @Output() pressed: EventEmitter<InputSelectOption> = new EventEmitter<InputSelectOption>();

  clickRemoveTag(): void {
    this.remove.emit(this.tag);
  }

  onClick(): void {
    if (this.isTagClickable) {
      this.pressed.emit(this.tag);
    }
  }
}
