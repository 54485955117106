<iov-slide-overlay-header
  [canArchive]="false"
  [shouldDisplayTags]="false"
  [isInboundShare]="true"
  [title]="title()"
  icon="locations"
></iov-slide-overlay-header>
<iov-slide-overlay-content #slideOverlayContent>
  <ng-template
    [overlay-menu-show-edit-button]="false"
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="menu-info"
    overlay-menu-title="Location details"
  >
    <iov-form-field-group [name]="mainInformationText">
      <app-location-overlay-main-information
        #informationView
        [countryOptions]="allCountries"
        [formGroup]="formGroup"
        [isEditing]="true"
        [organisationOptions]="organisationOptions"
        [isInboundShare]="true"
      ></app-location-overlay-main-information>
    </iov-form-field-group>
    <iov-form-field-group name="Address">
      <app-location-overlay-address
        [countryOptions]="allCountries"
        [formGroup]="formGroup"
      ></app-location-overlay-address>
    </iov-form-field-group>
  </ng-template>
  <ng-template
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.GPS_DATA).isEnabled"
    [overlay-menu-indicator]="gpsIndicator"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-title]="overlayTabEnum.GPS_DATA"
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="map"
  >
    <app-location-overlay-gps [formGroup]="formGroup" [isEditing]="true"></app-location-overlay-gps>
  </ng-template>
  <ng-template #gpsIndicator>
    @if (formGroup?.controls["geoLocation"]?.value) {
      <div class="gps-indicator"></div>
    }
  </ng-template>
  <ng-template
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.DOCUMENTS).isEnabled"
    [overlay-menu-indicator]="element()?.documents?.length"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-title]="overlayTabEnum.DOCUMENTS"
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="document"
  >
    <article class="field-group-container">
      <app-documents-table
        [isInboundShared]="true"
        [documents]="element()?.documents"
        [inboundSharedSenderOrgId]="organisationIdQueryParam"
      ></app-documents-table>
    </article>
  </ng-template>
  <ng-template
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.CERTIFICATES).isEnabled"
    [overlay-menu-indicator]="element()?.certificates?.length"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="overlayTabEnum.CERTIFICATES"
    iovSlideOverlayMenuItem
    overlay-menu-svg-icon="certificates"
  >
    <article class="field-group-container">
      <app-certificates-table
        [isInboundShared]="true"
        [inboundSharedSenderOrgId]="organisationIdQueryParam"
        [certificates]="element()?.certificates"
      ></app-certificates-table>
    </article>
  </ng-template>
</iov-slide-overlay-content>
