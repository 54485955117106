import { ChangeDetectionStrategy, Component } from "@angular/core";

import { RoutingEnum } from "@shared/enums";

@Component({
  templateUrl: "./settings-sent-information.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsSentInformationComponent {
  public readonly routingEnum = RoutingEnum;
}
