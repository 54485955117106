import { HttpClient } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, OnInit, signal } from "@angular/core";
import {
  FormArray,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";

import { firstValueFrom, pairwise, startWith, Subscription } from "rxjs";

import { SlideOverlayPageService } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.service";
import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { ItemSupplyChainMapperService } from "@components/shared/items-supply-chain/item-supply-chain-mapper.service";
import { ICustomFieldResponse } from "@components/shared/items-supply-chain/item-supply-chain.interface";
import { CommonConstants } from "@shared/constants";
import {
  AttachmentTargetEnum,
  AttachmentTypeEnum,
  FeatureFlagEnum,
  RoutingEnum,
} from "@shared/enums";
import {
  IAttachment,
  IBaseUnit,
  ICustomField,
  IDelivery,
  IItem,
  ILocationDetails,
  IMaterialExtended,
  IOrganisation,
  IProductExtended,
  ISelectOption,
} from "@shared/interfaces";
import {
  AttachmentsService,
  AuthenticationService,
  CommonService,
  ConnectionsService,
  CustomFieldsService,
  DeliveriesService,
  FeatureFlagService,
  ItemsService,
  LocationsService,
  MaterialsService,
  OrganisationsService,
  ProductsService,
} from "@shared/services";
import { RouterService } from "@shared/services/router.service";
import { CommonUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

import { EudrItem } from "./eudr-item";
import { EudrItemGroup } from "./eudr-item-group";
import { ReportsEudrModel as Model } from "./reports-eudr.model";

@Component({
  templateUrl: "./reports-eudr.component.html",
  styleUrls: ["./reports-eudr.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ReportsEudrComponent implements OnInit {
  public delivery: IDelivery;

  public items: IItem[] = [];

  public products: IProductExtended[] = [];

  public materials: IMaterialExtended[] = [];

  public isLoading = signal(true);

  public totalAreaError: boolean = false;

  public activeOrganisationId: string;

  public eudrItemGroups: EudrItemGroup[] = [];

  public countryOptions: ISelectOption[] = [];

  public productsWithErrors: IProductExtended[] = [];

  public locationWithoutOrgAttachment: ILocationDetails;

  public formGroup: UntypedFormGroup = new FormGroup({
    referenceNumber: new UntypedFormControl(null, [CustomValidators.required]),
    activity: new UntypedFormControl(Model.ActivityEnum.IMPORT, [CustomValidators.required]),
    operatorName: new UntypedFormControl({ value: null, disabled: true }),
    operatorCountry: new UntypedFormControl({ value: null, disabled: true }),
    operatorCountryIsoCode: new UntypedFormControl({ value: null, disabled: true }),
    operatorActivity: new UntypedFormControl(null, [CustomValidators.required]),
    operatorStreet: new UntypedFormControl({ value: null, disabled: true }),
    activityCountry: new UntypedFormControl({ value: null, disabled: true }),
    activityCountryIsoCode: new UntypedFormControl({ value: null, disabled: true }),
    entryCountry: new UntypedFormControl({ value: null, disabled: true }),
    entryCountryIsoCode: new UntypedFormControl({ value: null, disabled: true }),
    entryDate: new UntypedFormControl({ value: null, disabled: true }, [CustomValidators.date]),
    competentAuthorityMessage: new UntypedFormControl(),
    totalNetMass: new UntypedFormControl(null, [CustomValidators.required]),
    totalVolume: new UntypedFormControl(null, [CustomValidators.required]),
    suplementaryUnits: new UntypedFormControl(null, [CustomValidators.required]),
    totalArea: new UntypedFormControl({ value: null, disabled: true }, [CustomValidators.required]),
    commodities: new UntypedFormArray([]),
  });

  public readonly constants = Model;

  private readonly isOldMaterialsEnabled = !this.featureFlagService.isEnabled(
    FeatureFlagEnum.NEW_MATERIALS_BEHAVIOUR,
  );

  private fromOrganisation: IOrganisation;

  private toOrganisation: IOrganisation;

  private allCustomFields: ICustomField[] = [];

  private allUnitOfMeasurements: IBaseUnit[] = [];

  private subscriptions = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private deliveriesService: DeliveriesService,
    private locationsService: LocationsService,
    private attachmentsService: AttachmentsService,
    private connectionsService: ConnectionsService,
    private organisationsService: OrganisationsService,
    private itemsService: ItemsService,
    private productsService: ProductsService,
    private materialsService: MaterialsService,
    private customFieldsService: CustomFieldsService,
    private commonService: CommonService,
    private itemSupplyChainMapperService: ItemSupplyChainMapperService,
    private notificationService: NotificationService,
    private routerService: RouterService,
    private slideOverlayPageService: SlideOverlayPageService,
    private featureFlagService: FeatureFlagService,
    private httpClient: HttpClient,
  ) {
    this.subscriptions.add(
      this.commonService.countriesOptionsObservable$.subscribe(
        (countriesOptions: ISelectOption[]) => {
          this.countryOptions = countriesOptions;
        },
      ),
    );

    this.subscriptions.add(
      this.commonService.unitOfMeasurementsObservable$.subscribe(
        (unitOfMeasurements: IBaseUnit[]) => {
          this.allUnitOfMeasurements = unitOfMeasurements;
        },
      ),
    );

    this.subscriptions.add(
      this.slideOverlayPageService.trigger$.subscribe(async (params) => {
        if (params?.hasSaved) {
          this.isLoading.set(true);
          await this.setupForm();
          this.isLoading.set(false);
        }
      }),
    );

    this.activeOrganisationId = this.authenticationService.getActiveOrganisationId();
  }

  public async ngOnInit(): Promise<void> {
    const deliveryId = this.route.snapshot.params["id"];

    if (!deliveryId) {
      this.handleDeliveryNotFoundError();

      return;
    }

    try {
      this.delivery = await this.deliveriesService.get(deliveryId);
    } catch {
      this.handleDeliveryNotFoundError();

      return;
    }

    await this.setupForm();

    this.isLoading.set(false);
  }

  private handleDeliveryNotFoundError(): void {
    this.notificationService.showError("Delivery not found");
    this.routerService.navigate(RoutingEnum.DELIVERIES);
  }

  public get title(): string {
    return `Delivery ${this.delivery.deliveryId} EUDR due diligence statement`;
  }

  public onActivityChange(activity: Model.ActivityEnum): void {
    this.formGroup.controls["activity"].setValue(activity);

    this.setActivityDependantValues();
  }

  private setActivityDependantValues(): void {
    let values = {};

    const { fromOrganisation, toOrganisation } = this;

    if (!fromOrganisation || !toOrganisation) {
      return;
    }

    if (this.formGroup.controls["activity"].value === Model.ActivityEnum.IMPORT) {
      values = {
        operatorName: toOrganisation.name,
        operatorCountry: toOrganisation.address.country,
        operatorStreet: toOrganisation.address.street,
        activityCountry: fromOrganisation.address.country,
        entryCountry: toOrganisation.address.country,
        operatorCountryIsoCode: toOrganisation.address.country,
        activityCountryIsoCode: fromOrganisation.address.country,
        entryCountryIsoCode: toOrganisation.address.country,
      };
    } else if (this.formGroup.controls["activity"].value === Model.ActivityEnum.EXPORT) {
      values = {
        operatorName: fromOrganisation.name,
        operatorCountry: fromOrganisation.address.country,
        operatorStreet: fromOrganisation.address.street,
        activityCountry: fromOrganisation.address.country,
        entryCountry: toOrganisation.address.country,
        operatorCountryIsoCode: fromOrganisation.address.country,
        activityCountryIsoCode: fromOrganisation.address.country,
        entryCountryIsoCode: toOrganisation.address.country,
      };
    } else {
      return;
    }

    Object.keys(values).forEach((key) => this.formGroup.controls[key].setValue(values[key]));
  }

  private async setupForm(): Promise<void> {
    this.formGroup.controls["entryDate"].setValue(this.delivery.delivered);

    const [fromOrganisation, toOrganisation, allCustomFields] = await Promise.all([
      this.getOrganisationFromLocationUri(this.delivery.from),
      this.getOrganisationFromLocationUri(this.delivery.to),
      this.customFieldsService.getAll(),
    ]);

    this.fromOrganisation = fromOrganisation;
    this.toOrganisation = toOrganisation;
    this.allCustomFields = allCustomFields;

    this.setActivityDependantValues();

    await this.handleItems();
  }

  public async onClickProduct(product: IProductExtended): Promise<void> {
    this.routerService.navigate(this.routerService.getProductLink(product.id));
  }

  public get commodityFormControls(): FormGroup[] {
    return (this.formGroup.get("commodities") as UntypedFormArray).controls as FormGroup[];
  }

  private getProductsWithErrors(): IProductExtended[] {
    if (
      this.requiredCustomFieldsNeedingValues.length !==
      Model.requiredCustomFieldsNeedingValuesLabels.length
    ) {
      return this.products;
    }

    const products = [
      ...this.productsMissingRequiredCustomFields(),
      ...this.groupProductsByCustomFields(),
    ];

    return Array.from(new Map(products.map((product) => [product.id, product])).values());
  }

  private productsMissingRequiredCustomFields(): IProductExtended[] {
    const customFieldWhichNeedValueLabels: string[] = this.requiredCustomFieldsNeedingValues.map(
      (customField) => customField.label,
    );

    return this.products.filter((product) => {
      const productCustomFieldLabels: string[] = (product.customFields || []).map(
        (customField) => customField.definition.label,
      );

      const allRequiredFieldsPresent = customFieldWhichNeedValueLabels.every((label) =>
        productCustomFieldLabels.includes(label),
      );

      return !allRequiredFieldsPresent;
    });
  }

  private get descriptionCustomField(): ICustomField {
    return this.allCustomFields.find(
      (customField) => customField.label === Model.ReportCustomFieldEnum.DESCRIPTION,
    );
  }

  private groupProductsByCustomFields(): IProductExtended[] {
    const products = this.products.map((product) => {
      const productCustomFields = product.customFields || [];

      let description: ICustomFieldResponse;

      if (this.descriptionCustomField) {
        description = productCustomFields.find(
          (customField) => customField.definition.label === this.descriptionCustomField.label,
        );
      }

      const customFieldValuesToGroup: string[] = this.requiredCustomFieldsNeedingValues.map(
        (requiredField) => {
          const customField = productCustomFields.find(
            (customField) => customField.definition.label === requiredField.label,
          );

          return customField?.value;
        },
      );

      return {
        product,
        descriptionCustomFieldValue: description?.value,
        customFieldValuesToGroup,
      };
    });

    const groups: { [key: string]: Model.IProductWithDescription[] } = {};

    products.forEach((element) => {
      const key = JSON.stringify(element.customFieldValuesToGroup);

      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(element);
    });

    const result: Model.IProductWithDescription[] = [];

    Object.values(groups).forEach((group) => {
      const descriptions = new Set(group.map((element) => element.descriptionCustomFieldValue));

      if (descriptions.size > 1) {
        result.push(...group);
      }
    });

    return result.map((element) => element.product);
  }

  private async handleItems(): Promise<void> {
    const itemPromises = this.delivery.items.map((deliveryItem) => {
      const itemId = CommonUtils.getUriId(deliveryItem.item);

      return this.itemsService.get(itemId);
    });

    const items = await Promise.all(itemPromises);

    const productIds: string[] = Array.from(
      new Set(items.map((item) => CommonUtils.getUriId(item.product))),
    );

    const productsPromise = this.productsService.getByIdsGraphQL(
      productIds,
      CommonConstants.MAX_API_GET_ITEMS_SIZE,
      ["CUSTOM_FIELDS"],
    );

    let materialsPromise: Promise<IMaterialExtended[]> | null = null;

    if (this.isOldMaterialsEnabled) {
      const materialIds: string[] = Array.from(
        new Set(items.map((item) => item.materials).flat()),
      ).map((materialUri) => CommonUtils.getUriId(materialUri));

      materialsPromise = this.materialsService.getByIdsGraphQL(
        materialIds,
        CommonConstants.MAX_API_GET_ITEMS_SIZE,
        ["CUSTOM_FIELDS"],
      );
    }

    const [products, materials] = await Promise.all([
      productsPromise,
      materialsPromise ?? Promise.resolve([]),
    ]);

    this.products = products;

    if (this.isOldMaterialsEnabled) {
      this.materials = materials;
    }

    this.productsWithErrors = this.getProductsWithErrors();

    if (this.productsWithErrors.length) {
      return;
    }

    const [hsCodes, organisations] = await Promise.all([
      firstValueFrom(this.httpClient.get<Model.IHsCode[]>(Model.hsCodesJsonPath)),
      this.getAllOrganisations(),
    ]);

    const groupedItems = this.groupItems(items);

    this.eudrItemGroups = EudrItemGroup.buildGroups(
      groupedItems,
      this.itemSupplyChainMapperService,
    );

    for (const itemGroup of this.eudrItemGroups) {
      const fields = itemGroup.customRequiredFields;

      let materialForms: UntypedFormGroup[] = [];

      if (this.isOldMaterialsEnabled) {
        materialForms = await itemGroup.buildMaterialsInfoForms(this.materials);
      }

      const hsCode = hsCodes.find((hsCode) => {
        return String(hsCode.hscode) === String(fields[Model.ReportCustomFieldEnum.HS_CODE]);
      });

      const eudrProducers = (await itemGroup.buildEudrProducers(organisations)).sort((a, b) => {
        return a.organisation.name.localeCompare(b.organisation.name);
      });

      const producerForms = eudrProducers.map((producer) => producer.buildForm());
      const hsCodeTitle = hsCode ? `${hsCode.hscode} - ${hsCode.description}` : "UNKNOWN";

      const commodityForm = new UntypedFormGroup({
        hsCodeTitle: new UntypedFormControl(hsCodeTitle),
        description: new UntypedFormControl(fields[Model.ReportCustomFieldEnum.DESCRIPTION], [
          CustomValidators.required,
        ]),
        netMass: new UntypedFormControl(itemGroup.netMass),
        volume: new UntypedFormControl(itemGroup.volume),
        displayUnitWarning: new UntypedFormControl(itemGroup.displayUnitWarning),
        supplementaryUnits: new UntypedFormControl(),
        materialsInfo: new UntypedFormArray(materialForms),
        producersInfo: new UntypedFormArray(producerForms),
      });

      (this.formGroup.get("commodities") as UntypedFormArray).push(commodityForm);
    }

    this.calculateTotals(true);
  }

  private calculateTotals(subscribeToValueChanges: boolean = false): void {
    this.totalAreaError = false;

    let totalNetMass = 0;
    let totalVolume = 0;
    let totalSupplementaryUnits = 0;
    let totalArea = 0;

    const { controls } = this.formGroup;

    for (const commodityFormControl of (controls["commodities"] as UntypedFormArray)
      .controls as UntypedFormGroup[]) {
      totalNetMass += Number(commodityFormControl.controls["netMass"].value) || 0;
      totalVolume += Number(commodityFormControl.controls["volume"].value) || 0;
      totalSupplementaryUnits +=
        Number(commodityFormControl.controls["supplementaryUnits"].value) || 0;
      totalArea += this.calculateTotalAreaForCommodity(
        commodityFormControl,
        subscribeToValueChanges,
      );

      if (subscribeToValueChanges) {
        this.subscribeToValueChanges(commodityFormControl, "netMass");
        this.subscribeToValueChanges(commodityFormControl, "volume");
        this.subscribeToValueChanges(commodityFormControl, "supplementaryUnits");
      }
    }

    controls["totalNetMass"].setValue(totalNetMass);
    controls["totalVolume"].setValue(totalVolume);
    controls["suplementaryUnits"].setValue(totalSupplementaryUnits);

    if (this.totalAreaError) {
      controls["totalArea"].setValue("n/a");
    } else {
      controls["totalArea"].setValue(totalArea);
    }
  }

  private subscribeToValueChanges(formGroup: UntypedFormGroup, property: string): void {
    formGroup.controls[property].valueChanges
      .pipe(startWith(null), pairwise())
      .subscribe(() => this.calculateTotals());
  }

  private calculateTotalAreaForCommodity(
    commodityFormControl: UntypedFormGroup,
    subscribeToValueChanges: boolean = false,
  ): number {
    const producersInfo = commodityFormControl.controls["producersInfo"] as UntypedFormArray;

    let totalArea: number = 0;

    producersInfo.controls.forEach((producerInfo: UntypedFormGroup) => {
      return (producerInfo.controls["productionPlaces"] as FormArray).controls.forEach(
        (productionPlace: UntypedFormGroup) => {
          const productionPlaceArea = productionPlace.controls["area"].value;

          if (CommonUtils.isNumber(productionPlaceArea)) {
            totalArea += Number(productionPlaceArea) || 0;
          } else {
            this.totalAreaError = true;
          }

          if (subscribeToValueChanges) {
            this.subscribeToValueChanges(productionPlace, "area");
          }
        },
      );
    });

    return totalArea;
  }

  private async getAllOrganisations(): Promise<IOrganisation[]> {
    const loggedInUserOrganisationPromise = this.organisationsService.get(
      this.activeOrganisationId,
    );
    const otherOrganisationsPromise = this.connectionsService.getAll();

    const [loggedInUserOrganisation, otherOrganisations] = await Promise.all([
      loggedInUserOrganisationPromise,
      otherOrganisationsPromise,
    ]);

    return [loggedInUserOrganisation, ...otherOrganisations];
  }

  private get requiredCustomFieldsNeedingValues(): ICustomField[] {
    return this.allCustomFields.filter(({ label }) => {
      return (Model.requiredCustomFieldsNeedingValuesLabels as string[]).includes(label);
    });
  }

  private groupItems(items: IItem[]): Map<Model.requiredCustomFieldValue, EudrItem[]> {
    const eudrItems = items.map((item) => {
      const productId = CommonUtils.getUriId(item.product);
      const product = this.products.find(
        (product) => CommonUtils.getUriId(product.id) === productId,
      );

      const baseUnit = this.allUnitOfMeasurements.find(
        (unit) => unit.id === product.unitOfMeasurement.id,
      );

      const deliveryItem = this.delivery.items.find((deliveryItem) => {
        return CommonUtils.getUriId(deliveryItem.item) === item.id;
      });

      return new EudrItem(item, product, baseUnit, deliveryItem.quantity);
    });

    return this.groupEudrItemsByCustomFieldValues(eudrItems);
  }

  private stringifyCustomFieldValues(customFieldValues: Model.RequiredCustomFieldValues): string {
    const sortedValues: string[] = [];

    for (const label of Model.reportCustomFieldLabels) {
      sortedValues.push(customFieldValues[label]);
    }

    return JSON.stringify(sortedValues);
  }

  private groupEudrItemsByCustomFieldValues(
    items: EudrItem[],
  ): Map<Model.requiredCustomFieldValue, EudrItem[]> {
    const groups = new Map<Model.requiredCustomFieldValue, EudrItem[]>();

    for (const item of items) {
      const key = this.stringifyCustomFieldValues(item.customFieldValues);

      if (!groups.has(key)) {
        groups.set(key, []);
      }

      groups.get(key)!.push(item);
    }

    return groups;
  }

  public onClickLocation(location: ILocationDetails): void {
    this.routerService.navigate(this.routerService.getLocationLink(location.id));
  }

  private async getOrganisationFromLocationUri(locationUri: string): Promise<IOrganisation> {
    const locationId = CommonUtils.getUriId(locationUri);

    const location = await this.locationsService.get(locationId);
    const organisationAttachment = await this.loadOrganisationAttachment(location.id);

    if (!organisationAttachment) {
      this.locationWithoutOrgAttachment = location;

      return null;
    }

    const organisationId = CommonUtils.getUriId(organisationAttachment.targetUri);

    if (organisationId === this.activeOrganisationId) {
      return await this.organisationsService.get(organisationId);
    } else {
      return await this.connectionsService.get(organisationId);
    }
  }

  private loadOrganisationAttachment = async (locationId: string): Promise<IAttachment> => {
    return await this.attachmentsService
      .getAll(
        AttachmentTypeEnum.LOCATION,
        null,
        `/organisations/${this.activeOrganisationId}/locations/${locationId}`,
      )
      .then((attachments: IAttachment[]) => {
        return CommonUtils.getTargetAttachment(
          attachments,
          AttachmentTargetEnum.ORGANISATION,
          AttachmentTypeEnum.LOCATION,
          locationId,
        );
      });
  };
}
