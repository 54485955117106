import { Inject, Injectable } from "@angular/core";

import { Observable, lastValueFrom } from "rxjs";

import { APP_CONFIG } from "@shared/tokens";

import { ApiService } from "./api.service";
import { CommonConstants } from "../../constants";
import {
  IConfig,
  IPageableContent,
  IShare,
  ISharePayload,
  IShareStructure,
} from "../../interfaces";
import { FormUtils } from "../../utils";
import { AuthenticationService } from "../authentication.service";

@Injectable({
  providedIn: "root",
})
export class SharesService {
  private isSendingVerificationCode: boolean;

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {}

  private getBaseUrl = (): string =>
    `${this.environment.baseUrl}organisations/${this.authenticationService.getActiveOrganisationId()}/sharing`;

  public getPage = async (
    rootRecordUri: string,
    recipient: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
  ): Promise<IPageableContent<IShare>> => {
    recipient = recipient ? encodeURIComponent(recipient) : undefined;
    const url = `${this.getBaseUrl()}/shares?${FormUtils.addUrlParams({ rootRecordUri, recipient, size, page, sort })}`;

    return await lastValueFrom(this.apiService.get<IPageableContent<IShare>>(url));
  };

  public getPageSubscription = (
    rootRecordUri: string,
    recipient: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
  ): Observable<IPageableContent<IShare>> => {
    recipient = recipient ? encodeURIComponent(recipient) : undefined;
    const url = `${this.getBaseUrl()}/shares?${FormUtils.addUrlParams({ rootRecordUri, recipient, size, page, sort })}`;

    return this.apiService.get<IPageableContent<IShare>>(url);
  };

  public async getAll(
    rootRecordUri: string = undefined,
    recipient: string = undefined,
  ): Promise<IShare[]> {
    const result: IShare[] = [];
    let page = 0;
    let hasNextPage: boolean = true;

    while (hasNextPage) {
      const {
        content,
        page: { totalElements },
      } = await this.getPage(
        rootRecordUri,
        recipient,
        CommonConstants.MAX_API_GET_ITEMS_SIZE,
        page,
        undefined,
      );

      result.push(...content);

      page++;
      hasNextPage = result.length < totalElements;
    }

    return result;
  }

  public getShareStructure = async (recordUri: string): Promise<IShareStructure[]> =>
    await lastValueFrom(
      this.apiService.get<IShareStructure[]>(
        `${this.getBaseUrl()}/share-structure?record=${recordUri}`,
      ),
    );

  public create = async (payload: ISharePayload): Promise<IShare> =>
    await lastValueFrom(this.apiService.post<IShare>(`${this.getBaseUrl()}/shares`, payload));

  public reSendEmail = async (shareId: string): Promise<void> =>
    await lastValueFrom(
      this.apiService.post<void>(`${this.getBaseUrl()}/shares/${shareId}/resend-email`),
    );

  public delete = async (shareId: string): Promise<void> =>
    await lastValueFrom(this.apiService.delete<void>(`${this.getBaseUrl()}/shares/${shareId}`));

  public sendVerificationCode = async (organisationId: string, shareId: string): Promise<void> => {
    if (this.isSendingVerificationCode) {
      return Promise.resolve();
    }

    this.isSendingVerificationCode = true;

    try {
      await lastValueFrom(
        this.apiService.post<void>(
          `${this.environment.baseUrl}organisations/${organisationId}/sharing/shares/${shareId}/request-access`,
        ),
      );
      // eslint-disable-next-line no-useless-catch
    } catch (error) {
      throw error;
    } finally {
      this.isSendingVerificationCode = false;
    }
  };

  public getSharedRecords = async (shareId: string, srcUri: string): Promise<any[]> =>
    await lastValueFrom(
      this.apiService.get<any[]>(
        `${this.getBaseUrl()}/shared-records?${FormUtils.addUrlParams({ shareId, srcUri })}`,
      ),
    );
}
