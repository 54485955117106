import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  input,
  Output,
} from "@angular/core";

import { SlideOverlayPageClass } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.class";
import { SlideOverlayPageService } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.service";

import { EntityTypeEnum, FeatureFlagEnum } from "@shared/enums";
import { ITag, ITagExtended } from "@shared/interfaces";
import { AuthenticationService, FeatureFlagService } from "@shared/services";

@Component({
  selector: "iov-slide-overlay-header",
  templateUrl: "./slide-overlay-header.component.html",
  styleUrls: ["./slide-overlay-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SlideOverlayHeaderComponent {
  icon = input.required<string>();

  isSvgIcon = input(true);

  title = input.required<string>();

  canDuplicate = input<boolean>(false);

  canDelete = input<boolean>(false);

  canShare = input<boolean>(false);

  canSend = input<boolean>(false);

  canArchive = input<boolean>(true);

  shouldDisplayTags = input<boolean>(true);

  isInboundShare = input<boolean>(false);

  initialTags = input<ITag[]>([]);

  @Output() share = new EventEmitter<void>();

  @Output() send = new EventEmitter<void>();

  public readonly isRegularUser = this.authService.isRegularUser();

  public readonly userIsSystemAdmin = this.authService.isSystemAdminOrganisation();

  public readonly canModifyEntities = this.authService.canAddModifyEntities();

  private readonly isAccountOwnerOrAdmin = this.authService.isAccountOwnerOrAdmin();

  private readonly isCrossOrgSharingEnabled = this.featureFlagService.isEnabled(
    FeatureFlagEnum.CROSS_ORGANISATION_SHARING,
  );

  constructor(
    protected overlay: SlideOverlayPageService,
    private authService: AuthenticationService,
    private featureFlagService: FeatureFlagService,
  ) {}

  public shouldShowMoreActionsButton = computed(() => {
    return (
      !this.overlay.loading() &&
      this.isRegularUser &&
      !this.userIsSystemAdmin &&
      this.canModifyEntities &&
      !this.isInboundShare() &&
      this.doesDropdownMenuHaveItems()
    );
  });

  public doesDropdownMenuHaveItems = computed(() => {
    return (
      this.isShowConnectionButtons ||
      this.canDuplicate() ||
      this.canDelete() ||
      this.canShare() ||
      this.canSend() ||
      this.canArchive()
    );
  });

  get instance(): SlideOverlayPageClass {
    return this.overlay?.componentRef()?.instance;
  }

  get isRecordActive(): boolean {
    return this.instance?.isRecordArchived;
  }

  get isShowConnectionButtons(): boolean {
    return (
      this.isCrossOrgSharingEnabled &&
      this.isAccountOwnerOrAdmin &&
      this.instance?.entityType === EntityTypeEnum.CONNECTIONS &&
      this.instance?.recordId &&
      this.instance?.recordId !== this.authService.getActiveOrganisationId()
    );
  }

  get isAddConectionEnabled(): boolean {
    return this.isShowConnectionButtons && this.instance?.getProperty("publicUri") === null;
  }

  async toggleArchive(): Promise<void> {
    if (this.isRecordActive) {
      await this.instance.confirmUnarchiveDialog();
    } else {
      await this.instance.confirmArchiveDialog();
    }
  }

  async deleteHandler(): Promise<void> {
    await this.instance.confirmDeleteDialog();
  }

  async duplicateHandler(): Promise<void> {
    await this.instance.confirmDuplicateDialog();
  }

  addConnectionHandler(): void {
    this.instance.onEditConnection();
  }

  editConnectionHandler(): void {
    this.instance.onEditConnection();
  }

  removeConnectionHandler(): void {
    this.instance.onRemoveConnection();
  }

  shareHandler(): void {
    this.share.emit();
  }

  sendHandler(): void {
    this.send.emit();
  }

  onTagsChanged(selectedTags: ITagExtended[]): void {
    if (!this.instance) {
      return;
    }

    if (this.instance.recordId) {
      this.instance.updateTable();
    } else {
      this.instance.selectedTagsForNewRecord = selectedTags;
    }
  }
}
