import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  input,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";

import {
  ConfirmDialogResponseEnum,
  FeatureFlagEnum,
  RecordStateEnum,
  RoutingEnum,
} from "src/app/shared/enums";
import {
  AuthenticationService,
  CertificatesService,
  ConnectionsService,
  DeliveriesService,
  DocumentsService,
  DownloadDocumentsService,
  FeatureFlagService,
  ItemsService,
  LocationsService,
  ProcessesService,
  ProductsService,
  RulesetsService,
  SupplyChainsService,
} from "src/app/shared/services";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { EditOrganisationConnectionDialogComponent } from "..";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-more-actions-buttons",
  templateUrl: "./more-actions-buttons.component.html",
  styleUrls: ["./more-actions-buttons.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoreActionsButtonsComponent implements OnInit {
  @Input() label: string;

  @Input() labelIcon: string;

  @Input({ transform: booleanAttribute }) disabledMainButton: boolean;

  canSend = input<boolean>(false);

  @Output() send = new EventEmitter<void>();

  @Output() clickLabel: EventEmitter<void> = new EventEmitter();

  @Input()
  public element: any;

  @Input()
  public service:
    | DeliveriesService
    | DocumentsService
    | ItemsService
    | LocationsService
    | ConnectionsService
    | CertificatesService
    | ProcessesService
    | RulesetsService
    | ProductsService
    | SupplyChainsService;

  @Input()
  public isDeleteEnabled = false; //todo remove this when isDeleteEnabled is true for all entities.

  @Input({ transform: booleanAttribute })
  public isArchiveEnabled: boolean = true;

  @Input({ transform: booleanAttribute })
  public isShareEnabled = false;

  @Input({ transform: booleanAttribute })
  public isDuplicateEnabled: boolean = false;

  // @Output()
  // public setLoading: EventEmitter<boolean> = new EventEmitter(); //TODO, see below, not working on error.

  @Output()
  public reloadData: EventEmitter<string> = new EventEmitter();

  @Output()
  public share: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public duplicate: EventEmitter<void> = new EventEmitter<void>();

  public readonly recordStateEnum = RecordStateEnum;

  public readonly isAccountOwnerOrAdmin = this.authenticationService.isAccountOwnerOrAdmin();

  public readonly isCrossOrgSharingEnabled = this.featureFlagService.isEnabled(
    FeatureFlagEnum.CROSS_ORGANISATION_SHARING,
  );

  public isConnection = false;

  public isAddConectionEnabled = false;

  constructor(
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private router: Router,
    private downloadDocumentsService: DownloadDocumentsService,
    private featureFlagService: FeatureFlagService,
    private authenticationService: AuthenticationService,
  ) {}

  public ngOnInit(): void {
    this.setConnectionInfo();
  }

  private setConnectionInfo = (): void => {
    this.isConnection =
      this.service?.constructor === ConnectionsService &&
      this.element?.id &&
      this.element.id !== this.authenticationService.getActiveOrganisationId();

    if (this.isCrossOrgSharingEnabled && this.isAccountOwnerOrAdmin && this.isConnection) {
      this.isAddConectionEnabled = this.element.publicUri === null;
    }
  };

  public onAddConnection = (): void => {
    this.onEditConnection();
  };

  public onEditConnection = (): void => {
    const dialogRef = this.dialog.open(EditOrganisationConnectionDialogComponent, {
      data: {
        connection: this.element,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: { hasSaved: boolean }) => {
      if (result?.hasSaved) {
        try {
          //this.setLoading.emit(true); //TODO, see below, not working on error.
          this.reloadData.emit(this.element.id);
        } catch (error) {
          //this.setLoading.emit(false); //TODO, this does not work.
          this.notificationService.showError(error);
        }
      }
    });
  };

  public onRemoveConnection = async (): Promise<void> => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: "Remove connection",
        contentTranslatedText: `Are you sure that you want to remove the connection to this organisation?`,
        confirmButtonColor: "danger",
        confirmButtonTranslatedText: "Remove",
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        try {
          //this.setLoading.emit(true); //TODO, see below, not working on error.
          const payload: any = {
            ...this.element,
            publicUri: null,
            recordState: undefined,
          };

          await this.service.createOrUpdate(payload, this.element.id);
          this.notificationService.showSuccess(`Connection removed`);
          this.reloadData.emit(this.element.id);
        } catch (error) {
          //this.setLoading.emit(false); //TODO, this does not work.
          this.notificationService.showError(error);
        }
      }
    });
  };

  public onToggleArchiveStatus = (): void => {
    const isArchived = this.element.recordState === RecordStateEnum.ARCHIVED;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: "Confirmation",
        contentTranslatedText: `Are you sure that you want to ${isArchived ? "unarchive" : "archive"} this record?`,
        confirmButtonTranslatedText: isArchived ? "Unarchive" : "Archive",
        confirmButtonIcon: isArchived ? "inventory" : "archive",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        try {
          //this.setLoading.emit(true); //TODO, see below, not working on error.
          const wasArchived = this.element.recordState === RecordStateEnum.ARCHIVED;

          await this.service.setRecordState(
            {
              recordState: wasArchived ? RecordStateEnum.ACTIVE : RecordStateEnum.ARCHIVED,
            },
            this.element.id,
          );
          this.notificationService.showSuccess(wasArchived ? "Unarchived" : "Archived");
          this.reloadData.emit(this.element.id);
        } catch (error) {
          //this.setLoading.emit(false); //TODO, this does not work.
          this.notificationService.showError(error);
        }
      }
    });
  };

  public onDelete = (): void => {
    if (!this.isDeleteEnabled) {
      return;
    }
    if (
      this.service instanceof DocumentsService &&
      this.downloadDocumentsService.isDocumentBeingDownloaded(this.element.id)
    ) {
      this.notificationService.showError("This document is being downloaded");

      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: "Delete record",
        contentTranslatedText: `Are you sure that you want to delete this record?`,
        confirmButtonColor: "danger",
        confirmButtonTranslatedText: "Delete",
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        try {
          //this.setLoading.emit(true); //TODO, see below, not working on error.
          await this.service.delete(this.element.id);
          this.notificationService.showSuccess("Record deleted");
          await this.redirectBasedOnService();
        } catch (error) {
          //this.setLoading.emit(false); //TODO, this does not work.
          this.notificationService.showError(error);
        }
      }
    });
  };

  public onDuplicate = (): void => {
    if (!this.isDuplicateEnabled) {
      return;
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: "Duplicate record",
        contentTranslatedText: `Are you sure that you want to duplicate this record?`,
        confirmButtonTranslatedText: "Duplicate",
        confirmButtonIcon: "content_copy",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        this.duplicate.emit();
      }
    });
  };

  public onClickLabel(): void {
    this.clickLabel.emit();
  }

  private redirectBasedOnService = async (): Promise<void> => {
    let route: RoutingEnum;

    switch (this.service.constructor) {
      case DeliveriesService:
        route = RoutingEnum.DELIVERIES;
        break;
      case DocumentsService:
        route = RoutingEnum.DOCUMENTS;
        break;
      case ItemsService:
        route = RoutingEnum.ITEMS;
        break;
      case LocationsService:
        route = RoutingEnum.LOCATIONS;
        break;
      case ConnectionsService:
        route = RoutingEnum.ORGANISATIONS;
        break;
      case CertificatesService:
        route = RoutingEnum.CERTIFICATES;
        break;
      case ProcessesService:
        route = RoutingEnum.PROCESSES;
        break;
      case RulesetsService:
        route = RoutingEnum.ADMIN_RULESETS;
        break;
      case ProductsService:
        route = RoutingEnum.ADMIN_PRODUCTS;
        break;
      case SupplyChainsService:
        route = RoutingEnum.SUPPLY_CHAINS;
        break;
    }
    await this.router.navigate([`/${route}`]);
  };

  sendHandler(): void {
    this.send.emit();
  }
}
