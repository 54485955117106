@if (columnDefs().length) {
  <app-table
    [table]="table"
    [isLoading]="isLoading()"
    [class]="class"
    [rowData]="rowData"
    [columnDefs]="columnDefs()"
    [areButtonsEnabled]="areButtonsEnabled"
    [isSearchEnabled]="isSearchEnabled"
    [isRecordStateFilterEnabled]="false"
    [isPaginatorEnabled]="isPaginatorEnabled"
    [isSaveState]="isSaveTableState"
  ></app-table>
}
