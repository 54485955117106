<div class="auth-container">
  <mat-icon class="logo-icon" svgIcon="logo-night"></mat-icon>
  <div class="outer-container">
    <div class="container text-center">
      @switch (currentStep) {
        @case ("initial") {
          <div class="title">
            <h1>Recover password</h1>
          </div>

          <div class="subtitle">
            Please enter your email and we will send you a password recovery link.
          </div>

          @if (isLoading()) {
            <app-loader></app-loader>
          } @else {
            <div class="action-button-container">
              <form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
                <iov-input type="email" label="Email" formControlName="email" autofocus></iov-input>

                <iov-button
                  [disabled]="isSubmitButtonDisabled"
                  class="fresh-green"
                  size="large"
                  accessibilityRole="submit"
                  cdkFocusInitial
                >
                  Send
                </iov-button>
              </form>
            </div>
          }
        }
        @case ("email-sent") {
          <div class="title">
            <h1>Email sent</h1>
          </div>
          @if (isLoading()) {
            <app-loader></app-loader>
          } @else {
            <div class="subtitle">
              <p>
                We have sent you a password restore link to:
                <span class="email-address">{{ formGroup.controls["email"].value }}</span>
              </p>
              <br />
              <p>
                Didn’t receive the email? Check you spam folder or try
                <a
                  class="underline clickable"
                  (click)="onChangeStep('initial')"
                  (keydown.enter)="onChangeStep('initial')"
                  tabindex="0"
                >
                  another email address.
                </a>
              </p>
            </div>
          }
        }
      }

      @if (!isLoading()) {
        <div class="switch-mode-container">
          <span>Back to</span>
          <a [routerLink]="'/' + routingEnum.LOGIN">Login</a>
        </div>
      }
    </div>
  </div>
</div>
