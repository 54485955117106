import { DatePipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  inject,
  Input,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
} from "@angular/core";
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { DialogPosition, MatDialog, MatDialogRef } from "@angular/material/dialog";

import { CellClickedEvent, ColDef } from "ag-grid-community";
import { lastValueFrom, pairwise, startWith, Subscription } from "rxjs";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { BulkAddItemsModel } from "@components/items/bulk-add-items/bulk-add-items.component.model";
import { BulkAddItemsService } from "@components/items/bulk-add-items/bulk-add-items.service";
import { BulkAddItemsEditFieldDialogComponent } from "@components/items/bulk-add-items/edit-field-dialog/bulk-add-items-edit-field-dialog.component";
import { BulkAddItemsEditFieldDialogModel } from "@components/items/bulk-add-items/edit-field-dialog/bulk-add-items-edit-field-dialog.model";
import { BulkAddItemsEnterItemsModel as Model } from "@components/items/bulk-add-items/enter-items/bulk-add-items-enter-items.model";
import { ItemOverlayService } from "@components/items/pages/item-overlay/item-overlay.service";
import { ConfirmDialogComponent, TableComponent } from "@components/shared";
import { InputCellRendererComponent, QuickActionsMenuComponent } from "@shared/cell-renderers";
import { CommonConstants } from "@shared/constants";
import {
  AttachmentTargetEnum,
  AttachmentTypeEnum,
  ConfirmDialogResponseEnum,
  DateTypeEnum,
  FeatureFlagEnum,
} from "@shared/enums";
import {
  IAttachment,
  IBaseUnit,
  ICustomUnitOfMeasurement,
  IProduct,
  ISelectOption,
} from "@shared/interfaces";
import { FeatureFlagService, ItemsService } from "@shared/services";
import { ColumnUtils, CommonUtils, FormUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

@Component({
  selector: "app-bulk-add-items-enter-items",
  templateUrl: "./bulk-add-items-enter-items.component.html",
  styleUrls: ["./bulk-add-items-enter-items.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class BulkAddItemsEnterItemsComponent implements OnInit, OnDestroy {
  @ViewChild("table") table: TableComponent;

  private bulkAddItemsService: BulkAddItemsService = inject(BulkAddItemsService);

  private itemsService: ItemsService = inject(ItemsService);

  private datePipe: DatePipe = inject(DatePipe);

  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  private notificationService: NotificationService = inject(NotificationService);

  private featureFlagService: FeatureFlagService = inject(FeatureFlagService);

  private dialog: MatDialog = inject(MatDialog);

  private dialogRef: MatDialogRef<BulkAddItemsEditFieldDialogComponent, any>;

  @Input() public formGroup: FormGroup<BulkAddItemsModel.SetValuesFormGroup>;

  @Input() public productOptions: ISelectOption[] = [];

  @Input() public locationOptions: ISelectOption[] = [];

  public rowData: Model.IRowData[] = [];

  public materialOptions: ISelectOption[] = [];

  public isGridReady: boolean = false;

  public readonly maxItemsCount: number = Model.maxItemsCount;

  private subscriptions: Subscription = new Subscription();

  public readonly fieldEnum = BulkAddItemsModel.FieldEnum;

  public readonly isOldMaterialsEnabled = !this.featureFlagService.isEnabled(
    FeatureFlagEnum.NEW_MATERIALS_BEHAVIOUR,
  );

  public itemsQuantityFormGroup = new FormGroup({
    quantity: new FormControl(1, [
      CustomValidators.integer(),
      CustomValidators.min(1),
      CustomValidators.max(this.maxNewItemsThatCanBeAdded.bind(this)),
    ]),
  });

  public columnDefs = signal<ColDef[]>([]);

  private itemOverlayService = inject(ItemOverlayService);

  private materialAttachments: IAttachment[] = [];

  public ngOnInit(): void {
    this.materialOptions = this.bulkAddItemsService.allMaterials.map((material) => {
      return { label: `${material.category}: ${material.name}`, value: material.id };
    });

    this.setColumnDefs();
    this.buildItems(true);
  }

  public onClickNext(): boolean {
    if (this.formGroup.invalid) {
      this.formGroup.controls[this.fieldEnum.ITEMS].controls.forEach((control) => {
        FormUtils.findAndMarkInvalidControls(control);
      });

      this.notificationService.showError(CommonConstants.FILL_REQUIRED_FIELDS_MSG);
    }

    const areThereDuplicates = this.detectDuplicateIds();

    return !(this.formGroup.invalid || areThereDuplicates);
  }

  private detectDuplicateIds(): boolean {
    const allItemIds = this.formGroup.controls[this.fieldEnum.ITEMS].controls
      .map((itemControl) => itemControl.controls[this.fieldEnum.ITEM_ID].value)
      .filter((itemId) => !!itemId);

    let areThereDuplicates = false;

    this.formGroup.controls[this.fieldEnum.ITEMS].controls.forEach((control) => {
      const itemIdControl = control.controls[this.fieldEnum.ITEM_ID];
      const isDuplicate = allItemIds.filter((itemId) => itemId === itemIdControl.value).length > 1;

      if (isDuplicate) {
        areThereDuplicates = true;
      }

      control.controls[this.fieldEnum.IS_ITEM_ID_DUPLICATED].setValue(isDuplicate);
    });

    const rowIndexes: number[] = [];

    this.table.grid.api.forEachNode(
      (node: {
        rowIndex: number;
        data: { formGroup: FormGroup<BulkAddItemsModel.ItemFormGroup> };
      }) => {
        rowIndexes.push(node.rowIndex);
      },
    );

    rowIndexes.forEach((rowIndex) => {
      this.table.grid.api.refreshCells({
        rowNodes: [this.table.grid.api.getDisplayedRowAtIndex(rowIndex)],
        force: true,
      });
    });

    return areThereDuplicates;
  }

  private get allProducts(): IProduct[] {
    return this.bulkAddItemsService.allProducts;
  }

  private get customUnitOfMeasurements(): ICustomUnitOfMeasurement[] {
    return this.bulkAddItemsService.customUnitOfMeasurements;
  }

  private get baseUnitOfMeasurements(): IBaseUnit[] {
    return this.bulkAddItemsService.baseUnitOfMeasurements;
  }

  @HostListener("window:beforeunload")
  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    window.removeEventListener("resize", this.centerEditDialog.bind(this));
  }

  public maxNewItemsThatCanBeAdded(): number {
    return (
      Model.maxItemsCount - (this.formGroup?.controls[this.fieldEnum.ITEMS]?.controls?.length || 1)
    );
  }

  public get fixedFields(): Model.IField[] {
    const formGroupValue = this.formGroup.getRawValue();

    const fixedKeys = Object.keys(formGroupValue).filter((key) => {
      return key.startsWith("isFixed") && formGroupValue[key];
    });

    return fixedKeys.map((key) => {
      const label = key.replace("isFixed", "");

      if (key === this.fieldEnum.IS_FIXED_CREATION_DATE) {
        return this.getFixedFieldForCreationDate(formGroupValue);
      }

      const keyForValue = label.charAt(0).toLowerCase() + label.slice(1);

      return {
        label: this.getLabelForFixedField(key),
        value: this.getFieldValue(formGroupValue[keyForValue], key),
      };
    });
  }

  private getLabelForFixedField(key: string): string {
    return Model.fixedFieldMapping[key];
  }

  private getFixedFieldForCreationDate(formGroupValue): Model.IField {
    const value =
      formGroupValue.dateType === DateTypeEnum.EXACT
        ? this.formatDate(formGroupValue.createdFrom)
        : formGroupValue.createdRange.map((date) => this.formatDate(date)).join(" - ");

    return { label: "Creation date", value };
  }

  public getFieldValue(value: any, key?: string): string {
    if (key === this.fieldEnum.IS_FIXED_TAGS) {
      return value.map((tag) => tag.tagDefinition.title).join(", ");
    }

    if (Array.isArray(value)) {
      return value.map((v) => this.getFieldValue(v)).join(", ");
    }

    if (value instanceof Object && value.label) {
      return value.label;
    }

    return `${value}`;
  }

  public onKeyUpAddItems(event: KeyboardEvent): void {
    if (event.key === "Enter") {
      this.buildItems();
    }
  }

  public buildItems(isInitialFill: boolean = false): void {
    const itemsControl = this.formGroup.controls[this.fieldEnum.ITEMS];

    if (itemsControl.length >= Model.maxItemsCount) {
      return;
    }

    const quantityControl = this.itemsQuantityFormGroup.controls["quantity"];

    if (!quantityControl.value || quantityControl.invalid || quantityControl.pending) {
      return;
    }

    const quantity = quantityControl.value;

    for (let i = 0; i < quantity; i++) {
      const newItem = this.buildItem();

      itemsControl.push(newItem);

      if (!isInitialFill) {
        this.table.grid.api.applyTransaction({ add: [{ formGroup: newItem }] });
      }
    }

    if (isInitialFill) {
      this.rowData = itemsControl.controls.map((control) => {
        return { formGroup: control };
      });
    }

    quantityControl.updateValueAndValidity();

    this.setColumnDefs();
  }

  private buildItem(
    initialData?: FormGroup<BulkAddItemsModel.ItemFormGroup>,
  ): FormGroup<BulkAddItemsModel.ItemFormGroup> {
    const formGroup = this.buildItemFormGroup(initialData);
    const { fieldEnum } = this;

    switch (formGroup.controls[fieldEnum.DATE_TYPE].value) {
      case DateTypeEnum.EXACT:
        formGroup.controls[fieldEnum.CREATED_FROM].addValidators([
          CustomValidators.required,
          CustomValidators.date,
        ]);
        formGroup.controls[fieldEnum.CREATED_RANGE].clearValidators();
        break;
      case DateTypeEnum.RANGE:
        formGroup.controls[fieldEnum.CREATED_RANGE].addValidators([
          CustomValidators.required,
          CustomValidators.dateRange,
        ]);
        formGroup.controls[fieldEnum.CREATED_FROM].clearValidators();
        break;
    }

    this.addSubscriptionsToItemFormGroup(formGroup);

    return formGroup;
  }

  public isDuplicateItemId(itemIdControl: AbstractControl): boolean {
    const itemId = itemIdControl.value;

    const otherItemIds = this.formGroup.controls[this.fieldEnum.ITEMS].controls
      .filter(
        (otherItemIdControl) =>
          otherItemIdControl.controls[this.fieldEnum.ITEM_ID] !== itemIdControl,
      )
      .map((otherItemIdControl) => otherItemIdControl.controls[this.fieldEnum.ITEM_ID].value);

    return otherItemIds.includes(itemId);
  }

  public getGridHeightFn = (): number => {
    const windowHeight = window.innerHeight;

    const slideOverContentPadding = 70;

    const title = CommonUtils.getHtmlElementHeightWithMargins(".slide-over-title-container");
    const stepper = CommonUtils.getHtmlElementHeightWithMargins(".stepper-container");

    let fixedFieldsHeight = 0;
    const fixedFields = document.querySelector(".fixed-fields");

    if (fixedFields) {
      fixedFieldsHeight = CommonUtils.getHtmlElementHeightWithMargins(".fixed-fields");
    }

    const gridMargin = 5;

    const addItems = CommonUtils.getHtmlElementHeightWithMargins(".add-items");

    const buttons = CommonUtils.getHtmlElementHeightWithMargins(".slide-over-buttons");

    const availableHeight =
      windowHeight -
      slideOverContentPadding -
      title -
      stepper -
      fixedFieldsHeight -
      gridMargin -
      addItems -
      buttons;

    return availableHeight;
  };

  private buildItemFormGroup(
    initialData?: FormGroup<BulkAddItemsModel.ItemFormGroup>,
  ): FormGroup<BulkAddItemsModel.ItemFormGroup> {
    const { fieldEnum, formGroup } = this;

    const productValue =
      initialData?.controls[fieldEnum.PRODUCT]?.value ||
      this.getFieldValueForSelectField(fieldEnum.PRODUCT);

    const materialsValue =
      initialData?.controls[fieldEnum.MATERIALS]?.value ||
      formGroup.controls[fieldEnum.MATERIALS].value;

    const tagsData =
      initialData?.controls[fieldEnum.TAGS].value || formGroup.controls[fieldEnum.TAGS].value;

    const isDateRangeType = initialData
      ? initialData.controls[fieldEnum.IS_RANGE_DATE_TYPE].value
      : this.formGroup.controls[fieldEnum.IS_RANGE_DATE_TYPE].value;

    let initialQuantity =
      +initialData?.controls[fieldEnum.INITIAL_QUANTITY].value ||
      +this.getFieldValueForInput(fieldEnum.INITIAL_QUANTITY);

    if (initialQuantity === 0) {
      initialQuantity = null;
    }

    const controls: BulkAddItemsModel.ItemFormGroup = {
      [fieldEnum.ITEM_ID]: new FormControl(initialData?.controls[fieldEnum.ITEM_ID].value, {
        validators: [CustomValidators.required],
        asyncValidators: [
          CustomValidators.entityAlreadyExists(
            this.itemsService,
            null,
            BulkAddItemsModel.entityExistsValidatorArgs,
          ),
        ],
      }),
      [fieldEnum.TAGS]: new FormControl({
        value: tagsData,
        disabled: this.formGroup.controls[fieldEnum.IS_FIXED_TAGS].value,
      }),
      [fieldEnum.PRODUCT]: new FormControl(
        {
          value: productValue,
          disabled: this.isFieldDisabled(fieldEnum.IS_FIXED_PRODUCT),
        },
        [CustomValidators.required],
      ),
      [fieldEnum.INITIAL_QUANTITY]: new FormControl(
        {
          value: initialQuantity,
          disabled: this.isFieldDisabled(fieldEnum.IS_FIXED_INITIAL_QUANTITY) || !productValue,
        },
        [CustomValidators.required, CustomValidators.greaterThan(0)],
      ),
      [fieldEnum.UNIT_OF_MEASUREMENT]: new FormControl(
        {
          value:
            initialData?.controls[fieldEnum.UNIT_OF_MEASUREMENT].value ||
            this.getFieldValueForSelectField(fieldEnum.UNIT_OF_MEASUREMENT),
          disabled: this.isFieldDisabled(fieldEnum.IS_FIXED_UNIT_OF_MEASUREMENT) || !productValue,
        },
        [CustomValidators.required],
      ),
      [fieldEnum.MATERIALS]: new FormControl({
        value: materialsValue,
        disabled: this.isFieldDisabled(fieldEnum.IS_FIXED_MATERIALS) || !productValue,
      }),
      [fieldEnum.CREATED_AT_LOCATION]: new FormControl(
        {
          value:
            initialData?.controls[fieldEnum.CREATED_AT_LOCATION].value ||
            this.getFieldValueForSelectField(fieldEnum.CREATED_AT_LOCATION),
          disabled: this.isFieldDisabled(fieldEnum.IS_FIXED_CREATED_AT_LOCATION),
        },
        [CustomValidators.required],
      ),
      [fieldEnum.CURRENT_LOCATION]: new FormControl(
        {
          value:
            initialData?.controls[fieldEnum.CURRENT_LOCATION].value ||
            this.getFieldValueForSelectField(fieldEnum.CURRENT_LOCATION),
          disabled: this.isFieldDisabled(fieldEnum.IS_FIXED_CURRENT_LOCATION),
        },
        [CustomValidators.required],
      ),
      [fieldEnum.DATE_TYPE]: new FormControl(
        initialData?.controls[fieldEnum.DATE_TYPE].value ||
          this.formGroup.controls[fieldEnum.DATE_TYPE].value,
      ),
      [fieldEnum.IS_RANGE_DATE_TYPE]: new FormControl(isDateRangeType),
      [fieldEnum.CREATED_FROM]: new FormControl({
        value:
          initialData?.controls[fieldEnum.CREATED_FROM].value ||
          this.formGroup.controls[fieldEnum.CREATED_FROM].value,
        disabled: this.isFieldDisabled(fieldEnum.IS_FIXED_CREATION_DATE),
      }),
      [fieldEnum.CREATED_RANGE]: new FormControl({
        value:
          initialData?.controls[fieldEnum.CREATED_RANGE].value ||
          this.formGroup.controls[fieldEnum.CREATED_RANGE].value,
        disabled: this.isFieldDisabled(fieldEnum.IS_FIXED_CREATION_DATE),
      }),
      [fieldEnum.PREVIOUS_PRODUCT]: new FormControl(
        this.getFieldValueForSelectField(fieldEnum.PRODUCT),
      ),
      [fieldEnum.UNIT_OF_MEASUREMENT_OPTIONS]: new FormControl(
        initialData?.controls[fieldEnum.UNIT_OF_MEASUREMENT_OPTIONS].value ||
          this.getProductUnitOfMeasurements(
            this.getFieldValueForSelectField(fieldEnum.PRODUCT)?.value as string,
          ),
      ),
      [fieldEnum.BASE_INITIAL_QUANTITY]: new FormControl(
        initialData?.controls[fieldEnum.BASE_INITIAL_QUANTITY].value ||
          this.formGroup.controls[fieldEnum.BASE_INITIAL_QUANTITY].value,
      ),
      [fieldEnum.IS_ITEM_ID_DUPLICATED]: new FormControl(false),
    };

    if (this.bulkAddItemsService.delivery()) {
      controls[fieldEnum.BASE_DELIVERY_QUANTITY] = new FormControl(
        initialData?.controls[fieldEnum.BASE_DELIVERY_QUANTITY].value,
      );

      controls[fieldEnum.DELIVERY_QUANTITY] = new FormControl(
        {
          value: initialData?.controls[fieldEnum.DELIVERY_QUANTITY].value,
          disabled: !productValue,
        },
        [CustomValidators.required, CustomValidators.greaterThan(0)],
      );
    }

    return new FormGroup(controls);
  }

  private addSubscriptionsToItemFormGroup(
    formGroup: FormGroup<BulkAddItemsModel.ItemFormGroup>,
  ): void {
    const { fieldEnum } = this;
    const { controls } = formGroup;

    const itemIdSubscription = controls[fieldEnum.ITEM_ID].valueChanges
      .pipe(startWith(null), pairwise())
      .subscribe(this.detectDuplicateIds.bind(this));

    const dateTypeSubscription = controls[fieldEnum.IS_RANGE_DATE_TYPE].valueChanges.subscribe(
      (isRange) => {
        if (isRange) {
          formGroup.controls[fieldEnum.DATE_TYPE].setValue(DateTypeEnum.RANGE);
          formGroup.controls[fieldEnum.CREATED_RANGE].enable();
          formGroup.controls[fieldEnum.CREATED_FROM].disable();
          formGroup.controls[fieldEnum.CREATED_RANGE].addValidators([
            CustomValidators.required,
            CustomValidators.dateRange,
          ]);
          formGroup.controls[fieldEnum.CREATED_FROM].clearValidators();
        } else {
          formGroup.controls[fieldEnum.DATE_TYPE].setValue(DateTypeEnum.EXACT);
          formGroup.controls[fieldEnum.CREATED_FROM].enable();
          formGroup.controls[fieldEnum.CREATED_RANGE].disable();
          formGroup.controls[fieldEnum.CREATED_FROM].addValidators([
            CustomValidators.required,
            CustomValidators.date,
          ]);
          formGroup.controls[fieldEnum.CREATED_RANGE].clearValidators();
        }

        this.setColumnDefs();
      },
    );

    const initialQuantitySubscription = this.bulkAddItemsService.buildInitialQuantitySubscription(
      formGroup as unknown as FormGroup<BulkAddItemsModel.IItemFieldSharedFormGroup>,
    );

    const unitOfMeasurementSubscription =
      this.bulkAddItemsService.buildUnitOfMeasurementOptionsSubscription(
        formGroup as unknown as FormGroup<BulkAddItemsModel.IItemFieldSharedFormGroup>,
        this.setColumnDefs.bind(this),
      );

    this.subscriptions.add(dateTypeSubscription);
    this.subscriptions.add(initialQuantitySubscription);
    this.subscriptions.add(unitOfMeasurementSubscription);
    this.subscriptions.add(itemIdSubscription);

    if (this.bulkAddItemsService.delivery()) {
      const deliveryQuantitySubscription =
        this.bulkAddItemsService.buildDeliveryQuantitySubscription(
          formGroup as unknown as FormGroup<BulkAddItemsModel.IItemFieldSharedFormGroup>,
        );

      this.subscriptions.add(deliveryQuantitySubscription);
    }
  }

  private async onProductSelected(
    formGroup: FormGroup<BulkAddItemsModel.ItemFormGroup>,
    productOption: ISelectOption | null,
  ): Promise<void> {
    const previousProduct = formGroup.controls[this.fieldEnum.PREVIOUS_PRODUCT].value;

    if (!previousProduct || !productOption) {
      this.onChangeProduct(formGroup, productOption);

      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: "Resetting quantities and materials",
        contentTranslatedText:
          "Changing the item’s product will reset all of the specified materials and might result in a change of the unit of measurement (which will reset the item’s initial quantity). Are you sure you want to change the product?",
      },
    });

    const result = await lastValueFrom(dialogRef.afterClosed());

    if (result === ConfirmDialogResponseEnum.CONFIRM) {
      this.onChangeProduct(formGroup, productOption);
    } else {
      const previousProduct = formGroup.controls[this.fieldEnum.PREVIOUS_PRODUCT].value;

      formGroup.controls[this.fieldEnum.PRODUCT].setValue(previousProduct, {
        emitEvent: false,
      });
    }
  }

  private async getMaterialAttachments(productOption: ISelectOption): Promise<void> {
    if (this.isOldMaterialsEnabled || !productOption) {
      return;
    }
    this.materialAttachments = (await this.itemOverlayService.loadSelectedAttachments(
      AttachmentTypeEnum.MATERIAL,
      AttachmentTargetEnum.PRODUCT,
      productOption.value as string,
      {},
      true,
    )) as IAttachment[];
  }

  private onChangeProduct(
    formGroup: FormGroup<BulkAddItemsModel.ItemFormGroup>,
    productOption: ISelectOption,
  ): void {
    const { fieldEnum } = this;

    formGroup.controls[fieldEnum.MATERIALS].setValue([]);
    formGroup.controls[fieldEnum.UNIT_OF_MEASUREMENT].setValue(null);
    formGroup.controls[fieldEnum.BASE_INITIAL_QUANTITY].setValue(0);
    formGroup.controls[fieldEnum.BASE_DELIVERY_QUANTITY].setValue(0);

    if (!productOption) {
      FormUtils.disableControls(formGroup, [
        fieldEnum.INITIAL_QUANTITY,
        fieldEnum.DELIVERY_QUANTITY,
        fieldEnum.MATERIALS,
        fieldEnum.UNIT_OF_MEASUREMENT,
      ]);

      this.setColumnDefs();

      return;
    }

    FormUtils.enableControls(formGroup, [
      fieldEnum.INITIAL_QUANTITY,
      fieldEnum.DELIVERY_QUANTITY,
      fieldEnum.MATERIALS,
      fieldEnum.UNIT_OF_MEASUREMENT,
    ]);

    formGroup.controls["previousProduct"].setValue(productOption);

    formGroup.controls[fieldEnum.UNIT_OF_MEASUREMENT_OPTIONS].setValue(
      this.getProductUnitOfMeasurements(productOption.value as string),
    );

    const product = this.allProducts.find((product) => product.id === productOption.value);
    const defaulUnitId = CommonUtils.getUriId(product.defaultUnit);

    const defaultUnitOption = formGroup.controls[fieldEnum.UNIT_OF_MEASUREMENT_OPTIONS].value.find(
      (unit) => unit.value === defaulUnitId,
    );

    formGroup.controls[fieldEnum.UNIT_OF_MEASUREMENT].setValue(defaultUnitOption);

    this.cdr.detectChanges();

    this.setColumnDefs();
  }

  private getFieldValueForSelectField(field: BulkAddItemsModel.FieldEnum): ISelectOption {
    return this.formGroup.controls[field].value as ISelectOption;
  }

  private getFieldValueForInput(field: BulkAddItemsModel.FieldEnum): string {
    return this.formGroup.controls[field].value as string;
  }

  private isFieldDisabled(field: BulkAddItemsModel.FieldEnum): boolean {
    return this.formGroup.controls[field].value as boolean;
  }

  private onRemoveItem(data: Model.IRowData): void {
    const itemsControl = this.formGroup.controls[this.fieldEnum.ITEMS];
    const itemsControls = itemsControl.controls;

    if (itemsControls.length <= 1) {
      return;
    }

    const index = itemsControls.findIndex((control) => control === data.formGroup);

    itemsControl.removeAt(index);

    const rowNode = this.table.grid.api.getDisplayedRowAtIndex(index);

    if (rowNode) {
      this.table.grid.api.applyTransaction({ remove: [rowNode.data] });
    }

    this.detectDuplicateIds();

    const quantityControl = this.itemsQuantityFormGroup.controls["quantity"];

    quantityControl.updateValueAndValidity();
  }

  private onDuplicateItem(data: Model.IRowData): void {
    if (this.formGroup.controls[this.fieldEnum.ITEMS].controls.length >= Model.maxItemsCount) {
      return;
    }

    const itemsControls = this.formGroup.controls[this.fieldEnum.ITEMS].controls;
    const index = itemsControls.findIndex((control) => control === data.formGroup);
    const formGroup = itemsControls[index];
    const duplicatedItem = this.buildItem(formGroup);
    const duplicatedItemIndex = index + 1;

    this.formGroup.controls[this.fieldEnum.ITEMS].insert(duplicatedItemIndex, duplicatedItem);

    this.table.grid.api.applyTransaction({
      add: [{ formGroup: duplicatedItem }],
      addIndex: duplicatedItemIndex,
    });

    const quantityControl = this.itemsQuantityFormGroup.controls["quantity"];

    quantityControl.updateValueAndValidity();

    this.detectDuplicateIds();
  }

  private setColumnDefs(): void {
    const { fieldEnum, formGroup } = this;
    const { controls } = formGroup;
    const { fieldEnumToLabelMap } = BulkAddItemsModel;

    const columnDefs: ColDef[] = [
      {
        ...this.defaultColDefProperties(fieldEnum.ITEM_ID),
        headerName: fieldEnumToLabelMap[fieldEnum.ITEM_ID],
        valueGetter: (params: { data: Model.IRowData }) => {
          return params.data.formGroup;
        },
        suppressKeyboardEvent: () => true,
        pinned: "left",
        lockPinned: true,
        lockPosition: true,
        minWidth: 250,
        cellRenderer: InputCellRendererComponent,
        cellRendererParams: (params: { value: FormGroup<BulkAddItemsModel.ItemFormGroup> }) => {
          return {
            field: fieldEnum.ITEM_ID,
            shouldDisplayWarning: params.value.controls[fieldEnum.IS_ITEM_ID_DUPLICATED].value,
            warningTooltipText: Model.duplicatedIdOnTableMessage,
            actions: [
              {
                icon: "file_copy",
                tooltip: "Duplicate",
                disabled:
                  this.formGroup.controls[fieldEnum.ITEMS].controls.length >= Model.maxItemsCount,
                click: this.onDuplicateItem.bind(this),
              },
              {
                icon: "delete",
                tooltip: "Remove",
                disabled: this.formGroup.controls[fieldEnum.ITEMS].controls.length <= 1,
                click: this.onRemoveItem.bind(this),
              },
            ],
          };
        },
      },
    ];

    if (!controls[fieldEnum.IS_FIXED_INITIAL_QUANTITY].value) {
      columnDefs.push({
        ...this.defaultColDefProperties(fieldEnum.INITIAL_QUANTITY),
        headerName: fieldEnumToLabelMap[fieldEnum.INITIAL_QUANTITY],
        suppressKeyboardEvent: () => true,
        cellRendererParams: (params: { value: FormGroup<BulkAddItemsModel.ItemFormGroup> }) => {
          const formGroup = params.value;
          const unitOfMeasurementId =
            formGroup.controls[fieldEnum.UNIT_OF_MEASUREMENT].value?.value;

          let unit: IBaseUnit | ICustomUnitOfMeasurement;

          if (unitOfMeasurementId) {
            unit = [...this.baseUnitOfMeasurements, ...this.customUnitOfMeasurements].find(
              (unit) => unit.id === unitOfMeasurementId,
            );
          }

          return {
            field: fieldEnum.INITIAL_QUANTITY,
            inputType: "number",
            suffix: unit?.symbol,
          };
        },
        valueGetter: (params: { data: Model.IRowData }) => {
          return params.data.formGroup;
        },
        pinned: "left",
        lockPinned: true,
        lockPosition: true,
        cellRenderer: InputCellRendererComponent,
      });
    }

    if (this.bulkAddItemsService.delivery()) {
      columnDefs.push({
        ...this.defaultColDefProperties(fieldEnum.DELIVERY_QUANTITY),
        headerName: fieldEnumToLabelMap[fieldEnum.DELIVERY_QUANTITY],
        suppressKeyboardEvent: () => true,
        cellRendererParams: (params: { value: FormGroup<BulkAddItemsModel.ItemFormGroup> }) => {
          const formGroup = params.value;
          const unitOfMeasurementId =
            formGroup.controls[fieldEnum.UNIT_OF_MEASUREMENT].value?.value;

          let unit: IBaseUnit | ICustomUnitOfMeasurement;

          if (unitOfMeasurementId) {
            unit = [...this.baseUnitOfMeasurements, ...this.customUnitOfMeasurements].find(
              (unit) => unit.id === unitOfMeasurementId,
            );
          }

          return {
            field: fieldEnum.DELIVERY_QUANTITY,
            inputType: "number",
            suffix: unit?.symbol,
          };
        },
        valueGetter: (params: { data: Model.IRowData }) => {
          return params.data.formGroup;
        },
        pinned: "left",
        lockPinned: true,
        lockPosition: true,
        cellRenderer: InputCellRendererComponent,
      });
    }

    if (!controls[fieldEnum.IS_FIXED_PRODUCT].value) {
      columnDefs.push({
        ...this.defaultColDefProperties(fieldEnum.PRODUCT),
        headerName: fieldEnumToLabelMap[fieldEnum.PRODUCT],
        valueGetter: (params) => {
          return params.data.formGroup.controls[fieldEnum.PRODUCT].value?.label;
        },
        onCellClicked: (event: CellClickedEvent<Model.IRowData>) => {
          this.onClickEditProduct(event.data, event.event?.target);
        },
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: {
          actions: [
            {
              icon: "edit",
              tooltip: "Edit",
              click: (data: Model.IRowData, event: PointerEvent) => {
                this.onClickEditProduct(data, event.currentTarget);
              },
            },
          ],
        },
      });
    }

    if (!controls[fieldEnum.IS_FIXED_UNIT_OF_MEASUREMENT].value) {
      columnDefs.push({
        ...this.defaultColDefProperties(fieldEnum.UNIT_OF_MEASUREMENT),
        headerName: fieldEnumToLabelMap[fieldEnum.UNIT_OF_MEASUREMENT],
        valueGetter: (params) => {
          return params.data.formGroup.controls[fieldEnum.UNIT_OF_MEASUREMENT].value?.label;
        },
        onCellClicked: (event: CellClickedEvent<Model.IRowData>) => {
          if (event.data.formGroup.controls[fieldEnum.UNIT_OF_MEASUREMENT].disabled) {
            return;
          }

          this.onClickEditUnitOfMeasurement(event.data, event.event?.target);
        },
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: (params: { data: Model.IRowData }) => {
          return {
            actions: [
              {
                icon: "edit",
                tooltip: "Edit",
                disabled: params.data.formGroup.controls[fieldEnum.UNIT_OF_MEASUREMENT].disabled,
                click: (data: Model.IRowData, event: PointerEvent) => {
                  this.onClickEditUnitOfMeasurement(data, event.currentTarget);
                },
              },
            ],
          };
        },
      });
    }

    if (!controls[fieldEnum.IS_FIXED_MATERIALS].value && this.isOldMaterialsEnabled) {
      const cellRendererParams = (params: { data: Model.IRowData }) => {
        return {
          textParam: "label",
          cellContainerClass: "min-height-fix",
          actions: [
            {
              icon: "edit",
              tooltip: "Edit",
              disabled: params.data.formGroup.controls[fieldEnum.MATERIALS].disabled,
              click: (data: Model.IRowData, event: PointerEvent) => {
                this.onClickEditMaterials(data, event.currentTarget);
              },
            },
          ],
        };
      };

      const options: Partial<ColDef> = {
        ...this.defaultColDefProperties(fieldEnum.MATERIALS),
        maxWidth: 250,
        autoHeight: true,
        onCellClicked: (event: CellClickedEvent<Model.IRowData>) => {
          if (event.data.formGroup.controls[fieldEnum.MATERIALS].disabled) {
            return;
          }

          this.onClickEditMaterials(event.data, event.event?.target);
        },
        valueGetter: (params: { data: Model.IRowData }) => {
          return params.data.formGroup.controls[fieldEnum.MATERIALS].value;
        },
      };

      const colDef = ColumnUtils.chips(
        fieldEnumToLabelMap[fieldEnum.MATERIALS],
        "materials",
        cellRendererParams,
        options,
      );

      columnDefs.push(colDef);
    }

    if (!controls[fieldEnum.IS_FIXED_CREATED_AT_LOCATION].value) {
      columnDefs.push({
        ...this.defaultColDefProperties(fieldEnum.CREATED_AT_LOCATION),
        headerName: fieldEnumToLabelMap[fieldEnum.CREATED_AT_LOCATION],
        valueGetter: (params) => {
          return params.data.formGroup.controls[fieldEnum.CREATED_AT_LOCATION].value?.label;
        },
        onCellClicked: (event: CellClickedEvent<Model.IRowData>) => {
          this.onClickEditCreatedAtLocation(event.data, event.event?.target);
        },
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: {
          actions: [
            {
              icon: "edit",
              tooltip: "Edit",
              click: (data: Model.IRowData, event: PointerEvent) => {
                this.onClickEditCreatedAtLocation(data, event.currentTarget);
              },
            },
          ],
        },
      });
    }

    if (!controls[fieldEnum.IS_FIXED_CURRENT_LOCATION].value) {
      columnDefs.push({
        ...this.defaultColDefProperties(fieldEnum.CURRENT_LOCATION),
        headerName: fieldEnumToLabelMap[fieldEnum.CURRENT_LOCATION],
        valueGetter: (params) => {
          return params.data.formGroup.controls[fieldEnum.CURRENT_LOCATION].value?.label;
        },
        onCellClicked: (event: CellClickedEvent<Model.IRowData>) => {
          this.onClickEditCurrentLocation(event.data, event.event?.target);
        },
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: {
          actions: [
            {
              icon: "edit",
              tooltip: "Edit",
              click: (data: Model.IRowData, event: PointerEvent) => {
                this.onClickEditCurrentLocation(data, event.currentTarget);
              },
            },
          ],
        },
      });
    }

    if (!controls[fieldEnum.IS_FIXED_TAGS].value) {
      const cellRendererParams = {
        textParam: "tagDefinition.title",
        classParam: "tagDefinition.color",
        cellContainerClass: "min-height-fix",
        actions: [
          {
            icon: "edit",
            tooltip: "Edit",
            click: (data: Model.IRowData, event: PointerEvent) => {
              this.onClickEditTags(data, event.currentTarget);
            },
          },
        ],
      };

      const options: Partial<ColDef> = {
        ...this.defaultColDefProperties(fieldEnum.TAGS),
        minWidth: 250,
        autoHeight: true,
        onCellClicked: (event: CellClickedEvent<Model.IRowData>) => {
          this.onClickEditTags(event.data, event.event?.target);
        },
        valueGetter: (params: { data: Model.IRowData }) => {
          return params.data.formGroup.controls[fieldEnum.TAGS].value;
        },
      };

      const colDef = ColumnUtils.chips(
        fieldEnumToLabelMap[fieldEnum.TAGS],
        "tags",
        cellRendererParams,
        options,
      );

      columnDefs.push(colDef);
    }

    if (!controls[fieldEnum.IS_FIXED_CREATION_DATE].value) {
      columnDefs.push({
        ...this.defaultColDefProperties(fieldEnum.DATE_TYPE),
        headerName: "Creation date",
        onCellClicked: (event: CellClickedEvent<Model.IRowData>) => {
          this.onClickEditCreationDate(event.data, event.event?.target);
        },
        valueGetter: (params: { data: Model.IRowData }) => {
          const { controls } = params.data.formGroup;

          if (controls[fieldEnum.DATE_TYPE].value === DateTypeEnum.EXACT) {
            return this.formatDate(controls[fieldEnum.CREATED_FROM].value);
          } else {
            return controls[fieldEnum.CREATED_RANGE].value
              .map(this.formatDate.bind(this))
              .join(" - ");
          }
        },
        cellRendererParams: {
          actions: [
            {
              icon: "edit",
              tooltip: "Edit",
              click: (data: Model.IRowData, event: PointerEvent) => {
                this.onClickEditCreationDate(data, event.currentTarget);
              },
            },
          ],
        },

        cellRenderer: QuickActionsMenuComponent,
      });
    }

    this.columnDefs.set(columnDefs);
  }

  private getProductUnitOfMeasurements(productId: string): ISelectOption[] {
    if (!productId) {
      return [];
    }

    const product = this.allProducts.find((product) => product.id === productId);

    if (!product) {
      return [];
    }

    const baseUnitId = CommonUtils.getUriId(product.baseUnit);
    const baseUnit = this.baseUnitOfMeasurements.find((unit) => unit.id === baseUnitId);

    const customUnits = (product.customUnits || []).map((customUnitUri) => {
      return this.customUnitOfMeasurements.find(
        (customUnit) => CommonUtils.getUriId(customUnitUri) === customUnit.id,
      );
    });

    return [baseUnit, ...customUnits].map((unit) => {
      return { label: unit.name, value: unit.id };
    });
  }

  private getEditFieldDialogPosition(eventTarget: EventTarget): DialogPosition {
    const boundingRect = (eventTarget as HTMLElement)
      .closest(".cell-container")
      .getBoundingClientRect();

    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    const dialogWidth = Model.editDialogMaxWidth;
    const dialogHeight = Model.editDialogHeight;

    let topPosition = boundingRect.top - 60;
    let leftPosition = boundingRect.left - 20;

    if (topPosition + dialogHeight > viewportHeight) {
      topPosition = viewportHeight - dialogHeight - Model.editDialogViewportMargin;
    }
    if (leftPosition + dialogWidth > viewportWidth) {
      leftPosition = viewportWidth - dialogWidth - Model.editDialogViewportMargin;
    }

    if (topPosition < 0) {
      topPosition = Model.editDialogViewportMargin;
    }
    if (leftPosition < 0) {
      leftPosition = Model.editDialogViewportMargin;
    }

    return { top: `${topPosition}px`, left: `${leftPosition}px` };
  }

  private centerEditDialog(): void {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    const dialogWidth = Model.editDialogViewportMargin;
    const dialogHeight = Model.editDialogHeight;

    const topPosition = (viewportHeight - dialogHeight) / 2;
    const leftPosition = (viewportWidth - dialogWidth) / 2;

    this.dialogRef.updatePosition({ top: `${topPosition}px`, left: `${leftPosition}px` });
  }

  private async openEditFieldDialog<T>(
    data: Model.IRowData,
    formControlName: BulkAddItemsModel.FieldEnum,
    fieldType: BulkAddItemsEditFieldDialogModel.FieldTypeEnum,
    eventTarget: EventTarget,
    params?: object,
    onCancel?: (initialValues: BulkAddItemsModel.ItemFormRawValue) => void,
    onSuccess?: (newValue: T) => void,
  ) {
    const initialValues: BulkAddItemsModel.ItemFormRawValue = data.formGroup.getRawValue();

    this.dialogRef = this.dialog.open(BulkAddItemsEditFieldDialogComponent, {
      position: this.getEditFieldDialogPosition(eventTarget),
      panelClass: "reduced-padding",
      maxWidth: `${Model.editDialogMaxWidth}px`,
      data: {
        formGroup: data.formGroup,
        formControlName,
        fieldType,
        params: params || {},
      },
    });

    window.addEventListener("resize", this.centerEditDialog.bind(this));

    this.dialogRef
      .afterClosed()
      .subscribe(async (result: { hasSaved: boolean; newValue?: T; initialValue?: T }) => {
        if (result.hasSaved) {
          await onSuccess?.(result.newValue);

          this.setColumnDefs();

          this.cdr.detectChanges();

          return;
        }

        if (onCancel) {
          onCancel(initialValues);
        } else {
          data.formGroup.controls[formControlName].setValue(result.initialValue);
        }
      });
  }

  private defaultColDefProperties(formControlName: BulkAddItemsModel.FieldEnum): Partial<ColDef> {
    return {
      lockVisible: true,
      sortable: false,
      suppressSizeToFit: true,
      cellClass: (params: { data: Model.IRowData }) => {
        const statusClass = params.data.formGroup.controls[formControlName].disabled
          ? "disabled"
          : "clickable no-underline";

        return [formControlName, statusClass].join(" ");
      },
    };
  }

  private onClickEditProduct(data: Model.IRowData, eventTarget: EventTarget): void {
    this.openEditFieldDialog<ISelectOption>(
      data,
      this.fieldEnum.PRODUCT,
      BulkAddItemsEditFieldDialogModel.FieldTypeEnum.SELECT,
      eventTarget,
      {
        options: this.productOptions,
      },
      null,
      async (productOption: ISelectOption) => {
        await this.getMaterialAttachments(productOption);
        await this.onProductSelected(data.formGroup, productOption);
      },
    );
  }

  private onClickEditMaterials(data: Model.IRowData, eventTarget: EventTarget): void {
    this.openEditFieldDialog(
      data,
      this.fieldEnum.MATERIALS,
      BulkAddItemsEditFieldDialogModel.FieldTypeEnum.CHIPS,
      eventTarget,
      {
        options: this.getProductAllowedMaterials(
          data.formGroup?.controls[this.fieldEnum.PRODUCT]?.value?.value as string,
        ),
      },
    );
  }

  private onClickEditCreatedAtLocation(data: Model.IRowData, eventTarget: EventTarget): void {
    this.openEditFieldDialog(
      data,
      this.fieldEnum.CREATED_AT_LOCATION,
      BulkAddItemsEditFieldDialogModel.FieldTypeEnum.SELECT,
      eventTarget,
      {
        options: this.locationOptions,
        tooltip:
          "Provide information about where the item was first created / got a new identifier.",
      },
    );
  }

  private onClickEditCurrentLocation(data: Model.IRowData, eventTarget: EventTarget): void {
    this.openEditFieldDialog(
      data,
      this.fieldEnum.CURRENT_LOCATION,
      BulkAddItemsEditFieldDialogModel.FieldTypeEnum.SELECT,
      eventTarget,
      {
        options: this.locationOptions,
      },
    );
  }

  private onClickEditTags(data: Model.IRowData, eventTarget: EventTarget): void {
    this.openEditFieldDialog(
      data,
      this.fieldEnum.TAGS,
      BulkAddItemsEditFieldDialogModel.FieldTypeEnum.TAGS,
      eventTarget,
      {
        initialTags: data.formGroup.controls[this.fieldEnum.TAGS].value,
      },
    );
  }

  private onClickEditCreationDate(data: Model.IRowData, eventTarget: EventTarget): void {
    this.openEditFieldDialog(
      data,
      null,
      BulkAddItemsEditFieldDialogModel.FieldTypeEnum.DATE_PICKER,
      eventTarget,
      {
        dateTypeFormControlName: this.fieldEnum.DATE_TYPE,
        isRangeDateTypeFormControlName: this.fieldEnum.IS_RANGE_DATE_TYPE,
        fromFormControlName: this.fieldEnum.CREATED_FROM,
        rangeFormControlName: this.fieldEnum.CREATED_RANGE,
      },
      (initialValues: BulkAddItemsModel.ItemFormRawValue) => {
        const dateTypeInitialValue = initialValues[this.fieldEnum.DATE_TYPE];
        const isDateRangeTypeInitialValue = initialValues[this.fieldEnum.IS_RANGE_DATE_TYPE];
        const createdFromInitialValue = initialValues[this.fieldEnum.CREATED_FROM];
        const createdRangeInitialValue = initialValues[this.fieldEnum.CREATED_RANGE];

        data.formGroup.controls[this.fieldEnum.IS_RANGE_DATE_TYPE].setValue(
          isDateRangeTypeInitialValue,
        );
        data.formGroup.controls[this.fieldEnum.DATE_TYPE].setValue(dateTypeInitialValue);
        data.formGroup.controls[this.fieldEnum.CREATED_FROM].setValue(createdFromInitialValue);
        data.formGroup.controls[this.fieldEnum.CREATED_RANGE].setValue(createdRangeInitialValue);
      },
    );
  }

  private onClickEditUnitOfMeasurement(data: Model.IRowData, eventTarget: EventTarget): void {
    this.openEditFieldDialog(
      data,
      this.fieldEnum.UNIT_OF_MEASUREMENT,
      BulkAddItemsEditFieldDialogModel.FieldTypeEnum.SELECT,
      eventTarget,
      {
        options: data.formGroup?.controls[this.fieldEnum.UNIT_OF_MEASUREMENT_OPTIONS].value,
      },
    );
  }

  private getProductAllowedMaterials(productId: string): ISelectOption[] {
    if (!productId) {
      return [];
    }

    const product = this.allProducts.find((product) => product.id === productId);

    if (!product) {
      return [];
    }

    if (this.isOldMaterialsEnabled) {
      return product.allowedMaterials
        .map((allowedMaterial) => {
          const allowedMaterialId = CommonUtils.getUriId(allowedMaterial);

          return this.materialOptions.find(
            (materialOption) => materialOption.value === allowedMaterialId,
          );
        })
        .filter((materialOption) => materialOption !== undefined) as ISelectOption[];
    } else {
      const attachedMaterialsOptions = this.materialAttachments
        .map((attachment) => {
          const materialId = CommonUtils.getUriId(attachment.attachmentUri);

          return this.materialOptions.find((materialOption) => materialOption.value === materialId);
        })
        .filter((materialOption) => !!materialOption) as ISelectOption[];

      return attachedMaterialsOptions;
    }
  }

  private formatDate(date: string): string {
    return this.datePipe.transform(date, CommonConstants.DATE_FORMAT);
  }
}
