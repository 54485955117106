<div class="auth-container">
  <mat-icon class="logo-icon" svgIcon="logo-night"></mat-icon>
  <div class="outer-container">
    <div class="container">
      <div class="title">
        <h1>Access shared data</h1>
      </div>

      @if (isLoading()) {
        <app-loader></app-loader>
      } @else {
        <div class="subtitle">
          We have sent you a verification code to
          <b>{{ recipient }}</b>
          . Please, enter it below:
        </div>

        <div class="action-button-container">
          <form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
            <iov-input
              type="text"
              formControlName="verificationCode"
              label="Verification code"
              autofocus
            ></iov-input>

            <div class="details">
              <a
                class="clickable underline"
                (click)="onSendVerificationCode()"
                (keydown.enter)="onSendVerificationCode()"
                tabindex="0"
              >
                Re-send verification code
              </a>
            </div>

            <div class="login-button">
              <iov-button
                class="fresh-green"
                [disabled]="formGroup.invalid"
                accessibilityRole="submit"
                size="large"
              >
                Access
              </iov-button>
            </div>
          </form>
        </div>
      }
    </div>
  </div>
</div>
