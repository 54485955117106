import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from "@angular/core";

import { NEW_PASSWORD_MIN_LENGTH } from "../../../shared/validators";

@Component({
  selector: "app-password-requirements",
  templateUrl: "./password-requirements.component.html",
  styleUrls: ["./password-requirements.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordRequirementsComponent implements OnInit, OnChanges {
  @Input()
  public formErrors: any = null;

  @Input()
  public fieldErrors: any = null;

  @Input()
  public isConfirmPassword = false;

  public readonly requirements: any[] = [
    {
      text: `At least ${NEW_PASSWORD_MIN_LENGTH} characters`,
      type: "minlength",
    },
    {
      text: "A number",
      type: "noNumber",
    },
    {
      text: "A letter",
      type: "noLetter",
    },
    {
      text: "A special character",
      type: "noSpecialCharacter",
    },
  ];

  public ngOnInit(): void {
    if (this.isConfirmPassword) {
      this.requirements.push({
        text: "Passwords match",
        type: "noPasswordMatch",
      });
    }
  }

  public ngOnChanges(): void {
    if (this.formErrors) {
      this.fieldErrors = { ...this.fieldErrors, ...this.formErrors };
    }
  }
}
