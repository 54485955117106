<div class="header">
  @if (calendar.currentView === calendarViewEnum.MONTH) {
    <button class="arrow" (click)="previousArrowDefaultClicked(modeEnum.MONTH)">
      <mat-icon class="datepicker-arrow">keyboard_arrow_left</mat-icon>
    </button>
    <button class="label clickable" (click)="onClickMonth()">{{ monthLabel }}</button>
    <button class="arrow" (click)="nextArrowDefaultClicked(modeEnum.MONTH)">
      <mat-icon class="datepicker-arrow">keyboard_arrow_right</mat-icon>
    </button>
    <button class="arrow" (click)="previousArrowDefaultClicked(modeEnum.YEAR)">
      <mat-icon class="datepicker-arrow">keyboard_arrow_left</mat-icon>
    </button>
    <button class="label clickable" (click)="onClickYear()">{{ yearLabel }}</button>
    <button class="arrow" (click)="nextArrowDefaultClicked(modeEnum.YEAR)">
      <mat-icon class="datepicker-arrow">keyboard_arrow_right</mat-icon>
    </button>
  } @else {
    <button class="arrow" (click)="previousArrowClicked(calendar.currentView)">
      <mat-icon class="datepicker-arrow">keyboard_arrow_left</mat-icon>
    </button>
    <span class="label">{{ periodButtonText }}</span>
    <button class="arrow" (click)="nextArrowClicked(calendar.currentView)">
      <mat-icon class="datepicker-arrow">keyboard_arrow_right</mat-icon>
    </button>
  }
</div>
<div class="divider"></div>
