import { getProductFields } from "./queries.constants";

/**
 * Generates a GraphQL query to load product supply chain information.
 * @param orgId The ID of the organization.
 * @param productId The ID of the product.
 * @returns A GraphQL query string.
 */

export const getProductSupplyChainQuery = (
  orgId: string,
  productId: string,
  includes: string[] = [],
): string => {
  return `
    {
        loadProductSupplyChain(   
            orgId: "${orgId}",
            productId: "${productId}"
        ) {
            products {
                product ${getProductFields(["ALLOWED_MATERIALS", ...includes])}
                createdFrom ${getProductFields(["ALLOWED_MATERIALS", ...includes])}
                usedIn ${getProductFields(["ALLOWED_MATERIALS", ...includes])}
            }
       
        }
    }
`;
};
