import { Injectable, Inject, inject } from "@angular/core";

import { Observable, lastValueFrom } from "rxjs";

import { getProcessesByIdsGraphQLQuery, getProcessesGraphQLQuery } from "@shared/queries";
import { APP_CONFIG } from "@shared/tokens";

import { ApiService } from "./api.service";
import { GraphService } from "./graph.service";
import { CommonConstants } from "../../constants";
import { RecordStateEnum } from "../../enums";
import {
  IPageableContent,
  IRecordState,
  IProcessInput,
  IProcessOutput,
  IProcess,
  IProcessPayload,
  IConfig,
  IProcessExtended,
  IProcessGraphQLFilter,
  IProcessGraphQLResponse,
  IProcessByIdsGraphQLResponse,
} from "../../interfaces";
import { FormUtils } from "../../utils";
import { AuthenticationService } from "../authentication.service";

@Injectable({
  providedIn: "root",
})
export class ProcessesService {
  private graphService = inject(GraphService);

  private getBaseUrl = (): string =>
    `${this.environment.baseUrl}organisations/${this.authenticationService.getActiveOrganisationId()}/processes`;

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {}

  public getPage = async (
    processId: string = undefined,
    firstInputDate: string = undefined,
    lastOutputDate: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
    recordState: RecordStateEnum = undefined,
  ): Promise<IPageableContent<IProcess>> => {
    const url = `${this.getBaseUrl()}?${FormUtils.addUrlParams({
      processId,
      firstInputDate,
      lastOutputDate,
      size,
      page,
      sort,
      recordState,
    })}`;

    return await lastValueFrom(this.apiService.get<IPageableContent<IProcess>>(url));
  };

  public getPageSubscription = (
    processId: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
    recordState: RecordStateEnum = undefined,
  ): Observable<IPageableContent<IProcess>> => {
    const url = `${this.getBaseUrl()}?${FormUtils.addUrlParams({ processId, size, page, sort, recordState })}`;

    return this.apiService.get<IPageableContent<IProcess>>(url);
  };

  public async getAllGraphQL(
    filter: IProcessGraphQLFilter = null,
    first: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    include: string[] = [],
  ): Promise<IProcessExtended[]> {
    const result: IProcessExtended[] = [];
    let cursor: string | undefined = undefined;
    let hasNextPage: boolean = true;
    const activeOrganisationId = this.authenticationService.getActiveOrganisationId();

    while (hasNextPage) {
      const query = getProcessesGraphQLQuery(
        activeOrganisationId,
        filter,
        first,
        null,
        cursor,
        null,
        include,
      );
      const { content, pageInfo, totalCount } = await this.graphService.fetchSinglePage<
        IProcessGraphQLResponse,
        IProcessExtended
      >("loadProcesses", query, this.graphQLPropertiesToAddFunction);

      result.push(...content);

      cursor = pageInfo.endCursor;
      hasNextPage = result.length < totalCount;
    }

    return result;
  }

  public async getByIdsGraphQL(
    ids: string[],
    first: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    include: string[] = [],
  ): Promise<IProcessExtended[]> {
    if (!ids.length) {
      return [];
    }
    const result: IProcessExtended[] = [];
    let cursor: string | undefined = undefined;
    let hasNextPage: boolean = true;
    const activeOrganisationId = this.authenticationService.getActiveOrganisationId();

    while (hasNextPage) {
      const query = getProcessesByIdsGraphQLQuery(
        activeOrganisationId,
        ids,
        first,
        null,
        cursor,
        null,
        include,
      );
      const { content, pageInfo, totalCount } = await this.graphService.fetchSinglePage<
        IProcessByIdsGraphQLResponse,
        IProcessExtended
      >("loadProcessesByIds", query, this.graphQLPropertiesToAddFunction);

      result.push(...content);

      cursor = pageInfo.endCursor;
      hasNextPage = result.length < totalCount;
    }

    return result;
  }

  public get = async (processId: string): Promise<IProcess> =>
    await lastValueFrom(this.apiService.get<IProcess>(`${this.getBaseUrl()}/${processId}`));

  public delete = async (id: string): Promise<void> =>
    await this.apiService.delete<void>(`${this.getBaseUrl()}/${id}`).toPromise();

  public createOrUpdate = async (payload: IProcessPayload, id?: string): Promise<IProcess> => {
    if (id) {
      return await lastValueFrom(
        this.apiService.put<IProcess>(`${this.getBaseUrl()}/${id}`, payload),
      );
    } else {
      return await lastValueFrom(this.apiService.post<IProcess>(`${this.getBaseUrl()}`, payload));
    }
  };

  public getInput = async (processId: string, inputId: string): Promise<IProcessInput> =>
    await lastValueFrom(this.apiService.get(`${this.getBaseUrl()}/${processId}/inputs/${inputId}`));

  public getManyInputs = async (
    processId: string,
    startDate: string = undefined,
    endDate: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
  ): Promise<IPageableContent<IProcessInput>> => {
    const url = `${this.getBaseUrl()}/${processId}/inputs?${FormUtils.addUrlParams({
      startDate,
      endDate,
      size,
      page,
      sort,
    })}`;

    return await lastValueFrom(this.apiService.get(`${url}`));
  };

  public getOutput = async (processId: string, outputId: string): Promise<IProcessOutput> =>
    await lastValueFrom(
      this.apiService.get(`${this.getBaseUrl()}/${processId}/outputs/${outputId}`),
    );

  public getManyOutputs = async (
    processId: string,
    startDate: string = undefined,
    endDate: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
  ): Promise<IPageableContent<IProcessOutput>> => {
    const url = `${this.getBaseUrl()}/${processId}/outputs?${FormUtils.addUrlParams({
      startDate,
      endDate,
      size,
      page,
      sort,
    })}`;

    return await lastValueFrom(this.apiService.get(`${url}`));
  };

  public createInput = async (processId: string, payload: IProcessInput): Promise<IProcessInput> =>
    await lastValueFrom(
      this.apiService.post<IProcessInput>(`${this.getBaseUrl()}/${processId}/inputs`, payload),
    );

  public createOutput = async (
    processId: string,
    payload: IProcessOutput,
  ): Promise<IProcessOutput> =>
    await lastValueFrom(
      this.apiService.post<IProcessOutput>(`${this.getBaseUrl()}/${processId}/outputs`, payload),
    );

  public deleteInput = async (processId: string, inputId: string): Promise<void> =>
    await lastValueFrom(
      this.apiService.delete<void>(`${this.getBaseUrl()}/${processId}/inputs/${inputId}`),
    );

  public deleteOutput = async (processId: string, outputId: string): Promise<void> =>
    await lastValueFrom(
      this.apiService.delete<void>(`${this.getBaseUrl()}/${processId}/outputs/${outputId}`),
    );

  public setRecordState = async (payload: IRecordState, id: string): Promise<IProcess> =>
    await lastValueFrom(this.apiService.put<IProcess>(`${this.getBaseUrl()}/${id}`, payload));

  private graphQLPropertiesToAddFunction = (edge: any): any => {
    return {
      certificates: edge.node.certificates,
      documents: edge.node.documents,
    };
  };
}
