@if (submit$ | async) {}

@if (isLoading()) {
  <app-loader containerClass="on-dialog"></app-loader>
} @else {
  <form [formGroup]="form" (ngSubmit)="submit()">
    <h2 mat-dialog-title>{{ isEditing() ? "Edit" : "Add" }} risk level set</h2>
    <mat-dialog-content>
      <iov-input label="Name" formControlName="name" />

      <div class="container-flex">
        <h4>Levels</h4>
        <iov-button
          class="add-btn"
          type="icon"
          size="medium"
          [disabled]="isAddButtonDisabled"
          [tp]="
            isAddButtonDisabled
              ? 'A maximum of ' + maxAmountOfRiskLevels + ' risk levels can be added'
              : 'Add new risk level'
          "
          [tpIsLazy]="true"
          (pressed)="add()"
        >
          <mat-icon icon-medium-18>add</mat-icon>
        </iov-button>
      </div>

      @for (group of riskLevels.controls; track group; let index = $index) {
        <div [formGroup]="group" class="levels-container">
          <iov-input formControlName="title" placeholder="Title" #titleField />

          <iov-input-select
            formControlName="color"
            placeholder="Color"
            [options]="colorOptions"
            [prefixTemplate]="colorBox"
          ></iov-input-select>

          <iov-button
            class="delete-btn"
            type="icon"
            size="medium"
            [tp]="
              index < minAmountOfRiskLevels
                ? 'Risk level set requires a minimum of ' + minAmountOfRiskLevels + ' levels.'
                : 'Remove'
            "
            [disabled]="index < minAmountOfRiskLevels"
            [tpIsLazy]="true"
            (pressed)="remove(index)"
          >
            <mat-icon icon-medium-18>delete</mat-icon>
          </iov-button>
        </div>
      }
      <ng-template #colorBox let-option>
        <div [class]="option.value" class="color-box"></div>
      </ng-template>
    </mat-dialog-content>

    <mat-dialog-actions>
      <iov-button-group>
        <iov-button (pressed)="close()" type="ghost">Cancel</iov-button>
        <iov-button accessibilityRole="submit">
          <mat-icon icon-left>check</mat-icon>
          Save
        </iov-button>
      </iov-button-group>
    </mat-dialog-actions>
  </form>
}
