import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";

import { Toast } from "ngx-toastr";

@Component({
  template: `
    <mat-icon svgIcon="alert" class="alert-icon"></mat-icon>
    <div role="alert" [class]="options.messageClass" [attr.aria-label]="message">
      {{ message }}
    </div>
    <mat-icon svgIcon="close-2" (click)="remove()" aria-label="Close"></mat-icon>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, MatIconModule],
})
export class NotificationFailureComponent extends Toast {}
