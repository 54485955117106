import { Pipe, PipeTransform } from "@angular/core";

import {
  DeliveryStatusEnum,
  ResourceTypeEnum,
  UnitOfMeasurementCategoryTypeEnum,
  UnitOfMeasurementSourceTypeEnum,
} from "src/app/shared/enums";

import { CommonUtils } from "@shared/utils";

@Pipe({
  name: "enumToText",
})
export class EnumToTextPipe implements PipeTransform {
  transform(
    value:
      | DeliveryStatusEnum
      | ResourceTypeEnum
      | UnitOfMeasurementCategoryTypeEnum
      | UnitOfMeasurementSourceTypeEnum,
    plural: boolean = false,
  ): string {
    if (!value) {
      return "-";
    }
    let text = CommonUtils.enumToText(value.toString());

    if (plural) {
      text = CommonUtils.pluraliseEntity(text);
    }

    return text;
  }
}
