<div class="form-page-container">
  <div class="header-container">
    <app-page-title
      backText="Back to Rulesets"
      [goBackRoute]="routingEnum.ADMIN_RULESETS"
      [title]="isEditing() ? 'Edit ruleset - ' + element().name : 'Add ruleset'"
    ></app-page-title>
  </div>
  <div class="element-content">
    @if (isLoading()) {
      <app-loader></app-loader>
    } @else {
      <app-ruleset-delivery-info
        #rulesetDeliveryInfo
        [formGroup]="formGroup"
        (openDocumentTypeDialog)="openDocumentTypeDialog()"
        [allCountriesOption]="allCountries()"
        [allLocations]="allLocations()"
        (newLocationTypeCreated)="reloadLocationTypes()"
        [deliveryFromCriteria]="deliveryFromCriteria()"
        [deliveryToCriteria]="deliveryToCriteria()"
        (deliveryFromCriteriaChanged)="
          deliveryFromCriteriaChanged($event); updateSelectedListsChangedState()
        "
        (deliveryToCriteriaChanged)="
          deliveryToCriteriaChanged($event); updateSelectedListsChangedState()
        "
      ></app-ruleset-delivery-info>

      <app-document-list-items
        [documents]="selectedDocumentTypes()"
        (remove)="removeDocumentType($event)"
      ></app-document-list-items>
    }
  </div>
  <app-entity-save-footer
    (save)="onSave()"
    [isDisabled]="isSubmitButtonDisabled()"
    [showSubmitButton]="!isLoading()"
  ></app-entity-save-footer>
</div>
