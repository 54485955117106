<div [formGroup]="formGroup" class="row-container">
  <div class="row">
    <div class="item">
      <iov-input enableViewMode formControlName="deliveryId" label="ID"></iov-input>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <iov-input-select
        [options]="fromLocationOptions()"
        [viewModeTemplate]="locationViewTemplate()"
        enableViewMode
        formControlName="from"
        label="From"
      ></iov-input-select>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <iov-input-select
        [options]="toLocationOptions()"
        [viewModeTemplate]="locationViewTemplate()"
        enableViewMode
        formControlName="to"
        label="To"
      ></iov-input-select>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <iov-input-select
        [options]="statusOptions()"
        enableViewMode
        formControlName="status"
        label="Status"
      ></iov-input-select>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <iov-datepicker
        [maxDate]="formGroup.controls['delivered'].value"
        enableViewMode
        formControlName="sent"
        label="Sent"
      ></iov-datepicker>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <iov-datepicker
        [minDate]="formGroup.controls['sent'].value"
        enableViewMode
        formControlName="delivered"
        label="Delivered"
      ></iov-datepicker>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <iov-input-chips
        (tagPressed)="traderTagPressed.emit($event)"
        [isTagClickable]="true"
        [emitRawValue]="true"
        [options]="organisationsOptions()"
        enableViewMode
        formControlName="agents"
        label="Trader(s)"
        tooltip="State which organisation(s) performed the trade. Up to 5 organisations can be stated."
      ></iov-input-chips>
    </div>
  </div>
</div>
