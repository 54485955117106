import { ChangeDetectionStrategy, Component, signal } from "@angular/core";

import { RecordStateEnum } from "src/app/shared/enums";
import { IStandard } from "src/app/shared/interfaces";
import { AuthenticationService, StandardsService } from "src/app/shared/services";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { RouterService } from "@shared/services/router.service";

@Component({
  templateUrl: "./certificates.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CertificatesComponent {
  public isLoadingStandards = signal(true);

  public allStandards: IStandard[] = [];

  public readonly recordStateEnum = RecordStateEnum;

  public readonly canAddModifyEntities = this.authenticationService.canAddModifyEntities();

  constructor(
    private router: RouterService,
    private standardsService: StandardsService,
    private notificationService: NotificationService,
    public authenticationService: AuthenticationService,
  ) {
    this.onReloadStandards();
  }

  public onAdd = async (): Promise<boolean> => {
    return this.router.navigate(this.router.getCertificateLink());
  };

  private onReloadStandards = async (): Promise<void> => {
    this.isLoadingStandards.set(true);

    await this.standardsService
      .getAll()
      .then((response: IStandard[]) => {
        this.allStandards = response;
        this.isLoadingStandards.set(false);
      })
      .catch((error) => {
        this.notificationService.showError(error);
      });
  };
}
