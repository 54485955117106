<div class="slide-over-content">
  <div class="slide-over-title-container container-space-between">
    <app-page-title icon="library_add" [title]="title()"></app-page-title>
  </div>
  <iov-stepper>
    <ng-template
      iovStep
      title="Set values"
      [canGoBackTo]="true"
      (backClick)="onClickBackToFirstStep()"
    >
      @if (isLoading()) {
        <app-loader></app-loader>
      } @else {
        <app-bulk-add-items-set-values
          [formGroup]="formGroup"
          [productOptions]="productOptions"
          [locationOptions]="locationOptions"
        ></app-bulk-add-items-set-values>
      }
    </ng-template>
    <ng-template iovStep title="Enter items" [canGoForwardTo]="true" (forwardClick)="onClickNext()">
      @if (isLoading()) {
        <app-loader></app-loader>
      } @else {
        <app-bulk-add-items-enter-items
          #bulkAddItemsEnterItems
          [formGroup]="formGroup"
          [locationOptions]="locationOptions"
          [productOptions]="productOptions"
        ></app-bulk-add-items-enter-items>
      }
    </ng-template>
    <ng-template iovStep [title]="this.delivery ? 'Create and add items' : 'Create items'">
      @if (isLoading()) {
        <app-loader></app-loader>
      } @else {
        <app-bulk-add-items-create-items
          (creationProcessComplete)="onFinishedCreationProcess()"
          [formGroup]="formGroup"
        ></app-bulk-add-items-create-items>
      }
    </ng-template>
  </iov-stepper>
</div>

<div class="slide-over-buttons">
  <iov-button-group class="bottom-buttons flex-end-content">
    @if (stepper.currentStepIndex() === stepsEnum.ENTER_ITEMS) {
      <iov-button (pressed)="onClickBackToFirstStep()" type="outlined">Back</iov-button>
    }

    @if (stepper.currentStepIndex() < stepsEnum.CREATE_ITEMS) {
      <iov-button
        (pressed)="onClickNext()"
        [disabled]="isLoading() || isNextButtonDisabled"
        type="filled"
      >
        Next
      </iov-button>
    } @else if (displayDoneButton()) {
      <iov-button (pressed)="onClickDone()" type="filled">Done</iov-button>
    } @else {
      <iov-button (pressed)="onClickCancel()" type="filled">Cancel</iov-button>
    }
  </iov-button-group>
</div>
