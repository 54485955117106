import { ChangeDetectionStrategy, Component } from "@angular/core";

import { CriteriaTypeEnum, TargetPathEnum } from "src/app/shared/enums";
import { ILocationExtended, ILocationType } from "src/app/shared/interfaces";
import { RulesetUtils } from "src/app/shared/utils/ruleset.utils";

import { InputSelectOption } from "@design-makeover/components/inputs/input-select/input-select.model";

import { CommonConstants } from "@shared/constants";

import { RulesetBaseClass } from "../ruleset-base.class";

@Component({
  templateUrl: "./ruleset-details.component.html",
  styleUrl: "./ruleset-details.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RulesetDetailsComponent extends RulesetBaseClass {
  public readonly mainInformationText = CommonConstants.MAIN_INFORMATION_TEXT;

  constructor() {
    super();
  }

  getLocationTypesTags(locationTypes: ILocationType[]): InputSelectOption[] {
    if (locationTypes?.length) {
      return locationTypes.map((locationType) => ({
        label: locationType.type,
        value: locationType.type,
        icon: locationType.pointOfOrigin ? "target" : undefined,
        iconTooltip: locationType.pointOfOrigin
          ? CommonConstants.POINT_OF_ORIGIN_CHIP_TEXT
          : undefined,
      }));
    } else {
      return [{ label: "All" }];
    }
  }

  getLocationNames(locations: ILocationExtended[]): string[] {
    if (locations?.length) {
      return locations.map((location) => location.name);
    } else {
      return ["All"];
    }
  }

  protected override async loadData(rulesetId: string): Promise<void> {
    this.isLoading.set(true);
    try {
      this.element.set(await this.rulesetsService.get(rulesetId));
      this.resourceType = this.element().resourceType;
      this.setSelectedCountries();
      this.setSelectedLocationTypes();
      this.setSelectedDocumentTypes();
      if (this.element().resourceType === this.resourceTypeEnum.DELIVERY) {
        this.deliveryFromCriteria.set(
          RulesetUtils.tagExistsInCondition(this.element().condition, TargetPathEnum.FROM_LOCATION)
            ? CriteriaTypeEnum.EXACT
            : CriteriaTypeEnum.PARAMETERS,
        );

        this.deliveryToCriteria.set(
          RulesetUtils.tagExistsInCondition(this.element().condition, TargetPathEnum.TO_LOCATION)
            ? CriteriaTypeEnum.EXACT
            : CriteriaTypeEnum.PARAMETERS,
        );
        this.setSelectedLocationFromCountries();
        this.setSelectedLocationFromTypes();
        this.setSelectedLocationToCountries();
        this.setSelectedLocationToTypes();
        this.setSelectedLocationsTo();
        this.setSelectedLocationsFrom();
      }
    } catch (error) {
      this.notificationService.showError(error, true);
    }
    this.isLoading.set(false);
  }

  public onEdit = async (): Promise<void> => {
    const route =
      this.element().resourceType === this.resourceTypeEnum.DELIVERY
        ? this.routingEnum.ADMIN_RULESETS_EDIT_DELIVERY
        : this.routingEnum.ADMIN_RULESETS_EDIT_LOCATION;

    await this.router.navigate([`/${route}/${this.element().id}`]);
  };
}
