import { AfterViewInit, ChangeDetectionStrategy, Component, Input, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef } from "ag-grid-community";
import { EditDocumentTypeDialogComponent } from "src/app/components/settings";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import {
  LinkCellRendererComponent,
  ValueWithTooltipCellRendererComponent,
} from "@shared/cell-renderers";
import { CommonConstants } from "@shared/constants";
import { ConfirmDialogResponseEnum, RecordStateEnum, TableEnum } from "@shared/enums";
import { IDocumentType } from "@shared/interfaces";
import { DocumentTypesService } from "@shared/services";
import { RouterService } from "@shared/services/router.service";
import { ColumnUtils, CommonUtils } from "@shared/utils";

import { ConfirmDialogComponent, InfoDialogComponent } from "../..";

@Component({
  selector: "app-document-types-table",
  templateUrl: "./document-types-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentTypesTableComponent implements AfterViewInit {
  @Input()
  public documentTypes: IDocumentType[] = null;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isRecordStateFilterEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  @Input()
  public recordState: RecordStateEnum = RecordStateEnum.ALL;

  @Input()
  public table = TableEnum.DOCUMENT_TYPES;

  @Input()
  public isSaveTableState = false;

  @Input()
  public rulesetWarningTooltipText =
    "This delivery does not adhere to one or more rulesets applied to it.";

  @Input()
  public columns: string[] = ["recordState", "name"];

  public isLoading = signal(true);

  public rowData: IDocumentType[] = [];

  public columnDefs = signal<ColDef[]>([]);

  constructor(
    private documentTypesService: DocumentTypesService,
    private routerService: RouterService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) {}

  public async ngAfterViewInit() {
    this.setColumnDefs();
    await this.getAll();
  }

  private setColumnDefs = (): void => {
    const columnDefs: ColDef[] = [
      ColumnUtils.recordState(),
      { colId: "name", headerName: "Name", field: "name", lockVisible: true },
      { colId: "type", headerName: "Type", field: "name", lockVisible: true },
      {
        colId: "documentType",
        headerName: "Document type",
        field: "name",
        lockVisible: true,
        cellRenderer: ValueWithTooltipCellRendererComponent,
        cellRendererParams: (cell: { data: IDocumentType }) => {
          return {
            shouldDisplayIcon: () => cell.data.rulesetsNames?.length,
            icon: "warning",
            iconTooltip: this.rulesetWarningTooltipText,
          };
        },
      },
      ColumnUtils.chips("Rulesets", "rulesetsNames"),
      {
        colId: "documentNames",
        headerName: "Document name",
        field: "documentNames",
        lockVisible: true,
        valueGetter: (cell: { data: IDocumentType }) => {
          return (cell.data.documents || []).map((document) => {
            return { name: document.name, id: document.id };
          });
        },
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteIdParam: "id",
          linkRouteFn: this.routerService.getDocumentLink,
          textParam: "name",
        },
      },
    ];

    if (this.areButtonsEnabled) {
      const buttons = [
        {
          onClick: this.onDelete,
          tooltip: "Delete",
          icon: "delete",
        },
        {
          onClick: this.onToggleArchiveStatus,
          tooltip: (row) => (row.recordState === RecordStateEnum.ACTIVE ? "Archive" : "Unarchive"),
          icon: (row) => (row.recordState === RecordStateEnum.ACTIVE ? "archive" : "inventory"),
        },
        {
          onClick: this.onEdit,
          tooltip: "Edit",
          icon: "edit",
        },
      ];

      columnDefs.push(ColumnUtils.buttons(buttons));
    }
    this.columnDefs.set(CommonUtils.getVisibleColumnDefs(columnDefs, this.columns));
  };

  public onDelete = async (element: IDocumentType): Promise<void> => {
    if (!this.areButtonsEnabled) {
      return;
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: "Delete confirmation",
        contentTranslatedText: `Are you sure you want delete this document type?`,
        confirmButtonColor: "danger",
        confirmButtonTranslatedText: "Delete",
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        try {
          this.isLoading.set(true);
          await this.documentTypesService.delete(element.id);
          this.notificationService.showSuccess("Document type deleted");
          await this.getAll();
        } catch (error) {
          this.notificationService.showError(error);
          this.isLoading.set(false);
        }
      }
    });
  };

  public onEdit = async (element: IDocumentType): Promise<void> => {
    if (!this.areButtonsEnabled) {
      return;
    }
    if (element.recordState === RecordStateEnum.ARCHIVED) {
      this.dialog.open(InfoDialogComponent, {
        data: {
          icon: "error",
          iconColor: "red",
          contentTranslatedText: CommonConstants.EDIT_ARCHIVED_RECORD_ERROR_TEXT,
        },
      });

      return;
    }
    const dialogRef = this.dialog.open(EditDocumentTypeDialogComponent, {
      data: {
        documentType: element,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: { hasSaved: boolean }) => {
      if (result?.hasSaved) {
        await this.getAll();
      }
    });
  };

  public onToggleArchiveStatus = async (element: IDocumentType): Promise<void> => {
    const isArchived = element.recordState === RecordStateEnum.ARCHIVED;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: `${isArchived ? "Unarchive" : "Archive"} confirmation`,
        contentTranslatedText: `Are you sure that you want to ${isArchived ? "unarchive" : "archive"} this record?`,
        confirmButtonTranslatedText: isArchived ? "Unarchive" : "Archive",
        confirmButtonIcon: isArchived ? "inventory" : "archive",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        try {
          this.isLoading.set(true);
          const wasArchived = element.recordState === RecordStateEnum.ARCHIVED;

          await this.documentTypesService.setRecordState(
            {
              recordState: wasArchived ? RecordStateEnum.ACTIVE : RecordStateEnum.ARCHIVED,
            },
            element.id,
          );
          this.notificationService.showSuccess(wasArchived ? "Unarchived" : "Archived");
          await this.getAll();
        } catch (error) {
          this.notificationService.showError(error);
          this.isLoading.set(false);
        }
      }
    });
  };

  public getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    if (this.documentTypes) {
      this.rowData = this.documentTypes;
      this.isLoading.set(false);
    } else {
      try {
        this.rowData = await this.documentTypesService.getAll();
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };
}
