import { ChangeDetectionStrategy, Component, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { RecordStateEnum, RoutingEnum } from "src/app/shared/enums";

import { EditLocationTypeDialogComponent } from "..";
import { DocumentTypesTableComponent } from "../../shared";

@Component({
  templateUrl: "./settings-location-types.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsLocationTypesComponent {
  @ViewChild("locationTypesTable") locationTypesTable: DocumentTypesTableComponent;

  public readonly routingEnum = RoutingEnum;

  public readonly recordStateEnum = RecordStateEnum;

  constructor(private dialog: MatDialog) {}

  public onAdd = async (): Promise<void> => {
    const dialogRef = this.dialog.open(EditLocationTypeDialogComponent);

    dialogRef.afterClosed().subscribe(async (result: { hasSaved: boolean }) => {
      if (result?.hasSaved) {
        await this.locationTypesTable.getAll();
      }
    });
  };
}
