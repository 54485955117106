<div class="container-space-between">
  <app-page-title
    title="Certificates"
    hint="View and manage all of the certificates that your organisation has captured related to its supply chain,
        products, etc."
  ></app-page-title>

  <iov-button-group [visible]="canAddModifyEntities">
    <iov-button (pressed)="onAdd()">
      <mat-icon icon-left>add</mat-icon>
      Add new
    </iov-button>
  </iov-button-group>
</div>

<app-certificates-table
  *ngIf="!isLoadingStandards()"
  [allStandards]="allStandards"
  [recordState]="recordStateEnum.ACTIVE"
  [isSaveTableState]="true"
  [isFixedBottomPaginator]="true"
  [columns]="[
    'recordState',
    'number',
    'standard.name',
    'standardType.fullName',
    'issuanceDate',
    'validFromDate',
    'validToDate',
    'tags',
  ]"
></app-certificates-table>
