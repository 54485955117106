import { Inject, inject, Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { getLocationsByIdsGraphQLQuery, getLocationsGraphQLQuery } from "src/app/shared/queries";

import { APP_CONFIG } from "@shared/tokens";

import {
  ILocationByIdsGraphQLResponse,
  IPageableContent,
  IConfig,
  ILocation,
  ILocationDetails,
  ILocationExtended,
  ILocationGraphQLFilter,
  ILocationPayload,
  IRecordState,
  ILocationGraphQLResponse,
} from "./../../interfaces";
import { ApiService } from "./api.service";
import { GraphService } from "./graph.service";
import { CommonConstants } from "../../constants";
import { RecordStateEnum } from "../../enums";
import { FormUtils } from "../../utils";
import { AuthenticationService } from "../authentication.service";

@Injectable({
  providedIn: "root",
})
export class LocationsService {
  private graphService = inject(GraphService);

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {}

  public get getBaseUrl(): string {
    return `${this.environment.baseUrl}organisations/${this.authenticationService.getActiveOrganisationId()}/locations`;
  }

  public getPage = async (
    name: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort: string = undefined,
    recordState: RecordStateEnum = undefined,
  ): Promise<IPageableContent<ILocation>> => {
    return await this.getPageSubscription({
      name,
      size,
      page,
      sort,
      recordState,
    }).toPromise();
  };

  public getPageSubscription = ({
    name = undefined,
    size = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    sort = undefined,
    recordState = undefined,
  }: {
    name?: string;
    size?: number;
    page?: number;
    sort?: string;
    recordState?: RecordStateEnum;
  }): Observable<IPageableContent<ILocation>> => {
    const url = `${this.getBaseUrl}?${FormUtils.addUrlParams({ name, size, page, sort, recordState })}`;

    return this.apiService.get<IPageableContent<ILocation>>(url);
  };

  public async getAllGraphQL(
    filter: ILocationGraphQLFilter = null,
    first: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    include: string[] = [],
  ): Promise<ILocationExtended[]> {
    const result: ILocationExtended[] = [];
    let cursor: string | undefined = undefined;
    let hasNextPage: boolean = true;
    const activeOrganisationId = this.authenticationService.getActiveOrganisationId();

    while (hasNextPage) {
      const query = getLocationsGraphQLQuery(
        activeOrganisationId,
        filter,
        first,
        null,
        cursor,
        null,
        include,
      );
      const { content, pageInfo, totalCount } = await this.graphService.fetchSinglePage<
        ILocationGraphQLResponse,
        ILocationExtended
      >("loadLocations", query, this.graphQLPropertiesToAddFunction);

      result.push(...content);

      cursor = pageInfo.endCursor;
      hasNextPage = result.length < totalCount;
    }

    return result;
  }

  public async getByIdsGraphQL(
    ids: string[],
    first: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    include: string[] = [],
  ): Promise<ILocationExtended[]> {
    if (!ids.length) {
      return [];
    }
    const result: ILocationExtended[] = [];
    let cursor: string | undefined = undefined;
    let hasNextPage: boolean = true;
    const activeOrganisationId = this.authenticationService.getActiveOrganisationId();

    while (hasNextPage) {
      const query = getLocationsByIdsGraphQLQuery(
        activeOrganisationId,
        ids,
        first,
        null,
        cursor,
        null,
        include,
      );
      const { content, pageInfo, totalCount } = await this.graphService.fetchSinglePage<
        ILocationByIdsGraphQLResponse,
        ILocationExtended
      >("loadLocationsByIds", query, this.graphQLPropertiesToAddFunction);

      result.push(...content);

      cursor = pageInfo.endCursor;
      hasNextPage = result.length < totalCount;
    }

    return result;
  }

  public get = async (id: string): Promise<ILocationDetails> =>
    await this.apiService.get<ILocationDetails>(`${this.getBaseUrl}/${id}`).toPromise();

  public delete = async (id: string): Promise<void> =>
    await this.apiService.delete<void>(`${this.getBaseUrl}/${id}`).toPromise();

  public createOrUpdate = async (
    payload: ILocationPayload,
    id?: string,
  ): Promise<ILocationDetails> => {
    if (id) {
      return await this.apiService
        .put<ILocationDetails>(`${this.getBaseUrl}/${id}`, payload)
        .toPromise();
    } else {
      return await this.apiService
        .post<ILocationDetails>(`${this.getBaseUrl}`, payload)
        .toPromise();
    }
  };

  public setRecordState = async (payload: IRecordState, id: string): Promise<ILocationDetails> =>
    await this.apiService.put<ILocationDetails>(`${this.getBaseUrl}/${id}`, payload).toPromise();

  private graphQLPropertiesToAddFunction = (edge: any): any => {
    const activeOrganisation = this.authenticationService.getActiveOrganisation();

    return {
      types: edge.node.types,
      documents: edge.node.documents,
      certificates: edge.node.certificates,
      organisationName:
        (edge.node.connections && edge.node.connections[0]?.name) ?? activeOrganisation.name,
      organisationId:
        (edge.node.connections && edge.node.connections[0]?.id) ?? activeOrganisation.id,
    };
  };
}
