"use strict";

var util = require("./util");
var d3 = require("./d3");
var _ = require("./lodash");
module.exports = positionEdgeLabels;
function positionEdgeLabels(selection, g) {
  var created = selection.filter(function () {
    return !d3.select(this).classed("update");
  });
  function translate(e) {
    var edge = g.edge(e);
    return _.has(edge, "x") ? "translate(" + edge.x + "," + edge.y + ")" : "";
  }
  created.attr("transform", translate);
  util.applyTransition(selection, g).style("opacity", 1).attr("transform", translate);
}