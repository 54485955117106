<div class="container-space-between">
  <app-page-title title="Items"></app-page-title>

  <iov-button-group [visible]="canAddModifyEntities">
    <app-more-actions-buttons
      (clickLabel)="onAdd()"
      [isArchiveEnabled]="false"
      label="Add new"
      labelIcon="Add"
    >
      <iov-dropdown-menu-item (pressed)="onOpenBulkAddItemsSlideOver()">
        <mat-icon>library_add</mat-icon>
        Bulk-add items
      </iov-dropdown-menu-item>
    </app-more-actions-buttons>
  </iov-button-group>
</div>

<app-items-table
  [recordState]="recordStateEnum.ACTIVE"
  [isSaveTableState]="true"
  [isFixedBottomPaginator]="true"
></app-items-table>
