import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";

import { Observable, Subscription } from "rxjs";
import {
  EntityTypeEnum,
  FeatureFlagEnum,
  ResourceTypeEnum,
  RoutingEnum,
  SendTargetTypeEnum,
} from "src/app/shared/enums";
import { ILocation, ILocationLinkBase, IRecordResponse } from "src/app/shared/interfaces";
import { CommonUtils } from "src/app/shared/utils";

import { SlideOverlayPageService } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.service";
import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { SendDialogComponent } from "@components/index";
import { GeographicalSupplyChainMap } from "@components/shared/map/geographical-supply-map";
import { FeatureFlagService, RulesetsService } from "@shared/services";
import { RulesetUtils } from "@shared/utils/ruleset.utils";

import { SupplyChainAbstractClass } from "../supply-chain-abstract.class";

@Component({
  templateUrl: "./supply-chain-details.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplyChainDetailsComponent extends SupplyChainAbstractClass {
  public selectedTabIndex = 0;

  public selectedGeneralScreen = "graph";

  public locationsRulesetsRecords: IRecordResponse[];

  geographicalSupplyChainMap: GeographicalSupplyChainMap;

  public readonly isCrossOrgSharingEnabled = this.featureFlagService.isEnabled(
    FeatureFlagEnum.CROSS_ORGANISATION_SHARING,
  );

  public readonly isRegularUser = this.authenticationService.isRegularUser();

  public readonly isAccountOwnerOrAdminOrContributor =
    this.authenticationService.isAccountOwnerOrAdminOrContributor();

  public readonly canAddModifyEntities = this.authenticationService.canAddModifyEntities();

  public readonly entityTypeEnum = EntityTypeEnum;

  public readonly routingEnum = RoutingEnum;

  public locationsIds: string[] = [];

  public supplyChainHeight: number;

  private subscriptions = new Subscription();

  private dialog = inject(MatDialog);

  private notificationService = inject(NotificationService);

  constructor(
    private router: Router,
    private rulesetsService: RulesetsService,
    private slideOverlayPageService: SlideOverlayPageService,
    private cd: ChangeDetectorRef,
    private featureFlagService: FeatureFlagService,
  ) {
    super();

    this.subscriptions.add(
      this.slideOverlayPageService.trigger$.subscribe(async (params) => {
        if (params?.hasSaved) {
          await this.loadData(this.route.snapshot.params["id"]);
        }
      }),
    );
  }

  async loadData(id: string) {
    this.isLoading.set(true);
    this.supplyChainHeight = undefined;
    try {
      this.supplyChain = await this.getSupplyChainDetail(id);
      if (this.supplyChain === null) {
        throw "Record not found";
      }

      this.allDocumentTypes = await CommonUtils.handleApiCall(
        this.getDocumentTypes(),
        "Failed to fetch document types",
      );
      this.allStandards = await CommonUtils.handleApiCall(
        this.getStandards(),
        "Failed to fetch standards",
      );

      this.supplyChain.locations.forEach((l) => {
        l.documents = l.documents.filter((d) => d.isSelected);
        l.certificates = l.certificates.filter((c) => c.isSelected);
      });

      this.locations = this.supplyChain.locations as unknown as ILocation[];
      this.locationsIds = this.locations.map((location) => location.id);

      this.locationLinks = this.supplyChain.links;
      const locationLinksBase: ILocationLinkBase[] = this.locationLinks.map((link) => ({
        from: link.from.id,
        to: link.to.id,
      }));

      this.geographicalSupplyChainMap = new GeographicalSupplyChainMap(
        this.supplyChain.locations,
        locationLinksBase,
      );

      if (this.isRegularUser) {
        this.locationsRulesetsRecords = await this.getRulesetRecords(
          this.locationsIds,
          ResourceTypeEnum.LOCATION,
        );
      }

      this.isLoading.set(false);
      this.cd.detectChanges();
      this.supplyChainHeight = CommonUtils.getNonOverlaySupplyChainHeight();
    } catch (error) {
      this.notificationService.showError(error);
    }
  }

  private async getRulesetRecords(
    entitiesIds: string[],
    resourceType: ResourceTypeEnum,
  ): Promise<IRecordResponse[]> {
    const route = RulesetUtils.getResourceRoute(resourceType);
    const uniqueIds = new Set(entitiesIds);
    const records = [...uniqueIds].map(
      (id) => `/organisations/${this.activeOrganisation.id}/${route}/${id}`,
    );

    return await this.rulesetsService.getRulesetBasedOnRecord({
      resourceType,
      records,
    });
  }

  public onEdit = async (): Promise<void> => {
    await this.router.navigate([`/${RoutingEnum.SUPPLY_CHAINS_EDIT}/${this.supplyChain.id}`]);
  };

  public onTabIndexChange = (index: number): void => {
    this.selectedTabIndex = index;
  };

  public sendSupplyChain(): Observable<{ hasSaved: boolean }> {
    return this.dialog
      .open(SendDialogComponent, {
        data: {
          sendTargetType: SendTargetTypeEnum.SUPPLY_CHAIN,
          rootRecordUri: `/organisations/${this.activeOrganisation.id}/supply-chains/${this.supplyChain.id}`,
        },
      })
      .afterClosed();
  }
}
