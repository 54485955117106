export enum TagsColorEnum {
  LIGHT_GREY = "LIGHT_GREY",
  GREY = "GREY",
  BLUE = "BLUE",
  PURPLE = "PURPLE",
  GREEN = "GREEN",
  YELLOW = "YELLOW",
  ORANGE = "ORANGE",
  RED = "RED",
}
