@if (hasValue) {
  @if (copyMode === copyModeEnum.NONE) {
    <ng-container [ngTemplateOutlet]="valueTemplate"></ng-container>
  } @else {
    <div class="value clickable" (click)="copyMode === copyModeEnum.CONTAINER && onClick()">
      <ng-container [ngTemplateOutlet]="valueTemplate"></ng-container>
      @if (showCopyIcon) {
        <mat-icon
          class="copy-icon"
          svgIcon="copy"
          [tp]="translations.copy"
          (click)="copyMode === copyModeEnum.ICON && onClick()"
        ></mat-icon>
      }
    </div>
  }
  <ng-template #valueTemplate>
    @if (isHTML) {
      <span
        [class]="valueClass"
        [innerHtml]="value"
        (click)="copyMode === copyModeEnum.ICON && onValueClick()"
      ></span>
    } @else {
      <span [class]="valueClass" (click)="copyMode === copyModeEnum.ICON && onValueClick()">
        {{ value }}
      </span>
    }
  </ng-template>
} @else {
  <div class="value" [class]="valueClass">-</div>
}
