import { BrowserEnum } from "../enums";

const isFirefox = (): boolean => navigator.userAgent.indexOf("Firefox") !== -1;

const isSafari = (): boolean =>
  /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);

const getBrowser = async (): Promise<BrowserEnum> => {
  const userAgent = navigator.userAgent;
  const isChrome = userAgent.indexOf("Chrome") !== -1;

  if (isSafari()) {
    return BrowserEnum.SAFARI;
  } else if (isFirefox()) {
    return BrowserEnum.FIREFOX;
  } else if (isChrome) {
    const isOpera = userAgent.indexOf("OP") !== -1;
    const isEdge = userAgent.indexOf("Edg/") !== -1;
    const isBrave =
      ((navigator as any).brave && (await (navigator as any).brave.isBrave())) || false;

    if (isOpera) {
      return BrowserEnum.OPERA;
    } else if (isEdge) {
      return BrowserEnum.EDGE;
    } else if (isBrave) {
      return BrowserEnum.BRAVE;
    } else {
      return BrowserEnum.CHROME;
    }
  } else {
    //default
    return BrowserEnum.CHROME;
  }
};

export const BrowserUtils = {
  isFirefox,
  isSafari,
  getBrowser,
};
