import { AfterViewInit, ChangeDetectionStrategy, Component, Input, signal } from "@angular/core";
import { Router } from "@angular/router";

import { ColDef } from "ag-grid-community";
import {
  LinkCellRendererComponent,
  SlideOverCellRendererComponent,
} from "src/app/shared/cell-renderers";
import { RecordStateEnum, ResourceTypeEnum, RoutingEnum, TableEnum } from "src/app/shared/enums";
import { IRuleset } from "src/app/shared/interfaces";
import { AuthenticationService, RulesetsService } from "src/app/shared/services";
import { ColumnUtils } from "src/app/shared/utils";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

@Component({
  selector: "app-rulesets-table",
  templateUrl: "./rulesets-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RulesetsTableComponent implements AfterViewInit {
  @Input()
  public rulesets: IRuleset[];

  @Input()
  public resourceType: ResourceTypeEnum;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isRecordStateFilterEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  @Input()
  public recordState = RecordStateEnum.ALL;

  @Input()
  public table = TableEnum.RULESETS;

  @Input()
  public isSaveTableState = false;

  public totalElements: number = 0;

  public isLoading = signal(true);

  public rowData: IRuleset[] = [];

  public columnDefs = signal<ColDef[]>([]);

  constructor(
    private router: Router,
    private rulesetsService: RulesetsService,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService,
  ) {}

  public async ngAfterViewInit() {
    this.setColumnDefs();
    await this.getAll();
  }

  private setColumnDefs = (): void => {
    this.columnDefs.set([
      ColumnUtils.recordState(),
      {
        headerName: "Name",
        field: "name",
        lockVisible: true,
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRoute: RoutingEnum.ADMIN_RULESETS_DETAILS,
          linkRouteIdParam: "id",
        },
      },
      {
        headerName: "Description",
        field: "description",
        valueFormatter: ColumnUtils.defaultValueFormatter(),
      },
      {
        headerName: "Active",
        field: "active",
        resizable: false,
        suppressSizeToFit: true,
        suppressAutoSize: true,
        cellClass: "slide-over-cell-renderer-container",
        cellRenderer: SlideOverCellRendererComponent,
        cellRendererParams: {
          hide: (row) => row.recordState === RecordStateEnum.ARCHIVED,
          statusChange: this.onStatusChange,
          isDisabled: !this.authenticationService.canAddOrModifyRulesets,
          tooltip: (row) => (row.active ? "Deactivate" : "Activate"),
        },
      },
    ]);
  };

  public onViewDetails = async (row: IRuleset): Promise<void> => {
    await this.router.navigate([`/${RoutingEnum.ADMIN_RULESETS_DETAILS}/${row.id}`]);
  };

  public onStatusChange = async (element: IRuleset) => {
    element.active = !element.active;
    try {
      await this.rulesetsService.setActiveState({ active: element.active }, element.id);
      this.notificationService.showSuccess(
        `Ruleset ${element.active ? "activated" : "deactivated"}`,
      );
    } catch (error) {
      this.notificationService.showError(error);
      await this.getAll(); //todo find a better way of resetting the slide UI component.
    }
  };

  private getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    if (this.rulesets) {
      this.rowData = this.rulesets;
      this.totalElements = this.rowData.length;
      this.isLoading.set(false);
    } else {
      try {
        this.rowData = await this.rulesetsService.getAll(this.resourceType);
        this.totalElements = this.rowData.length;
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };
}
