import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";

import { BehaviorSubject, Observable } from "rxjs";

import { APP_CONFIG } from "@shared/tokens";

import { ApiService } from "./api.service";
import { IBaseUnit, IConfig, ISelectOption } from "../../interfaces";
import { LocalizationService } from "../localization.service";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  private readonly baseUrl: string = `${this.environment.baseUrl}common/`;

  private countriesOptionsSubject: BehaviorSubject<ISelectOption[]>;

  public countriesOptionsObservable$: Observable<ISelectOption[]>;

  private unitOfMeasurementsSubject: BehaviorSubject<IBaseUnit[]>;

  public unitOfMeasurementsObservable$: Observable<IBaseUnit[]>;

  private unitOfMeasurementsOptionsSubject: BehaviorSubject<ISelectOption[]>;

  public unitOfMeasurementsOptionsObservable$: Observable<ISelectOption[]>;

  constructor(
    private apiService: ApiService,
    private httpClient: HttpClient,
    private localizationService: LocalizationService,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {
    this.countriesOptionsSubject = new BehaviorSubject<ISelectOption[]>(null);
    this.countriesOptionsObservable$ = this.countriesOptionsSubject.asObservable();

    this.unitOfMeasurementsSubject = new BehaviorSubject<IBaseUnit[]>(null);
    this.unitOfMeasurementsObservable$ = this.unitOfMeasurementsSubject.asObservable();

    this.unitOfMeasurementsOptionsSubject = new BehaviorSubject<ISelectOption[]>(null);
    this.unitOfMeasurementsOptionsObservable$ =
      this.unitOfMeasurementsOptionsSubject.asObservable();
  }

  public loadCountries = async (): Promise<void> => {
    if (this.countriesOptionsSubject.value) {
      return;
    }

    const countriesJsonPath = `../../../../assets/data/localization/${this.localizationService.getLanguage()}/countries.json`;

    await this.apiService
      .get<string[]>(`${this.baseUrl}countries`)
      .toPromise()
      .then(async (countryCodes: string[]) => {
        this.httpClient.get(countriesJsonPath).subscribe((countriesJson: any) => {
          this.countriesOptionsSubject.next(
            countryCodes
              .filter((countryCode: string) => !!this.getCountryName(countriesJson, countryCode))
              .map((countryCode: string) => ({
                label: this.getCountryName(countriesJson, countryCode),
                value: countryCode,
              }))
              .sort((a: ISelectOption, b: ISelectOption) => (a.label > b.label ? 1 : -1)),
          );
        });
      })
      .catch((error: HttpErrorResponse) => {
        console.error(error);
      });
  };

  private getCountryName = (countriesJson: any[], countryCode: string): string => {
    const country = countriesJson.find((c) => c.alpha2 === countryCode);

    return country?.name;
  };

  public loadUnitOfMeasurements = async (): Promise<void> => {
    if (this.unitOfMeasurementsSubject.value) {
      return;
    }

    await this.apiService
      .get<IBaseUnit[]>(`${this.baseUrl}base-units`)
      .toPromise()
      .then((response: IBaseUnit[]) => {
        this.unitOfMeasurementsSubject.next(response);
        this.unitOfMeasurementsOptionsSubject.next(
          response.map((u: IBaseUnit) => ({
            label: u.name,
            value: u.id,
          })),
        );
      });
  };
}
