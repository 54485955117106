import { ChangeDetectionStrategy, Component, ViewChild } from "@angular/core";

import { BaseRulesetInfoClass } from "./base-ruleset-info.class";
import { LocationCriteriaInfoComponent } from "../location-criteria-info/location-criteria-info.component";

@Component({
  selector: "app-ruleset-location-info",
  templateUrl: "./ruleset-location-info.component.html",
  styleUrl: "./ruleset-shared-info.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RulesetLocationInfoComponent extends BaseRulesetInfoClass {
  @ViewChild("locationCriteria") locationCriteria: LocationCriteriaInfoComponent;

  constructor() {
    super();
  }
}
