<ng-template let-enableViewMode="enableViewMode" let-isFirst="isFirst" let-isLast="isLast">
  @if (!enableViewMode) {
    <mat-card-header>
      <iov-button
        type="icon"
        size="medium"
        tp="Move up"
        [tpIsLazy]="true"
        [disabled]="isFirst"
        (pressed)="moveUp.emit()"
      >
        <mat-icon icon-medium-18>keyboard_arrow_up</mat-icon>
      </iov-button>

      <iov-button
        type="icon"
        size="medium"
        tp="Move down"
        [tpIsLazy]="true"
        [disabled]="isLast"
        (pressed)="moveDown.emit()"
      >
        <mat-icon icon-medium-18>keyboard_arrow_down</mat-icon>
      </iov-button>

      <iov-button
        type="icon"
        size="medium"
        [disabled]="isDeleteDisabled()"
        [tp]="isDeleteDisabled() ? deleteDisabledText() : 'Delete'"
        [tpIsLazy]="true"
        (pressed)="remove.emit()"
      >
        <mat-icon icon-medium-18>delete</mat-icon>
      </iov-button>
    </mat-card-header>
  }
  <mat-card-content #content>
    <ng-content />
  </mat-card-content>
</ng-template>
