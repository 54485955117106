<h2 mat-dialog-title>{{ data.dialogTitle }}</h2>

@if (isLoading()) {
  <app-loader containerClass="on-dialog"></app-loader>
} @else {
  <form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
    <mat-dialog-content>
      <h4>{{ mainInformationText }}</h4>
      <div class="row-container">
        <div class="row row-2">
          <div class="item">
            <iov-input
              formControlName="itemId"
              hint="E.g. stock / batch number, etc."
              label="ID"
            ></iov-input>
          </div>
          <div class="item">
            <iov-input-select
              [options]="allProductsOptions"
              formControlName="product"
              label="Product"
            >
              <ng-container action>
                <iov-button
                  (pressed)="onAddProductClicked()"
                  class="icon-add-circle"
                  tp="Add new product"
                  type="icon"
                >
                  <mat-icon icon-small>add</mat-icon>
                </iov-button>
              </ng-container>
            </iov-input-select>
          </div>
          <div class="item">
            @if (isOldMaterialsEnabled) {
              <iov-input-chips
                [options]="materialOptions"
                [tooltip]="
                  formGroup.controls['product'].value
                    ? ''
                    : 'Please, first select a product to see which materials are allowed.'
                "
                formControlName="materials"
                label="Materials"
              >
                @if (formGroup.controls["product"].value) {
                  <ng-container action [ngTemplateOutlet]="addMaterialBtnTemplate"></ng-container>
                }
              </iov-input-chips>
            } @else {
              <div class="tag-group-container">
                <div class="container-flex-left">
                  <iov-form-label
                    label="Materials"
                    [tooltip]="
                      formGroup.controls['product'].value
                        ? ''
                        : 'Please select a product to see which materials it comprises of.'
                    "
                  ></iov-form-label>
                  @if (formGroup.controls["product"]?.value) {
                    <ng-container [ngTemplateOutlet]="addMaterialBtnTemplate"></ng-container>
                  }
                </div>
                @if (formGroup.controls["materials"].value?.length) {
                  <iov-tag-group [tags]="formGroup.controls['materials'].value"></iov-tag-group>
                } @else {
                  -
                }
              </div>
            }
          </div>
          <div class="item">
            <iov-input-select
              [options]="unitOfMeasurementOptions"
              formControlName="unitOfMeasurement"
              label="Unit of measurement"
            ></iov-input-select>
          </div>
          <div class="item">
            <iov-input
              [hint]="
                itemDefaultUnitOfMeasurement?.id === unitOfMeasurement?.id
                  ? ''
                  : (formGroup.controls['initialQuantity'].value
                    | unitConversion: itemDefaultUnitOfMeasurement : unitOfMeasurement)
              "
              [suffix]="itemDefaultUnitOfMeasurement?.symbol"
              formControlName="initialQuantity"
              [viewModeTemplate]="initialQuantityViewTemplate"
              label="Initial quantity"
              type="number"
            ></iov-input>
          </div>
          <div class="item">
            <div class="item">
              <iov-input-select
                [options]="createdAtLocationOptions"
                formControlName="createdAtLocation"
                label="Created at location"
                tooltip="Provide information about where the item was first created /
                            got a new identifier."
              ></iov-input-select>
            </div>
          </div>

          <div class="item">
            @if (datesType === dateTypeEnum.EXACT) {
              <iov-datepicker
                #datePicker
                class="no-padding"
                label="Creation date"
                formControlName="createdFrom"
              ></iov-datepicker>
            } @else if (datesType === dateTypeEnum.RANGE) {
              <iov-daterangepicker
                #datePicker
                class="no-padding"
                label="Creation date"
                formControlName="createdRange"
              ></iov-daterangepicker>
            }

            <div class="checkbox-row">
              <iov-checkbox formControlName="datesType" label="Range (from/to)"></iov-checkbox>
            </div>
          </div>

          <div class="item">
            <iov-input-select
              [options]="currentLocationOptions"
              class="no-padding"
              formControlName="currentLocation"
              label="Current / final location"
              tooltip="Provide information on what is the item's current location
                    (if the item still physically exists) or what was its last location if it has
                    been already transformed into other items / destroyed."
            ></iov-input-select>
          </div>

          <div class="checkbox-row">
            <iov-checkbox
              formControlName="isSameAsCreatedAtLocation"
              label="Same as created at location"
            ></iov-checkbox>
          </div>

          <div class="item">
            <iov-input
              [suffix]="itemDefaultUnitOfMeasurement?.symbol"
              [viewModeTemplate]="deliveryQuantityViewTemplate"
              class="no-padding"
              formControlName="deliveredQty"
              label="Delivery quantity"
              type="number"
            ></iov-input>
          </div>

          <div class="checkbox-row">
            <iov-checkbox
              formControlName="isDeliveredQtySameAsInitial"
              label="Same as initial quantity"
            ></iov-checkbox>
          </div>
        </div>
      </div>
      @if (visibleCustomFields?.length) {
        <app-custom-fields-edit
          [columns]="1"
          [formGroup]="formGroup"
          [visibleCustomFields]="visibleCustomFields"
        ></app-custom-fields-edit>
      }
    </mat-dialog-content>

    <mat-dialog-actions>
      <iov-button-group>
        <iov-button (pressed)="onClose(false)" type="ghost">Cancel</iov-button>
        <iov-button accessibilityRole="submit">
          <mat-icon icon-left>check</mat-icon>
          Save
        </iov-button>
      </iov-button-group>
    </mat-dialog-actions>
  </form>
}
<ng-template #addMaterialBtnTemplate>
  <iov-button
    (pressed)="onAddMaterialClicked()"
    class="icon-add-circle"
    tp="Add new material"
    type="icon"
  >
    <mat-icon icon-small>add</mat-icon>
  </iov-button>
</ng-template>
<ng-template #initialQuantityViewTemplate let-data>
  @if (unitOfMeasurement) {
    @if (hasDifferentDefaultUnit && defaultUnitOfMeasurement) {
      <iov-form-input-readonly
        [label]="data.label"
        [value]="initialQuantityFormatted"
      ></iov-form-input-readonly>
    } @else {
      <iov-form-input-readonly
        [label]="data.label"
        [value]="
          (formGroup.controls['initialQuantity'].value
            | number: '1.0-' + unitOfMeasurement?.precision ?? '0') +
          ' ' +
          unitOfMeasurement?.symbol
        "
      ></iov-form-input-readonly>
    }
  } @else {
    <app-not-shared-data></app-not-shared-data>
  }
</ng-template>

<ng-template #deliveryQuantityViewTemplate let-data>
  @if (unitOfMeasurement) {
    @if (hasDifferentDefaultUnit && defaultUnitOfMeasurement) {
      <iov-form-input-readonly
        [label]="data.label"
        [value]="deliveredQuantityFormatted"
      ></iov-form-input-readonly>
    } @else {
      <iov-form-input-readonly
        [label]="data.label"
        [value]="
          (formGroup.controls['deliveryQty'].value
            | number: '1.0-' + unitOfMeasurement?.precision ?? '0') +
          ' ' +
          unitOfMeasurement?.symbol
        "
      ></iov-form-input-readonly>
    }
  } @else {
    <app-not-shared-data></app-not-shared-data>
  }
</ng-template>
