/* eslint-disable @typescript-eslint/no-unused-vars,@typescript-eslint/ban-ts-comment,@typescript-eslint/no-explicit-any */

import { ChangeDetectorRef, inject, signal } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";

import { Subscription } from "rxjs";

import { OverlayCommonService } from "@design-makeover/components/overlay/overlay-common.service";
import { SlideOverlayPageService } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.service";
import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { ConfirmDialogComponent } from "@components/shared";
import { CommonConstants } from "@shared/constants";
import {
  AttachmentTargetEnum,
  ConfirmDialogResponseEnum,
  CustomFieldsResourceTypeEnum,
  EntityTypeEnum,
  OverlayTabEnum,
  RecordStateEnum,
  RoutingEnum,
} from "@shared/enums";
import {
  ICustomField,
  IOverlayMenuItem,
  IRecordState,
  ITag,
  ITagExtended,
} from "@shared/interfaces";
import { AuthenticationService, CustomFieldsService } from "@shared/services";
import { CanDeactiveDialogService } from "@shared/services/can-deactive-dialog.service";
import { RouterService } from "@shared/services/router.service";
import { CommonUtils, FormUtils } from "@shared/utils";

import { SlideOverlayContentComponent } from "../slide-overlay-content/slide-overlay-content.component";

export abstract class SlideOverlayPageClass {
  hasFormValuesChanged = false;

  initialFormValue = null;

  hasSaved: boolean = false;

  element: any = {};

  entityType: EntityTypeEnum;

  attachmentTargetType: AttachmentTargetEnum;

  selectedTagsForNewRecord: ITagExtended[] = [];

  initialTags: ITag[] = [];

  menuItems = signal<Map<string, IOverlayMenuItem>>(undefined);

  public isEditing = signal(false);

  public visibleCustomFields: ICustomField[] = [];

  protected allCustomFields: ICustomField[] = [];

  readonly overlayTabEnum = OverlayTabEnum;

  protected canDeactivateDialogService = inject(CanDeactiveDialogService);

  protected authenticationService = inject(AuthenticationService);

  protected dialog = inject(MatDialog);

  protected notification = inject(NotificationService);

  protected overlay = inject(SlideOverlayPageService);

  protected formBuilder = inject(FormBuilder);

  protected routerService = inject(RouterService);

  protected route = inject(ActivatedRoute);

  protected changeDetectorRef = inject(ChangeDetectorRef);

  protected subscriptions = new Subscription();

  protected slideOverlayContent: SlideOverlayContentComponent;

  private customFieldsService = inject(CustomFieldsService);

  public readonly canAddModifyEntities = this.authenticationService.canAddModifyEntities();

  public readonly isRegularUser = this.authenticationService.isRegularUser();

  public readonly activeOrganisationId = this.authenticationService.getActiveOrganisationId();

  public readonly mainInformationText = CommonConstants.MAIN_INFORMATION_TEXT;

  public readonly additionalInformationText = CommonConstants.ADDITIONAL_INFORMATION_TEXT;

  get shouldUpdateTable(): boolean {
    return true;
  }

  get recordId(): string {
    return this.route.snapshot.queryParams["id"];
  }

  get tabQueryParam(): string {
    return this.route.snapshot.queryParams["tab"];
  }

  get organisationIdQueryParam(): string {
    return this.route.snapshot.queryParams["organisationId"];
  }

  get isSubmitButtonDisabled(): boolean {
    return true;
  }

  get isRecordActive(): boolean {
    return this.element?.recordState === RecordStateEnum.ACTIVE;
  }

  get isRecordArchived(): boolean {
    return this.element?.recordState === RecordStateEnum.ARCHIVED;
  }

  getProperty(propertyName: string): any {
    return this.element?.[propertyName];
  }

  get entityUri(): string {
    return `/organisations/${this.activeOrganisationId}/${this.entityType}/${this.element.id}`;
  }

  protected get requiresConfirmation(): boolean {
    return this.hasFormValuesChanged;
  }

  isOnCorrectOverlay = (route: RoutingEnum): boolean =>
    this.route.snapshot.queryParams["view"]?.toLowerCase() === route.toLowerCase();

  canClose(): Promise<boolean> {
    return this.canExit(true);
  }

  canChangeMenuItem(): Promise<boolean> {
    return this.canExit(false);
  }

  setupForm(): void {}

  setEditMode = (): void => {
    this.menuItems().forEach((m: IOverlayMenuItem) => (m.isEnabled = true));
    this.changeDetectorRef.detectChanges(); //todo this should not be needed. It's only needed for risk assessment template overlay -> indicators tab when refreshing the page.  Investigate.

    this.isEditing.set(true);
  };

  async save(): Promise<boolean> {
    return false;
  }

  async afterSave(_isSaveOnly = true): Promise<void> {}

  async handleDiscardChanges(): Promise<void> {
    await this.setupForm();
  }

  async confirmUnarchiveDialog(): Promise<void> {
    this.confirmToggleArchiveStatusDialog("Unarchive");
  }

  async confirmArchiveDialog(): Promise<void> {
    this.confirmToggleArchiveStatusDialog("Archive");
  }

  async confirmDuplicateDialog(): Promise<void> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: "Duplicate record",
        contentTranslatedText: `Are you sure that you want to duplicate this record?`,
        confirmButtonTranslatedText: "Duplicate",
        confirmButtonIcon: "content_copy",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        await this.duplicateRecord(this.recordId);
      }
    });
  }

  async confirmDeleteDialog(): Promise<void> {
    const id: string = this.element?.id;

    if (!id) {
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: "Delete record",
        contentTranslatedText: `Are you sure that you want to delete this record?`,
        confirmButtonColor: "danger",
        confirmButtonTranslatedText: "Delete",
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        try {
          await this.deleteRecord(id);
          this.updateTable();
          this.notification.showSuccess("Record deleted");
          this.routerService.closeViewOutlet();
        } catch (error) {
          this.notification.showError(error);
        }
      }
    });
  }

  updateTable(): void {
    if (this.shouldUpdateTable) {
      this.overlay.updateTable();
    }
  }

  title(): string {
    const recordId = this.recordId;

    if (recordId && this.overlay.loading()) {
      return "Loading...";
    }

    return recordId ? this.recordName() : this.newItemTitle();
  }

  onCommentAdded(overlayService: OverlayCommonService): void {
    overlayService.commentCounter.set(overlayService.commentCounter() + 1);
  }

  onCommentDeleted(overlayService: OverlayCommonService): void {
    overlayService.commentCounter.set(overlayService.commentCounter() - 1);
  }

  onHasSaved(): void {
    this.hasSaved = true;
  }

  protected recordName(): string {
    return this.element?.name;
  }

  protected newItemTitle(): string {
    const postfix = this.route.snapshot.queryParams["view"]?.replace(/-/g, " ");

    return `Add ${postfix}`;
  }

  protected hasInitialFormValueChanged(currentFormValue: object): boolean {
    return FormUtils.hasInitialFormValueChanged(this.initialFormValue, currentFormValue);
  }

  onEditConnection = (): void => this.editConnection();

  onRemoveConnection = (): void => this.removeConnection();

  protected async saveBeforeClosing(): Promise<void> {
    await this.overlay.save(false);
  }

  // @ts-ignore
  protected async reloadElement(id: string): Promise<void> {}

  // @ts-ignore
  protected async duplicateRecord(id: string): Promise<void> {}

  // @ts-ignore
  protected async deleteRecord(id: string): Promise<void> {}

  // @ts-ignore
  protected async archiveRecord(id: string, payload: IRecordState): Promise<void> {}

  // @ts-ignore
  protected editConnection = (): void => {};

  // @ts-ignore
  protected removeConnection = (): void => {};

  setMenuItemFromURLParam = async (): Promise<void> => {
    if (this.recordId && this.tabQueryParam) {
      await this.changeMenuItem(CommonUtils.enumToText(this.tabQueryParam));
    }
  };

  protected changeMenuItem = async (title: string): Promise<void> => {
    if (!title) {
      return;
    }
    title = title.toLowerCase();
    const menuItem = this.slideOverlayContent.menuItems.find(
      (t) => t.title().toLowerCase() === title,
    );

    if (menuItem) {
      await this.slideOverlayContent.selectMenuItem(menuItem);
    }
  };

  private async canExit(exitOverlay: boolean = true): Promise<boolean> {
    if (!this.authenticationService.haveTokensExpired() && this.requiresConfirmation) {
      let response: ConfirmDialogResponseEnum;

      if (exitOverlay) {
        response = await this.canDeactivateDialogService.showDialog(this.isSubmitButtonDisabled);
      } else {
        response = await this.canDeactivateDialogService.showOverlayMenuDialog(
          this.isSubmitButtonDisabled,
        );
      }

      switch (response) {
        case ConfirmDialogResponseEnum.CONFIRM:
          await this.saveBeforeClosing();

          return true;
        case ConfirmDialogResponseEnum.THIRD_ACTION:
          return true;
        default:
          return false;
      }
    } else {
      return true;
    }
  }

  protected setAllCustomFields = async (
    resourceType: CustomFieldsResourceTypeEnum,
  ): Promise<void> => {
    this.allCustomFields = await this.customFieldsService.getAll(resourceType);
  };

  private confirmToggleArchiveStatusDialog(action: string): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: "Confirmation",
        contentTranslatedText: `Are you sure that you want to ${action.toLowerCase()} this record?`,
        confirmButtonTranslatedText: action,
        confirmButtonIcon: action === "Archive" ? "archive" : "inventory",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        const currentState = this.element?.recordState;

        try {
          const wasArchived = action !== "Archive";
          const newState = wasArchived ? RecordStateEnum.ACTIVE : RecordStateEnum.ARCHIVED;

          this.element.recordState = newState;
          await this.archiveRecord(this.element.id, {
            recordState: newState,
          });
          this.notification.showSuccess(wasArchived ? "Unarchived" : "Archived");
          await this.reloadElement(this.element.id);
          this.updateTable();
        } catch (error) {
          this.element.recordState = currentState;
          this.notification.showError(error);
        }
      }
    });
  }
}
