import { Injectable } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

interface Icon {
  name: string;
  path?: string;
  content?: string;
}

@Injectable({
  providedIn: "root",
})
export class IconService {
  private icons: Icon[] = [
    { name: "action_completed", path: "../../../assets/icons/action-completed.svg" },
    { name: "action_pending", path: "../../../assets/icons/action-pending.svg" },
    { name: "admin", path: "../../../assets/icons/admin.svg" },
    { name: "alert", path: "../../../assets/icons/alert.svg" },
    { name: "analysis", path: "../../../assets/icons/analysis.svg" },
    {
      name: "alert-triangle",
      content: `
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round">
                    <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0"></path>
                    <line x1="12" y1="9" x2="12" y2="13"></line>
                    <line x1="12" y1="17" x2="12.01" y2="17"></line>
                </svg>
            `,
    },
    { name: "arrow_back", path: "../../../assets/icons/arrow-back.svg" },
    { name: "arrow_forward", path: "../../../assets/icons/arrow-forward.svg" },
    { name: "calendar", path: "../../../assets/icons/calendar.svg" },
    { name: "cancel-2", path: "../../../assets/icons/delete.svg" },
    { name: "certificates", path: "../../../assets/icons/certificates.svg" },
    { name: "check-1", path: "../../../assets/icons/check-1.svg" },
    { name: "check-2", path: "../../../assets/icons/check-2.svg" },
    { name: "chevron-left", path: "../../../assets/icons/chevron-left.svg" },
    { name: "chevron-right", path: "../../../assets/icons/chevron-right.svg" },
    { name: "close", path: "../../../assets/icons/close.svg" },
    { name: "close-2", path: "../../../assets/icons/close-2.svg" },
    { name: "comment", path: "../../../assets/icons/comment.svg" },
    { name: "copy", path: "../../../assets/icons/copy.svg" },
    { name: "deliveries", path: "../../../assets/icons/deliveries.svg" },
    { name: "document", path: "../../../assets/icons/document.svg" },
    { name: "double-arrow", path: "../../../assets/icons/double-arrow.svg" },
    { name: "download", path: "../../../assets/icons/download.svg" },
    { name: "eye", path: "../../../assets/icons/eye.svg" },
    { name: "eye_off", path: "../../../assets/icons/eye-off.svg" },
    { name: "geo-pinpoint-bordered", path: "../../../assets/icons/geo-pinpoint-bordered.svg" },
    { name: "geo-pinpoint-dashed", path: "../../../assets/icons/geo-pinpoint-dashed.svg" },
    { name: "group-216", path: "../../../assets/icons/group-216.svg" },
    { name: "help", path: "../../../assets/icons/help.svg" },
    { name: "inbox", path: "../../../assets/icons/inbox.svg" },
    { name: "inbox-menu", path: "../../../assets/icons/inbox-menu.svg" },
    { name: "info-black", path: "../../../assets/icons/info-black.svg" },
    { name: "info-gray", path: "../../../assets/icons/info-gray.svg" },
    { name: "items", path: "../../../assets/icons/items.svg" },
    { name: "keyboard_arrow_down", path: "../../../assets/icons/keyboard-arrow-down.svg" },
    { name: "keyboard_arrow_left", path: "../../../assets/icons/keyboard-arrow-left.svg" },
    { name: "keyboard_arrow_right", path: "../../../assets/icons/keyboard-arrow-right.svg" },
    { name: "keyboard_arrow_up", path: "../../../assets/icons/keyboard-arrow-up.svg" },
    { name: "locations", path: "../../../assets/icons/locations.svg" },
    { name: "lock", path: "../../../assets/icons/lock.svg" },
    { name: "linked-locations", path: "../../../assets/icons/linked-locations.svg" },
    { name: "logo-night", path: "../../../assets/icons/logo-night.svg" },
    { name: "logo-symbol-night", path: "../../../assets/icons/logo-symbol-night.svg" },
    { name: "input", path: "../../../assets/icons/log-in.svg" },
    { name: "output", path: "../../../assets/icons/log-out.svg" },
    { name: "map", path: "../../../assets/icons/map.svg" },
    { name: "map-pin", path: "../../../assets/icons/map-pin.svg" },
    { name: "maximize-2", path: "../../../assets/icons/maximize-2.svg" },
    { name: "menu-info", path: "../../../assets/icons/overlay-menu-info.svg" },
    { name: "menu-link", path: "../../../assets/icons/overlay-menu-links.svg" },
    { name: "minus", path: "../../../assets/icons/minus.svg" },
    { name: "more-horizontal", path: "../../../assets/icons/more_horiz.svg" },
    { name: "orbify", path: "../../../assets/icons/orbify.svg" },
    { name: "organisation-members", path: "../../../assets/icons/organisation-members.svg" },
    { name: "organisations", path: "../../../assets/icons/organisations.svg" },
    { name: "paperclip", path: "../../../assets/icons/paperclip.svg" },
    { name: "plus", path: "../../../assets/icons/plus.svg" },
    { name: "plus-2", path: "../../../assets/icons/plus-2.svg" },
    { name: "processes", path: "../../../assets/icons/processes.svg" },
    { name: "refresh", path: "../../../assets/icons/refresh.svg" },
    { name: "search", path: "../../../assets/icons/search.svg" },
    { name: "settings", path: "../../../assets/icons/settings.svg" },
    { name: "share", path: "../../../assets/icons/share.svg" },
    { name: "supply-chain", path: "../../../assets/icons/supply-chain.svg" },
    { name: "upload", path: "../../../assets/icons/upload.svg" },
    { name: "users", path: "../../../assets/icons/users.svg" },
    { name: "x-circle", path: "../../../assets/icons/x-circle.svg" },
    { name: "tape", path: "../../../assets/icons/tape.svg" },
    { name: "products", path: "../../../assets/icons/products.svg" },
    { name: "sync", path: "../../../assets/icons/sync.svg" },
    { name: "category", path: "../../../assets/icons/category.svg" },
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {}

  registerIcons(): void {
    this.matIconRegistry.setDefaultFontSetClass("material-symbols-outlined");
    this.icons.forEach((icon) => {
      if (icon.path) {
        this.matIconRegistry.addSvgIcon(
          icon.name,
          this.sanitizer.bypassSecurityTrustResourceUrl(icon.path),
        );
      } else if (icon.content) {
        this.matIconRegistry.addSvgIconLiteral(
          icon.name,
          this.sanitizer.bypassSecurityTrustHtml(icon.content),
        );
      }
    });
  }
}
