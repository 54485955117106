export enum CrossOrgShareDataTypeEnum {
  DOCUMENT_TYPES = "document-types",
  LOCATION_TYPES = "location-types",
  SUPPLY_CHAINS = "supply-chains",
  DOCUMENTS = "documents",
  CERTIFICATES = "certificates",
  CONNECTIONS = "connections",
  LOCATIONS = "locations",
  DELIVERIES = "deliveries",
}
