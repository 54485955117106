import { ITooltipComp, ITooltipParams } from "ag-grid-community";

export class TableTooltip implements ITooltipComp {
  eGui: any;

  init(params: ITooltipParams & { color: string }) {
    const eGui = (this.eGui = document.createElement("div"));

    eGui.classList.add("ag-grid-tooltip");
    eGui.innerHTML = `${params.value}`;
  }

  getGui() {
    return this.eGui;
  }
}
