<section class="stepper-container">
  @for (step of steps; track step.title; let idx = $index) {
    <button
      class="step-header"
      [class.active]="step.active"
      [class.disabled]="step.disabled"
      [style.z-index]="2 * (steps.length - idx)"
      [tp]="step.tooltip"
      (click)="step.onClick()"
    >
      <div class="step-icon">
        @if (step.navigated) {
          <mat-icon>check</mat-icon>
        } @else {
          {{ idx + 1 }}
        }
      </div>
      <p class="step-title">
        {{ step.title }}
      </p>
    </button>
    @if (!$last) {
      <svg
        [class.active]="step.active"
        [class.navigated]="step.navigated"
        [style.z-index]="2 * (steps.length - idx) - 1"
        fill="none"
        height="60"
        viewBox="0 0 27 60"
        width="27"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.2644 21.9778L2.71817 0.773227C2.09291 0.127933 1 0.570571 1 1.4691V58.4524C1 59.3423 2.07522 59.7889 2.70574 59.1608L23.1152 38.8299C27.7647 34.1983 27.8311 26.6909 23.2644 21.9778Z"
        />
      </svg>
    }
  }
</section>
<section class="step-content">
  @if (currentStep) {
    <ng-container [ngTemplateOutlet]="currentStep"></ng-container>
  }
</section>
