import { ChangeDetectionStrategy, Component } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

import { CommonUtils } from "@shared/utils";

@Component({
  template: `
    {{ getValue() }}
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirstExistingValueCellRendererComponent implements ICellRendererAngularComp {
  public params!: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  getValue = (): string => {
    const propertiesOrderedByPriority = this.params["propertiesOrderedByPriority"];

    if (!propertiesOrderedByPriority?.length) {
      return "-";
    }

    for (const property of propertiesOrderedByPriority) {
      const value = CommonUtils.getObjectNestedProperty(property, this.getData());

      if (value) {
        return value;
      }
    }

    return "-";
  };

  private getData = (): any => this.params.data ?? this.params.node.allLeafChildren[0].data;
}
