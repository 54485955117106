import { AfterViewInit, ChangeDetectionStrategy, Component, Input, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef } from "ag-grid-community";
import {
  ConfirmDialogComponent,
  EditCustomFieldDialogComponent,
  InfoDialogComponent,
} from "src/app/components";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { EnumsCellRendererComponent } from "@shared/cell-renderers";
import { CommonConstants } from "@shared/constants";
import { ConfirmDialogResponseEnum, RecordStateEnum, TableEnum } from "@shared/enums";
import { ICustomField } from "@shared/interfaces";
import { CustomFieldsService } from "@shared/services";
import { ColumnUtils, CommonUtils } from "@shared/utils";

@Component({
  selector: "app-custom-fields-table",
  templateUrl: "./custom-fields-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFieldsTableComponent implements AfterViewInit {
  @Input()
  public customFields: ICustomField[] = null;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isRecordStateFilterEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  @Input()
  public recordState: RecordStateEnum = RecordStateEnum.ALL;

  @Input()
  public table = TableEnum.CUSTOM_FIELDS;

  @Input()
  public isSaveTableState = false;

  public isLoading = signal(true);

  public rowData: ICustomField[] = [];

  public columnDefs = signal<ColDef[]>([]);

  constructor(
    private customFieldsService: CustomFieldsService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) {}

  public async ngAfterViewInit() {
    this.setColumnDefs();
    await this.getAll();
  }

  private setColumnDefs = (): void => {
    const columnDefs: ColDef[] = [
      ColumnUtils.recordState(),
      { headerName: "Label", field: "label", lockVisible: true },
      { headerName: "Type", field: "type", cellRenderer: EnumsCellRendererComponent },
      ColumnUtils.chips("Modules", "resourceTypes"),
    ];

    if (this.areButtonsEnabled) {
      const buttons = [
        {
          onClick: this.onDelete,
          tooltip: "Delete",
          icon: "delete",
        },
        {
          onClick: this.onToggleArchiveStatus,
          tooltip: (row) => (row.recordState === RecordStateEnum.ACTIVE ? "Archive" : "Unarchive"),
          icon: (row) => (row.recordState === RecordStateEnum.ACTIVE ? "archive" : "inventory"),
        },
        {
          onClick: this.onEdit,
          tooltip: "Edit",
          icon: "edit",
        },
      ];

      columnDefs.push(ColumnUtils.buttons(buttons));
    }
    this.columnDefs.set(columnDefs);
  };

  public onDelete = async (element: ICustomField): Promise<void> => {
    if (!this.areButtonsEnabled) {
      return;
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: "Delete confirmation",
        contentTranslatedText: `Are you sure you want delete this custom field?`,
        confirmButtonColor: "danger",
        confirmButtonTranslatedText: "Delete",
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        try {
          this.isLoading.set(true);
          await this.customFieldsService.delete(element.id);
          this.notificationService.showSuccess("Custom field deleted");
          await this.getAll();
        } catch (error) {
          this.notificationService.showError(error);
          this.isLoading.set(false);
        }
      }
    });
  };

  public onEdit = async (element: ICustomField): Promise<void> => {
    if (!this.areButtonsEnabled) {
      return;
    }
    if (element.recordState === RecordStateEnum.ARCHIVED) {
      this.dialog.open(InfoDialogComponent, {
        data: {
          icon: "error",
          iconColor: "red",
          contentTranslatedText: CommonConstants.EDIT_ARCHIVED_RECORD_ERROR_TEXT,
        },
      });

      return;
    }
    const dialogRef = this.dialog.open(EditCustomFieldDialogComponent, {
      data: {
        customField: element,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: { hasSaved: boolean }) => {
      if (result?.hasSaved) {
        await this.getAll();
      }
    });
  };

  public onToggleArchiveStatus = async (element: ICustomField): Promise<void> => {
    if (!this.areButtonsEnabled) {
      return;
    }
    const isArchived = element.recordState === RecordStateEnum.ARCHIVED;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleTranslatedText: `${isArchived ? "Unarchive" : "Archive"} confirmation`,
        contentTranslatedText: `Are you sure that you want to ${isArchived ? "unarchive" : "archive"} this record?`,
        confirmButtonTranslatedText: isArchived ? "Unarchive" : "Archive",
        confirmButtonIcon: isArchived ? "inventory" : "archive",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        try {
          this.isLoading.set(true);
          const wasArchived = element.recordState === RecordStateEnum.ARCHIVED;

          await this.customFieldsService.setRecordState(
            {
              recordState: wasArchived ? RecordStateEnum.ACTIVE : RecordStateEnum.ARCHIVED,
            },
            element.id,
          );
          this.notificationService.showSuccess(wasArchived ? "Unarchived" : "Archived");
          await this.getAll();
        } catch (error) {
          this.notificationService.showError(error);
          this.isLoading.set(false);
        }
      }
    });
  };

  private getParsedRowData = (customFields: any[]): ICustomField[] => {
    for (const customField of customFields) {
      const resourceTypes = [];

      for (const resourceType of customField.resourceTypes) {
        resourceTypes.push(CommonUtils.getParsedCustomFieldResourceType(resourceType));
      }
      customField.resourceTypes = resourceTypes;
    }

    return customFields;
  };

  public getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    if (this.customFields) {
      this.rowData = this.getParsedRowData(this.customFields);
      this.isLoading.set(false);
    } else {
      try {
        const customFields = await this.customFieldsService.getAll();

        this.rowData = this.getParsedRowData(customFields);
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };
}
