const getBase64File = async (file: File): Promise<any> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

const getFileExtension = (filename: string): string => {
  if (!filename) {
    return null;
  }

  return filename.substring(filename.lastIndexOf(".") + 1);
};

const getFileNameWithoutExtension = (filename: string): string => {
  if (!filename) {
    return null;
  }
  const lastDotIndex = filename.lastIndexOf(".");

  if (lastDotIndex === -1) {
    return filename;
  }

  return filename.substring(0, lastDotIndex);
};

const openFileInNewTab = (base64Data: string, contentType: string): void => {
  const byteCharacters = atob(base64Data.substr(`data:${contentType};base64,`.length));
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
    const slice = byteCharacters.slice(offset, offset + 1024);

    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  const blobUrl = URL.createObjectURL(blob);

  window.open(blobUrl, "_blank");
};

const downloadFileBlob = (blob: Blob, name: string): void => {
  const link = document.createElement("a");

  link.href = window.URL.createObjectURL(blob);
  link.download = name;
  document.body.appendChild(link);
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );
  link.remove();
  window.URL.revokeObjectURL(link.href);
};

const downloadJsonFile = (jsonData: object, name: string): void => {
  const blob = new Blob([JSON.stringify(jsonData, null, 2)], { type: "application/json" });

  downloadFileBlob(blob, name);
};

export const FileUtils = {
  getBase64File,
  getFileExtension,
  getFileNameWithoutExtension,
  openFileInNewTab,
  downloadFileBlob,
  downloadJsonFile,
};
