import { ChangeDetectionStrategy, Component, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { RoutingEnum } from "@shared/enums";

import { EditTagDialogComponent } from "..";
import { TagsTableComponent } from "../../shared";

@Component({
  templateUrl: "./settings-tags.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsTagsComponent {
  @ViewChild("tagsTable") tagsTable: TagsTableComponent;

  public readonly routingEnum = RoutingEnum;

  constructor(private dialog: MatDialog) {}

  public onAdd = async (): Promise<void> => {
    const dialogRef = this.dialog.open(EditTagDialogComponent);

    dialogRef.afterClosed().subscribe(async (result: { hasSaved: boolean }) => {
      if (result?.hasSaved) {
        await this.tagsTable.getAll();
      }
    });
  };
}
