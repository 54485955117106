<div class="container-space-between">
  <app-page-title
    title="Supply chains"
    hint="Access and manage all your existing supply chains. Edit, view details,
        and create new ones to streamline your operations."
  ></app-page-title>

  <iov-button-group>
    <iov-button type="outlined" (pressed)="onNavigate(routingEnum.FULL_SUPPLY_CHAIN)">
      View full supply chain
    </iov-button>
    <iov-button
      [visible]="canAddModifyEntities"
      (pressed)="onNavigate(routingEnum.SUPPLY_CHAINS_ADD)"
    >
      <mat-icon icon-left>add</mat-icon>
      Add new
    </iov-button>
  </iov-button-group>
</div>

<app-supply-chains-table
  [recordState]="recordStateEnum.ACTIVE"
  [isSaveTableState]="true"
  [isFixedBottomPaginator]="true"
></app-supply-chains-table>
