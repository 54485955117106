import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, Inject, signal } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { IDocumentType, IInvitationPayload, ISelectOption } from "src/app/shared/interfaces";
import { AuthenticationService, InvitationsService } from "src/app/shared/services";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { CommonUtils } from "../../../../shared/utils";
import { CustomValidators } from "../../../../shared/validators";

@Component({
  templateUrl: "./add-user-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddUserDialogComponent {
  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl(null, [CustomValidators.required, CustomValidators.email]),
    role: new UntypedFormControl(null, [CustomValidators.required]),
  });

  public isLoading = signal(false);

  public readonly userRolesOptions: ISelectOption[] = [];

  constructor(
    public dialogRef: MatDialogRef<AddUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { documentType?: IDocumentType },
    private notificationService: NotificationService,
    private invitationsService: InvitationsService,
    private authenticationService: AuthenticationService,
  ) {
    this.userRolesOptions = CommonUtils.getUserRolesOptions(
      this.authenticationService.getActiveOrganisation().roles,
    );
  }

  public isSubmitButtonDisabled = (): boolean => this.formGroup.invalid;

  public onSubmit = async (): Promise<void> => {
    this.isLoading.set(true);

    const email = this.formGroup.controls["email"].value.trim();

    const payload: IInvitationPayload = {
      email,
      type: "USER",
      organisation: "/organisations/" + this.authenticationService.getActiveOrganisationId(),
      role: this.formGroup.controls["role"].value.value,
    };

    await this.invitationsService
      .invite(payload)
      .then(() => {
        const roleName = this.userRolesOptions.find(
          (u) => u.value === this.formGroup.controls["role"].value.value,
        ).label;

        this.notificationService.showSuccess(`User ${email} has been invited as ${roleName}`);
        this.onClose(true);
      })
      .catch((error: HttpErrorResponse) => {
        this.notificationService.showError(error);
      })
      .finally(() => {
        this.isLoading.set(false);
      });
  };

  public onClose = (hasSaved = false): void => {
    this.dialogRef.close({ hasSaved });
  };
}
