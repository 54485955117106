import { ChangeDetectionStrategy, Component, OnDestroy, signal } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";

import { Subscription } from "rxjs";
import { IAvailableOrganisation, ISelectOption, IUserData } from "src/app/shared/interfaces";
import { AuthenticationService } from "src/app/shared/services";

import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { CustomValidators } from "../../../shared/validators";

@Component({
  templateUrl: "./select-organisation-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectOrganisationDialogComponent implements OnDestroy {
  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    organisation: new UntypedFormControl(null, [CustomValidators.required]),
  });

  public isLoading = signal(false);

  public organisationOptions: ISelectOption[] = [];

  private subscriptions = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<SelectOrganisationDialogComponent>,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService,
  ) {
    this.subscriptions.add(
      this.authenticationService.userDataObservable$.subscribe((userData: IUserData) => {
        this.organisationOptions = [];
        if (userData?.availableOrganisations?.length) {
          this.organisationOptions = userData.availableOrganisations.map(
            (o: IAvailableOrganisation) => ({
              label: o.name,
              value: o.id,
            }),
          );
        }
      }),
    );
  }

  public isSubmitButtonDisabled = (): boolean => this.formGroup.invalid;

  public onSubmit = async (): Promise<void> => {
    this.isLoading.set(true);

    const organisation = this.formGroup.controls["organisation"].value;
    const organisationIndex = this.organisationOptions.findIndex(
      (o) => o.value === organisation.value,
    );

    await this.authenticationService.setActiveOrganisationIndex(organisationIndex);

    this.isLoading.set(false);

    this.notificationService.showSuccess(`'${organisation.label}' is now active`);

    this.onClose();
  };

  public onClose = (): void => {
    this.dialogRef.close();
  };

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
