import {
  ChangeDetectionStrategy,
  Component,
  input,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { FormGroup } from "@angular/forms";

import { SlideOverlayPageService } from "@design-makeover/components/overlay/slide-overlay-page/slide-overlay-page.service";
import { NotificationService } from "@design-makeover/services/notification/notification.service";

import { CommonConstants } from "@shared/constants";
import { GeoJSONUtils } from "@shared/utils";

@Component({
  selector: "app-location-overlay-gps",
  templateUrl: "./location-overlay-gps.component.html",
  styleUrl: "./location-overlay-gps.component.scss",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class LocationOverlayGpsComponent implements OnChanges {
  @Input({ required: true }) formGroup: FormGroup;

  public isEditing = input<boolean>(false);

  mapDetailsType = "coordinates";

  file: File;

  readonly commonConstants = CommonConstants;

  readonly uploadBoxFooterRightText: string = `Maximum size: ${this.commonConstants.MAX_GEO_JSON_SIZE_IN_MB}MB`;

  constructor(
    private notificationService: NotificationService,
    protected overlay: SlideOverlayPageService,
  ) {}

  get hasGeoLocationValue(): boolean {
    return !!this.formGroup.controls["geoLocation"].value;
  }

  public ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges["isEditing"] && !this.isEditing()) {
      this.mapDetailsType = "coordinates";
    }
  }

  public onFileSelected = async (file: File): Promise<void> => {
    this.file = file;
    const reader = new FileReader();

    reader.readAsText(file);
    reader.onload = () => {
      try {
        const geojson = JSON.parse(reader.result.toString());

        if (!GeoJSONUtils.isValid(geojson)) {
          this.notificationService.showError("File not supported");
          this.file = null;

          return;
        }
        // this.mapDetailsType = null;
        this.file = null;
        this.formGroup.controls["gpsX"].setValue(null);
        this.formGroup.controls["gpsY"].setValue(null);
        this.formGroup.controls["geoLocation"].setValue(geojson);
      } catch {
        this.notificationService.showError("File not supported");
        this.file = null;
      }
    };
  };

  public onDeleteMap = (): void => {
    this.formGroup.controls["gpsX"].setValue(null);
    this.formGroup.controls["gpsY"].setValue(null);
    this.formGroup.controls["geoLocation"].setValue(null);
    this.mapDetailsType = "coordinates";
  };
}
